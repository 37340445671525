import React, { Component } from "react";
import { colorTypes } from "./Resources";

export default class TextAccessibility extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleReport = () => {
    const { showReport } = this.state;
    let reportStatus = !showReport;
    this.setState({
      showReport: reportStatus,
    });
  };

  makeAccessible = (color, size) => {
    const { itemColors } = this.props;
    const itemColorsCopy = { ...itemColors };
    itemColorsCopy.colorReport[`toggle${color}${size}`] =
      itemColorsCopy.colorReport[`toggle${color}${size}`] ===
      itemColorsCopy.colorReport[`${color}AA${size}Alt`]
        ? itemColorsCopy.colorVars[color]
        : itemColorsCopy.colorReport[`${color}AA${size}Alt`];
    itemColorsCopy.colorReport[`${color}AA${size}`] =
      !itemColorsCopy.colorReport[`${color}AA${size}`];
    this.setState({
      itemColors: itemColorsCopy,
    });
  };

  render() {
    const { item, itemColors } = this.props;
    const { showReport } = this.state;

    return (
      <div className="item-palette-report-wrap">
        <div className={`item-palette-report ${!showReport ? "hidden" : ""}`}>
          <h3>
            <span className="palette-report-title" onClick={this.toggleReport}>
              Text Color Accessibility
            </span>
            <span className="palette-report-sub">
              We checked how{" "}
              <a
                href="https://www.usability.gov/what-and-why/accessibility.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                web accessible
              </a>{" "}
              your palette is when using it as a text color (
              <a
                href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                target="_blank"
                rel="noopener noreferrer"
              >
                WCAG AA
              </a>
              ). We will include color alternates that you can use to ensure
              your palette is compliant.
            </span>
          </h3>
          <div className="palette-report-wrap">
            {item.palette.colors
              ? Object.keys(item.palette.colors)
                  .sort((a, b) => colorTypes.indexOf(a) - colorTypes.indexOf(b))
                  .map((color) => (
                    <div
                      className={`palette-report-${color} palette-report-col`}
                      style={{ color: itemColors.colorVars[color] }}
                      key={color}
                    >
                      <span
                        className={`${color}-large color-text color-text-large`}
                        style={{
                          color: itemColors.colorReport[`toggle${color}large`],
                        }}
                      >
                        {itemColors.colorReport[`${color}AAlarge`] ? (
                          <span className="report-pass">
                            <i className="icon icon-check" />
                          </span>
                        ) : (
                          <span className="report-fail">
                            <i className="icon icon-exclamation" />
                          </span>
                        )}
                        {color} large text
                      </span>
                      <span
                        className={`${color}-small color-text`}
                        style={{
                          color: itemColors.colorReport[`toggle${color}small`],
                        }}
                      >
                        {itemColors.colorReport[`${color}AAsmall`] ? (
                          <span className="report-pass">
                            <i className="icon icon-check" />
                          </span>
                        ) : (
                          <span className="report-fail">
                            <i className="icon icon-exclamation" />
                          </span>
                        )}
                        {color} small text
                      </span>
                      <span className="report-color-alts">
                        {!itemColors.colorReport[`${color}AAlarge`] ||
                        itemColors.colorReport[`toggle${color}large`] !== "" ? (
                          <span
                            className="report-color-alt"
                            style={{
                              color:
                                itemColors.colorReport[`${color}AAlargeAlt`],
                            }}
                          >
                            <div
                              className="color-alt"
                              style={{
                                background:
                                  itemColors.colorReport[`${color}AAlargeAlt`],
                                color: "#fff",
                              }}
                            >
                              {itemColors.colorReport[`${color}AAlargeAlt`]}
                            </div>
                            &nbsp;
                            <div
                              className="color-alt-text"
                              onClick={() =>
                                this.makeAccessible(color, "large")
                              }
                              title="toggle alternate color"
                            >
                              Large text alternate
                            </div>
                          </span>
                        ) : null}
                        {!itemColors.colorReport[`${color}AAsmall`] ||
                        itemColors.colorReport[`toggle${color}small`] !== "" ? (
                          <span
                            className="report-color-alt"
                            style={{
                              color:
                                itemColors.colorReport[`${color}AAsmallAlt`],
                            }}
                          >
                            <div
                              className="color-alt"
                              style={{
                                background:
                                  itemColors.colorReport[`${color}AAsmallAlt`],
                                color: "#fff",
                              }}
                            >
                              {itemColors.colorReport[`${color}AAsmallAlt`]}
                            </div>
                            &nbsp;
                            <div
                              className="color-alt-text"
                              onClick={() =>
                                this.makeAccessible(color, "small")
                              }
                              title="toggle alternate color"
                            >
                              Small text alternate
                            </div>
                          </span>
                        ) : null}
                      </span>
                    </div>
                  ))
              : null}
          </div>

          {item.palette.altColors ? (
            <div className="palette-report-wrap palette-alt-report">
              {item.palette.altColors.map((color, index) => (
                <div
                  className={`palette-report-${color.id} palette-report-col`}
                  style={{ color: itemColors.colorVars[color.id] }}
                  key={color.id}
                >
                  <span
                    className={`${color.id}-large color-text color-text-large`}
                    style={{
                      color: itemColors.colorReport[`toggle${color.id}large`],
                    }}
                  >
                    {itemColors.colorReport[`${color.id}AAlarge`] ? (
                      <span className="report-pass">
                        <i className="icon icon-check" />
                      </span>
                    ) : (
                      <span className="report-fail">
                        <i className="icon icon-exclamation" />
                      </span>
                    )}
                    {color.name ? color.name : "Untitled"} large text
                  </span>
                  <span
                    className={`${color.id}-small color-text`}
                    style={{
                      color: itemColors.colorReport[`toggle${color.id}small`],
                    }}
                  >
                    {itemColors.colorReport[`${color.id}AAsmall`] ? (
                      <span className="report-pass">
                        <i className="icon icon-check" />
                      </span>
                    ) : (
                      <span className="report-fail">
                        <i className="icon icon-exclamation" />
                      </span>
                    )}
                    {color.name ? color.name : "Untitled"} small text
                  </span>
                  <span className="report-color-alts">
                    {!itemColors.colorReport[`${color.id}AAlarge`] ||
                    itemColors.colorReport[`toggle${color.id}large`] !== "" ? (
                      <span
                        className="report-color-alt"
                        style={{
                          color:
                            itemColors.colorReport[`${color.id}AAlargeAlt`],
                        }}
                      >
                        <div
                          className="color-alt"
                          style={{
                            background:
                              itemColors.colorReport[`${color.id}AAlargeAlt`],
                            color: "#fff",
                          }}
                        >
                          {itemColors.colorReport[`${color.id}AAlargeAlt`]}
                        </div>
                        &nbsp;
                        <div
                          className="color-alt-text"
                          onClick={() => this.makeAccessible(color.id, "large")}
                          title="toggle alternate color"
                        >
                          Large text alternate
                        </div>
                      </span>
                    ) : null}
                    {!itemColors.colorReport[`${color.id}AAsmall`] ||
                    itemColors.colorReport[`toggle${color.id}small`] !== "" ? (
                      <span
                        className="report-color-alt"
                        style={{
                          color:
                            itemColors.colorReport[`${color.id}AAsmallAlt`],
                        }}
                      >
                        <div
                          className="color-alt"
                          style={{
                            background:
                              itemColors.colorReport[`${color.id}AAsmallAlt`],
                            color: "#fff",
                          }}
                        >
                          {itemColors.colorReport[`${color.id}AAsmallAlt`]}
                        </div>
                        &nbsp;
                        <div
                          className="color-alt-text"
                          onClick={() => this.makeAccessible(color.id, "small")}
                          title="toggle alternate color"
                        >
                          Small text alternate
                        </div>
                      </span>
                    ) : null}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className="btn btn-light" onClick={this.toggleReport}>
          {!showReport ? "view report" : "hide report"}
        </div>
      </div>
    );
  }
}

const INITIAL_STATE = {
  showReport: false,
};
