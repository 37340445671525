import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { db, storage, firebase } from "../../firebase";
import * as routes from "constants/routes";
import ItemInviteCard from "./ItemInviteCard";
import Members from "../shared/Members";
import Share from "../shared/Share";
import {
  Dropdown,
  Menu,
  Popup,
  Modal,
  Confirm,
  Input,
} from "semantic-ui-react";
import Moment from "react-moment";
import ProgressiveImage from "react-progressive-image";
import {
  useMountEffect,
  AsyncState,
  findInitials,
  findFileType,
  loadFonts,
  capString,
  findNormalFontWeight,
  findNormalFontStyle,
} from "./Resources";
import { MembershipStatus } from "../users/Account";
import { MembershipLimit } from "../users/MembershipData";
import { Helmet } from "react-helmet-async";
import ReactBody from "react-body";

export const ItemsList = (props) => {
  const { history, userMembership, type, label, authUser } = props;

  useEffect(() => window.scrollTo(0, 0), []);

  const timeout = useRef(null);
  const stepTotal =
    type === "projects"
      ? 8
      : type === "styleguides"
      ? 9
      : type === "showcases"
      ? 6
      : type === "personas"
      ? 6
      : 1;
  const [items, setItems] = AsyncState({});
  const [itemsArchive, setItemsArchive] = AsyncState([]);
  const [itemTemplate, setItemTemplate] = useState([]);
  const [itemInvites, setItemInvites] = useState([]);
  const [itemsLimit, setItemsLimit] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasItems, setHasItems] = useState(null);
  const [hasInvites, setHasInvites] = useState(null);
  const [hasArchive, setHasArchive] = useState(null);
  const [inviteRemoveConfirmOpen, setInviteRemoveConfirmOpen] = useState(false);
  const [itemInviteStatus, setItemInviteStatus] = useState("");
  const [itemInviteKey, setItemInviteKey] = useState("");
  const [shareItemOpen, setShareItemOpen] = useState(false);
  const [shareItemID, setShareItemID] = useState("");
  const [manageUsersOpen, setManageUsersOpen] = useState(false);
  const [sortOn, setSortOn] = useState(false);
  const [itemsSort, setItemsSort] = useState("");
  const [itemsSearch, setItemsSearch] = useState(null);
  const [itemsSearchOn, setItemsSearchOn] = useState(false);
  const [item, setItem] = useState([]);
  const [itemsCols, setItemsCols] = useState([]);
  const [itemID, setItemID] = useState("");
  const [itemsArchiveOn, setItemsArchiveOn] = useState(false);
  const [archiveItemConfirmOpen, setArchiveItemConfirmOpen] = useState(false);
  const [archiveItemID, setArchiveItemID] = useState("");
  const [archivingItem, setArchivingItem] = useState(false);
  const [unarchiveItemID, setUnarchiveItemID] = useState("");
  const [unarchivingItem, setUnarchivingItem] = useState(false);
  const [itemDeleteOpen, setItemDeleteOpen] = useState(false);
  const [itemDeleting, setItemDeleting] = useState(false);
  const [deleteItemID, setDeleteItemID] = useState("");
  const [deletingItem, setDeletingItem] = useState(false);
  const [duplicateItemID, setDuplicateItemID] = useState("");
  const [duplicatingItem, setDuplicatingItem] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [itemDeletedModalOpen, setItemDeletedModalOpen] = useState(false);

  useMountEffect(() => {
    getTemplate();
    return () => {
      clearTimeout(timeout.current);
    };
  });

  const getTemplate = () => {
    storage.getCurrentTemplate(label).then((response) => {
      const request = new XMLHttpRequest();
      request.open("GET", response, true);
      request.onreadystatechange = (request) => {
        if (request.target.readyState === 4) {
          const data = JSON.parse(request.target.response);
          setItemTemplate(data);
          getItems(data);
        }
      };
      request.send();
    });
  };

  const getItems = () =>
    db.getUserItems(type, authUser.uid).then((snapshot) => {
      const itemsInit = {};
      const itemsArchiveInit = {};
      const itemInvitesInit = [];
      const usersInit = {};
      let itemsSortInit = "";
      let itemsColsInit = "";
      let itemsListInit = {};
      let usersListInit = {};
      if (snapshot !== null) {
        const itemsSnap = snapshot;
        itemsListInit = snapshot.map((item) => {
          return new Promise((resolve) => {
            if (item.val().archived && item.val().admin === authUser.uid) {
              itemsArchiveInit[item.key] = item.val();
            } else if (!item.val().archived) {
              if (item.val().users[authUser.uid] === true) {
                itemsInit[item.key] = item.val();
              } else {
                itemInvitesInit.push(item);
              }
            }
            usersListInit = Object.keys(item.val().users).map((user) => {
              return new Promise((resolve) => {
                db.getUser(user).then((snapshot) => {
                  usersInit[user] = snapshot.val();
                  resolve();
                });
              });
            });
            Promise.all(usersListInit)
              .then(() => {
                resolve();
              })
              .catch((error) => console.log(error));
          });
        });
        Promise.all(itemsListInit)
          .then(() => {
            db.getSort(type, authUser.uid).then((snapshot) => {
              if (snapshot.val() === null) {
                itemsSortInit = "update";
                db.setSort(type, authUser.uid, itemsSortInit);
              } else {
                itemsSortInit = snapshot.val();
              }

              // TODO: These should really be using useEffect to detect if things are there or not
              setHasItems(Object.keys(itemsInit).length > 0 ? true : false);
              setHasInvites(itemInvitesInit.length > 0 ? true : false);
              setHasArchive(
                Object.keys(itemsArchiveInit).length > 0 ? true : false
              );
              setItemInviteStatus("");
              setItemInviteKey("");

              setItemsSort(itemsSortInit);
              setItemsLimit(
                MembershipLimit(Object.keys(itemsInit).length, userMembership)
              );
              setUsers(usersInit);
              setItems(itemsInit);

              setItemInvites(itemInvitesInit);
              setItemsArchive(itemsArchiveInit);
              if (
                type === "styleguides" ||
                type === "showcases" ||
                type === "personas"
              ) {
                getFonts(itemsSnap);
              }
            });
          })
          .then(() => {
            db.getCols(type, authUser.uid).then((snapshot) => {
              if (snapshot.val() === null) {
                itemsColsInit = 2;
                db.setCols(type, authUser.uid, itemsColsInit);
              } else {
                itemsColsInit = snapshot.val();
              }
              setItemsCols(itemsColsInit);
            });
          })
          .catch((error) => console.log(error));
      } else {
        setHasItems(false);
        setHasInvites(false);
        setHasArchive(false);
        setItemInviteStatus("");
        setItemInviteKey("");

        setItemsSort(itemsSortInit);
        setItemsLimit(
          MembershipLimit(Object.keys(itemsInit).length, userMembership)
        );
        setUsers(usersInit);
        setItems(itemsInit);

        setItemsArchive(itemsArchiveInit);
      }
    });

  const updateItems = (id, archiveType, timestamp) => {
    if (archiveType === "archive") {
      setItemsArchive({
        ...itemsArchive,
        [id]: {
          ...items[id],
          archived: timestamp,
        },
      });
      let itemsUpdate = {};
      Object.keys(items)
        .filter((item) => item !== id)
        .map((item) => (itemsUpdate[item] = items[item]));
      setItems(itemsUpdate).then((update) => {
        setArchivingItem(false);
        setArchiveItemID("");
        setHasItems(Object.keys(update).length > 0 ? true : false);
        setHasArchive(true);
      });
    } else if (archiveType === "unarchive" || archiveType === "delete") {
      if (archiveType === "unarchive") {
        setItems({
          ...items,
          [id]: {
            ...itemsArchive[id],
            archived: [],
          },
        });
      }
      let itemsArchiveUpdate = {};
      Object.keys(itemsArchive)
        .filter((item) => item !== id)
        .map((item) => (itemsArchiveUpdate[item] = itemsArchive[item]));
      setItemsArchive(itemsArchiveUpdate).then((update) => {
        setUnarchivingItem(false);
        setUnarchiveItemID("");
        setHasArchive(Object.keys(update).length > 0 ? true : false);
        if (archiveType === "unarchive")
          setHasItems(Object.keys(items).length > 0 ? true : false);
      });
    }
  };

  const getFonts = (items) => {
    let fonts = [];
    items.forEach((item) => {
      if (item.val().fonts) {
        for (var i = 0; i < item.val().fonts.length; i++) {
          fonts.push(item.val().fonts[i]);
          if (i === 1) {
            i = item.val().fonts.length;
          }
        }
      }
    });
    loadFonts(fonts, () => {
      setFontsLoaded(true);
    });
  };

  const sortItems = (sort) => {
    setSortOn(true);
    db.setSort(type, authUser.uid, sort).then(() => {
      setItemsSort(sort);
    });
  };

  const colsItems = (cols) => {
    db.setCols(type, authUser.uid, cols).then(() => {
      setItemsCols(cols);
    });
  };

  const goToItem = (itemID) => {
    const path = `${routes[capString(label)]}/edit/${itemID}`;
    history.push(path);
  };

  const addItem = () => {
    db.createItem(type, authUser.uid, goToItem, itemTemplate.version);
  };

  const editItemInvitation = (selection, key) => {
    db.getItem(type, key).then((snapshot) => {
      if (snapshot.val() !== null) {
        if (selection === "confirm") {
          setItemInviteStatus("joining");
          setItemInviteKey(key);
          firebase
            .confirmJoinItem({ uid: authUser.uid, id: key, type: type })
            .then(() => {
              getItems();
            });
        } else if (selection === "delete") {
          setInviteRemoveConfirmOpen(true);
          setItemInviteKey(key);
        }
      } else {
        setItemDeletedModalOpen(true);
      }
    });
  };

  const confirmRemoveInvite = () => {
    setItemInviteStatus("declining");
    setInviteRemoveConfirmOpen(false);
    firebase
      .removeUser({ id: itemInviteKey, type: type, uid: authUser.uid })
      .then(() => {
        getItems();
      });
  };

  const cancelRemoveInvite = () => {
    setInviteRemoveConfirmOpen(false);
    setItemInviteKey("");
  };

  const openShareItemModal = (itemID) => {
    setShareItemID(itemID);
    setShareItemOpen(true);
  };

  const closeShareItemModal = () => {
    setShareItemOpen(false);
  };

  const manageItemUsers = (item, itemID) => {
    setItem(item);
    setItemID(itemID);
    setManageUsersOpen(true);
  };

  const closeManageUsersModal = (bool) => {
    setItem([]);
    setItemID("");
    setManageUsersOpen(bool);
  };

  const openArchiveItemConfirm = (itemID) => {
    setArchiveItemConfirmOpen(true);
    setArchiveItemID(itemID);
    setArchivingItem(false);
  };

  const cancelArchiveItemConfirm = () => {
    setArchiveItemConfirmOpen(false);
    setArchiveItemID("");
  };

  const confirmArchiveItem = () => {
    setArchiveItemConfirmOpen(false);
    setArchivingItem(true);
    db.archiveItem(type, archiveItemID, authUser.uid).then((timestamp) => {
      timeout.current = setTimeout(() => {
        updateItems(archiveItemID, "archive", timestamp);
      }, 500);
    });
  };

  const unarchiveItem = (itemID) => {
    if (!itemsLimit && !unarchivingItem) {
      setUnarchivingItem(true);
      setUnarchiveItemID(itemID);
      db.unarchiveItem(type, itemID, authUser.uid).then(() => {
        timeout.current = setTimeout(() => {
          updateItems(itemID, "unarchive");
        }, 500);
      });
    }
  };

  const itemClick = (itemID) => {
    const path = `${routes[capString(label)]}/${
      !items[itemID].doneList || !items[itemID].doneList.publish ? "edit/" : ""
    }${itemID}`;
    history.push(path);
  };

  const changeItemsView = (view) => {
    if (view === "archive") {
      setItemsArchiveOn(true);
      disableItemsSearch();
    } else {
      setItemsArchiveOn(false);
      disableItemsSearch();
    }
  };

  const openDeleteItem = (itemID) => {
    setItemDeleteOpen(true);
    setDeleteItemID(itemID);
  };

  const cancelDeleteItem = () => {
    setItemDeleteOpen(false);
    setDeleteItemID("");
  };

  useEffect(() => {
    if (itemsSearchOn) {
      const itemsSearch = document.getElementById("items-search-input");
      if (itemsSearch) itemsSearch.focus();
    }
  }, [itemsSearchOn]);

  const enableItemsSearch = () => {
    setItemsSearchOn(true);
  };

  const filterCondition = (item) => {
    return (
      (!item.name &&
        itemsSearch &&
        String("untitled").includes(itemsSearch.toLowerCase())) ||
      (item.name &&
        itemsSearch &&
        item.name.toLowerCase().includes(itemsSearch.toLowerCase())) ||
      !itemsSearch
    );
  };

  const disableItemsSearch = () => {
    setItemsSearchOn(false);
    setItemsSearch(null);
  };

  const dismissLimit = (event) => {
    event.preventDefault();
    document.body.classList.add("limit-dismiss");
  };

  const deleteItem = () => {
    setItemDeleting(true);
    firebase
      .deleteItem({ id: deleteItemID, type: type, uid: authUser.uid })
      .then(() => {
        setItemDeleteOpen(false);
        setItemDeleting(false);
        setDeletingItem(true);
        timeout.current = setTimeout(() => {
          updateItems(deleteItemID, "delete");
          setDeletingItem(false);
        }, 500);
      });
  };

  const duplicateItem = (itemID) => {
    db.duplicateItem(type, itemID, authUser.uid, (dupID) => {
      setDuplicateItemID(dupID);
      setDuplicatingItem(true);
      getItems();
      firebase
        .duplicateItemFiles({
          id: itemID,
          dupID: dupID,
          type: type,
          uid: authUser.uid,
        })
        .then(() => {
          setDuplicatingItem(false);
          timeout.current = setTimeout(() => {
            setDuplicateItemID("");
          }, 333);
        });
    });
  };

  const itemsFiltered = Object.keys(items).filter((key) =>
    filterCondition(items[key])
  );
  const itemsArchiveFiltered = Object.keys(itemsArchive).filter((key) =>
    filterCondition(itemsArchive[key])
  );

  return (
    <section id={type} className="content-outer">
      <div className="item-inner">
        <Helmet>
          <title>DesignGapp - {capString(type)}</title>
          <meta name="description" content={`Your DesignGapp ${type}`} />
        </Helmet>

        <Confirm
          className="confirm delete"
          open={inviteRemoveConfirmOpen}
          header="Are you sure you want to decline this invite?"
          content=""
          cancelButton="nevermind"
          confirmButton="yes, remove"
          onCancel={cancelRemoveInvite}
          onConfirm={confirmRemoveInvite}
        />

        <Confirm
          className="confirm progressive"
          open={archiveItemConfirmOpen}
          header={`Are you sure you want to archive this ${label}?`}
          content={`Archived ${type} can be restored but only by you, the ${label} owner. This ${label} will be hidden to all other members.`}
          cancelButton="nevermind"
          confirmButton="yes, archive"
          onCancel={cancelArchiveItemConfirm}
          onConfirm={confirmArchiveItem}
        />

        <Confirm
          className={`confirm delete ${itemDeleting ? "loading" : ""}`}
          open={itemDeleteOpen}
          header={`Do you really want to delete this ${label}?`}
          content="This is permanent and can't be undone."
          cancelButton="nevermind"
          confirmButton="yes, delete"
          onCancel={cancelDeleteItem}
          onConfirm={deleteItem}
        />

        <Members
          type={type}
          closeManageUsersModal={closeManageUsersModal}
          manageUsersOpen={manageUsersOpen}
          getItems={getItems}
          id={itemID}
          data={item}
          authUser={authUser}
          history={history}
        />

        <Share
          type={type}
          shareOpen={shareItemOpen}
          closeShareModal={closeShareItemModal}
          id={shareItemID}
        />

        <Modal className="item-deleted-modal" open={itemDeletedModalOpen}>
          <Modal.Header>Sorry, your {label} was deleted</Modal.Header>
          <Modal.Content>
            <p>
              Please contact the {label} owner for help. You can refresh the{" "}
              {type} page below.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => window.location.reload()}
            >
              refresh my {type}
            </button>
          </Modal.Content>
        </Modal>

        <div className="content-wrap">
          <MembershipStatus userMembership={userMembership} />
          <div
            className={`items-header ${
              itemsArchiveOn ? "archive" : type.toLowerCase()
            }`}
          >
            <Menu className="items-view">
              <Dropdown
                trigger={
                  <div className="trigger items-header-trigger">
                    <h1>
                      {itemsArchiveOn ? "Archive" : capString(type)}
                      <i className="icon icon-chevron-down" />
                    </h1>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <span
                    className="link menu-item sort-item"
                    onClick={() => changeItemsView(type.toLowerCase())}
                  >
                    {capString(type)} ({Object.keys(items).length})
                  </span>
                  <span
                    className="link menu-item sort-item"
                    onClick={() => changeItemsView("archive")}
                  >
                    Archive ({Object.keys(itemsArchive).length})
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
            {itemTemplate.hasOwnProperty("version") ? (
              <div className="items-controls">
                {(Object.keys(items).length > 1 && itemsArchiveOn === false) ||
                (Object.keys(itemsArchive).length > 1 &&
                  itemsArchiveOn === true) ? (
                  <div className="items-search" title={`Search for ${type}`}>
                    {itemsSearchOn ? (
                      <span className="icon-search-input">
                        <Input
                          id="items-search-input"
                          value={itemsSearch ? itemsSearch : ""}
                          placeholder={`Search ${type}...`}
                          onChange={(event) =>
                            setItemsSearch(event.target.value)
                          }
                          onKeyUp={(event) => {
                            if (event.key === "Escape") {
                              event.preventDefault();
                              setItemsSearch(null);
                              setItemsSearchOn(false);
                            }
                          }}
                        />
                        <i
                          className="icon icon-cross"
                          onClick={disableItemsSearch}
                        />
                      </span>
                    ) : (
                      <i
                        className="icon icon-magnifier"
                        onClick={enableItemsSearch}
                      />
                    )}
                  </div>
                ) : null}
                {Object.keys(items).length > 0 && itemsArchiveOn === false ? (
                  <Menu className="items-cols" title="Choose column size">
                    <Dropdown
                      trigger={
                        <div className="trigger items-cols-trigger">
                          <div className={`item-cols cols-${itemsCols}`}>
                            <div className="cols-item">
                              {Array.from(Array(itemsCols), (col, index) => (
                                <span key={`item-${index}`} />
                              ))}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu>
                        <span
                          className="link menu-item cols-item cols-1"
                          onClick={() => colsItems(1)}
                        >
                          <span />
                        </span>
                        <span
                          className="link menu-item cols-item cols-2"
                          onClick={() => colsItems(2)}
                        >
                          <span />
                          <span />
                        </span>
                        <span
                          className="link menu-item cols-item cols-3"
                          onClick={() => colsItems(3)}
                        >
                          <span />
                          <span />
                          <span />
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                ) : null}
                {Object.keys(items).length > 1 && itemsArchiveOn === false ? (
                  <Menu className="items-sort" title="Choose sort option">
                    <Dropdown
                      trigger={
                        <div className="trigger items-sort-trigger">
                          <p>
                            {itemsSort === "alpha"
                              ? "Alpha"
                              : itemsSort === "created"
                              ? "Created"
                              : "Recent"}
                          </p>
                          <i className="icon icon-chevron-down" />
                        </div>
                      }
                    >
                      <Dropdown.Menu>
                        <span
                          className="link menu-item sort-item"
                          onClick={() => sortItems("alpha")}
                        >
                          Sort alphabetically
                        </span>
                        <span
                          className="link menu-item sort-item"
                          onClick={() => sortItems("update")}
                        >
                          Sort by recent
                        </span>
                        {
                          // TODO: Not ready for this yet
                          // <span className="link menu-item sort-item" onClick={() => sortItems('created')}>Sort by created</span>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                ) : null}
              </div>
            ) : null}
            {itemTemplate.hasOwnProperty("version") &&
            (hasItems !== null || hasInvites !== null) &&
            !itemsArchiveOn ? (
              <AddItem
                label={label}
                addItem={addItem}
                itemsLimit={itemsLimit}
              />
            ) : null}
          </div>

          {(hasItems === true || hasInvites === true) && !itemsArchiveOn ? (
            <ItemList
              type={type}
              label={label}
              items={items}
              users={users}
              authUser={authUser}
              history={history}
              stepTotal={stepTotal}
              itemInvites={itemInvites}
              itemsLimit={itemsLimit}
              editItemInvitation={editItemInvitation}
              itemInviteStatus={itemInviteStatus}
              itemInviteKey={itemInviteKey}
              openShareItemModal={openShareItemModal}
              manageItemUsers={manageItemUsers}
              itemsSort={itemsSort}
              itemsCols={itemsCols}
              sortOn={sortOn}
              openArchiveItemConfirm={openArchiveItemConfirm}
              archiveItemID={archiveItemID}
              archivingItem={archivingItem}
              unarchivingItem={unarchivingItem}
              fontsLoaded={fontsLoaded}
              itemClick={itemClick}
              itemsSearch={itemsSearch}
              filterCondition={filterCondition}
              duplicateItem={duplicateItem}
              duplicateItemID={duplicateItemID}
              duplicatingItem={duplicatingItem}
            />
          ) : itemsArchiveOn === true ? null : hasItems === false &&
            itemsArchiveOn === false ? (
            <ZeroItems type={type} itemsArchive={itemsArchive} />
          ) : (
            <div className="content-loading no-header" />
          )}
          {itemsArchiveOn ? (
            <ArchiveList
              type={type}
              itemsArchive={itemsArchive}
              itemsLimit={itemsLimit}
              hasArchive={hasArchive}
              unarchiveItem={unarchiveItem}
              unarchiveItemID={unarchiveItemID}
              unarchivingItem={unarchivingItem}
              openDeleteItem={openDeleteItem}
              deleteItemID={deleteItemID}
              deletingItem={deletingItem}
              fontsLoaded={fontsLoaded}
              itemsSearch={itemsSearch}
              filterCondition={filterCondition}
            />
          ) : null}
        </div>
        {(itemsSearchOn &&
          hasItems &&
          !itemsArchiveOn &&
          itemsFiltered.length === 0) ||
        (itemsSearchOn &&
          hasArchive &&
          itemsArchiveOn &&
          itemsArchiveFiltered.length === 0) ? (
          <div className="items-search-none">
            <i className="icon icon-notification" />
            <p className="error">Nothing matches that search</p>
          </div>
        ) : null}
        {(itemsLimit && itemsArchiveOn && hasArchive === true) ||
        (itemTemplate.hasOwnProperty("version") &&
          (hasItems !== null || hasInvites !== null) &&
          itemsLimit &&
          !itemsArchiveOn) ? (
          <Link to={routes.AccountUpgrade}>
            <div className="items-limit">
              <p>
                <i className="icon icon-notification" />
                You've hit your {label} limit. <span>Upgrade now</span>
              </p>
              <i className="icon icon-cross" onClick={dismissLimit} />
              <ReactBody className="limit" />
            </div>
          </Link>
        ) : null}
      </div>
      <ReactBody className={type} />
    </section>
  );
};

const ZeroItems = ({ type, itemsArchive }) => (
  <div className="items-zero">
    <div className="items-zero-img">
      <img
        src={require("../../images/items-empty.svg").default}
        alt={`no ${type} yet`}
        role="presentation"
      />
    </div>
    <h3>
      {Object.keys(itemsArchive).length > 0
        ? `You have no active ${type}.`
        : `You haven't created any ${type} yet.`}
    </h3>
  </div>
);

const ItemList = ({
  type,
  label,
  items,
  users,
  authUser,
  stepTotal,
  itemInvites,
  itemsLimit,
  editItemInvitation,
  itemInviteStatus,
  itemInviteKey,
  history,
  openShareItemModal,
  manageItemUsers,
  itemsSort,
  itemsCols,
  sortOn,
  openArchiveItemConfirm,
  archiveItemID,
  archivingItem,
  unarchivingItem,
  fontsLoaded,
  itemClick,
  itemsSearch,
  filterCondition,
  duplicateItem,
  duplicateItemID,
  duplicatingItem,
}) => (
  <div
    className={`items-list ${archivingItem ? "archiving" : ""} ${
      duplicatingItem ? "duplicating" : ""
    } ${itemsSearch || itemsSearch === "" ? "searching" : ""}`}
  >
    <div
      className={`item-cards ${sortOn ? "sorted" : ""} ${
        itemsCols === 3
          ? "item-three-col"
          : itemsCols === 1
          ? "item-one-col"
          : "item-two-col"
      }`}
    >
      {itemInvites.length > 0
        ? itemInvites.map((itemInvite) => {
            return (
              <ItemInviteCard
                key={itemInvite.key}
                type={type}
                label={label}
                item={itemInvite}
                itemsLimit={itemsLimit}
                itemInviteAction={editItemInvitation}
                itemInviteStatus={itemInviteStatus}
                itemInviteKey={itemInviteKey}
              />
            );
          })
        : null}
      {Object.keys(items)
        .sort((a, b) => {
          if (itemsSort === "alpha") {
            if (
              (!items[a].name ? "untitled" : items[a].name.toLowerCase()) >
              (!items[b].name ? "untitled" : items[b].name.toLowerCase())
            ) {
              return 1;
            }
            if (
              (!items[a].name ? "untitled" : items[a].name.toLowerCase()) <
              (!items[b].name ? "untitled" : items[b].name.toLowerCase())
            ) {
              return -1;
            }
            return 0;
          } else {
            return new Date(items[b].updated) - new Date(items[a].updated);
          }
        })
        .filter((key) => filterCondition(items[key]))
        .map((key) => (
          <div
            key={key}
            onClick={() => itemClick(key)}
            className={`item-card ${
              items[key].doneList && items[key].doneList.publish
                ? "published"
                : ""
            } ${archiveItemID === key ? "archive" : ""} ${
              duplicateItemID === key ? "duplicate" : ""
            } ${
              !items[key].palette ||
              (!items[key].palette.colors && !items[key].palette.altColors)
                ? "no-palette"
                : ""
            } ${!items[key].images ? "no-images" : ""} ${
              items[key].fonts ? "has-fonts" : ""
            } theme-${items[key].theme ? items[key].theme : "none"}`}
          >
            <h3>{items[key].name}</h3>
            <Menu className="item-menu">
              <Dropdown
                trigger={
                  <div className="item-nav-trigger">
                    <i className="icon icon-ellipsis" />
                  </div>
                }
              >
                <Dropdown.Menu>
                  <span
                    className="item-menu-item menu-item item-edit-item"
                    onClick={(event) => {
                      event.preventDefault();
                      history.push(`${routes[[capString(label)]]}/edit/${key}`);
                    }}
                  >
                    <i className="icon icon-edit" />
                    <span className="menu-text">edit</span>
                  </span>
                  <span
                    className="item-menu-item menu-item item-manage-users-item"
                    onClick={() => manageItemUsers(items[key], key)}
                  >
                    <i className="icon icon-users" />
                    <span className="menu-text">members</span>
                  </span>
                  {items[key].doneList && items[key].doneList.publish ? (
                    <span className="item-menu-publish">
                      <span
                        className="item-menu-item menu-item item-share-item"
                        onClick={() => openShareItemModal(key)}
                      >
                        <i className="icon icon-exit-up" />
                        <span className="menu-text">share</span>
                      </span>
                    </span>
                  ) : null}
                  {!itemsLimit ? (
                    <span
                      className="item-menu-item menu-item item-archive-item"
                      onClick={() => duplicateItem(key)}
                    >
                      <i className="icon icon-copy" />
                      <span className="menu-text">duplicate</span>
                    </span>
                  ) : null}
                  {items[key].admin === authUser.uid ? (
                    <span
                      className="item-menu-item menu-item item-archive-item"
                      onClick={() => openArchiveItemConfirm(key)}
                    >
                      <i className="icon icon-archive" />
                      <span className="menu-text">archive</span>
                    </span>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
            <div className={`item-progress step-${items[key].stepCount}`}>
              <span
                className={`progress-bar positive done-${Math.round(
                  (items[key].stepCount / stepTotal) * 100
                )}`}
              >
                <span className="progress-bar-fill" />
              </span>
            </div>

            {type === "projects" ? (
              <ProjectContent project={items[key]} />
            ) : type === "styleguides" ? (
              <StyleguideContent
                styleguide={items[key]}
                fontsLoaded={fontsLoaded}
              />
            ) : type === "showcases" ? (
              <ShowcaseContent
                id={key}
                showcase={items[key]}
                fontsLoaded={fontsLoaded}
              />
            ) : type === "personas" ? (
              <PersonaContent
                id={key}
                persona={items[key]}
                fontsLoaded={fontsLoaded}
              />
            ) : null}

            <div className="item-footer">
              <div
                className="item-users"
                onClick={(event) => {
                  event.stopPropagation();
                  manageItemUsers(items[key], key);
                }}
              >
                {Object.keys(items[key].users).filter(
                  (user) => items[key].users[user]
                ).length < 4 ? (
                  Object.keys(items[key].users).map((user) =>
                    users[user] && items[key].users[user] === true ? (
                      <div
                        className={`item-user ${
                          authUser.uid === user ? "user-me" : ""
                        } ${!users[user].profileImage ? "no-photo" : ""}`}
                        key={user}
                        title={users[user].username}
                      >
                        <Popup
                          hoverable
                          hideOnScroll
                          className="item-members"
                          position="top center"
                          trigger={
                            <div className="profile-img-wrap">
                              <p className="profile-initials">
                                {findInitials(users[user].username)}
                              </p>
                              {users[user].profileImage ? (
                                <ProgressiveImage
                                  placeholder={require("../../images/loading-md.svg")}
                                  src={users[user].profileImage}
                                  key={users[user].profileImage}
                                >
                                  {(src, loading) => (
                                    <div
                                      className={`profile-img progressive ${
                                        loading ? "loading" : "done"
                                      }`}
                                      style={{
                                        backgroundImage: `url("${src}")`,
                                      }}
                                    />
                                  )}
                                </ProgressiveImage>
                              ) : null}
                            </div>
                          }
                          header={
                            users[user].email.toLowerCase() ===
                            authUser.email.toLowerCase() ? (
                              "Me"
                            ) : (
                              <a
                                href={`mailto:${users[user].email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {users[user].username}
                              </a>
                            )
                          }
                        />
                      </div>
                    ) : null
                  )
                ) : users[authUser.uid] ? (
                  <span className="item-user-wrap large-group">
                    <div
                      className={`item-user user-me ${
                        !users[authUser.uid].profileImage ? "no-photo" : ""
                      }`}
                      title={users[authUser.uid].username}
                    >
                      <Popup
                        hoverable
                        hideOnScroll
                        className="item-members"
                        position="top center"
                        trigger={
                          <div className="profile-img-wrap">
                            <p className="profile-initials">
                              {findInitials(users[authUser.uid].username)}
                            </p>
                            {users[authUser.uid].profileImage ? (
                              <ProgressiveImage
                                placeholder={require("../../images/loading-md.svg")}
                                src={users[authUser.uid].profileImage}
                                key={users[authUser.uid].profileImage}
                              >
                                {(src, loading) => (
                                  <div
                                    className={`profile-img progressive ${
                                      loading ? "loading" : "done"
                                    }`}
                                    style={{ backgroundImage: `url("${src}")` }}
                                  />
                                )}
                              </ProgressiveImage>
                            ) : null}
                          </div>
                        }
                        header={
                          users[authUser.uid].email.toLowerCase() ===
                          authUser.email.toLowerCase() ? (
                            "Me"
                          ) : (
                            <a
                              href={`mailto:${users[authUser.uid].email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {users[authUser.uid].username}
                            </a>
                          )
                        }
                      />
                    </div>
                    <div
                      className="item-user user-count no-photo"
                      title="Additional item members"
                    >
                      <Popup
                        className="item-members"
                        hoverable
                        hideOnScroll
                        position="top center"
                        trigger={
                          <div className="profile-img-wrap">
                            <p className="profile-initials">
                              +
                              {Object.keys(items[key].users).filter(
                                (user) => items[key].users[user]
                              ).length - 1}
                            </p>
                          </div>
                        }
                        header={Object.keys(items[key].users).map((user) =>
                          authUser.uid !== user && items[key].users[user] ? (
                            <span className="item-user-name" key={user}>
                              <a
                                href={`mailto:${users[user].email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {users[user].username}
                              </a>
                            </span>
                          ) : null
                        )}
                      />
                    </div>
                  </span>
                ) : null}
              </div>
              <div className="item-updated-date">
                <label>
                  {items[key].doneList && items[key].doneList.publish
                    ? "Updated"
                    : "Edited"}{" "}
                  <Moment fromNow>{items[key].updated}</Moment>
                </label>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

const ProjectContent = ({ project }) => (
  <div className="project-item-content">
    <div className="item-images">
      {project.images ? (
        <span
          className={`${project.images.length > 2 ? "has-three" : ""} ${
            project.images.length === 2 ? "has-two" : ""
          } ${project.images.length === 1 ? "has-one" : ""}`}
        >
          {project.images[0] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[0].urls
                  ? project.images[0].urls.regular
                  : project.images[0].downloadURL
              }
              key={
                project.images[0].urls
                  ? project.images[0].urls.regular
                  : project.images[0].downloadURL
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-1 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
          {project.images[1] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[1].urls
                  ? project.images[1].urls.small
                  : project.images[1].downloadURL
              }
              key={
                project.images[1].urls
                  ? project.images[1].urls.small
                  : project.images[1].downloadURL
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-2 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
          {project.images[2] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[2].urls
                  ? project.images[2].urls.small
                  : project.images[2].downloadURL
              }
              key={
                project.images[2].urls
                  ? project.images[2].urls.small
                  : project.images[2].downloadURL
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-3 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
        </span>
      ) : (
        <span className="item-image-empty">
          <i className="icon icon-picture" />
        </span>
      )}
    </div>
    <div className="item-palette">
      {project.palette ? (
        <div className="palette">
          <div
            className="color color-primary"
            title={project.palette.colors.primary}
            style={{ backgroundColor: project.palette.colors.primary }}
          />
          <div
            className="color color-secondary"
            title={project.palette.colors.secondary}
            style={{ backgroundColor: project.palette.colors.secondary }}
          />
          <div
            className="color color-accent"
            title={project.palette.colors.accent}
            style={{ backgroundColor: project.palette.colors.accent }}
          />
        </div>
      ) : null}
    </div>
  </div>
);

const StyleguideContent = ({ styleguide, fontsLoaded }) => (
  <div className="styleguide-item-content">
    <div className={`item-logos ${!styleguide.logos ? "no-logo" : ""}`}>
      {styleguide.logos &&
      styleguide.logos[0] &&
      findFileType(styleguide.logos[0]) === "image-embed" ? (
        <ProgressiveImage
          placeholder={require("../../images/loading-md.svg")}
          onError={(error) => console.log("ERROR - Load Image", error)}
          src={styleguide.logos[0].downloadURL}
          key={styleguide.logos[0].downloadURL}
        >
          {(src, loading) => (
            <div
              className={`item-logo progressive ${
                loading ? "loading" : "done"
              } ${styleguide.logos[0].color ? styleguide.logos[0].color : ""}`}
            >
              <img src={src} alt="Primary Logo" />
            </div>
          )}
        </ProgressiveImage>
      ) : (
        <span className="item-logo-empty">
          <i className="icon icon-picture" />
        </span>
      )}
    </div>
    {styleguide.fonts ? (
      <div className="item-fonts">
        {styleguide.fonts[0] && fontsLoaded ? (
          <div
            className="item-font"
            title={
              styleguide.fonts[0].family
                ? styleguide.fonts[0].family
                : styleguide.fonts[0].name
            }
            style={{
              fontFamily: styleguide.fonts[0].family
                ? `"${styleguide.fonts[0].family}"`
                : styleguide.fonts[0].css_stack,
              fontWeight: findNormalFontWeight(styleguide.fonts[0]),
              fontStyle: findNormalFontStyle(styleguide.fonts[0]),
            }}
          >
            {styleguide.fonts[0].type === "upload" ? (
              <style>
                {`@font-face {
                    font-family: "${styleguide.fonts[0].family}";
                    src: url("${styleguide.fonts[0].encode}");
                  }
                `}
              </style>
            ) : null}
            <p>Aa</p>
          </div>
        ) : styleguide.fonts[0] && !fontsLoaded ? (
          <div className="item-font">
            <span className="inline-loading" />
          </div>
        ) : null}
        {styleguide.fonts[1] && fontsLoaded ? (
          <div
            className="item-font"
            title={
              styleguide.fonts[1].family
                ? styleguide.fonts[1].family
                : styleguide.fonts[1].name
            }
            style={{
              fontFamily: styleguide.fonts[1].family
                ? `"${styleguide.fonts[1].family}"`
                : styleguide.fonts[1].css_stack,
              fontWeight: findNormalFontWeight(styleguide.fonts[1]),
              fontStyle: findNormalFontStyle(styleguide.fonts[1]),
            }}
          >
            {styleguide.fonts[1].type === "upload" ? (
              <style>
                {`@font-face {
                    font-family: "${styleguide.fonts[1].family}";
                    src: url("${styleguide.fonts[1].encode}");
                  }
                `}
              </style>
            ) : null}
            <p>Aa</p>
          </div>
        ) : styleguide.fonts[1] && !fontsLoaded ? (
          <div className="item-font">
            <span className="inline-loading" />
          </div>
        ) : null}
      </div>
    ) : null}
    <div className="item-palette">
      {styleguide.palette && styleguide.palette.colors ? (
        <div className="palette">
          <div
            className="color color-primary"
            title={styleguide.palette.colors.primary}
            style={{ backgroundColor: styleguide.palette.colors.primary }}
          />
          <div
            className="color color-secondary"
            title={styleguide.palette.colors.secondary}
            style={{ backgroundColor: styleguide.palette.colors.secondary }}
          />
          <div
            className="color color-accent"
            title={styleguide.palette.colors.accent}
            style={{ backgroundColor: styleguide.palette.colors.accent }}
          />
        </div>
      ) : styleguide.palette &&
        !styleguide.palette.colors &&
        styleguide.palette.altColors ? (
        <div className="palette">
          {styleguide.palette.altColors.map((color, index) =>
            index < 3 ? (
              <div
                className="color"
                title={color.color}
                style={{ backgroundColor: color.color }}
                key={color.id}
              />
            ) : null
          )}
        </div>
      ) : null}
    </div>
  </div>
);

const ShowcaseContent = ({ id, showcase, fontsLoaded }) => (
  <div className="showcase-item-content">
    <div className={`item-screens ${!showcase.screens ? "no-screen" : ""}`}>
      {showcase.screens && showcase.screens[0] ? (
        <div
          className={`item-screen-type screen-${showcase.screens[0].screenType}`}
        >
          <ProgressiveImage
            placeholder={require("../../images/empty.svg")}
            onError={(error) => console.log("ERROR - Load Image", error)}
            src={showcase.screens[0].downloadURL}
            key={showcase.screens[0].downloadURL}
          >
            {(src, loading) => (
              <div
                id={`item-screen-${id}`}
                className={`item-screen progressive ${
                  loading ? "loading" : "done"
                } ${
                  showcase.screens[0].height > showcase.screens[0].width
                    ? "tall"
                    : document.getElementById(`item-screen-${id}`) &&
                      showcase.screens[0].height <
                        document.getElementById(`item-screen-${id}`)
                          .offsetHeight
                    ? "short"
                    : ""
                } ${showcase.screens[0].screenType} ${
                  showcase.screens[0].screenScale && !loading
                    ? `scale-${showcase.screens[0].screenScale}`
                    : ""
                }`}
                style={{
                  backgroundColor:
                    showcase.screens[0].screenType === "background" &&
                    showcase.screens[0].background &&
                    !loading
                      ? showcase.screens[0].background.hex
                      : "inherit",
                }}
              >
                <img
                  src={src}
                  height={showcase.height}
                  width={showcase.width}
                  alt="Primary Showcase"
                />
              </div>
            )}
          </ProgressiveImage>
        </div>
      ) : (
        <span className="item-screen-empty">
          <i className="icon icon-picture" />
        </span>
      )}
    </div>
    {showcase.fonts ? (
      <div className="item-fonts">
        {showcase.fonts[0] && fontsLoaded ? (
          <div
            className="item-font"
            title={
              showcase.fonts[0].family
                ? showcase.fonts[0].family
                : showcase.fonts[0].name
            }
            style={{
              fontFamily: showcase.fonts[0].family
                ? `"${showcase.fonts[0].family}"`
                : showcase.fonts[0].css_stack,
              fontWeight: findNormalFontWeight(showcase.fonts[0]),
              fontStyle: findNormalFontStyle(showcase.fonts[0]),
            }}
          >
            {showcase.fonts[0].type === "upload" ? (
              <style>
                {`@font-face {
                    font-family: "${showcase.fonts[0].family}";
                    src: url("${showcase.fonts[0].encode}");
                  }
                `}
              </style>
            ) : null}
            <p>Aa</p>
          </div>
        ) : showcase.fonts[0] && !fontsLoaded ? (
          <div className="item-font">
            <span className="inline-loading" />
          </div>
        ) : null}
        {showcase.fonts[1] && fontsLoaded ? (
          <div
            className="item-font"
            title={
              showcase.fonts[1].family
                ? showcase.fonts[1].family
                : showcase.fonts[1].name
            }
            style={{
              fontFamily: showcase.fonts[1].family
                ? `"${showcase.fonts[1].family}"`
                : showcase.fonts[1].css_stack,
              fontWeight: findNormalFontWeight(showcase.fonts[1]),
              fontStyle: findNormalFontStyle(showcase.fonts[1]),
            }}
          >
            {showcase.fonts[1].type === "upload" ? (
              <style>
                {`@font-face {
                    font-family: "${showcase.fonts[1].family}";
                    src: url("${showcase.fonts[1].encode}");
                  }
                `}
              </style>
            ) : null}
            <p>Aa</p>
          </div>
        ) : showcase.fonts[1] && !fontsLoaded ? (
          <div className="item-font">
            <span className="inline-loading" />
          </div>
        ) : null}
      </div>
    ) : null}
    <div className="item-palette">
      {showcase.palette && showcase.palette.colors ? (
        <div className="palette">
          <div
            className="color color-primary"
            title={showcase.palette.colors.primary}
            style={{ backgroundColor: showcase.palette.colors.primary }}
          />
          <div
            className="color color-secondary"
            title={showcase.palette.colors.secondary}
            style={{ backgroundColor: showcase.palette.colors.secondary }}
          />
          <div
            className="color color-accent"
            title={showcase.palette.colors.accent}
            style={{ backgroundColor: showcase.palette.colors.accent }}
          />
        </div>
      ) : showcase.palette &&
        !showcase.palette.colors &&
        showcase.palette.altColors ? (
        <div className="palette">
          {showcase.palette.altColors.map((color, index) =>
            index < 3 ? (
              <div
                className="color"
                title={color.color}
                style={{ backgroundColor: color.color }}
                key={color.id}
              />
            ) : null
          )}
        </div>
      ) : null}
    </div>
  </div>
);

const PersonaContent = ({ id, persona, fontsLoaded }) => (
  <div className="persona-item-content"></div>
);

const ArchiveList = ({
  type,
  itemsArchive,
  itemsLimit,
  hasArchive,
  unarchiveItem,
  unarchiveItemID,
  unarchivingItem,
  openDeleteItem,
  deleteItemID,
  deletingItem,
  fontsLoaded,
  itemsSearch,
  filterCondition,
}) => (
  <div
    className={`items-archive ${unarchivingItem ? "unarchiving" : ""} ${
      deletingItem ? "deleting" : ""
    }`}
  >
    {hasArchive ? (
      <div className="archive-list item-cards">
        {Object.keys(itemsArchive)
          .filter((key) => filterCondition(itemsArchive[key]))
          .map((key) => (
            <div
              className={`item-card ${
                unarchiveItemID === key ? "unarchive" : ""
              } ${deleteItemID === key ? "delete" : ""} ${
                !itemsArchive[key].logos ? "no-logo" : ""
              } ${!itemsArchive[key].screens ? "no-screen" : ""} ${
                !itemsArchive[key].palette ||
                (!itemsArchive[key].palette.colors &&
                  !itemsArchive[key].palette.altColors)
                  ? "no-palette"
                  : ""
              } ${!itemsArchive[key].images ? "no-images" : ""} ${
                itemsArchive[key].fonts ? "has-fonts" : ""
              } theme-${
                itemsArchive[key].theme ? itemsArchive[key].theme : "none"
              }`}
              key={key}
            >
              <h3>
                <span>{itemsArchive[key].name}</span>
              </h3>
              <span className="card-delete-wrap">
                <span
                  className="card-delete"
                  onClick={() => openDeleteItem(key)}
                >
                  <i className="icon icon-trash" />
                </span>
              </span>

              {type === "projects" ? (
                <ProjectContent project={itemsArchive[key]} />
              ) : type === "styleguides" ? (
                <StyleguideContent
                  styleguide={itemsArchive[key]}
                  fontsLoaded={fontsLoaded}
                />
              ) : type === "showcases" ? (
                <ShowcaseContent
                  id={key}
                  showcase={itemsArchive[key]}
                  fontsLoaded={fontsLoaded}
                />
              ) : type === "personas" ? (
                <PersonaContent
                  id={key}
                  persona={itemsArchive[key]}
                  fontsLoaded={fontsLoaded}
                />
              ) : null}

              {!itemsLimit ? (
                <div className="item-footer">
                  <div
                    className="btn btn-text"
                    onClick={() => unarchiveItem(key)}
                  >
                    unarchive
                  </div>
                </div>
              ) : (
                <div className="item-footer disabled">
                  <Popup
                    position="top center"
                    className="popup-item-footer"
                    trigger={
                      <div className="btn btn-text">
                        can't unarchive{" "}
                        <i className="icon icon-question-circle" />
                      </div>
                    }
                    header={
                      <span>
                        You've reached your {type} limit. Please upgrade or
                        archive existing {type}.
                      </span>
                    }
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    ) : (
      <div className="items-zero archive">
        <div className="items-zero-img">
          <img
            src={require("../../images/archive-empty.svg").default}
            alt={`no ${type} yet`}
            role="presentation"
          />
        </div>
        <h3>You have no archived {type}.</h3>
      </div>
    )}
  </div>
);

const AddItem = ({ label, addItem, itemsLimit }) => (
  <button
    className="btn btn-icon btn-accent btn-item-add"
    disabled={itemsLimit}
    onClick={addItem}
    title={`New ${label}`}
  >
    <i className="icon icon-plus" />
  </button>
);

export default ItemsList;
