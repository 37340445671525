import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { db, firebase } from "../firebase";
import * as routes from "../constants/routes";

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const { location, history, userMembership, setUserMembership } =
        this.props;
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!authCondition(authUser)) {
          history.push({
            pathname: routes.Login,
            search: history.action === "POP" ? location.pathname : null,
          });
        } else {
          if (!authUser.emailVerified) {
            history.push(routes.Verify);
          } else {
            if (!userMembership) {
              db.getUserMembership(authUser.uid).then((snapshot) => {
                setUserMembership(snapshot.val() ? snapshot.val() : "none");
              });
            }
          }
        }
      });
    }
    render() {
      const { authUser, userMembership, history, match } = this.props;
      return (authUser && userMembership) ||
        (authUser && !authUser.emailVerified) ? (
        <Component history={history} match={match} />
      ) : (
        <div className="content-loading no-body" />
      );
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    userMembership: state.sessionState.userMembership,
  });

  const mapDispatchToProps = (dispatch) => ({
    setUserMembership: (membership) =>
      dispatch({ type: "USER_MEMBERSHIP", membership }),
  });

  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(WithAuthorization);
};

export default withAuthorization;
