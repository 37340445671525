import React, { useState, useRef } from "react";
import TextContent from "../shared/TextContent";
import { db, storage } from "../../firebase";
import Dropzone from "react-dropzone";
import { Popup, Confirm, Menu, Dropdown } from "semantic-ui-react";
import {
  useMountEffect,
  headerText,
  helpText,
  convertFileSize,
  findFileUsage,
  arrayBufferToBase64,
} from "./Resources";
import JSZip from "jszip";
import arrayMove from "array-move";
import _cloneDeep from "lodash.clonedeep";

export const Icons = (props) => {
  const {
    authUser,
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { icons, doneList },
    setItem,
    saveItem,
    killSteps,
    demo,
  } = props;

  const timeout = useRef(null);
  const iconFileUploads = useRef([]);
  const iconFileQuota = authUser.premiumUser ? 52000000 : 21000000;

  const [iconType, setIconType] = useState("icomoon");
  const [badIconFiles, setBadIconFiles] = useState([]);
  const [iconFileUsage, setIconFileUsage] = useState(0);
  const [iconFilesUploading, setIconFilesUploading] = useState(false);
  const [iconFileDeleteOpen, setIconFileDeleteOpen] = useState(false);
  const [iconFileDelete, setIconFileDelete] = useState(false);
  const [iconFileDeleteID, setIconFileDeleteID] = useState(null);
  const [iconFileDeleteFile, setIconFileDeleteFile] = useState(null);
  const [iconReorder, setIconReorder] = useState(false);

  useMountEffect(() => {
    setIconFileUsage(findFileUsage(icons));
    return () => {
      clearTimeout(timeout.current);
      iconFileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const editIcons = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        icons: false,
      },
    });
  };

  const setIconFiles = (iconSet, acceptedFiles, rejectedFiles) => {
    setIconFilesUploading(true);
    let newIconFiles = [];
    let badIconFiles = [];
    let iconFileUsageNew = Number(iconFileUsage);
    let acceptedFilesList = acceptedFiles.map((file, index) => {
      return new Promise((resolve) => {
        if (
          !checkIconsFileMatch(file) &&
          file.size + iconFileUsageNew <= iconFileQuota
        ) {
          iconFileUsageNew += file.size;
          newIconFiles.push({
            file: file,
            name: file.name,
            preview: file.preview,
            size: file.size,
            type: file.type,
            iconSet: iconSet,
            new: true,
          });
          resolve();
        } else {
          if (file.size + iconFileUsageNew > iconFileQuota) {
            badIconFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "File is " +
                convertFileSize(file.size + iconFileUsageNew - iconFileQuota) +
                " too large for your quota",
            });
            resolve();
          } else {
            badIconFiles.push({
              name: file.name,
              size: file.size,
              reason: "File name already exists",
            });
            resolve();
          }
        }
      });
    });
    rejectedFiles.forEach((file, error) => {
      badIconFiles.push({
        name: file.name,
        size: file.size,
        reason:
          file.type !== "application/zip"
            ? "Sorry, you must use a .zip file"
            : file.size > 5000000
            ? "Sorry, each file must be less than 5MB"
            : `Sorry, something went wrong. We're on it.`,
      });
    });
    Promise.all(acceptedFilesList)
      .then(() => {
        setBadIconFiles(badIconFiles);
        setIconFilesUnzip(iconSet, newIconFiles);
      })
      .catch((file) => {
        badIconFiles.push({
          name: file.name,
          size: file.size,
          reason: "The ZIP file is not formatted correctly.",
        });
        setBadIconFiles(badIconFiles);
      });
  };

  const setIconFilesUnzip = (iconSet, newIconFiles) => {
    let badIconFiles = [];
    let newIconFilesList = newIconFiles.map((file, index) => {
      return new Promise((resolve) => {
        let zip = new JSZip();
        zip.loadAsync(file.file).then((unzip) => {
          let data, encode, dataFiles;
          if (iconSet === "icomoon") {
            data = unzip.files["selection.json"];
            encode = unzip.files["fonts/icomoon.ttf"];
          }
          if (iconSet === "fontello") {
            let fontelloName = Object.keys(unzip.files)[0];
            data = unzip.files[`${fontelloName}config.json`];
            encode = unzip.files[`${fontelloName}font/fontello.ttf`];
          }
          if (iconSet === "fontastic") {
            let fontasticFont = "";
            Object.keys(unzip.files).forEach((key) => {
              if (key.indexOf(".ttf") !== -1) {
                fontasticFont = key;
              }
            });
            data = unzip.files["styles.css"];
            encode = unzip.files[fontasticFont];
          }
          if (iconSet === "upload") {
            data = [];
            encode = [];
            dataFiles = [];
            Object.keys(unzip.files).forEach((image) => {
              if (
                unzip.files[image].name.indexOf(".svg") !== -1 &&
                unzip.files[image].name.indexOf("__MACOSX") === -1
              ) {
                data.push(unzip.files[image]);
                let fileName = unzip.files[image].name;
                let folderIndex = fileName.indexOf("/") + 1;
                dataFiles.push(fileName.slice(folderIndex, fileName.length));
              }
            });
          }
          if (!data || !encode) {
            badIconFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "Something went wrong, please make sure you used the right icon type.",
            });
            resolve();
          } else {
            if (iconSet === "icomoon" || iconSet === "fontello") {
              data.async("uint8array").then((dataConvert) => {
                data = new TextDecoder("utf-8").decode(dataConvert);
                newIconFiles[index].data = JSON.parse(data);
                encode.async("uint8array").then((encodeConvert) => {
                  encode = arrayBufferToBase64(encodeConvert);
                  newIconFiles[index].encode = encode;
                  resolve();
                });
              });
            } else if (iconSet === "fontastic") {
              data.async("uint8array").then((dataConvert) => {
                data = new TextDecoder("utf-8").decode(dataConvert);
                data = data.slice(data.indexOf(".icon-"), data.length);
                data = data.replace(/.icon-/g, '"');
                data = data.replace(/:before/g, '":');
                data = data.replace(/;/g, "");
                data = data.replace(/\\/g, "");
                data = data.replace(/}/g, "},");
                data = data.replace(/content/g, '"content"');
                data = data.slice(0, data.length - 2);
                data = `{${data}}`;
                newIconFiles[index].data = JSON.parse(data);
                encode.async("uint8array").then((encodeConvert) => {
                  encode = arrayBufferToBase64(encodeConvert);
                  newIconFiles[index].encode = encode;
                  resolve();
                });
              });
            } else if (iconSet === "upload") {
              let uploadList = data.map((image) => {
                let imageEncode = "";
                return new Promise((resolveUp) => {
                  image.async("uint8array").then((dataConvert) => {
                    imageEncode = arrayBufferToBase64(dataConvert);
                    encode.push(imageEncode);
                    resolveUp();
                  });
                });
              });
              Promise.all(uploadList)
                .then(() => {
                  newIconFiles[index].encode = encode;
                  newIconFiles[index].data = dataFiles;
                  resolve();
                })
                .catch((error) => console.log(error));
            }
          }
        });
      });
    });
    Promise.all(newIconFilesList)
      .then(() => {
        if (badIconFiles.length > 0) {
          setBadIconFiles(badIconFiles);
          badIconFiles.forEach((file) => {
            newIconFiles.splice(
              newIconFiles.map((e) => e.name).indexOf(file.name),
              1
            );
          });
        }
        setIconFilesUploading(newIconFiles.length > 0 ? true : false);
        setItem({ ...item, icons: [...icons, ...newIconFiles] }).then(
          (update) => {
            killSteps(update);
            setIconFilesDone(_cloneDeep(update), newIconFiles);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  const setIconFilesDone = (update, newIconFiles) => {
    let progress = 0;
    let fileCount = update.icons.length - newIconFiles.length;
    let fileDropProgress = document.getElementById("icon-drop-progress");
    let newIconFilesList = newIconFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/icons/${file.name}`)
          : storage.getRef(`${type}/${id}/icons/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        iconFileUploads.current.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fileDropProgress)
              fileDropProgress.style.width =
                (progress / newIconFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              setIconFileUsage(
                iconFileUsage + uploadTask.snapshot.metadata.size
              );
              update.icons[fileCount + index].downloadURL = downloadURL;
              update.icons[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.icons[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.icons[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.icons[fileCount + index].file = null;
              update.icons[fileCount + index].preview = null;
              update.icons[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveIcons(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(newIconFilesList)
      .then(() => {
        iconFileUploads.current = [];
        timeout.current = setTimeout(() => {
          setIconFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fileDropProgress) fileDropProgress.style.width = "0";
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const checkIconsFileMatch = (file) => {
    let hasMatch = false;
    item.icons.forEach((item) => {
      if (item.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const openDeleteIconFile = (id, file) => {
    setIconFileDeleteOpen(true);
    setIconFileDeleteID(id);
    setIconFileDeleteFile(file);
  };

  const cancelDeleteIconFile = (event) => {
    event.preventDefault();
    setIconFileDeleteOpen(false);
    setIconFileDeleteID(null);
    setIconFileDeleteFile(null);
  };

  const deleteIconFile = () => {
    setIconFileDeleteOpen(false);
    setIconFileDelete(true);
    setItem({
      ...item,
      icons: Object.assign([], [...icons], {
        [iconFileDeleteID]: {
          ...icons[iconFileDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(iconFileDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.icons.splice(iconFileDeleteID, 1);
            if (!demo) saveIcons(updateClone);
            killSteps(updateClone);
            setItem(updateClone);
            setIconFileUsage(findFileUsage(updateClone.icons));
            timeout.current = setTimeout(() => {
              setIconFileDeleteID(null);
              setIconFileDelete(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const reorderIcon = (index, direction) => {
    setIconReorder(true);
    const newIndex =
      direction === "down"
        ? index + 1
        : direction === "bottom"
        ? icons.length - 1
        : direction === "up"
        ? index - 1
        : direction === "top"
        ? 0
        : index;
    setItem({ ...item, icons: arrayMove(icons, index, newIndex) }).then(() => {
      const iconItem = document.getElementById(`icon-${newIndex}`);
      if (iconItem) {
        window.scrollTo({
          top:
            iconItem.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            85,
        });
      }
      timeout.current = setTimeout(() => {
        setIconReorder(false);
      }, 500);
    });
  };

  const saveIcons = (update) => {
    let icons = {
      icons: update.icons ? update.icons : [],
    };
    db.saveItem(type, id, icons);
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "icons",
    saveItem: saveItem,
  };

  const dropZoneProps = {
    iconFilesUploading: iconFilesUploading,
    iconFileQuota: iconFileQuota,
    iconFileUsage: iconFileUsage,
    setIconFiles: setIconFiles,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.icons ? "done" : ""
      } ${!icons || icons.length === 0 ? "none-selected" : ""}`}
    >
      <Confirm
        className="confirm"
        open={iconFileDeleteOpen}
        header="Are you sure?"
        content="The file will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteIconFile}
        onConfirm={deleteIconFile}
      />
      <div
        className={`component-section-inner ${iconReorder ? "reorder" : ""} ${
          iconFileDelete ? "deleting" : ""
        }`}
      >
        <h2 className="with-help">
          {headerText.icons[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.icons[label]}</p>}
          />
        </h2>

        <TextContent node={0} {...textContentProps} />

        <div className="type-select-wrap">
          <ul className="type-select-nav">
            {iconTypes.map((type) => (
              <li
                className={iconType === type.id ? "active" : ""}
                onClick={() => setIconType(type.id)}
                key={type.id}
              >
                <span>{type.label}</span>
              </li>
            ))}
          </ul>
          <div className="type-select-content">
            <div
              className={`icons-icomoon type-select-item ${
                iconType === "icomoon" ? "active" : ""
              }`}
            >
              <IconDropZone iconSet="icomoon" {...dropZoneProps} />
            </div>

            <div
              className={`icons-fontello type-select-item ${
                iconType === "fontello" ? "active" : ""
              }`}
            >
              <IconDropZone iconSet="fontello" {...dropZoneProps} />
            </div>

            <div
              className={`icons-fontastic type-select-item ${
                iconType === "fontastic" ? "active" : ""
              }`}
            >
              <IconDropZone iconSet="fontastic" {...dropZoneProps} />
            </div>

            <div
              className={`icons-upload type-select-item ${
                iconType === "upload" ? "active" : ""
              }`}
            >
              <IconDropZone
                iconSet="upload"
                title="Drop a zipped file of .svg icons here or..."
                {...dropZoneProps}
              />
            </div>

            {badIconFiles.length > 0 ? (
              <div className="icons-files-bad files-bad-wrap">
                <span
                  className="close-files-bad"
                  onClick={() => setBadIconFiles([])}
                >
                  <i className="icon icon-cross" />
                </span>
                <label>Sorry, we couldn't upload these files:</label>
                <ul className={`files-bad`}>
                  {Object.keys(badIconFiles).map((key) => (
                    <li key={key} className="file-bad">
                      <p>
                        <span className="file-name">
                          <span className="file-text">
                            {badIconFiles[key].name}
                          </span>
                          <span className="file-size">
                            ({convertFileSize(badIconFiles[key].size)})
                          </span>
                        </span>
                        <span className="file-reason">
                          {badIconFiles[key].reason}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <ul
          className={`files-list cards-list icons-list ${
            iconFileDelete ? "deleting" : ""
          }`}
        >
          {icons.map((icon, index) => (
            <li
              id={`icon-${index}`}
              className={`card selected ${icon.new ? "new" : ""} ${
                !icon.downloadURL ? "loading" : ""
              } ${icon.deleted ? "deleted" : ""}`}
              key={icon.name}
            >
              <div className="icon-content">
                {icon.encode && icon.data && icon.data.length !== 0 ? (
                  <div className="icons-grid">
                    {icon.iconSet !== "upload" ? (
                      <style>
                        {`@font-face {
                            font-family: 'icons-${index}';
                            src: url(data:font/ttf;base64,${icon.encode});
                          }
                          .icons-${index} {
                            font-family: 'icons-${index}' !important
                          }
                        `}
                      </style>
                    ) : null}
                    {icon.iconSet === "icomoon"
                      ? icon.data.icons.map((data, dataIndex) => (
                          <div
                            className="icons-data"
                            key={`icomoon-${index}-${dataIndex}`}
                          >
                            {
                              <style>
                                {`.icons-${index}.data-${dataIndex}.icon-${data.properties.name
                                  .replace(/,/g, "")
                                  .replace(/ /g, ".icon-")}:before {
                                  content: '\\${data.properties.code.toString(
                                    16
                                  )}'
                                }
                              `}
                              </style>
                            }
                            <i
                              className={`icons-${index} data-${dataIndex} icon-${data.properties.name
                                .replace(/,/g, "")
                                .replace(/ /g, " icon-")}`}
                            />
                            <h5>
                              icon-
                              {data.properties.name
                                .replace(/,/g, "")
                                .replace(/ /g, ", icon-")}
                            </h5>
                            <p>
                              character:{" "}
                              <span>\{data.properties.code.toString(16)}</span>
                            </p>
                          </div>
                        ))
                      : icon.iconSet === "fontello"
                      ? icon.data.glyphs.map((data, dataIndex) => (
                          <div
                            className="icons-data"
                            key={`fontello-${index}-${dataIndex}`}
                          >
                            {
                              <style>
                                {`.icons-${index}.data-${dataIndex}.icon-${data.css
                                  .replace(/,/g, "")
                                  .replace(/ /g, ".")}:before {
                                  content: '\\${data.code.toString(16)}'
                                }
                              `}
                              </style>
                            }
                            <i
                              className={`icons-${index} data-${dataIndex} icon-${data.css.replace(
                                /,/g,
                                ""
                              )}`}
                            />
                            <h5>icon-{data.css.replace(/,/g, "")}</h5>
                            <p>
                              character: <span>\{data.code.toString(16)}</span>
                            </p>
                          </div>
                        ))
                      : icon.iconSet === "fontastic"
                      ? Object.keys(icon.data).map((data, dataIndex) => (
                          <div
                            className="icons-data"
                            key={`fontastic-${index}-${dataIndex}`}
                          >
                            {
                              <style>
                                {`.icons-${index}.data-${dataIndex}.icon-${data}:before {
                                  content: '\\${icon.data[data].content}'
                                }
                              `}
                              </style>
                            }
                            <i
                              className={`icons-${index} data-${dataIndex} icon-${data}`}
                            />
                            <h5>icon-{data}</h5>
                            <p>
                              character: <span>\{icon.data[data].content}</span>
                            </p>
                          </div>
                        ))
                      : icon.iconSet === "upload"
                      ? Object.keys(icon.data).map((data, dataIndex) => (
                          <div
                            className="icons-data"
                            key={`upload-${index}-${dataIndex}`}
                            title={icon.data[data]}
                          >
                            <img
                              src={`data:image/svg+xml;base64,${icon.encode[data]}`}
                              alt={icon.data[data]}
                            />
                            <p>{icon.data[data]}</p>
                          </div>
                        ))
                      : null}
                  </div>
                ) : (icon.iconSet === "upload" && !icon.data) ||
                  (icon.iconSet === "upload" &&
                    icon.data &&
                    icon.data.length === 0) ? (
                  <div className="icons-upload-nodata">
                    <h3 className="error">
                      <i className="icon icon-notification" /> No SVG's found in
                      your .ZIP file
                    </h3>
                  </div>
                ) : (
                  <div className="inline-loading icon-loading" />
                )}
                {icon.downloadURL ? (
                  <span>
                    {icon.downloadURL ? (
                      <a
                        href={icon.downloadURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="card-download">
                          <i className="icon icon-download" />
                        </span>
                      </a>
                    ) : null}
                    {icons.length > 1 ? (
                      <span className="card-reorder-wrap">
                        <Menu className="card-reorder">
                          <Dropdown
                            item
                            className="center"
                            trigger={
                              <div className="card-reorder-trigger">
                                <i className="icon icon-move" />
                              </div>
                            }
                          >
                            <Dropdown.Menu>
                              {index < icons.length - 1 ? (
                                <span
                                  className="link menu-item card-reorder-item reorder-down"
                                  onClick={() => reorderIcon(index, "down")}
                                >
                                  <i className="icon icon-arrow-left" />
                                  Move down
                                </span>
                              ) : null}
                              {index < icons.length - 1 ? (
                                <span
                                  className="link menu-item card-reorder-item reorder-bottom"
                                  onClick={() => reorderIcon(index, "bottom")}
                                >
                                  <i className="icon icon-arrow-bottom" />
                                  Move bottom
                                </span>
                              ) : null}
                              {index > 0 ? (
                                <span
                                  className="link menu-item card-reorder-item reorder-up"
                                  onClick={() => reorderIcon(index, "up")}
                                >
                                  <i className="icon icon-arrow-left" />
                                  Move up
                                </span>
                              ) : null}
                              {index > 0 ? (
                                <span
                                  className="link menu-item card-reorder-item reorder-top"
                                  onClick={() => reorderIcon(index, "top")}
                                >
                                  <i className="icon icon-arrow-top" />
                                  Move top
                                </span>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Menu>
                      </span>
                    ) : null}
                    {icon.downloadURL ? (
                      <span className="card-delete-wrap">
                        <span
                          className="card-delete"
                          onClick={(event) => openDeleteIconFile(index, icon)}
                        >
                          <i className="icon icon-trash" />
                        </span>
                      </span>
                    ) : null}
                  </span>
                ) : null}
                <span className="file-detail">
                  <p className="file-detail-content">
                    <span className="file-text">{icon.name}</span>
                    <span className="file-size">
                      {convertFileSize(icon.size)}
                    </span>
                  </p>
                </span>
              </div>
            </li>
          ))}
        </ul>

        <p className="section-empty">No item icons, click here to add them.</p>

        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={() => doneStep("icons", step)}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editIcons}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

const iconTypes = [
  {
    id: "icomoon",
    label: "Icomoon",
  },
  {
    id: "fontello",
    label: "Fontello",
  },
  {
    id: "fontastic",
    label: "Fontastic",
  },
  {
    id: "upload",
    label: `SVG's`,
  },
];

const IconDropZone = ({
  iconSet,
  title,
  iconFilesUploading,
  iconFileQuota,
  iconFileUsage,
  setIconFiles,
}) => (
  <Dropzone
    style={{}}
    accept="application/zip"
    maxSize={5000000}
    disabled={iconFilesUploading}
    className={`icons-files-dropzone files-dropzone ${
      iconFilesUploading ? "uploading" : ""
    }`}
    activeClassName="active"
    onDrop={(acceptedFiles, rejectedFiles) =>
      setIconFiles(iconSet, acceptedFiles, rejectedFiles)
    }
  >
    <div className="icons-files-drop files-drop">
      {title ? (
        <h4>{title}</h4>
      ) : (
        <h4>
          Drop your <span>{iconSet}</span> zip file here or...
        </h4>
      )}
      <div className="btn btn-primary">click to upload</div>
      <div className="icons-files-usage files-usage">
        <label>
          <i className="icon icon-notification" />
          You have {convertFileSize(iconFileQuota - iconFileUsage)} left in your{" "}
          {convertFileSize(iconFileQuota)} icon quota{" "}
          {iconSet === "upload" ? " -- 5MB limit on zip files" : ""}
        </label>
        <div
          className={`progress-bar negative done-${Math.round(
            (iconFileUsage / iconFileQuota) * 100
          )} ${(iconFileUsage / iconFileQuota) * 100 > 100 ? "burst" : ""}`}
        >
          <span
            className="progress-bar-fill"
            style={{ width: `${(iconFileUsage / iconFileQuota) * 100}%` }}
          />
        </div>
      </div>
      <span className="files-drop-msg">
        <i className="icon icon-dropzone" />
        <p>Drop files here</p>
      </span>
      <span className="files-drop-progress">
        <span
          id={`icon-drop-progress-${iconSet}`}
          className="drop-progress-bar"
        />
        <span className="upload-loading" />
      </span>
    </div>
  </Dropzone>
);

export default Icons;
