import React, { useState, useRef } from "react";
import { Confirm } from "semantic-ui-react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  ItemColor,
  ItemBoxShadow,
  ItemFont,
  ItemFontStyle,
  ItemFontSize,
  ItemPadding,
  ItemBorderStyle,
  ItemBorderWidth,
  ItemBorderRadius,
  ItemCSS,
} from "../Elements";
import tinycolor from "tinycolor2";
import shortid from "shortid";
import _cloneDeep from "lodash.clonedeep";

export const ButtonsElement = (props) => {
  const {
    sections,
    borderStyles,
    findFontVal,
    findFontVariants,
    findStyleVal,
    findColorVal,
    updateColor,
    updateFont,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item,
    item: { elements, fonts, palette, doneList },
    setItem,
    getInitButtons,
  } = props;

  const [buttonSortOn, setButtonSortOn] = useState(false);
  const [restoreDefaultsOpen, setRestoreDefaultsOpen] = useState(false);
  const [customButtonDeleteOpen, setCustomButtonDeleteOpen] = useState(false);
  const [customButtonToDelete, setCustomButtonToDelete] = useState("");
  const [buttonsOn, setButtonsOn] = useState({});

  const timeout = useRef(null);

  const updateButtonName = (index, update) => {
    setItem({
      ...item,
      elements: {
        ...elements,
        buttons: {
          ...elements.buttons,
          list: Object.assign(
            [],
            { ...elements.buttons.list },
            {
              [index]: {
                ...elements.buttons.list[index],
                name: update.replace(/[^\w\s]/gi, ""),
              },
            }
          ),
        },
      },
    });
  };

  const onSortButtonStart = () => {
    clearTimeout(timeout.current);
    setButtonSortOn(true);
  };

  const onSortButtonEnd = ({ oldIndex, newIndex }) => {
    setItem({
      ...item,
      elements: {
        ...elements,
        buttons: {
          ...elements.buttons,
          list: arrayMove(elements.buttons.list, oldIndex, newIndex),
        },
      },
    });
    timeout.current = setTimeout(() => {
      setButtonSortOn(false);
    }, 500);
  };

  const editButton = (id, type) => {
    const buttonsOnCopy = { ...buttonsOn };
    const buttonsIndex = Object.keys(buttonsOnCopy).indexOf(id);
    if (
      buttonsIndex === -1 ||
      (buttonsIndex !== -1 && buttonsOnCopy[id].type !== type)
    ) {
      buttonsOnCopy[id] = { type: type };
    } else {
      delete buttonsOnCopy[id];
    }
    setButtonsOn(buttonsOnCopy);
  };

  const addCustomButton = () => {
    const buttonIndex = elements.buttons.list
      ? elements.buttons.list.length
      : 0;
    const customButton = {
      id: shortid.generate(),
      background:
        palette.colors && palette.colors.primary
          ? palette.colors.primary
          : "#2b2b2b",
      borderColor:
        palette.colors && palette.colors.primary
          ? palette.colors.primary
          : "#2b2b2b",
      boxShadowColor: elements.buttons.boxShadowColor,
      color: "#ffffff",
      name: `Custom ${buttonIndex + 1}`,
      states: {
        active: {
          background:
            palette.colors && palette.colors.primary
              ? tinycolor(palette.colors.primary).lighten(10).toHexString()
              : "#4b4b4b",
          borderColor:
            palette.colors && palette.colors.primary
              ? tinycolor(palette.colors.primary).lighten(10).toHexString()
              : "#4b4b4b",
          boxShadowColor: elements.buttonsActive.boxShadowColor,
          color: "#ffffff",
        },
        disabled: {
          background:
            palette.colors && palette.colors.primary
              ? tinycolor(palette.colors.primary).setAlpha(0.25).toRgbString()
              : "rgba(0,0,0,0.25)",
          borderColor: "rgba(0,0,0,0)",
          boxShadowColor:
            elements.buttonsDisabled && elements.buttonsDisabled.boxShadowColor,
          color: "#2b2b2b",
        },
        hover: {
          background:
            palette.colors && palette.colors.primary
              ? tinycolor(palette.colors.primary).darken(10).toHexString()
              : "#101010",
          borderColor:
            palette.colors && palette.colors.primary
              ? tinycolor(palette.colors.primary).darken(10).toHexString()
              : "#101010",
          boxShadowColor: elements.buttonsHover.boxShadowColor,
          color: "#ffffff",
        },
      },
    };
    setItem({
      ...item,
      elements: {
        ...elements,
        buttons: {
          ...elements.buttons,
          list: Object.assign(
            [],
            { ...elements.buttons.list },
            { [buttonIndex]: customButton }
          ),
        },
      },
    });
    editButton(customButton.id, "normal");
    timeout.current = setTimeout(() => {
      const button = document.getElementById("button-text-" + buttonIndex);
      if (button) button.focus();
    });
  };

  const openDeleteCustomButton = (id) => {
    setCustomButtonDeleteOpen(true);
    setCustomButtonToDelete(id);
  };

  const cancelDeleteCustomButton = () => {
    setCustomButtonDeleteOpen(false);
    setCustomButtonToDelete("");
  };

  const deleteCustomButton = () => {
    const elementsClone = _cloneDeep(elements);
    if (elementsClone.buttons.list.length > 1) {
      elementsClone.buttons.list.splice(customButtonToDelete, 1);
      setItem({ ...item, elements: elementsClone });
      cancelDeleteCustomButton();
    }
  };

  const getButtonPos = (index, type) => {
    if (window.innerWidth < 568) {
      const buttonNormal = document.getElementById(`btn-normal-${index}`);
      const buttonHover = document.getElementById(`btn-hover-${index}`);
      const buttonActive = document.getElementById(`btn-active-${index}`);
      const buttonDisabled = document.getElementById(`btn-disabled-${index}`);
      const marginHeight = 18;
      if (buttonNormal && buttonHover && buttonActive && buttonDisabled) {
        if (type === "normal") {
          return `-${
            buttonHover.offsetHeight +
            buttonActive.offsetHeight +
            buttonDisabled.offsetHeight +
            marginHeight * 4
          }px`;
        } else if (type === "hover") {
          return `-${
            buttonActive.offsetHeight +
            buttonDisabled.offsetHeight +
            marginHeight * 3
          }px`;
        } else if (type === "active") {
          return `-${buttonDisabled.offsetHeight + marginHeight * 2}px`;
        } else if (type === "disabled") {
          return `-${marginHeight}px`;
        } else {
          return "0px";
        }
      } else {
        return "0px";
      }
    } else {
      return "0px";
    }
  };

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const fontSizeProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  const borderProps = {
    elements: elements,
    updateStyle: updateStyle,
    borderStyles: borderStyles,
  };

  const boxShadowProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateStyle: updateStyle,
    updateColor: updateColor,
  };

  return (
    <div
      id="button-element"
      className={`element element-buttons ${
        sections.buttons.active ? "active" : ""
      } ${sections.buttons.css ? "css" : ""} ${
        elements.buttons.disabled ? "disabled" : ""
      }`}
    >
      <div
        className={`element-content ${buttonSortOn ? "sorting" : ""} ${
          elements.buttons.list && elements.buttons.list.length === 1
            ? "one-btn"
            : ""
        }`}
        onClick={() => toggleSection("buttons", true)}
      >
        <style>
          {`
            .element-button-edit {
              border-radius: ${elements.buttons.borderRadius};
              border-style: ${elements.buttons.borderStyle};
              border-width: ${elements.buttons.borderWidth};
            }
            #elements .element-content .btn, .button-drag .btn {
              border-radius: ${elements.buttons.borderRadius};
              border-style: ${elements.buttons.borderStyle};
              border-width: ${elements.buttons.borderWidth};
              box-shadow: ${elements.buttons.boxShadow} ${findColorVal(
            "buttons",
            "boxShadowColor"
          )};
              color: ${findColorVal("buttons", "color")};
              font-family: ${findFontVal("buttons", "fontFamily")};
              font-size: ${elements.buttons.fontSize};
              font-style: ${elements.buttons.fontStyle};
              font-weight: ${elements.buttons.fontWeight};
              padding: ${elements.buttons.padding};
              transition: all ease 0.33s;
            }
            #elements .element-content .btn:hover, #elements .element-content .btn.hover, .button-drag .btn:hover, .button-drag .btn.hover {
              box-shadow: ${elements.buttonsHover.boxShadow} ${findColorVal(
            "buttonsHover",
            "boxShadowColor"
          )};
            }
            #elements .element-content .btn:active, #elements .element-content .btn.active, .button-drag .btn:active, .button-drag .btn.active {
              box-shadow: ${elements.buttonsActive.boxShadow} ${findColorVal(
            "buttonsActive",
            "boxShadowColor"
          )};
            }
          `}
        </style>

        <SortableList
          elements={elements}
          findColorVal={findColorVal}
          editButton={editButton}
          updateButtonName={updateButtonName}
          openDeleteCustomButton={openDeleteCustomButton}
          buttonsOn={buttonsOn}
          updateColor={updateColor}
          getButtonPos={getButtonPos}
          palette={palette}
          colorProps={colorProps}
          helperClass={"item-drag button-drag"}
          useDragHandle={true}
          distance={window.innerWidth > 1024 ? 5 : 0}
          pressDelay={window.innerWidth <= 1024 ? 200 : 0}
          axis={"xy"}
          onSortStart={({ node, index, collection }, event) => {
            event.preventDefault();
            onSortButtonStart();
          }}
          onSortEnd={onSortButtonEnd}
        />

        {sections.buttons.active ? (
          <div className="buttons-custom">
            <span className="link" onClick={addCustomButton}>
              <i className="icon icon-plus-circle" />
              add custom button
            </span>
          </div>
        ) : null}

        {sections.buttons.active &&
        elements.buttons.list &&
        palette.colors &&
        doneList.palette ? (
          <div className="buttons-restore">
            <span className="link" onClick={() => setRestoreDefaultsOpen(true)}>
              <i className="icon icon-revert" />
              restore defaults
            </span>
          </div>
        ) : null}
      </div>

      {sections.buttons.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>All buttons font and styles</label>

            <div className="edit-item-sub">
              <ItemFont item={"buttons"} {...fontProps} />

              <ItemFontStyle item={"buttons"} {...fontStyleProps} />

              <ItemFontSize item={"buttons"} {...fontSizeProps} />

              <ItemPadding item="buttons" {...defaultProps} />
            </div>

            <div className="edit-item-sub">
              <ItemBorderStyle item="buttons" {...borderProps} />

              <ItemBorderWidth item="buttons" {...defaultProps} />

              <ItemBorderRadius item="buttons" {...defaultProps} />
            </div>

            <div className="edit-item-sub">
              <ItemBoxShadow
                item={"buttons"}
                label={"box shadow"}
                {...boxShadowProps}
              />

              <ItemBoxShadow
                item={"buttonsHover"}
                label={"hover shadow"}
                {...boxShadowProps}
              />

              <ItemBoxShadow
                item={"buttonsActive"}
                label={"active shadow"}
                {...boxShadowProps}
              />

              <ItemBoxShadow
                item={"buttonsDisabled"}
                label={"disabled shadow"}
                {...boxShadowProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.buttons.active && elements.buttons.list ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("buttons")}>
          <span>{sections.buttons.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.buttons.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.buttons}
              label={`button`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.buttonsHover}
              label={`button:hover`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.buttonsActive}
              label={`button:active`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.buttonsDisabled}
              label={`button[disabled]`}
              findStyleVal={findStyleVal}
            />

            {elements.buttons.list.map((button, index) => (
              <span key={`button-${index}`}>
                <ItemCSS
                  item={button}
                  label={`button.${button.name.toLowerCase()}`}
                  shadow={elements.buttons}
                  findStyleVal={findStyleVal}
                />

                <ItemCSS
                  item={button.states.hover}
                  label={`button.${button.name.toLowerCase()}:hover`}
                  shadow={elements.buttonsHover}
                  findStyleVal={findStyleVal}
                />

                <ItemCSS
                  item={button.states.active}
                  label={`button.${button.name.toLowerCase()}:active`}
                  shadow={elements.buttonsActive}
                  findStyleVal={findStyleVal}
                />

                <ItemCSS
                  item={button.states.disabled}
                  label={`button.${button.name.toLowerCase()}[disabled]`}
                  shadow={elements.buttonsDisabled}
                  findStyleVal={findStyleVal}
                />
              </span>
            ))}
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("buttons")}>
          <i className="icon icon-edit" />
        </span>
      </div>

      <Confirm
        className="confirm"
        open={restoreDefaultsOpen}
        header="Are you sure?"
        content="All of your buttons will return to the default state and styles."
        cancelButton="nevermind"
        confirmButton="yes, restore"
        onCancel={() => setRestoreDefaultsOpen(false)}
        onConfirm={() => {
          setItem({
            ...item,
            elements: {
              ...elements,
              buttons: {
                ...elements.buttons,
                list: getInitButtons(),
              },
            },
          });
          setRestoreDefaultsOpen(false);
        }}
      />
      <Confirm
        className="confirm"
        open={customButtonDeleteOpen}
        header="Are you sure?"
        content="This button and styles will be permanently removed."
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteCustomButton}
        onConfirm={deleteCustomButton}
      />
    </div>
  );
};

const DragHandle = SortableHandle(() => (
  <div className="element-button-drag">
    <div className="element-drag-icon" />
  </div>
));

const SortableList = SortableContainer(
  ({
    elements,
    findColorVal,
    editButton,
    updateButtonName,
    openDeleteCustomButton,
    buttonsOn,
    updateColor,
    getButtonPos,
    palette,
    colorProps,
  }) => (
    <div className="buttons-list">
      {elements.buttons &&
      elements.buttons.list &&
      elements.buttons.list.length > 0 ? (
        elements.buttons.list.map((button, index) => (
          <SortableItem
            key={button.id}
            index={index}
            disabled={false}
            buttonContent={
              <div
                className={`element-button-wrap ${
                  buttonsOn[button.id] ? buttonsOn[button.id].type : ""
                }`}
              >
                <div id={`btn-group-${index}`} className="btn-group">
                  <style>
                    {`
                  .element-button-edit.edit-btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}:before {
                    border-bottom-color: ${
                      findColorVal(`buttons.list[${index}]`, "borderColor") !==
                      "rgba(0,0,0,0)"
                        ? findColorVal(`buttons.list[${index}]`, "borderColor")
                        : findColorVal(`buttons.list[${index}]`, "background")
                    }; 
                  }
                  .element-button-edit.edit-btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}.hover:before {
                    border-bottom-color: ${
                      findColorVal(
                        `buttons.list[${index}].states.hover`,
                        "borderColor"
                      ) !== "rgba(0,0,0,0)"
                        ? findColorVal(
                            `buttons.list[${index}].states.hover`,
                            "borderColor"
                          )
                        : findColorVal(
                            `buttons.list[${index}].states.hover`,
                            "background"
                          )
                    };
                  }
                  .element-button-edit.edit-btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}.active:before {
                    border-bottom-color: ${
                      findColorVal(
                        `buttons.list[${index}].states.active`,
                        "borderColor"
                      ) !== "rgba(0,0,0,0)"
                        ? findColorVal(
                            `buttons.list[${index}].states.active`,
                            "borderColor"
                          )
                        : findColorVal(
                            `buttons.list[${index}].states.active`,
                            "background"
                          )
                    };
                  }
                  .element-button-edit.edit-btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}.disabled:before {
                    border-bottom-color: ${
                      findColorVal(
                        `buttons.list[${index}].states.disabled`,
                        "borderColor"
                      ) !== "rgba(0,0,0,0)"
                        ? findColorVal(
                            `buttons.list[${index}].states.disabled`,
                            "borderColor"
                          )
                        : findColorVal(
                            `buttons.list[${index}].states.disabled`,
                            "background"
                          )
                    };
                  }
                  #elements .element-content .btn.btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}, .button-drag .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}, .element-button-edit.edit-btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} {
                    background: ${findColorVal(
                      `buttons.list[${index}]`,
                      "background"
                    )};
                    border-color: ${findColorVal(
                      `buttons.list[${index}]`,
                      "borderColor"
                    )};
                    box-shadow: ${elements.buttons.boxShadow} ${findColorVal(
                      `buttons.list[${index}]`,
                      "boxShadowColor"
                    )};
                    color: ${findColorVal(`buttons.list[${index}]`, "color")};
                  }
                  #elements .element-content .btn.btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}:hover, #elements .element-content .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.hover, .button-drag .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.hover, .element-button-edit.edit-btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.hover {
                    background: ${findColorVal(
                      `buttons.list[${index}].states.hover`,
                      "background"
                    )};
                    border-color: ${findColorVal(
                      `buttons.list[${index}].states.hover`,
                      "borderColor"
                    )};
                    box-shadow: ${
                      elements.buttonsHover.boxShadow
                    } ${findColorVal(
                      `buttons.list[${index}].states.hover`,
                      "boxShadowColor"
                    )};
                    color: ${findColorVal(
                      `buttons.list[${index}].states.hover`,
                      "color"
                    )};
                  }
                  #elements .element-content .btn.btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}:active, #elements .element-content .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.active, .button-drag .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.active, .element-button-edit.edit-btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.active {
                    background: ${findColorVal(
                      `buttons.list[${index}].states.active`,
                      "background"
                    )};
                    border-color: ${findColorVal(
                      `buttons.list[${index}].states.active`,
                      "borderColor"
                    )};
                    box-shadow: ${
                      elements.buttonsActive.boxShadow
                    } ${findColorVal(
                      `buttons.list[${index}].states.active`,
                      "boxShadowColor"
                    )};
                    color: ${findColorVal(
                      `buttons.list[${index}].states.active`,
                      "color"
                    )};
                  }
                  #elements .element-content .btn.btn-${button.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}.disabled, .button-drag .btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.disabled, .element-button-edit.edit-btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}.disabled {
                    background: ${findColorVal(
                      `buttons.list[${index}].states.disabled`,
                      "background"
                    )};
                    border-color: ${findColorVal(
                      `buttons.list[${index}].states.disabled`,
                      "borderColor"
                    )};
                    box-shadow: ${
                      elements.buttonsDisabled.boxShadow
                    } ${findColorVal(
                      `buttons.list[${index}].states.disabled`,
                      "boxShadowColor"
                    )};
                    color: ${findColorVal(
                      `buttons.list[${index}].states.disabled`,
                      "color"
                    )};
                  }
                  #elements .element-content .btn-group-${index}
                `}
                  </style>

                  <DragHandle />

                  <button
                    id={`btn-normal-${index}`}
                    className={`btn btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} normal`}
                    onClick={(event) => {
                      event.preventDefault();
                      editButton(button.id, "normal");
                    }}
                  >
                    {button.name}
                  </button>

                  <button
                    id={`btn-hover-${index}`}
                    className={`btn btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} hover`}
                    onClick={(event) => {
                      event.preventDefault();
                      editButton(button.id, "hover");
                    }}
                  >
                    Hover
                  </button>
                  <button
                    id={`btn-active-${index}`}
                    className={`btn btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} active`}
                    onClick={(event) => {
                      event.preventDefault();
                      editButton(button.id, "active");
                    }}
                  >
                    Active
                  </button>
                  <button
                    id={`btn-disabled-${index}`}
                    className={`btn btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} disabled`}
                    onClick={(event) => {
                      event.preventDefault();
                      editButton(button.id, "disabled");
                    }}
                  >
                    Disabled
                  </button>

                  <div
                    className="element-button-delete"
                    onClick={() => openDeleteCustomButton(index)}
                  >
                    <i className="icon icon-cross" />
                  </div>
                </div>

                <div className="element-button-edit-wrap">
                  <div
                    id={`element-button-normal-${index}`}
                    className={`element-button-edit edit-btn-${button.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()} normal`}
                    style={{ marginTop: getButtonPos(index, "normal") }}
                  >
                    <div className="edit-item-input">
                      <input
                        id={`button-text-${index}`}
                        type="text"
                        value={button.name}
                        onChange={(event) =>
                          updateButtonName(index, event.target.value)
                        }
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            document
                              .getElementById(`button-text-${index}`)
                              .blur();
                          }
                        }}
                      />
                    </div>

                    <ItemColor
                      type="color"
                      item={`buttons.list[${index}]`}
                      label="font color"
                      {...colorProps}
                    />

                    <ItemColor
                      type="background"
                      item={`buttons.list[${index}]`}
                      label="bg color"
                      {...colorProps}
                    />

                    <ItemColor
                      type="borderColor"
                      item={`buttons.list[${index}]`}
                      label="border color"
                      {...colorProps}
                    />

                    <ItemColor
                      type="boxShadowColor"
                      item={`buttons.list[${index}]`}
                      label="shadow color"
                      {...colorProps}
                    />
                  </div>

                  {Object.keys(button.states).map((state) => (
                    <div
                      id={`element-button-${state}-${index}`}
                      className={`element-button-edit edit-btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()} ${state}`}
                      style={{ marginTop: getButtonPos(index, state) }}
                      key={`${button.name}-${state}`}
                    >
                      <ItemColor
                        type="color"
                        item={`buttons.list[${index}].states[${state}]`}
                        label="font color"
                        {...colorProps}
                      />

                      <ItemColor
                        type="background"
                        item={`buttons.list[${index}].states[${state}]`}
                        label="bg color"
                        {...colorProps}
                      />

                      <ItemColor
                        type="borderColor"
                        item={`buttons.list[${index}].states[${state}]`}
                        label="border color"
                        {...colorProps}
                      />

                      <ItemColor
                        type="boxShadowColor"
                        item={`buttons.list[${index}].states[${state}]`}
                        label="shadow color"
                        {...colorProps}
                      />
                    </div>
                  ))}
                </div>
              </div>
            }
          />
        ))
      ) : (
        <h3>No buttons added yet</h3>
      )}
    </div>
  )
);

const SortableItem = SortableElement(({ buttonContent }) => (
  <div className="element-button">{buttonContent}</div>
));
