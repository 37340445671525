import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// DEV
// TODO - can we hide these keys somewhere in the Cloud?
const configDev = {
  apiKey: "AIzaSyABwprEYIMQptU_fS9exz0ouBEkgHyl0n0",
  authDomain: "designgapp-dev.firebaseapp.com",
  databaseURL: "https://designgapp-dev.firebaseio.com",
  projectId: "designgapp-dev",
  storageBucket: "designgapp-dev.appspot.com",
  messagingSenderId: "983130018001",
  appId: "1:983130018001:web:18c61449b2e8e88a55f5f0",
};

// PROD
// TODO - can we hide these keys somewhere in the Cloud?
const configProd = {
  apiKey: "AIzaSyC_sK_Gmh-oH1TmoYHIw-4LSeWI3ysTAaI",
  authDomain: "designgapp-prod.firebaseapp.com",
  databaseURL: "https://designgapp-prod.firebaseio.com",
  projectId: "designgapp-prod",
  storageBucket: "designgapp-prod.appspot.com",
  messagingSenderId: "296709900858",
  appId: "1:296709900858:web:025dc90cff22b4a22f8303",
  measurementId: "G-8D7CQMXPY3",
};

const appEnv = process.env.REACT_APP_ENV;
const config = appEnv === "production" ? configProd : configDev;
const demoConfig =
  appEnv === "production"
    ? "gs://designgapp-demos"
    : "gs://designgapp-dev-demos";

export const app = initializeApp(config);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const storageDemos = getStorage(app, demoConfig);
export const functions = getFunctions(app);
export const sendContactEmail = httpsCallable(functions, "sendContactEmail");
export const sendWelcomeEmail = httpsCallable(functions, "sendWelcomeEmail");
export const sendJoinAppInvitation = httpsCallable(
  functions,
  "sendJoinAppInvitation"
);
export const sendItemInvitation = httpsCallable(
  functions,
  "sendItemInvitation"
);
export const sendJoinTeamInvitation = httpsCallable(
  functions,
  "sendJoinTeamInvitation"
);
export const sendTeamLeadChange = httpsCallable(
  functions,
  "sendTeamLeadChange"
);
export const sendOwnerChange = httpsCallable(functions, "sendOwnerChange");
export const updateUserEmail = httpsCallable(functions, "updateUserEmail");
export const inviteUser = httpsCallable(functions, "inviteUser");
export const removeUser = httpsCallable(functions, "removeUser");
export const inviteNewUser = httpsCallable(functions, "inviteNewUser");
export const removeNewUser = httpsCallable(functions, "removeNewUser");
export const changeAdmin = httpsCallable(functions, "changeAdmin");
export const inviteMember = httpsCallable(functions, "inviteMember");
export const removeMember = httpsCallable(functions, "removeMember");
export const inviteNewMember = httpsCallable(functions, "inviteNewMember");
export const removeNewMember = httpsCallable(functions, "removeNewMember");
export const checkUserInvites = httpsCallable(functions, "checkUserInvites");
export const joinTeam = httpsCallable(functions, "joinTeam");
export const declineJoinTeam = httpsCallable(functions, "declineJoinTeam");
export const changeTeamLead = httpsCallable(functions, "changeTeamLead");
export const updateMemberStatus = httpsCallable(
  functions,
  "updateMemberStatus"
);
export const submitNewSubscription = httpsCallable(
  functions,
  "submitNewSubscription"
);
export const submitUpdateSubscription = httpsCallable(
  functions,
  "submitUpdateSubscription"
);
export const submitNewCard = httpsCallable(functions, "submitNewCard");
export const confirmJoinItem = httpsCallable(functions, "confirmJoinItem");
export const deleteUser = httpsCallable(functions, "deleteUser");
export const deleteItem = httpsCallable(functions, "deleteItem");
export const duplicateItemFiles = httpsCallable(
  functions,
  "duplicateItemFiles"
);
export const updateStripeCustomerEmail = httpsCallable(
  functions,
  "updateStripeCustomerEmail"
);
export const findStripeProration = httpsCallable(
  functions,
  "findStripeProration"
);
export const retrieveStripeCoupon = httpsCallable(
  functions,
  "retrieveStripeCoupon"
);
export const saveDemoFiles = httpsCallable(functions, "saveDemoFiles");
