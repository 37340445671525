import React, { useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { Item } from "components/shared/Item";
import { useMountEffect } from "./Resources";
import ReactBody from "react-body";
import * as routes from "constants/routes";

export const ItemShare = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  const { match, history } = props;
  const [type, setType] = useState(null);
  const [label, setLabel] = useState(null);
  const [itemID, setItemID] = useState(null);

  useMountEffect(() => {
    const typeIndex =
      match.path.indexOf("/p/") !== -1
        ? "projects"
        : match.path.indexOf("/s/") !== -1
        ? "styleguides"
        : "showcases";
    db.findItemID(typeIndex, match.params.shareID).then((snapshot) => {
      if (snapshot.val() !== null) {
        let itemID = Object.keys(snapshot.val())[0];
        let matchedID = "none";
        if (snapshot.val()[itemID].share.active) {
          matchedID = itemID;
        } else {
          matchedID = "locked";
        }
        setType(typeIndex);
        setLabel(typeIndex.slice(0, -1));
        setItemID(matchedID);
      } else {
        history.push(routes.NotFound);
      }
    });
  });

  return type && label && itemID ? (
    <span className="item-share-outer">
      {itemID && itemID !== "none" && itemID !== "locked" ? (
        <Item
          history={history}
          share={true}
          type={type}
          label={label}
          itemID={itemID}
          authUser={authUser}
        />
      ) : itemID === "locked" ? (
        <div className="content-outer">
          <div className="page-locked">
            <i className="icon icon-large icon-lock" />
            <h1>Sorry, this {type.slice(0, -1)} is not public.</h1>
          </div>
        </div>
      ) : itemID === "none" ? (
        <div className="content-outer ">
          <div className="page-not-found">
            <i className="icon icon-large icon-notification" />
            <h1>Sorry, we couldn't find that {type.slice(0, -1)}.</h1>
          </div>
        </div>
      ) : null}
    </span>
  ) : (
    <ReactBody className="content-full" />
  );
};

export default ItemShare;
