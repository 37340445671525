import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { db, auth, firebase } from "../../firebase";
import * as routes from "constants/routes";

class AuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { history } = this.props;
    const mode = this.getParameterByName("mode");
    const actionCode = this.getParameterByName("oobCode");
    const continueUrl = this.getParameterByName("continueUrl");
    switch (mode) {
      case "resetPassword":
        auth.doSignOut();
        this.handleResetPassword(auth, actionCode);
        break;
      case "recoverEmail":
        auth.doSignOut();
        this.handleRecoverEmail(auth, actionCode);
        break;
      case "verifyEmail":
        this.handleVerifyEmail(auth, actionCode, continueUrl);
        break;
      default:
        history.push(routes.NotFound);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  handleResetPassword = (auth, actionCode) => {
    auth
      .doPasswordResetCode(actionCode)
      .then((email) => {
        this.setState({
          email: email,
          modeSuccess: "pass-reset",
          actionCode: actionCode,
        });
      })
      .catch((error) =>
        this.setState({
          modeSuccess: "error",
          error: error,
        })
      );
  };

  handleRecoverEmail = (auth, actionCode) => {
    auth
      .doCheckActionCode(actionCode)
      .then((info) => {
        const oldEmail = info.data.previousEmail;
        const restoredEmail = info.data.email;
        // TODO - should this lookup be exposed or be a Function?
        db.lookupUserByEmail(oldEmail.toLowerCase()).then((user) => {
          const userID = Object.keys(user.val())[0];
          firebase.updateUserEmail({
            uid: userID,
            email: restoredEmail,
            verified: true,
          });
          firebase.updateStripeCustomerEmail({
            uid: userID,
            email: restoredEmail,
          });
          auth.doApplyActionCode(actionCode).then(() => {
            this.setState({
              email: restoredEmail,
              modeSuccess: "email-recover-success",
            });
          });
        });
      })
      .catch((error) =>
        this.setState({
          modeSuccess: "error",
          error: error,
        })
      );
  };

  handleVerifyEmail = (auth, actionCode, continueUrl) => {
    auth
      .doCheckActionCode(actionCode)
      .then((info) => {
        const lowercaseEmail = info.data.email.toLowerCase();
        auth
          .doApplyActionCode(actionCode)
          .then((response) => {
            // TODO - should this lookup be exposed or be a Function?
            db.lookupUserByEmail(lowercaseEmail).then((user) => {
              const userID = Object.keys(user.val())[0];
              const userVerified = user.val()[userID].verified;
              firebase.updateUserEmail({
                uid: userID,
                email: info.data.email,
                verified: true,
              });
              this.userEmailCheck(
                userID,
                lowercaseEmail,
                userVerified,
                continueUrl
              );
            });
          })
          .catch((error) =>
            this.setState({
              modeSuccess: "error",
              error: error,
            })
          );
      })
      .catch((error) =>
        this.setState({
          modeSuccess: "error",
          error: error,
        })
      );
  };

  userEmailCheck = (userID, email, verified, continueUrl) => {
    const { history } = this.props;
    if (verified === "new user") {
      firebase
        .checkUserInvites({ email: email, uid: userID })
        .then((invites) => {
          firebase.sendWelcomeEmail({ email: email });
          this.setState(
            {
              modeSuccess: "email-verify-success",
              continueUrl: continueUrl ? continueUrl : "",
              invites: invites.data,
            },
            () => {
              if (firebase.auth.currentUser) {
                firebase.auth.currentUser.reload();
              }
              if (continueUrl) {
                this.timeout = window.setTimeout(() => {
                  history.push(
                    continueUrl.replace("https://www.designgapp.com/", "")
                  );
                }, 5000);
              }
            }
          );
        });
    } else {
      this.setState(
        {
          modeSuccess: "email-verify-success",
          continueUrl: continueUrl ? continueUrl : "",
          invites: false,
        },
        () => {
          firebase.auth.currentUser.reload();
          if (continueUrl) {
            this.timeout = window.setTimeout(() => {
              history.push(
                continueUrl.replace("https://www.designgapp.com/", "")
              );
            }, 5000);
          }
        }
      );
    }
  };

  onEmailResetSubmit = (event) => {
    event.preventDefault();
    const { actionCode, passwordOne, email } = this.state;
    this.setState(
      {
        actionStatus: true,
      },
      () => {
        auth
          .doPasswordResetConfirm(actionCode, passwordOne)
          .then(() => {
            this.setState(
              {
                actionStatus: false,
                modeSuccess: "pass-reset-success",
              },
              () => {
                auth.doSignInWithEmailAndPassword(email, passwordOne);
              }
            );
          })
          .catch((error) => {
            this.setState({ error: error });
          });
      }
    );
  };

  render() {
    const {
      passwordOne,
      passwordTwo,
      email,
      modeSuccess,
      actionStatus,
      continueUrl,
      invites,
      error,
    } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <section id="auth" className="content-outer">
        {modeSuccess === "error" ? (
          <div className="auth-content auth-error">
            <i className="icon icon-cross-circle" />
            <h1>Sorry, something went wrong</h1>
            <p>{error.message}</p>
          </div>
        ) : modeSuccess === "pass-reset" ? (
          <div className="auth-content auth-pass-reset">
            <h1>Reset Password</h1>
            <form id="form-pass" onSubmit={this.onEmailResetSubmit}>
              <input
                autoFocus
                value={passwordOne}
                onChange={(event) =>
                  this.setState({ passwordOne: event.target.value })
                }
                type="password"
                placeholder="New Password"
              />
              <input
                value={passwordTwo}
                onChange={(event) =>
                  this.setState({ passwordTwo: event.target.value })
                }
                type="password"
                placeholder="Confirm New Password"
              />
              <button disabled={isInvalid} type="submit">
                {!actionStatus ? (
                  "reset password"
                ) : (
                  <span className="btn-loading" />
                )}
              </button>
              {error && <p className="error">{error.message}</p>}
            </form>
          </div>
        ) : modeSuccess === "pass-reset-success" ? (
          <div className="auth-content auth-success">
            <i className="icon icon-checkmark-circle" />
            <h1>Your password has been reset!</h1>
            <div className="invite-btns">
              <Link to={routes.Projects}>
                <button className="btn btn-secondary">projects</button>
              </Link>
              <Link to={routes.Styleguides}>
                <button className="btn btn-primary">styleguides</button>
              </Link>
            </div>
            <Link to={routes.Account}>
              <button className="btn btn-text">my account</button>
            </Link>
          </div>
        ) : // TODO: I don't think we need this one... don't see it referenced anywhere?
        modeSuccess === "email-recover" ? (
          <div className="auth-content auth-email-recover">
            <h1>Recover Email</h1>
            <form className="form-email" onSubmit={this.onEmailResetSubmit}>
              <input
                autoFocus
                value={email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
                type="email"
                placeholder="Enter your email"
              />
              <button disabled={isInvalid} type="submit">
                {!actionStatus ? (
                  "recover email"
                ) : (
                  <span className="btn-loading" />
                )}
              </button>
              {error && <p className="error">{error.message}</p>}
            </form>
          </div>
        ) : modeSuccess === "email-recover-success" ? (
          <div className="auth-content auth-success">
            <i className="icon icon-checkmark-circle" />
            <h1>Your email has been recovered!</h1>
            <p>
              {email} has been restored. If you didn't initiate an update, you
              should change your password to protect your account.
            </p>
            <Link to={`${routes.Login}?${routes.PasswordChange}`}>
              <button className="btn btn-primary">change your password</button>
            </Link>
            <br />
            <Link to={`${routes.Login}?${routes.Account}`}>
              <button className="btn btn-text">my account</button>
            </Link>
          </div>
        ) : modeSuccess === "email-verify-success" ? (
          <div className="auth-content auth-success">
            <i className="icon icon-checkmark-circle" />
            <h1>Your email has been verified!</h1>
            {continueUrl ? (
              <span className="email-redirect">
                <p>
                  You'll be redirected to your project in 5 seconds.{" "}
                  <Link
                    to={continueUrl.replace("https://www.designgapp.com/", "")}
                  >
                    Or click here.
                  </Link>
                </p>
              </span>
            ) : null}
            {invites && invites.projects ? (
              <div className="invite-wrap">
                <p className="invite-text">
                  You've been invited to{" "}
                  {invites.projects === 1 ? "a project" : "projects"}!
                </p>
                <Link to={routes.Projects}>
                  <button className="btn btn-secondary">projects</button>
                </Link>
              </div>
            ) : null}
            {invites && invites.styleguides ? (
              <div className="invite-wrap">
                <p className="invite-text">
                  You've been invited to{" "}
                  {invites.styleguides === 1 ? "a styleguide" : "styleguides"}!
                </p>
                <Link to={routes.Styleguides}>
                  <button className="btn btn-accent">styleguides</button>
                </Link>
              </div>
            ) : null}
            {invites && invites.showcases ? (
              <div className="invite-wrap">
                <p className="invite-text">
                  You've been invited to{" "}
                  {invites.showcases === 1 ? "a showcase" : "showcases"}!
                </p>
                <Link to={routes.Showcases}>
                  <button className="btn btn-tertiary">showcases</button>
                </Link>
              </div>
            ) : null}
            {!invites ? (
              <div className="invite-btns">
                <Link to={routes.Projects}>
                  <button className="btn btn-secondary">projects</button>
                </Link>
                <Link to={routes.Styleguides}>
                  <button className="btn btn-accent">styleguides</button>
                </Link>
                <Link to={routes.Showcases}>
                  <button className="btn btn-tertiary">showcases</button>
                </Link>
              </div>
            ) : null}
            <Link to={routes.Account}>
              <button className="btn btn-text">my account</button>
            </Link>
          </div>
        ) : (
          <div className="content-loading no-header" />
        )}
      </section>
    );
  }
}

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  email: "",
  modeSuccess: "",
  continueUrl: "",
  invites: false,
  actionCode: null,
  actionStatus: false,
  error: null,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps))(AuthPage);
