import React, { useState, useRef } from "react";
import { db, storage } from "../../firebase";
import TextContent from "../shared/TextContent";
import ProgressiveImage from "react-progressive-image";
import Dropzone from "react-dropzone";
import { Popup, Confirm } from "semantic-ui-react";
import {
  useMountEffect,
  headerText,
  helpText,
  convertFileSize,
  findFileType,
  findFileUsage,
} from "./Resources";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import tinycolor from "tinycolor2";
import getColors from "image-pal-canvas/lib/hsluv";
import _cloneDeep from "lodash.clonedeep";

export const Logos = (props) => {
  const {
    authUser,
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { logos, doneList },
    setItem,
    saveItem,
    killSteps,
    demo,
  } = props;

  const timeout = useRef(null);
  const logoFileUploads = useRef([]);
  const logoFileQuota = authUser.premiumUser ? 52000000 : 21000000;

  const [badLogoFiles, setBadLogoFiles] = useState([]);
  const [logoFileUsage, setLogoFileUsage] = useState(0);
  const [logoFilesUploading, setLogoFilesUploading] = useState(false);
  const [logoFileDeleteOpen, setLogoFileDeleteOpen] = useState(false);
  const [logoDeleteID, setLogoDeleteID] = useState(null);
  const [logoDeleteFile, setLogoDeleteFile] = useState("");
  const [logoSortOn, setLogoSortOn] = useState(false);
  const [logoDelete, setLogoDelete] = useState(false);

  useMountEffect(() => {
    setLogoFileUsage(findFileUsage(logos));
    return () => {
      clearTimeout(timeout.current);
      logoFileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const editLogos = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        logos: false,
      },
    });
  };

  const setLogoFiles = (files) => {
    setLogoFilesUploading(true);
    let newLogoFiles = [];
    let badLogoFiles = [];
    let logoFileUsageNew = Number(logoFileUsage);
    let logoFilesList = files.map((file) => {
      return new Promise((resolve) => {
        if (
          !checkLogosFileMatch(file) &&
          (file.type !== "" || file.name.indexOf(".") !== -1) &&
          file.size + logoFileUsageNew <= logoFileQuota
        ) {
          logoFileUsageNew += file.size;
          let fileImg = new Image();
          let _URL = window.URL || window.webkitURL;
          if (findFileType(file) === "image-embed") {
            let logoPalette = [];
            let darkCount = 0;
            let whiteCount = 0;
            getColors(
              {
                srcUrl: file.preview,
                order: "density",
                mean: true,
                hasAlpha: true,
                maxColors: 5,
                minDensity: 0.03333,
              },
              (err, colors) => {
                if (colors && colors.length > 0) {
                  colors.forEach((color, index) => {
                    if (color.distance > 0) {
                      if (
                        logoPalette.map((e) => e.hex).indexOf(color.hex) === -1
                      ) {
                        logoPalette.push({ hex: color.hex });
                      }
                      if (tinycolor(color.hex).getBrightness() > 240) {
                        whiteCount += color.density;
                      } else {
                        darkCount += color.density;
                      }
                    }
                  });
                } else {
                  colors = [];
                }
                fileImg.src = _URL.createObjectURL(file);
                fileImg.onload = () => {
                  newLogoFiles.push({
                    file: file,
                    name: file.name,
                    preview: file.preview,
                    size: file.size,
                    type: file.type,
                    new: true,
                    height: fileImg.height,
                    width: fileImg.width,
                    color: whiteCount >= darkCount ? "white" : "dark",
                    palette: logoPalette,
                  });
                  resolve();
                };
              }
            );
          } else {
            newLogoFiles.push({
              file: file,
              name: file.name,
              preview: file.preview,
              size: file.size,
              type: file.type,
              new: true,
            });
            resolve();
          }
        } else {
          if (file.size + logoFileUsageNew > logoFileQuota) {
            badLogoFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "File is " +
                convertFileSize(file.size + logoFileUsageNew - logoFileQuota) +
                " too large for your quota",
            });
            resolve();
          } else if (file.type === "" && file.name.indexOf(".") === -1) {
            badLogoFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, folders are not supported",
            });
            resolve();
          } else {
            badLogoFiles.push({
              name: file.name,
              size: file.size,
              reason: "File name already exists",
            });
            resolve();
          }
        }
      });
    });
    Promise.all(logoFilesList)
      .then(() => {
        setBadLogoFiles(badLogoFiles);
        setLogoFilesUploading(newLogoFiles.length > 0 ? true : false);
        setItem({ ...item, logos: [...logos, ...newLogoFiles] }).then(
          (update) => {
            killSteps(update);
            setLogoFilesDone(_cloneDeep(update), newLogoFiles);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  const setLogoFilesDone = (update, newLogoFiles) => {
    let progress = 0;
    let fileCount = update.logos.length - newLogoFiles.length;
    let fileDropProgress = document.getElementById("logo-drop-progress");
    let logoFileList = newLogoFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/logos/${file.name}`)
          : storage.getRef(`${type}/${id}/logos/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        logoFileUploads.current.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fileDropProgress)
              fileDropProgress.style.width =
                (progress / newLogoFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              setLogoFileUsage(
                logoFileUsage + uploadTask.snapshot.metadata.size
              );
              update.logos[fileCount + index].downloadURL = downloadURL;
              update.logos[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.logos[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.logos[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.logos[fileCount + index].file = null;
              update.logos[fileCount + index].preview = null;
              update.logos[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveLogos(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(logoFileList)
      .then(() => {
        logoFileUploads.current = [];
        timeout.current = setTimeout(() => {
          setLogoFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fileDropProgress) fileDropProgress.style.width = "0";
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const saveLogos = (update) => {
    let logos = {
      logos: update.logos ? update.logos : [],
    };
    db.saveItem(type, id, logos);
  };

  const checkLogosFileMatch = (file) => {
    let hasMatch = false;
    logos.forEach((logo) => {
      if (logo.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const openDeleteLogoFile = (id, file) => {
    setLogoFileDeleteOpen(true);
    setLogoDeleteID(id);
    setLogoDeleteFile(file);
  };

  const cancelDeleteLogoFile = (event, id, file) => {
    event.preventDefault();
    setLogoFileDeleteOpen(false);
    setLogoDeleteID(null);
    setLogoDeleteFile(null);
  };

  const deleteLogoFile = () => {
    setLogoFileDeleteOpen(false);
    setLogoDelete(true);
    setItem({
      ...item,
      logos: Object.assign([], [...logos], {
        [logoDeleteID]: {
          ...logos[logoDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(logoDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.logos.splice(logoDeleteID, 1);
            setItem(updateClone);
            if (!demo) saveLogos(updateClone);
            killSteps(updateClone);
            setLogoFileUsage(findFileUsage(updateClone.logos));
            timeout.current = setTimeout(() => {
              setLogoDeleteID(null);
              setLogoDelete(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onSortLogoStart = () => {
    clearTimeout(timeout.current);
    setLogoSortOn(true);
  };

  const onSortLogoEnd = ({ oldIndex, newIndex }) => {
    setItem({ ...item, logos: arrayMove(logos, oldIndex, newIndex) });
    timeout.current = setTimeout(() => setLogoSortOn(false), 500);
  };

  const findDropHeight = (element) => {
    if (element) {
      document.getElementById("logo-primary-drop").style.height =
        element.clientHeight + "px";
    }
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "logos",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.logos ? "done" : ""
      } ${!logos || logos.length === 0 ? "none-selected" : ""}`}
    >
      <Confirm
        className="confirm"
        open={logoFileDeleteOpen}
        header="Are you sure?"
        content="The file will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteLogoFile}
        onConfirm={deleteLogoFile}
      />
      <div
        className={`component-section-inner ${logoSortOn ? "sorting" : ""} ${
          logoDelete ? "deleting" : ""
        }`}
      >
        <span className={`logos-list ${logos.length > 0 ? "active" : ""}`}>
          <h2 className="with-help">
            {headerText.logos[label]}
            <Popup
              hoverable
              hideOnScroll
              position="top right"
              className="popup-help"
              trigger={
                <div className="header-help">
                  <i className="icon icon-question-circle" />
                </div>
              }
              header={<p>{helpText.logos[label]}</p>}
            />
          </h2>

          <TextContent node={0} {...textContentProps} />

          <div
            className={`logos-section logos-files ${
              !logos || logos.length === 0 ? "empty" : ""
            }`}
          >
            {doneList && !doneList.logos ? (
              <Dropzone
                style={{}}
                disabled={logoFilesUploading}
                className={`logos-files-dropzone files-dropzone ${
                  logoFilesUploading ? "uploading" : ""
                }`}
                activeClassName="active"
                onDrop={(files) => setLogoFiles(files)}
              >
                <div className="logos-files-drop files-drop">
                  <h4>Drop your logo files here or...</h4>
                  <div className="btn btn-primary">click to upload</div>
                  <div className="logos-files-usage files-usage">
                    <label>
                      <i className="icon icon-notification" />
                      You have {convertFileSize(
                        logoFileQuota - logoFileUsage
                      )}{" "}
                      left in your {convertFileSize(logoFileQuota)} logo quota
                    </label>
                    <div
                      className={`progress-bar negative done-${Math.round(
                        (logoFileUsage / logoFileQuota) * 100
                      )} ${
                        (logoFileUsage / logoFileQuota) * 100 > 100
                          ? "burst"
                          : ""
                      }`}
                    >
                      <span
                        className="progress-bar-fill"
                        style={{
                          width: `${(logoFileUsage / logoFileQuota) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                  <span className="files-drop-msg">
                    <i className="icon icon-dropzone" />
                    <p>Drop files here</p>
                  </span>
                  <span className="files-drop-progress">
                    <span
                      id="logo-drop-progress"
                      className="drop-progress-bar"
                    />
                    <span className="upload-loading" />
                  </span>
                </div>
              </Dropzone>
            ) : null}
            {badLogoFiles.length > 0 ? (
              <div className="logos-files-bad files-bad-wrap">
                <span
                  className="close-files-bad"
                  onClick={() => setBadLogoFiles([])}
                >
                  <i className="icon icon-cross" />
                </span>
                <label>Sorry, we couldn't upload these files:</label>
                <ul className={`files-bad`}>
                  {Object.keys(badLogoFiles).map((key) => (
                    <li key={key} className="file-bad">
                      <p>
                        <span className="file-name">
                          <span className="file-text">
                            {badLogoFiles[key].name}
                          </span>
                          <span className="file-size">
                            ({convertFileSize(badLogoFiles[key].size)})
                          </span>
                        </span>
                        <span className="file-reason">
                          {badLogoFiles[key].reason}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {logos ? (
              <SortableList
                logos={logos}
                convertFileSize={convertFileSize}
                findFileType={findFileType}
                openDeleteLogoFile={openDeleteLogoFile}
                findDropHeight={findDropHeight}
                helperClass={"item-drag logo-drag"}
                distance={window.innerWidth > 1024 ? 5 : 0}
                pressDelay={window.innerWidth <= 1024 ? 200 : 0}
                axis={"xy"}
                onSortStart={({ node, index, collection }, event) => {
                  event.preventDefault();
                  onSortLogoStart();
                }}
                onSortEnd={onSortLogoEnd}
              />
            ) : null}
          </div>
        </span>
        <p className="section-empty">No logos added, click here to add them.</p>

        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={() => doneStep("logos", step)}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editLogos}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

const SortableList = SortableContainer(
  ({
    logos,
    convertFileSize,
    findFileType,
    openDeleteLogoFile,
    findDropHeight,
  }) => (
    <ul className="files-list cards-list sortable">
      {logos.map((logo, index) => (
        <SortableItem
          key={logo.name}
          index={index}
          logo={logo}
          disabled={logo.new}
          logoContent={
            <div
              className={`file-content logo-content ${
                logo.color ? logo.color : ""
              }`}
              title={logo.name}
            >
              {findFileType(logo) === "image-embed" ? (
                <div className="file-img">
                  {logo.downloadURL ? (
                    <ProgressiveImage
                      placeholder={require("../../images/loading-md.svg")}
                      src={logo.downloadURL}
                      key={logo.downloadURL}
                    >
                      {(src, loading) => (
                        <div
                          className={`file-img-bg progressive ${
                            loading ? "loading" : "done"
                          }`}
                          style={{ backgroundImage: `url("${src}")` }}
                        />
                      )}
                    </ProgressiveImage>
                  ) : null}
                  {logo.preview ? (
                    <div
                      className="file-img-preview progressive done"
                      style={{ backgroundImage: `url('${logo.preview}')` }}
                      alt={`preview ${logo.name}`}
                    />
                  ) : null}
                </div>
              ) : (
                <div className={`file-icon ${findFileType(logo)}`}>
                  <i className="icon icon-file-empty" />
                </div>
              )}
              {logo.downloadURL ? (
                <span>
                  <span className="card-download-wrap">
                    <a
                      href={logo.downloadURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="card-download">
                        <i className="icon icon-download" />
                      </span>
                    </a>
                  </span>
                  <span
                    className="card-delete-wrap"
                    onClick={() => openDeleteLogoFile(index, logo)}
                  >
                    <span className="card-delete">
                      <i className="icon icon-trash" />
                    </span>
                  </span>
                </span>
              ) : (
                <span className="file-loading" />
              )}
              <span className="file-detail">
                <p className="file-detail-content">
                  <span className="file-text">{logo.name}</span>
                  <span className="file-size">
                    {convertFileSize(logo.size)}
                  </span>
                </p>
              </span>
            </div>
          }
        />
      ))}
      {logos.length > 1 ? (
        <span className="logo-primary-drop card">
          <p>Primary Logo</p>
        </span>
      ) : null}
    </ul>
  )
);

const SortableItem = SortableElement(({ logo, logoContent }) => (
  <li
    className={`card selected ${logo.new ? "new" : ""} ${
      !logo.downloadURL ? "loading" : ""
    } ${logo.deleted ? "deleted" : ""}`}
  >
    {logoContent}
  </li>
));

export default Logos;
