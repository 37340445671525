import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import ReactBody from "react-body";

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;

    this.setState(
      {
        emailSending: true,
      },
      () => {
        auth
          .doPasswordReset(email)
          .then(() => {
            this.setState(() => ({
              error: null,
              emailSent: true,
              emailSending: false,
            }));
          })
          .catch((error) => {
            this.setState({ error: error });
          });
      }
    );
  };

  render() {
    const { email, error, emailSent, emailSending } = this.state;

    const isInvalid = email === "";

    return (
      <div className="form-wrap">
        {emailSent ? (
          <div className="auth-verify">
            <i className="icon icon-paper-plane" />
            <h1>Please check your email.</h1>
            <p>
              An email with a link to reset your password was sent to: {email}.
            </p>
          </div>
        ) : (
          <form id="form-pass" onSubmit={this.onSubmit}>
            <input
              autoFocus
              value={email}
              onChange={(event) => this.setState({ email: event.target.value })}
              type="text"
              placeholder="Email Address"
            />
            <button
              className="btn btn-primary"
              disabled={isInvalid}
              type="submit"
            >
              {!emailSending ? (
                <span>reset my password</span>
              ) : (
                <span className="btn-loading" />
              )}
            </button>
            {error && <p className="error">{error.message}</p>}
          </form>
        )}
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: "",
  error: null,
  emailSent: false,
  emailSending: false,
};

const PasswordForgetPage = ({ history }) => (
  <section id="pass-forget" className="content-outer">
    <h1>Password Reset</h1>
    <PasswordForgetForm history={history} />
    <span
      className="btn btn-text auth-bottom-link"
      onClick={() => {
        history.goBack();
      }}
    >
      go back
    </span>
    <ReactBody className="pass-forget" />
  </section>
);

const PasswordForgetLink = () => (
  <p>
    <Link to="/pass-forget">Forgot password?</Link>
  </p>
);

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink, PasswordForgetPage };
