import React, { useState, useEffect, useRef, useCallback } from "react";
import TextContent from "./TextContent";
import ProgressiveImage from "react-progressive-image";
import Sticky from "react-sticky-el";
import { db, storage } from "../../firebase";
import { Popup, Modal, Confirm } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  useMountEffect,
  headerText,
  helpText,
  convertFileSize,
  findFileUsage,
} from "./Resources";
import Dropzone from "react-dropzone";
import _cloneDeep from "lodash.clonedeep";

export const Images = (props) => {
  const {
    authUser,
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { name, images, adjectives, doneList },
    setItem,
    saveItem,
    itemTemplate,
    killSteps,
    demo,
  } = props;

  const timeout = useRef(null);
  const imageFileUploads = useRef([]);
  const imageFileQuota = authUser.premiumUser ? 52000000 : 21000000;
  const imagesPerPage = 30;
  const imagesMaxPages = 10;
  const imagesSearchPerPage = 30;
  const imagesPage = useRef(1);
  const imagesSearchPage = useRef(1);

  const [badImageFiles, setBadImageFiles] = useState([]);
  const [imageFileUsage, setImageFileUsage] = useState(0);
  const [imageFilesUploading, setImageFilesUploading] = useState(false);
  const [imageFileDeleteOpen, setImageFileDeleteOpen] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [imagesPageTotal, setImagesPageTotal] = useState(0);
  const [imageFeaturedModal, setImageFeaturedModal] = useState("");
  const [imageDelete, setImageDelete] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageFeaturedType, setImageFeaturedType] = useState(null);
  const [imageDeleteID, setImageDeleteID] = useState(null);
  const [imageDeleteFile, setImageDeleteFile] = useState("");
  const [imagesSearchQuery, setImagesSearchQuery] = useState("");
  const [imagesSearchTotal, setImagesSearchTotal] = useState(0);
  const [imagesSearchPageTotal, setImagesSearchPageTotal] = useState(0);
  const [imagesSearchList, setImagesSearchList] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [imagesSearchOn, setImagesSearchOn] = useState(false);
  const [imageSortOn, setImageSortOn] = useState(false);
  const [imagesError, setImagesError] = useState(false);
  const [imagesAdvanced, setImagesAdvanced] = useState(false);

  useMountEffect(() => {
    findImages([]);
    setImageFileUsage(findFileUsage(images));
    return () => {
      clearTimeout(timeout.current);
      imageFileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const editImages = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        images: false,
      },
    });
  };

  const findImages = useCallback(
    (imagesList) => {
      let collectionIDs = [];
      let collectionNames = [];
      let fetchURL = "";
      let fetchType = "";
      return new Promise((resolve) => {
        if (adjectives && itemTemplate) {
          collectionIDs.push("1808514");
          itemTemplate.sections.adjectives.forEach((adjective) => {
            if (adjectives.indexOf(adjective.value) !== -1) {
              collectionIDs.push(adjective.id);
              collectionNames.push(adjective.value);
              if (adjective.altValues) {
                collectionNames.push(adjective.altValues[0]);
              }
            }
          });
          if (collectionNames.length > 0) {
            if (name) {
              collectionNames.push(name);
            }
            // TODO: This is what we want (below) BUT the collections param is broken so it does a general search, not specific to the collection ID's listed
            // fetchURL = `https://api.unsplash.com/search/photos?page=${imagesPage.current}&per_page=${imagesPerPage}&collections=${collectionIDs}&query=${collectionNames}&content_filter=high&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`;

            // TODO: this is wrong (see above)
            fetchURL = `https://api.unsplash.com/photos/random?count=${imagesPerPage}&collections=${collectionIDs[0]}&query=${name}&content_filter=high&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`;
            fetchType = "search";
          } else {
            fetchURL = `https://api.unsplash.com/photos/random?count=${imagesPerPage}&collections=${collectionIDs[0]}&content_filter=high&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`;
            fetchType = "collection";
          }
        } else {
          fetchURL = `https://api.unsplash.com/photos?page=${imagesPage.current}&per_page=${imagesPerPage}&order_by=popular&content_filter=high&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`;
          fetchType = "curated";
        }
        fetch(fetchURL)
          .then((res) => res.json())
          .then((data) => {
            if (fetchType === "search") {
              // FIX
              // imagesList.push(...data.results);
              imagesList.push(...data);
            } else {
              imagesList.push(...data);
            }
            setImagesList(imagesList);
            // FIX
            setImagesPageTotal(
              fetchType === "search"
                ? /*data.total_pages*/ 5
                : fetchType === "collection"
                ? 1
                : 5
            );
            setImagesLoading(true);
            timeout.current = setTimeout(() => setImagesLoading(false), 500);
          })
          .catch((error) => {
            setImagesList([]);
            setImagesError(true);
            setImagesLoading(false);
            console.log("Error happened during fetching!", error);
          });
      });
    },
    [name, adjectives, itemTemplate, imagesPage, imagesPerPage]
  );

  useEffect(() => {
    if (adjectives && adjectives.length > 0 && doneList.adjectives) {
      imagesPage.current = 1;
      findImages([]);
    }
  }, [adjectives, doneList.adjectives, findImages]);

  const findImagesSearch = (query, reset) => {
    if (query) {
      const imagesSearchListCopy = reset ? [] : [...imagesSearchList];
      setImagesSearchOn(true);
      fetch(
        `https://api.unsplash.com/search/photos/?page=${imagesSearchPage.current}&per_page=${imagesSearchPerPage}&query=${query}&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results.length > 0) {
            imagesSearchListCopy.push(...data.results);
            setImagesLoading(true);
            setImagesSearchList(imagesSearchListCopy);
            setImagesSearchTotal(data.total);
            setImagesSearchPageTotal(data.total_pages);
            timeout.current = setTimeout(() => setImagesLoading(false), 500);
          } else {
            setImagesSearchList("empty");
            setImagesSearchTotal(0);
            setImagesSearchPageTotal(0);
            setImagesLoading(false);
          }
        })
        .catch((err) => {
          console.log("Error happened during fetching!", err);
          setImagesSearchList([]);
          setImagesError(true);
          setImagesLoading(false);
        });
    }
  };

  const addImage = (image, event) => {
    image.deleted = null;
    fetch(
      `${image.links.download_location}?client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`
    ).catch((err) => {
      console.log("Error happened during fetching!", err);
    });
    if (imageModalOpen) {
      setImageModalOpen(false);
    }
    setItem({ ...item, images: [...images, image] });
  };

  const removeImage = (image, delay) => {
    const index = images.findIndex((img) => img.id === image.id);
    setImageDelete(true);
    setItem({
      ...item,
      images: Object.assign([], [...images], {
        [index]: {
          ...images[index],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      if (delay) {
        timeout.current = setTimeout(
          () => {
            updateClone.images.splice(index, 1);
            setItem({ ...item, images: updateClone.images });
            timeout.current = setTimeout(() => setImageDelete(false), 0);
          },
          delay ? 333 : 0
        );
      } else {
        updateClone.images.splice(index, 1);
        setItem({ ...item, images: updateClone.images });
        setImageDelete(false);
      }
    });
  };

  const showMoreImages = () => {
    imagesPage.current++;
    setImagesLoading(true);
    findImages([...imagesList]);
  };

  const showMoreImagesSearch = () => {
    imagesSearchPage.current++;
    setImagesLoading(true);
    findImagesSearch(imagesSearchQuery);
  };

  const onSortImageStart = () => {
    clearTimeout(timeout.current);
    setImageSortOn(true);
  };

  const onSortImageEnd = ({ oldIndex, newIndex }) => {
    setItem({ ...item, images: arrayMove(images, oldIndex, newIndex) });
    timeout.current = setTimeout(() => setImageSortOn(false), 500);
  };

  const openImageModal = (image, type) => {
    setImageFeaturedModal(image);
    setImageFeaturedType(type);
    setImageModalOpen(true);
  };

  const addImageModal = (image) => {
    addImage(image);
    closeImageModal();
  };

  const closeImageModal = () => {
    setImageFeaturedModal(null);
    setImageFeaturedType(null);
    setImageModalOpen(false);
  };

  const inputImageKeyPress = (event) => {
    if (event.key === "Enter") {
      if (imagesSearchQuery !== "") {
        event.preventDefault();
        setImagesSearchList([]);
        imagesSearchPage.current = 1;
        setImagesSearchTotal(0);
        setImagesSearchPageTotal(0);
        findImagesSearch(imagesSearchQuery, true);
        document.getElementById("images-search").scrollIntoView();
      } else {
        alert("Please enter a search term!");
      }
    }
  };

  const imagesSearch = (event) => {
    event.preventDefault();
    const imageSearchCopy = [...imagesSearchQuery];
    imageSearchCopy.value = event.target.value;
    setImagesSearchQuery(imageSearchCopy.value);
  };

  const clearImageSearch = () => {
    imagesSearchPage.current = 1;
    setImagesSearchQuery("");
    setImagesSearchList([]);
    setImagesSearchTotal(0);
    setImagesSearchPageTotal(0);
    setImagesSearchOn(false);
    setImagesLoading(true);
    document.getElementById("images-search").scrollIntoView();
    let searchInput = document.getElementById("image-search-input");
    if (searchInput) {
      searchInput.focus();
    }
    timeout.current = setTimeout(() => setImagesLoading(false), 500);
  };

  const toggleImagesAdvanced = (event) => {
    event.preventDefault();
    setImagesAdvanced(!imagesAdvanced);
    if (!imagesAdvanced) {
      document.getElementById("step-5").scrollIntoView();
    }
  };

  const setImageFiles = (acceptedFiles, rejectedFiles) => {
    setImageFilesUploading(true);
    let newImageFiles = [];
    let badImageFiles = [];
    let imageFileUsageNew = Number(imageFileUsage);
    let acceptedFilesList = acceptedFiles.map((file) => {
      return new Promise((resolve) => {
        if (
          !checkImagesFileMatch(file) &&
          file.size + imageFileUsageNew <= imageFileQuota
        ) {
          imageFileUsageNew += file.size;
          newImageFiles.push({
            id: file.name,
            file: file,
            name: file.name,
            preview: file.preview,
            size: file.size,
            type: file.type,
            new: true,
          });
          resolve();
        } else {
          if (file.size + imageFileUsageNew > imageFileQuota) {
            badImageFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "File is " +
                convertFileSize(
                  file.size + imageFileUsageNew - imageFileQuota
                ) +
                " too large for your quota",
            });
            resolve();
          } else {
            badImageFiles.push({
              name: file.name,
              size: file.size,
              reason: "File name already exists",
            });
            resolve();
          }
        }
      });
    });
    rejectedFiles.forEach((file) => {
      badImageFiles.push({
        name: file.name,
        size: file.size,
        reason: "Sorry, you must use an image file (.jpg, .png, .gif)",
      });
    });
    Promise.all(acceptedFilesList)
      .then(() => {
        setBadImageFiles(badImageFiles);
        setImageFilesUploading(newImageFiles.length > 0 ? true : false);
        setItem({ ...item, images: [...images, ...newImageFiles] }).then(
          (update) => {
            killSteps(update);
            setImageFilesDone(_cloneDeep(update), newImageFiles);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  const setImageFilesDone = (update, newImageFiles) => {
    let progress = 0;
    let fileCount = update.images.length - newImageFiles.length;
    let fileDropProgress = document.getElementById("image-drop-progress");
    let newImageFilesList = newImageFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/images/${file.name}`)
          : storage.getRef(`${type}/${id}/images/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        imageFileUploads.current.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fileDropProgress)
              fileDropProgress.style.width =
                (progress / newImageFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              setImageFileUsage(
                imageFileUsage + uploadTask.snapshot.metadata.size
              );
              update.images[fileCount + index].downloadURL = downloadURL;
              update.images[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.images[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.images[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.images[fileCount + index].file = null;
              update.images[fileCount + index].preview = null;
              update.images[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveImages(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(newImageFilesList)
      .then(() => {
        imageFileUploads.current = [];
        timeout.current = setTimeout(() => {
          setImageFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fileDropProgress) fileDropProgress.style.width = "0";
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const saveImages = (update) => {
    let images = {
      images: update.images ? update.images : [],
    };
    db.saveItem(type, id, images);
  };

  const checkImagesFileMatch = (file) => {
    let hasMatch = false;
    images.forEach((image) => {
      if (image.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const openDeleteImageFile = (id, file) => {
    setImageFileDeleteOpen(true);
    setImageDeleteID(id);
    setImageDeleteFile(file);
  };

  const cancelDeleteImageFile = (event) => {
    event.preventDefault();
    setImageFileDeleteOpen(false);
    setImageDeleteID(null);
    setImageDeleteFile(null);
  };

  const deleteImageFile = () => {
    setImageFileDeleteOpen(false);
    setImageDelete(true);
    setItem({
      ...item,
      images: Object.assign([], [...images], {
        [imageDeleteID]: {
          ...images[imageDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(imageDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.images.splice(imageDeleteID, 1);
            setItem(updateClone);
            if (!demo) saveImages(updateClone);
            killSteps(updateClone);
            setImageFileUsage(findFileUsage(updateClone.images));
            timeout.current = setTimeout(() => {
              setImageDeleteID(null);
              setImageDelete(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const findSelected = (id) => {
    return images.findIndex((img) => img.id === id) !== -1 ? true : false;
  };

  const doneImages = () => {
    clearImageSearch();
    doneStep("images", step);
    if (item.audience && !item.audience.definition) {
      timeout.current = setTimeout(() => {
        let definitionText = document.getElementById(
          "audience-definition-textarea"
        );
        if (definitionText) {
          definitionText.focus();
        }
      });
    }
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "images",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.images ? "done" : ""
      } ${images.length === 0 ? "none-selected" : ""}`}
    >
      <Confirm
        className="confirm"
        open={imageFileDeleteOpen}
        header="Are you sure?"
        content="The image will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteImageFile}
        onConfirm={deleteImageFile}
      />
      <Modal
        className="image-featured-modal"
        open={imageModalOpen}
        onClose={closeImageModal}
      >
        <div className="modal-close-wrap">
          <span className="card-close modal-close" onClick={closeImageModal}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        <Modal.Content>
          <div
            className="image-featured"
            onClick={() => {
              if (
                imageFeaturedType === "list" &&
                !findSelected(imageFeaturedModal.id)
              ) {
                addImageModal(imageFeaturedModal);
              } else {
                closeImageModal();
              }
            }}
          >
            {imageFeaturedModal && imageFeaturedModal.downloadURL ? (
              <img
                src={imageFeaturedModal ? imageFeaturedModal.downloadURL : ""}
                alt="Featured"
              />
            ) : imageFeaturedModal && imageFeaturedModal.preview ? (
              <img
                src={imageFeaturedModal ? imageFeaturedModal.preview : ""}
                alt="Featured"
              />
            ) : imageFeaturedModal &&
              imageFeaturedModal.urls &&
              imageFeaturedModal.urls.regular ? (
              <img
                src={imageFeaturedModal ? imageFeaturedModal.urls.regular : ""}
                alt="Featured"
              />
            ) : null}
          </div>
          {imageFeaturedModal && imageFeaturedModal.user ? (
            <span className="image-attribute">
              <p>
                Photo by{" "}
                <a
                  href={`https://unsplash.com/@${
                    imageFeaturedModal ? imageFeaturedModal.user.username : ""
                  }?utm_source=designgapp&utm_medium=referral`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {imageFeaturedModal ? imageFeaturedModal.user.name : ""}
                </a>{" "}
                on{" "}
                <a
                  href="https://unsplash.com/?utm_source=designgapp&utm_medium=referral"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unsplash
                </a>
              </p>
            </span>
          ) : null}
        </Modal.Content>
      </Modal>
      <div
        className={`component-section-inner ${imageSortOn ? "sorting" : ""} ${
          imageDelete ? "deleting" : ""
        }`}
      >
        <span className="images-wrap">
          {type !== "projects" || imagesAdvanced ? (
            <div id="images-upload" className="images-files-upload">
              <h2 className="with-help">
                {headerText.images[label].upload}
                <Popup
                  hoverable
                  hideOnScroll
                  position="top right"
                  className="popup-help"
                  trigger={
                    <div className="header-help">
                      <i className="icon icon-question-circle" />
                    </div>
                  }
                  header={<p>{helpText.images[label]}</p>}
                />
              </h2>

              {type !== "projects" ? (
                <TextContent node={0} {...textContentProps} />
              ) : null}

              <Dropzone
                style={{}}
                accept="image/jpeg, image/png, image/gif"
                disabled={imageFilesUploading}
                className={`images-files-dropzone files-dropzone ${
                  imageFilesUploading ? "uploading" : ""
                }`}
                activeClassName="active"
                onDrop={(acceptedFiles, rejectedFiles) =>
                  setImageFiles(acceptedFiles, rejectedFiles)
                }
              >
                <div className="images-files-drop files-drop">
                  <h4>Drop your image files here or...</h4>
                  <h5>(Only .jpg, .png, and .gif files are accepted)</h5>
                  <div className="btn btn-primary">click to upload</div>
                  <div className="images-files-usage files-usage">
                    <label>
                      <i className="icon icon-notification" />
                      You have{" "}
                      {convertFileSize(imageFileQuota - imageFileUsage)} left in
                      your {convertFileSize(imageFileQuota)} image quota
                    </label>
                    <div
                      className={`progress-bar negative done-${Math.round(
                        (imageFileUsage / imageFileQuota) * 100
                      )} ${
                        (imageFileUsage / imageFileQuota) * 100 > 100
                          ? "burst"
                          : ""
                      }`}
                    >
                      <span
                        className="progress-bar-fill"
                        style={{
                          width: `${(imageFileUsage / imageFileQuota) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                  <span className="files-drop-msg">
                    <i className="icon icon-dropzone" />
                    <p>Drop files here</p>
                  </span>
                  <span className="files-drop-progress">
                    <span
                      id="image-drop-progress"
                      className="drop-progress-bar"
                    />
                    <span className="upload-loading" />
                  </span>
                </div>
              </Dropzone>
              {badImageFiles.length > 0 ? (
                <div className="images-files-bad files-bad-wrap">
                  <span
                    className="close-files-bad"
                    onClick={() => setBadImageFiles([])}
                  >
                    <i className="icon icon-cross" />
                  </span>
                  <label>Sorry, we couldn't upload these files:</label>
                  <ul className={`files-bad`}>
                    {Object.keys(badImageFiles).map((key) => (
                      <li key={key} className="file-bad">
                        <p>
                          <span className="file-name">
                            <span className="file-text">
                              {badImageFiles[key].name}
                            </span>
                            <span className="file-size">
                              ({convertFileSize(badImageFiles[key].size)})
                            </span>
                          </span>
                          <span className="file-reason">
                            {badImageFiles[key].reason}
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
          <div
            id="images-selected"
            className={`images-list-selected ${
              images.length > 0 ? "active" : ""
            }`}
          >
            <h2>Here are the images you selected:</h2>
            {images ? (
              <SortableList
                images={images}
                imageDeleteID={imageDeleteID}
                openImageModal={openImageModal}
                openDeleteImageFile={openDeleteImageFile}
                removeImage={removeImage}
                onSortEnd={onSortImageEnd}
                distance={window.innerWidth > 1024 ? 5 : 0}
                pressDelay={window.innerWidth <= 1024 ? 200 : 0}
                helperClass={"item-drag image-drag"}
                axis={"xy"}
                onSortStart={({ node, index, collection }, event) => {
                  event.preventDefault();
                  onSortImageStart();
                }}
              />
            ) : null}
          </div>
          <div
            id="images-search"
            className={`images-list-search ${
              imagesSearchList.length > 0 || imagesSearchList === "empty"
                ? "search-on"
                : ""
            } ${imagesError && imagesSearchOn ? "search-error" : ""} ${
              imagesLoading ? "loading" : ""
            }`}
          >
            {!doneList.images ? (
              <span className="images-list-wrap">
                <div className="images-list-header">
                  <h2 className="with-help">
                    {headerText.images[label].search}
                    <Popup
                      hoverable
                      hideOnScroll
                      position="top right"
                      className="popup-help"
                      trigger={
                        <div className="header-help">
                          <i className="icon icon-question-circle" />
                        </div>
                      }
                      header={<p>{helpText.images[label]}</p>}
                    />
                  </h2>

                  <Sticky
                    className="sticky-holder"
                    mode="top"
                    bottomOffset={100}
                    boundaryElement=".images-list-search"
                    positionRecheckInterval={1}
                  >
                    <div className="images-search-bar">
                      <input
                        type="text"
                        id="image-search-input"
                        name="image-search"
                        value={imagesSearchQuery ? imagesSearchQuery : ""}
                        className="search-input"
                        placeholder="Search for images..."
                        onKeyUp={inputImageKeyPress}
                        onChange={imagesSearch}
                      />
                      <i
                        className="icon icon-magnifier search-input-icon"
                        onClick={() => {
                          findImagesSearch(imagesSearchQuery);
                          document
                            .getElementById("images-search")
                            .scrollIntoView();
                        }}
                      />
                      <span className="image-search-results">
                        <p>{imagesSearchTotal.toLocaleString()} results</p>
                        <i
                          className="icon icon-cross"
                          onClick={clearImageSearch}
                        />
                      </span>
                    </div>
                  </Sticky>
                </div>
                <ul className="images-list images-match-list cards-list">
                  {imagesList.length === 0 &&
                  imagesLoading &&
                  !imagesError &&
                  !imagesSearchOn ? (
                    <span className="images-loading">
                      <img
                        src={require("../../images/loading.svg")}
                        alt="loading images"
                      />
                    </span>
                  ) : imagesList.length === 0 &&
                    !imagesLoading &&
                    !imagesError &&
                    !imagesSearchOn ? (
                    <h3>Sorry, we couldn't find any images. Try a search.</h3>
                  ) : (imagesList.length === 0 ||
                      (imagesSearchOn && imagesSearchList.length === 0)) &&
                    imagesError ? (
                    <h3>Sorry, no images were found. Please and try again.</h3>
                  ) : null}
                  {!imagesSearchOn && imagesList ? (
                    Object.keys(imagesList).map((key, index) => (
                      <li
                        key={`${imagesList[key].id}-${index}`}
                        className={`card ${
                          findSelected(imagesList[key].id) ? "selected" : ""
                        }`}
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-sm.svg")}
                          onError={(error) =>
                            console.log("ERROR - Load Image", error)
                          }
                          src={imagesList[key].urls.regular}
                        >
                          {(src, loading) => (
                            <span
                              className={`file-img-bg progressive ${
                                loading ? "loading" : "done"
                              }`}
                              onClick={(event) => addImage(imagesList[key])}
                              style={{ backgroundImage: `url("${src}")` }}
                            />
                          )}
                        </ProgressiveImage>
                        <span
                          className="card-expand"
                          onClick={() =>
                            openImageModal(imagesList[key], "list")
                          }
                        >
                          <i className="icon icon-expand" />
                        </span>
                        <span className="card-delete-wrap">
                          <span
                            className="card-delete"
                            onClick={() => removeImage(imagesList[key], false)}
                          >
                            <i className="icon icon-cross" />
                          </span>
                        </span>
                      </li>
                    ))
                  ) : !imagesError ? (
                    <span className="images-loading">
                      <img
                        src={require("../../images/loading.svg")}
                        alt="loading images"
                      />
                    </span>
                  ) : null}
                </ul>
                <ul className="images-list images-search-list cards-list">
                  {imagesSearchOn &&
                  imagesSearchList &&
                  imagesSearchList !== "empty" ? (
                    Object.keys(imagesSearchList).map((key, index) => (
                      <li
                        key={`${imagesSearchList[key].id}-${index}`}
                        className={`card ${
                          findSelected(imagesSearchList[key].id)
                            ? "selected"
                            : ""
                        }`}
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-sm.svg")}
                          onError={(error) =>
                            console.log("ERROR - Load Image", error)
                          }
                          src={imagesSearchList[key].urls.regular}
                        >
                          {(src, loading) => (
                            <span
                              className={`file-img-bg progressive ${
                                loading ? "loading" : "done"
                              }`}
                              onClick={(event) =>
                                addImage(imagesSearchList[key])
                              }
                              style={{ backgroundImage: `url("${src}")` }}
                            />
                          )}
                        </ProgressiveImage>
                        <span
                          className="card-expand"
                          onClick={() =>
                            openImageModal(imagesSearchList[key], "list")
                          }
                        >
                          <i className="icon icon-expand" />
                        </span>
                        <span className="card-delete-wrap">
                          <span
                            className="card-delete"
                            onClick={() =>
                              removeImage(imagesSearchList[key], false)
                            }
                          >
                            <i className="icon icon-cross" />
                          </span>
                        </span>
                      </li>
                    ))
                  ) : imagesSearchList === "empty" ? (
                    <h3>Sorry, we couldn't find images matching that search</h3>
                  ) : null}
                </ul>
                <div className="images-list-more">
                  {imagesPageTotal > imagesPage.current &&
                  imagesList &&
                  imagesPage.current < imagesMaxPages ? (
                    <div className="btn btn-primary" onClick={showMoreImages}>
                      {!imagesLoading ? (
                        <span>show more images</span>
                      ) : (
                        <span className="btn-loading" />
                      )}
                    </div>
                  ) : (imagesPageTotal === imagesPage.current && imagesList) ||
                    imagesPage.current === imagesMaxPages ? (
                    <div
                      className="btn btn-white"
                      onClick={() => {
                        document
                          .getElementById("images-search")
                          .scrollIntoView();
                        document.getElementById("image-search-input").focus();
                      }}
                    >
                      <span>search to find more images</span>
                    </div>
                  ) : null}
                  {imagesSearchPageTotal >= imagesSearchPage.current &&
                  imagesSearchPage.current < imagesMaxPages ? (
                    <div
                      className="btn btn-primary images-search-btn"
                      disabled={
                        imagesSearchPageTotal <= imagesSearchPage.current
                      }
                      onClick={showMoreImagesSearch}
                    >
                      {!imagesLoading ? (
                        <span>show more images</span>
                      ) : (
                        <span className="btn-loading" />
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="images-count-wrap">
                  <Sticky
                    className="sticky-holder"
                    mode="bottom"
                    topOffset={100}
                    bottomOffset={250}
                    boundaryElement=".images-list-wrap"
                    positionRecheckInterval={1}
                    disabled={images.length === 0}
                  >
                    {images.length > 0 ? (
                      <div
                        className={`images-count count-${images.length}`}
                        onClick={() =>
                          document
                            .getElementById("images-selected")
                            .scrollIntoView({ block: "start" })
                        }
                      >
                        <p>
                          You've selected
                          <span className="images-count-click">
                            {images.length}
                            {images.length === 1 ? " image" : " images"}
                          </span>
                        </p>
                      </div>
                    ) : null}
                  </Sticky>
                </div>
              </span>
            ) : null}
          </div>
        </span>
        {type === "projects" ? (
          <div className="images-advanced-toggle">
            <div className="btn btn-text" onClick={toggleImagesAdvanced}>
              {imagesAdvanced
                ? "Nevermind, I don't want to upload images."
                : "Not finding the right imagery? Upload your own."}
            </div>
          </div>
        ) : null}
        <p className="section-empty">
          No images selected, click here to choose some.
        </p>

        {type !== "projects" ? (
          <TextContent node={1} {...textContentProps} />
        ) : null}
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneImages}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editImages}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

const SortableList = SortableContainer(
  ({
    images,
    imageDeleteID,
    openImageModal,
    openDeleteImageFile,
    removeImage,
  }) => (
    <ul className="images-list cards-list sortable">
      {images.map((image, index) => (
        <SortableItem
          key={image.id}
          index={index}
          image={image}
          disabled={image.new}
          imageContent={
            image.downloadURL || image.preview ? (
              <div
                className={`image-wrap ${image.downloadURL ? "upload" : ""}`}
              >
                {image.downloadURL ? (
                  <ProgressiveImage
                    placeholder={require("../../images/loading-sm.svg")}
                    src={image.downloadURL}
                    key={image.downloadURL}
                  >
                    {(src, loading) => (
                      <div className="file-img-wrap">
                        <div
                          className={`file-img-bg progressive ${
                            loading ? "loading" : "done"
                          }`}
                          style={{ backgroundImage: `url("${src}")` }}
                        />
                        <span className="file-detail">
                          <p className="file-detail-content">
                            <span className="file-text">{image.name}</span>
                            <span className="file-size">
                              {convertFileSize(image.size)}
                            </span>
                          </p>
                        </span>
                      </div>
                    )}
                  </ProgressiveImage>
                ) : null}
                {image.preview ? (
                  <div
                    className="file-img-preview"
                    style={{ backgroundImage: `url('${image.preview}')` }}
                  />
                ) : null}
                {image.downloadURL ? (
                  <span>
                    <span
                      className="card-expand"
                      onClick={() => openImageModal(image, "selected")}
                    >
                      <i className="icon icon-expand" />
                    </span>
                    <span
                      className="card-delete"
                      onClick={() => openDeleteImageFile(index, image)}
                    >
                      <i className="icon icon-trash" />
                    </span>
                  </span>
                ) : (
                  <span className="file-loading" />
                )}
              </div>
            ) : (
              <div className="image-wrap">
                <ProgressiveImage
                  placeholder={require("../../images/loading-sm.svg")}
                  src={image.urls.regular}
                  key={image.urls.regular}
                >
                  {(src, loading) => (
                    <div className="file-img-wrap">
                      <div
                        className={`file-img-bg progressive ${
                          loading ? "loading" : "done"
                        }`}
                        style={{ backgroundImage: `url("${src}")` }}
                      />
                    </div>
                  )}
                </ProgressiveImage>
                <span
                  className="card-expand"
                  onClick={() => openImageModal(image, "selected")}
                >
                  <i className="icon icon-expand" />
                </span>
                <span
                  className="card-delete"
                  onClick={() => removeImage(image, true)}
                >
                  <i className="icon icon-cross" />
                </span>
              </div>
            )
          }
        />
      ))}
      {images.length > 1 ? (
        <span className="image-featured-drop card">
          <p>Featured Image</p>
        </span>
      ) : null}
    </ul>
  )
);

const SortableItem = SortableElement(({ image, imageContent }) => (
  <li
    className={`card selected ${image.new ? "new" : ""} ${
      image.preview && !image.downloadURL ? "loading" : ""
    } ${image.deleted ? "deleted" : ""}`}
  >
    {imageContent}
  </li>
));

export default Images;
