import React, { useState, useRef } from "react";
import { db, storage } from "../../firebase";
import TextContent from "../shared/TextContent";
import ProgressiveImage from "react-progressive-image";
import Dropzone from "react-dropzone";
import { Popup, Confirm, Menu, Dropdown } from "semantic-ui-react";
import {
  isTouch,
  useMountEffect,
  headerText,
  helpText,
  convertFileSize,
  findFileType,
  findFileUsage,
} from "./Resources";
import { SketchPicker } from "react-color";
import Sticky from "react-sticky-el";
import arrayMove from "array-move";
import tinycolor from "tinycolor2";
import getColors from "image-pal-canvas/lib/hsluv";
import _cloneDeep from "lodash.clonedeep";

export const Screens = (props) => {
  const {
    authUser,
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { screens, doneList },
    setItem,
    saveItem,
    killSteps,
    demo,
  } = props;

  const timeout = useRef(null);
  const screenFileUploads = useRef([]);
  const screenFileQuota = authUser.premiumUser ? 104000000 : 52000000;
  const defaultScreenType = "background";
  const defaultScreenScale = 5;
  const screenItemOffset = 85;

  const [badScreenFiles, setBadScreenFiles] = useState([]);
  const [screenFileUsage, setScreenFileUsage] = useState(0);
  const [screenFilesUploading, setScreenFilesUploading] = useState(false);
  const [screenFileDeleteOpen, setScreenFileDeleteOpen] = useState(false);
  const [screenDeleteID, setScreenDeleteID] = useState(null);
  const [screenDeleteFile, setScreenDeleteFile] = useState("");
  const [screenReorder, setScreenReorder] = useState(false);
  const [screenDelete, setScreenDelete] = useState(false);
  const [customActive, setCustomActive] = useState(false);

  useMountEffect(() => {
    setScreenFileUsage(findFileUsage(screens));
    return () => {
      clearTimeout(timeout.current);
      screenFileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const editScreens = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        screens: false,
      },
    });
  };

  const setScreenFiles = (acceptedFiles, rejectedFiles) => {
    setScreenFilesUploading(true);
    let newScreenFiles = [];
    let badScreenFiles = [];
    let screenFileUsageNew = Number(screenFileUsage);
    let screenFilesList = acceptedFiles.map((file) => {
      return new Promise((resolve) => {
        if (
          !checkScreensFileMatch(file) &&
          (file.type !== "" || file.name.indexOf(".") !== -1) &&
          file.size + screenFileUsageNew <= screenFileQuota
        ) {
          screenFileUsageNew += file.size;
          let fileImg = new Image();
          let _URL = window.URL || window.webkitURL;
          if (findFileType(file) === "image-embed") {
            let screenPalette = [];
            let darkCount = 0;
            let whiteCount = 0;
            getColors(
              {
                srcUrl: file.preview,
                order: "density",
                mean: false,
                hasAlpha: true,
                maxColors: 10,
                minDensity: 0.0075,
              },
              (err, colors) => {
                colors.forEach((color, index) => {
                  if (color.distance > 0) {
                    if (
                      screenPalette.map((e) => e.hex).indexOf(color.hex) === -1
                    ) {
                      screenPalette.push({ hex: color.hex, type: "auto" });
                    }
                    if (tinycolor(color.hex).getBrightness() > 240) {
                      whiteCount += color.density;
                    } else {
                      darkCount += color.density;
                    }
                  }
                });
                fileImg.src = _URL.createObjectURL(file);
                fileImg.onload = () => {
                  newScreenFiles.push({
                    file: file,
                    name: file.name,
                    preview: file.preview,
                    size: file.size,
                    type: file.type,
                    new: true,
                    height: fileImg.height,
                    width: fileImg.width,
                    color: whiteCount >= darkCount ? "white" : "dark",
                    palette: screenPalette,
                    screenType: defaultScreenType,
                    screenScale: defaultScreenScale,
                    background: screenPalette[0] ? screenPalette[0] : [],
                  });
                  resolve();
                };
              }
            );
          } else {
            newScreenFiles.push({
              file: file,
              name: file.name,
              preview: file.preview,
              size: file.size,
              type: file.type,
              new: true,
              height: fileImg.height,
              width: fileImg.width,
              screenType: defaultScreenType,
              screenScale: defaultScreenScale,
            });
            resolve();
          }
        } else {
          if (file.size + screenFileUsageNew > screenFileQuota) {
            badScreenFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "File is " +
                convertFileSize(
                  file.size + screenFileUsageNew - screenFileQuota
                ) +
                " too large for your quota",
            });
            resolve();
          } else if (file.type === "" && file.name.indexOf(".") === -1) {
            badScreenFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, folders are not supported",
            });
            resolve();
          } else {
            badScreenFiles.push({
              name: file.name,
              size: file.size,
              reason: "File name already exists",
            });
            resolve();
          }
        }
      });
    });
    rejectedFiles.forEach((file) => {
      badScreenFiles.push({
        name: file.name,
        size: file.size,
        reason: "Sorry, you must use an image file (.jpg, .png, .gif)",
      });
    });
    Promise.all(screenFilesList)
      .then(() => {
        setBadScreenFiles(badScreenFiles);
        setScreenFilesUploading(newScreenFiles.length > 0 ? true : false);
        setItem({ ...item, screens: [...screens, ...newScreenFiles] }).then(
          (update) => {
            killSteps(update);
            setScreenFilesDone(_cloneDeep(update), newScreenFiles);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  const setScreenFilesDone = (update, newScreenFiles) => {
    let progress = 0;
    let fileCount = update.screens.length - newScreenFiles.length;
    let fileDropProgress = document.getElementById("screen-drop-progress");
    let screenFileList = newScreenFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/screens/${file.name}`)
          : storage.getRef(`${type}/${id}/screens/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        screenFileUploads.current.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fileDropProgress)
              fileDropProgress.style.width =
                (progress / newScreenFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              setScreenFileUsage(
                screenFileUsage + uploadTask.snapshot.metadata.size
              );
              update.screens[fileCount + index].downloadURL = downloadURL;
              update.screens[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.screens[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.screens[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.screens[fileCount + index].file = null;
              update.screens[fileCount + index].preview = null;
              update.screens[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveScreens(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(screenFileList)
      .then(() => {
        screenFileUploads.current = [];
        timeout.current = setTimeout(() => {
          setScreenFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fileDropProgress) fileDropProgress.style.width = "0";
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const saveScreens = (update) => {
    let screens = {
      screens: update.screens ? update.screens : [],
    };
    db.saveItem(type, id, screens);
  };

  const checkScreensFileMatch = (file) => {
    let hasMatch = false;
    screens.forEach((screen) => {
      if (screen.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const openDeleteScreenFile = (id, file) => {
    setScreenFileDeleteOpen(true);
    setScreenDeleteID(id);
    setScreenDeleteFile(file);
  };

  const cancelDeleteScreenFile = (event, id, file) => {
    event.preventDefault();
    setScreenFileDeleteOpen(false);
    setScreenDeleteID(null);
    setScreenDeleteFile(null);
  };

  const deleteScreenFile = () => {
    setScreenFileDeleteOpen(false);
    setScreenDelete(true);
    setItem({
      ...item,
      screens: Object.assign([], [...screens], {
        [screenDeleteID]: {
          ...screens[screenDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(screenDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.screens.splice(screenDeleteID, 1);
            setItem(updateClone);
            if (!demo) saveScreens(updateClone);
            killSteps(updateClone);
            setScreenFileUsage(findFileUsage(updateClone.screens));
            timeout.current = setTimeout(() => {
              setScreenDeleteID(null);
              setScreenDelete(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const setScreenBackground = (index, color) => {
    setItem({
      ...item,
      screens: Object.assign([], [...screens], {
        [index]: {
          ...screens[index],
          background: color,
        },
      }),
    });
  };

  const getScreenColors = (palette) => {
    let colors = ["rgba(0,0,0,0)"];
    palette.map((color) => colors.push(color.hex));
    return colors;
  };

  const reorderScreen = (index, direction) => {
    setScreenReorder(true);
    const newIndex =
      direction === "down"
        ? index + 1
        : direction === "bottom"
        ? screens.length - 1
        : direction === "up"
        ? index - 1
        : direction === "top"
        ? 0
        : index;
    setItem({ ...item, screens: arrayMove(screens, index, newIndex) }).then(
      () => {
        const screenItem = document.getElementById(`screen-${newIndex}`);
        if (screenItem) screenScroll(screenItem);
        timeout.current = setTimeout(() => {
          setScreenReorder(false);
        }, 500);
      }
    );
  };

  const screenScroll = (screenItem) => {
    window.scrollTo({
      top:
        screenItem.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        screenItemOffset,
    });
  };

  const setScreenType = (index, type) => {
    setItem({
      ...item,
      screens: Object.assign([], [...screens], {
        [index]: {
          ...screens[index],
          screenType: type,
        },
      }),
    }).then(() => {
      const screenItem = document.getElementById(`screen-${index}`);
      if (screenItem) screenScroll(screenItem);
    });
  };

  const setScreenScale = (index, scale) => {
    setItem({
      ...item,
      screens: Object.assign([], [...screens], {
        [index]: {
          ...screens[index],
          screenScale: scale,
        },
      }),
    });
  };

  const doneScreens = () => {
    screens.forEach((screen, index) => {
      if (screen.screenType === "desktop" || screen.screenType === "mobile") {
        const screenItemImg = document
          .getElementById(`screen-${index}`)
          .getElementsByClassName("file-img-wrap")[0];
        if (screenItemImg) screenItemImg.scrollTop = 0;
      }
    });
    doneStep("screens", step);
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "screens",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.screens ? "done" : ""
      } ${!screens || screens.length === 0 ? "none-selected" : ""}`}
    >
      <Confirm
        className="confirm"
        open={screenFileDeleteOpen}
        header="Are you sure?"
        content="The file will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteScreenFile}
        onConfirm={deleteScreenFile}
      />
      <div
        className={`component-section-inner ${screenReorder ? "reorder" : ""} ${
          screenDelete ? "deleting" : ""
        }`}
      >
        <span className={`screens-list ${screens.length > 0 ? "active" : ""}`}>
          <h2 className="with-help">
            {headerText.screens[label]}
            <Popup
              hoverable
              hideOnScroll
              position="top right"
              className="popup-help"
              trigger={
                <div className="header-help">
                  <i className="icon icon-question-circle" />
                </div>
              }
              header={<p>{helpText.screens[label]}</p>}
            />
          </h2>

          <TextContent node={0} {...textContentProps} />

          <div
            className={`screens-section screens-files ${
              !screens || screens.length === 0 ? "empty" : ""
            }`}
          >
            {doneList && !doneList.screens ? (
              <Dropzone
                style={{}}
                accept="image/jpeg, image/png, image/gif"
                disabled={screenFilesUploading}
                className={`screens-files-dropzone files-dropzone ${
                  screenFilesUploading ? "uploading" : ""
                }`}
                activeClassName="active"
                onDrop={(acceptedFiles, rejectedFiles) =>
                  setScreenFiles(acceptedFiles, rejectedFiles)
                }
              >
                <div className="screens-files-drop files-drop">
                  <h4>Drop your screen files here or...</h4>
                  <h5>(Only .jpg, .png, and .gif files are accepted)</h5>
                  <div className="btn btn-primary">click to upload</div>
                  <div className="screens-files-usage files-usage">
                    <label>
                      <i className="icon icon-notification" />
                      You have{" "}
                      {convertFileSize(screenFileQuota - screenFileUsage)} left
                      in your {convertFileSize(screenFileQuota)} screen quota
                    </label>
                    <div
                      className={`progress-bar negative done-${Math.round(
                        (screenFileUsage / screenFileQuota) * 100
                      )} ${
                        (screenFileUsage / screenFileQuota) * 100 > 100
                          ? "burst"
                          : ""
                      }`}
                    >
                      <span
                        className="progress-bar-fill"
                        style={{
                          width: `${
                            (screenFileUsage / screenFileQuota) * 100
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                  <span className="files-drop-msg">
                    <i className="icon icon-dropzone" />
                    <p>Drop files here</p>
                  </span>
                  <span className="files-drop-progress">
                    <span
                      id="screen-drop-progress"
                      className="drop-progress-bar"
                    />
                    <span className="upload-loading" />
                  </span>
                </div>
              </Dropzone>
            ) : null}
            {badScreenFiles.length > 0 ? (
              <div className="screens-files-bad files-bad-wrap">
                <span
                  className="close-files-bad"
                  onClick={() => setBadScreenFiles([])}
                >
                  <i className="icon icon-cross" />
                </span>
                <label>Sorry, we couldn't upload these files:</label>
                <ul className={`files-bad`}>
                  {Object.keys(badScreenFiles).map((key) => (
                    <li key={key} className="file-bad">
                      <p>
                        <span className="file-name">
                          <span className="file-text">
                            {badScreenFiles[key].name}
                          </span>
                          <span className="file-size">
                            ({convertFileSize(badScreenFiles[key].size)})
                          </span>
                        </span>
                        <span className="file-reason">
                          {badScreenFiles[key].reason}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {screens && screens.length > 0 ? (
              <ul className="files-list cards-list">
                {screens.map((screen, index) => (
                  <li
                    id={`screen-${index}`}
                    className={`card selected ${screen.new ? "new" : ""} ${
                      !screen.downloadURL ? "loading" : ""
                    } ${screen.deleted ? "deleted" : ""} screen-${
                      screen.screenType
                    }`}
                    key={screen.name}
                  >
                    <div className="file-content screen-content">
                      <div
                        className={`file-img ${
                          screen.screenScale
                            ? `scale-${screen.screenScale}`
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            screen.screenType === "background" &&
                            screen.background
                              ? screen.background.hex
                              : (screen.screenType === "desktop" ||
                                  screen.screenType === "mobile") &&
                                item.theme !== "dark"
                              ? "black"
                              : "white",
                        }}
                      >
                        {screen.downloadURL ? (
                          <ProgressiveImage
                            placeholder={require("../../images/empty.svg")}
                            src={screen.downloadURL}
                            key={screen.downloadURL}
                          >
                            {(src, loading) => (
                              <div
                                className={`file-img-wrap progressive ${
                                  loading ? "loading" : "done"
                                }`}
                              >
                                <img
                                  height={screen.height}
                                  width={screen.width}
                                  src={src}
                                  alt={screen.name}
                                />
                              </div>
                            )}
                          </ProgressiveImage>
                        ) : null}
                        {screen.preview ? (
                          <div className="file-img-preview">
                            <img
                              src={screen.preview}
                              alt={`preview ${screen.name}`}
                            />
                          </div>
                        ) : null}
                      </div>
                      {!screen.new ? (
                        <Sticky
                          className="sticky-holder"
                          mode="bottom"
                          boundaryElement=".screen-content"
                          positionRecheckInterval={1}
                          disabled={isTouch()}
                        >
                          <ScreenControls
                            index={index}
                            screen={screen}
                            screenType={screen.screenType}
                            setScreenType={setScreenType}
                          />

                          {screen.palette &&
                          screen.screenType === "background" ? (
                            <div className="file-background">
                              <div className="file-scale">
                                <label>Image scale:</label>
                                <input
                                  type="range"
                                  orient="vertical"
                                  min="1"
                                  max="12"
                                  step="1"
                                  className="screen-image-zoom"
                                  value={screen.screenScale}
                                  onChange={(event) =>
                                    setScreenScale(index, event.target.value)
                                  }
                                />
                              </div>
                              <div className="file-palette">
                                {screen.palette.map((color, paletteIndex) => (
                                  <Popup
                                    key={paletteIndex}
                                    hoverable
                                    hideOnScroll
                                    position="bottom center"
                                    className="popup-color"
                                    trigger={
                                      <div
                                        className={`palette ${
                                          screen.background &&
                                          screen.background.hex === color.hex &&
                                          screen.background.type === "auto"
                                            ? "active"
                                            : ""
                                        }`}
                                        style={{ backgroundColor: color.hex }}
                                        onClick={() =>
                                          setScreenBackground(index, color)
                                        }
                                      >
                                        {screen.background &&
                                        screen.background.hex === color.hex &&
                                        screen.background.type === "auto" ? (
                                          <span
                                            className="palette-active-indicator"
                                            style={{
                                              borderBottomColor: color.hex,
                                            }}
                                          />
                                        ) : null}
                                      </div>
                                    }
                                    header={
                                      <div className="palette-values">
                                        <span>{color.hex}</span>
                                        <span>
                                          {tinycolor(color.hex).toRgbString()}
                                        </span>
                                        <span>
                                          {tinycolor(color.hex).toHslString()}
                                        </span>
                                      </div>
                                    }
                                  />
                                ))}
                                <div
                                  className={`palette custom ${
                                    screen.background &&
                                    screen.background.type === "custom"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <Menu className="item-color-wrap">
                                    <Dropdown
                                      item
                                      closeOnChange={false}
                                      onOpen={() => setCustomActive(true)}
                                      onClose={() => setCustomActive(false)}
                                      trigger={
                                        <div
                                          className="palette-custom-trigger"
                                          style={{
                                            backgroundColor:
                                              screen.background &&
                                              screen.background.type ===
                                                "custom"
                                                ? screen.background.hex
                                                : "inherit",
                                          }}
                                        >
                                          {screen.background &&
                                          screen.background.type ===
                                            "custom" ? (
                                            <span
                                              className="palette-active-indicator"
                                              style={{
                                                borderBottomColor:
                                                  screen.background.hex,
                                              }}
                                            />
                                          ) : (
                                            <i className="icon icon-palette" />
                                          )}
                                        </div>
                                      }
                                      options={[
                                        {
                                          key: index,
                                          content: customActive ? (
                                            <div
                                              className="color-picker"
                                              onClick={(event) =>
                                                event.stopPropagation()
                                              }
                                            >
                                              <SketchPicker
                                                color={screen.background.hex}
                                                presetColors={getScreenColors(
                                                  screen.palette
                                                )}
                                                onChangeComplete={(color) =>
                                                  setScreenBackground(index, {
                                                    hex: color.hex,
                                                    type: "custom",
                                                  })
                                                }
                                                disableAlpha={true}
                                              />
                                            </div>
                                          ) : null,
                                        },
                                      ]}
                                    />
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </Sticky>
                      ) : null}
                      {screen.downloadURL ? (
                        <span>
                          {screen.downloadURL ? (
                            <span className="card-download-wrap">
                              <a
                                href={screen.downloadURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="card-download">
                                  <i className="icon icon-download" />
                                </span>
                              </a>
                            </span>
                          ) : null}
                          {screens.length > 1 ? (
                            <span className="card-reorder-wrap">
                              <Menu className="card-reorder">
                                <Dropdown
                                  item
                                  className="center"
                                  trigger={
                                    <div className="card-reorder-trigger">
                                      <i className="icon icon-move" />
                                    </div>
                                  }
                                >
                                  <Dropdown.Menu>
                                    {index < screens.length - 1 ? (
                                      <span
                                        className="link menu-item card-reorder-item reorder-down"
                                        onClick={() =>
                                          reorderScreen(index, "down")
                                        }
                                      >
                                        <i className="icon icon-arrow-left" />
                                        Move down
                                      </span>
                                    ) : null}
                                    {index < screens.length - 1 ? (
                                      <span
                                        className="link menu-item card-reorder-item reorder-bottom"
                                        onClick={() =>
                                          reorderScreen(index, "bottom")
                                        }
                                      >
                                        <i className="icon icon-arrow-bottom" />
                                        Move bottom
                                      </span>
                                    ) : null}
                                    {index > 0 ? (
                                      <span
                                        className="link menu-item card-reorder-item reorder-up"
                                        onClick={() =>
                                          reorderScreen(index, "up")
                                        }
                                      >
                                        <i className="icon icon-arrow-left" />
                                        Move up
                                      </span>
                                    ) : null}
                                    {index > 0 ? (
                                      <span
                                        className="link menu-item card-reorder-item reorder-top"
                                        onClick={() =>
                                          reorderScreen(index, "top")
                                        }
                                      >
                                        <i className="icon icon-arrow-top" />
                                        Move top
                                      </span>
                                    ) : null}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Menu>
                            </span>
                          ) : null}
                          {screen.downloadURL ? (
                            <span
                              className="card-delete-wrap"
                              onClick={() =>
                                openDeleteScreenFile(index, screen)
                              }
                            >
                              <span className="card-delete">
                                <i className="icon icon-trash" />
                              </span>
                            </span>
                          ) : null}
                        </span>
                      ) : (
                        <span className="file-loading" />
                      )}
                      <div className="file-detail">
                        <p className="file-detail-content">
                          <span className="file-name">{`${
                            screen.name
                          } – ${convertFileSize(screen.size)}`}</span>
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </span>
        <p className="section-empty">
          No screens added, click here to add them.
        </p>

        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneScreens}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editScreens}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export const ScreenControls = ({
  index,
  screen,
  screenType,
  setScreenType,
}) => {
  return (
    <div className="screen-controls-wrap">
      <div className="screen-controls">
        <div
          className={`screen-control control-normal ${
            screenType === "normal" ? "active" : ""
          }`}
          onClick={() => setScreenType(index, "normal")}
        >
          <i className="icon icon-picture" />
        </div>
        <div
          className={`screen-control control-background ${
            screenType === "background" ? "active" : ""
          }`}
          onClick={() => setScreenType(index, "background")}
        >
          <i className="icon icon-picture-pad" />
        </div>
        <div
          className={`screen-control control-desktop ${
            screenType === "desktop" ? "active" : ""
          }`}
          onClick={() => setScreenType(index, "desktop")}
        >
          <i className="icon icon-screen" />
        </div>
        <div
          className={`screen-control control-mobile ${
            screenType === "mobile" ? "active" : ""
          }`}
          onClick={() => setScreenType(index, "mobile")}
        >
          <i className="icon icon-phone" />
        </div>
      </div>
    </div>
  );
};

export default Screens;
