import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as routes from "constants/routes";
import withAuthentication from "./withAuthentication";
import { RefreshToast } from "../serviceWorkerRegistration";
import { isTouch } from "components/shared/Resources";
import TagManager from "react-gtm-module";

import Header from "components/core/Header";
import Footer from "components/core/Footer";
import Index from "components/core/Index";
import Home from "components/core/Home";
import About from "components/core/About";
import Contact from "components/core/Contact";
import PasswordForget from "components/users/PasswordForget";
import PasswordChange from "components/users/PasswordChange";
import AuthPage from "components/users/Auth";
import ItemShare from "components/shared/ItemShare";
import ConfirmUser from "components/shared/ConfirmUser";
import Projects from "components/projects/Projects";
import ProjectEdit from "components/projects/ProjectEdit";
import Project from "components/projects/Project";
import Styleguides from "components/styleguides/Styleguides";
import StyleguideEdit from "components/styleguides/StyleguideEdit";
import Styleguide from "components/styleguides/Styleguide";
import Showcases from "components/showcases/Showcases";
import ShowcaseEdit from "components/showcases/ShowcaseEdit";
import Showcase from "components/showcases/Showcase";
import Personas from "components/personas/Personas";
import PersonaEdit from "components/personas/PersonaEdit";
import Persona from "components/personas/Persona";
import Demo from "components/shared/Demo";

const App = () => {
  const Account = lazy(() => import("components/users/Account"));
  const AccountUpgrade = lazy(() => import("components/users/AccountUpgrade"));
  const AccountBilling = lazy(() => import("components/users/AccountBilling"));
  const Terms = lazy(() => import("components/core/Terms"));
  const Privacy = lazy(() => import("components/core/Privacy"));
  const IframeError = lazy(() => import("components/core/IframeError"));
  const PageNotFound = lazy(() => import("components/core/PageNotFound"));
  const Login = lazy(() => import("components/users/Login"));
  const Signup = lazy(() => import("components/users/Signup"));
  const Verify = lazy(() => import("components/users/Verify"));
  const Deleted = lazy(() => import("components/users/Deleted"));

  return (
    <Router>
      <Suspense fallback={<div className="content-loading no-body" />}>
        <div className={`app-wrap ${isTouch() ? "touch" : ""}`}>
          <Header />
          <Switch>
            <Route exact path={routes.Index} component={Index} />
            <Route exact path={routes.Home} component={Home} />
            <Route exact path={routes.About} component={About} />
            <Route exact path={routes.Terms} component={Terms} />
            <Route exact path={routes.Privacy} component={Privacy} />
            <Route exact path={routes.Login} component={Login} />
            <Route exact path={routes.Signup} component={Signup} />
            <Route exact path={routes.Verify} component={Verify} />
            <Route
              path={`${routes.ConfirmUser}/:type/:id/:userID`}
              component={ConfirmUser}
            />
            <Route exact path={routes.Projects} component={Projects} />
            <Route
              path={`${routes.Project}/edit/:projectID`}
              component={ProjectEdit}
            />
            <Route path={`${routes.Project}/:projectID`} component={Project} />
            <Route exact path={routes.Styleguides} component={Styleguides} />
            <Route
              path={`${routes.Styleguide}/edit/:styleguideID`}
              component={StyleguideEdit}
            />
            <Route
              path={`${routes.Styleguide}/:styleguideID`}
              component={Styleguide}
            />
            <Route exact path={routes.Showcases} component={Showcases} />
            <Route
              path={`${routes.Showcase}/edit/:showcaseID`}
              component={ShowcaseEdit}
            />
            <Route
              path={`${routes.Showcase}/:showcaseID`}
              component={Showcase}
            />
            <Route exact path={routes.Personas} component={Personas} />
            <Route
              path={`${routes.Persona}/edit/:personaID`}
              component={PersonaEdit}
            />
            <Route path={`${routes.Persona}/:personaID`} component={Persona} />
            <Route exact path={routes.Demo} component={Demo} />
            <Route path={`/p/:shareID`} component={ItemShare} />
            <Route path={`/s/:shareID`} component={ItemShare} />
            <Route path={`/h/:shareID`} component={ItemShare} />
            <Route path={`/e/:shareID`} component={ItemShare} />
            <Route exact path={routes.Account} component={Account} />
            <Route
              exact
              path={routes.AccountUpgrade}
              component={AccountUpgrade}
            />
            <Route
              exact
              path={routes.AccountBilling}
              component={AccountBilling}
            />
            <Route exact path={routes.Deleted} component={Deleted} />
            <Route
              exact
              path={routes.PasswordForget}
              component={PasswordForget}
            />
            <Route
              exact
              path={routes.PasswordChange}
              component={PasswordChange}
            />
            <Route exact path={routes.Auth} component={AuthPage} />
            <Route exact path={routes.Contact} component={Contact} />
            <Route exact path="/iframe-error" component={IframeError} />
            <Route exact path="/404" component={PageNotFound} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <Footer />
          <RefreshToast />
        </div>
      </Suspense>
    </Router>
  );
};

TagManager.initialize({ gtmId: "GTM-PMKFN4K" });

export default withAuthentication(App);
