import React, { useState, useRef } from "react";
import { db, storage } from "../../firebase";
import Dropzone from "react-dropzone";
import { Confirm } from "semantic-ui-react";
import ProgressiveImage from "react-progressive-image";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  useMountEffect,
  convertFileSize,
  findFileType,
  findFileUsage,
} from "./Resources";
import _cloneDeep from "lodash.clonedeep";

export const FilesList = (props) => {
  const {
    authUser,
    id,
    type,
    item,
    item: { files, doneList },
    setItem,
    killSteps,
    header,
    demo,
  } = props;

  const timeout = useRef(null);
  const fileUploads = useRef([]);
  const fileQuota = authUser.premiumUser ? 262000000 : 52000000;

  const [badFiles, setBadFiles] = useState([]);
  const [fileUsage, setFileUsage] = useState(0);
  const [filesUploading, setFilesUploading] = useState(false);
  const [fileSortOn, setFileSortOn] = useState(false);
  const [fileDeleteOpen, setFileDeleteOpen] = useState(false);
  const [fileDeleteID, setFileDeleteID] = useState(null);
  const [fileDeleteFile, setFileDeleteFile] = useState(null);
  const [fileDelete, setFileDelete] = useState(false);

  useMountEffect(() => {
    setFileUsage(findFileUsage(files));
    return () => {
      clearTimeout(timeout.current);
      fileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const setItemFiles = (itemFiles) => {
    setFilesUploading(true);
    let newFiles = [];
    let badFiles = [];
    let fileUsageNew = Number(fileUsage);
    let itemFilesList = itemFiles.map((file) => {
      return new Promise((resolve) => {
        if (
          !checkItemFileMatch(file) &&
          (file.type !== "" || file.name.indexOf(".") !== -1) &&
          file.size + fileUsageNew <= fileQuota
        ) {
          fileUsageNew += file.size;
          newFiles.push({
            file: file,
            name: file.name,
            preview: file.preview,
            size: file.size,
            type: file.type,
            new: true,
          });
          resolve();
        } else {
          if (file.size + fileUsageNew > fileQuota) {
            badFiles.push({
              name: file.name,
              size: file.size,
              reason:
                "File is " +
                convertFileSize(file.size + fileUsageNew - fileQuota) +
                " too large for your quota",
            });
            resolve();
          } else if (file.type === "" && file.name.indexOf(".") === -1) {
            badFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, folders are not supported",
            });
            resolve();
          } else {
            badFiles.push({
              name: file.name,
              size: file.size,
              reason: "File name already exists",
            });
            resolve();
          }
        }
      });
    });
    Promise.all(itemFilesList)
      .then(() => {
        setBadFiles(badFiles);
        setFilesUploading(newFiles.length > 0 ? true : false);
        setItem({ ...item, files: [...files, ...newFiles] }).then((update) => {
          killSteps(update);
          setItemFilesDone(_cloneDeep(update), newFiles);
        });
      })
      .catch((error) => console.log(error));
  };

  const setItemFilesDone = (update, newFiles) => {
    let progress = 0;
    let fileCount = update.files.length - newFiles.length;
    let fileDropProgress = document.getElementById("file-drop-progress");
    let newFilesList = newFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/files/${file.name}`)
          : storage.getRef(`${type}/${id}/files/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        fileUploads.current.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fileDropProgress)
              fileDropProgress.style.width =
                (progress / newFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              setFileUsage(fileUsage + uploadTask.snapshot.metadata.size);
              update.files[fileCount + index].downloadURL = downloadURL;
              update.files[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.files[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.files[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.files[fileCount + index].file = null;
              update.files[fileCount + index].preview = null;
              update.files[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveFiles(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(newFilesList)
      .then(() => {
        fileUploads.current = [];
        timeout.current = setTimeout(() => {
          setFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fileDropProgress) fileDropProgress.style.width = "0";
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const saveFiles = (update) => {
    let files = {
      files: update.files ? update.files : [],
    };
    db.saveItem(type, id, files);
  };

  const checkItemFileMatch = (file) => {
    let hasMatch = false;
    files.forEach((itemFile) => {
      if (itemFile.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const closeBadFiles = () => {
    setBadFiles([]);
  };

  const onSortFileStart = () => {
    clearTimeout(timeout.current);
    setFileSortOn(true);
  };

  const onSortFileEnd = ({ oldIndex, newIndex }) => {
    setItem({ ...item, files: arrayMove([...files], oldIndex, newIndex) });
    timeout.current = setTimeout(() => setFileSortOn(false), 500);
  };

  const openDeleteFile = (id, file) => {
    setFileDeleteOpen(true);
    setFileDeleteID(id);
    setFileDeleteFile(file);
  };

  const cancelDeleteFile = (event) => {
    event.preventDefault();
    setFileDeleteOpen(false);
    setFileDeleteID(null);
    setFileDeleteFile(null);
  };

  const deleteItemFile = () => {
    setFileDeleteOpen(false);
    setFileDelete(true);
    setItem({
      ...item,
      files: Object.assign([], [...files], {
        [fileDeleteID]: {
          ...files[fileDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(fileDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.files.splice(fileDeleteID, 1);
            setItem(updateClone);
            if (!demo) saveFiles(updateClone);
            killSteps(updateClone);
            setFileUsage(findFileUsage(updateClone.files));
            timeout.current = setTimeout(() => {
              setFileDeleteID(null);
              setFileDelete(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <div
      className={`item-section item-files ${fileSortOn ? "sorting" : ""} ${
        fileDelete ? "deleting" : ""
      }`}
    >
      <Confirm
        className="confirm"
        open={fileDeleteOpen}
        header="Are you sure?"
        content="The file will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteFile}
        onConfirm={deleteItemFile}
      />
      {header ? <label>{header}</label> : null}
      {!doneList.files && !doneList.details ? (
        <Dropzone
          style={{}}
          disabled={filesUploading}
          className={`item-files-dropzone files-dropzone ${
            filesUploading ? "uploading" : ""
          }`}
          activeClassName="active"
          onDrop={(files) => setItemFiles(files)}
        >
          <div className="item-files-drop files-drop">
            <h4>Drop your files here or...</h4>
            <div className="btn btn-primary">click to upload</div>
            <div className="item-files-usage files-usage">
              <label>
                <i className="icon icon-notification" />
                You have {convertFileSize(fileQuota - fileUsage)} left in your{" "}
                {convertFileSize(fileQuota)} file quota
              </label>
              <div
                className={`progress-bar negative done-${Math.round(
                  (fileUsage / fileQuota) * 100
                )} ${(fileUsage / fileQuota) * 100 > 100 ? "burst" : ""}`}
              >
                <span
                  className="progress-bar-fill"
                  style={{ width: `${(fileUsage / fileQuota) * 100}%` }}
                />
              </div>
            </div>
            <span className="files-drop-msg">
              <i className="icon icon-dropzone" />
              <p>Drop files here</p>
            </span>
            <span className="files-drop-progress">
              <span id="file-drop-progress" className="drop-progress-bar" />
              <span className="upload-loading" />
            </span>
          </div>
        </Dropzone>
      ) : null}
      {badFiles.length > 0 ? (
        <div className="item-files-bad files-bad-wrap">
          <span className="close-files-bad" onClick={closeBadFiles}>
            <i className="icon icon-cross" />
          </span>
          <label>Sorry, we couldn't upload these files:</label>
          <ul className={`files-bad`}>
            {Object.keys(badFiles).map((key) => (
              <li key={key} className="file-bad">
                <p>
                  <span className="file-name">
                    <span className="file-text">{badFiles[key].name}</span>
                    <span className="file-size">
                      ({convertFileSize(badFiles[key].size)})
                    </span>
                  </span>
                  <span className="file-reason">{badFiles[key].reason}</span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {files ? (
        <SortableList
          files={files}
          convertFileSize={convertFileSize}
          findFileType={findFileType}
          openDeleteFile={openDeleteFile}
          helperClass={"item-drag file-drag"}
          distance={window.innerWidth > 1024 ? 5 : 0}
          pressDelay={window.innerWidth <= 1024 ? 200 : 0}
          axis={"xy"}
          onSortStart={({ node, index, collection }, event) => {
            event.preventDefault();
            onSortFileStart();
          }}
          onSortEnd={onSortFileEnd}
        />
      ) : null}
    </div>
  );
};

const SortableList = SortableContainer(
  ({ files, convertFileSize, findFileType, openDeleteFile }) => (
    <ul className="files-list cards-list sortable">
      {files.map((file, index) => (
        <SortableItem
          key={file.name}
          index={index}
          file={file}
          disabled={file.new}
          fileContent={
            <div className="file-content">
              {findFileType(file) === "image-embed" ? (
                <div className="file-img">
                  {file.downloadURL ? (
                    <ProgressiveImage
                      placeholder={require("../../images/loading-sm.svg")}
                      src={file.downloadURL}
                      key={file.downloadURL}
                    >
                      {(src, loading) => (
                        <div className="file-img-wrap">
                          <span
                            className={`file-img-bg progressive ${
                              loading ? "loading" : "done"
                            }`}
                            style={{ backgroundImage: `url("${src}")` }}
                          />
                          <span className="file-detail">
                            <p className="file-detail-content">
                              <span className="file-text">{file.name}</span>
                              <span className="file-size">
                                {convertFileSize(file.size)}
                              </span>
                            </p>
                          </span>
                        </div>
                      )}
                    </ProgressiveImage>
                  ) : null}
                  {file.preview ? (
                    <span
                      className="file-img-preview"
                      style={{ backgroundImage: `url('${file.preview}')` }}
                    />
                  ) : null}
                </div>
              ) : (
                <div className={`file-icon ${findFileType(file)}`}>
                  <i className="icon icon-file-empty" />
                  <span className="file-detail">
                    <p className="file-detail-content">
                      <span className="file-text">{file.name}</span>
                      <span className="file-size">
                        {convertFileSize(file.size)}
                      </span>
                    </p>
                  </span>
                </div>
              )}
              {file.downloadURL ? (
                <span>
                  <span className="card-download-wrap">
                    <a
                      href={file.downloadURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="card-download">
                        <i className="icon icon-download" />
                      </span>
                    </a>
                  </span>
                  <span className="card-delete-wrap">
                    <span
                      className="card-delete"
                      onClick={() => openDeleteFile(index, file)}
                    >
                      <i className="icon icon-trash" />
                    </span>
                  </span>
                </span>
              ) : (
                <span className="file-loading" />
              )}
            </div>
          }
        />
      ))}
    </ul>
  )
);

const SortableItem = SortableElement(({ file, fileContent }) => (
  <li
    className={`card selected ${file.new ? "new" : ""} ${
      !file.downloadURL ? "loading" : ""
    } ${file.deleted ? "deleted" : ""}`}
  >
    {fileContent}
  </li>
));

export const FileDisplay = (props) => {
  const { file, loadItem, itemLoadError } = props;
  return (
    <div className="file-content">
      <a href={file.downloadURL} target="_blank" rel="noopener noreferrer">
        {findFileType(file) === "image-embed" ? (
          <span className="file-img">
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => {
                console.log("ERROR - Load File", error);
                itemLoadError();
              }}
              src={file.downloadURL}
            >
              {(src, loading) => (
                <div className="file-img-wrap">
                  <span
                    className={`file-img-bg progressive ${
                      loading ? "loading" : "done"
                    }`}
                    style={{ backgroundImage: `url("${src}")` }}
                    ref={!loading ? loadItem : null}
                  />
                  <span className="file-detail">
                    <p className="file-detail-content">
                      <span className="file-text">{file.name}</span>
                      <span className="file-size">
                        {convertFileSize(file.size)}
                      </span>
                    </p>
                  </span>
                </div>
              )}
            </ProgressiveImage>
          </span>
        ) : (
          <span className={`file-icon ${findFileType(file)}`}>
            <i className="icon icon-file-empty" />
            <span className="file-detail">
              <p className="file-detail-content">
                <span className="file-text">{file.name}</span>
                <span className="file-size">{convertFileSize(file.size)}</span>
              </p>
            </span>
          </span>
        )}
      </a>
      <a href={file.downloadURL} target="_blank" rel="noopener noreferrer">
        <span className="card-download">
          <i className="icon icon-download" />
        </span>
      </a>
    </div>
  );
};

export default FilesList;
