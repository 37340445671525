import React, { useState } from "react";
import { findHeaderName } from "../../shared/Resources";
import {
  ItemColor,
  ItemFont,
  ItemFontStyle,
  ItemFontSize,
  ItemCSS,
} from "../Elements";

export const HeadersElement = (props) => {
  const {
    sections,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateFont,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, fonts, palette },
  } = props;

  const [headers, setHeaders] = useState([
    { name: "h1", active: false },
    { name: "h2", active: false },
    { name: "h3", active: false },
    { name: "h4", active: false },
    { name: "h5", active: false },
  ]);

  const toggleHeader = (index) => {
    const headersCopy = [...headers];
    headersCopy[index].active = !headersCopy[index].active;
    setHeaders(headersCopy);
  };

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  return (
    <div
      id="header-element"
      className={`element element-headers ${
        sections.headers.active ? "active" : ""
      } ${sections.headers.css ? "css" : ""} ${
        elements.headers.disabled ? "disabled" : ""
      }`}
    >
      <div
        className="element-content"
        onClick={() => toggleSection("headers", true)}
      >
        {headers.map((header, index) => (
          <div className={`header-wrap ${header.name}`} key={`header-${index}`}>
            <style>
              {`
                #elements ${header.name}, .text-content ${header.name} {
                  color: ${findColorVal(header.name, "color")};
                  font-family: ${findFontVal(header.name, "fontFamily")};
                  font-size: ${
                    elements[header.name] && elements[header.name].fontSize
                      ? elements[header.name].fontSize
                      : "inherit"
                  };
                  font-style: ${
                    elements[header.name] && elements[header.name].fontStyle
                      ? elements[header.name].fontStyle
                      : "inherit"
                  };
                  font-weight: ${
                    elements[header.name] && elements[header.name].fontWeight
                      ? elements[header.name].fontWeight
                      : "inherit"
                  };
                }
              `}
            </style>

            <header.name onClick={() => toggleHeader(index)}>
              Header {findHeaderName(header.name)}
            </header.name>

            <div
              className={`edit-element ${header.active ? "active" : ""}`}
              key={header.name}
            >
              <div className="edit-item">
                <ItemFont item={header.name} {...fontProps} />

                <ItemFontStyle item={header.name} {...fontStyleProps} />

                <ItemFontSize item={header.name} {...defaultProps} />

                <ItemColor
                  type="color"
                  item={header.name}
                  label="font color"
                  {...colorProps}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {sections.headers.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>All headers font and color</label>

            <div className="edit-item-sub">
              <ItemFont item={"headers"} {...fontProps} />

              <ItemFontStyle item={"headers"} {...fontStyleProps} />

              <ItemColor
                type="color"
                item="headers"
                label="font color"
                {...colorProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.headers.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("headers")}>
          <span>{sections.headers.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.headers.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.headers}
              label={`h1, h2, h3, h4, h5`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.h1}
              label={`h1`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.h2}
              label={`h2`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.h3}
              label={`h3`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.h4}
              label={`h4`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.h5}
              label={`h5`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("headers")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
