import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "components/core/Navigation";
import * as routes from "constants/routes";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerShow: false,
      headerHide: false,
      headerActive: false,
      currPath: "",
    };
  }

  componentDidMount() {
    this.scrollListener();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    const { currPath } = this.state;
    if (currPath !== window.location.pathname) {
      this.scrollListener();
      this.setState({
        currPath: window.location.pathname,
        headerShow: false,
        headerHide: false,
        headerActive: false,
      });
    }
  }

  oldScroll = 0;

  handleScroll = (event) => {
    const { headerShow, headerHide } = this.state;
    const winLimit = 333;
    let pageY = window.scrollY;
    if (
      pageY > winLimit &&
      headerShow &&
      this.oldScroll < pageY &&
      !headerHide
    ) {
      this.setState({
        headerHide: true,
      });
    } else if (
      pageY > winLimit &&
      !headerShow &&
      this.oldScroll > pageY &&
      headerHide
    ) {
      this.setState({
        headerHide: false,
      });
    }
    if (pageY > window.innerHeight && !headerShow && this.oldScroll > pageY) {
      this.setState({
        headerShow: true,
      });
    } else if ((pageY < winLimit && headerShow) || this.oldScroll < pageY) {
      this.setState({
        headerShow: false,
      });
    }
    if (pageY === 0) {
      this.setState({
        headerShow: false,
        headerHide: false,
      });
    }
    if (pageY > winLimit) {
      this.setState({
        headerActive: true,
      });
    } else {
      this.setState({
        headerActive: false,
      });
    }
    this.oldScroll = pageY;
  };

  scrollListener = () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/home" ||
      window.location.pathname === "/about"
    ) {
      window.addEventListener("scroll", this.handleScroll);
    } else {
      window.removeEventListener("scroll", this.handleScroll);
      setTimeout(() => {
        this.setState({ headerActive: false });
      });
    }
  };

  render() {
    const { headerShow, headerHide, headerActive } = this.state;

    return (
      <header
        id="header"
        className={`header-wrap ${headerShow ? "show" : ""} ${
          headerHide ? "hide" : ""
        } ${headerActive ? "active" : ""}`}
      >
        {/*<div class="header-bgs">
          <div className="header-bg projects" />
          <div className="header-bg styleguides" />
          <div className="header-bg showcases" />
        </div>*/}
        <div className="logo-wrap">
          <Link to={routes.Home} onClick={this.scrollListener}>
            <img src={require("../../images/logo.png")} alt="DesignGapp" />
            <p>DesignGapp</p>
          </Link>
        </div>
        <Navigation scrollListener={this.scrollListener} />
      </header>
    );
  }
}
