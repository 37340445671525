import React, { useState } from "react";
import {
  ItemColor,
  ItemBoxShadow,
  ItemFont,
  ItemFontStyle,
  ItemFontSize,
  ItemLineHeight,
  ItemPadding,
  ItemBorderStyle,
  ItemBorderWidth,
  ItemBorderRadius,
  ItemCSS,
} from "../Elements";

export const InputsElement = (props) => {
  const {
    sections,
    borderStyles,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateFont,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, fonts, palette },
  } = props;

  const [inputText, setInputText] = useState("");
  const [textareaText, setTextareaText] = useState("");

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  const borderProps = {
    elements: elements,
    updateStyle: updateStyle,
    borderStyles: borderStyles,
  };

  const boxShadowProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateStyle: updateStyle,
    updateColor: updateColor,
  };

  return (
    <div
      id="input-element"
      className={`element element-inputs ${
        sections.inputs.active ? "active" : ""
      } ${sections.inputs.css ? "css" : ""} ${
        elements.input.disabled ? "disabled" : ""
      }`}
    >
      <div
        className="element-content"
        onClick={() => toggleSection("inputs", true)}
      >
        <div className="input-group">
          <style>
            {`
              #elements .element-content .textarea-group textarea {
                resize: none;
              }
              #elements .element-content .input-group input, 
              #elements .element-content .textarea-group textarea {
                background: ${findColorVal("input", "background")};
                border-color: ${findColorVal("input", "borderColor")};
                border-radius: ${elements.input.borderRadius};
                border-style: ${elements.input.borderStyle};
                border-width: ${elements.input.borderWidth};
                box-shadow: ${elements.input.boxShadow} ${findColorVal(
              "input",
              "boxShadowColor"
            )};
                color: ${findColorVal("input", "color")};
                font-family: ${findFontVal("input", "fontFamily")};
                font-size: ${elements.input.fontSize};
                font-style: ${elements.input.fontStyle};
                font-weight: ${elements.input.fontWeight};
                line-height: ${elements.input.lineHeight};
                padding: ${elements.input.padding};
                transition: all ease 0.33s;
              }
              #elements .element-content .input-group input:focus, #elements .element-content .input-group input.focus,
              #elements .element-content .textarea-group textarea:focus, #elements .element-content .textarea-group textarea.focus {
                background: ${findColorVal("inputFocus", "background")};
                border-color: ${findColorVal("inputFocus", "borderColor")};
                box-shadow: ${elements.inputFocus.boxShadow} ${findColorVal(
              "inputFocus",
              "boxShadowColor"
            )};
              }
              #elements .element-content .input-group input.error,
              #elements .element-content .textarea-group textarea.error {
                background: ${findColorVal("inputError", "background")};
                border-color: ${findColorVal("inputError", "borderColor")};
                box-shadow: ${elements.inputError.boxShadow} ${findColorVal(
              "inputError",
              "boxShadowColor"
            )};
              }
            `}
          </style>
          <input
            type="text"
            className="input"
            value={inputText}
            placeholder="Text Input"
            onChange={(event) => setInputText(event.target.value)}
          />
          <input
            type="text"
            className="input focus"
            value={inputText}
            placeholder="Text Input Focus"
            onChange={(event) => setInputText(event.target.value)}
          />
          <input
            type="text"
            className="input error"
            value={inputText}
            placeholder="Text Input Error"
            onChange={(event) => setInputText(event.target.value)}
          />
        </div>

        <div className="textarea-group">
          <textarea
            rows="4"
            className="textarea"
            value={textareaText}
            placeholder="Textarea"
            onChange={(event) => setTextareaText(event.target.value)}
          />
          <textarea
            rows="4"
            className="textarea focus"
            value={textareaText}
            placeholder="Textarea"
            onChange={(event) => setTextareaText(event.target.value)}
          />
          <textarea
            rows="4"
            className="textarea error"
            value={textareaText}
            placeholder="Textarea"
            onChange={(event) => setTextareaText(event.target.value)}
          />
        </div>
      </div>

      {sections.inputs.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Input / textarea font, styles, and colors</label>

            <div className="edit-item-sub">
              <ItemFont item={"input"} {...fontProps} />

              <ItemFontStyle item={"input"} {...fontStyleProps} />

              <ItemFontSize item={"input"} {...defaultProps} />

              <ItemLineHeight item={"input"} {...defaultProps} />

              <ItemColor
                type="color"
                item="input"
                label="font color"
                {...colorProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemPadding item="input" {...defaultProps} />

              <ItemColor
                type="background"
                item="input"
                label="bg color"
                {...colorProps}
              />

              <ItemBoxShadow
                item={"input"}
                label={"box shadow"}
                {...boxShadowProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemBorderStyle item="input" {...borderProps} />

              <ItemBorderWidth item="input" {...defaultProps} />

              <ItemBorderRadius item="input" {...defaultProps} />

              <ItemColor
                type="borderColor"
                item="input"
                label="border color"
                {...colorProps}
              />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Input / textarea focus styles</label>

            <div className="edit-item-sub">
              <ItemColor
                type="background"
                item="inputFocus"
                label="bg color"
                {...colorProps}
              />

              <ItemColor
                type="borderColor"
                item="inputFocus"
                label="border color"
                {...colorProps}
              />

              <ItemBoxShadow
                item={"inputFocus"}
                label={"box shadow"}
                {...boxShadowProps}
              />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Input / textarea error styles</label>

            <div className="edit-item-sub">
              <ItemColor
                type="background"
                item="inputError"
                label="bg color"
                {...colorProps}
              />

              <ItemColor
                type="borderColor"
                item="inputError"
                label="border color"
                {...colorProps}
              />

              <ItemBoxShadow
                item={"inputError"}
                label={"box shadow"}
                {...boxShadowProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.inputs.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("inputs")}>
          <span>{sections.inputs.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.inputs.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.input}
              label={`input, textarea`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.inputFocus}
              label={`input:focus, textarea:focus`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.inputError}
              label={`input.error, textarea.error`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("inputs")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
