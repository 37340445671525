import React from "react";
import { connect } from "react-redux";
import { db, firebase } from "../firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        authUser ? this.setUser(authUser) : this.setUser(null);
      });
    }

    setUser = (authUser) => {
      const {
        setAuthUser,
        setUserImg,
        setUserName,
        userMembership,
        setUserMembership,
      } = this.props;
      if (authUser) {
        setAuthUser(authUser);
        db.getUser(authUser.uid).then((result) => {
          if (result.val() !== null) {
            setUserName(result.val().username);
            setUserImg(
              result.val().profileImage
                ? result.val().profileImage
                : authUser.photoURL
            );
            if (!userMembership) {
              db.getUserMembership(authUser.uid).then((result) => {
                setUserMembership(result.val() ? result.val() : "none");
              });
            }
          } else {
            setUserName(authUser.displayName);
            setUserImg(authUser.photoURL);
          }
        });
      } else {
        setAuthUser(false);
        setUserMembership(null);
      }
    };

    render() {
      return <Component />;
    }
  }

  const mapStateToProps = (state) => ({
    userMembership: state.sessionState.userMembership,
  });

  const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch({ type: "AUTH_USER", authUser }),
    setUserImg: (img) => dispatch({ type: "USER_IMG", img }),
    setUserName: (name) => dispatch({ type: "USER_NAME", name }),
    setUserMembership: (membership) =>
      dispatch({ type: "USER_MEMBERSHIP", membership }),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
