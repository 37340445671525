import React, { Component } from "react";
import { db } from "../../firebase";
import {
  Modal,
  Input,
  Popup,
  TextArea,
  Button,
  Checkbox,
} from "semantic-ui-react";
import shortid from "shortid";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class Share extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getShare = () => {
    const { id, type } = this.props;
    db.getItemShare(type, id).then((res) => {
      if (res.val() !== null) {
        this.setState({
          share: res.val(),
          shareLoad: true,
        });
      } else {
        this.setShare();
      }
    });
  };

  setShare = () => {
    let share = { id: shortid.generate(), active: false };
    this.setState(
      {
        share: share,
        shareLoad: true,
      },
      () => {
        this.saveShare();
      }
    );
  };

  saveShare = () => {
    const { id, type } = this.props;
    const { share } = this.state;
    let data = {
      share: share,
    };
    db.saveItem(type, id, data);
  };

  shareInputHighlight = (event) => {
    event.target.select();
  };

  shareToggle = (event, data) => {
    event.preventDefault();
    const { share } = this.state;
    const shareCopy = { ...share };
    shareCopy.active = !shareCopy.active;
    this.setState(
      {
        share: shareCopy,
      },
      () => {
        this.saveShare();
      }
    );
  };

  shareCopied = () => {
    this.setState(
      {
        shareCopy: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            shareCopy: false,
          });
        }, 2000);
      }
    );
  };

  shareEmbedCopied = () => {
    this.setState(
      {
        shareEmbedCopy: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            shareEmbedCopy: false,
          });
        }, 2000);
      }
    );
  };

  render() {
    const { type, shareOpen, closeShareModal } = this.props;
    const { share, shareLoad, shareCopy, shareEmbedCopy } = this.state;

    const shareURL = `${window.location.hostname}/${
      type === "projects" ? "p" : type === "styleguides" ? "s" : "h"
    }/${share.id}`;
    const iframeEmbed = `<iframe id="designgapp" title="${
      type === "projects"
        ? "Project"
        : type === "styleguides"
        ? "Styleguide"
        : "Showcase"
    }" width="100%" height="768" frameborder="0" src="https://${shareURL}" onload="window.addEventListener('message', (e) => document.getElementById('designgapp').height = JSON.parse(e.data).height+'px')" allowfullscreen />`;

    return (
      <Modal
        className={`share-modal ${share.active ? "enabled" : "disabled"} ${
          shareLoad ? "active" : ""
        }`}
        open={shareOpen}
        onMount={this.getShare}
        onUnmount={() => this.setState({ shareLoad: false, share: [] })}
        onClose={closeShareModal}
      >
        <div className="modal-close-wrap">
          <span className="modal-close" onClick={closeShareModal}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        <Modal.Header>
          Share your{" "}
          {type === "projects"
            ? "project"
            : type === "styleguides"
            ? "styleguide"
            : "showcase"}
        </Modal.Header>
        <Modal.Content>
          <span className="content-inline">
            <div className="share-container">
              <div className="share-toggle">
                <label>
                  Turn public sharing off / on
                  <Popup
                    hoverable
                    hideOnScroll
                    position="top right"
                    className="popup-share-help"
                    trigger={
                      <div className="share-help">
                        <i className="icon icon-question-circle" />
                      </div>
                    }
                    header={
                      <p>
                        The share link / embed code lets people view the
                        published{" "}
                        {type === "projects"
                          ? "project"
                          : type === "styleguides"
                          ? "styleguide"
                          : "showcase"}{" "}
                        but not edit anything.
                      </p>
                    }
                  />
                </label>
                <Checkbox
                  toggle
                  checked={share.active}
                  onChange={this.shareToggle}
                />
              </div>
              <div className={`share-elements ${shareCopy ? "copied" : ""}`}>
                <Input
                  type="text"
                  name="share-input"
                  value={`${shareURL}`}
                  disabled={!share.active}
                  className="share-input"
                  onClick={this.shareInputHighlight}
                />
                <CopyToClipboard
                  text={`https://${shareURL}`}
                  onCopy={this.shareCopied}
                >
                  <Button className="btn btn-primary" disabled={!share.active}>
                    Copy
                  </Button>
                </CopyToClipboard>
                <span className="tooltip">Link Copied</span>
              </div>
              <div className="share-embed-wrap">
                <label>Embed code</label>
                <div
                  className={`share-embed ${shareEmbedCopy ? "copied" : ""}`}
                >
                  <TextArea
                    rows="4"
                    disabled={!share.active}
                    value={iframeEmbed}
                    onClick={this.shareInputHighlight}
                  />
                  <CopyToClipboard
                    text={iframeEmbed}
                    onCopy={this.shareEmbedCopied}
                  >
                    <Button
                      className="btn btn-primary"
                      disabled={!share.active}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                  <span className="tooltip">Code Copied</span>
                </div>
              </div>
              <button
                className="btn btn-primary btn-done"
                onClick={closeShareModal}
              >
                Done here
              </button>
            </div>
          </span>
        </Modal.Content>
      </Modal>
    );
  }
}

const INITIAL_STATE = {
  shareOpen: false,
  shareCopy: false,
  shareEmbedCopy: false,
  shareLoad: false,
  share: [],
};
