import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../firebase";
import { Modal } from "semantic-ui-react";
import * as routes from "constants/routes";
import {
  useMountEffect,
  AsyncState,
  loadFonts,
  capString,
  findFileType,
  setItemColors,
  isBrendan,
} from "./Resources";
import Members from "./Members";
import Share from "./Share";
import ProjectPublished from "../projects/ProjectPublished";
import StyleguidePublished from "../styleguides/StyleguidePublished";
import ShowcasePublished from "../showcases/ShowcasePublished";

export const Item = (props) => {
  const { history, share, type, label, authUser, itemID } = props;

  useEffect(() => window.scrollTo(0, 0), []);

  const [item, setItem] = AsyncState({});
  const [itemTemplate, setItemTemplate] = useState([]);
  const [itemDeletedModal, setItemDeletedModal] = useState(false);
  const [itemDeniedModal, setItemDeniedModal] = useState(false);
  const [itemArchivedModal, setItemArchivedModal] = useState(false);
  const [itemIsReady, setItemIsReady] = useState(false);
  const [shareItemOpen, setShareItemOpen] = useState(false);
  const [manageUsersOpen, setManageUsersOpen] = useState(false);
  const [hideSpinner, setHideSpinner] = useState(false);

  const itemCount = useRef(0);
  const idleTime = useRef(3);
  const timeout = useRef(null);
  const interval = useRef(null);
  const itemColors = setItemColors(item.palette, item.theme);

  useEffect(() => {
    return () => {
      if (
        history.location.pathname !== `/${label}/edit/${itemID}` &&
        item.theme
      ) {
        document.body.classList.remove(item.theme);
      }
    };
  }, [history.location.pathname, item.theme, itemID, label]);

  useMountEffect(() => {
    getTemplate();
    return () => {
      clearTimeout(timeout.current);
      clearInterval(interval.current);
    };
  });

  const getTemplate = () => {
    storage.getCurrentTemplate(label).then((response) => {
      const request = new XMLHttpRequest();
      request.open("GET", response, true);
      request.onreadystatechange = (request) => {
        if (request.target.readyState === 4) {
          const data = JSON.parse(request.target.response);
          getItem(itemID, data);
        }
      };
      request.send();
    });
  };

  const getItem = (itemID, template) => {
    db.getItem(type, itemID).then((snapshot) => {
      if (
        (snapshot.val() !== null &&
          Object.keys(snapshot.val().users).indexOf(authUser.uid) !== -1) ||
        (snapshot.val() !== null && share) ||
        (snapshot.val() !== null && authUser.uid === isBrendan())
      ) {
        if (
          snapshot.val().version &&
          template.version &&
          template.version !== snapshot.val().version
        ) {
          storage
            .getCurrentArchivedTemplate(label, snapshot.val().version)
            .then((response) => {
              const request = new XMLHttpRequest();
              request.open("GET", response, true);
              request.onreadystatechange = (request) => {
                if (request.target.readyState === 4) {
                  const data = JSON.parse(request.target.response);
                  initItem(snapshot.val(), data);
                }
              };
              request.send();
            });
        } else {
          initItem(snapshot.val(), template);
        }
      } else if (snapshot.val() === null) {
        setItemDeletedModal(true);
      } else if (snapshot.val().archived) {
        setItemArchivedModal(true);
      } else {
        setItemDeniedModal(true);
      }
    });
  };

  const initItem = (snapshot, template) => {
    if (!snapshot.settings && template) {
      snapshot.settings = template.settings;
    } else {
      if (snapshot.settings.accessibility === null) {
        snapshot.settings.accessibility = true;
      }
    }

    if (type === "projects") {
      if (snapshot.settings && snapshot.settings.order) {
        snapshot.settings.orderDisabled = [];
        snapshot.settings.order.forEach((section, index) => {
          if (
            (section === "aesthetic" &&
              !snapshot.adjectives &&
              !snapshot.palette &&
              !snapshot.images &&
              !snapshot.font) ||
            (section === "audience" && !snapshot.audience) ||
            (section === "details" && !snapshot.details && !snapshot.files)
          ) {
            snapshot.settings.orderDisabled.push(section);
          }
        });
      }
      if (snapshot.font.type === "advanced" && snapshot.fonts) {
        loadFonts(snapshot.fonts);
      }
    }

    if (type === "styleguides") {
      if (snapshot.settings && snapshot.settings.order) {
        snapshot.settings.orderDisabled = [];
        snapshot.settings.order.forEach((section, index) => {
          if (
            (section === "logos" && !snapshot.logos) ||
            (section === "palette" && !snapshot.palette) ||
            (section === "fonts" && !snapshot.fonts) ||
            (section === "images" && !snapshot.images) ||
            (section === "icons" && !snapshot.icons) ||
            (section === "elements" && !snapshot.elements) ||
            (section === "elements" &&
              snapshot.elements &&
              snapshot.elements.headers.disabled &&
              snapshot.elements.p.disabled &&
              snapshot.elements.a.disabled &&
              snapshot.elements.blockquote.disabled &&
              snapshot.elements.code.disabled &&
              snapshot.elements.buttons.disabled &&
              snapshot.elements.dropdownMenu.disabled &&
              snapshot.elements.input.disabled &&
              snapshot.elements.toggle.disabled) ||
            (section === "files" && !snapshot.files)
          ) {
            snapshot.settings.orderDisabled.push(section);
          }
        });
      }
      if (snapshot.fonts) {
        loadFonts(snapshot.fonts);
      }
    }

    if (type === "showcases") {
      if (snapshot.settings && snapshot.settings.order) {
        snapshot.settings.orderDisabled = [];
        snapshot.settings.order.forEach((section, index) => {
          if (
            (section === "screens" && !snapshot.screens) ||
            (section === "palette" && !snapshot.palette) ||
            (section === "fonts" && !snapshot.fonts) ||
            (section === "files" && !snapshot.files)
          ) {
            snapshot.settings.orderDisabled.push(section);
          }
        });
      }
      if (snapshot.fonts) {
        loadFonts(snapshot.fonts);
      }
    }

    if (snapshot.files) {
      let badFiles = [];
      snapshot.files.forEach((file) => {
        if (file.new) {
          badFiles.push(file);
        }
      });
      badFiles.forEach((file) => {
        let i = snapshot.files.indexOf(file);
        snapshot.files.splice(i, 1);
      });
    }
    document.body.classList.add(snapshot.theme);
    setItemTemplate(template);
    setItem(snapshot);
    itemLoadCheck();
    itemIdleCheck();
  };

  const loadItem = () => {
    if (!itemIsReady) {
      itemCount.current = itemCount.current + 1;
      itemLoadCheck();
    }
  };

  const itemLoadCheck = () => {
    let imageCount = 0;
    let fileCount = 0;

    if (item.images) {
      imageCount = item.images.length + (type === "projects" ? 2 : 0);
    }
    if (item.files) {
      fileCount = item.files.length;
      item.files.forEach((file) => {
        if (findFileType(file) !== "image-embed") {
          fileCount--;
        }
      });
    }
    if (
      imageCount + fileCount === itemCount.current ||
      idleTime.current === 0
    ) {
      timeout.current = setTimeout(() => {
        setItemIsReady(true);
        window.top.postMessage(
          JSON.stringify({ height: document.body.scrollHeight }),
          "*"
        );
        timeout.current = setTimeout(() => {
          setHideSpinner(true);
        }, 1000);
      }, 670);
    }
  };

  const itemIdleCheck = () => {
    interval.current = setInterval(() => {
      if (idleTime.current > 0) {
        idleTime.current = idleTime.current - 1;
      }
      if (idleTime.current === 0) {
        clearInterval(interval.current);
        itemLoadCheck();
      }
    }, 1000);
  };

  const openShareItemModal = (itemID) => {
    setShareItemOpen(true);
  };

  const closeShareItemModal = () => {
    setShareItemOpen(false);
  };

  const manageItemUsers = () => {
    setManageUsersOpen(true);
  };

  const closeManageUsersModal = (bool) => {
    setManageUsersOpen(bool);
  };

  const updateSettings = (settings, noSave) => {
    setItem({ ...item, settings: settings });
    if (!noSave) {
      db.saveItemSettings(type, itemID, { ...settings, orderDisabled: [] });
    }
  };

  const saveTheme = (theme) => {
    if (theme) {
      document.body.classList.remove(item.theme);
      document.body.classList.add(theme);
      setItem({ ...item, theme: theme }).then((update) => {
        db.saveItemTheme(type, itemID, theme);
      });
    }
  };

  const itemProps = {
    history: history,
    authUser: authUser,
    item: item,
    itemTemplate: itemTemplate,
    itemID: itemID,
    itemColors: itemColors,
    itemIsReady: itemIsReady,
    loadItem: loadItem,
    hideSpinner: hideSpinner,
    updateSettings: updateSettings,
    saveTheme: saveTheme,
    manageItemUsers: manageItemUsers,
    openShareItemModal: openShareItemModal,
  };

  return (
    <span className="item-outer-wrap">
      <Modal className="item-deleted-modal" open={itemDeletedModal}>
        <Modal.Header>Sorry, this {label} doesn't exist</Modal.Header>
        <Modal.Content>
          <p>
            The url may be incorrect or it may have been deleted by the owner.
            You can return to the {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(routes[capString(type)])}
          >
            back to my {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-archived-modal" open={itemArchivedModal}>
        <Modal.Header>Sorry, this {label} has been archived</Modal.Header>
        <Modal.Content>
          <p>
            Please contact the {label} owner to unarchive the {label}. You can
            return to the {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(routes[capString(type)])}
          >
            back to my {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-denied-modal" open={itemDeniedModal}>
        <Modal.Header>
          Sorry, you don't have access to this {label}
        </Modal.Header>
        <Modal.Content>
          <p>
            Please contact the {label} owner for help. You can return to the{" "}
            {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(routes[capString(label)])}
          >
            back to my {type}
          </button>
        </Modal.Content>
      </Modal>

      <Members
        type={type}
        closeManageUsersModal={closeManageUsersModal}
        manageUsersOpen={manageUsersOpen}
        id={itemID}
        data={label}
        authUser={authUser}
        history={history}
      />

      <Share
        type={type}
        shareOpen={shareItemOpen}
        closeShareModal={closeShareItemModal}
        id={itemID}
      />

      {type === "projects" ? (
        <ProjectPublished {...itemProps} />
      ) : type === "styleguides" ? (
        <StyleguidePublished {...itemProps} />
      ) : type === "showcases" ? (
        <ShowcasePublished {...itemProps} />
      ) : null}
    </span>
  );
};

export default Item;
