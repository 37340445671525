import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import { Link, useLocation } from "react-router-dom";
import ItemEdit from "./ItemEdit";
import * as routes from "constants/routes";
import ReactBody from "react-body";
import { Menu, Dropdown } from "semantic-ui-react";
import {
  capString,
  itemProject,
  itemStyleguide,
  itemShowcase,
} from "./Resources";
import { ThemeThumb } from "./Themes";
import { Helmet } from "react-helmet-async";
import shortid from "shortid";

export const Demo = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const userSignup = useSelector((state) => state.sessionState.userSignup);

  const [type, setType] = useState(null);
  const [label, setLabel] = useState(null);

  const location = useLocation();

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    if (location.hash && location.hash !== "") {
      setType(null);
      setLabel(null);
      setTimeout(() => {
        if (location.hash.indexOf("project") !== -1) {
          setType("projects");
          setLabel("project");
        } else if (location.hash.indexOf("styleguide") !== -1) {
          setType("styleguides");
          setLabel("styleguide");
        } else if (location.hash.indexOf("showcase") !== -1) {
          setType("showcases");
          setLabel("showcase");
        }
      });
    } else {
      setType(null);
      setLabel(null);
    }
  }, [location.hash]);

  return (
    <section id="demo" className="content-outer">
      <Helmet>
        <title>DesignGapp - Demo</title>
        <meta
          name="description"
          content="Try the demos of the DesignGapp projects, styleguides, showcases."
        />
      </Helmet>

      {type && label ? (
        <div id="demo">
          {!authUser && type ? (
            <div className="demo-wrap">
              <ItemEdit
                history={props.history}
                type={type}
                label={label}
                authUser={authUser}
                itemID={shortid.generate()}
                demo={true}
              />
              <DemoTypes type={type} label={label} />
              <ReactBody className="demo" />
            </div>
          ) : authUser && !userSignup ? (
            <div
              className="demo-auth content-outer"
              onLoad={window.scrollTo(0, 0)}
            >
              <div className="msg-logged-in">
                <h1>
                  You're already signed up. Go to your {type} page to create a{" "}
                  {label}.
                </h1>
                <Link className="btn btn-primary" to={routes[capString(type)]}>
                  {type}
                </Link>
              </div>
            </div>
          ) : (
            <div className="demo-wrap">
              <div className="content-loading no-header" />
              <ReactBody className="demo" />
            </div>
          )}
        </div>
      ) : (
        <div className="demo-list-outer">
          <div className="demo-list">
            <Link className="demo-type project" to={`${routes.Demo}#project`}>
              <div className="demo-type-content">
                <span className="demo-type-name">Project</span>
                <ThemeThumb item={itemProject} type="projects" />
              </div>
            </Link>
            <Link
              className="demo-type styleguide"
              to={`${routes.Demo}#styleguide`}
            >
              <div className="demo-type-content">
                <span className="demo-type-name">Styleguide</span>
                <ThemeThumb item={itemStyleguide} type="styleguides" />
              </div>
            </Link>
            <Link className="demo-type showcase" to={`${routes.Demo}#showcase`}>
              <div className="demo-type-content">
                <span className="demo-type-name">Showcase</span>
                <ThemeThumb item={itemShowcase} type="showcases" />
              </div>
            </Link>
          </div>
          <ReactBody className="demo-intro" />
        </div>
      )}
    </section>
  );
};

const DemoTypes = ({ type, label }) => (
  <Menu className={`demo-types ${label}`}>
    <Dropdown
      item
      className="right"
      trigger={
        <button
          className={`trigger btn btn-${
            label === "project"
              ? "primary"
              : label === "styleguide"
              ? "accent"
              : "tertiary"
          } ${label}`}
        >
          <span className="demo-type-name">
            {capString(label)}
            <i className="icon icon-chevron-down" />
          </span>
        </button>
      }
    >
      <Dropdown.Menu>
        <Link
          className={`menu-item project ${type === "projects" ? "active" : ""}`}
          to={`${routes.Demo}#project`}
        >
          <span className="demo-type-name">Project</span>
        </Link>
        <Link
          className={`menu-item styleguide ${
            type === "styleguides" ? "active" : ""
          }`}
          to={`${routes.Demo}#styleguide`}
        >
          <span className="demo-type-name">Styleguide</span>
        </Link>
        <Link
          className={`menu-item showcase ${
            type === "showcases" ? "active" : ""
          }`}
          to={`${routes.Demo}#showcase`}
        >
          <span className="demo-type-name">Showcase</span>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  </Menu>
);

export default compose(Demo);
