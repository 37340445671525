import React, { useState, useRef, useEffect, useCallback } from "react";
import TextContent from "../shared/TextContent";
import { SketchPicker } from "react-color";
import { Popup, Input } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  useMountEffect,
  headerText,
  helpText,
  customPresetColors,
} from "../shared/Resources";
import shortid from "shortid";
import tinycolor from "tinycolor2";
import _cloneDeep from "lodash.clonedeep";

export const Palette = (props) => {
  const {
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { palette, logos, screens, doneList },
    setItem,
    saveItem,
  } = props;

  useMountEffect(() => {
    findPaletteColors();
    findLogoColors();
    return () => {
      clearTimeout(timeout.current);
    };
  });

  const timeout = useRef(null);
  const [colorOn, setColorOn] = useState("primary");
  const [pickerOn, setPickerOn] = useState(true);
  const [recommendedColors, setRecommendedColors] = useState([]);
  const [altColorID, setAltColorID] = useState(null);
  const [altColor, setAltColor] = useState("");
  const [altPickerOn, setAltPickerOn] = useState(false);
  const [altColorDelete, setAltColorDelete] = useState(false);
  const [paletteSortOn, setPaletteSortOn] = useState(false);
  const [logoColors, setLogoColors] = useState([]);
  const [screenColors, setScreenColors] = useState([]);

  const findLogoColors = useCallback(() => {
    const logoColorsCopy = [];
    if (logos && logos.length > 0 && logos.find((logo) => logo.palette)) {
      logos.forEach((logo) => {
        if (logo.palette && !logo.deleted) {
          logo.palette.forEach((color) => {
            if (
              logoColorsCopy
                .map((logoColor) => logoColor.hex)
                .indexOf(color.hex) === -1
            ) {
              logoColorsCopy.push(color);
            }
          });
        }
      });
      setLogoColors(logoColorsCopy);
    }
  }, [logos]);

  useEffect(() => {
    findLogoColors();
  }, [logos, findLogoColors]);

  const findScreenColors = useCallback(() => {
    const screenColorsCopy = [];
    if (
      screens &&
      screens.length > 0 &&
      screens.find((screen) => screen.palette)
    ) {
      screens.forEach((screen) => {
        if (screen.palette && !screen.deleted) {
          screen.palette.forEach((color) => {
            if (
              screenColorsCopy
                .map((screenColor) => screenColor.hex)
                .indexOf(color.hex) === -1
            ) {
              screenColorsCopy.push(color);
            }
          });
        }
      });
      setScreenColors(screenColorsCopy);
    }
  }, [screens]);

  useEffect(() => {
    findScreenColors();
  }, [screens, findScreenColors]);

  const editPalette = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        palette: false,
      },
    });
  };

  const findPaletteColors = () => {
    if (palette && palette.colors && palette.colors.primary) {
      setPickerOn(false);
      findPrimaryAlt(palette.colors.primary);
    }
  };

  const toggleColorType = (type) => {
    setColorOn(type);
    setPickerOn(colorOn === type && pickerOn ? false : true);
  };

  const findPrimaryAlt = (color) => {
    let recommendedColors = [];
    let colorChecked = colorCheck(tinycolor(color));
    let compColor = tinycolor(colorChecked).complement();
    //let analColor = tinycolor(colorChecked).analogous();
    let monoColor = tinycolor(colorChecked).monochromatic();
    let splitCompColor = tinycolor(colorChecked).splitcomplement();
    let triadColor = tinycolor(colorChecked).triad();
    let tetradColor = tinycolor(colorChecked).tetrad();
    recommendedColors.push(
      { hex: compColor.toHexString() },
      { hex: monoColor[4].toHexString() },
      { hex: monoColor[5].toHexString() },
      { hex: tetradColor[1].toHexString() },
      { hex: splitCompColor[1].toHexString() },
      { hex: triadColor[1].toHexString() },
      { hex: splitCompColor[2].toHexString() },
      { hex: triadColor[2].toHexString() },
      { hex: tetradColor[3].toHexString() }
    );
    setRecommendedColors(recommendedColors);
  };

  const colorCheck = (color) => {
    let brightness = color.getBrightness();
    let isDark = color.isDark();
    if (brightness > 200) {
      color.darken(10);
    }
    if (brightness < 85 || (brightness < 115 && isDark === true)) {
      color.lighten(10);
      if (brightness > 100 && isDark === true) {
        color.saturate(2);
      }
    }
    return color;
  };

  const colorChange = (color, colorType) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {
          ...palette.colors,
          [colorType.toLowerCase()]: color.hex.toLowerCase(),
        },
      },
    });
    if (colorType === "primary") {
      findPrimaryAlt(color.hex.toLowerCase());
    }
    fixInputFocus();
  };

  const fixInputFocus = () => {
    // Wonky workaround cause react-color breaks the cursor on :focus once you input a valid color
    const activeInput = document.activeElement;
    if (activeInput.type === "text") {
      // TODO: Is this timeout necessary? It seems like no but I'm sure I put it here for a reason
      // timeout.current = setTimeout(() => activeInput.selectionStart = activeInput.value.length);
      activeInput.selectionStart = activeInput.value.length;
    }
  };

  const clearCustomColor = (colorType) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {
          ...palette.colors,
          [colorType]: "",
        },
      },
    });
    if (colorType === "primary") {
      setRecommendedColors([]);
    }
  };

  const clearCustomColors = () => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {},
      },
    });
    setRecommendedColors([]);
  };

  const addAltColor = () => {
    const altID = shortid.generate();
    setAltPickerOn(true);
    setAltColorID(altID);
    setItem({
      ...item,
      palette: {
        ...palette,
        altColors: [
          ...palette.altColors,
          {
            id: altID,
            name: `Alt ${
              palette && palette.altColors ? palette.altColors.length + 1 : 1
            }`,
            color: "",
          },
        ],
      },
    });
  };

  const deleteAltColor = (colorIndex) => {
    setAltColorDelete(true);
    setAltPickerOn(false);
    setAltColorID(-1);
    setItem({
      ...item,
      palette: {
        ...palette,
        altColors: Object.assign([], [...palette.altColors], {
          [colorIndex]: {
            ...palette.altColors[colorIndex],
            deleted: true,
          },
        }),
      },
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      timeout.current = setTimeout(() => {
        updateClone.palette.altColors.splice(colorIndex, 1);
        setItem({ ...item, palette: updateClone.palette });
        timeout.current = setTimeout(() => setAltColorDelete(false), 0);
      }, 333);
    });
  };

  const editAltColor = (color) => {
    setAltColorID(altColorID !== color.id ? color.id : null);
    setAltPickerOn(altColorID !== color.id ? true : false);
    setAltColor(color.color ? color.color : altColor);
  };

  const changeAltColor = (color) => {
    const index = palette.altColors.findIndex((alt) => alt.id === altColorID);
    setItem({
      ...item,
      palette: {
        ...palette,
        altColors: Object.assign([], [...palette.altColors], {
          [index]: {
            ...palette.altColors[index],
            color: color.hex,
          },
        }),
      },
    }).then(() => {
      fixInputFocus();
    });
  };

  const onSortPaletteStart = () => {
    clearTimeout(timeout.current);
    setPaletteSortOn(true);
    setAltPickerOn(false);
    setAltColorID(null);
  };

  const onSortPaletteEnd = ({ oldIndex, newIndex }) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        altColors: arrayMove(palette.altColors, oldIndex, newIndex),
      },
    });
    timeout.current = setTimeout(() => setPaletteSortOn(false), 500);
  };

  const updateColorName = (event, colorIndex) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        altColors: Object.assign([], [...palette.altColors], {
          [colorIndex]: {
            ...palette.altColors[colorIndex],
            name: event.target.value,
          },
        }),
      },
    });
  };

  const donePalette = () => {
    setAltPickerOn(false);
    setAltColorID(null);
    setItem({
      ...item,
      palette: {
        ...palette,
        colors:
          !palette.colors.primary ||
          !palette.colors.secondary ||
          !palette.colors.accent
            ? {}
            : { ...palette.colors },
      },
    }).then(() => doneStep("palette", step));
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "palette",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.palette ? "done" : ""
      } ${
        palette.length === 0 ||
        !palette.colors ||
        (palette.colors && Object.keys(palette.colors).length !== 3)
          ? "no-palette"
          : ""
      } ${
        palette.length === 0 ||
        (!palette.colors &&
          palette.altColors &&
          palette.altColors.length === 0) ||
        (palette.colors &&
          Object.keys(palette.colors).length !== 3 &&
          !palette.altColors) ||
        (palette.colors &&
          Object.keys(palette.colors).length !== 3 &&
          palette.altColors &&
          palette.altColors.length === 0)
          ? "none-selected"
          : ""
      }`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.palette[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.palette[label]}</p>}
          />
        </h2>

        <TextContent node={0} {...textContentProps} />

        <ul
          className={`palette-list cards-list custom active ${
            palette.colors.primary &&
            palette.colors.secondary &&
            palette.colors.accent
              ? "custom-done"
              : ""
          }`}
        >
          <li
            id="custom-palette"
            className={`card card-palette-custom ${
              palette.colors.primary &&
              palette.colors.secondary &&
              palette.colors.accent
                ? "selected"
                : ""
            } ${pickerOn ? "editing" : ""}`}
          >
            <div
              className={`palette-custom ${
                recommendedColors.length > 0 ? "has-recommended" : ""
              }`}
            >
              {!doneList.palette ? (
                <div className="palette">
                  <div
                    className={`color color-primary ${
                      palette.colors.primary ? "selected" : ""
                    } ${colorOn === "primary" && pickerOn ? "editing" : ""}`}
                    style={{ backgroundColor: palette.colors.primary }}
                  >
                    <p>
                      <span className="color-num">1</span>Choose a primary color
                    </p>
                    <span
                      className="color-picker-btn"
                      onClick={() => toggleColorType("primary")}
                    >
                      <i className="icon icon-edit" />
                    </span>
                    <span className="card-clear-wrap">
                      <span
                        className="card-clear"
                        onClick={() => clearCustomColor("primary")}
                      >
                        <i className="icon icon-color-clear" />
                      </span>
                    </span>
                  </div>
                  <div
                    className={`color color-secondary ${
                      palette.colors.secondary ? "selected" : ""
                    } ${colorOn === "secondary" && pickerOn ? "editing" : ""}`}
                    style={{ backgroundColor: palette.colors.secondary }}
                  >
                    <p>
                      <span className="color-num">2</span>Choose a secondary
                      color
                    </p>
                    <span
                      className="color-picker-btn"
                      onClick={() => toggleColorType("secondary")}
                    >
                      <i className="icon icon-edit" />
                    </span>
                    <span className="card-clear-wrap">
                      <span
                        className="card-clear"
                        onClick={() => clearCustomColor("secondary")}
                      >
                        <i className="icon icon-color-clear" />
                      </span>
                    </span>
                  </div>
                  <div
                    className={`color color-accent ${
                      palette.colors.accent ? "selected" : ""
                    } ${colorOn === "accent" && pickerOn ? "editing" : ""}`}
                    style={{ backgroundColor: palette.colors.accent }}
                  >
                    <p>
                      <span className="color-num">3</span>Choose an accent color
                    </p>
                    <span
                      className="color-picker-btn"
                      onClick={() => toggleColorType("accent")}
                    >
                      <i className="icon icon-edit" />
                    </span>
                    <span className="card-clear-wrap">
                      <span
                        className="card-clear"
                        onClick={() => clearCustomColor("accent")}
                      >
                        <i className="icon icon-color-clear" />
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="palette">
                  <div
                    className="color color-primary"
                    style={{ backgroundColor: palette.colors.primary }}
                  />
                  <div
                    className="color color-secondary"
                    style={{ backgroundColor: palette.colors.secondary }}
                  />
                  <div
                    className="color color-accent"
                    style={{ backgroundColor: palette.colors.accent }}
                  />
                </div>
              )}
            </div>
            <span className="card-delete-wrap">
              <span className="card-delete" onClick={clearCustomColors}>
                <i className="icon icon-cross" />
              </span>
            </span>
          </li>
        </ul>
        <div
          className={`custom-palette-picker ${
            !pickerOn ? "picker-off" : ""
          } ${colorOn}`}
        >
          <div
            id="sketch-palette"
            className={`color-picker ${
              logos && logos.length > 0 && logos.find((logo) => logo.palette)
                ? "has-logo"
                : ""
            } ${
              screens &&
              screens.length > 0 &&
              screens.find((screen) => screen.palette)
                ? "has-screen"
                : ""
            } ${
              recommendedColors && recommendedColors.length > 1
                ? "has-recommended"
                : ""
            }`}
          >
            <span
              className="color-indicator"
              style={{
                borderTopColor:
                  pickerOn && palette.colors[colorOn]
                    ? palette.colors[colorOn]
                    : "",
              }}
            />
            <SketchPicker
              color={palette.colors[colorOn]}
              presetColors={customPresetColors}
              onChangeComplete={(color) => colorChange(color, colorOn)}
              disableAlpha={true}
              width={"calc(100% - 20px)"}
            />
            {logoColors && logoColors.length > 0 ? (
              <div className="color-picker-logo">
                <label>Logo Color Matches</label>
                {logoColors.map((color) => (
                  <span
                    key={color.hex}
                    onClick={() => colorChange(color, colorOn)}
                    className="color-logo"
                    style={{ backgroundColor: color.hex }}
                  />
                ))}
              </div>
            ) : null}
            {screenColors && screenColors.length > 0 ? (
              <div className="color-picker-screen">
                <label>
                  Image
                  <br />
                  Colors
                </label>
                {screenColors.map((color) => (
                  <span
                    key={color.hex}
                    onClick={() => colorChange(color, colorOn)}
                    className="color-screen"
                    style={{ backgroundColor: color.hex }}
                  />
                ))}
              </div>
            ) : null}
            {recommendedColors &&
            recommendedColors.length > 0 &&
            colorOn !== "primary" ? (
              <div className="color-picker-alt">
                <label>Primary Matches</label>
                {Object.keys(recommendedColors).map((key) => (
                  <span
                    key={key}
                    onClick={() => colorChange(recommendedColors[key], colorOn)}
                    className="color-alt"
                    style={{ backgroundColor: recommendedColors[key].hex }}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>

        {palette ? (
          <SortableList
            palette={palette}
            logos={logos}
            screens={screens}
            altPickerOn={altPickerOn}
            altColorID={altColorID}
            editAltColor={editAltColor}
            altColorDelete={altColorDelete}
            recommendedColors={recommendedColors}
            altColor={altColor}
            addAltColor={addAltColor}
            updateColorName={updateColorName}
            customPresetColors={customPresetColors}
            changeAltColor={changeAltColor}
            deleteAltColor={deleteAltColor}
            paletteSortOn={paletteSortOn}
            helperClass={"item-drag palette-drag"}
            distance={window.innerWidth > 1024 ? 5 : 0}
            pressDelay={window.innerWidth <= 1024 ? 200 : 0}
            axis={"xy"}
            onSortStart={({ node, index, collection }, event) => {
              event.preventDefault();
              onSortPaletteStart();
            }}
            onSortEnd={onSortPaletteEnd}
          />
        ) : null}

        <p className="section-empty">
          No palette selected, click here to choose one.
        </p>

        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={donePalette}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editPalette}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

const SortableList = SortableContainer(
  ({
    palette,
    logos,
    screens,
    altPickerOn,
    altColorID,
    editAltColor,
    altColorDelete,
    recommendedColors,
    altColor,
    customPresetColors,
    changeAltColor,
    deleteAltColor,
    paletteSortOn,
    addAltColor,
    updateColorName,
  }) => (
    <ul
      className={`palette-alt-wrap cards-list sortable ${
        altPickerOn ? "editing" : ""
      } ${paletteSortOn ? "sorting" : ""} ${altColorDelete ? "deleting" : ""} ${
        palette.altColors && palette.altColors.length > 0 ? "has-alt" : ""
      }`}
    >
      {palette.altColors && palette.altColors.length > 0
        ? palette.altColors.map((color, index) => (
            <SortableItem
              key={color.id}
              index={index}
              color={color}
              altColorID={altColorID}
              disabled={
                (altPickerOn && altColorID === color.id) || altColorDelete
              }
              paletteContent={
                <span className="alt-color-wrap">
                  <span
                    className="alt-color"
                    style={{ background: color.color }}
                  />
                  <span
                    className="color-picker-btn"
                    onClick={() => editAltColor(color)}
                  >
                    <i className="icon icon-edit" />
                  </span>
                  <span className="card-delete-wrap">
                    <span
                      className="card-delete"
                      onClick={() => deleteAltColor(index)}
                    >
                      <i className="icon icon-cross" />
                    </span>
                  </span>
                  <div className="color-name">
                    <Input
                      id={`alt-color-${index}-name`}
                      value={color.name}
                      placeholder="Enter a color name..."
                      maxLength={30}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.currentTarget.blur();
                        }
                      }}
                      onChange={(event) => updateColorName(event, index)}
                    />
                  </div>
                  {altPickerOn && altColorID === color.id ? (
                    <div className="color-picker-wrap">
                      <div
                        className={`color-picker ${
                          recommendedColors.length > 0 ? "has-recommended" : ""
                        } ${
                          logos &&
                          logos.length > 0 &&
                          logos.find((logo) => logo.palette)
                            ? "has-logo"
                            : ""
                        } ${
                          screens &&
                          screens.length > 0 &&
                          screens.find((screen) => screen.palette)
                            ? "has-screen"
                            : ""
                        }`}
                      >
                        <SketchPicker
                          color={altColor}
                          presetColors={customPresetColors}
                          onChangeComplete={changeAltColor}
                          disableAlpha={true}
                        />
                        {recommendedColors && recommendedColors.length > 0 ? (
                          <div className="color-picker-alt">
                            <label>Primary Matches</label>
                            {Object.keys(recommendedColors).map((key) => (
                              <span
                                key={key}
                                onClick={() =>
                                  changeAltColor(recommendedColors[key])
                                }
                                className="color-alt"
                                style={{
                                  backgroundColor: recommendedColors[key].hex,
                                }}
                              />
                            ))}
                          </div>
                        ) : null}
                        {logos && logos.length > 0 ? (
                          <div className="color-picker-logo">
                            <label>Logo Color Matches</label>
                            {logos.map((logo) =>
                              logo.palette && !logo.deleted
                                ? Object.keys(logo.palette).map((key) => (
                                    <span
                                      key={`${logo.name}-${key}`}
                                      onClick={() =>
                                        changeAltColor(logo.palette[key])
                                      }
                                      className="color-logo"
                                      style={{
                                        backgroundColor: logo.palette[key].hex,
                                      }}
                                    />
                                  ))
                                : null
                            )}
                          </div>
                        ) : null}
                        {screens && screens.length > 0 ? (
                          <div className="color-picker-screen">
                            <label>Screen Color Matches</label>
                            {screens.map((screen) =>
                              screen.palette && !screen.deleted
                                ? Object.keys(screen.palette).map((key) => (
                                    <span
                                      key={`${screen.name}-${key}`}
                                      onClick={() =>
                                        changeAltColor(screen.palette[key])
                                      }
                                      className="color-screen"
                                      style={{
                                        backgroundColor:
                                          screen.palette[key].hex,
                                      }}
                                    />
                                  ))
                                : null
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </span>
              }
            />
          ))
        : null}
      <li className="palette-alt card palette-alt-add" onClick={addAltColor}>
        <label>Add alternate color</label>
        <i className="icon icon-plus-circle" />
      </li>
    </ul>
  )
);

const SortableItem = SortableElement(
  ({ color, altColorID, paletteContent }) => (
    <li
      className={`card palette-alt ${
        altColorID === color.id ? "editing" : ""
      } ${!color.color ? "empty" : "done"} ${color.deleted ? "deleted" : ""}`}
    >
      {paletteContent}
    </li>
  )
);

export default Palette;
