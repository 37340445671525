import React from "react";
import {
  findNormalFontWeight,
  findNormalFontStyle,
  findElementColorVal,
  findElementFontVal,
} from "./Resources";
import _cloneDeep from "lodash.clonedeep";

export const Themes = (props) => {
  const { step, type, template, item, setItem } = props;

  const changeTheme = (theme) => {
    const itemClone = _cloneDeep(item);
    if (itemClone.elements && itemClone.theme === "light" && theme === "dark") {
      Object.keys(itemClone.elements).forEach((element) => {
        if (
          (element === "p" ||
            element === "toggle" ||
            element === "toggleDisabled") &&
          itemClone.elements[element].color &&
          (itemClone.elements[element].color === "#000000" ||
            itemClone.elements[element].color === "#111111")
        ) {
          itemClone.elements[element].color = "#ffffff";
        }
      });
    }
    if (itemClone.elements && itemClone.theme === "dark" && theme === "light") {
      Object.keys(itemClone.elements).forEach((element) => {
        if (
          (element === "p" ||
            element === "toggle" ||
            element === "toggleDisabled") &&
          itemClone.elements[element].color &&
          itemClone.elements[element].color === "#ffffff"
        ) {
          itemClone.elements[element].color = "#111111";
        }
      });
    }
    itemClone.theme = theme;
    document.body.classList.remove(item.theme);
    document.body.classList.add(theme);
    setItem(itemClone);
  };

  return (
    <section id={`step-${step}`} className="item-section item-theme step">
      <div className="component-section-wrap">
        <div className="component-section-inner">
          <h2>Choose a theme</h2>
          <div className="component-theme-wrap cards-list">
            {template.themes.map((theme) => (
              <div
                key={theme}
                className={`component-theme theme-${theme} card ${
                  item.theme === theme ? "selected" : ""
                }`}
                onClick={() => changeTheme(theme)}
              >
                <ThemeThumb type={type} item={item} theme={theme} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export const ThemeThumb = (props) => {
  const { type, item, theme } = props;

  return (
    <div className={`theme-thumb ${type}`} {...props}>
      {item ? (
        <style>
          {`
            .theme-thumb.${type} .theme-header h4,
            .theme-thumb.${type} .theme-font .theme-aa {
              font-family: ${
                item.font && item.font.name
                  ? item.font.name
                  : item.fonts && item.fonts[0]
                  ? item.fonts[0].family
                    ? `"${item.fonts[0].family}"`
                    : item.fonts[0].css_stack
                  : "inherit"
              };
              font-weight: ${
                item.fonts && item.fonts[0]
                  ? findNormalFontWeight(item.fonts[0])
                  : "normal"
              };
              font-style: ${
                item.fonts && item.fonts[0]
                  ? findNormalFontStyle(item.fonts[0])
                  : "normal"
              };
            }
            .theme-thumb.${type} .theme-logos .theme-logo,
            .theme-thumb.${type} .theme-logos .theme-logo:before,
            .theme-thumb.${type} .theme-layout.project .theme-audience .theme-line,
            .theme-thumb.${type} .theme-layout.project .theme-audience .theme-line .icon,
            .theme-thumb.${type} .theme-palette .theme-color.color-primary {
              background: ${
                item.palette &&
                item.palette.colors &&
                item.palette.colors.primary
                  ? item.palette.colors.primary
                  : item.palette &&
                    item.palette.altColors &&
                    item.palette.altColors[0]
                  ? item.palette.altColors[0].color
                  : ""
              };
            }
            .theme-thumb.${type} .theme-palette .theme-color.color-secondary {
              background: ${
                item.palette &&
                item.palette.colors &&
                item.palette.colors.secondary
                  ? item.palette.colors.secondary
                  : item.palette &&
                    item.palette.altColors &&
                    item.palette.altColors[1]
                  ? item.palette.altColors[1].color
                  : ""
              };
            }
            .theme-thumb.${type} .theme-palette .theme-color.color-accent {
              background: ${
                item.palette &&
                item.palette.colors &&
                item.palette.colors.accent
                  ? item.palette.colors.accent
                  : item.palette &&
                    item.palette.altColors &&
                    item.palette.altColors[2]
                  ? item.palette.altColors[2].color
                  : ""
              };
            }
            .theme-thumb.${type} .theme-images .theme-image.image-1 {
              background-image: ${
                item.images && item.images[0]
                  ? `url(${
                      item.images[0].downloadURL
                        ? item.images[0].downloadURL
                        : item.images[0].urls
                        ? item.images[0].urls.regular
                        : ""
                    })`
                  : "inherit"
              };
            }
            .theme-thumb.${type} .theme-images .theme-image.image-2 {
              background-image: ${
                item.images && item.images[1]
                  ? `url(${
                      item.images[1].downloadURL
                        ? item.images[1].downloadURL
                        : item.images[1].urls
                        ? item.images[1].urls.regular
                        : ""
                    })`
                  : "inherit"
              };
            }
            .theme-thumb.${type} .theme-images .theme-image.image-3 {
              background-image: ${
                item.images && item.images[2]
                  ? `url(${
                      item.images[2].downloadURL
                        ? item.images[2].downloadURL
                        : item.images[2].urls
                        ? item.images[2].urls.regular
                        : ""
                    })`
                  : "inherit"
              };
            }
            .theme-thumb.${type} .theme-images .theme-image.image-4 {
              background-image: ${
                item.images && item.images[3]
                  ? `url(${
                      item.images[3].downloadURL
                        ? item.images[3].downloadURL
                        : item.images[3].urls
                        ? item.images[3].urls.regular
                        : ""
                    })`
                  : "inherit"
              };
            }
            .theme-thumb.${type} .theme-layout.project .theme-header {
              background-image: linear-gradient(-15deg, ${
                item.palette && item.palette.colors
                  ? item.palette.colors.secondary
                  : "white"
              } 5%, ${
            item.palette && item.palette.colors
              ? item.palette.colors.primary
              : "black"
          } 67%);
            }
            .theme-thumb.${type} .theme-screens {
              background-color: ${
                item.screens &&
                item.screens[0] &&
                item.screens[0].background &&
                item.screens[0].screenType === "background"
                  ? item.screens[0].background.hex
                  : "inherit"
              };
            }
          `}
        </style>
      ) : null}
      {type === "projects" ? (
        <div className="theme-layout project">
          <div className="theme-header">
            <h4>{theme ? `${theme} Theme` : "Design Brief"}</h4>
          </div>
          <div className="theme-audience">
            <div className="theme-line line-long">
              <i className="icon icon-user" />
            </div>
          </div>
          <div className="theme-problem">
            <div className="theme-files">
              <div className="theme-file">
                {item.example ? <i className="icon icon-image" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-code" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-file-text" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-brackets" /> : null}
              </div>
            </div>
          </div>
          <div className="theme-palette">
            <div className="theme-color color-primary" />
            <div className="theme-color color-secondary" />
            <div className="theme-color color-accent" />
          </div>
          <div className="theme-images">
            <div className="theme-image image-1" />
            <div className="theme-image image-2" />
            {item && item.images && item.images[2] ? (
              <div className="theme-image image-3" />
            ) : null}
            {item && item.images && item.images[3] ? (
              <div className="theme-image image-4" />
            ) : null}
          </div>
        </div>
      ) : type === "styleguides" ? (
        <div className="theme-layout styleguide">
          <div className="theme-header">
            <h4>{theme ? `${theme} Theme` : "Brand Styleguide"}</h4>
          </div>
          <div className="theme-logos">
            {item &&
            item.logos &&
            item.logos[0] &&
            item.logos[0].downloadURL ? (
              <img src={item.logos[0].downloadURL} alt="Styleguide logo" />
            ) : (
              <div className="theme-logo" />
            )}
          </div>
          {item &&
          (!item.palette.colors ||
            Object.keys(item.palette.colors).length !== 3) &&
          item.palette.altColors &&
          item.palette.altColors.length > 0 ? (
            <div className="theme-palette">
              {item.palette.altColors.map((color, index) =>
                index < 3 ? (
                  <div
                    className="theme-color"
                    style={{ backgroundColor: color.color }}
                    key={color.id}
                  />
                ) : null
              )}
            </div>
          ) : (
            <div className="theme-palette">
              <div className="theme-color color-primary" />
              <div className="theme-color color-secondary" />
              <div className="theme-color color-accent" />
            </div>
          )}
          <div className="theme-font">
            <div className="theme-aa">Aa</div>
            <div className="theme-line" />
          </div>
          {item &&
          item.elements &&
          item.elements.buttons &&
          item.elements.buttons.list &&
          Object.keys(item.elements.buttons.list).length > 0 ? (
            <div className="theme-buttons">
              <style>
                {`
                  .theme-buttons button.btn {
                    border-color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons",
                      "borderColor"
                    )};
                    border-radius: ${
                      item.elements && item.elements.buttons
                        ? item.elements.buttons.borderRadius
                        : "inherit"
                    };
                    border-style: ${
                      item.elements && item.elements.buttons
                        ? item.elements.buttons.borderStyle
                        : "inherit"
                    };
                    border-width: ${
                      item.elements && item.elements.buttons
                        ? item.elements.buttons.borderWidth
                        : "inherit"
                    };
                    color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons",
                      "color"
                    )};
                    font-family: ${findElementFontVal(
                      item.elements,
                      item.fonts,
                      "buttons",
                      "fontFamily"
                    )};
                    font-style: ${item.elements.buttons.fontStyle};
                    font-weight: ${item.elements.buttons.fontWeight};
                  }
                  .theme-buttons button.btn#btn-0 {
                    background: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[0]",
                      "background"
                    )};
                    border-color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[0]",
                      "borderColor"
                    )};
                    color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[0]",
                      "color"
                    )};
                  }
                  .theme-buttons button.btn#btn-1 {
                    background: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[1]",
                      "background"
                    )};
                    border-color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[1]",
                      "borderColor"
                    )};
                    color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[1]",
                      "color"
                    )};
                  }
                  .theme-buttons button.btn#btn-2 {
                    background: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[2]",
                      "background"
                    )};
                    border-color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[2]",
                      "borderColor"
                    )};
                    color: ${findElementColorVal(
                      item.elements,
                      item.palette,
                      "buttons.list[2]",
                      "color"
                    )};
                  }
                `}
              </style>
              {item.elements.buttons.list[0] ? (
                <button className="btn" id="btn-0">
                  {item.elements.buttons.list[0].name}
                </button>
              ) : null}
              {item.elements.buttons.list[1] ? (
                <button className="btn" id="btn-1">
                  {item.elements.buttons.list[1].name}
                </button>
              ) : null}
              {item.elements.buttons.list[2] ? (
                <button className="btn" id="btn-2">
                  {item.elements.buttons.list[2].name}
                </button>
              ) : null}
            </div>
          ) : (
            <div className="theme-images">
              <div className="theme-image image-1" />
              <div className="theme-image image-2" />
            </div>
          )}
        </div>
      ) : type === "showcases" ? (
        <div className="theme-layout showcase">
          <div className="theme-header">
            <h4>{theme ? `${theme} Theme` : "Design Showcase"}</h4>
          </div>
          {item && item.screens && item.screens[0] ? (
            <div
              className={`theme-screens ${item.screens[0].screenType} scale-${item.screens[0].screenScale}`}
            >
              <div className="theme-screen">
                <img src={item.screens[0].downloadURL} alt="Showcase design" />
              </div>
            </div>
          ) : (
            <div className="theme-screens desktop">
              <i className="icon icon-image" />
            </div>
          )}
          <div className="theme-palette">
            <div className="theme-color color-primary" />
            <div className="theme-color color-secondary" />
            <div className="theme-color color-accent" />
          </div>
          <div className="theme-font">
            <div className="theme-aa">Aa</div>
            <div className="theme-line" />
          </div>
          {item.example || (item.files && item.files.length > 0) ? (
            <div className="theme-files">
              <div className="theme-file">
                {item.example ? <i className="icon icon-image" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-code" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-file-text" /> : null}
              </div>
              <div className="theme-file">
                {item.example ? <i className="icon icon-brackets" /> : null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Themes;
