import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextContent from "./TextContent";
import { db, storage } from "../../firebase";
import { Popup, Input, Confirm } from "semantic-ui-react";
import Autocomplete from "react-autocomplete";
import Dropzone from "react-dropzone";
import {
  useMountEffect,
  convertFileSize,
  websafeFonts,
  headerText,
  helpText,
  loadFonts,
  normalFontVariant,
  spaceToEmpty,
  findNormalFontWeight,
  findNormalFontStyle,
} from "./Resources";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import shortid from "shortid";
import _cloneDeep from "lodash.clonedeep";

export const Fonts = (props) => {
  const {
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { fonts, doneList },
    setItem,
    saveItem,
    killSteps,
    demo,
  } = props;

  const timeout = useRef(null);
  const fontFileUploads = useRef([]);
  const fontFileQuota = 2000000;

  const [fontType, setFontType] = useState("google");
  const [fontsTypekit, setFontsTypekit] = useState([]);
  const [googleSearch, setGoogleSearch] = useState("");
  const [fontAddID, setFontAddID] = useState(-1);
  const [fontSortOn, setFontSortOn] = useState(false);
  const [fontRemove, setFontRemove] = useState(false);
  const [fontRemoveID, setFontRemoveID] = useState("");
  const [typekitToken, setTypekitToken] = useState("");
  const [typekitError, setTypekitError] = useState("");
  const [typekitLoading, setTypekitLoading] = useState(false);
  const [websafeSearch, setWebsafeSearch] = useState("");
  const [badFontFiles, setBadFontFiles] = useState([]);
  const [fontFilesUploading, setFontFilesUploading] = useState(false);
  const [fontFileDeleteOpen, setFontFileDeleteOpen] = useState(false);
  const [cardDropHeight, setCardDropHeight] = useState(0);
  const [variantsOn, setVariantsOn] = useState([]);
  const [fontFileDeleteID, setFontFileDeleteID] = useState(null);
  const [fontFileDeleteFile, setFontFileDeleteFile] = useState(null);

  const dispatch = useDispatch();
  const googleFontsList = useSelector(
    (state) => state.sessionState.googleFontsList
  );
  const setGoogleFontsList = useCallback(
    (googleFontsList) =>
      dispatch({ type: "GOOGLE_FONTS_LIST", googleFontsList }),
    [dispatch]
  );

  useMountEffect(() => {
    findFonts();
    loadFonts(fonts, webFontsLoaded);
    return () => {
      clearTimeout(timeout.current);
      fontFileUploads.current.forEach((upload) => {
        upload.cancel();
      });
    };
  });

  const editFonts = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        fonts: false,
      },
    }).then(() => findCardDropHeight());
  };

  const toggleVariant = (font, variant) => {
    const itemClone = _cloneDeep(item);
    let fontIndex = -1;
    let variantIndex = -1;
    if (font.type === "google") {
      fontIndex = itemClone.fonts
        .map((e) => {
          return e.family;
        })
        .indexOf(font.family);
      variantIndex = itemClone.fonts[fontIndex].variantData
        .map((e) => {
          return e.url;
        })
        .indexOf(variant.url);
    } else if (font.type === "typekit") {
      fontIndex = itemClone.fonts
        .map((e) => {
          return e.id;
        })
        .indexOf(font.id);
      variantIndex = itemClone.fonts[fontIndex].variantData
        .map((e) => {
          return e.name;
        })
        .indexOf(variant.name);
    } else if (font.type === "websafe") {
      fontIndex = itemClone.fonts
        .map((e) => {
          return e.name;
        })
        .indexOf(font.name);
      variantIndex = itemClone.fonts[fontIndex].variantData
        .map((e) => {
          return e.name;
        })
        .indexOf(variant.name);
    }
    itemClone.fonts[fontIndex].variantData[variantIndex].active =
      !itemClone.fonts[fontIndex].variantData[variantIndex].active;
    setItem(itemClone);
  };

  const findFonts = () => {
    if (googleFontsList && googleFontsList.length === 0) {
      const request = new XMLHttpRequest();
      request.open(
        "GET",
        "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyALmSUJlx2LQy7sQnOkCdYtoU92Q90soJ8",
        true
      );
      request.onreadystatechange = (request) => {
        if (request.target.readyState === 4) {
          const data = JSON.parse(request.target.response);
          setGoogleFontsList(data.items);
        }
      };
      request.send();
    }
  };

  const webFontsLoaded = () => {
    setTimeout(() => {
      setFontAddID(-1);
    }, 500);
  };

  const toggleFontVariants = (id) => {
    const variantsOnCopy = [...variantsOn];
    const variantsIndex = variantsOnCopy.indexOf(id);
    if (variantsIndex === -1) {
      variantsOnCopy.push(id);
    } else {
      variantsOnCopy.splice(variantsIndex, 1);
    }
    setVariantsOn(variantsOnCopy);
  };

  const searchGoogleFont = (event) => {
    event.preventDefault();
    setGoogleSearch(event.target.value);
  };

  const selectGoogleFont = (font) => {
    const findGoogleIndex = fonts
      .map((e) => {
        return e.family;
      })
      .indexOf(font.family);
    if (findGoogleIndex === -1) {
      let fontClone = _cloneDeep(font);
      fontClone.id = shortid.generate();
      fontClone.type = "google";
      fontClone.name = font.family;
      fontClone.variantData = [];
      fontClone.variants.forEach((variant) => {
        let variantData = {};
        variantData.name = variant;
        variantData.active = true;
        if (variant.indexOf("italic") !== -1) {
          variantData.style = "italic";
          variantData.weight = variant.replace("italic", "");
          if (variantData.weight === "") {
            variantData.weight = "normal";
          }
        } else {
          variantData.style = "normal";
          variantData.weight = variant;
        }
        if (variant === "regular") {
          variantData.url = fontClone.family;
          variantData.weight = "normal";
        } else {
          variantData.url = fontClone.family + ":" + variant;
        }
        fontClone.variantData.push(variantData);
      });
      setGoogleSearch("");
      setFontAddID(fonts.length);
      setItem({ ...item, fonts: [...fonts, fontClone] }).then((update) =>
        loadFonts(update.fonts, webFontsLoaded)
      );
    }
  };

  const editTypekitToken = (event) => {
    event.preventDefault();
    setTypekitToken(event.target.value);
  };

  const findTypekitToken = () => {
    setTypekitLoading(true);
    const proxyProd =
      "https://us-central1-designgapp-prod.cloudfunctions.net/corsContent";
    const proxyDev =
      "https://us-central1-designgapp-dev.cloudfunctions.net/corsContent";
    const proxy =
      process.env.REACT_APP_ENV === "production" ? proxyProd : proxyDev;
    fetch(
      `${proxy}?url=https://typekit.com/api/v1/json/kits?token=${typekitToken}`
    )
      .then((res) => res.json())
      .then((data) => {
        getTypekitKits(data);
      })
      .catch((err) => {
        console.log("Error happened during fetching!", err);
      });

    // This is how Adobe Fonts will work... WOMP
    // fetch("https://cctypekit.adobe.io/v1/kits", {
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer eyJ4NXUiOiJpbXNfbmExLWtleS0xLmNlciIsImFsZyI6IlJTMjU2In0.eyJpZCI6IjE1NTU2MDc5MjU4MDZfYWQwZGY4NzMtOGNhYS00NjVkLTgxYzAtMTdmMzU5NzEyMjUxX3VlMSIsImNsaWVudF9pZCI6IlR5cGVraXRTd2FnZ2VyVUkiLCJ1c2VyX2lkIjoiREUyMTZFQTg1Q0I3RUM2QTBBNDk1RURDQEFkb2JlSUQiLCJ0eXBlIjoiYWNjZXNzX3Rva2VuIiwiYXMiOiJpbXMtbmExIiwiZmciOiJUTE5XNUhTSFhMUDM3WFg2SzRGUUJBQUE2ST09PT09PSIsInNpZCI6IjE1NTU1NTc0ODMzMzRfYTZkZjE0MmYtYmYyNi00NzY0LTkzNjItYTMxZGIxODE5NTA2X3VlMSIsIm1vaSI6IjNkNTkwMzJlIiwiYyI6IkVLemo3c0s3aUNwOG5kTjIrSUM4TUE9PSIsImV4cGlyZXNfaW4iOiI4NjQwMDAwMCIsInNjb3BlIjoidGtfcGxhdGZvcm0sdGtfcGxhdGZvcm1fbXAiLCJjcmVhdGVkX2F0IjoiMTU1NTYwNzkyNTgwNiJ9.d6L8t4blYlloY0uLKQ6bSGczFAxHFDCYN8KAKDFZgLn895bsPqhvtaouAe5sGRnhwbznxhXSjC101yX0HSFgd8o_ABI26cjfJ5XgOdNapLts-4L2eCK4gY4hEkoOKrxMVsNjvmbPo4T0t05imY_8fFvnglpAz11HXhykT44vHHwWd0Het0JE8FjlyeE-JMB5wLCdj6jaRl5eMHbF3Ytqot8_ghtINQ978MJZP8TagXKV3W4vWAx7tZrO5nKlifFP7EWvYv7Yk2SVJvCXcrit1Ww8eSCz5g2bg7DJ4dAmTxcR6w9NOsjIQMOZxXymaxE3HDrC5Q7m9Y2Q2MN_KsNtLg",
    //     "X-Api-Key": "0f8118bf823b4056a4321822d39c840a"
    //   }
    // })
    // .then(res => res.json())
    // .then(data => {
    //   console.log('DATA: ', data)
    //   // getTypekitKits(data);
    // })
    // .catch(err => {
    //   console.log('Error happened during fetching!', err);
    // });
  };

  const getTypekitKits = (data) => {
    let fontsList = [];
    if (data.kits && data.kits.length > 0) {
      let kitsList = data.kits.map((kit, index) => {
        return new Promise((resolve) => {
          const proxyProd =
            "https://us-central1-designgapp-prod.cloudfunctions.net/corsContent";
          const proxyDev =
            "https://us-central1-designgapp-dev.cloudfunctions.net/corsContent";
          const proxy =
            process.env.REACT_APP_ENV === "production" ? proxyProd : proxyDev;
          fetch(
            `${proxy}?url=https://typekit.com/api/v1/json/kits/${data.kits[index].id}?token=${typekitToken}`
          )
            .then((res) => res.json())
            .then((data) => {
              fontsList.push(data.kit);
              resolve();
            })
            .catch((err) => {
              console.log("Error happened during fetching!", err);
            });
        });
      });
      Promise.all(kitsList)
        .then(() => {
          fontsList.forEach((font, index) => {
            fonts.forEach((selectedFont) => {
              const fontsTypekitIndex = fontsList[index].families
                .map((e) => {
                  return e.id;
                })
                .indexOf(selectedFont.id);
              if (fontsTypekitIndex !== -1) {
                fontsList[index].families[fontsTypekitIndex].active = true;
              }
            });
          });
          setTypekitLoading(false);
          setFontsTypekit(fontsList);
          setTypekitError("");
        })
        .catch((error) => console.log(error));
    } else if (data.kits && data.kits.length === 0) {
      setTypekitLoading(false);
      setFontsTypekit([]);
      setTypekitError("Your token has no kits associated with it.");
    } else if (data.errors) {
      setTypekitLoading(false);
      setFontsTypekit([]);
      setTypekitError(`${data.errors}. Please check your token.`);
    } else {
      setTypekitLoading(false);
      setFontsTypekit([]);
      setTypekitError(
        "Sorry, something went wrong. If this continues, please contact us."
      );
    }
  };

  const selectAllTypekitFonts = (font, fontIndex) => {
    const newFonts = [];
    let typekitFontsList = font.families.map((family, familyIndex) => {
      if (
        fonts
          .map((e) => {
            return e.id;
          })
          .indexOf(family.id) === -1
      ) {
        return new Promise((resolve) => {
          selectTypekitFont(family, familyIndex, font.id, fontIndex, (font) => {
            newFonts.push(font);
            resolve();
          });
        });
      } else {
        return false;
      }
    });
    Promise.all(typekitFontsList)
      .then(() => {
        setItem({ ...item, fonts: [...fonts, ...newFonts] }).then((update) =>
          loadFonts(update.fonts, webFontsLoaded)
        );
      })
      .catch((error) => console.log(error));
  };

  const selectTypekitFont = (
    family,
    familyIndex,
    fontID,
    fontIndex,
    callback
  ) => {
    const findTypekitIndex = fonts
      .map((e) => {
        return e.id;
      })
      .indexOf(family.id);
    if (findTypekitIndex === -1) {
      let fontClone = _cloneDeep(family);
      fontClone.type = "typekit";
      fontClone.kit = fontID;
      fontClone.variantData = [];
      fontClone.variations.forEach((variant) => {
        let variantData = {};
        variantData.name = variant;
        variantData.active = true;
        if (variant.indexOf("i") !== -1) {
          variantData.style = "italic";
          variantData.weight = variant.replace("i", "") * 100;
        } else {
          variantData.style = "normal";
          variantData.weight = variant.replace("n", "") * 100;
        }
        fontClone.variantData.push(variantData);
      });
      if (callback) {
        callback(fontClone);
      } else {
        setFontAddID(fonts.length);
        setItem({ ...item, fonts: [...fonts, fontClone] }).then((update) =>
          loadFonts(update.fonts, webFontsLoaded)
        );
      }
    }
  };

  const clearTypekitSearch = () => {
    setFontsTypekit([]);
    setTypekitToken("");
    setTypekitError("");
  };

  const editWebsafeFont = (event) => {
    event.preventDefault();
    setWebsafeSearch(event.target.value);
  };

  const selectWebsafeFont = (font) => {
    const findWebsafeIndex = fonts
      .map((e) => {
        return e.name;
      })
      .indexOf(font.name);
    if (findWebsafeIndex === -1) {
      let fontClone = _cloneDeep(font);
      fontClone.id = shortid.generate();
      fontClone.type = "websafe";
      fontClone.variantData = [];
      fontClone.variants.forEach((variant) => {
        let variantData = {};
        variantData.name = variant;
        variantData.active = true;
        variantData.weight =
          variant === "bold" || variant === "600italic"
            ? "bold"
            : variant === "300" || variant === "300italic"
            ? "300"
            : "normal";
        variantData.style =
          variant === "italic" ||
          variant === "300italic" ||
          variant === "600italic"
            ? "italic"
            : "normal";
        fontClone.variantData.push(variantData);
      });
      setWebsafeSearch("");
      setFontAddID(fonts.length);
      setItem({ ...item, fonts: [...fonts, fontClone] }).then(() =>
        selectWebsafeFontDone()
      );
    }
  };

  const selectWebsafeFontDone = () => {
    setTimeout(() => {
      setFontAddID(-1);
    }, 500);
  };

  const setFontFiles = (files) => {
    let newFontFiles = [];
    let badFontFiles = [];
    let filesList = files.map((file) => {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (
            !checkFontFileMatch(file) &&
            file.size <= fontFileQuota &&
            (file.name.toLowerCase().indexOf(".ttf") !== -1 ||
              file.name.toLowerCase().indexOf(".otf") !== -1)
          ) {
            newFontFiles.push({
              id: shortid.generate(),
              file: file,
              type: "upload",
              name: file.name,
              encode: reader.result,
              family:
                file.name.toLowerCase().indexOf(".ttf") !== -1
                  ? file.name.toLowerCase().replace(".ttf", "")
                  : file.name.toLowerCase().replace(".otf", ""),
              size: file.size,
              new: true,
            });
            resolve();
          } else if (checkFontFileMatch(file)) {
            badFontFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, this file already exists",
            });
            resolve();
          } else if (file.size > fontFileQuota) {
            badFontFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, this file is too large",
            });
            resolve();
          } else if (
            file.name.indexOf(".ttf") === -1 &&
            file.name.indexOf(".otf") === -1
          ) {
            badFontFiles.push({
              name: file.name,
              size: file.size,
              reason: "Sorry, the file must be a .ttf or .otf",
            });
            resolve();
          }
        };
        reader.onerror = (error) => {
          console.log("Error: ", error);
          badFontFiles.push({
            name: file.name,
            size: file.size,
            reason: "Sorry, something went wrong",
          });
          resolve();
        };
      });
    });
    Promise.all(filesList)
      .then(() => {
        setFontAddID(fonts.length);
        setBadFontFiles(badFontFiles);
        setFontFilesUploading(newFontFiles.length > 0 ? true : false);
        setItem({ ...item, fonts: [...fonts, ...newFontFiles] }).then(
          (update) => {
            killSteps(update);
            setFontFilesDone(_cloneDeep(update), newFontFiles);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  const setFontFilesDone = (update, newFontFiles) => {
    let progress = 0;
    let fileCount = update.fonts.length - newFontFiles.length;
    let fontDropProgress = document.getElementById("font-drop-progress");
    let newFontFilesList = newFontFiles.map((file, index) => {
      return new Promise((resolve) => {
        const storageRef = demo
          ? storage.getDemosRef(`${type}/${id}/fonts/${file.name}`)
          : storage.getRef(`${type}/${id}/fonts/${file.name}`);
        const uploadTask = storage.uploadFiles(storageRef, file.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              progress++;
            }
            if (fontDropProgress)
              fontDropProgress.style.width =
                (progress / newFontFiles.length) * 100 + "%";
          },
          (error) => {
            console.log("Upload error: ", error);
          },
          () => {
            storage.getDownload(uploadTask.snapshot.ref).then((downloadURL) => {
              update.fonts[fileCount + index].downloadURL = downloadURL;
              update.fonts[fileCount + index].fullPath =
                uploadTask.snapshot.metadata.fullPath;
              update.fonts[fileCount + index].timeCreated =
                uploadTask.snapshot.metadata.timeCreated;
              update.fonts[fileCount + index].updated =
                uploadTask.snapshot.metadata.updated;
              update.fonts[fileCount + index].file = null;
              update.fonts[fileCount + index].preview = null;
              update.fonts[fileCount + index].new = null;
              setItem(update).then((update) => {
                killSteps(update);
                if (!demo) saveFonts(update);
                resolve();
              });
            });
          }
        );
      });
    });
    Promise.all(newFontFilesList)
      .then(() => {
        fontFileUploads.current = [];
        timeout.current = setTimeout(() => {
          setFontFilesUploading(false);
          timeout.current = setTimeout(() => {
            if (fontDropProgress) fontDropProgress.style.width = "0";
            setFontAddID(-1);
          }, 500);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const checkFontFileMatch = (file) => {
    let hasMatch = false;
    fonts.forEach((item) => {
      if (item.type === "upload" && item.name === file.name) {
        hasMatch = true;
      }
    });
    return hasMatch;
  };

  const openDeleteFontFile = (id, file) => {
    setFontFileDeleteOpen(true);
    setFontFileDeleteID(id);
    setFontFileDeleteFile(file);
  };

  const cancelDeleteFontFile = (event) => {
    event.preventDefault();
    setFontFileDeleteOpen(false);
    setFontFileDeleteID(null);
    setFontFileDeleteFile(null);
  };

  const editFontName = (index) => {
    setItem({
      ...item,
      fonts: Object.assign([], [...fonts], {
        [index]: {
          ...fonts[index],
          editOn: true,
          editName: fonts[index].family,
        },
      }),
    }).then(() => {
      const fontInput = document.getElementById(
        "font-name-edit-input-" + index
      );
      if (fontInput) fontInput.focus();
    });
  };

  const changeFontName = (event, index) => {
    event.preventDefault();
    setItem({
      ...item,
      fonts: Object.assign([], [...fonts], {
        [index]: {
          ...fonts[index],
          editName: event.target.value,
        },
      }),
    });
  };

  const saveFontName = (index) => {
    setItem({
      ...item,
      fonts: Object.assign([], [...fonts], {
        [index]: {
          ...fonts[index],
          family: fonts[index].editName,
          editOn: false,
          editName: "",
        },
      }),
    });
  };

  const cancelFontName = (index) => {
    setItem({
      ...item,
      fonts: Object.assign([], [...fonts], {
        [index]: {
          ...fonts[index],
          editOn: false,
          editName: "",
        },
      }),
    });
  };

  const deleteFontFile = () => {
    setFontFileDeleteOpen(false);
    setFontRemove(true);
    setItem({
      ...item,
      fonts: Object.assign([], [...fonts], {
        [fontFileDeleteID]: {
          ...fonts[fontFileDeleteID],
          deleted: true,
        },
      }),
    }).then((update) => {
      const updateClone = _cloneDeep(update);
      killSteps(updateClone);
      let itemFile = storage.getRef(fontFileDeleteFile.downloadURL);
      storage
        .deleteFile(itemFile)
        .then(() => {
          timeout.current = setTimeout(() => {
            updateClone.fonts.splice(fontFileDeleteID, 1);
            setItem(updateClone);
            if (!demo) saveFonts(updateClone);
            killSteps(updateClone);
            timeout.current = setTimeout(() => {
              setFontRemoveID(null);
              setFontRemove(false);
            }, 0);
          }, 333);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const saveFonts = (update) => {
    let fonts = {
      fonts: update.fonts ? update.fonts : [],
    };
    db.saveItem(type, id, fonts);
  };

  const removeFont = (font, index) => {
    if (font.type === "upload") {
      openDeleteFontFile(index, font);
    } else {
      setItem({
        ...item,
        fonts: Object.assign([], [...fonts], {
          [index]: {
            ...fonts[index],
            deleted: true,
          },
        }),
      }).then((update) => {
        const updateClone = _cloneDeep(update);
        setFontRemove(true);
        timeout.current = setTimeout(() => {
          updateClone.fonts.splice(index, 1);
          setItem(updateClone);
          timeout.current = setTimeout(() => setFontRemove(false), 0);
        }, 333);
      });
    }
  };

  const onSortFontStart = () => {
    clearTimeout(timeout.current);
    setFontSortOn(true);
    setVariantsOn([]);
  };

  const onSortFontEnd = ({ oldIndex, newIndex }) => {
    setItem({ ...item, fonts: arrayMove([...fonts], oldIndex, newIndex) }).then(
      () => findCardDropHeight()
    );
    timeout.current = setTimeout(() => setFontSortOn(false), 500);
  };

  const findCardDropHeight = () => {
    if (document.getElementById("font-card-0")) {
      const cardDropHeight =
        document.getElementById("font-card-0").clientHeight + 2;
      setCardDropHeight(cardDropHeight);
    }
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "fonts",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.fonts ? "done" : ""
      } ${fonts.length === 0 ? "none-selected" : ""}`}
    >
      <Confirm
        className="confirm"
        open={fontFileDeleteOpen}
        header="Are you sure?"
        content="The file will be permanently deleted!"
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={cancelDeleteFontFile}
        onConfirm={deleteFontFile}
      />
      <div
        className={`component-section-inner ${fontSortOn ? "sorting" : ""} ${
          fontRemove ? "deleting" : ""
        }`}
      >
        <h2 className="with-help">
          {headerText.fonts[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={
              <p dangerouslySetInnerHTML={{ __html: helpText.fonts[label] }} />
            }
          />
        </h2>

        {type !== "projects" ? (
          <TextContent node={0} {...textContentProps} />
        ) : null}

        <div className="fonts-custom">
          <div className="type-select-wrap">
            <ul className="type-select-nav">
              {fontTypes.map((type) => (
                <li
                  className={fontType === type.id ? "active" : ""}
                  onClick={() => setFontType(type.id)}
                  key={type.id}
                >
                  <span>{type.label}</span>
                </li>
              ))}
            </ul>
            <div className="type-select-content">
              <div
                className={`fonts-google type-select-item ${
                  fontType === "google" ? "active" : ""
                }`}
              >
                {googleFontsList && googleFontsList.length > 0 ? (
                  <div className="type-select-search">
                    <Autocomplete
                      getItemValue={(item) => item.family}
                      shouldItemRender={(item, value) =>
                        item.family
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) === 0 &&
                        googleSearch.length > 0
                      }
                      items={googleFontsList}
                      inputProps={{
                        placeholder: "Search for a Google Font...",
                        autoFocus: true,
                      }}
                      menuStyle={{ top: "auto", left: "auto" }}
                      wrapperStyle={{}}
                      onSelect={(value, item) => selectGoogleFont(item)}
                      wrapperProps={{ className: "type-select-autocomplete" }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`fonts-type-family fonts-google-family ${
                            isHighlighted ? "highlighted" : ""
                          } ${
                            fonts.map((e) => e.family).indexOf(item.family) !==
                            -1
                              ? "active"
                              : ""
                          }`}
                          key={item.family}
                        >
                          <div className="fonts-google-name fonts-name">
                            {item.family}
                          </div>
                          <div className="fonts-google-variants fonts-variants">
                            <Popup
                              hoverable
                              position="top right"
                              className="font-variants-info"
                              trigger={
                                <span className="variants-info-trigger">
                                  {`${item.variants.length} ${
                                    item.variants.length === 1
                                      ? "variant"
                                      : "variants"
                                  }`}
                                </span>
                              }
                              header={
                                <ul>
                                  {item.variants.map((variant) => (
                                    <li key={variant}>{variant}</li>
                                  ))}
                                </ul>
                              }
                            />
                          </div>
                        </div>
                      )}
                      value={googleSearch}
                      onChange={searchGoogleFont}
                    />
                    <div className="type-select-search-icons">
                      {googleSearch.length === 0 ? (
                        <i className="icon icon-magnifier" />
                      ) : (
                        <i
                          className="icon icon-cross"
                          onClick={() => setGoogleSearch("")}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="inline-loading font-loading" />
                )}
                <a
                  href="https://fonts.google.com"
                  className="btn btn-text btn-under-input white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Browse Google Fonts
                </a>
              </div>
              <div
                className={`fonts-typekit type-select-item ${
                  fontType === "typekit" ? "active" : ""
                }`}
              >
                <div className="fonts-typekit-wrap">
                  <div className="type-select-search">
                    <Input
                      name="typekit-token"
                      value={typekitToken ? typekitToken : ""}
                      placeholder="Enter your Typekit Token..."
                      onChange={(event) => editTypekitToken(event)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          findTypekitToken();
                        }
                      }}
                    />
                    <div className="type-select-search-icons">
                      {(fontsTypekit &&
                        fontsTypekit.length > 0 &&
                        !typekitLoading) ||
                      (typekitError !== "" && !typekitLoading) ? (
                        <i
                          className="icon icon-cross"
                          onClick={clearTypekitSearch}
                        />
                      ) : typekitLoading ? (
                        <img
                          className="type-select-search-icon"
                          src={require("../../images/loading-md.svg")}
                          alt="Searching for token"
                        />
                      ) : (
                        <i
                          className="icon icon-magnifier"
                          onClick={findTypekitToken}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    id="fonts-typekit-wrapper"
                    className="fonts-typekit-kits"
                  >
                    {typekitError !== "" ? (
                      <div className="fonts-typekit-error fonts-error">
                        <p className="error">{typekitError}</p>
                      </div>
                    ) : null}
                    <div className="fonts-typekit-list">
                      {fontsTypekit
                        .sort((a, b) => {
                          if (a.name > b.name) {
                            return 1;
                          } else {
                            return -1;
                          }
                        })
                        .map((font, fontIndex) => (
                          <div className="typekit-kit" key={font.id}>
                            <label className="typekit-kit-name">
                              {font.name}
                              <div
                                className="btn btn-primary btn-small"
                                onClick={() =>
                                  selectAllTypekitFonts(font, fontIndex)
                                }
                              >
                                add all
                              </div>
                            </label>
                            {font.families.map((family, familyIndex) => (
                              <div
                                className={`fonts-type-family fonts-typekit-family ${
                                  fonts.map((e) => e.id).indexOf(family.id) !==
                                  -1
                                    ? "active"
                                    : ""
                                }`}
                                key={family.id}
                                onClick={() =>
                                  selectTypekitFont(
                                    family,
                                    familyIndex,
                                    font.id,
                                    fontIndex
                                  )
                                }
                              >
                                <div className="fonts-typekit-name fonts-name">
                                  {family.name}
                                </div>
                                <div className="fonts-typekit-variants fonts-variants">
                                  <Popup
                                    hoverable
                                    position="top right"
                                    className="font-variants-info"
                                    trigger={
                                      <span className="variants-info-trigger">
                                        {`${family.variations.length} ${
                                          family.variations.length === 1
                                            ? "variant"
                                            : "variants"
                                        }`}
                                      </span>
                                    }
                                    header={
                                      <ul>
                                        {family.variations.map((variant) => (
                                          <li key={variant}>{variant}</li>
                                        ))}
                                      </ul>
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <a
                  href="https://typekit.com/account/tokens"
                  className="btn btn-text btn-under-input white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Find your Typekit Token
                </a>
              </div>
              <div
                className={`fonts-websafe type-select-item ${
                  fontType === "websafe" ? "active" : ""
                }`}
              >
                <div className="type-select-search">
                  <Autocomplete
                    className="type-select-input"
                    getItemValue={(item) => item.name}
                    shouldItemRender={(item, value) =>
                      item.name.toLowerCase().indexOf(value.toLowerCase()) === 0
                    }
                    items={websafeFonts}
                    inputProps={{ placeholder: "Search for a websafe font..." }}
                    menuStyle={{ top: "auto", left: "auto" }}
                    wrapperStyle={{}}
                    onSelect={(value, item) => selectWebsafeFont(item)}
                    wrapperProps={{ className: "type-select-autocomplete" }}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`fonts-type-family fonts-websafe-family ${
                          isHighlighted ? "highlighted" : ""
                        } ${
                          fonts.map((e) => e.name).indexOf(item.name) !== -1
                            ? "active"
                            : ""
                        }`}
                        key={item.name}
                      >
                        <div
                          className="fonts-websafe-name fonts-name"
                          style={{ fontFamily: item.css_stack }}
                        >
                          {item.name}
                        </div>
                        <div className="fonts-websafe-variants fonts-variants">
                          <Popup
                            hoverable
                            position="top right"
                            className="font-variants-info"
                            trigger={
                              <span className="variants-info-trigger">
                                {`${item.variants.length} ${
                                  item.variants.length === 1
                                    ? "variant"
                                    : "variants"
                                }`}
                              </span>
                            }
                            header={
                              <ul>
                                {item.variants.map((variant) => (
                                  <li key={variant}>
                                    {variant === "400" ? "normal" : variant}
                                  </li>
                                ))}
                              </ul>
                            }
                          />
                        </div>
                      </div>
                    )}
                    value={websafeSearch}
                    onChange={editWebsafeFont}
                  />
                  <div className="type-select-search-icons">
                    {websafeSearch.length === 0 ? (
                      <i className="icon icon-magnifier" />
                    ) : (
                      <i
                        className="icon icon-cross"
                        onClick={() => setWebsafeSearch("")}
                      />
                    )}
                  </div>
                </div>
                <a
                  href="https://www.w3schools.com/cssref/css_websafe_fonts.asp"
                  className="btn btn-text btn-under-input white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What's a websafe font?
                </a>
              </div>
              <div
                className={`fonts-upload type-select-item ${
                  fontType === "upload" ? "active" : ""
                }`}
              >
                <div id="fonts-upload-wrapper" className="type-select-search">
                  <Dropzone
                    style={{}}
                    disabled={fontFilesUploading}
                    className={`type-select-dropzone files-dropzone ${
                      fontFilesUploading ? "uploading" : ""
                    }`}
                    activeClassName="active"
                    onDrop={(files) => setFontFiles(files)}
                  >
                    <div className="fonts-files-drop files-drop">
                      <h4>Drop your font files here...</h4>
                      <h5>(Only .ttf and .otf files are accepted)</h5>
                      <div className="btn btn-primary">click to upload</div>
                      <div className="logos-files-usage files-usage">
                        <label>
                          <i className="icon icon-notification" />
                          There's a 2MB limit for font files
                        </label>
                      </div>
                      <span className="files-drop-msg">
                        <i className="icon icon-dropzone" />
                        <p>Drop files here</p>
                      </span>
                      <span className="files-drop-progress">
                        <span
                          id="font-drop-progress"
                          className="drop-progress-bar"
                        />
                        <span className="upload-loading" />
                      </span>
                    </div>
                  </Dropzone>
                  {badFontFiles.length > 0 ? (
                    <div className="fonts-files-bad files-bad-wrap">
                      <span
                        className="close-files-bad"
                        onClick={() => setBadFontFiles([])}
                      >
                        <i className="icon icon-cross" />
                      </span>
                      <label>Sorry, we couldn't upload these files:</label>
                      <ul className={`files-bad`}>
                        {Object.keys(badFontFiles).map((key) => (
                          <li key={key} className="file-bad">
                            <p>
                              <span className="file-name">
                                <span className="file-text">
                                  {badFontFiles[key].name}
                                </span>
                                <span className="file-size">
                                  ({convertFileSize(badFontFiles[key].size)})
                                </span>
                              </span>
                              <span className="file-reason">
                                {badFontFiles[key].reason}
                              </span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {fonts ? (
          <SortableList
            fonts={fonts}
            toggleFontVariants={toggleFontVariants}
            toggleVariant={toggleVariant}
            editFontName={editFontName}
            changeFontName={changeFontName}
            saveFontName={saveFontName}
            cancelFontName={cancelFontName}
            variantsOn={variantsOn}
            fontAddID={fontAddID}
            fontRemove={fontRemove}
            fontRemoveID={fontRemoveID}
            removeFont={removeFont}
            fontSortOn={fontSortOn}
            cardDropHeight={cardDropHeight}
            helperClass={"item-drag font-drag"}
            distance={window.innerWidth > 1024 ? 5 : 0}
            pressDelay={window.innerWidth <= 1024 ? 200 : 0}
            axis={"xy"}
            onSortStart={({ node, index, collection }, event) => {
              event.preventDefault();
              onSortFontStart();
            }}
            onSortEnd={onSortFontEnd}
          />
        ) : null}
        <p className="section-empty">
          No fonts selected, click here to choose one.
        </p>

        {type !== "projects" ? (
          <TextContent node={1} {...textContentProps} />
        ) : null}
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={() => doneStep("fonts", step)}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editFonts}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

const SortableList = SortableContainer(
  ({
    fonts,
    toggleFontVariants,
    toggleVariant,
    fontAddID,
    fontRemoveID,
    removeFont,
    cardDropHeight,
    editFontName,
    changeFontName,
    saveFontName,
    cancelFontName,
    variantsOn,
  }) => (
    <ul
      className={`fonts-custom-list cards-list sortable ${
        fonts && fonts.length === 1 ? "has-one" : ""
      }`}
    >
      {fonts.map((font, index) => (
        <SortableItem
          key={`${font.family ? font.family : font.name}-${index}`}
          index={index}
          font={font}
          fontID={index}
          disabled={variantsOn.indexOf(font.id) !== -1}
          fontContent={
            <div
              className={`fonts-custom-content ${
                font.family
                  ? spaceToEmpty(font.family)
                  : spaceToEmpty(font.name)
              } ${variantsOn.indexOf(font.id) !== -1 ? "variantOn" : ""}`}
            >
              {font.type === "upload" ? (
                <style>
                  {`@font-face {
                    font-family: "${font.family}";
                    src: url("${font.encode}");
                  }
                `}
                </style>
              ) : null}
              <div className="fonts-custom-name">
                <h3 className="font-name">
                  {font.family ? font.family : font.name ? font.name : ""}
                  {font.variantData && font.variantData.length > 1 ? (
                    <span
                      className="font-toggle-trigger"
                      onClick={() => toggleFontVariants(font.id)}
                    />
                  ) : null}
                  {font.editOn ? (
                    <div className="font-name-edit">
                      <Input
                        name="font-name-edit-input"
                        id={`font-name-edit-input-${index}`}
                        value={font.editName ? font.editName : ""}
                        placeholder="Enter your Typekit Token..."
                        onChange={(event) => changeFontName(event, index)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            saveFontName(index);
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {font.variantData && font.variantData.length > 1 ? (
                    <div
                      className={`font-variants-toggle ${
                        variantsOn.indexOf(font.id) !== -1 ? "active" : ""
                      }`}
                    >
                      <label className="variants-selected">
                        <span>
                          {
                            font.variantData.filter(
                              (variant) => variant.active === true
                            ).length
                          }
                        </span>
                        active variants
                        <i className="icon icon-chevron-down" />
                      </label>
                    </div>
                  ) : null}
                </h3>
              </div>
              {index !== fontAddID ? (
                <div className="font-detail">
                  {font.variantData && font.variantData.length > 1 ? (
                    <span
                      className="font-toggle-trigger"
                      onClick={() => toggleFontVariants(index)}
                    />
                  ) : null}
                  {
                    <style>
                      {`
                      .fonts-custom-content.${
                        font.family
                          ? spaceToEmpty(font.family)
                          : spaceToEmpty(font.name)
                      } .font-detail h4.font-aa, 
                      .fonts-custom-content.${
                        font.family
                          ? spaceToEmpty(font.family)
                          : spaceToEmpty(font.name)
                      } .font-detail p.font-example {
                        font-family: ${
                          font.family
                            ? `"${font.family}"`
                            : font.css_stack
                            ? font.css_stack
                            : "inherit"
                        };
                        font-weight: ${findNormalFontWeight(font)};
                        font-style: ${findNormalFontStyle(font)};
                      }
                    `}
                    </style>
                  }
                  <h4 className="font-aa">Aa</h4>
                  <p className="font-example">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    <br />
                    abcdefghijklmnopqrstuvwxyz
                    <br />
                    1234567890(,.;:?!$&*)
                  </p>
                  <div className="font-buttons">
                    {font.type === "upload" ? (
                      <span className="font-edit-btns-wrap">
                        {font.editOn ? (
                          <span className="font-edit-btns">
                            <div
                              className="btn btn-small btn-text"
                              onClick={() => cancelFontName(index)}
                            >
                              cancel
                            </div>
                            <div
                              className="btn btn-small btn-success"
                              onClick={() => saveFontName(index)}
                            >
                              save
                            </div>
                          </span>
                        ) : (
                          <span
                            className="btn btn-white btn-small"
                            title="Edit font name"
                            onClick={() => editFontName(index)}
                          >
                            <i className="icon icon-edit" />
                          </span>
                        )}
                      </span>
                    ) : null}
                    {font.type === "google" ? (
                      <a
                        className="btn btn-white btn-small"
                        title="Download font file"
                        href={
                          font.type === "upload"
                            ? font.downloadURL
                            : `https://fonts.google.com/download?family=${font.family}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon icon-download" />
                      </a>
                    ) : null}
                    {font.type === "google" || font.type === "typekit" ? (
                      <a
                        className="btn btn-white btn-small"
                        title="View font detail"
                        href={
                          font.type === "typekit"
                            ? `https://www.typekit.com/fonts/${font.slug}`
                            : `https://fonts.google.com/specimen/${font.family}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon icon-link" />
                      </a>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="inline-loading" />
              )}
              {font.variantData &&
              font.variantData.length > 1 &&
              variantsOn.indexOf(font.id) !== -1 ? (
                <div
                  className={`font-variants-list ${
                    variantsOn.indexOf(font.id) !== -1 ? "active" : ""
                  }`}
                >
                  {font.variantData.map((variant, index) => (
                    <div
                      className={`font-detail ${
                        variant.active ? "active" : ""
                      }`}
                      key={variant.name}
                      onClick={() => toggleVariant(font, variant)}
                    >
                      <h4
                        className="font-aa"
                        style={{
                          fontFamily: font.family
                            ? `'${font.family}'`
                            : font.css_stack
                            ? font.css_stack
                            : "inherit",
                          fontWeight: variant.weight,
                          fontStyle: variant.style,
                        }}
                      >
                        Aa
                      </h4>
                      <div className="font-content">
                        <p
                          className="font-example"
                          style={{
                            fontFamily: font.family
                              ? `'${font.family}'`
                              : font.css_stack
                              ? font.css_stack
                              : "inherit",
                            fontWeight: variant.weight,
                            fontStyle: variant.style,
                          }}
                        >
                          ABCDEFGHIJKLMNOPQRSTUVWXYZ
                          <br />
                          abcdefghijklmnopqrstuvwxyz
                          <br />
                          1234567890(,.;:?!$&*)
                        </p>
                        <label>
                          <span>Weight:</span> {variant.weight}
                        </label>
                        <label>
                          <span>Style:</span> {variant.style}
                        </label>
                      </div>
                      <div className="font-toggle">
                        <i className="icon icon-check" />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <span
                className="card-delete-wrap"
                onClick={() => removeFont(font, index)}
              >
                <span className="card-delete">
                  {font.type === "upload" ? (
                    <i className="icon icon-trash" />
                  ) : (
                    <i className="icon icon-cross" />
                  )}
                </span>
              </span>
            </div>
          }
        />
      ))}
      {fonts.length > 1 ? (
        <span
          className="font-primary-drop card"
          style={{ height: cardDropHeight }}
        >
          <p>Primary Font</p>
        </span>
      ) : null}
    </ul>
  )
);

const SortableItem = SortableElement(({ font, fontID, fontContent }) => (
  <li
    id={`font-card-${fontID}`}
    className={`card selected ${font.new ? "new" : ""} ${
      font.deleted ? "deleted" : ""
    } ${font.variantData && font.variantData.length > 1 ? "has-variants" : ""}`}
  >
    {fontContent}
  </li>
));

const fontTypes = [
  {
    id: "google",
    label: "Google",
  },
  {
    id: "typekit",
    label: "Typekit",
  },
  {
    id: "websafe",
    label: "Websafe",
  },
  {
    id: "upload",
    label: "Upload",
  },
];

const FontsDisplay = ({ type, fonts }) => (
  <div className="font-inner">
    {fonts.map((font, index) => (
      <div
        className={`item-publish-font ${
          font.family ? spaceToEmpty(font.family) : spaceToEmpty(font.name)
        }`}
        key={`font-${index}`}
      >
        {font.type === "upload" ? (
          <style>
            {`@font-face {
                font-family: "${font.family}";
                src: url("${font.encode}");
              }
            `}
          </style>
        ) : null}
        <style>
          {`
            #${type} .item-publish-font.${
            font.family ? spaceToEmpty(font.family) : spaceToEmpty(font.name)
          } * {
              font-family: ${font.family ? `"${font.family}"` : font.css_stack};
              ${index > 0 ? `font-weight: ${findNormalFontWeight(font)};` : ""}
              ${index > 0 ? `font-style: ${findNormalFontStyle(font)};` : ""}
            }
          `}
        </style>
        {font.type === "google" ? (
          <a
            href={
              font.type === "upload"
                ? font.downloadURL
                : `https://fonts.google.com/download?family=${font.family}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="card-download">
              <i className="icon icon-download" />
            </span>
          </a>
        ) : null}
        {font.type === "google" || font.type === "typekit" ? (
          <a
            href={
              font.type === "typekit"
                ? `https://www.typekit.com/fonts/${font.slug}`
                : `https://fonts.google.com/specimen/${font.family}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="card-link">
              <i className="icon icon-link" />
            </span>
          </a>
        ) : null}
        <h2>{font.family ? font.family : font.name ? font.name : ""}</h2>
        <p className="font-paragraph">
          I wandered lonely as a cloud That floats on high o'er vales and hills,
          When all at once I saw a crowd, A host, of golden daffodils; Beside
          the lake, beneath the trees, Fluttering and dancing in the breeze.
          Continuous as the stars that shine And twinkle on the milky way, They
          stretched in never-ending line Along the margin of a bay: Ten thousand
          saw I at a glance, Tossing their heads in sprightly dance.
        </p>
        <h3>Font Characters</h3>
        <p className="font-characters">
          {`A B C D E F G H I J K L M N O P Q R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 0 ‘ ? ’ “ ! ” ( % ) [ # ] { @ } / & < - + ÷ × = > ® © $ € £ ¥ ¢ : ; , . *`}
        </p>

        {font.variantData ? (
          <div className="font-variants">
            <h3>Font Variants</h3>
            <ul>
              {font.variantData.map((variant) =>
                variant.active ? (
                  <li
                    style={{
                      fontWeight: variant.weight,
                      fontStyle: variant.style,
                    }}
                    key={variant.name}
                  >
                    {font.family ? font.family : font.name ? font.name : ""}{" "}
                    {normalFontVariant(variant.name, font.type)}
                  </li>
                ) : null
              )}
            </ul>
          </div>
        ) : null}
      </div>
    ))}
  </div>
);

export { FontsDisplay };

export default Fonts;
