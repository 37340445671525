import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactBody from "react-body";
import * as routes from "constants/routes";
import { ThemeThumb } from "../shared/Themes";
import Parallax from "../shared/Parallax.js";
import { itemProject, itemStyleguide, itemShowcase } from "../shared/Resources";
import { Picture } from "react-responsive-picture";
import { Helmet } from "react-helmet-async";

const Home = (props) => {
  const { history } = props;
  const timeout = useRef(null);
  const [current, setCurrent] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [playing, setPlaying] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrent("projects");
    setTimeout(() => {
      setPlaying(true);
    }, 250);
  }, []);

  useEffect(() => {
    if (playing === true) {
      timeout.current = setTimeout(() => {
        setPrevious(current);
        setCurrent(
          current === "projects"
            ? "styleguides"
            : current === "styleguides"
            ? "showcases"
            : "projects"
        );
      }, 2000);
    } else {
      clearTimeout(timeout.current);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [current, playing]);

  const goToType = (type) => {
    if (type !== current) {
      clearTimeout(timeout.current);
      setPlaying(false);
      setPrevious(current);
      setCurrent(type);
    }
  };

  return (
    <section id="home">
      <Helmet>
        <title>DesignGapp</title>
        <meta
          name="description"
          content="Simple, intuitive design documentation for projects and styleguides."
        />
      </Helmet>
      <div className="home-spectrum">
        <div
          className={`spectrum-projects ${
            current === "projects"
              ? "current"
              : previous === "projects"
              ? "previous"
              : ""
          }`}
        />
        <div
          className={`spectrum-styleguides ${
            current === "styleguides"
              ? "current"
              : previous === "styleguides"
              ? "previous"
              : ""
          }`}
        />
        <div
          className={`spectrum-showcases ${
            current === "showcases"
              ? "current"
              : previous === "showcases"
              ? "previous"
              : ""
          }`}
        />
      </div>
      <div className="home-top">
        <div className="home-header">
          <div
            className={`home-header-content ${
              playing !== null ? "playing" : ""
            }`}
          >
            <h1 className="item-types">
              <span
                className={`item-type projects ${
                  current === "projects"
                    ? "current"
                    : previous === "projects"
                    ? "previous"
                    : ""
                }`}
                onClick={() => goToType("projects")}
              >
                Projects,
              </span>
              <span
                className={`item-type styleguides ${
                  current === "styleguides"
                    ? "current"
                    : previous === "styleguides"
                    ? "previous"
                    : ""
                }`}
                onClick={() => goToType("styleguides")}
              >
                Styleguides,
              </span>
              <span
                className={`item-type showcases ${
                  current === "showcases"
                    ? "current"
                    : previous === "showcases"
                    ? "previous"
                    : ""
                }`}
                onClick={() => goToType("showcases")}
              >
                Showcases
              </span>
            </h1>
            <div className={`home-header-thumbs ${current} prev-${previous}`}>
              <ThemeThumb
                item={itemProject}
                type="projects"
                onClick={() => history.push(`${routes.About}#projects`)}
              />
              <ThemeThumb
                item={itemStyleguide}
                type="styleguides"
                onClick={() => history.push(`${routes.About}#styleguides`)}
              />
              <ThemeThumb
                item={itemShowcase}
                type="showcases"
                onClick={() => history.push(`${routes.About}#showcases`)}
              />
            </div>
          </div>
          <div className="home-top-btns">
            <Link to={`${routes.About}#${current}`}>
              <button
                className={`btn btn-${
                  current === "projects"
                    ? "secondary"
                    : current === "styleguides"
                    ? "accent"
                    : current === "showcases"
                    ? "tertiary"
                    : "secondary"
                }`}
              >
                how it works
              </button>
            </Link>
            {/*<Link to={`${routes.Demo}#${current}`}><button className="btn btn-quaternary">try the demo</button></Link>*/}
          </div>
        </div>
      </div>

      <div className="home-demo">
        <span className="bg-texture light" />
        <h2>
          Create sharable design{" "}
          <Link className="projects" to={`${routes.About}#projects`}>
            projects
          </Link>
          ,{" "}
          <Link className="styleguides" to={`${routes.About}#styleguides`}>
            styleguides
          </Link>
          , and{" "}
          <Link className="showcases" to={`${routes.About}#showcases`}>
            showcases
          </Link>{" "}
          in minute(s).
        </h2>
        <iframe
          width="726"
          height="408"
          src="https://www.youtube.com/embed/U_QAzOxYqlk"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="60 Second Styleguide"
          allowFullScreen
        ></iframe>
        <Link to={`${routes.Demo}#stylguide`}>
          <button className="btn btn-quaternary">try the demo</button>
        </Link>
      </div>

      <div className="home-about">
        <div className="home-how-it-works">
          <div className="how-it-works-wrap projects">
            <span className="bg-texture" />
            <div className="how-it-works-inner">
              <div className="how-it-works-imgs">
                <Parallax
                  speed="20"
                  direction="up"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/project-1.jpg")}
                    alt="Guide design thinking by understanding your users"
                  />
                </Parallax>
                <Parallax
                  speed="25"
                  direction="down"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/project-2.jpg")}
                    alt="Guide design thinking by understanding your users"
                  />
                </Parallax>
                <Parallax
                  speed="20"
                  direction="up"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/project-3.jpg")}
                    alt="Guide design thinking by understanding your users"
                  />
                </Parallax>
              </div>
              <div className="how-it-works-text">
                <h3>Document design projects.</h3>
                <ul>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Capture the design details before you kickoff
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Quickly find color palettes, fonts, and imagery
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Easily document design requirements in minutes
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Securely share the details with stakeholders
                  </li>
                </ul>
                <div className="how-it-works-btns">
                  <Link to={`${routes.About}#projects`}>
                    <button className="btn btn-secondary">how it works</button>
                  </Link>
                  <Link to={`${routes.Demo}#project`}>
                    <button className="btn btn-quaternary">try the demo</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="how-it-works-wrap styleguides">
            <span className="bg-texture" />
            <div className="how-it-works-inner">
              <div className="how-it-works-imgs">
                <Parallax
                  speed="25"
                  direction="up"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/styleguide-1.jpg")}
                    alt="Capture important project details and files"
                  />
                </Parallax>
                <Parallax
                  speed="15"
                  direction="up"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/styleguide-5.jpg")}
                    alt="Capture important project details and files"
                  />
                </Parallax>
                <Parallax
                  speed="20"
                  direction="down"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/styleguide-2.jpg")}
                    alt="Capture important project details and files"
                  />
                </Parallax>
              </div>
              <div className="how-it-works-text">
                <h3>Create beautiful styleguides.</h3>
                <ul>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Build a beautiful styleguide in minutes
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Easily collaborate with your team and clients
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Securely save and share important brand files
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Quickly design UI elements and view the CSS
                  </li>
                </ul>
                <div className="how-it-works-btns">
                  <Link to={`${routes.About}#styleguides`}>
                    <button className="btn btn-accent">how it works</button>
                  </Link>
                  <Link to={`${routes.Demo}#styleguide`}>
                    <button className="btn btn-quaternary">try the demo</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="how-it-works-wrap showcases">
            <span className="bg-texture" />
            <div className="how-it-works-inner">
              <div className="how-it-works-imgs">
                <Parallax
                  speed="20"
                  direction="up"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/showcase-8.jpg")}
                    alt="Showcase your designs with colorful backgrounds and device frames"
                  />
                </Parallax>
                <Parallax
                  speed="25"
                  direction="down"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/showcase-6.jpg")}
                    alt="Showcase your designs with colorful backgrounds and device frames"
                  />
                </Parallax>
                <Parallax
                  speed="15"
                  direction="down"
                  className="how-it-works-img"
                >
                  <img
                    src={require("../../images/home/showcase-9.jpg")}
                    alt="Showcase your designs with colorful backgrounds and device frames"
                  />
                </Parallax>
              </div>
              <div className="how-it-works-text">
                <h3>Showcase your designs.</h3>
                <ul>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Expertly present your designs to stakeholders
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Accentuate designs with device frames &amp; colors
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Easily add palettes and fonts used in designs
                  </li>
                  <li>
                    <i className="icon icon-checkmark-circle" />
                    Securely publish and share your designs
                  </li>
                </ul>
                <div className="how-it-works-btns">
                  <Link to={`${routes.About}#showcases`}>
                    <button className="btn btn-tertiary">how it works</button>
                  </Link>
                  <Link to={`${routes.Demo}#showcase`}>
                    <button className="btn btn-quaternary">try the demo</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-samples">
        <span className="bg-texture light" />
        <div className="home-samples-inner">
          <h2>Check out some samples...</h2>
          <div className="home-sample-items home-sample-projects">
            <p className="sample-title">
              <i className="icon icon-undo" /> projects
            </p>
            <div className="home-samples-list">
              <a
                href="https://www.designgapp.com/p/ph3ZuxXWO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Project Sample"
                  sources={[
                    {
                      srcSet: require("../../images/samples/projects/designgapp-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/projects/designgapp.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/projects/designgapp.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/p/SkreuFX2z"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Project: FTP Creative"
                  sources={[
                    {
                      srcSet: require("../../images/samples/projects/ftp-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/projects/ftp.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/projects/ftp.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/p/ByOgEZX2f"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Project: Vide Consulting"
                  sources={[
                    {
                      srcSet: require("../../images/samples/projects/vide-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/projects/vide.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/projects/vide.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/p/3yY3HG7Tl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Project: Listhour"
                  sources={[
                    {
                      srcSet: require("../../images/samples/projects/listhour-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/projects/listhour.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/projects/listhour.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/p/LvR1eGTFE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Project: Ellis & English"
                  sources={[
                    {
                      srcSet: require("../../images/samples/projects/ellis-english-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/projects/ellis-english.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/projects/ellis-english.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
            </div>
          </div>
          <div className="home-sample-items home-sample-styleguides">
            <p className="sample-title">
              styleguides <i className="icon icon-undo" />
            </p>
            <div className="home-samples-list">
              <a
                href="https://www.designgapp.com/s/jOo2AZi1p"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Styleguide Sample"
                  sources={[
                    {
                      srcSet: require("../../images/samples/styleguides/designgapp-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/designgapp.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/designgapp.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/s/bYzjWTpDV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Styleguide: RightNow"
                  sources={[
                    {
                      srcSet: require("../../images/samples/styleguides/rightnow-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/rightnow.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/rightnow.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/s/hL-qOjXF"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Styleguide: Fishbowl"
                  sources={[
                    {
                      srcSet: require("../../images/samples/styleguides/fishbowl-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/fishbowl.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/fishbowl.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/s/ZXQoXCG9F"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Styleguide: 3 Dates"
                  sources={[
                    {
                      srcSet: require("../../images/samples/styleguides/3dates-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/3dates.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/3dates.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/s/4v_jGSdS9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Styleguide: Alluvion"
                  sources={[
                    {
                      srcSet: require("../../images/samples/styleguides/alluvion-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/alluvion.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/styleguides/alluvion.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
            </div>
          </div>
          <div className="home-sample-items home-sample-showcases">
            <p className="sample-title">
              <i className="icon icon-undo" /> showcases
            </p>
            <div className="home-samples-list">
              <a
                href="https://www.designgapp.com/h/wy4k_3Sap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Showcase: Designgapp"
                  sources={[
                    {
                      srcSet: require("../../images/samples/showcases/designgapp-alt-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/showcases/designgapp-alt.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/showcases/designgapp-alt.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/h/Obo-a8mU7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Showcase: CADD Community"
                  sources={[
                    {
                      srcSet: require("../../images/samples/showcases/cadd-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/showcases/cadd.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/showcases/cadd.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/h/7pOvvYKsz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Showcase: XChanger"
                  sources={[
                    {
                      srcSet: require("../../images/samples/showcases/xchanger-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/showcases/xchanger.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/showcases/xchanger.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/h/RGDNAdOGL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Showcase: Pierina Sanchez 2021"
                  sources={[
                    {
                      srcSet: require("../../images/samples/showcases/pierina-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/showcases/pierina.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/showcases/pierina.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
              <a
                href="https://www.designgapp.com/h/G0vuF_9Fq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Picture
                  alt="Showcase: NTS"
                  sources={[
                    {
                      srcSet: require("../../images/samples/showcases/nts-sm.jpg"),
                      media: "(max-width: 768px)",
                    },
                    {
                      srcSet: require("../../images/samples/showcases/nts.jpg"),
                    },
                    {
                      srcSet: require("../../images/samples/showcases/nts.webp"),
                      type: "image/webp",
                    },
                  ]}
                />
              </a>
            </div>
          </div>
        </div>
        <Link to={routes.Demo}>
          <button className="btn btn-quaternary">try the demo</button>
        </Link>
      </div>

      <div className="home-bottom">
        <h2>
          Start documenting your designs.
          <br />
          Sign up &ndash; Free.
        </h2>
        <Link to={routes.Signup}>
          <button className="btn btn-accent">get started now</button>
        </Link>
      </div>

      <ReactBody className="home" />
    </section>
  );
};

export default Home;
