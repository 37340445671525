import React from "react";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import Item from "../shared/Item";
import withAuthorization from "components/withAuthorization";

export const Showcase = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <Item
      history={props.history}
      type={"showcases"}
      label={"showcase"}
      authUser={authUser}
      itemID={props.match.params.showcaseID}
    />
  );
};

const authCondition = (authUser) => !!authUser;

export default compose(withAuthorization(authCondition))(Showcase);
