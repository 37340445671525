import React from "react";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import Items from "../shared/Items";
import withAuthorization from "components/withAuthorization";

export const Projects = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const userMembership = useSelector(
    (state) => state.sessionState.userMembership
  );

  return (
    <Items
      history={props.history}
      type={"projects"}
      label={"project"}
      authUser={authUser}
      userMembership={userMembership}
    />
  );
};

const authCondition = (authUser) => !!authUser;

export default compose(withAuthorization(authCondition))(Projects);
