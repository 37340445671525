import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { auth, db, firebase } from "../../firebase";
import { updateProfile } from "firebase/auth";
import * as routes from "constants/routes";
import LogoutButton from "components/users/Logout";
import LoginGoogle from "./LoginGoogle";
import ReactBody from "react-body";
import { Input } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { inFrame, capString } from "../shared/Resources";

export const Signup = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const userSignup = useSelector((state) => state.sessionState.userSignup);

  return (
    <section
      id="signup"
      className="content-outer"
      onLoad={window.scrollTo(0, 0)}
    >
      <Helmet>
        <title>DesignGapp - Sign up</title>
        <meta name="description" content="Sign up for DesignGapp." />
      </Helmet>
      {!authUser ? (
        <div>
          <h1>Sign up</h1>
          <h3>Free forever. No credit card needed.</h3>
          <SignupForm history={props.history} />
          <LoginLink />
        </div>
      ) : authUser && !userSignup ? (
        <LoggedInMsg />
      ) : (
        <div className="content-loading no-header" />
      )}
      <ReactBody className="signup" />
    </section>
  );
};

const SignupForm = (props) => {
  const {
    history,
    item,
    itemTemplate,
    type,
    label,
    userSignupType,
    setUserSignupModal,
  } = props;

  const dispatch = useDispatch();
  const setUserName = useCallback(
    (name) => dispatch({ type: "USER_NAME", name }),
    [dispatch]
  );
  const userSignup = useSelector((state) => state.sessionState.userSignup);
  const setUserSignup = useCallback(
    (userSignup) => dispatch({ type: "USER_SIGNUP", userSignup }),
    [dispatch]
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const isInvalid =
    password === "" || email === "" || name === "" || password.length < 6;

  useEffect(() => {
    if (inFrame()) {
      history.push(routes.IframeError);
    }
  }, [history]);

  const saveDemo = (itemID, google) => {
    const user = firebase.auth.currentUser;

    let itemPubList = {
      name: true,
      publish: true,
      adjectives: item.adjectives ? true : null,
      palette: item.palette ? true : null,
      font: item.font ? true : null,
      fonts: item.fonts ? true : null,
      images: item.images ? true : null,
      audience: item.audience ? true : null,
      details: item.details ? true : null,
      files: item.files ? true : null,
      logos: item.logos ? true : null,
      icons: item.icons ? true : null,
      elements: item.elements ? true : null,
      screens: item.screens ? true : null,
    };

    let itemData = {
      updated: Date.now(),
      updatedBy: user ? user.uid : [],
      stepCount: item.stepCount ? item.stepCount : 1,
      name: item.name ? item.name : [],
      content: item.content ? item.content : [],
      adjectives: item.adjectives ? item.adjectives : [],
      palette: item.palette ? item.palette : [],
      font: item.font ? item.font : [],
      fonts: item.fonts ? item.fonts : [],
      images: item.images ? item.images : [],
      audience: item.audience ? item.audience : [],
      details: item.details ? item.details : [],
      files: item.files ? item.files : [],
      logos: item.logos ? item.logos : [],
      icons: item.icons ? item.icons : [],
      elements: item.elements ? item.elements : [],
      screens: item.screens ? item.screens : [],
      theme: item.theme ? item.theme : [],
      doneList:
        userSignupType === "publish"
          ? itemPubList
          : item.doneList
          ? item.doneList
          : [],
    };
    let itemPath = userSignupType === "publish" ? itemID : "edit/" + itemID;
    db.saveItem(type, itemID, itemData).then(() => {
      firebase
        .saveDemoFiles({ id: itemID, type: type, itemData: itemData })
        .then(() => {
          if (google) {
            setUserSignup(false);
            history.push(`${routes[capString(label)]}/${itemPath}`);
          } else {
            sendVerifyEmail(itemPath);
          }
        });
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setUserSignup(true);
    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then(() => {
        const user = firebase.auth.currentUser;
        db.createUser(user.uid, name, email, "new user", null)
          .then(() => {
            setUserName(name);
            updateProfile(user, {
              displayName: name,
            })
              .then(() => {
                if (item) {
                  db.createItem(type, user.uid, saveDemo, itemTemplate.version);
                } else {
                  sendVerifyEmail();
                }
              })
              .catch((error) => {
                console.log(error);
                setError(error);
                setUserSignup(false);
              });
          })
          .catch((error) => {
            console.log(error);
            setError(error);
            setUserSignup(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setUserSignup(false);
      });
  };

  const sendVerifyEmail = (itemID) => {
    auth
      .doSendEmailVerification()
      .then(() => {
        setUserSignup(false);
        history.push({
          pathname: routes.Verify,
          search: itemID ? itemID : null,
        });
      })
      .catch((error) => {
        console.log(error);
        setUserSignup(false);
      });
  };

  const saveDemoGoogle = (demo) => saveDemo(demo, true);

  return (
    <form id="form-signup" onSubmit={onSubmit}>
      <Input
        autoFocus={window.innerWidth > 1024 ? true : false}
        value={name}
        onChange={(event) => setName(event.target.value)}
        type="text"
        placeholder="Name"
      />
      <Input
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        type="email"
        placeholder="Email"
      />
      <Input
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        type="password"
        placeholder="Password (6 char min)"
      />
      <button disabled={isInvalid} type="submit" className="btn btn-primary">
        {!userSignup ? <span>sign up</span> : <span className="btn-loading" />}
      </button>
      <p className="legal">
        By clicking <em>sign up</em>, you agree to the{" "}
        <Link to={routes.Terms} target="_blank">
          terms &amp; conditions
        </Link>
        .
      </p>
      {error && <p className="error">{error.message}</p>}
      <LoginGoogle
        action={"signup"}
        history={history}
        item={item}
        itemTemplate={itemTemplate}
        type={type}
        saveDemo={saveDemoGoogle}
        setUserSignupModal={setUserSignupModal}
      />
    </form>
  );
};

const LoginLink = () => (
  <Link className="auth-bottom-link" to={routes.Login}>
    Already have an account? Log in here.
  </Link>
);

const LoggedInMsg = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <div className="msg-logged-in" onLoad={window.scrollTo(0, 0)}>
      {authUser ? (
        <span>
          <h1>
            You're already signed up and logged in. Please logout first to sign
            up.
          </h1>
          <div className="btn-row">
            <LogoutButton />
          </div>
        </span>
      ) : (
        <div className="content-loading no-header" />
      )}
    </div>
  );
};

export default compose(Signup);

export { SignupForm };
