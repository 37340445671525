import React, { useState } from "react";
import { Popup, Modal } from "semantic-ui-react";
import { headerText, helpText, replaceSpace } from "./Resources";
import Fonts from "./Fonts";

export const FontList = (props) => {
  const {
    label,
    step,
    doneStep,
    item,
    item: { font, doneList },
    setItem,
    itemTemplate,
    demo,
  } = props;

  const [fontExample, setFontExample] = useState([]);
  const [fontModalOpen, setFontModalOpen] = useState(false);

  const editFont = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        font: false,
      },
    });
  };

  const toggleFont = (name, family) => {
    setFontModalOpen(false);
    setItem({
      ...item,
      font: {
        ...item.font,
        name: !item.font
          ? "mulish"
          : item.font && item.font.name && item.font.name !== name
          ? name
          : item.font.name,
        family: !item.font
          ? "Mulish"
          : item.font && item.font.family && item.font.family !== family
          ? family
          : item.font.family,
      },
    });
  };

  const openFontModal = (font) => {
    setFontExample(font);
    setFontModalOpen(true);
  };

  const closeFontModal = () => {
    setFontExample([]);
    setFontModalOpen(false);
  };

  const toggleFontType = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      font: {
        ...item.font,
        type: font.type === "basic" ? "advanced" : "basic",
      },
    }).then(() => {
      const stepDiv = document.getElementById(`step-${step}`);
      if (stepDiv) stepDiv.scrollIntoView();
    });
  };

  const doneFont = () => {
    if (font.type === "advanced" && (!item.fonts || item.fonts.length === 0)) {
      setItem({
        ...item,
        font: {
          ...item.font,
          type: "basic",
        },
      }).then(() => doneStep("font", step));
    } else {
      doneStep("font", step);
    }
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.font ? "done" : ""
      } ${
        font.type === "advanced" && item.fonts.length === 0
          ? "none-selected"
          : ""
      }`}
    >
      <Modal
        className="font-example-modal fonts-list"
        open={fontModalOpen}
        onClose={closeFontModal}
      >
        <div className="modal-close-wrap">
          <span className="modal-close" onClick={closeFontModal}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        {font && font.name && font.name === fontExample.name ? (
          <span className="card-selected">
            <i className="icon icon-check" />
          </span>
        ) : null}
        <Modal.Content className={`font ${replaceSpace(fontExample.name)}`}>
          <h1>
            {fontExample.name}
            <span className="font-btns">
              <a
                href={`https://fonts.google.com/download?family=${fontExample.family}`}
                className="btn btn-small btn-white font-download"
                title="Download the font file"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon icon-download" />
              </a>
              <a
                href={`https://fonts.google.com/specimen/${fontExample.family}`}
                className="btn btn-small btn-white font-link"
                target="_blank"
                title="View font in Google Fonts"
                rel="noopener noreferrer"
              >
                <i className="icon icon-link" />
              </a>
            </span>
          </h1>
          <p className="font-paragraph">
            I wandered lonely as a cloud That floats on high o'er vales and
            hills, When all at once I saw a crowd, A host, of golden daffodils;
            Beside the lake, beneath the trees, Fluttering and dancing in the
            breeze. Continuous as the stars that shine And twinkle on the milky
            way, They stretched in never-ending line Along the margin of a bay:
            Ten thousand saw I at a glance, Tossing their heads in sprightly
            dance.
          </p>
          <h2>Font Characters</h2>
          <p className="font-characters">
            {`A B C D E F G H I J K L M N O P Q R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 0 ‘ ? ’ “ ! ” ( % ) [ # ] { @ } / & < - + ÷ × = > ® © $ € £ ¥ ¢ : ; , . *`}
          </p>
          <span className="btn-row">
            {font && font.name && font.name !== fontExample.name ? (
              <button
                className="btn btn-primary"
                onClick={() => toggleFont(fontExample.name, fontExample.family)}
              >
                use this font
              </button>
            ) : (
              <button className="btn btn-primary" onClick={closeFontModal}>
                close window
              </button>
            )}
          </span>
        </Modal.Content>
      </Modal>
      <div
        className={`component-section-inner ${
          font.type === "basic" ? "basic" : "advanced"
        }`}
      >
        <h2 className="with-help">
          {headerText.fonts[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={
              <p dangerouslySetInnerHTML={{ __html: helpText.fonts[label] }} />
            }
          />
        </h2>

        {font.type === "basic" ? (
          <ul className="fonts-list cards-list">
            {Object.keys(itemTemplate.sections.fontList).map((key) => (
              <li
                key={key}
                className={`card ${replaceSpace(
                  itemTemplate.sections.fontList[key].name
                )} ${
                  font.name === itemTemplate.sections.fontList[key].name
                    ? "selected"
                    : ""
                }`}
                onClick={() =>
                  toggleFont(
                    itemTemplate.sections.fontList[key].name,
                    itemTemplate.sections.fontList[key].family
                  )
                }
              >
                <p>{itemTemplate.sections.fontList[key].name}</p>
                <span
                  className="card-expand"
                  onClick={(event) => {
                    event.stopPropagation();
                    openFontModal(itemTemplate.sections.fontList[key]);
                  }}
                >
                  <i className="icon icon-text-size" />
                </span>
              </li>
            ))}
          </ul>
        ) : null}
        {font && font.type === "advanced" ? (
          <div className="fonts-advanced">
            <Fonts {...props} />
          </div>
        ) : null}
        {!demo ? (
          <div className="font-type-toggle">
            <div className="btn btn-text" onClick={toggleFontType}>
              {font.type === "basic"
                ? "Not finding the right font? View advanced options."
                : "Too advanced? Back to basics."}
            </div>
          </div>
        ) : null}
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneFont}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editFont}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default FontList;
