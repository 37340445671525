import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  applyActionCode,
  checkActionCode,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";

export const doCreateUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const doApplyActionCode = (code) => applyActionCode(auth, code);

export const doCheckActionCode = (code) => checkActionCode(auth, code);

export const doSignInWithEmailAndPassword = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const doSignOut = () => signOut(auth);

export const doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

export const doPasswordUpdate = (password) =>
  updatePassword(auth.currentUser, password);

export const doPasswordResetCode = (code) =>
  verifyPasswordResetCode(auth, code);

export const doPasswordResetConfirm = (code, password) =>
  confirmPasswordReset(auth, code, password);

export const doSignInWithPopup = (provider) => signInWithPopup(auth, provider);

export const doSendEmailVerification = () =>
  sendEmailVerification(auth.currentUser);
