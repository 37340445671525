import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Popup } from "semantic-ui-react";
import Sticky from "react-sticky-el";
import {
  useMountEffect,
  customPresetColors,
  headerText,
  helpText,
} from "./Resources";
import tinycolor from "tinycolor2";

export const PaletteList = (props) => {
  const {
    label,
    step,
    doneStep,
    item,
    item: { palette, adjectives, doneList },
    setItem,
    itemTemplate,
  } = props;

  const [primaryOn, setPrimaryOn] = useState(true);
  const [secondaryOn, setSecondaryOn] = useState(false);
  const [accentOn, setAccentOn] = useState(false);
  const [recommendedColors, setRecommendedColors] = useState([]);

  useMountEffect(() => {
    if (palette && palette.name === "custom") {
      findCustomColors();
    }
  });

  const editPalette = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        palette: false,
      },
    });
  };

  const editColors = (update) => {
    setItem({
      ...item,
      palette: {
        id: "00",
        name: "custom",
        colors: update.colors,
      },
    }).then(() => {
      setPrimaryOn(true);
      setSecondaryOn(false);
      setAccentOn(false);
      findPrimaryAlt(update.colors.primary);
      setTimeout(() => {
        document
          .getElementById("custom-palette")
          .scrollIntoView({ behavior: "smooth" });
      });
    });
  };

  const togglePalette = (update) => {
    setItem({
      ...item,
      palette: palette.name !== update.name ? update : { colors: [] },
    });
  };

  const findPaletteMatch = (palettes, adjectives) => {
    let hasMatch = false;
    adjectives.forEach((adjective) => {
      palettes.forEach((palette) => {
        if (adjective === palette) {
          hasMatch = true;
        }
      });
    });
    return hasMatch;
  };

  const findCustomColors = () => {
    setPrimaryOn(palette.colors.primary === "" ? true : false);
    setSecondaryOn(false);
    setAccentOn(false);
    findPrimaryAlt(palette.colors.primary);
  };

  const customPalette = () => {
    if (!palette || (palette && palette.name !== "custom")) {
      setItem({
        ...item,
        palette: {
          id: "00",
          name: "custom",
          colors: {
            primary: "",
            secondary: "",
            accent: "",
          },
        },
      }).then(() => {
        setTimeout(() => {
          document
            .getElementById("custom-palette")
            .scrollIntoView({ block: "center", behavior: "smooth" });
        });
      });
    }
  };

  const colorPrimaryOn = () => {
    setPrimaryOn(!primaryOn);
    setSecondaryOn(false);
    setAccentOn(false);
  };

  const colorSecondaryOn = () => {
    setPrimaryOn(false);
    setSecondaryOn(!secondaryOn);
    setAccentOn(false);
  };

  const colorAccentOn = () => {
    setPrimaryOn(false);
    setSecondaryOn(false);
    setAccentOn(!accentOn);
  };

  const findPrimaryAlt = (color) => {
    let recommended = [];
    let colorChecked = colorCheck(tinycolor(color));
    let compColor = tinycolor(colorChecked).complement();
    //let analColor = tinycolor(colorChecked).analogous();
    let monoColor = tinycolor(colorChecked).monochromatic();
    let splitCompColor = tinycolor(colorChecked).splitcomplement();
    let triadColor = tinycolor(colorChecked).triad();
    let tetradColor = tinycolor(colorChecked).tetrad();
    recommended.push(
      { hex: compColor.toHexString() },
      { hex: monoColor[4].toHexString() },
      { hex: monoColor[5].toHexString() },
      { hex: tetradColor[1].toHexString() },
      { hex: splitCompColor[1].toHexString() },
      { hex: triadColor[1].toHexString() },
      { hex: splitCompColor[2].toHexString() },
      { hex: triadColor[2].toHexString() },
      { hex: tetradColor[3].toHexString() }
    );
    setRecommendedColors(recommended);
  };

  const colorCheck = (color) => {
    let brightness = color.getBrightness();
    let isDark = color.isDark();
    if (brightness > 200) {
      color.darken(10);
    }
    if (brightness < 85 || (brightness < 115 && isDark === true)) {
      color.lighten(15);
      if (brightness > 100 && isDark === true) {
        color.saturate(2);
      }
    }
    return color;
  };

  const colorChange = (color, colorType) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {
          ...palette.colors,
          [colorType.toLowerCase()]: color.hex.toLowerCase(),
        },
      },
    });
    if (colorType === "Primary") {
      findPrimaryAlt(color.hex.toLowerCase());
    }
  };

  const clearCustomColor = (colorType) => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {
          ...palette.colors,
          [colorType]: "",
        },
      },
    });
    if (colorType === "primary") {
      setRecommendedColors([]);
    }
  };

  const clearCustomColors = () => {
    setItem({
      ...item,
      palette: {
        ...palette,
        colors: {},
      },
    });
    setRecommendedColors([]);
    setPrimaryOn(true);
    setSecondaryOn(false);
    setAccentOn(false);
  };

  const donePalette = () => {
    if (palette && palette.name === "custom") {
      setPrimaryOn(false);
      setSecondaryOn(false);
      setAccentOn(false);
      let paletteUpdate =
        !palette.colors.primary ||
        !palette.colors.secondary ||
        !palette.colors.accent
          ? []
          : { ...palette };
      setItem({ ...item, palette: paletteUpdate }).then(() =>
        doneStep("palette", step)
      );
    } else {
      doneStep("palette", step);
    }
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.palette ? "done" : ""
      } ${
        !palette ||
        !palette.colors ||
        Object.keys(palette.colors).length === 0 ||
        palette.colors.primary === "" ||
        palette.colors.secondary === "" ||
        palette.colors.accent === ""
          ? "none-selected"
          : ""
      }`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.palette[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.palette[label]}</p>}
          />
        </h2>

        <ul
          className={`palette-list cards-list ${palette.name ? "active" : ""} ${
            adjectives.length === 0 ? "no-adjectives" : ""
          } ${palette.name === "custom" ? "custom" : ""} ${
            palette.name === "custom" &&
            palette.colors.primary &&
            palette.colors.secondary &&
            palette.colors.accent
              ? "custom-done"
              : ""
          }`}
        >
          {Object.keys(itemTemplate.sections.paletteList).map((key) => (
            <li
              key={key}
              className={`card ${
                palette.name === itemTemplate.sections.paletteList[key].name
                  ? "selected"
                  : ""
              } ${
                !findPaletteMatch(
                  itemTemplate.sections.paletteList[key].adjectives,
                  adjectives
                )
                  ? "unmatched"
                  : ""
              }`}
            >
              <div
                className="palette"
                onClick={() =>
                  togglePalette(itemTemplate.sections.paletteList[key])
                }
              >
                <div
                  className="color color-primary"
                  style={{
                    backgroundColor:
                      itemTemplate.sections.paletteList[key].colors.primary,
                  }}
                  draggable={true}
                  onDragStart={(event) => {
                    event.dataTransfer.setDragImage(
                      document.getElementById(`color-drag-primary${key}`),
                      33,
                      50
                    );
                    event.dataTransfer.setData(
                      "text",
                      itemTemplate.sections.paletteList[key].colors.primary
                    );
                  }}
                >
                  <span
                    className="color-drag"
                    id={`color-drag-primary${key}`}
                    style={{
                      backgroundColor:
                        itemTemplate.sections.paletteList[key].colors.primary,
                    }}
                  >
                    <i className="icon icon-color-drop" />
                  </span>
                </div>
                <div
                  className="color color-secondary"
                  style={{
                    backgroundColor:
                      itemTemplate.sections.paletteList[key].colors.secondary,
                  }}
                  draggable={true}
                  onDragStart={(event) => {
                    event.dataTransfer.setDragImage(
                      document.getElementById(`color-drag-secondary${key}`),
                      33,
                      50
                    );
                    event.dataTransfer.setData(
                      "text",
                      itemTemplate.sections.paletteList[key].colors.secondary
                    );
                  }}
                >
                  <span
                    className="color-drag"
                    id={`color-drag-secondary${key}`}
                    style={{
                      backgroundColor:
                        itemTemplate.sections.paletteList[key].colors.secondary,
                    }}
                  >
                    <i className="icon icon-color-drop" />
                  </span>
                </div>
                <div
                  className="color color-accent"
                  style={{
                    backgroundColor:
                      itemTemplate.sections.paletteList[key].colors.accent,
                  }}
                  draggable={true}
                  onDragStart={(event) => {
                    event.dataTransfer.setDragImage(
                      document.getElementById(`color-drag-accent${key}`),
                      33,
                      50
                    );
                    event.dataTransfer.setData(
                      "text",
                      itemTemplate.sections.paletteList[key].colors.accent
                    );
                  }}
                >
                  <span
                    className="color-drag"
                    id={`color-drag-accent${key}`}
                    style={{
                      backgroundColor:
                        itemTemplate.sections.paletteList[key].colors.accent,
                    }}
                  >
                    <i className="icon icon-color-drop" />
                  </span>
                </div>
              </div>
              <span
                className="card-edit"
                onClick={() =>
                  editColors(itemTemplate.sections.paletteList[key])
                }
              >
                <i className="icon icon-edit" />
              </span>
            </li>
          ))}
          <p className="instructions">
            (You can drag-and-drop the colors above into the custom boxes below)
          </p>
          <li
            id="custom-palette"
            className={`card card-palette-custom ${
              palette.name === "custom" &&
              palette.colors.primary &&
              palette.colors.secondary &&
              palette.colors.accent
                ? "selected"
                : ""
            } ${primaryOn || secondaryOn || accentOn ? "editing" : ""}`}
          >
            <div
              className={`palette-custom ${
                recommendedColors.length > 0 ? "has-recommended" : ""
              }`}
              onClick={customPalette}
            >
              <div className="palette-custom-icon">
                <i className="icon icon-color-sampler" />
                <span>Custom palette</span>
              </div>
              {!doneList.palette ? (
                <Sticky
                  className="sticky-holder"
                  mode="bottom"
                  boundaryElement=".palette-list"
                  positionRecheckInterval={1}
                  disabled={
                    (palette.colors.primary !== "" &&
                      palette.colors.secondary !== "" &&
                      palette.colors.accent !== "") ||
                    window.innerWidth < 768
                  }
                >
                  <div className="palette">
                    <div
                      className={`color color-primary ${
                        palette.colors.primary ? "selected" : ""
                      } ${primaryOn ? "editing" : ""}`}
                      style={{ backgroundColor: palette.colors.primary }}
                      onDragOver={(event) => {
                        event.preventDefault();
                      }}
                      onDragEnter={(event) => {
                        event.target.style.boxShadow =
                          "0 0.075em 0.5em 0.125em rgba(0,0,0,0.5) inset";
                      }}
                      onDragLeave={(event) => {
                        event.target.style.boxShadow = "";
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                        event.target.style.boxShadow = "";
                        colorChange(
                          { hex: event.dataTransfer.getData("text") },
                          "Primary"
                        );
                      }}
                    >
                      <p>
                        <span className="color-num">1</span>Choose a primary
                        color
                      </p>
                      <span
                        className="color-picker-btn"
                        onClick={colorPrimaryOn}
                      >
                        <i className="icon icon-edit" />
                      </span>
                      <span className="card-clear-wrap">
                        <span
                          className="card-clear"
                          onClick={() => clearCustomColor("primary")}
                        >
                          <i className="icon icon-color-clear" />
                        </span>
                      </span>
                      <div
                        className="color-picker"
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            colorPrimaryOn();
                          }
                        }}
                      >
                        <SketchPicker
                          color={palette.colors.primary}
                          presetColors={customPresetColors}
                          onChangeComplete={(color) =>
                            colorChange(color, "Primary")
                          }
                          disableAlpha={true}
                        />
                      </div>
                    </div>
                    <div
                      className={`color color-secondary ${
                        palette.colors.secondary ? "selected" : ""
                      } ${secondaryOn ? "editing" : ""}`}
                      style={{ backgroundColor: palette.colors.secondary }}
                      onDragOver={(event) => {
                        event.preventDefault();
                      }}
                      onDragEnter={(event) => {
                        event.target.style.boxShadow =
                          "0 0.075em 0.5em 0.125em rgba(0,0,0,0.5) inset";
                      }}
                      onDragLeave={(event) => {
                        event.target.style.boxShadow = "";
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                        event.target.style.boxShadow = "";
                        colorChange(
                          { hex: event.dataTransfer.getData("text") },
                          "Secondary"
                        );
                      }}
                    >
                      <p>
                        <span className="color-num">2</span>Choose a secondary
                        color
                      </p>
                      <span
                        className="color-picker-btn"
                        onClick={colorSecondaryOn}
                      >
                        <i className="icon icon-edit" />
                      </span>
                      <span className="card-clear-wrap">
                        <span
                          className="card-clear"
                          onClick={() => clearCustomColor("secondary")}
                        >
                          <i className="icon icon-color-clear" />
                        </span>
                      </span>
                      <div
                        className="color-picker"
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            colorSecondaryOn();
                          }
                        }}
                      >
                        <SketchPicker
                          color={palette.colors.secondary}
                          presetColors={customPresetColors}
                          onChangeComplete={(color) =>
                            colorChange(color, "Secondary")
                          }
                          disableAlpha={true}
                        />
                        <div className="color-picker-alt">
                          <label>Primary Matches</label>
                          {Object.keys(recommendedColors).map((key) => (
                            <span
                              key={key}
                              onClick={() =>
                                colorChange(recommendedColors[key], "Secondary")
                              }
                              className="color-alt"
                              style={{
                                backgroundColor: recommendedColors[key].hex,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`color color-accent ${
                        palette.colors.accent ? "selected" : ""
                      } ${accentOn ? "editing" : ""}`}
                      style={{ backgroundColor: palette.colors.accent }}
                      onDragOver={(event) => {
                        event.preventDefault();
                      }}
                      onDragEnter={(event) => {
                        event.target.style.boxShadow =
                          "0 0.075em 0.5em 0.125em rgba(0,0,0,0.5) inset";
                      }}
                      onDragLeave={(event) => {
                        event.target.style.boxShadow = "";
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                        event.target.style.boxShadow = "";
                        colorChange(
                          { hex: event.dataTransfer.getData("text") },
                          "Accent"
                        );
                      }}
                    >
                      <p>
                        <span className="color-num">3</span>Choose an accent
                        color
                      </p>
                      <span
                        className="color-picker-btn"
                        onClick={colorAccentOn}
                      >
                        <i className="icon icon-edit" />
                      </span>
                      <span className="card-clear-wrap">
                        <span
                          className="card-clear"
                          onClick={() => clearCustomColor("accent")}
                        >
                          <i className="icon icon-color-clear" />
                        </span>
                      </span>
                      <div
                        className="color-picker"
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            colorAccentOn();
                          }
                        }}
                      >
                        <SketchPicker
                          color={palette.colors.accent}
                          presetColors={customPresetColors}
                          onChangeComplete={(color) =>
                            colorChange(color, "Accent")
                          }
                          disableAlpha={true}
                        />
                        <div className="color-picker-alt">
                          <label>Primary Matches</label>
                          {Object.keys(recommendedColors).map((key) => (
                            <span
                              key={key}
                              onClick={() =>
                                colorChange(recommendedColors[key], "Accent")
                              }
                              className="color-alt"
                              style={{
                                backgroundColor: recommendedColors[key].hex,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Sticky>
              ) : (
                <div className="palette">
                  <div
                    className="color color-primary"
                    style={{ backgroundColor: palette.colors.primary }}
                  />
                  <div
                    className="color color-secondary"
                    style={{ backgroundColor: palette.colors.secondary }}
                  />
                  <div
                    className="color color-accent"
                    style={{ backgroundColor: palette.colors.accent }}
                  />
                </div>
              )}
            </div>
            <span className="card-delete-wrap">
              <span className="card-delete" onClick={clearCustomColors}>
                <i className="icon icon-cross" />
              </span>
            </span>
          </li>
        </ul>

        <p className="section-empty">
          No palette selected, click here to choose one.
        </p>
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={donePalette}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editPalette}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default PaletteList;
