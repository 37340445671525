import React from "react";
import { Popup } from "semantic-ui-react";
import { headerText, helpText } from "./Resources";
import TextareaAutosize from "react-textarea-autosize";

export const Audience = (props) => {
  const {
    label,
    step,
    doneStep,
    item,
    item: { audience, doneList },
    setItem,
    itemTemplate,
  } = props;

  const editAudience = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        audience: false,
      },
    }).then(() => {
      if (!audience.definition || audience.definition === "") {
        document.getElementById("audience-definition-textarea").focus();
      }
    });
  };

  const setAudienceDefinition = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      audience: {
        ...audience,
        definition: event.target.value,
      },
    });
  };

  const toggleAudienceIndustry = (industry) => {
    let industriesUpdate = [...audience.industries];
    let industryOtherUpdate = audience.industryOther;
    const industryIndex = audience.industries.indexOf(industry);
    if (industry === "any industry" && industryIndex === -1) {
      industriesUpdate = [];
      industryOtherUpdate = [];
    } else if (audience.industries.indexOf("any industry") !== -1) {
      industriesUpdate.splice(0, 1);
    }
    if (industryIndex === -1) {
      industriesUpdate.push(industry);
    } else {
      industriesUpdate.splice(industryIndex, 1);
    }
    setItem({
      ...item,
      audience: {
        ...audience,
        industries: industriesUpdate,
        industryOther: industryOtherUpdate,
      },
    });
  };

  const setAudienceIndustryOther = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      audience: {
        ...audience,
        industries:
          audience.industries.indexOf("any industry") !== -1
            ? []
            : [...audience.industries],
        industryOther: event.target.value,
      },
    });
  };

  const clearAudienceIndustryOther = () => {
    setItem({
      ...item,
      audience: {
        ...audience,
        industryOther: [],
      },
    });
  };

  const toggleAudienceAge = (age) => {
    let agesUpdate = [];
    let ageIndex = -1;
    if (audience.ages) {
      agesUpdate = [...audience.ages];
      ageIndex = audience.ages.indexOf(age);
      if (age === "any age" && ageIndex === -1) {
        agesUpdate = [];
      } else if (audience.ages.indexOf("any age") !== -1) {
        agesUpdate.splice(0, 1);
      }
    }
    if (ageIndex === -1) {
      agesUpdate.push(age);
    } else {
      agesUpdate.splice(ageIndex, 1);
    }
    setItem({
      ...item,
      audience: {
        ...audience,
        ages: agesUpdate,
      },
    });
  };

  const toggleAudienceSkill = (skill) => {
    let skillsUpdate = [];
    let skillIndex = -1;
    if (audience.skills) {
      skillsUpdate = [...audience.skills];
      skillIndex = audience.skills.indexOf(skill);
      if (skill === "any skill level" && skillIndex === -1) {
        skillsUpdate = [];
      } else if (audience.skills.indexOf("any skill level") !== -1) {
        skillsUpdate.splice(0, 1);
      }
    }
    if (skillIndex === -1) {
      skillsUpdate.push(skill);
    } else {
      skillsUpdate.splice(skillIndex, 1);
    }
    setItem({
      ...item,
      audience: {
        ...audience,
        skills: skillsUpdate,
      },
    });
  };

  const doneAudience = () => {
    doneStep("audience", step);
    if (item.details && !item.details.problem) {
      setTimeout(() => {
        document.getElementById("details-problem-textarea").focus();
      });
    }
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.audience ? "done" : ""
      } ${
        (!audience.definition || audience.definition === "") &&
        (!audience.industryOther || audience.industryOther.length === 0) &&
        (!audience.industries || audience.industries.length === 0) &&
        (!audience.ages || audience.ages.length === 0) &&
        (!audience.skills || audience.skills.length === 0)
          ? "none-selected"
          : ""
      }`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.audience[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.audience[label]}</p>}
          />
        </h2>
        <div
          className={`audience-section audience-definition ${
            !audience.definition || audience.definition === ""
              ? "empty"
              : "selected"
          }`}
        >
          <label>In a sentence (or two), describe your audience:</label>
          <div className="audience-definition-wrap">
            <TextareaAutosize
              id="audience-definition-textarea"
              minRows={1}
              maxRows={12}
              type="text"
              name="audience-definition"
              style={{ lineHeight: 1.67 }}
              value={audience.definition}
              className="audience-textarea"
              placeholder="Ex: The app is for freelance clients to define design and goals."
              onChange={setAudienceDefinition}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  document
                    .getElementById("audience-definition-textarea")
                    .blur();
                }
              }}
            />
            <span className="card-selected-wrap">
              <span className="card-selected">
                <i className="icon icon-check" />
              </span>
            </span>
          </div>
        </div>
        <div
          className={`audience-section audience-industry ${
            (audience.industries && audience.industries.length > 0) ||
            audience.industryOther.length > 0
              ? ""
              : "empty"
          }`}
        >
          <label>What industry does your audience belong to?</label>
          <ul className="audience-list cards-list">
            {Object.keys(itemTemplate.sections.audience.industries).map(
              (key) => (
                <li
                  key={key}
                  className={`card ${
                    itemTemplate.sections.audience.industries[key]
                  } ${
                    audience.industries &&
                    audience.industries.indexOf(
                      itemTemplate.sections.audience.industries[key]
                    ) !== -1
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    toggleAudienceIndustry(
                      itemTemplate.sections.audience.industries[key]
                    )
                  }
                >
                  <p>{itemTemplate.sections.audience.industries[key]}</p>
                </li>
              )
            )}
            <li
              className={`card other ${
                audience.industryOther && audience.industryOther.length > 0
                  ? "selected"
                  : ""
              }`}
            >
              <input
                type="text"
                id="audience-industry-other-input"
                placeholder="Other..."
                value={audience.industryOther}
                onChange={setAudienceIndustryOther}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document
                      .getElementById("audience-industry-other-input")
                      .blur();
                  }
                }}
              />
              <span
                className="card-delete"
                onClick={clearAudienceIndustryOther}
              >
                <i className="icon icon-cross" />
              </span>
            </li>
          </ul>
        </div>
        <div
          className={`audience-section audience-demographics ${
            !audience.ages || audience.ages.length === 0 ? "empty" : ""
          }`}
        >
          <span className="audience-age">
            <label>What age group is your audience?</label>
            <ul className="audience-list cards-list">
              {Object.keys(itemTemplate.sections.audience.ages).map((key) => (
                <li
                  key={key}
                  className={`card ${
                    itemTemplate.sections.audience.ages[key]
                  } ${
                    audience.ages &&
                    audience.ages.indexOf(
                      itemTemplate.sections.audience.ages[key]
                    ) !== -1
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    toggleAudienceAge(itemTemplate.sections.audience.ages[key])
                  }
                >
                  <p>{itemTemplate.sections.audience.ages[key]}</p>
                </li>
              ))}
            </ul>
          </span>
          <span className="audience-skill">
            <label>What's their level of technical skills?</label>
            <ul className="audience-list cards-list">
              {Object.keys(itemTemplate.sections.audience.skills).map((key) => (
                <li
                  key={key}
                  className={`card ${
                    itemTemplate.sections.audience.skills[key]
                  } ${
                    audience.skills &&
                    audience.skills.indexOf(
                      itemTemplate.sections.audience.skills[key]
                    ) !== -1
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    toggleAudienceSkill(
                      itemTemplate.sections.audience.skills[key]
                    )
                  }
                >
                  <p>{itemTemplate.sections.audience.skills[key]}</p>
                </li>
              ))}
            </ul>
          </span>
        </div>
        <p className="section-empty">
          No audience details, click here to enter them.
        </p>
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneAudience}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editAudience}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default Audience;
