import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactBody from "react-body";
import Parallax from "../shared/Parallax.js";
import { Input } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { ThemeThumb } from "../shared/Themes";
import { ScreenControls } from "../shared/Screens";
import Autocomplete from "react-autocomplete";
import Sticky from "react-sticky-el";
import Scrollspy from "react-scrollspy";
import { SketchPicker } from "react-color";
import {
  customPresetColors,
  replaceSpace,
  itemProject,
  itemStyleguide,
  itemShowcase,
} from "../shared/Resources";
import { Picture } from "react-responsive-picture";
import * as routes from "constants/routes";

export const About = (props) => {
  const audienceInit = {
    description: [],
    age: [],
    skill: [],
  };

  const detailsInt = {
    problem: [],
    category: [],
    files: [],
    uploading: false,
    uploaded: false,
  };

  const publishInt = {
    theme: [],
    button: false,
    published: false,
  };

  const logosInt = {
    files: [],
    uploading: false,
    uploaded: false,
  };

  const paletteInt = {
    colors: {
      primary: false,
      secondary: false,
      accent: false,
    },
    active: "primary",
    picker: "#ffffff",
    indicator: "#ffffff",
  };

  const fontsInt = {
    highlighted: false,
    active: false,
  };

  const fontsFamilies = [
    { family: "Macondo" },
    { family: "Magra" },
    { family: "Merienda" },
    { family: "Merriweather" },
    { family: "Mukta" },
    { family: "Mukta Mahee" },
    { family: "Mukta Malar" },
    { family: "Mulish" },
  ];

  const iconsInt = {
    uploading: false,
    uploaded: false,
  };

  const elementsInt = {
    button: false,
    color: "normal",
  };

  const screensInit = {
    files: [],
    uploading: false,
    uploaded: false,
    screenType: "normal",
  };

  const filesInit = {
    files: [],
    uploading: false,
    uploaded: false,
  };

  const [aboutType, setAboutType] = useState(null);
  const [prevType, setPrevType] = useState(null);
  const [aboutScreen, setAboutScreen] = useState("name");

  const [adjectives, setAdjectives] = useState([]);
  const [palettes, setPalettes] = useState([]);
  const [fontList, setFontList] = useState([]);
  const [images, setImages] = useState([]);
  const [audience, setAudience] = useState(audienceInit);
  const [details, setDetails] = useState(detailsInt);
  const [publish, setPublish] = useState(publishInt);

  const [logos, setLogos] = useState(logosInt);
  const [palette, setPalette] = useState(paletteInt);
  const [fonts, setFonts] = useState(fontsInt);
  const [fontsSearch, setFontsSearch] = useState("");
  const [icons, setIcons] = useState(iconsInt);
  const [elements, setElements] = useState(elementsInt);

  const [screens, setScreens] = useState(screensInit);
  const [files, setFiles] = useState(filesInit);

  const aboutScreenRef = useRef(aboutScreen);
  const timer = useRef();

  useEffect(() => {
    if (window.location.hash) {
      if (window.location.hash === "#projects") {
        setAboutType("projects");
        window.scrollTo(0, 0);
      } else if (window.location.hash === "#styleguides") {
        setAboutType("styleguides");
        window.scrollTo(0, 0);
      } else if (window.location.hash === "#showcases") {
        setAboutType("showcases");
        window.scrollTo(0, 0);
      } else if (window.location.hash === "#collab") {
        const collabElem = document.getElementById("collab");
        if (collabElem) {
          collabElem.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      setAboutType("projects");
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    let footer = document.getElementById("footer");
    if (footer) {
      if (aboutType === "projects") {
        footer.classList = "projects";
      } else if (aboutType === "styleguides") {
        footer.classList = "styleguides";
      } else {
        footer.classList = "showcases";
      }
    }
  }, [aboutType]);

  useEffect(() => {
    aboutScreenRef.current = aboutScreen;
  }, [aboutScreen]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const toggleAboutType = (type) => {
    setPrevType(aboutType);
    setAboutType(type);
    window.location.hash = type;
    window.scrollTo(0, 0);
  };

  const aboutScroll = (update) => {
    // TODO: THIS IS TRIGGERING ONLY on showcases / styleguides ?????????
    clearTimeout(timer.current);
    setAboutScreen(update && update.id ? update.id : "");
    if (update) {
      if (update.id === "adjectives") {
        adjectivesAnimation();
      } else if (update.id === "palettes") {
        palettesAnimation();
      } else if (update.id === "font") {
        fontAnimation();
      } else if (update.id === "images") {
        imagesAnimation();
      } else if (update.id === "audience") {
        audienceAnimation();
      } else if (update.id === "details") {
        detailsAnimation();
      } else if (update.id === "logos") {
        logosAnimation();
      } else if (update.id === "palette") {
        paletteAnimation();
      } else if (update.id === "paletteAlt") {
        paletteAnimationAlt();
      } else if (update.id === "fonts") {
        fontsAnimation();
      } else if (update.id === "icons") {
        iconsAnimation();
      } else if (update.id === "elements") {
        elementsAnimation();
      } else if (update.id === "screens") {
        screensAnimation();
      } else if (update.id === "files") {
        filesAnimation();
      } else if (update.id === "publish") {
        publishAnimation();
      }
    }
  };

  // Project Animations

  const adjectivesAnimation = () => {
    setAdjectives([]);
    timer.current = setTimeout(() => {
      setAdjectives(["clean"]);
      timer.current = setTimeout(() => {
        setAdjectives(["clean", "minimal"]);
        timer.current = setTimeout(() => {
          setAdjectives(["clean", "minimal", "modern"]);
          timer.current = setTimeout(() => {
            if (aboutScreenRef.current === "adjectives") {
              adjectivesAnimation();
            }
          }, 1000);
        }, 500);
      }, 500);
    }, 250);
  };

  const palettesAnimation = () => {
    setPalettes([]);
    timer.current = setTimeout(() => {
      setPalettes([2]);
      timer.current = setTimeout(() => {
        setPalettes([]);
        timer.current = setTimeout(() => {
          setPalettes([1]);
          timer.current = setTimeout(() => {
            setPalettes([]);
            timer.current = setTimeout(() => {
              setPalettes([7]);
              timer.current = setTimeout(() => {
                if (aboutScreenRef.current === "palettes") {
                  palettesAnimation();
                }
              }, 2000);
            }, 500);
          }, 1000);
        }, 500);
      }, 1000);
    }, 500);
  };

  const fontAnimation = () => {
    setFontList([]);
    timer.current = setTimeout(() => {
      setFontList([3]);
      timer.current = setTimeout(() => {
        setFontList([]);
        timer.current = setTimeout(() => {
          setFontList([0]);
          timer.current = setTimeout(() => {
            setFontList([]);
            timer.current = setTimeout(() => {
              setFontList([6]);
              timer.current = setTimeout(() => {
                if (aboutScreenRef.current === "font") {
                  fontAnimation();
                }
              }, 2000);
            }, 350);
          }, 750);
        }, 350);
      }, 750);
    }, 350);
  };

  const imagesAnimation = () => {
    setImages([]);
    timer.current = setTimeout(() => {
      setImages([1]);
      timer.current = setTimeout(() => {
        setImages([1, 2]);
        timer.current = setTimeout(() => {
          setImages([1, 2, 4]);
          timer.current = setTimeout(() => {
            setImages([1, 2, 4, 7]);
            timer.current = setTimeout(() => {
              if (aboutScreenRef.current === "images") {
                imagesAnimation();
              }
            }, 2000);
          }, 500);
        }, 500);
      }, 500);
    }, 250);
  };

  const audienceAnimation = () => {
    setAudience(audienceInit);
    timer.current = setTimeout(() => {
      typeText(audienceArray.description, 33, "item-audience-description").then(
        () => {
          setAudience({
            description: audienceArray.description,
            age: [0],
            skill: [],
          });
          timer.current = setTimeout(() => {
            setAudience({
              description: audienceArray.description,
              age: [0],
              skill: [0],
            });
            timer.current = setTimeout(() => {
              if (aboutScreenRef.current === "audience") {
                audienceAnimation();
              }
            }, 2000);
          }, 500);
        }
      );
    }, 250);
  };

  const detailsAnimation = () => {
    setDetails(detailsInt);
    timer.current = setTimeout(() => {
      typeText(detailsArray.problem, 33, "item-details-problem").then(() => {
        setDetails({
          problem: detailsArray.problem,
          category: [],
          files: [],
          uploading: false,
          uploaded: false,
        });
        timer.current = setTimeout(() => {
          setDetails({
            problem: detailsArray.problem,
            category: [0],
            files: [],
            uploading: false,
            uploaded: false,
          });
          timer.current = setTimeout(() => {
            setDetails({
              problem: detailsArray.problem,
              category: [0],
              files: [],
              uploading: true,
              uploaded: false,
            });
            timer.current = setTimeout(() => {
              setDetails({
                problem: detailsArray.problem,
                category: [0],
                files: [],
                uploading: true,
                uploaded: true,
              });
              timer.current = setTimeout(() => {
                setDetails({
                  problem: detailsArray.problem,
                  category: [0],
                  files: [0],
                  uploading: true,
                  uploaded: true,
                });
                timer.current = setTimeout(() => {
                  setDetails({
                    problem: detailsArray.problem,
                    category: [0],
                    files: [0, 1],
                    uploading: true,
                    uploaded: true,
                  });
                  timer.current = setTimeout(() => {
                    setDetails({
                      problem: detailsArray.problem,
                      category: [0],
                      files: [0, 1, 2],
                      uploading: true,
                      uploaded: true,
                    });
                    timer.current = setTimeout(() => {
                      setDetails({
                        problem: detailsArray.problem,
                        category: [0],
                        files: [0, 1, 2, 3],
                        uploading: true,
                        uploaded: true,
                      });
                      timer.current = setTimeout(() => {
                        if (aboutScreenRef.current === "details") {
                          detailsAnimation();
                        }
                      }, 2000);
                    }, 50);
                  }, 50);
                }, 50);
              }, 250);
            }, 250);
          }, 250);
        }, 250);
      });
    }, 250);
  };

  // Styleguide Animations

  const logosAnimation = () => {
    setLogos(logosInt);
    timer.current = setTimeout(() => {
      setLogos({ files: [], uploading: true, uploaded: false });
      timer.current = setTimeout(() => {
        setLogos({ files: [], uploading: true, uploaded: true });
        timer.current = setTimeout(() => {
          setLogos({ files: [0], uploading: true, uploaded: true });
          timer.current = setTimeout(() => {
            setLogos({ files: [0, 1, 2], uploading: true, uploaded: true });
            timer.current = setTimeout(() => {
              setLogos({
                files: [0, 1, 2, 3],
                uploading: true,
                uploaded: true,
              });
              timer.current = setTimeout(() => {
                if (aboutScreenRef.current === "logos") {
                  logosAnimation();
                }
              }, 2000);
            }, 50);
          }, 50);
        }, 250);
      }, 250);
    }, 500);
  };

  const paletteAnimation = () => {
    setPalette(paletteInt);
    timer.current = setTimeout(() => {
      setPalette({
        colors: { primary: false, secondary: false, accent: false },
        active: "primary",
        picker: "#ffffff",
        indicator: "#ffffff",
      });
      timer.current = setTimeout(() => {
        setPalette({
          colors: { primary: true, secondary: false, accent: false },
          active: "primary",
          picker: "#1FBADB",
          indicator: "#1FBADB",
        });
        timer.current = setTimeout(() => {
          setPalette({
            colors: { primary: true, secondary: false, accent: false },
            active: "secondary",
            picker: "#1FBADB",
            indicator: "#ffffff",
          });
          timer.current = setTimeout(() => {
            setPalette({
              colors: { primary: true, secondary: true, accent: false },
              active: "secondary",
              picker: "#511FDB",
              indicator: "#511FDB",
            });
            timer.current = setTimeout(() => {
              setPalette({
                colors: { primary: true, secondary: true, accent: false },
                active: "accent",
                picker: "#511FDB",
                indicator: "#ffffff",
              });
              timer.current = setTimeout(() => {
                setPalette({
                  colors: { primary: true, secondary: true, accent: true },
                  active: "accent",
                  picker: "#E1367B",
                  indicator: "#E1367B",
                });
                timer.current = setTimeout(() => {
                  if (aboutScreenRef.current === "palette") {
                    paletteAnimation();
                  }
                }, 2000);
              }, 500);
            }, 750);
          }, 500);
        }, 750);
      }, 500);
    }, 250);
  };

  const fontsAnimation = () => {
    setFonts(fontsInt);
    timer.current = setTimeout(() => {
      setFontsSearch("m");
      timer.current = setTimeout(() => {
        setFontsSearch("mu");
        timer.current = setTimeout(() => {
          setFontsSearch("mul");
          timer.current = setTimeout(() => {
            setFontsSearch("muli");
            timer.current = setTimeout(() => {
              setFonts({ highlighted: true, active: false });
              timer.current = setTimeout(() => {
                setFontsSearch("");
                setFonts({ highlighted: true, active: true });
                timer.current = setTimeout(() => {
                  if (aboutScreenRef.current === "fonts") {
                    fontsAnimation();
                  }
                }, 2000);
              }, 150);
            }, 250);
          }, 150);
        }, 250);
      }, 250);
    }, 250);
  };

  const iconsAnimation = () => {
    setIcons(iconsInt);
    timer.current = setTimeout(() => {
      setIcons({ files: false, uploading: true, uploaded: false });
      timer.current = setTimeout(() => {
        setIcons({ files: false, uploading: true, uploaded: true });
        timer.current = setTimeout(() => {
          setIcons({ files: true, uploading: true, uploaded: true });
          timer.current = setTimeout(() => {
            if (aboutScreenRef.current === "icons") {
              iconsAnimation();
            }
          }, 2000);
        }, 500);
      }, 250);
    }, 500);
  };

  const elementsAnimation = () => {
    setElements(elementsInt);
    timer.current = setTimeout(() => {
      setElements({ button: true, color: "normal" });
      timer.current = setTimeout(() => {
        setElements({ button: true, color: "hover" });
        timer.current = setTimeout(() => {
          setElements({ button: true, color: "active" });
          timer.current = setTimeout(() => {
            if (aboutScreenRef.current === "elements") {
              elementsAnimation();
            }
          }, 2000);
        }, 1000);
      }, 1000);
    }, 500);
  };

  // Showcase Animations

  const screensAnimation = () => {
    setScreens(screensInit);
    timer.current = setTimeout(() => {
      setScreens({
        files: [],
        uploading: true,
        uploaded: false,
        screenType: "normal",
      });
      timer.current = setTimeout(() => {
        setScreens({
          files: [],
          uploading: true,
          uploaded: true,
          screenType: "normal",
        });
        timer.current = setTimeout(() => {
          setScreens({
            files: [0],
            uploading: true,
            uploaded: true,
            screenType: "normal",
          });
          timer.current = setTimeout(() => {
            setScreens({
              files: [0],
              uploading: true,
              uploaded: true,
              screenType: "background",
            });
            timer.current = setTimeout(() => {
              setScreens({
                files: [0],
                uploading: true,
                uploaded: true,
                screenType: "desktop",
              });
              timer.current = setTimeout(() => {
                if (aboutScreenRef.current === "screens") {
                  screensAnimation();
                }
              }, 2000);
            }, 666);
          }, 400);
        }, 250);
      }, 250);
    }, 500);
  };

  const paletteAnimationAlt = () => {
    setPalette(paletteInt);
    timer.current = setTimeout(() => {
      setPalette({
        colors: { primary: false, secondary: false, accent: false },
        active: "primary",
        picker: "#ffffff",
        indicator: "#ffffff",
      });
      timer.current = setTimeout(() => {
        setPalette({
          colors: { primary: true, secondary: false, accent: false },
          active: "primary",
          picker: "#E18936",
          indicator: "#E18936",
        });
        timer.current = setTimeout(() => {
          setPalette({
            colors: { primary: true, secondary: false, accent: false },
            active: "secondary",
            picker: "#E18936",
            indicator: "#ffffff",
          });
          timer.current = setTimeout(() => {
            setPalette({
              colors: { primary: true, secondary: true, accent: false },
              active: "secondary",
              picker: "#E1367B",
              indicator: "#E1367B",
            });
            timer.current = setTimeout(() => {
              setPalette({
                colors: { primary: true, secondary: true, accent: false },
                active: "accent",
                picker: "#E1367B",
                indicator: "#ffffff",
              });
              timer.current = setTimeout(() => {
                setPalette({
                  colors: { primary: true, secondary: true, accent: true },
                  active: "accent",
                  picker: "#1FBADB",
                  indicator: "#1FBADB",
                });
                timer.current = setTimeout(() => {
                  if (aboutScreenRef.current === "paletteAlt") {
                    paletteAnimationAlt();
                  }
                }, 2000);
              }, 500);
            }, 750);
          }, 500);
        }, 750);
      }, 500);
    }, 250);
  };

  const filesAnimation = () => {
    setFiles(filesInit);
    timer.current = setTimeout(() => {
      setFiles({ files: [], uploading: true, uploaded: false });
      timer.current = setTimeout(() => {
        setFiles({ files: [], uploading: true, uploaded: true });
        timer.current = setTimeout(() => {
          setFiles({ files: [0], uploading: true, uploaded: true });
          timer.current = setTimeout(() => {
            setFiles({ files: [0, 1], uploading: true, uploaded: true });
            timer.current = setTimeout(() => {
              setFiles({ files: [0, 1, 2], uploading: true, uploaded: true });
              timer.current = setTimeout(() => {
                setFiles({
                  files: [0, 1, 2, 3],
                  uploading: true,
                  uploaded: true,
                });
                timer.current = setTimeout(() => {
                  setFiles({
                    files: [0, 1, 2, 3, 4],
                    uploading: true,
                    uploaded: true,
                  });
                  timer.current = setTimeout(() => {
                    setFiles({
                      files: [0, 1, 2, 3, 4, 5],
                      uploading: true,
                      uploaded: true,
                    });
                    timer.current = setTimeout(() => {
                      if (aboutScreenRef.current === "files") {
                        filesAnimation();
                      }
                    }, 2000);
                  }, 50);
                }, 50);
              }, 50);
            }, 50);
          }, 50);
        }, 250);
      }, 250);
    }, 500);
  };

  // General

  const publishAnimation = () => {
    setPublish(publishInt);
    timer.current = setTimeout(() => {
      setPublish({
        theme: [0],
        button: false,
        published: false,
        scroll: false,
      });
      timer.current = setTimeout(() => {
        setPublish({
          theme: [0],
          button: true,
          published: false,
          scroll: false,
        });
        timer.current = setTimeout(() => {
          setPublish({
            theme: [0],
            button: true,
            published: true,
            scroll: false,
          });
          timer.current = setTimeout(() => {
            setPublish({
              theme: [0],
              button: true,
              published: true,
              scroll: true,
            });
            timer.current = setTimeout(
              () => {
                setPublish({
                  theme: [0],
                  button: true,
                  published: true,
                  scroll: false,
                });
                timer.current = setTimeout(
                  () => {
                    if (aboutScreenRef.current === "publish") {
                      publishAnimation();
                    }
                  },
                  aboutType === "styleguides"
                    ? 5500
                    : aboutType === "showcases"
                    ? 3333
                    : 2500
                );
              },
              aboutType === "styleguides"
                ? 5500
                : aboutType === "showcases"
                ? 3333
                : 2500
            );
          }, 1500);
        }, 300);
      }, 500);
    }, 500);
  };

  const smScreen = window.innerWidth < 768;

  const typeText = (string, duration, target) => {
    let promise = Promise.resolve();
    Object.keys(string).map((char) => {
      return (promise = promise.then(() => {
        document.getElementById(target).value += string[char];
        return new Promise((resolve) => {
          timer.current = setTimeout(resolve, duration);
        });
      }));
    });
    return promise;
  };

  const adjectivesArray = [
    "clean",
    "elegant",
    "bold",
    "colorful",
    "minimal",
    "modern",
    "innovative",
    "unique",
  ];

  const palettesArray = [
    {
      primary: "#5A9CCA",
      secondary: "#83BCD2",
      accent: "#7A56A6",
    },
    {
      primary: "#b89cf0",
      secondary: "#f3a0fd",
      accent: "#95fbd6",
    },
    {
      primary: "#218EC0",
      secondary: "#085273",
      accent: "#CA374B",
    },
    {
      primary: "#1fbadb",
      secondary: "#1f94db",
      accent: "#db401f",
    },
    {
      primary: "#6ed3cf",
      secondary: "#7E6ED3",
      accent: "#e62739",
    },
    {
      primary: "#244E4C",
      secondary: "#389A96",
      accent: "#CD3F36",
    },
    {
      primary: "#d8453a",
      secondary: "#0A355F",
      accent: "#1E924C",
    },
    {
      primary: "#d53c9d",
      secondary: "#3249ec",
      accent: "#2bb4f5",
    },
  ];

  const fontArray = [
    "Abril Fatface",
    "Crimson Text",
    "Dancing Script",
    "Eb Garamond",
    "Josefin Slab",
    "Montserrat",
    "Muli",
    "Open Sans",
    "Raleway",
    "Roboto",
  ];

  const imagesArray = [
    require("../../images/home-1.jpg"),
    require("../../images/home-2.jpg"),
    require("../../images/home-3.jpg"),
    require("../../images/home-4.jpg"),
    require("../../images/home-5.jpg"),
    require("../../images/home-6.jpg"),
    require("../../images/home-7.jpg"),
    require("../../images/home-8.jpg"),
    require("../../images/home-9.jpg"),
  ];

  const audienceArray = {
    description: "Designers, developers, and their clients.",
    age: ["any age", "15 - 34", "35 - 54", "55 and up"],
    skill: [
      "any skill level",
      "somewhat skilled",
      "average skill",
      "advanced skill",
    ],
  };

  const detailsArray = {
    problem: "Make design documentation easy and fun.",
    category: ["brand new", "redesign"],
    files: [
      {
        icon: "brackets",
      },
      {
        icon: "code",
      },
      {
        src: require("../../images/home-1.jpg"),
      },
      {
        src: require("../../images/home-4.jpg"),
      },
    ],
  };

  const logosArray = [
    require("../../images/about/designgapp-logo.png"),
    require("../../images/about/designgapp-logo_dark.png"),
    require("../../images/about/designgapp-emblem.png"),
    require("../../images/about/designgapp-emblem_dark.png"),
  ];

  const iconsArray = [
    "revert",
    "link",
    "credit-card",
    "user-plus",
    "archive",
    "change",
    "question-circle",
    "paper-plane",
    "user",
    "edit",
    "palette",
    "lock",
  ];

  const screensArray = [
    {
      src: require("../../images/designgapp-showcase-2.jpg"),
      screenType: "desktop",
      background: "#D12A5B",
    },
    {
      src: require("../../images/about-screens-2.jpg"),
      screenType: "background",
      background: "#1B2873",
    },
  ];

  const filesArray = [
    {
      icon: "figma",
    },
    {
      icon: "sketch",
    },
    {
      icon: "photoshop",
    },
    {
      src: require("../../images/home-1.jpg"),
    },
    {
      src: require("../../images/home-4.jpg"),
    },
    {
      src: require("../../images/home-2.jpg"),
    },
  ];

  return (
    <div id="about" className={`${aboutType}`}>
      <Helmet>
        <title>DesignGapp - How it works</title>
        <meta name="og:title" content="How it works" />
        <meta name="twitter:title" content="How it works" />
        <meta
          name="description"
          content="Simple, intuitive design documentation for projects and styleguides."
        />
      </Helmet>
      <div
        className={`about-bg projects ${
          aboutType === "projects"
            ? "current"
            : prevType === "projects"
            ? "previous"
            : ""
        }`}
      />
      <div
        className={`about-bg styleguides ${
          aboutType === "styleguides"
            ? "current"
            : prevType === "styleguides"
            ? "previous"
            : ""
        }`}
      />
      <div
        className={`about-bg showcases ${
          aboutType === "showcases"
            ? "current"
            : prevType === "showcases"
            ? "previous"
            : ""
        }`}
      />
      <div className="content-wrap">
        <Parallax speed="3" direction="up" className="about-header">
          <h1>Here's how it works</h1>
          <div className="about-types">
            <div
              className={`about-type projects ${
                aboutType === "projects" ? "active" : ""
              }`}
              onClick={() => toggleAboutType("projects")}
            >
              <ThemeThumb item={itemProject} type="projects" />
              <p>Projects</p>
            </div>
            <div
              className={`about-type styleguides ${
                aboutType === "styleguides" ? "active" : ""
              }`}
              onClick={() => toggleAboutType("styleguides")}
            >
              <ThemeThumb item={itemStyleguide} type="styleguides" />
              <p>Styleguides</p>
            </div>
            <div
              className={`about-type showcases ${
                aboutType === "showcases" ? "active" : ""
              }`}
              onClick={() => toggleAboutType("showcases")}
            >
              <ThemeThumb item={itemShowcase} type="showcases" />
              <p>Showcases</p>
            </div>
          </div>
        </Parallax>
      </div>

      <div className="about-tour">
        <div className="about-tour-bg" />

        {aboutType === "projects" ? (
          <div className="tour-content">
            {smScreen ? (
              <Scrollspy
                onUpdate={aboutScroll}
                items={[
                  "name",
                  "adjectives",
                  "palettes",
                  "font",
                  "images",
                  "audience",
                  "details",
                  "publish",
                ]}
                currentClassName="active"
                offset={-80}
              />
            ) : null}

            <div
              className={`tour-section ${
                aboutScreen === "name" ? "active" : ""
              }`}
              id="name"
            />

            <div
              className={`tour-section ${
                aboutScreen === "adjectives" ? "active" : ""
              }`}
              id="adjectives"
            >
              <h2>
                <span className="tour-num">1.</span>
                Choose adjectives.
              </h2>
              <p>
                Establish the aesthetic tone of your project. These will help
                define the overall design direction.
              </p>
              {smScreen ? (
                <div className="screen-section adjectives">
                  <ProjectAdjectives
                    adjectives={adjectives}
                    adjectivesArray={adjectivesArray}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "palettes" ? "active" : ""
              }`}
              id="palettes"
            >
              <h2>
                <span className="tour-num">2.</span>
                Select a color palette.
              </h2>
              <p>
                Choose a color palette and a font that match the theme of the
                project. We'll recommend complimentary colors.
              </p>
              {smScreen ? (
                <div className="screen-section palettes">
                  <ProjectPalettes
                    palettes={palettes}
                    palettesArray={palettesArray}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "font" ? "active" : ""
              }`}
              id="font"
            >
              <h2>
                <span className="tour-num">3.</span>
                Choose a font.
              </h2>
              <p>
                Choose a font that will match the overall design and theme of
                the project.
              </p>
              {smScreen ? (
                <div className="screen-section font">
                  <ProjectFont fontList={fontList} fontArray={fontArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "images" ? "active" : ""
              }`}
              id="images"
            >
              <h2>
                <span className="tour-num">4.</span>
                Choose imagery.
              </h2>
              <p>
                Select images from the{" "}
                <a
                  href="https://unsplash.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unsplash
                </a>{" "}
                library or upload your own. These images will serve as design
                inspiration.
              </p>
              {smScreen ? (
                <div className="screen-section images">
                  <ProjectImages images={images} imagesArray={imagesArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "audience" ? "active" : ""
              }`}
              id="audience"
            >
              <h2>
                <span className="tour-num">5.</span>
                Define the audience.
              </h2>
              <p>
                Designing for your audience is perhaps the single most important
                factor for a successful project.
              </p>
              {smScreen ? (
                <div className="screen-section audience">
                  <ProjectAudience
                    audience={audience}
                    audienceArray={audienceArray}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "details" ? "active" : ""
              }`}
              id="details"
            >
              <h2>
                <span className="tour-num">6.</span>
                Capture the details.
              </h2>
              <p>
                What problem are you solving and what makes the project
                important? Capture all the details and files.
              </p>
              {smScreen ? (
                <div className="screen-section details">
                  <ProjectDetails
                    details={details}
                    detailsArray={detailsArray}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "publish" ? "active" : ""
              }`}
              id="publish"
            >
              <h2>
                <span className="tour-num">7.</span>
                Choose a theme and publish.
              </h2>
              <p>
                Light or dark mode? Then publish and share with your team,
                stakeholders, or everyone.
                <span className="tour-section-btns">
                  <a
                    href="https://www.designgapp.com/p/ph3ZuxXWO"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-small btn-primary btn-text btn-arrow"
                  >
                    view the project <i className="icon icon-arrow-left" />
                  </a>
                  <Link
                    to={`${routes.Demo}#projects`}
                    className="btn btn-quaternary btn-text btn-arrow btn-small"
                  >
                    try the demo <i className="icon icon-arrow-left" />
                  </Link>
                </span>
              </p>
              {smScreen ? (
                <div
                  className={`screen-section publish active ${
                    publish.published ? "published" : ""
                  }`}
                >
                  <ProjectPublish
                    publish={publish}
                    imagesArray={imagesArray}
                    details={details}
                    detailsArray={detailsArray}
                  />
                </div>
              ) : null}
            </div>

            {!smScreen ? (
              <Sticky
                className="sticky-holder"
                positionRecheckInterval={1}
                topOffset={0}
                bottomOffset={480}
                boundaryElement={".about-tour"}
                hideOnBoundaryHit={false}
              >
                <div
                  className={`tour-screen ${aboutScreen ? aboutScreen : "off"}`}
                >
                  <Scrollspy
                    onUpdate={aboutScroll}
                    items={[
                      "name",
                      "adjectives",
                      "palettes",
                      "font",
                      "images",
                      "audience",
                      "details",
                      "publish",
                    ]}
                    currentClassName="active"
                    offset={-80}
                  >
                    <div className="screen-section name">
                      <ProjectName />
                    </div>
                    <div className="screen-section adjectives">
                      <ProjectAdjectives
                        adjectives={adjectives}
                        adjectivesArray={adjectivesArray}
                      />
                    </div>
                    <div className="screen-section palettes">
                      <ProjectPalettes
                        palettes={palettes}
                        palettesArray={palettesArray}
                      />
                    </div>
                    <div className="screen-section font">
                      <ProjectFont fontList={fontList} fontArray={fontArray} />
                    </div>
                    <div className="screen-section images">
                      <ProjectImages
                        images={images}
                        imagesArray={imagesArray}
                      />
                    </div>
                    <div className="screen-section audience">
                      <ProjectAudience
                        audience={audience}
                        audienceArray={audienceArray}
                      />
                    </div>
                    <div className="screen-section details">
                      <ProjectDetails
                        details={details}
                        detailsArray={detailsArray}
                      />
                    </div>
                    <div
                      className={`screen-section publish ${
                        publish.published ? "published" : ""
                      }`}
                    >
                      <ProjectPublish
                        publish={publish}
                        imagesArray={imagesArray}
                        details={details}
                        detailsArray={detailsArray}
                      />
                    </div>
                  </Scrollspy>
                </div>
              </Sticky>
            ) : null}
          </div>
        ) : aboutType === "styleguides" ? (
          <div className="tour-content">
            {smScreen ? (
              <Scrollspy
                onUpdate={aboutScroll}
                items={[
                  "name",
                  "logos",
                  "palette",
                  "fonts",
                  "images",
                  "icons",
                  "elements",
                  "publish",
                ]}
                currentClassName="active"
                offset={-80}
              />
            ) : null}

            <div
              className={`tour-section ${
                aboutScreen === "name" ? "active" : ""
              }`}
              id="name"
            />

            <div
              className={`tour-section ${
                aboutScreen === "logos" ? "active" : ""
              }`}
              id="logos"
            >
              <h2>
                <span className="tour-num">1.</span>
                Upload your logos.
              </h2>
              <p>
                Include the various versions of your logo as a cornerstone of
                the brand.
              </p>
              {smScreen ? (
                <div className="screen-section logos">
                  <StyleguideLogos logos={logos} logosArray={logosArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "palette" ? "active" : ""
              }`}
              id="palette"
            >
              <h2>
                <span className="tour-num">2.</span>
                Add your color palette.
              </h2>
              <p>
                Choose the palette that matches your brand. We'll recommend
                complimentary colors.
              </p>
              {smScreen ? (
                <div className="screen-section palettes">
                  <StyleguidePalette palette={palette} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "fonts" ? "active" : ""
              }`}
              id="fonts"
            >
              <h2>
                <span className="tour-num">3.</span>
                Add your font(s).
              </h2>
              <p>
                Choose your font(s) that matches your brand. How you display
                text can communicate a lot about the style of the brand.
              </p>
              {smScreen ? (
                <div className="screen-section fonts">
                  <StyleguideFonts
                    fonts={fonts}
                    fontsFamilies={fontsFamilies}
                    fontsSearch={fontsSearch}
                    setFontsSearch={setFontsSearch}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "images" ? "active" : ""
              }`}
              id="images"
            >
              <h2>
                <span className="tour-num">4.</span>
                Choose imagery.
              </h2>
              <p>
                Select images from the{" "}
                <a
                  href="https://unsplash.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unsplash
                </a>{" "}
                library or upload your own. These images will set the tone of
                the visual language.
              </p>
              {smScreen ? (
                <div className="screen-section images">
                  <StyleguideImages images={images} imagesArray={imagesArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "icons" ? "active" : ""
              }`}
              id="icons"
            >
              <h2>
                <span className="tour-num">5.</span>
                Add your icons.
              </h2>
              <p>
                Include the various sets of icons that represent your brand or
                used in products.
              </p>
              {smScreen ? (
                <div className="screen-section icons">
                  <StyleguideIcons icons={icons} iconsArray={iconsArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "elements" ? "active" : ""
              }`}
              id="elements"
            >
              <h2>
                <span className="tour-num">6.</span>
                Design the UI elements.
              </h2>
              <p>
                Design and style the various UI elements (headers, buttons,
                links, etc.) used in your interfaces.
              </p>
              {smScreen ? (
                <div className="screen-section elements item-elements">
                  <StyleguideElements elements={elements} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "publish" ? "active" : ""
              }`}
              id="publish"
            >
              <h2>
                <span className="tour-num">7.</span>
                Choose a theme and publish.
              </h2>
              <p>
                Light mode or dark mode? Then publish and share with your team,
                stakeholders, or the public.
                <span className="tour-section-btns">
                  <a
                    href="https://www.designgapp.com/s/jOo2AZi1p"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-text btn-small btn-arrow"
                  >
                    view the styleguide <i className="icon icon-arrow-left" />
                  </a>
                  <Link
                    to={`${routes.Demo}#styleguides`}
                    className="btn btn-quaternary btn-text btn-arrow btn-small"
                  >
                    try the demo <i className="icon icon-arrow-left" />
                  </Link>
                </span>
              </p>
              {smScreen ? (
                <div
                  className={`screen-section publish active ${
                    publish.published ? "published" : ""
                  }`}
                >
                  <StyleguidePublish
                    publish={publish}
                    imagesArray={imagesArray}
                    logosArray={logosArray}
                    icons={icons}
                    iconsArray={iconsArray}
                  />
                </div>
              ) : null}
            </div>

            {!smScreen ? (
              <Sticky
                className="sticky-holder"
                positionRecheckInterval={1}
                topOffset={-80}
                bottomOffset={480}
                boundaryElement={".about-tour"}
                hideOnBoundaryHit={false}
              >
                <div
                  className={`tour-screen ${aboutScreen ? aboutScreen : "off"}`}
                >
                  <Scrollspy
                    onUpdate={aboutScroll}
                    items={[
                      "name",
                      "logos",
                      "palette",
                      "fonts",
                      "images",
                      "icons",
                      "elements",
                      "publish",
                    ]}
                    currentClassName="active"
                    offset={-80}
                  >
                    <div className="screen-section name">
                      <StyleguideName />
                    </div>
                    <div className="screen-section logos">
                      <StyleguideLogos logos={logos} logosArray={logosArray} />
                    </div>
                    <div className="screen-section palettes">
                      <StyleguidePalette palette={palette} />
                    </div>
                    <div className="screen-section fonts">
                      <StyleguideFonts
                        fonts={fonts}
                        fontsFamilies={fontsFamilies}
                        fontsSearch={fontsSearch}
                        setFontsSearch={setFontsSearch}
                      />
                    </div>
                    <div className="screen-section images">
                      <StyleguideImages
                        images={images}
                        imagesArray={imagesArray}
                      />
                    </div>
                    <div className="screen-section icons">
                      <StyleguideIcons icons={icons} iconsArray={iconsArray} />
                    </div>
                    <div className="screen-section elements item-elements">
                      <StyleguideElements elements={elements} />
                    </div>
                    <div
                      className={`screen-section publish ${
                        publish.published ? "published" : ""
                      }`}
                    >
                      <StyleguidePublish
                        publish={publish}
                        imagesArray={imagesArray}
                        logosArray={logosArray}
                        icons={icons}
                        iconsArray={iconsArray}
                      />
                    </div>
                  </Scrollspy>
                </div>
              </Sticky>
            ) : null}
          </div>
        ) : aboutType === "showcases" ? (
          <div className="tour-content">
            {smScreen ? (
              <Scrollspy
                onUpdate={aboutScroll}
                items={[
                  "name",
                  "screens",
                  "paletteAlt",
                  "fonts",
                  "files",
                  "publish",
                ]}
                currentClassName="active"
                offset={-80}
              />
            ) : null}

            <div
              className={`tour-section ${
                aboutScreen === "name" ? "active" : ""
              }`}
              id="name"
            />

            <div
              className={`tour-section ${
                aboutScreen === "screens" ? "active" : ""
              }`}
              id="screens"
            >
              <h2>
                <span className="tour-num">1.</span>
                Upload your images.
              </h2>
              <p>
                Include your design files and choose a layout that best
                accentuates the aesthetic.
              </p>
              {smScreen ? (
                <div className="screen-section screens">
                  <ShowcaseScreens
                    screens={screens}
                    screensArray={screensArray}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "paletteAlt" ? "active" : ""
              }`}
              id="paletteAlt"
            >
              <h2>
                <span className="tour-num">2.</span>
                Add your color palette.
              </h2>
              <p>
                Choose the palette that matches your brand. We'll recommend
                complimentary colors.
              </p>
              {smScreen ? (
                <div className="screen-section palettes">
                  <ShowcasePalette palette={palette} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "fonts" ? "active" : ""
              }`}
              id="fonts"
            >
              <h2>
                <span className="tour-num">3.</span>
                Add your font(s).
              </h2>
              <p>
                Choose your font(s) that matches your brand. How you display
                text can communicate a lot about the style of the brand.
              </p>
              {smScreen ? (
                <div className="screen-section fonts">
                  <ShowcaseFonts
                    fonts={fonts}
                    fontsFamilies={fontsFamilies}
                    fontsSearch={fontsSearch}
                    setFontsSearch={setFontsSearch}
                  />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "files" ? "active" : ""
              }`}
              id="files"
            >
              <h2>
                <span className="tour-num">4.</span>
                Add your files.
              </h2>
              <p>
                Include any helpful files that might aid in the design or
                development process.
              </p>
              {smScreen ? (
                <div className="screen-section icons">
                  <ShowcaseFiles files={files} filesArray={filesArray} />
                </div>
              ) : null}
            </div>

            <div
              className={`tour-section ${
                aboutScreen === "publish" ? "active" : ""
              }`}
              id="publish"
            >
              <h2>
                <span className="tour-num">5.</span>
                Choose a theme and publish.
              </h2>
              <p>
                Light mode or dark mode? Then publish and share with your team,
                stakeholders, or the public.
                <span className="tour-section-btns">
                  <a
                    href="https://www.designgapp.com/h/wy4k_3Sap"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-text btn-small btn-arrow"
                  >
                    view the showcase <i className="icon icon-arrow-left" />
                  </a>
                  <Link
                    to={`${routes.Demo}#showcases`}
                    className="btn btn-quaternary btn-text btn-arrow btn-small"
                  >
                    try the demo <i className="icon icon-arrow-left" />
                  </Link>
                </span>
              </p>
              {smScreen ? (
                <div
                  className={`screen-section publish active ${
                    publish.published ? "published" : ""
                  }`}
                >
                  <ShowcasePublish
                    publish={publish}
                    screens={screens}
                    screensArray={screensArray}
                    files={files}
                    filesArray={filesArray}
                  />
                </div>
              ) : null}
            </div>

            {!smScreen ? (
              <Sticky
                className="sticky-holder"
                positionRecheckInterval={1}
                topOffset={-80}
                bottomOffset={480}
                boundaryElement={".about-tour"}
                hideOnBoundaryHit={false}
              >
                <div
                  className={`tour-screen ${aboutScreen ? aboutScreen : "off"}`}
                >
                  <Scrollspy
                    onUpdate={aboutScroll}
                    items={[
                      "name",
                      "screens",
                      "paletteAlt",
                      "fonts",
                      "files",
                      "publish",
                    ]}
                    currentClassName="active"
                    offset={-80}
                  >
                    <div className="screen-section name">
                      <ShowcaseName />
                    </div>
                    <div className="screen-section screens">
                      <ShowcaseScreens
                        screens={screens}
                        screensArray={screensArray}
                      />
                    </div>
                    <div className="screen-section palettes">
                      <ShowcasePalette palette={palette} />
                    </div>
                    <div className="screen-section fonts">
                      <ShowcaseFonts
                        fonts={fonts}
                        fontsFamilies={fontsFamilies}
                        fontsSearch={fontsSearch}
                        setFontsSearch={setFontsSearch}
                      />
                    </div>
                    <div className="screen-section files">
                      <ShowcaseFiles files={files} filesArray={filesArray} />
                    </div>
                    <div
                      className={`screen-section publish ${
                        publish.published ? "published" : ""
                      }`}
                    >
                      <ShowcasePublish
                        publish={publish}
                        screens={screens}
                        screensArray={screensArray}
                        files={files}
                        filesArray={filesArray}
                      />
                    </div>
                  </Scrollspy>
                </div>
              </Sticky>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="about-responsive">
        <div className="about-types">
          <div
            className={`about-type projects ${
              aboutType === "projects" ? "active" : ""
            }`}
            onClick={() => toggleAboutType("projects")}
          >
            <ThemeThumb item={itemProject} type="projects" />
            <p>Projects</p>
          </div>
          <div
            className={`about-type styleguides ${
              aboutType === "styleguides" ? "active" : ""
            }`}
            onClick={() => toggleAboutType("styleguides")}
          >
            <ThemeThumb item={itemStyleguide} type="styleguides" />
            <p>Styleguides</p>
          </div>
          <div
            className={`about-type showcases ${
              aboutType === "showcases" ? "active" : ""
            }`}
            onClick={() => toggleAboutType("showcases")}
          >
            <ThemeThumb item={itemShowcase} type="showcases" />
            <p>Showcases</p>
          </div>
        </div>

        <h3>Works great on any device</h3>
        <div className="screens-list">
          <div className="about-responsive-lg screen-desktop">
            <Picture
              className="file-img"
              alt="Responsive design on large screens"
              sources={[
                {
                  srcSet: require(`../../images/about/about-responsive-lg-sm${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.jpg`),
                  media: "(max-width: 768px)",
                },
                {
                  srcSet: require(`../../images/about/about-responsive-lg${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.jpg`),
                },
                {
                  srcSet: require(`../../images/about/about-responsive-lg${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.webp`),
                  type: "image/webp",
                },
              ]}
            />
            <span className="about-img-hover">
              <Picture
                className="file-img"
                alt="Responsive design on large screens"
                sources={[
                  {
                    srcSet: require(`../../images/about/about-responsive-lg-hover-sm${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-4"
                        : ""
                    }.jpg`),
                    media: "(max-width: 768px)",
                  },
                  {
                    srcSet: require(`../../images/about/about-responsive-lg-hover${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-4"
                        : ""
                    }.jpg`),
                  },
                  {
                    srcSet: require(`../../images/about/about-responsive-lg-hover${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-4"
                        : ""
                    }.webp`),
                    type: "image/webp",
                  },
                ]}
              />
            </span>
          </div>
          <div className="about-responsive-sm screen-mobile">
            <Picture
              className="file-img"
              alt="Responsive design on small screens"
              sources={[
                {
                  srcSet: require(`../../images/about/about-responsive-sm-sm${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.jpg`),
                  media: "(max-width: 768px)",
                },
                {
                  srcSet: require(`../../images/about/about-responsive-sm${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.jpg`),
                },
                {
                  srcSet: require(`../../images/about/about-responsive-sm${
                    aboutType === "styleguides"
                      ? "-2"
                      : aboutType === "showcases"
                      ? "-4"
                      : ""
                  }.webp`),
                  type: "image/webp",
                },
              ]}
            />
            <span className="about-img-hover">
              <Picture
                className="file-img"
                alt="Responsive design on small screens"
                sources={[
                  {
                    srcSet: require(`../../images/about/about-responsive-sm-hover-sm${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-3"
                        : ""
                    }.jpg`),
                    media: "(max-width: 768px)",
                  },
                  {
                    srcSet: require(`../../images/about/about-responsive-sm-hover${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-3"
                        : ""
                    }.jpg`),
                  },
                  {
                    srcSet: require(`../../images/about/about-responsive-sm-hover${
                      aboutType === "styleguides"
                        ? "-2"
                        : aboutType === "showcases"
                        ? "-3"
                        : ""
                    }.webp`),
                    type: "image/webp",
                  },
                ]}
              />
            </span>
          </div>
        </div>
        <Link to={`${routes.Demo}#${aboutType}`}>
          <button className="btn btn-quaternary">try the demo</button>
        </Link>
      </div>
      <div className="about-collab" id="collab">
        <div className="about-collab-content">
          <div className="about-collab-text">
            <h2>Include team members and clients.</h2>
            <p>
              Involve your stakeholders, clients, and developers in the design
              process by collaborating on {aboutType}. Document the important
              design decisions together, then share your work to help bring it
              to life.
            </p>
            <a
              className="btn btn-text btn-primary btn-arrow"
              href="https://uxplanet.org/what-the-hell-is-design-documentation-e79d7f73fbd5"
              target="_blank"
              rel="noopener noreferrer"
            >
              why documentation matters <i className="icon icon-arrow-left" />
            </a>
          </div>
          <div className="about-collab-video">
            {aboutType === "projects" ? (
              <video
                autoPlay
                muted
                loop
                playsInline
                key="collab-video-projects"
              >
                <source
                  src={require("../../videos/aboutCollab-projects.mp4")}
                  type="video/mp4"
                />
              </video>
            ) : aboutType === "styleguides" ? (
              <video
                autoPlay
                muted
                loop
                playsInline
                key="collab-video-styleguides"
              >
                <source
                  src={require("../../videos/aboutCollab-styleguides.mp4")}
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                autoPlay
                muted
                loop
                playsInline
                key="collab-video-showcases"
              >
                <source
                  src={require("../../videos/aboutCollab-styleguides.mp4")}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>
      </div>
      <div className="about-signup">
        <h3>
          <span>DesignGapp is FREE</span> and always will be.
        </h3>
        <Link to={routes.Signup}>
          <button className="btn btn-secondary">get started now</button>
        </Link>
      </div>
      <ReactBody className="about" />
    </div>
  );
};

// Project Content

const ProjectName = () => (
  <div className="screen-section-content">
    <Input
      id="item-name-input"
      name="item-name"
      value={"Your design project"}
    />
  </div>
);

const ProjectAdjectives = ({ adjectives, adjectivesArray }) => (
  <div className="screen-section-content">
    <ul className="adjectives-list cards-list">
      {adjectives
        ? adjectivesArray.map((adjective) => (
            <li
              key={adjective}
              className={`card ${adjective} ${
                adjectives.indexOf(adjective) !== -1 ? "selected" : ""
              }`}
            >
              <p>{adjective}</p>
            </li>
          ))
        : null}
    </ul>
  </div>
);

const ProjectPalettes = ({ palettes, palettesArray }) => (
  <div className="screen-section-content">
    <ul
      className={`palette-list cards-list ${
        palettes && palettes.length > 0 ? "active" : ""
      }`}
    >
      {palettes
        ? palettesArray.map((palette, index) => (
            <li
              key={index}
              className={`card ${
                palettes.indexOf(index) !== -1 ? "selected" : ""
              }`}
            >
              <div className="palette">
                <div
                  className="color color-primary"
                  style={{ backgroundColor: palette.primary }}
                />
                <div
                  className="color color-secondary"
                  style={{ backgroundColor: palette.secondary }}
                />
                <div
                  className="color color-accent"
                  style={{ backgroundColor: palette.accent }}
                />
              </div>
            </li>
          ))
        : null}
    </ul>
  </div>
);

const ProjectFont = ({ fontList, fontArray }) => (
  <div className="screen-section-content">
    <ul className="fonts-list cards-list">
      {fontList
        ? fontArray.map((font, index) => (
            <li
              key={index}
              className={`card ${replaceSpace(font)} ${
                fontList.indexOf(index) !== -1 ? "selected" : ""
              }`}
            >
              <p>{font}</p>
            </li>
          ))
        : null}
    </ul>
  </div>
);

const ProjectImages = ({ images, imagesArray }) => (
  <div className="screen-section-content">
    <ul className="images-list cards-list">
      {images
        ? imagesArray.map((image, index) => (
            <li
              key={image}
              className={`card ${
                images.indexOf(index) !== -1 ? "selected" : ""
              }`}
            >
              <div className="image-wrap">
                <div className="file-img-wrap">
                  <div
                    className="file-img-bg progressive done"
                    style={{ backgroundImage: `url('${image}')` }}
                  />
                </div>
              </div>
            </li>
          ))
        : null}
    </ul>
  </div>
);

const ProjectAudience = ({ audience, audienceArray }) => (
  <div className="screen-section-content">
    <div className="screen-input input-audience">
      <label>Describe your audience</label>
      <Input
        id="item-audience-description"
        name="item-audience"
        value={audience.description}
      />
    </div>
    <div className="audience-age">
      <label>Age group?</label>
      <ul className="audience-list cards-list">
        {audience
          ? audienceArray.age.map((age, index) => (
              <li
                key={age}
                className={`card ${
                  audience.age.indexOf(index) !== -1 ? "selected" : ""
                }`}
              >
                {age}
              </li>
            ))
          : null}
      </ul>
    </div>
    <div className="audience-skill">
      <label>Technical skills?</label>
      <ul className="audience-list cards-list">
        {audience
          ? audienceArray.skill.map((skill, index) => (
              <li
                key={skill}
                className={`card ${
                  audience.skill.indexOf(index) !== -1 ? "selected" : ""
                }`}
              >
                {skill}
              </li>
            ))
          : null}
      </ul>
    </div>
  </div>
);

const ProjectDetails = ({ details, detailsArray }) => (
  <div className="screen-section-content">
    <div className="screen-input input-details">
      <label>What problem are you solving?</label>
      <Input
        id="item-details-problem"
        name="item-details"
        value={details.problem}
      />
    </div>
    <div className="details-category">
      <label>What type of project is this?</label>
      {details ? (
        <ul className="details-list cards-list">
          <li
            className={`card ${detailsArray.category[0]} ${
              details.category.indexOf(0) !== -1 ? "selected" : ""
            }`}
          >
            {detailsArray.category[0]}
          </li>
          <li
            className={`card ${detailsArray.category[1]} ${
              details.category.indexOf(1) !== -1 ? "selected" : ""
            }`}
          >
            {detailsArray.category[1]}
          </li>
          <p className="details-category-text">or</p>
        </ul>
      ) : null}
    </div>
    <div className="details-files">
      <label>Attach helpful files</label>
      <div className="item-section item-files">
        <div
          className={`files-dropzone ${details.uploading ? "uploading" : ""}`}
        >
          <h4>Drop your files here or...</h4>
          <div className="btn btn-primary">click to upload</div>
          <span className="files-drop-progress">
            <span
              id="file-drop-progress"
              className={`drop-progress-bar ${
                details.uploaded ? "uploaded" : ""
              }`}
            />
          </span>
        </div>

        <ul className="files-list cards-list dark">
          {details
            ? detailsArray.files.map((file, index) => (
                <li
                  key={index}
                  className={`card ${
                    details.files.indexOf(index) !== -1 ? "selected" : ""
                  }`}
                >
                  <div className="file-content">
                    {file.src ? (
                      <span
                        className="file-img-bg"
                        style={{ backgroundImage: `url('${file.src}')` }}
                      />
                    ) : (
                      <span className="file-icon">
                        <i className={`icon icon-${file.icon}`} />
                      </span>
                    )}
                  </div>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  </div>
);

const ProjectPublish = ({ publish, imagesArray, details, detailsArray }) => (
  <div className="screen-section-content">
    <div id="pub-inner" className="publish-inner">
      <div className="item-theme">
        <label>Choose a theme</label>
        <div className="component-theme-wrap cards-list">
          <div
            className={`component-theme theme-light card ${
              publish.theme.indexOf(0) !== -1 ? "selected" : ""
            }`}
          >
            <ThemeThumb item={itemProject} type="projects" theme="light" />
          </div>
          <div className="component-theme theme-dark card">
            <ThemeThumb item={itemProject} type="projects" theme="dark" />
          </div>
          <button
            className={`btn btn-quaternary ${publish.button ? "active" : ""}`}
          >
            <i className="icon icon-rocket" />
            <span className="publish-text">publish project</span>
          </button>
        </div>
      </div>

      <div
        id="item-pub"
        className={`item-publish project`}
        style={{
          marginTop: publish.scroll
            ? `${
                document.getElementById("pub-inner").clientHeight -
                document.getElementById("item-pub").clientHeight
              }px`
            : "0",
        }}
      >
        <div className="item-header">
          <span
            className="item-image"
            style={{ backgroundImage: `url('${imagesArray[1]}')` }}
          />
          <h3>DesignGapp</h3>
        </div>
        <div className="item-body">
          <div className="publish-section">
            <label>The aesthetics can be described as:</label>
            <div className="adjectives-list">
              <div className="item-adjective clean">
                <span>clean</span>
              </div>
              <div className="item-adjective minimal">
                <span>minimal</span>
              </div>
              <div className="item-adjective modern">
                <span>modern</span>
              </div>
            </div>
          </div>
          <div className="publish-section">
            <div className="item-palette-wrap">
              <div className="item-palette-color primary" />
              <div className="item-palette-color secondary" />
              <div className="item-palette-color accent" />
            </div>
          </div>
          <div className="publish-section">
            <span className="font-wrap">
              <div className="item-publish-font">
                <h3>Mulish</h3>
                <p className="font-paragraph">
                  I wandered lonely as a cloud That floats on high o'er vales
                  and hills, When all at once I saw a crowd, A host, of golden
                  daffodils; Beside the lake, beneath the trees, Fluttering and
                  dancing in the breeze. Continuous as the stars that shine And
                  twinkle on the milky way, They stretched in never-ending line
                  Along the margin of a bay: Ten thousand saw I at a glance,
                  Tossing their heads in sprightly dance.
                </p>
              </div>
            </span>
          </div>
          <div className="publish-section">
            <div className="images-list">
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[1]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[2]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[4]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[7]}')` }}
              />
            </div>
          </div>
          <div className="publish-section">
            <label>You're creating this project for:</label>
            <h5>Designers, developers, and their clients.</h5>
          </div>
          <div className="publish-section">
            <label>What problem are you solving:</label>
            <h5>Make design documentation easy and fun.</h5>
          </div>
          <div className="publish-section">
            <div className="showcase-files">
              <ul className="files-list cards-list dark">
                {details
                  ? detailsArray.files.map((file, index) => (
                      <li key={index} className="card selected">
                        <div className="file-content">
                          {file.src ? (
                            <span
                              className="file-img-bg"
                              style={{ backgroundImage: `url('${file.src}')` }}
                            />
                          ) : (
                            <span className="file-icon">
                              <i className={`icon icon-${file.icon}`} />
                            </span>
                          )}
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Styleguide Content

const StyleguideName = () => (
  <div className="screen-section-content">
    <Input id="item-name-input" name="item-name" value={"Your styleguide"} />
  </div>
);

const StyleguideLogos = ({ logos, logosArray }) => (
  <div className="screen-section-content">
    <div className="item-section item-files ">
      <div className={`files-dropzone ${logos.uploading ? "uploading" : ""}`}>
        <h4>Drop your files here or...</h4>
        <div className="btn btn-primary">click to upload</div>
        <span className="files-drop-progress">
          <span
            id="file-drop-progress"
            className={`drop-progress-bar ${logos.uploaded ? "uploaded" : ""}`}
          />
        </span>
      </div>
      <ul className="files-list cards-list">
        {logos
          ? logosArray.map((logo, index) => (
              <li
                key={index}
                className={`card ${
                  logos.files.indexOf(index) !== -1 ? "selected" : ""
                }`}
              >
                <div className="file-content">
                  <span
                    className="file-img-bg"
                    style={{ backgroundImage: `url('${logo}')` }}
                  />
                </div>
              </li>
            ))
          : null}
      </ul>
    </div>
  </div>
);

const StyleguidePalette = ({ palette }) => (
  <div className="screen-section-content">
    <div className="card card-palette-custom">
      <div className="palette-custom">
        <div className="palette">
          <div
            className={`color color-primary ${
              palette.colors.primary ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">1</span>Choose a primary color
            </p>
          </div>
          <div
            className={`color color-secondary ${
              palette.colors.secondary ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">2</span>Choose a secondary color
            </p>
          </div>
          <div
            className={`color color-accent ${
              palette.colors.accent ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">3</span>Choose an accent color
            </p>
          </div>
        </div>
        <div className={`custom-palette-picker ${palette.active}`}>
          <span
            className="color-indicator"
            style={{ borderTopColor: palette.indicator }}
          />
          <SketchPicker
            color={palette.picker}
            presetColors={customPresetColors}
            disableAlpha={true}
            width={"calc(100% - 1em)"}
          />
        </div>
      </div>
    </div>
  </div>
);

const StyleguideFonts = ({
  fonts,
  fontsFamilies,
  fontsSearch,
  setFontsSearch,
}) => (
  <div className="screen-section-content">
    <div className="fonts-google type-select-wrap">
      <div className="type-select-search">
        <Autocomplete
          getItemValue={(item) => item.family}
          shouldItemRender={(item, value) =>
            item.family.toLowerCase().indexOf(value.toLowerCase()) === 0 &&
            fontsSearch.length > 0
          }
          items={fontsFamilies}
          open={fontsSearch.length > 0}
          inputProps={{ placeholder: "Search for a Font..." }}
          menuStyle={{ top: "auto", left: "auto" }}
          wrapperStyle={{}}
          wrapperProps={{ className: "type-select-autocomplete" }}
          renderItem={(item, isHighlighted) => (
            <div
              className={`fonts-type-family fonts-google-family ${
                fonts.highlighted ? "highlighted" : ""
              }`}
              key={item.family}
            >
              <div className="fonts-google-name fonts-name">{item.family}</div>
            </div>
          )}
          value={fontsSearch}
          onChange={setFontsSearch}
        />
        <div className="type-select-search-icons">
          {fontsSearch.length === 0 ? (
            <i className="icon icon-magnifier" />
          ) : (
            <i className="icon icon-cross" />
          )}
        </div>
      </div>
    </div>
    <ul className="fonts-custom-list cards-list">
      <li className={`card has-variants ${fonts.active ? "selected" : ""}`}>
        <div className="fonts-custom-content Muli variantOn">
          <div className="fonts-custom-name">
            <h3 className="font-name">Mulish</h3>
          </div>
          <div className="font-detail">
            <h4 className="font-aa">Aa</h4>
            <p className="font-example">
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              1234567890(,.;:?!$&amp;*)
            </p>
          </div>
          <div className="font-variants-list active">
            <div className="font-detail active weight-100">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 100
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
            <div className="font-detail active weight-200">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 200
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
            <div className="font-detail active weight-300">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 300
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
);

const StyleguideImages = ({ images, imagesArray }) => (
  <div className="screen-section-content">
    <ul className="images-list cards-list">
      {images
        ? imagesArray.map((image, index) => (
            <li
              key={image}
              className={`card ${
                images.indexOf(index) !== -1 ? "selected" : ""
              }`}
            >
              <div className="image-wrap">
                <div className="file-img-wrap">
                  <div
                    className="file-img-bg progressive done"
                    style={{ backgroundImage: `url('${image}')` }}
                  />
                </div>
              </div>
            </li>
          ))
        : null}
    </ul>
  </div>
);

const StyleguideIcons = ({ icons, iconsArray }) => (
  <div className="screen-section-content">
    <div className="item-section item-files ">
      <div className={`files-dropzone ${icons.uploading ? "uploading" : ""}`}>
        <h4>Drop your files here or...</h4>
        <div className="btn btn-primary">click to upload</div>
        <span className="files-drop-progress">
          <span
            id="file-drop-progress"
            className={`drop-progress-bar ${icons.uploaded ? "uploaded" : ""}`}
          />
        </span>
      </div>

      <ul className="files-list cards-list icons-list">
        <li className={`card ${icons.files ? "selected" : ""}`}>
          <div className="icon-content">
            <div className="icons-grid">
              {icons
                ? iconsArray.map((icon) => (
                    <div className="icons-data" key={icon}>
                      <i className={`icon icon-${icon}`} />
                      <h5>{icon}</h5>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
);

const StyleguideElements = ({ elements }) => (
  <div className="screen-section-content">
    <div id="elements">
      <div className="elements-item">
        <div className="elements-content">
          <div id="blockquote-element" className="element element-blockquote">
            <div className="element-content">
              <div className="text-group">
                <blockquote>
                  <strong>Blockquote: </strong>If you can dream—and not make
                  dreams your master; If you can think—and not make thoughts
                  your aim; If you can meet with Triumph and Disaster And treat
                  those two impostors just the same;
                </blockquote>
              </div>
            </div>
            <div className="card-edit-wrap">
              <span className="card-edit">
                <i className="icon icon-edit" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="elements-item">
        <div className="elements-content">
          <div id="button-element" className="element element-buttons active">
            <div className="element-content">
              <div className="buttons-list">
                <div className="element-button">
                  <div className="btn-group">
                    <div className="btn btn-primary normal">Primary</div>
                    <div className="btn btn-primary hover">Hover</div>
                    <div className="btn btn-primary active">Active</div>
                  </div>
                  <div className="btn-group">
                    <div className="btn btn-secondary normal">Secondary</div>
                    <div className="btn btn-secondary hover">Hover</div>
                    <div className="btn btn-secondary active">Active</div>
                  </div>
                  <div className="btn-group">
                    <div className="btn btn-accent normal">Accent</div>
                    <div className="btn btn-accent hover">Hover</div>
                    <div className="btn btn-accent active">Active</div>
                  </div>
                  <div
                    className={`element-button-edit-wrap ${
                      elements.button ? "active" : ""
                    }`}
                  >
                    <div
                      className={`element-button-edit edit-btn-accent ${elements.color}`}
                    >
                      <div className="edit-item-colors color">
                        <div className="item-palette-picker" />
                        <label>font color</label>
                      </div>
                      <div className="edit-item-colors bg">
                        <div className="item-palette-picker" />
                        <label>bg color</label>
                      </div>
                      <div className="edit-item-colors border">
                        <div className="item-palette-picker" />
                        <label>border color</label>
                      </div>
                      <div className="edit-item-colors shadow">
                        <div className="item-palette-picker" />
                        <label>shadow color</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons-custom">
                <span className="link">
                  <i className="icon icon-plus-circle"></i>add custom button
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const StyleguidePublish = ({
  publish,
  imagesArray,
  logosArray,
  icons,
  iconsArray,
}) => (
  <div className="screen-section-content">
    <div id="pub-inner" className="publish-inner">
      <div className="item-theme">
        <label>Choose a theme</label>
        <div className="component-theme-wrap cards-list">
          <div
            className={`component-theme theme-light card ${
              publish.theme.indexOf(0) !== -1 ? "selected" : ""
            }`}
          >
            <ThemeThumb
              item={itemStyleguide}
              type="styleguides"
              theme="light"
            />
          </div>
          <div className="component-theme theme-dark card">
            <ThemeThumb item={itemStyleguide} type="styleguides" theme="dark" />
          </div>
          <button
            className={`btn btn-quaternary ${publish.button ? "active" : ""}`}
          >
            <i className="icon icon-rocket" />
            <span className="publish-text">publish styleguide</span>
          </button>
        </div>
      </div>
      <div
        id="item-pub"
        className={`item-publish styleguide`}
        style={{
          marginTop: publish.scroll
            ? `${
                document.getElementById("pub-inner").clientHeight -
                document.getElementById("item-pub").clientHeight
              }px`
            : "0",
        }}
      >
        <div className="item-header">
          <img
            src={require("../../images/about/designgapp-logo.png")}
            alt="DesignGapp"
          />
        </div>
        <div className="item-body">
          <div id="palette-header" className="item-palette-header">
            <div className="item-header-color header-palette-primary">
              <span
                className="header-img"
                style={{ backgroundImage: `url('${imagesArray[1]}')` }}
              />
            </div>
            <div className="item-header-color header-palette-secondary">
              <span
                className="header-img"
                style={{ backgroundImage: `url('${imagesArray[2]}')` }}
              />
            </div>
            <div className="item-header-color header-palette-accent">
              <span
                className="header-img"
                style={{ backgroundImage: `url('${imagesArray[4]}')` }}
              />
            </div>
          </div>
          <div className="publish-section">
            <div className="item-logos-wrap">
              <div className="item-logo logo-content dark">
                <img src={logosArray[0]} alt="" />
              </div>
              <div className="item-logo logo-content dark">
                <img src={logosArray[1]} alt="" />
              </div>
              <div className="item-logo logo-content dark">
                <img src={logosArray[2]} alt="" />
              </div>
              <div className="item-logo logo-content dark">
                <img src={logosArray[3]} alt="" />
              </div>
            </div>
          </div>
          <div className="publish-section">
            <div className="item-palette-wrap">
              <div className="item-palette-color primary" />
              <div className="item-palette-color secondary" />
              <div className="item-palette-color accent" />
            </div>
          </div>
          <div className="publish-section">
            <span className="font-wrap">
              <div className="item-publish-font">
                <h3>Mulish</h3>
                <p className="font-paragraph">
                  I wandered lonely as a cloud That floats on high o'er vales
                  and hills, When all at once I saw a crowd, A host, of golden
                  daffodils; Beside the lake, beneath the trees, Fluttering and
                  dancing in the breeze. Continuous as the stars that shine And
                  twinkle on the milky way, They stretched in never-ending line
                  Along the margin of a bay: Ten thousand saw I at a glance,
                  Tossing their heads in sprightly dance.
                </p>
              </div>
            </span>
          </div>
          <div className="publish-section">
            <div className="images-list">
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[1]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[2]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[4]}')` }}
              />
              <div
                className="image"
                style={{ backgroundImage: `url('${imagesArray[7]}')` }}
              />
            </div>
          </div>
          <div className="publish-section">
            <div className="icons-grid">
              {icons
                ? iconsArray.map((icon) => (
                    <div className="icons-data" key={icon}>
                      <i className={`icon icon-${icon}`} />
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className="publish-section">
            <div id="header-element" className="elements-item">
              <div className="elements-content">
                <div className="content-headers">
                  <h1>Header One</h1>
                  <h2>Header Two</h2>
                  <h3>Header Three</h3>
                  <h4>Header Four</h4>
                  <h5>Header Five</h5>
                </div>
              </div>
            </div>
            <div id="blockquote-element" className="elements-item">
              <div className="elements-content">
                <blockquote>
                  <strong>Blockquote: </strong>If you can dream—and not make
                  dreams your master; If you can think—and not make thoughts
                  your aim; If you can meet with Triumph and Disaster And treat
                  those two impostors just the same;
                </blockquote>
              </div>
            </div>
            <div id="button-element" className="elements-item">
              <div className="element-content">
                <div className="buttons-list">
                  <div className="element-button">
                    <div className="btn-group">
                      <div className="btn btn-primary normal">Primary</div>
                      <div className="btn btn-primary hover">Hover</div>
                      <div className="btn btn-primary active">Active</div>
                    </div>
                    <div className="btn-group">
                      <div className="btn btn-secondary normal">Secondary</div>
                      <div className="btn btn-secondary hover">Hover</div>
                      <div className="btn btn-secondary active">Active</div>
                    </div>
                    <div className="btn-group">
                      <div className="btn btn-accent normal">Accent</div>
                      <div className="btn btn-accent hover">Hover</div>
                      <div className="btn btn-accent active">Active</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Showcase Content

const ShowcaseName = () => (
  <div className="screen-section-content">
    <Input id="item-name-input" name="item-name" value={"Your showcase"} />
  </div>
);

const ShowcaseScreens = ({ screens, screensArray }) => (
  <div className="screen-section-content">
    <div className="item-section item-files screens-list">
      <div className={`files-dropzone ${screens.uploading ? "uploading" : ""}`}>
        <h4>Drop your files here or...</h4>
        <div className="btn btn-primary">click to upload</div>
        <span className="files-drop-progress">
          <span
            id="file-drop-progress"
            className={`drop-progress-bar ${
              screens.uploaded ? "uploaded" : ""
            }`}
          />
        </span>
      </div>
      <ul className="files-list cards-list">
        <li
          className={`card screen-${screens.screenType} ${
            screens.files.indexOf(0) !== -1 ? "selected" : ""
          }`}
        >
          <div
            className="file-img"
            style={{
              backgroundColor:
                screens.screenType !== "desktop"
                  ? screensArray[0].background
                  : "black",
            }}
          >
            <img src={screensArray[0].src} alt={screensArray[0].name} />
          </div>
          <ScreenControls
            index={0}
            screen={screensArray[0]}
            screenType={screens.screenType}
            setScreenType={() => null}
          />
        </li>
      </ul>
    </div>
  </div>
);

const ShowcasePalette = ({ palette }) => (
  <div className="screen-section-content">
    <div className="card card-palette-custom">
      <div className="palette-custom">
        <div className="palette">
          <div
            className={`color color-primary ${
              palette.colors.primary ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">1</span>Choose a primary color
            </p>
          </div>
          <div
            className={`color color-secondary ${
              palette.colors.secondary ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">2</span>Choose a secondary color
            </p>
          </div>
          <div
            className={`color color-accent ${
              palette.colors.accent ? "active" : ""
            }`}
          >
            <p>
              <span className="color-num">3</span>Choose an accent color
            </p>
          </div>
        </div>
        <div className={`custom-palette-picker ${palette.active}`}>
          <span
            className="color-indicator"
            style={{ borderTopColor: palette.indicator }}
          />
          <SketchPicker
            color={palette.picker}
            presetColors={customPresetColors}
            disableAlpha={true}
            width={"calc(100% - 1em)"}
          />
        </div>
      </div>
    </div>
  </div>
);

const ShowcaseFonts = ({
  fonts,
  fontsFamilies,
  fontsSearch,
  setFontsSearch,
}) => (
  <div className="screen-section-content">
    <div className="fonts-google type-select-wrap">
      <div className="type-select-search">
        <Autocomplete
          getItemValue={(item) => item.family}
          shouldItemRender={(item, value) =>
            item.family.toLowerCase().indexOf(value.toLowerCase()) === 0 &&
            fontsSearch.length > 0
          }
          items={fontsFamilies}
          open={fontsSearch.length > 0}
          inputProps={{ placeholder: "Search for a Font..." }}
          menuStyle={{ top: "auto", left: "auto" }}
          wrapperStyle={{}}
          wrapperProps={{ className: "type-select-autocomplete" }}
          renderItem={(item, isHighlighted) => (
            <div
              className={`fonts-type-family fonts-google-family ${
                fonts.highlighted ? "highlighted" : ""
              }`}
              key={item.family}
            >
              <div className="fonts-google-name fonts-name">{item.family}</div>
            </div>
          )}
          value={fontsSearch}
          onChange={setFontsSearch}
        />
        <div className="type-select-search-icons">
          {fontsSearch.length === 0 ? (
            <i className="icon icon-magnifier" />
          ) : (
            <i className="icon icon-cross" />
          )}
        </div>
      </div>
    </div>
    <ul className="fonts-custom-list cards-list">
      <li className={`card has-variants ${fonts.active ? "selected" : ""}`}>
        <div className="fonts-custom-content Muli variantOn">
          <div className="fonts-custom-name">
            <h3 className="font-name">Mulish</h3>
          </div>
          <div className="font-detail">
            <h4 className="font-aa">Aa</h4>
            <p className="font-example">
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              1234567890(,.;:?!$&amp;*)
            </p>
          </div>
          <div className="font-variants-list active">
            <div className="font-detail active weight-100">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 100
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
            <div className="font-detail active weight-200">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 200
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
            <div className="font-detail active weight-300">
              <h4 className="font-aa">Aa</h4>
              <div className="font-content">
                <p className="font-example">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  1234567890(,.;:?!$&amp;*)
                </p>
                <label>
                  <span>Weight:</span> 300
                </label>
                <label>
                  <span>Style:</span> normal
                </label>
              </div>
              <div className="font-toggle">
                <i className="icon icon-check"></i>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
);

const ShowcaseFiles = ({ files, filesArray }) => (
  <div className="showcase-files">
    <label>Attach helpful files</label>
    <div className="item-section item-files">
      <div className={`files-dropzone ${files.uploading ? "uploading" : ""}`}>
        <h4>Drop your files here or...</h4>
        <div className="btn btn-primary">click to upload</div>
        <span className="files-drop-progress">
          <span
            id="file-drop-progress"
            className={`drop-progress-bar ${files.uploaded ? "uploaded" : ""}`}
          />
        </span>
      </div>
      <ul className="files-list cards-list dark">
        {files
          ? filesArray.map((file, index) => (
              <li
                key={index}
                className={`card ${
                  files.files.indexOf(index) !== -1 ? "selected" : ""
                }`}
              >
                <div className="file-content">
                  {file.src ? (
                    <span
                      className="file-img-bg"
                      style={{ backgroundImage: `url('${file.src}')` }}
                    />
                  ) : (
                    <span className="file-icon">
                      <i className={`icon icon-${file.icon}`} />
                    </span>
                  )}
                </div>
              </li>
            ))
          : null}
      </ul>
    </div>
  </div>
);

const ShowcasePublish = ({ publish, screensArray, files, filesArray }) => (
  <div className="screen-section-content">
    <div id="pub-inner" className="publish-inner">
      <div className="item-theme">
        <label>Choose a theme</label>
        <div className="component-theme-wrap cards-list">
          <div
            className={`component-theme theme-light card ${
              publish.theme.indexOf(0) !== -1 ? "selected" : ""
            }`}
          >
            <ThemeThumb item={itemShowcase} type="showcases" theme="light" />
          </div>
          <div className="component-theme theme-dark card">
            <ThemeThumb item={itemShowcase} type="showcases" theme="dark" />
          </div>
          <button
            className={`btn btn-quaternary ${publish.button ? "active" : ""}`}
          >
            <i className="icon icon-rocket" />
            <span className="publish-text">publish showcase</span>
          </button>
        </div>
      </div>
      <div
        id="item-pub"
        className={`item-publish showcase`}
        style={{
          marginTop: publish.scroll
            ? `${
                document.getElementById("pub-inner").clientHeight -
                document.getElementById("item-pub").clientHeight
              }px`
            : "0",
        }}
      >
        <div className="item-body">
          <div className="publish-section">
            <div className="screens-list">
              <div className="screen-desktop">
                <div className="file-img">
                  <img src={screensArray[0].src} alt="" />
                </div>
              </div>
              <div className="screen-background">
                <div
                  className="file-img"
                  style={{ backgroundColor: screensArray[1].background }}
                >
                  <img src={screensArray[1].src} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="publish-section">
            <div className="item-palette-wrap">
              <div className="item-palette-color tertiary" />
              <div className="item-palette-color accent" />
              <div className="item-palette-color primary" />
            </div>
          </div>
          <div className="publish-section">
            <span className="font-wrap">
              <div className="item-publish-font">
                <h3>Mulish</h3>
                <p className="font-paragraph">
                  I wandered lonely as a cloud That floats on high o'er vales
                  and hills, When all at once I saw a crowd, A host, of golden
                  daffodils; Beside the lake, beneath the trees, Fluttering and
                  dancing in the breeze. Continuous as the stars that shine And
                  twinkle on the milky way, They stretched in never-ending line
                  Along the margin of a bay: Ten thousand saw I at a glance,
                  Tossing their heads in sprightly dance.
                </p>
              </div>
            </span>
          </div>
          <div className="publish-section">
            <div className="showcase-files item-files">
              <ul className="files-list cards-list dark">
                {files
                  ? filesArray.map((file, index) => (
                      <li key={index} className="card selected">
                        <div className="file-content">
                          {file.src ? (
                            <span
                              className="file-img-bg"
                              style={{ backgroundImage: `url('${file.src}')` }}
                            />
                          ) : (
                            <span className="file-icon">
                              <i className={`icon icon-${file.icon}`} />
                            </span>
                          )}
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;
