import React, { Component } from "react";
import { db, firebase } from "../../firebase";
import { findInitials } from "./Resources";
import { Modal, Input } from "semantic-ui-react";
import * as routes from "constants/routes";
import _debounce from "lodash.debounce";
import validator from "email-validator";

export default class Members extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.debounceUsers = _debounce(this.findUsers, 150);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const { userSearchValue } = this.state;
    if (
      !prevProps.data.hasOwnProperty("users") &&
      data.hasOwnProperty("users")
    ) {
      this.setUsers(data.users);
    }
    if (
      validator.validate(prevState.userSearchValue) &&
      !validator.validate(userSearchValue)
    ) {
      this.setState({
        addNewUser: false,
        userSearchResults: {},
      });
    }
  }

  getUsers = () => {
    const { id, type } = this.props;
    return db.getUserIDs(type, id).then((res) =>
      this.setUsers(res.val()).then(() => {
        return true;
      })
    );
  };

  setUsers = (users) => {
    const { id, type } = this.props;
    return db.getAdmin(type, id).then((snapshot) =>
      db.getUsers(users).then((res) => {
        const currentUsers = [];
        const invitedUsers = [];
        res.forEach((user) => {
          if (user.val()) {
            if (users[user.key] === true && user.key !== snapshot.val()) {
              currentUsers.push({
                admin: false,
                email: user.val().email,
                username: user.val().username,
                key: user.key,
                profileImage: user.val().profileImage,
              });
            } else if (
              users[user.key] === true &&
              user.key === snapshot.val()
            ) {
              currentUsers.unshift({
                admin: true,
                email: user.val().email,
                username: user.val().username,
                key: user.key,
                profileImage: user.val().profileImage,
              });
            } else {
              invitedUsers.push({
                email: user.val().email,
                username: user.val().username,
                key: user.key,
                profileImage: user.val().profileImage,
              });
            }
          }
        });
        this.setState(
          {
            currentUsers: currentUsers,
            invitedUsers: invitedUsers,
            userToRemoveID: null,
            userToRemoveEmail: null,
            userToRemoveName: "",
          },
          () => {
            return this.findInvitedUsers();
          }
        );
      })
    );
  };

  findUsers = () => {
    const { userSearchValue, currentUsers, invitedUsers } = this.state;
    if (userSearchValue !== "") {
      this.setState({ findingUser: true }, () => {
        const searchValue = userSearchValue;
        db.lookupUserByEmail(searchValue.toString().toLowerCase()).then(
          (foundUser) => {
            if (foundUser.val()) {
              let isAdded = false;
              let isInvited = false;
              currentUsers.forEach((user) => {
                if (user.key === Object.keys(foundUser.val())[0]) {
                  isAdded = true;
                }
              });
              invitedUsers.forEach((user) => {
                if (user.key === Object.keys(foundUser.val())[0]) {
                  isInvited = true;
                }
              });
              const userSearchResults = foundUser.val()[
                Object.keys(foundUser.val())[0]
              ]
                ? foundUser.val()[Object.keys(foundUser.val())[0]]
                : {};
              userSearchResults.id = Object.keys(foundUser.val())[0];
              if (isAdded) {
                userSearchResults.code = "added";
              }
              if (isInvited) {
                userSearchResults.code = "invited";
              }
              this.setState({
                userSearchResults: userSearchResults,
                findingUser: false,
              });
            } else {
              this.setState({
                addNewUser: true,
                findingUser: false,
                userSearchResults: {
                  newUserEmail: searchValue.toString().toLowerCase(),
                },
              });
            }
          }
        );
      });
    } else {
      this.setState({
        addNewUser: false,
        userSearchResults: {},
      });
    }
  };

  handleUserSearchChange = (value) => {
    const { addNewUser } = this.state;
    if (addNewUser) {
      this.setState({
        userSearchValue: value,
        addNewUser: false,
      });
    } else {
      this.setState({ userSearchValue: value });
    }
    if (validator.validate(value)) {
      this.debounceUsers();
    }
  };

  selectUser = () => {
    const { userSelected } = this.state;
    if (userSelected) {
      this.setState({ userSelected: false });
    } else {
      this.setState({ userSelected: true });
    }
  };

  inviteUser = () => {
    const { id, type } = this.props;
    const { userSearchResults, addNewUser } = this.state;
    this.setState(
      {
        addingUser: true,
      },
      () => {
        if (userSearchResults.hasOwnProperty("email") && !addNewUser) {
          firebase
            .inviteUser({ id: id, type: type, uid: userSearchResults.id })
            .then(() => {
              this.invitedUserCallback();
            });
        } else if (
          userSearchResults.hasOwnProperty("newUserEmail") &&
          addNewUser
        ) {
          firebase
            .inviteNewUser({
              id: id,
              type: type,
              email: userSearchResults.newUserEmail,
            })
            .then(() => {
              this.invitedNewUserCallback();
            });
        }
      }
    );
  };

  invitedUserCallback = () => {
    const { authUser, data, id, type } = this.props;
    const { userSearchResults } = this.state;
    const item = data.name
      ? data.name
      : `a DesignGapp ${
          type === "styleguides"
            ? "Styleguide"
            : type === "showcases"
            ? "Showcase"
            : "Project"
        }`;
    const itemType =
      type === "styleguides"
        ? "styleguide"
        : type === "showcases"
        ? "showcase"
        : "project";
    const joinUrl = `${routes.ConfirmUser}/${type}/${id}/${userSearchResults.id}`;
    firebase.sendItemInvitation({
      type: itemType,
      name: authUser.displayName,
      url: `https://www.designgapp.com${joinUrl}`,
      email: userSearchResults.email,
      item: item,
    });
    this.setState(
      {
        addNewUser: false,
        addingUser: false,
        userSearchResults: {},
        userSearchValue: "",
      },
      () => {
        this.getUsers();
      }
    );
  };

  invitedNewUserCallback = () => {
    const { authUser, type, id } = this.props;
    const { userSearchResults } = this.state;
    const email = userSearchResults.newUserEmail
      ? userSearchResults.newUserEmail
      : userSearchResults.email;
    firebase.sendJoinAppInvitation({
      name: authUser.displayName,
      email: email,
    });
    // TODO - Figure out how to only send one of these if the user has already been invited
    db.findAllInvitedNewUsers(type, id).then((allInvitedNewUsers) => {
      this.setState({
        allInvitedNewUsers,
        addNewUser: false,
        addingUser: false,
        userSearchResults: {},
        userSearchValue: "",
      });
    });
  };

  removeUser = (userID, userName) => {
    this.setState({
      userToRemoveID: userID,
      userToRemoveName: userName,
    });
  };

  cancelRemoveUser = (event) => {
    event.preventDefault();
    this.setState({
      userToRemoveID: null,
      userToRemoveName: "",
    });
  };

  confirmRemoveUser = (event) => {
    event.preventDefault();
    const { id, type } = this.props;
    const { userToRemoveID } = this.state;
    this.setState(
      {
        removingUser: true,
      },
      () => {
        firebase
          .removeUser({ id: id, type: type, uid: userToRemoveID })
          .then(() => this.returnToPrev());
      }
    );
  };

  returnToPrev = () => {
    const { type, authUser, history, getItems, closeManageUsersModal } =
      this.props;
    const { userToRemoveID } = this.state;
    const routeType =
      type === "styleguides"
        ? routes.Styleguides
        : type === "showcases"
        ? routes.Showcases
        : routes.Projects;
    if (history.location.pathname === routeType) {
      if (getItems) {
        getItems().then(() => {
          this.getUsers().then(() => {
            const removedUser = userToRemoveID;
            this.setState(
              {
                userToRemoveID: null,
                userToRemoveName: "",
                removingUser: false,
              },
              () => {
                if (removedUser === authUser.uid) {
                  closeManageUsersModal(false);
                }
              }
            );
          });
        });
      }
    } else {
      if (userToRemoveID === authUser.uid) {
        history.push(routeType);
      } else {
        this.getUsers().then(() => {
          this.setState({
            userToRemoveID: null,
            userToRemoveName: "",
            removingUser: false,
          });
        });
      }
    }
  };

  findInvitedUsers = () => {
    const { id, type } = this.props;
    db.findAllInvitedNewUsers(type, id).then((allInvitedNewUsers) => {
      this.setState({
        allInvitedNewUsers,
        addNewUser: false,
        userSearchResults: {},
        userSearchValue: "",
      });
    });
  };

  removeUserInvitation = (email) => {
    this.setState({
      userToRemoveEmail: email,
    });
  };

  cancelRemoveUserInvitation = (event) => {
    event.preventDefault();
    this.setState({
      userToRemoveEmail: null,
    });
  };

  confirmRemoveUserInvitation = (event) => {
    event.preventDefault();
    const { id, type } = this.props;
    const { userToRemoveEmail } = this.state;
    const lowercaseEmail = userToRemoveEmail.toLowerCase();
    this.setState(
      {
        removingUser: true,
      },
      () => {
        firebase
          .removeNewUser({ id: id, type: type, email: lowercaseEmail })
          .then(() => {
            this.getUsers().then(() => {
              this.setState({
                removingUser: false,
              });
            });
          });
      }
    );
  };

  toggleManageUsers = (event) => {
    const { manageUsersOpen, closeManageUsersModal } = this.props;
    if (event && event.nativeEvent) {
      event.nativeEvent.stopImmediatePropagation();
    }
    if (manageUsersOpen) {
      this.setState(
        {
          userSearchValue: "",
          userSearchResults: {},
          userSelected: false,
          addNewUser: false,
          addingUser: false,
          changeOwnerOn: false,
          changeOwnerNew: "",
        },
        () => {
          closeManageUsersModal(false);
        }
      );
    } else {
      closeManageUsersModal(true);
    }
  };

  createcurrentUsers = () => {
    const { authUser } = this.props;
    const { changeOwnerOn, changeOwnerNew, currentUsers } = this.state;
    return currentUsers.map((user) => {
      return (
        <UserProfileCard
          key={user.key}
          selectionType={"remove"}
          userSearchResults={user}
          userAction={this.removeUser}
          authUser={authUser}
          currentUsers={currentUsers}
          changeOwner={this.changeOwner}
          changeOwnerOn={changeOwnerOn}
          changeOwnerNew={changeOwnerNew}
          toggleChangeOwner={this.toggleChangeOwner}
        />
      );
    });
  };

  createCurrentInvitedUsers = () => {
    const { authUser } = this.props;
    const { invitedUsers, allInvitedNewUsers } = this.state;
    const invitedUsersList = invitedUsers.map((user) => (
      <UserProfileCard
        key={user.key}
        selectionType={"remove"}
        userSearchResults={user}
        userAction={this.removeUser}
        authUser={authUser}
      />
    ));
    const allInvitedNewUsersList = allInvitedNewUsers.map((user) => (
      <div key={user.email} className="user user-add-email">
        <div className="user-profile no-user">
          <div className="profile-img-wrap">
            <i className="icon icon-user" />
          </div>
        </div>
        <div className="user-info">
          <a
            href={`mailto:${user.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {user.email}
          </a>
        </div>
        <div
          className="user-remove"
          onClick={() => this.removeUserInvitation(user.email)}
        >
          <i className="icon icon-cross-circle" />
        </div>
      </div>
    ));
    return (
      <div className="users-invited">
        {invitedUsersList}
        {allInvitedNewUsersList}
      </div>
    );
  };

  changeOwner = () => {
    this.setState({
      changeOwnerOn: true,
    });
  };

  toggleChangeOwner = (user) => {
    const { changeOwnerNew } = this.state;
    if (user !== changeOwnerNew) {
      this.setState({ changeOwnerNew: user });
    } else {
      this.setState({ changeOwnerNew: "" });
    }
  };

  saveChangeOwner = (event) => {
    event.preventDefault();
    const { authUser, id, type, data } = this.props;
    const { changeOwnerNew } = this.state;
    const item = data.name
      ? data.name
      : `a DesignGapp ${
          type === "styleguides"
            ? "Styleguide"
            : type === "showcases"
            ? "Showcase"
            : "Project"
        }`;
    const routeType =
      type === "styleguides"
        ? routes.Styleguide
        : type === "showcases"
        ? routes.Showcase
        : routes.Project;
    const joinUrl = routeType + "/edit/" + id;
    this.setState(
      {
        changingUser: true,
      },
      () => {
        firebase
          .changeAdmin({ id: id, type: type, uid: changeOwnerNew })
          .then(() => {
            this.setState(
              {
                changeOwnerOn: false,
                changeOwnerNew: "",
                changingUser: false,
              },
              () => {
                this.getUsers();
              }
            );
          });

        db.getUser(changeOwnerNew).then((res) => {
          firebase.sendOwnerChange({
            name: authUser.displayName,
            url: `https://www.designgapp.com${joinUrl}`,
            email: res.val().email,
            item: item,
          });
        });
      }
    );
  };

  cancelChangeOwner = (event) => {
    event.preventDefault();
    this.setState({
      changeOwnerOn: false,
      changeOwnerNew: "",
    });
  };

  render() {
    const { type, authUser, manageUsersOpen } = this.props;

    const {
      userSearchValue,
      userSearchResults,
      addNewUser,
      addingUser,
      changingUser,
      removingUser,
      findingUser,
      changeOwnerOn,
      changeOwnerNew,
      userToRemoveID,
      userToRemoveEmail,
      userToRemoveName,
      allInvitedNewUsers,
      invitedUsers,
    } = this.state;

    const currentUsers = this.createcurrentUsers();
    const currentInvitedUsers = this.createCurrentInvitedUsers();
    return (
      <Modal
        className={`add-user-modal ${changeOwnerOn ? "owner-change" : ""} ${
          userToRemoveID || userToRemoveEmail ? "remove-user" : ""
        } ${removingUser ? "removing-user" : ""}`}
        open={manageUsersOpen}
        onMount={this.getUsers}
        onClose={this.toggleManageUsers}
      >
        <div className="modal-close-wrap">
          <span className="modal-close" onClick={this.toggleManageUsers}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        <Modal.Header>
          {!changeOwnerOn
            ? `${
                type === "styleguides"
                  ? "Styleguide"
                  : type === "showcases"
                  ? "Showcase"
                  : "Project"
              } Members`
            : "Change Owner"}
        </Modal.Header>
        <Modal.Content>
          {!changeOwnerOn ? (
            <h3>
              Members can edit, publish, share, and manage members.
              <br />
              Only{" "}
              {type === "styleguides"
                ? "styleguide"
                : type === "showcases"
                ? "showcase"
                : "project"}{" "}
              owners can delete and archive the{" "}
              {type === "styleguides"
                ? "styleguide"
                : type === "showcases"
                ? "showcase"
                : "project"}
              .
            </h3>
          ) : null}
          <div className="user-search-container" disabled={addingUser}>
            {!changeOwnerOn ? (
              <Input
                type="email"
                id="user-search-input"
                name="user-search"
                value={userSearchValue}
                className="user-search"
                placeholder="Invite user by email..."
                onChange={(event, val) =>
                  this.handleUserSearchChange(val.value)
                }
              />
            ) : null}
            <span
              className={`user-search-results ${
                userSearchResults.hasOwnProperty("email") ? "user-found" : ""
              }`}
            >
              {userSearchResults.hasOwnProperty("email") ? (
                <UserProfileCard
                  selectionType={"add"}
                  userSearchResults={userSearchResults}
                  userAction={this.inviteUser}
                  authUser={authUser}
                  addingUser={addingUser}
                />
              ) : addNewUser && !findingUser ? (
                <div className="user-add" onClick={this.inviteUser}>
                  {!addingUser ? (
                    <i className="icon icon-plus-circle" />
                  ) : (
                    <div className="inline-loading" />
                  )}
                </div>
              ) : findingUser ? (
                <div className="inline-loading" />
              ) : null}
            </span>
          </div>
          <div className="users">
            {!changeOwnerOn ? (
              <h4>
                Current{" "}
                {type === "styleguides"
                  ? "styleguide"
                  : type === "showcases"
                  ? "showcase"
                  : "project"}{" "}
                users
              </h4>
            ) : (
              <h4>
                Select a new{" "}
                {type === "styleguides"
                  ? "styleguide"
                  : type === "showcases"
                  ? "showcase"
                  : "project"}{" "}
                owner
              </h4>
            )}
            {currentUsers}
            {(allInvitedNewUsers.length > 0 && !changeOwnerOn) ||
            (invitedUsers.length > 0 && !changeOwnerOn) ? (
              <div className="users-pending">
                <h4>Pending invitations</h4>
                {currentInvitedUsers}
              </div>
            ) : null}
          </div>
          {changeOwnerOn ? (
            <div className="owner-btns" disabled={changingUser}>
              <button
                className="btn btn-success"
                disabled={changeOwnerNew === ""}
                onClick={this.saveChangeOwner}
              >
                {!changingUser ? (
                  "save new owner"
                ) : (
                  <span className="btn-loading" />
                )}
              </button>
              <button
                className="btn btn-text regressive"
                onClick={this.cancelChangeOwner}
              >
                cancel
              </button>
            </div>
          ) : null}
        </Modal.Content>
        {userToRemoveID || userToRemoveEmail ? (
          <div className="remove-confirm-wrap" disabled={removingUser}>
            <div className="remove-confirm-content">
              <h4>
                Sure you want to remove
                <br />
                {userToRemoveID && userToRemoveID === authUser.uid
                  ? "yourself"
                  : userToRemoveName}{" "}
                {userToRemoveEmail ? userToRemoveEmail : ""} from this{" "}
                {type === "styleguides"
                  ? "styleguide"
                  : type === "showcases"
                  ? "showcase"
                  : "project"}
                ?
              </h4>
              {userToRemoveID ? (
                <span className="remove-confirm-btns">
                  <button
                    className="btn btn-alert"
                    onClick={this.confirmRemoveUser}
                  >
                    {!removingUser ? (
                      "yes, remove"
                    ) : (
                      <span className="btn-loading" />
                    )}
                  </button>
                  <button
                    className="btn btn-text dark"
                    onClick={this.cancelRemoveUser}
                  >
                    cancel
                  </button>
                </span>
              ) : null}
              {userToRemoveEmail ? (
                <span className="remove-confirm-btns">
                  <button
                    className="btn btn-alert"
                    onClick={this.confirmRemoveUserInvitation}
                  >
                    {!removingUser ? (
                      "yes, remove"
                    ) : (
                      <span className="btn-loading" />
                    )}
                  </button>
                  <button
                    className="btn btn-text dark"
                    onClick={this.cancelRemoveUserInvitation}
                  >
                    cancel
                  </button>
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}

const UserProfileCard = ({
  selectionType,
  userSearchResults,
  userAction,
  authUser,
  currentUsers,
  changeOwner,
  changeOwnerOn,
  changeOwnerNew,
  toggleChangeOwner,
  addingUser,
}) => {
  return (
    <div
      key={userSearchResults.username}
      className={`user ${userSearchResults.admin ? "owner" : ""}`}
    >
      <div
        className={`user-profile ${
          userSearchResults.profileImage ? "" : "no-photo"
        }`}
      >
        <div className="profile-img-wrap">
          <p className="profile-initials">
            {findInitials(userSearchResults.username)}
          </p>
          <span
            className="profile-img"
            style={{
              backgroundImage: "url(" + userSearchResults.profileImage + ")",
            }}
          />
        </div>
      </div>
      <div className="user-info">
        <p>
          {userSearchResults.username}
          {userSearchResults.admin ? (
            <span className="user-admin">(Owner)</span>
          ) : null}
        </p>
        <a
          href={`mailto:${userSearchResults.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {userSearchResults.email}
        </a>
      </div>
      {selectionType === "add" && !userSearchResults.code ? (
        <div className="user-add" onClick={userAction} disabled={addingUser}>
          {!addingUser ? (
            <i className="icon icon-plus-circle" />
          ) : (
            <div className="inline-loading" />
          )}
        </div>
      ) : selectionType === "remove" &&
        !userSearchResults.code &&
        !userSearchResults.admin &&
        !changeOwnerOn ? (
        <div
          className="user-remove"
          onClick={() =>
            userAction(userSearchResults.key, userSearchResults.username)
          }
        >
          <i className="icon icon-cross-circle" />
        </div>
      ) : userSearchResults.admin &&
        !changeOwnerOn &&
        userSearchResults.key === authUser.uid &&
        currentUsers.length > 1 ? (
        <div className="owner-change" onClick={changeOwner}>
          <i className="icon icon-change" />
        </div>
      ) : !userSearchResults.admin && changeOwnerOn ? (
        <div
          className={`owner-select ${
            changeOwnerNew === userSearchResults.key ? "selected" : ""
          }`}
          onClick={() => toggleChangeOwner(userSearchResults.key)}
        >
          <i className="icon icon-checkmark-circle" />
        </div>
      ) : userSearchResults.code ? (
        <p className="user-code">Already {userSearchResults.code}</p>
      ) : null}
    </div>
  );
};

const INITIAL_STATE = {
  userSearchValue: "",
  userSearchResults: {},
  addNewUser: false,
  addingUser: false,
  removingUser: false,
  findingUser: false,
  userSelected: false,
  currentUsers: [],
  invitedUsers: [],
  userAdded: false,
  changeOwnerOn: false,
  changeOwnerNew: "",
  changingUser: false,
  allInvitedNewUsers: [],
  userToRemoveID: null,
  userToRemoveEmail: null,
  userToRemoveName: "",
};
