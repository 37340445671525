export const Index = "/";
export const Home = "/home";
export const About = "/about";
export const AboutNew = "/about-new";
export const Terms = "/terms";
export const Privacy = "/privacy";
export const Login = "/login";
export const Signup = "/signup";
export const Verify = "/verify";
export const ConfirmUser = "/confirm-user";
export const Projects = "/projects";
export const Project = "/project";
export const Styleguides = "/styleguides";
export const Styleguide = "/styleguide";
export const Showcases = "/showcases";
export const Showcase = "/showcase";
export const Personas = "/personas";
export const Persona = "/persona";
export const Demo = "/demo";
export const Account = "/account";
export const AccountUpgrade = "/upgrade";
export const AccountBilling = "/billing";
export const Deleted = "/deleted";
export const PasswordForget = "/pass-forget";
export const PasswordChange = "/pass-change";
export const Auth = "/auth";
export const Contact = "/contact";
export const IframeError = "/iframe-error";
export const NotFound = "/404";
