export const membershipData = {
  products: {
    free: {
      id: `${
        process.env.REACT_APP_ENV === "development"
          ? "prod_GfteNMGyB8IQ0E"
          : "prod_GftXZ9vrgeRKIi"
      }`,
      description: "For users who only work on a few active designs.",
      features: ["3 projects", "3 styleguides", "3 showcases"],
    },
    starter: {
      id: `${
        process.env.REACT_APP_ENV === "development"
          ? "prod_D9Q78gn1BbuypY"
          : "prod_EpREJE6HzwMCRz"
      }`,
      description: "For users who need a few more active designs.",
      features: ["6 projects", "6 styleguides", "6 showcases"],
    },
    professional: {
      id: `${
        process.env.REACT_APP_ENV === "development"
          ? "prod_D9Q8pF6rKztUyS"
          : "prod_EpRFC9SAJgcstZ"
      }`,
      description: "For professional users with lots of active designs.",
      features: [
        "Unlimited projects",
        "Unlimited styleguides",
        "Unlimited showcases",
      ],
    },
    team: {
      id: `${
        process.env.REACT_APP_ENV === "development"
          ? "prod_D9QA8lEXB3dvIv"
          : "prod_EpRHOmu1q1zHLn"
      }`,
      description: "For dedicated design teams with lots of active designs.",
      features: [
        "Unlimited projects",
        "Unlimited styleguides",
        "Unlimited showcases",
        "Up to 5 team members",
      ],
    },
  },
  plans: {
    free: {
      id: `${
        process.env.REACT_APP_ENV === "development"
          ? "price_1GpmCuCBBRbtgYivATryHKUl"
          : "price_1Gpm49CBBRbtgYiv2BVAqifV"
      }`,
      price: 0,
    },
    monthly: {
      starter: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "plan_D9Q7NSEBKmL7VF"
            : "plan_EpREMnEqYzsMkw"
        }`,
        price: 5,
      },
      professional: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "price_1GpmE6CBBRbtgYivQI91iAOm"
            : "price_1Gpm9WCBBRbtgYiveLvNfz71"
        }`,
        price: 15,
      },
      team: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "price_1GpmChCBBRbtgYivASgSoakk"
            : "price_1Gpm1dCBBRbtgYivCCQvKyAx"
        }`,
        price: 60,
      },
    },
    yearly: {
      starter: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "plan_D9Q8IoOGP7Uujh"
            : "plan_EpREYFvsEj4Igp"
        }`,
        price: 4,
      },
      professional: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "price_1GpmDpCBBRbtgYivQeY8T3om"
            : "price_1Gpm7HCBBRbtgYivZp92cIWB"
        }`,
        price: 12,
      },
      team: {
        id: `${
          process.env.REACT_APP_ENV === "development"
            ? "price_1GpmCXCBBRbtgYivMpNPgaeG"
            : "price_1Gpm29CBBRbtgYiv5FgU4CzB"
        }`,
        price: 48,
      },
    },
  },
};

export const MembershipPlan = (membership) => {
  return !membership
    ? 3
    : !membership.subscription ||
      membership.status === "deleted" ||
      (membership.status === "cancelled" &&
        membership &&
        membership.subscription &&
        membership.subscription.plan.product === membershipData.plans.free.id)
    ? 3
    : membership &&
      membership.subscription &&
      membership.subscription.plan.product ===
        membershipData.products.starter.id
    ? 6
    : "unlimited";
};

export const MembershipLimit = (length, membership) => {
  return MembershipPlan(membership) !== "unlimited" &&
    length >= MembershipPlan(membership)
    ? true
    : false;
};
