import React from "react";
import { ItemColor, ItemFontStyle, ItemDecoration, ItemCSS } from "../Elements";

export const LinkElement = (props) => {
  const {
    sections,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, palette },
  } = props;

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  return (
    <div
      id="link-element"
      className={`element element-links ${sections.a.active ? "active" : ""} ${
        sections.a.css ? "css" : ""
      } ${elements.a.disabled ? "disabled" : ""}`}
    >
      <div className="element-content" onClick={() => toggleSection("a", true)}>
        <div className="text-group">
          <style>
            {`
              #elements a, #elements .link, .text-content a, .text-content .quill a {
                color: ${findColorVal("a", "color")};
                font-family: ${findFontVal("p", "fontFamily")};
                font-style: ${
                  elements.a && elements.a.fontStyle
                    ? elements.a.fontStyle
                    : "inherit"
                };
                font-weight: ${
                  elements.a && elements.a.fontWeight
                    ? elements.a.fontWeight
                    : "inherit"
                };
                text-decoration: ${
                  elements.a && elements.a.textDecoration
                    ? elements.a.textDecoration
                    : "inherit"
                };
              }
              #elements a:hover, #elements a.hover, #elements .link:hover, #elements .link.hover, .text-content a:hover, .text-content .quill a:hover {
                color: ${findColorVal("aHover", "color")};
                text-decoration: ${
                  elements.aHover && elements.aHover.textDecoration
                    ? elements.aHover.textDecoration
                    : "inherit"
                };
                font-style: ${
                  elements.aHover && elements.aHover.fontStyle
                    ? elements.aHover.fontStyle
                    : "inherit"
                };
                font-weight: ${
                  elements.aHover && elements.aHover.fontWeight
                    ? elements.aHover.fontWeight
                    : "inherit"
                };
              }
              #elements a:active, #elements a.active, #elements .link:active, #elements .link.active, .text-content a:active, .text-content .quill a:active {
                color: ${findColorVal("aActive", "color")};
                text-decoration: ${
                  elements.aActive && elements.aActive.textDecoration
                    ? elements.aActive.textDecoration
                    : "inherit"
                };
                font-style: ${
                  elements.aActive && elements.aActive.fontStyle
                    ? elements.aActive.fontStyle
                    : "inherit"
                };
                font-weight: ${
                  elements.aActive && elements.aActive.fontWeight
                    ? elements.aActive.fontWeight
                    : "inherit"
                };
              }
            `}
          </style>
          <p
            style={{
              color: findColorVal("p", "color"),
              fontFamily: findFontVal("p", "fontFamily"),
              fontSize: elements.p.fontSize,
              fontStyle: elements.p.fontStyle,
              fontWeight: elements.p.fontWeight,
              lineHeight: elements.p.lineHeight,
            }}
          >
            This is how a <span className="link">link will appear</span> and
            this is a <span className="link hover">hovered link</span> and an{" "}
            <span className="link active">active link</span>.
          </p>
        </div>
      </div>
      {sections.a.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Link styles and color</label>

            <div className="edit-item-sub">
              <ItemFontStyle item={"a"} {...fontStyleProps} />

              <ItemColor
                type="color"
                item="a"
                label="font color"
                {...colorProps}
              />

              <ItemDecoration item="a" {...defaultProps} />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Hovered link styles and color</label>

            <div className="edit-item-sub">
              <ItemFontStyle item={"aHover"} {...fontStyleProps} />

              <ItemColor
                type="color"
                item="aHover"
                label="font color"
                {...colorProps}
              />

              <ItemDecoration item="aHover" {...defaultProps} />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Active link styles and color</label>

            <div className="edit-item-sub">
              <ItemFontStyle item={"aActive"} {...fontStyleProps} />

              <ItemColor
                type="color"
                item="aActive"
                label="font color"
                {...colorProps}
              />

              <ItemDecoration item="aActive" {...defaultProps} />
            </div>
          </div>
        </div>
      ) : null}
      {sections.a.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("a")}>
          <span>{sections.a.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.a.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.a}
              label={`a`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.aHover}
              label={`a:hover`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.aActive}
              label={`a:active`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("a")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
