import { db } from "./firebase";
import {
  ref,
  child,
  get,
  query,
  set,
  push,
  update,
  orderByChild,
  orderByKey,
  equalTo,
  onValue,
} from "firebase/database";
import shortid from "shortid";

// User Functions

export const createUser = (userID, username, email, verified, profileImage) =>
  set(ref(db, `users/${userID}`), {
    username: username,
    email: email,
    lowercase_email: email.toLowerCase(),
    verified: verified,
    profileImage: profileImage,
  });

export const getUser = (userID) => get(ref(db, `users/${userID}`));

export const getUsers = (userIDs) => {
  const promises = [];
  Object.keys(userIDs).forEach((key) => {
    promises.push(get(ref(db, `users/${key}`)));
  });
  return Promise.all(promises);
};

export const lookupUserByEmail = (email) =>
  get(
    query(ref(db, "users/"), orderByChild("lowercase_email"), equalTo(email))
  );

export const setUpdateData = (type, id, userID) => {
  const updateData = {};
  updateData.id = id;
  updateData.type = type;
  updateData.time = new Date().getTime();
  return update(ref(db, `users/${userID}`), {
    updateData: updateData,
  });
};

// Account Functions+

export const saveName = (userID, username) =>
  update(ref(db, `users/${userID}`), {
    username: username,
  });

export const saveProfileImage = (userID, profileImage) =>
  update(ref(db, `users/${userID}`), {
    profileImage: profileImage,
  });

// Item Functions

export const createItem = (type, userID, callback, version) => {
  const newItemKey = push(child(ref(db), type)).key;
  const newItemData = {
    version: version,
    admin: userID,
    created: new Date().getTime(),
    updated: new Date().getTime(),
    updatedBy: userID,
    users: {
      [userID]: true,
    },
  };
  set(ref(db, `${type}/${newItemKey}`), newItemData)
    .then(() => set(ref(db, `users/${userID}/${type}/${newItemKey}`), true))
    .then(() => callback(newItemKey))
    .catch((error) => {
      console.log(error);
      callback(false);
    });
};

export const duplicateItem = (type, id, userID, callback) => {
  const dupItemKey = push(child(ref(db), type)).key;
  getItem(type, id)
    .then((snapshot) => {
      const dupItem = snapshot.val();
      dupItem.name = `${snapshot.val().name} COPY`;
      dupItem.created = new Date().getTime();
      dupItem.updated = new Date().getTime();
      dupItem.updatedBy = userID;
      dupItem.admin = userID;
      dupItem.users = { [userID]: true };
      dupItem.share = { active: false, id: shortid.generate() };
      return dupItem;
    })
    .then((dupItem) => set(ref(db, `${type}/${dupItemKey}`), dupItem))
    .then(() => set(ref(db, `users/${userID}/${type}/${dupItemKey}`), true))
    .then(() => callback(dupItemKey))
    .catch((error) => {
      console.log(error);
      callback(false);
    });
};

export const getItem = (type, id) => get(ref(db, `${type}/${id}`));

export const getItemActive = (type, id) => get(ref(db, `${type}/${id}/active`));

export const getItemShare = (type, id) => get(ref(db, `${type}/${id}/share`));

export const saveItem = (type, id, data) =>
  update(ref(db, `${type}/${id}`), data);

export const saveContent = (type, id, data) =>
  update(ref(db, `${type}/${id}/content`), data);

export const findItemID = (type, shareID) =>
  get(query(ref(db, type), orderByChild("share/id"), equalTo(shareID)));

export const getUserIDs = (type, id) => get(ref(db, `${type}/${id}/users`));

export const getAdmin = (type, id) => get(ref(db, `${type}/${id}/admin`));

export const getUserItems = (type, userID) =>
  get(ref(db, `users/${userID}/${type}`)).then((snapshot) => {
    if (JSON.stringify(snapshot) === "null") {
      return null;
    } else {
      return Promise.all(
        Object.keys(snapshot.val()).map((id) => get(ref(db, `${type}/${id}`)))
      );
    }
  });

export const findAllInvitedNewUsers = (type, id) =>
  new Promise((resolve, reject) => {
    get(query(ref(db, `invites`), orderByKey())).then((snapshot) => {
      const allInvitedNewUsers = [];
      snapshot.forEach((childSnapshot) => {
        if (childSnapshot.val()[type] && childSnapshot.val()[type][id]) {
          allInvitedNewUsers.push(childSnapshot.val());
        }
      });
      resolve(allInvitedNewUsers);
    });
  });

export const getInvitedNewUser = (userKey) =>
  get(ref(db, `invites/${userKey}`));

export const findInvitedNewUser = (userEmail) =>
  get(
    query(
      ref(db, "invites"),
      orderByChild("email"),
      equalTo(userEmail.toLowerCase())
    )
  );

export const getSort = (type, userID) =>
  get(ref(db, `users/${userID}/${type}Sort`));

export const setSort = (type, userID, sort) =>
  set(ref(db, `users/${userID}/${type}Sort`), sort);

export const getCols = (type, userID) =>
  get(ref(db, `users/${userID}/${type}Cols`));

export const setCols = (type, userID, cols) =>
  set(ref(db, `users/${userID}/${type}Cols`), cols);

export const archiveItem = (type, id, userID) =>
  get(ref(db, `${type}/${id}/admin`)).then((res) => {
    if (res.val() === userID) {
      const timestamp = new Date().getTime();
      set(ref(db, `${type}/${id}/archived`), timestamp);
      return timestamp;
    }
  });

export const unarchiveItem = (type, id, userID) =>
  get(ref(db, `${type}/${id}/admin`)).then((res) => {
    if (res.val() === userID) {
      set(ref(db, `${type}/${id}/archived`), false);
    }
  });

export const saveItemSettings = (type, id, data) =>
  update(ref(db, `${type}/${id}/settings`), data);

export const saveItemTheme = (type, id, theme) =>
  set(ref(db, `${type}/${id}/theme`), theme);

// Stripe Functions

export const getCustomerID = (userID) =>
  get(ref(db, `stripe/${userID}/customer_id`));

export const getUserMembership = (userID) =>
  get(ref(db, `stripe/${userID}/membership`));

export const watchStripeStatusChange = (userID, callback) =>
  onValue(ref(db, `stripe/${userID}/membership/status`), (snapshot) =>
    callback(snapshot.val())
  );

export const getStripeError = (userID) =>
  get(ref(db, `stripe/${userID}/membership/error`));

export const getTeamMembers = (userID) =>
  get(ref(db, `stripe/${userID}/membership/team_members`));
