import React, { useState, useRef } from "react";
import { db, storage } from "../../firebase";
import TextContent from "../shared/TextContent";
import ProgressiveImage from "react-progressive-image";
import Dropzone from "react-dropzone";
import { Popup, Confirm, Menu, Dropdown } from "semantic-ui-react";
import {
  isTouch,
  useMountEffect,
  headerText,
  helpText,
  convertFileSize,
  findFileType,
  findFileUsage,
} from "./Resources";
import { SketchPicker } from "react-color";
import Sticky from "react-sticky-el";
import arrayMove from "array-move";
import tinycolor from "tinycolor2";
import getColors from "image-pal-canvas/lib/hsluv";
import _cloneDeep from "lodash.clonedeep";

export const PersonaAI = (props) => {
  const {
    authUser,
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { personaai, doneList },
    setItem,
    saveItem,
    killSteps,
    demo,
  } = props;

  const {
    GoogleGenerativeAI,
    HarmCategory,
    HarmBlockThreshold,
  } = require("@google/generative-ai");

  const MODEL_NAME = "gemini-pro";
  const API_KEY = "AIzaSyDc5p4x1Wv3LT27tyc8NzutK32PvNJDJjU";

  async function run() {
    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: MODEL_NAME });

    const generationConfig = {
      temperature: 0.9,
      topK: 1,
      topP: 1,
      maxOutputTokens: 2048,
    };

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_LOW_AND_ABOVE,
      },
    ];

    const parts = [
      {
        text: "Create a user persona based on a background of teaching and they are 24",
      },
    ];

    const result = await model.generateContent({
      contents: [{ role: "user", parts }],
      generationConfig,
      safetySettings,
    });

    const response = result.response;
    console.log(response.text());
  }

  run();

  return (
    <div className="component-section-wrap">
      <div className="component-section-inner"></div>
    </div>
  );
};

export default PersonaAI;
