export const IconDesign = ({ colors }) =>
  <svg version="1.1" id="icon-design" x="0px" y="0px" viewBox="0 0 155 155" height="155" width="155">
    <g>
      <circle style={{ fill: colors.colorDisplay.primary }} cx="77.5" cy="77.5" r="77.5"/>
      <g>
        <rect x="82.9" y="77.5" style={{ fill: '#FFFFFF' }} width="48" height="51.1"/>
        <rect x="87.6" y="82.3" style={{ fill: colors.colorVars.primary }} width="38" height="41.5"/>
      </g>
      <g>
        <rect x="90.5" y="60.9" style={{ fill: '#FFFFFF' }} width="51.1" height="51.1"/>
        <rect x="95.3" y="65.7" style={{ fill: colors.colorVars.accent }} width="41.5" height="41.5"/>
      </g>
      <g>
        <rect x="13.5" y="97.9" style={{ fill: '#FFFFFF' }} width="51.1" height="51.1"/>
        <rect x="18.3" y="102.7" style={{ fill: colors.colorVars.secondary }} width="41.5" height="41.5"/>
      </g>
      <g>
        <g>
          <g>
            <polygon style={{ fill: '#FFFFFF' }} points="120,32.4 120,137.6 35,137.6 35,17.4 105,17.4"/>
            <polygon style={{ fill: '#dddddd' }} points="120,32.4 105,32.4 105,17.4"/>
          </g>
          <g>
            <path style={{ fill: colors.colorVars.secondaryLighter }} d="M94,77.5C94,86.6,86.6,94,77.5,94C68.4,94,61,86.6,61,77.5C61,68.4,68.4,61,77.5,61C86.6,61,94,68.4,94,77.5z"/>
            <g>
              <g>
                <path style={{ fill: '#353F49' }} d="M77.5,105.5c-14.4,0-26.7-11.2-27.9-25.6l0-0.5l1-0.1l0,0.5c1.2,13.8,13,24.6,26.9,24.6c13.9,0,25.7-10.8,26.9-24.6l0.1-0.5l1,0.1l0,0.5C104.2,94.3,91.9,105.5,77.5,105.5z"/>
                <path style={{ fill: '#353F49' }} d="M104.4,75.7l-0.1-0.5c-1.2-13.8-13-24.6-26.9-24.6c-13.9,0-25.7,10.8-26.9,24.6l0,0.5l-1-0.1l0-0.5c1.2-14.3,13.5-25.6,27.9-25.6c14.4,0,26.7,11.2,27.9,25.6l0,0.5L104.4,75.7z"/>
                <path style={{ fill: '#353F49' }} d="M102.6,75.1v4.8h4.8v-4.8H102.6z M106.4,78.9h-2.7v-2.7h2.7V78.9z"/>
                <path style={{ fill: '#353F49' }} d="M47.6,75.1v4.8h4.8v-4.8H47.6z M51.4,78.9h-2.7v-2.7h2.7V78.9z"/>
              </g>
              <g>
                <rect x="75.1" y="47.6" style={{ fill: '#353F49' }} width="4.8" height="4.8"/>
                <rect x="50" y="49.5" style={{ fill: '#353F49' }} width="55" height="1"/>
                <path style={{ fill: '#353F49' }} d="M52.4,50c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C51.3,47.6,52.4,48.7,52.4,50z"/>
                <path style={{ fill: '#353F49' }} d="M107.4,50c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C106.3,47.6,107.4,48.7,107.4,50z"/>
              </g>
            </g>
          </g>
          <g>
            <polygon style={{ fill: '#FFFFFF' }} points="81.1,89.2 77.5,77.5 73.9,89.2"/>
            <polygon style={{ fill: '#dddddd' }} points="77.5,77.5 81.1,89.2 78.8,89.2"/>
            <rect x="73.9" y="89.2" style={{ fill: '#474F59' }} width="7.1" height="48.4"/>
            <rect x="78.8" y="89.2" style={{ fill: '#232E38' }} width="2.2" height="48.4"/>
            <polygon style={{ fill: '#474F59' }} points="78.9,82.1 77.5,77.5 76.1,82.1"/>
            <polygon style={{ fill: '#353F49' }} points="78.9,82.1 77.5,77.5 78,82.1"/>
            <rect x="73.9" y="137.6" style={{ fill: '#FFFFFF' }} width="7.1" height="4.1"/>
            <rect x="78.8" y="137.6" style={{ fill: '#CCCBCA' }} width="2.2" height="4.1"/>
            <path style={{ fill: colors.colorVars.accent }} d="M81.1,144c0,0.9-0.4,1.8-1,2.5c-0.7,0.7-1.6,1-2.5,1l0,0c-0.9,0-1.8-0.3-2.5-1c-0.7-0.7-1-1.6-1-2.5v-2.2h7.1V144z"/>
            <path style={{ fill: colors.colorVars.accentDarker }} d="M78.8,141.7c0,0.9-0.4,1.8-1,2.5c-0.7,0.7-1.6,1-2.5,1c-0.4,0-0.8-0.1-1.1-0.2c0.2,0.5,0.4,1,0.8,1.4c0.7,0.7,1.6,1,2.5,1c0.9,0,1.8-0.3,2.5-1c0.7-0.7,1-1.6,1-2.5v-2.2H78.8z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>

export default IconDesign;