import React, { Component } from "react";

export default class Parallax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: props.speed ? props.speed : 4,
      direction: props.direction,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.calcTranslation);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.calcTranslation);
  }
  calcTranslation = () => {
    const { disabled } = this.props;
    const { speed, direction } = this.state;
    if (!disabled) {
      var translateValue = window.scrollY / speed;
      if (translateValue < 0) {
        translateValue = 0;
      }
      if (direction === "up") {
        var translateUp = "translate3d(0px," + translateValue + "px, 0px)";
        this.refs.root.style.transform = translateUp;
      }
      if (direction === "down") {
        var translateDown = "translate3d(0px,-" + translateValue + "px, 0px)";
        this.refs.root.style.transform = translateDown;
      }
      if (direction === "left") {
        var translateLeft = "translate3d(-" + translateValue + "px, 0px, 0px)";
        this.refs.root.style.transform = translateLeft;
      }
      if (direction === "right") {
        var translateRight = "translate3d(" + translateValue + "px, 0px, 0px)";
        this.refs.root.style.transform = translateRight;
      }
    } else {
      this.refs.root.style.transform = "none";
    }
  };
  render = () => {
    const { className } = this.props;
    return React.createElement(
      "span",
      Object.assign({}, this.props, {
        ref: "root",
        className: `parallax ${className}`,
      })
    );
  };
}
