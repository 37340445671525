export const IconProblem = ({ colors }) => 
  <svg version="1.1" id="icon-problem" x="0px" y="0px" viewBox="0 0 155 155" height="155" width="155">
    <g>
      <circle style={{ fill: colors.colorDisplay.primary }} cx="77.5" cy="77.5" r="77.5"/>
      <g>
        <g>
          <g>
            <path style={{ fill: '#FFFFFF' }} d="M11.5,29.4v70.8c0,5.8,4.7,10.5,10.5,10.5h111c5.8,0,10.5-4.7,10.5-10.5V29.4H11.5z"/>
            <path style={{ fill: '#CCCBCA' }} d="M22,19v70.8c-5.8,0-10.5,4.7-10.5,10.5V29.4C11.5,23.7,16.2,19,22,19z"/>
            <path style={{ fill: '#CCCBCA' }} d="M133,19v70.8c5.8,0,10.5,4.7,10.5,10.5V29.4C143.5,23.7,138.8,19,133,19z"/>
          </g>
          <g>
            <g>
              <rect x="30.4" y="97.2" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="92.2" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="87.3" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="82.3" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="77.4" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="72.4" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="67.4" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="62.5" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="57.5" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="52.5" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="47.6" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="30.4" y="42.6" style={{ fill: '#F2F2F2' }} width="94.3" height="0.3"/>
              <rect x="114.5" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="119.5" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="109.6" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="104.6" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="99.7" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="94.7" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="89.7" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="84.8" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="79.8" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="74.8" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="69.9" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="64.9" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="60" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="55" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="50" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="45.1" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="40.1" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
              <rect x="35.2" y="37.8" style={{ fill: '#F2F2F2' }} width="0.3" height="64.5"/>
            </g>
            <path style={{ fill: '#F2F2F2' }} d="M125.6,103.3H29.4V36.8h96.3V103.3z M31.3,101.3h92.3V38.8H31.3V101.3z"/>
            <g>
              <polygon style={{ fill: '#F2F2F2' }} points="126.6,38.8 123.6,38.8 123.6,35.8 125.6,35.8 125.6,36.8 126.6,36.8"/>
              <polygon style={{ fill: '#F2F2F2' }} points="125.6,104.3 123.6,104.3 123.6,101.3 126.6,101.3 126.6,103.3 125.6,103.3"/>
              <polygon style={{ fill: '#F2F2F2' }} points="31.3,38.8 28.4,38.8 28.4,36.8 29.4,36.8 29.4,35.8 31.3,35.8"/>
              <polygon style={{ fill: '#F2F2F2' }} points="31.3,104.3 29.4,104.3 29.4,103.3 28.4,103.3 28.4,101.3 31.3,101.3"/>
            </g>
          </g>
          <g>
            <path style={{ fill: colors.colorVars.accent }} d="M80,93.4h-5l-1-4.8c-1-0.2-1.9-0.4-2.8-0.7L68,91.6l-4.4-2.5l1.6-4.7c-0.7-0.6-1.4-1.3-2-2l-4.7,1.6
              L56,79.6l3.7-3.2c-0.3-0.9-0.6-1.8-0.7-2.8l-4.8-1v-5l4.8-1c0.2-1,0.4-1.9,0.7-2.8L56,60.6l2.5-4.3l4.6,1.6c0.6-0.7,1.3-1.4,2-2
              l-1.6-4.6l4.4-2.5l3.2,3.7c0.9-0.3,1.8-0.6,2.8-0.7l1-4.8h5l1,4.8c1,0.2,1.9,0.4,2.8,0.7l3.2-3.7l4.4,2.5l-1.6,4.6
              c0.7,0.6,1.4,1.3,2,2l4.7-1.6l2.5,4.3l-3.7,3.2c0.3,0.9,0.6,1.8,0.7,2.8l4.8,1v5l-4.8,1c-0.2,1-0.4,1.9-0.7,2.8l3.7,3.2
              l-2.5,4.3l-4.7-1.6c-0.6,0.7-1.3,1.4-2,2l1.6,4.6L87,91.6l-3.2-3.7c-0.9,0.3-1.8,0.6-2.8,0.7L80,93.4z M76.6,91.5h1.8l0.9-4.6
              l0.7-0.1c1.3-0.2,2.5-0.5,3.7-1l0.6-0.3l3.1,3.5l1.5-0.9l-1.5-4.4l0.5-0.4c1-0.8,1.9-1.7,2.7-2.7l0.4-0.5l4.4,1.5l0.9-1.5
              L93,76.9l0.3-0.6c0.5-1.2,0.8-2.4,1-3.7l0.1-0.7l4.6-0.9v-1.8l-4.6-0.9l-0.1-0.7c-0.2-1.3-0.5-2.5-1-3.7L93,63.2l3.5-3.1
              l-0.9-1.5l-4.4,1.5l-0.4-0.5c-0.8-1-1.7-1.9-2.7-2.7l-0.5-0.4L89,52l-1.5-0.9l-3.1,3.5l-0.6-0.3c-1.2-0.5-2.4-0.8-3.7-1
              l-0.7-0.1l-0.9-4.6h-1.8l-0.9,4.6L75,53.4c-1.3,0.2-2.5,0.5-3.7,1l-0.6,0.3l-3.1-3.5L66,52l1.5,4.4L67,56.9
              c-1,0.8-1.9,1.7-2.7,2.7l-0.4,0.5l-4.4-1.5l-0.9,1.5l3.5,3.1l-0.3,0.6c-0.5,1.2-0.8,2.4-1,3.7l-0.1,0.7l-4.6,0.9V71l4.6,0.9
              l0.1,0.7c0.2,1.3,0.5,2.5,1,3.7l0.3,0.6L58.5,80l0.9,1.5l4.4-1.5l0.4,0.5c0.8,1,1.7,1.9,2.7,2.7l0.5,0.4L66,88.2l1.5,0.9
              l3.1-3.5l0.6,0.3c1.2,0.5,2.4,0.8,3.7,1l0.7,0.1L76.6,91.5z"/>
            <path style={{ fill: colors.colorVars.accent }} d="M77.5,83.5c-7.4,0-13.4-6-13.4-13.4c0-7.4,6-13.4,13.4-13.4c7.4,0,13.4,6,13.4,13.4
              C90.9,77.5,84.9,83.5,77.5,83.5z M77.5,58.7c-6.3,0-11.4,5.1-11.4,11.4c0,6.3,5.1,11.4,11.4,11.4c6.3,0,11.4-5.1,11.4-11.4
              C88.9,63.8,83.8,58.7,77.5,58.7z"/>
            <path style={{ fill: colors.colorVars.accent }} d="M77.5,73.6c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5c1.9,0,3.5,1.6,3.5,3.5C81,72,79.4,73.6,77.5,73.6
              z M77.5,68.6c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C79,69.3,78.3,68.6,77.5,68.6z"/>
            <path style={{ fill: colors.colorVars.accent }} d="M77.7,98.4h-0.1c-1,0-2-0.1-3.1-0.2l0.2-2c0.9,0.1,1.9,0.2,2.8,0.2h0.1V98.4z M80.7,98.2l-0.2-2
              c0.9-0.1,1.9-0.3,2.8-0.5l0.4,1.9C82.7,97.9,81.7,98.1,80.7,98.2z M71.5,97.7c-1-0.2-2-0.5-3-0.8l0.6-1.9
              c0.9,0.3,1.8,0.6,2.7,0.8L71.5,97.7z M86.7,96.8L86,95c0.9-0.3,1.8-0.7,2.6-1.1l0.9,1.8C88.6,96.1,87.6,96.5,86.7,96.8z
               M65.7,95.8c-0.9-0.4-1.8-0.9-2.7-1.4l1-1.7c0.8,0.5,1.7,0.9,2.5,1.3L65.7,95.8z M92.2,94.2l-1-1.7c0.8-0.5,1.6-1,2.3-1.6
              l1.2,1.6C93.9,93.1,93.1,93.7,92.2,94.2z M60.4,92.6c-0.8-0.6-1.6-1.3-2.3-2l1.4-1.4c0.7,0.6,1.4,1.3,2.2,1.8L60.4,92.6z
               M97,90.5l-1.4-1.4c0.7-0.7,1.3-1.4,1.9-2.1l1.5,1.3C98.5,89.1,97.8,89.8,97,90.5z M56,88.4c-0.7-0.8-1.3-1.6-1.9-2.4l1.6-1.1
              c0.5,0.8,1.1,1.5,1.7,2.3L56,88.4z M101,85.8l-1.6-1.1c0.5-0.8,1-1.6,1.5-2.4l1.8,0.9C102.1,84.1,101.5,85,101,85.8z M52.5,83.4
              c-0.5-0.9-0.9-1.8-1.3-2.8l1.8-0.7c0.3,0.9,0.8,1.7,1.2,2.6L52.5,83.4z M103.8,80.4l-1.8-0.7c0.3-0.9,0.6-1.8,0.9-2.7l1.9,0.5
              C104.5,78.5,104.2,79.5,103.8,80.4z M50.3,77.7c-0.3-1-0.5-2-0.7-3l2-0.3c0.2,0.9,0.4,1.9,0.6,2.8L50.3,77.7z M105.4,74.5
              l-2-0.3c0.1-0.9,0.3-1.9,0.3-2.8l2,0.1C105.7,72.5,105.6,73.5,105.4,74.5z M49.3,71.7c0-0.5,0-1.1,0-1.6c0-0.5,0-1,0-1.4l2,0.1
              c0,0.4,0,0.9,0,1.3c0,0.5,0,1,0,1.5L49.3,71.7z M103.8,68.5c0-0.9-0.2-1.9-0.3-2.8l2-0.3c0.2,1,0.3,2,0.3,3L103.8,68.5z
               M51.5,65.9l-2-0.3c0.2-1,0.4-2,0.6-3l1.9,0.5C51.9,64,51.7,65,51.5,65.9z M102.8,62.9c-0.3-0.9-0.6-1.8-0.9-2.7l1.8-0.7
              c0.4,0.9,0.7,1.9,1,2.9L102.8,62.9z M53,60.4l-1.8-0.7c0.4-1,0.8-1.9,1.3-2.8l1.8,0.9C53.8,58.7,53.4,59.5,53,60.4z M100.7,57.7
              c-0.4-0.8-0.9-1.6-1.5-2.4l1.6-1.1c0.6,0.8,1.1,1.7,1.6,2.6L100.7,57.7z M55.7,55.4L54,54.3c0.6-0.8,1.2-1.7,1.8-2.4l1.5,1.3
              C56.8,53.8,56.2,54.6,55.7,55.4z M97.5,53c-0.6-0.7-1.3-1.4-2-2.1l1.4-1.4c0.7,0.7,1.4,1.4,2.1,2.2L97.5,53z M59.4,51L58,49.6
              c0.7-0.7,1.5-1.4,2.3-2l1.2,1.6C60.8,49.8,60.1,50.4,59.4,51z M93.3,49.1c-0.8-0.6-1.5-1.1-2.4-1.6l1-1.7
              c0.9,0.5,1.7,1.1,2.5,1.7L93.3,49.1z M63.9,47.6l-1-1.7c0.9-0.5,1.8-1,2.7-1.4l0.8,1.8C65.5,46.6,64.7,47.1,63.9,47.6z
               M88.5,46.2c-0.9-0.4-1.7-0.7-2.6-1l0.6-1.9c1,0.3,1.9,0.7,2.8,1.1L88.5,46.2z M69,45.2l-0.6-1.9c1-0.3,2-0.6,3-0.8l0.4,1.9
              C70.8,44.6,69.9,44.9,69,45.2z M83.1,44.4c-0.9-0.2-1.9-0.3-2.8-0.4l0.2-2c1,0.1,2,0.3,3,0.5L83.1,44.4z M74.6,43.9l-0.2-2
              c1-0.1,2-0.2,3.1-0.2l0,2C76.5,43.8,75.5,43.8,74.6,43.9z"/>
          </g>
        </g>
        <g>
          <polygon style={{ fill: '#FFFFFF' }} points="29.1,23.1 27.1,29.4 33.6,29.4 31.6,23.1"/>
          <polygon style={{ fill: '#CCCBCA' }} points="30.8,23.1 31.6,29.4 33.6,29.4 31.6,23.1"/>
          <rect x="27.1" y="29.4" style={{ fill: colors.colorVars.secondary }} width="6.4" height="65.5"/>
          <rect x="31.6" y="29.4" style={{ fill: colors.colorVars.secondaryDarker }} width="2" height="65.5"/>
          <polygon style={{ fill: '#656A73' }} points="31.6,23.1 30.4,19 29.1,23.1"/>
          <polygon style={{ fill: '#232E38' }} points="31.6,23.1 30.4,19 30.8,23.1"/>
          <rect x="27.1" y="94.9" style={{ fill: '#F2F2F2' }} width="6.4" height="2.5"/>
          <rect x="31.6" y="94.9" style={{ fill: '#CCCBCA' }} width="2" height="2.5"/>
          <path style={{ fill: colors.colorVars.accent }} d="M33.6,101.1c0,0.8-0.3,1.6-0.9,2.3c-0.6,0.6-1.5,0.9-2.3,0.9l0,0c-0.8,0-1.6-0.3-2.3-0.9c-0.6-0.6-0.9-1.5-0.9-2.3v-3.7h6.4V101.1z"/>
          <path style={{ fill: colors.colorVars.accentDarker }} d="M31.6,97.4v1.7c0,0.8-0.3,1.7-0.9,2.3c-0.6,0.6-1.5,0.9-2.3,0.9c-0.3,0-0.7-0.1-1-0.2c0.1,0.5,0.4,0.9,0.8,1.3c0.6,0.6,1.5,0.9,2.3,0.9c0.8,0,1.7-0.3,2.3-0.9c0.6-0.6,0.9-1.5,0.9-2.3v-3.7H31.6z"/>
        </g>
        <g>
          <path style={{ fill: colors.colorVars.accent }} d="M11.5,119.2V133h132v-13.9H11.5z M135.3,129.6c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C137.3,128.7,136.4,129.6,135.3,129.6z"/>
          <g>
            <rect x="140.6" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="137.7" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="134.8" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="131.9" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="129" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="126.2" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="123.3" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="120.4" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="117.5" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="114.6" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="111.7" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="108.8" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="105.9" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="103" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="100.1" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="97.2" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="94.3" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="91.5" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="88.6" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="85.7" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="82.8" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="79.9" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="77" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="74.1" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="71.2" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="68.3" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="65.4" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="62.5" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="59.7" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="56.8" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="53.9" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="51" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="48.1" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="45.2" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="42.3" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="39.4" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="36.5" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="33.6" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="30.7" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="27.9" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="25" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="22.1" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="19.2" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="16.3" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
            <rect x="13.4" y="119.2" style={{ fill: '#FFFFFF' }} width="1" height="3"/>
          </g>
          <rect x="11.5" y="133" style={{ fill: colors.colorVars.accentDarker }} width="132" height="3"/>
        </g>
      </g>
    </g>
  </svg>

export default IconProblem;