import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  inlineCSS,
  inFrame,
  spaceToEmpty,
  findNormalFontWeight,
  findNormalFontStyle,
  findTextColor,
  findInitials,
  isBrendan,
} from "../shared/Resources";
import * as routes from "constants/routes";
import LoadingSpinner from "../shared/Icons/LoadingSpinner";
import TextAccessibility from "../shared/TextAccessibility";
import { FontsDisplay } from "../shared/Fonts";
import { PaletteDisplay } from "../shared/PaletteDisplay";
import { FileDisplay } from "../shared/FilesList";
import { ItemSettings } from "../shared/ItemSettings";
import IconAudience from "../shared/Icons/IconAudience";
import IconProblem from "../shared/Icons/IconProblem";
import IconDesign from "../shared/Icons/IconDesign";
import Parallax from "../shared/Parallax.js";
import { Dropdown, Menu, Popup } from "semantic-ui-react";
import ProgressiveImage from "react-progressive-image";
import moment from "moment";
// import { AddToCalendarButton } from "add-to-calendar-button-react";
import Masonry from "react-masonry-component";
import ReactBody from "react-body";

export const ProjectPublished = (props) => {
  const {
    history,
    authUser,
    item,
    itemID,
    itemTemplate,
    itemColors,
    itemIsReady,
    loadItem,
    hideSpinner,
    updateSettings,
    saveTheme,
    manageItemUsers,
    openShareItemModal,
  } = props;

  const [drawerActive, setDrawerActive] = useState(false);
  const [itemLoadError, setItemLoadError] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (item.users && !users) {
      let itemUsers = [];
      let usersList = Object.keys(item.users).map((user) => {
        return new Promise((resolve) => {
          db.getUser(user).then((snapshot) => {
            itemUsers[user] = snapshot.val();
            resolve();
          });
        });
      });
      Promise.all(usersList).then(() => setUsers(itemUsers));
    }
  }, [item.users, users]);

  return (
    <section
      id="project"
      className={`content-outer ${itemIsReady ? "loaded" : ""} theme-${
        item.theme ? item.theme : "light"
      } ${item.settings && !item.settings.icons ? "no-icons" : ""}`}
    >
      {item.doneList && item.doneList.publish ? (
        <div className="item-outer">
          <Helmet>
            <title>{item.name ? item.name : "DesignGapp"}</title>
            <meta
              name="og:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="twitter:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="description"
              content={`DesignGapp Project: ${
                item.name ? item.name : "Untitled"
              }`}
            />
          </Helmet>

          {item.settings && item.settings.css ? (
            <style>{inlineCSS(item.settings.css, "#project")}</style>
          ) : null}

          {authUser &&
          (Object.keys(item.users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan()) &&
          !inFrame() ? (
            <nav className="item-publish-nav">
              <button
                className="btn btn-text item-back-btn"
                onClick={() => history.push(routes.Projects)}
              >
                <i className="icon icon-arrow-left" />
              </button>
              <Menu className="item-nav-menu">
                <Dropdown
                  trigger={
                    <div className="item-nav-trigger">
                      <i className="icon icon-ellipsis" />
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <span
                      className="link item-menu-item menu-item item-projects"
                      onClick={() => history.push(routes.Projects)}
                    >
                      <i className="icon icon-arrow-left" />
                      <span className="menu-text">projects</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() =>
                        history.push(routes.Project + "/edit/" + itemID)
                      }
                    >
                      <i className="icon icon-edit" />
                      <span className="menu-text">edit</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() => setDrawerActive(true)}
                    >
                      <i className="icon icon-equalizer" />
                      <span className="menu-text">publish settings</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-members"
                      onClick={manageItemUsers}
                    >
                      <i className="icon icon-users" />
                      <span className="menu-text">members</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-share"
                      onClick={openShareItemModal}
                    >
                      <i className="icon icon-exit-up" />
                      <span className="menu-text">share</span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>

              <ItemSettings
                template={itemTemplate}
                settings={item.settings}
                theme={item.theme}
                drawerActive={drawerActive}
                setDrawerActive={setDrawerActive}
                updateSettings={updateSettings}
                saveTheme={saveTheme}
              />
            </nav>
          ) : null}

          {item.palette ? (
            <style>{`
              ::selection {
                background: ${itemColors.colorDisplay.primary};
              }
              .item-publish .item-loading h1,
              #project h2 .header-text,
              .section-sub .item-adjective p,
              .item-inspiration-font .item-publish-font h2,
              .item-inspiration-font .item-publish-font h3 {
                color: ${itemColors.colorDisplay.primary};
              }
              .section-sub p {
                color: ${itemColors.colorDisplay.secondary};
              }
              #project .item-publish-nav .item-back-btn:hover .icon {
                color: ${itemColors.colorDisplay.primary};
              }
              #project .item-publish-nav .item-nav-trigger:hover {
                background: ${itemColors.colorDisplay.primary};
              }
              #project .publish-icon-wrap:before {
                border-top-color: ${itemColors.colorDisplay.primaryLight};
              }
              #project .publish-icon-wrap.icon-wrap-small {
                background-image: linear-gradient(120deg, ${
                  itemColors.colorDisplay.secondaryDark
                }, ${itemColors.colorDisplay.secondary});
              }
              #project .publish-icon-wrap.icon-wrap-small:before {
                border-top-color: ${itemColors.colorDisplay.secondaryLight};
              }
              #project .due-date-month {
                background: ${itemColors.colorDisplay.secondary};
              }
              .publish-section .publish-icon-wrap.icon-wrap-small:after,
              .item-publish-audience.has-definition .item-audience-sub .sub-content:nth-child(2) {
                border-color: ${itemColors.colorDisplay.secondaryLight};
              }
              .item-audience-sub .sub-content .sub-text {
                color: ${
                  item.theme === "dark"
                    ? itemColors.colorDisplay.secondaryLight
                    : itemColors.colorDisplay.secondaryDark
                };
              }
              #project .due-date-day,
              #project .due-date-year {
                color: ${itemColors.colorDisplay.secondary};
              }
              .font-paragraph {
                border-left-color: ${itemColors.colorDisplay.primary}
              }
              #project .item-publish-members.publish-section {
                background-image: linear-gradient(-170deg, ${
                  itemColors.colorDisplay.secondary
                } 10%, ${itemColors.colorDisplay.primary} 67%);
              }
            `}</style>
          ) : null}
          <div
            className={`item-content ${
              item.font.type === "basic" ? spaceToEmpty(item.font.name) : ""
            }`}
          >
            {item.font.type === "advanced" ? (
              <style>
                {`
                  .item-content, .item-header h1, .publish-section p, .publish-section h1, .publish-section h2, .publish-section .btn, .section-sub .sub-content h3, .item-publish .item-loading h1 {
                    font-family: ${
                      item.fonts[0].family
                        ? `"${item.fonts[0].family}"`
                        : item.fonts[0].css_stack
                    };
                    font-weight: ${findNormalFontWeight(item.fonts[0])};
                    font-style: ${findNormalFontStyle(item.fonts[0])};
                  }
                `}
              </style>
            ) : null}

            {item.palette ? (
              <div
                className={`item-header ${findTextColor(
                  itemColors.colorVars.primary,
                  "large"
                )} ${
                  item.settings && !item.settings.parallax ? "not-fixed" : ""
                }`}
              >
                <Parallax
                  speed="1"
                  direction="down"
                  className="item-parallax-bg"
                  disabled={item.settings && !item.settings.parallax}
                >
                  <span
                    className="item-header-bg display"
                    style={{
                      backgroundImage:
                        "linear-gradient(160deg, " +
                        itemColors.colorDisplay.primary +
                        " 33%," +
                        itemColors.colorDisplay.secondary +
                        " 90%)",
                    }}
                  />
                  <span
                    className="item-header-bg normal"
                    style={{
                      backgroundImage:
                        "linear-gradient(160deg, " +
                        item.palette.colors.primary +
                        " 33%," +
                        item.palette.colors.secondary +
                        " 90%)",
                    }}
                  />
                </Parallax>
                {item.images ? (
                  <Parallax
                    speed="3"
                    direction="down"
                    className="item-parallax-img"
                    disabled={item.settings && !item.settings.parallax}
                  >
                    <ProgressiveImage
                      placeholder={
                        item.images[0].urls
                          ? item.images[0].urls.regular
                          : item.images[0].downloadURL
                      }
                      onError={(error) => {
                        console.log("ERROR - Load Image Header", error);
                        setItemLoadError(true);
                      }}
                      src={
                        item.images[0].urls
                          ? item.images[0].urls.full
                          : item.images[0].downloadURL
                      }
                    >
                      {(src, loading) => (
                        <div
                          className="item-image progressive blur"
                          style={{
                            filter: loading ? "blur(6px)" : "none",
                            backgroundImage: `url("${src}")`,
                          }}
                          ref={!loading ? loadItem : null}
                        />
                      )}
                    </ProgressiveImage>
                  </Parallax>
                ) : null}
                <Parallax
                  speed="2"
                  direction="down"
                  className="item-parallax-name"
                  disabled={item.settings && !item.settings.parallax}
                >
                  <h1>{item.name}</h1>
                </Parallax>
              </div>
            ) : (
              <div
                className={`item-header no-palette ${
                  item.settings && !item.settings.parallax ? "not-fixed" : ""
                }`}
              >
                {item.images ? (
                  <Parallax
                    speed="3"
                    direction="down"
                    className="item-parallax-img"
                    disabled={item.settings && !item.settings.parallax}
                  >
                    <ProgressiveImage
                      placeholder={
                        item.images[0].urls
                          ? item.images[0].urls.small
                          : item.images[0].downloadURL
                      }
                      onError={(error) =>
                        console.log("ERROR - Load Image Header", error)
                      }
                      src={
                        item.images[0].urls
                          ? item.images[0].urls.full
                          : item.images[0].downloadURL
                      }
                    >
                      {(src, loading) => (
                        <div
                          className="item-image progressive blur"
                          style={{
                            filter: loading ? "blur(6px)" : "none",
                            backgroundImage: `url("${src}")`,
                          }}
                          ref={!loading ? loadItem : null}
                        />
                      )}
                    </ProgressiveImage>
                  </Parallax>
                ) : null}
                <Parallax
                  speed="2"
                  direction="down"
                  className="item-parallax-name"
                  disabled={item.settings && !item.settings.parallax}
                >
                  <h1>{item.name}</h1>
                </Parallax>
              </div>
            )}

            <div className="item-body">
              {item.settings && item.settings.order
                ? item.settings.order.map((section) =>
                    section === "audience" && item.audience ? (
                      <ProjectAudience
                        key={"audience"}
                        item={item}
                        itemColors={itemColors}
                      />
                    ) : section === "details" &&
                      (item.details || item.files) ? (
                      <ProjectDetails
                        key={"details"}
                        item={item}
                        itemColors={itemColors}
                        loadItem={loadItem}
                        setItemLoadError={setItemLoadError}
                      />
                    ) : section === "aesthetic" &&
                      (item.adjectives ||
                        item.palette ||
                        item.images ||
                        item.font) ? (
                      <ProjectInspiration
                        key={"aesthetic"}
                        item={item}
                        itemColors={itemColors}
                        loadItem={loadItem}
                        setItemLoadError={setItemLoadError}
                      />
                    ) : null
                  )
                : null}

              {users ? (
                <section
                  className={`item-publish-members publish-section ${
                    !item.palette ? "no-palette" : ""
                  }`}
                >
                  {item.images ? (
                    <span className="item-image-wrap">
                      <ProgressiveImage
                        placeholder={
                          item.images[0].urls
                            ? item.images[0].urls.regular
                            : item.images[0].downloadURL
                        }
                        onError={(error) => {
                          console.log("ERROR - Load Image Footer", error);
                          setItemLoadError(true);
                        }}
                        src={
                          item.images[0].urls
                            ? item.images[0].urls.full
                            : item.images[0].downloadURL
                        }
                      >
                        {(src, loading) => (
                          <span
                            className="item-image progressive blur"
                            style={{
                              filter: loading ? "blur(6px)" : "none",
                              backgroundImage: `url("${src}")`,
                            }}
                            ref={!loading ? loadItem : null}
                          />
                        )}
                      </ProgressiveImage>
                    </span>
                  ) : null}
                  <h3>Project members:</h3>
                  <div className="item-publish-member-wrap">
                    {Object.keys(item.users).map((user) =>
                      users[user] && item.users[user] === true ? (
                        <div
                          className={`item-publish-member ${
                            user === authUser.uid ? "owner" : ""
                          } ${!users[user].profileImage ? "no-photo" : ""}`}
                          key={user}
                        >
                          <div className="profile-img-wrap">
                            {users[user].profileImage ? (
                              <ProgressiveImage
                                preview={require("../../images/loading-md.svg")}
                                src={users[user].profileImage}
                                key={users[user].profileImage}
                              >
                                {(src, loading) => (
                                  <div
                                    className={`profile-img progressive ${
                                      loading ? "loading" : "done"
                                    }`}
                                    style={{ backgroundImage: `url("${src}")` }}
                                  />
                                )}
                              </ProgressiveImage>
                            ) : (
                              <p className="profile-initials">
                                {findInitials(users[user].username)}
                              </p>
                            )}
                          </div>
                          <p className="item-publish-member-name">
                            {users[user].username}
                          </p>
                        </div>
                      ) : null
                    )}
                  </div>
                </section>
              ) : null}
            </div>
          </div>
          <div
            className={`item-loading content-loading no-spinner ${
              itemLoadError ? "error" : ""
            }`}
          >
            {!itemLoadError && !hideSpinner ? (
              <span>
                <h1
                  className={item.font.type === "basic" ? item.font.name : ""}
                >
                  Building project...
                </h1>
                <LoadingSpinner colors={itemColors} />
              </span>
            ) : itemLoadError ? (
              <span className="item-load-error">
                <i className="icon icon-cross-circle" />
                <h1>
                  Sorry, something went wrong.
                  <br />
                  Please try loading this again.
                </h1>
                <p>
                  <Link to={routes.Contact}>
                    Contact us if this problem persists.
                  </Link>
                </p>
              </span>
            ) : null}
          </div>
          <ReactBody className="item-publish" />
        </div>
      ) : item.doneList && !item.doneList.publish ? (
        <div className="item-outer item-unpublish-outer">
          <i className="icon icon-large icon-notification" />
          <h1>This project hasn't been published.</h1>
        </div>
      ) : (
        <ReactBody className="item-publish" />
      )}
    </section>
  );
};

const ProjectAudience = ({ item, itemColors }) => (
  <section
    className={`item-publish-audience publish-section ${
      item.audience.definition ? "has-definition" : ""
    }`}
  >
    {item.audience.definition ? (
      <div className="item-inner">
        <div className="item-audience-definition">
          <div className="publish-icon-wrap">
            <IconAudience colors={itemColors} />
          </div>
          <h2>
            <span className="header-label">
              You're creating this project for:
            </span>
            <p className="header-text">{item.audience.definition}</p>
          </h2>
        </div>
      </div>
    ) : null}
    <div className="item-audience-sub section-sub">
      {item.audience.industries || item.audience.industryOther ? (
        <div className="item-audience-industries sub-content">
          <div className="publish-icon-wrap icon-wrap-small">
            <i className="icon icon-marker-user publish-icon" />
          </div>
          <label className="sub-label">
            Your audience belongs to
            {(item.audience.industries &&
              item.audience.industries.length > 1) ||
            (item.audience.industries &&
              item.audience.industries.length === 1 &&
              item.audience.industryOther)
              ? " these industries:"
              : " this industry:"}
          </label>
          {item.audience.industries
            ? Object.keys(item.audience.industries).map((key) => (
                <p className="sub-text" key={key}>
                  {item.audience.industries[key]}
                </p>
              ))
            : null}
          {item.audience.industryOther ? (
            <p className="sub-text">{item.audience.industryOther}</p>
          ) : null}
        </div>
      ) : null}
      {item.audience.ages || item.audience.skills ? (
        <div className="item-audience-demographics sub-content">
          <div className="publish-icon-wrap icon-wrap-small">
            <i className="icon icon-profile publish-icon" />
          </div>
          <label className="sub-label">
            Your audience can be described as:
          </label>
          {item.audience.ages ? (
            <div className="item-audience-ages">
              {Object.keys(item.audience.ages).map((key) => (
                <p className="sub-text" key={key}>
                  {item.audience.ages[key]}
                  {item.audience.ages[key] !== "any age" ? " years old" : null}
                </p>
              ))}
            </div>
          ) : null}
          {item.audience.skills ? (
            <div className="item-audience-skills">
              {Object.keys(item.audience.skills).map((key) => (
                <p className="sub-text" key={key}>
                  {item.audience.skills[key]} with technology
                </p>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  </section>
);

const ProjectDetails = ({ item, itemColors, loadItem, setItemLoadError }) => (
  <section className="item-publish-details publish-section">
    <div className="item-inner">
      {item.details && item.details.problem ? (
        <div className="item-details-problem">
          <div className="publish-icon-wrap">
            <IconProblem colors={itemColors} />
          </div>
          <h2>
            <span className="header-label">
              The problem this project is solving:
            </span>
            <span className="header-text">{item.details.problem}</span>
          </h2>
        </div>
      ) : null}
      {item.details || item.files ? (
        <div className="item-details-sub section-sub">
          {item.details ? (
            <div className="item-details-content sub-content">
              {item.details.category || item.details.dueDate ? (
                <div className="publish-icon-wrap icon-wrap-small">
                  <i className="icon icon-list publish-icon" />
                </div>
              ) : null}
              {item.details.category ? (
                <div className="item-details-category">
                  <p className="sub-text">{`this is a ${item.details.category} project`}</p>
                </div>
              ) : null}
              {item.details.dueDate ? (
                <div
                  className={`item-details-due ${findTextColor(
                    itemColors.colorDisplay.accent,
                    "small"
                  )}`}
                >
                  <label>
                    {item.details.category ? "due on:" : "this project is due:"}
                  </label>
                  <div className="details-due-date">
                    <span className="due-date-month">
                      {moment(item.details.dueDate).format("MMM")}
                    </span>
                    <span className="due-date-day">
                      {moment(item.details.dueDate).format("D")}
                    </span>
                    <span className="due-date-year">
                      {moment(item.details.dueDate).format("YYYY")}
                    </span>
                  </div>
                  {/* <AddToCalendarButton
                    name={`${item.name} Due`}
                    options={[
                      "Apple",
                      "Google",
                      "MicrosoftTeams",
                      "Microsoft365",
                      "Outlook.com",
                      "Yahoo",
                    ]}
                    startDate={moment(item.details.dueDate).format(
                      "YYYY-MM-DD"
                    )}
                    endDate={moment(item.details.dueDate).format("YYYY-MM-DD")}
                    hideCheckmark
                    styleLight={`--btn-background: ${
                      itemColors.colorDisplay.accent
                    }; --btn-text: #fff; --font: ${
                      item.font.type === "basic"
                        ? item.font.name
                        : item.font.family
                    }`}
                  /> */}
                </div>
              ) : null}
              {item.details.other ? (
                <div className="item-details-other">
                  <label className="sub-label">Other important details:</label>
                  <p className="sub-text">{item.details.other}</p>
                </div>
              ) : null}
            </div>
          ) : null}
          {item.files ? (
            <div className="item-details-files sub-content details-files">
              <div className="publish-icon-wrap icon-wrap-small">
                <i className="icon icon-files publish-icon" />
              </div>
              <label className="sub-label">
                {item.files.length > 1
                  ? "Here are the files attached to this project:"
                  : "Here's the file attached to this project:"}
              </label>
              <ul className="files-list cards-list">
                {item.files.map((file, index) => (
                  <li
                    key={index}
                    title={file.name}
                    className="card selected item-file"
                  >
                    <FileDisplay
                      file={file}
                      loadItem={loadItem}
                      setItemLoadError={setItemLoadError}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  </section>
);

const ProjectInspiration = ({
  item,
  itemColors,
  loadItem,
  setItemLoadError,
}) => (
  <section className="item-publish-inspiration publish-section">
    <div className="item-inner">
      {item.adjectives ? (
        <div className="publish-icon-wrap">
          <IconDesign colors={itemColors} />
        </div>
      ) : null}
      <div className="item-inspiration-sub section-sub">
        {item.adjectives ? (
          <div className="item-inspiration-adjectives sub-content">
            <h3>The aesthetics for this project can be described as:</h3>
            <div className="adjectives-list">
              {Object.keys(item.adjectives).map((key) => (
                <div
                  className={`item-adjective ${item.adjectives[key]}`}
                  key={key}
                >
                  <span>{item.adjectives[key]}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {item.palette ? (
          <div className="item-inspiration-palette sub-content">
            <div className="publish-icon-wrap icon-wrap-small">
              <i className="icon icon-palette publish-icon" />
            </div>
            <label className="sub-label">
              Here's the project palette and some color{" "}
              <Popup
                hoverable
                hideOnScroll
                position="top right"
                className="popup-help"
                trigger={<span className="popup">variations</span>}
                header={
                  <p>
                    We automatically calculated some lighter shades of the
                    colors for additional palette variation.
                  </p>
                }
              />
              :
            </label>
            <div className="item-palette-wrap">
              <div className="item-palette-color primary">
                <PaletteDisplay
                  name="primary"
                  color={itemColors.colorVars.primary}
                />
              </div>
              <div className="item-palette-color secondary">
                <PaletteDisplay
                  name="secondary"
                  color={itemColors.colorVars.secondary}
                />
              </div>
              <div className="item-palette-color accent">
                <PaletteDisplay
                  name="accent"
                  color={itemColors.colorVars.accent}
                />
              </div>
            </div>

            {item.settings && item.settings.accessibility !== false ? (
              <TextAccessibility item={item} itemColors={itemColors} />
            ) : null}
          </div>
        ) : null}
        <div className="item-inspiration-font sub-content">
          <div className="publish-icon-wrap icon-wrap-small">
            <i className="icon icon-text-size publish-icon" />
          </div>

          {item.font.type === "basic" ? (
            <span className="font-wrap">
              <label className="sub-label">Here's the project font:</label>
              <div className="item-publish-font">
                <a
                  href={`https://fonts.google.com/download?family=${item.font.family}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="card-download">
                    <i className="icon icon-download" />
                  </span>
                </a>
                <a
                  href={`https://fonts.google.com/specimen/${item.font.family}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="card-link">
                    <i className="icon icon-link" />
                  </span>
                </a>
                <h2>{item.font.name}</h2>
                <p className="font-paragraph">
                  I wandered lonely as a cloud That floats on high o'er vales
                  and hills, When all at once I saw a crowd, A host, of golden
                  daffodils; Beside the lake, beneath the trees, Fluttering and
                  dancing in the breeze. Continuous as the stars that shine And
                  twinkle on the milky way, They stretched in never-ending line
                  Along the margin of a bay: Ten thousand saw I at a glance,
                  Tossing their heads in sprightly dance.
                </p>
                <h3>Font Characters</h3>
                <p className="font-characters">
                  {`A B C D E F G H I J K L M N O P Q R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 0 ‘ ? ’ “ ! ” ( % ) [ # ] { @ } / & < - + ÷ × = > ® © $ € £ ¥ ¢ : ; , . *`}
                </p>
              </div>
            </span>
          ) : item.font.type === "advanced" && item.fonts ? (
            <span className="font-wrap">
              <label className="sub-label">
                {item.fonts.length === 1
                  ? "Here's the project font:"
                  : "Here are the project fonts:"}
              </label>
              <FontsDisplay type={"project"} fonts={item.fonts} />
            </span>
          ) : null}
        </div>
        {item.images ? (
          <div className="item-inspiration-images sub-content">
            <div className="publish-icon-wrap icon-wrap-small">
              <i className="icon icon-pictures publish-icon" />
            </div>
            <label className="sub-label">
              Here's some inspirational imagery for the project:
            </label>
            <div className="item-publish-images">
              <Masonry className={"publish-images"}>
                {item.images.map((image, index) => (
                  <div className="item-image-wrap" key={index}>
                    <div className="item-image">
                      <a
                        href={`${
                          image.urls ? image.urls.full : image.downloadURL
                        }&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-md.svg")}
                          onError={(error) => {
                            console.log("ERROR - Load Image", error);
                            setItemLoadError(true);
                          }}
                          src={`${
                            image.urls ? image.urls.regular : image.downloadURL
                          }&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`}
                        >
                          {(src, loading) => (
                            <img
                              className={`progressive ${
                                loading ? "loading" : "done"
                              }`}
                              src={src}
                              alt={image.description}
                              onLoad={!loading ? loadItem : null}
                            />
                          )}
                        </ProgressiveImage>
                      </a>
                      <a
                        href={`${
                          image.urls ? image.urls.full : image.downloadURL
                        }&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="card-download">
                          <i className="icon icon-download" />
                        </span>
                      </a>
                    </div>
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  </section>
);

export default ProjectPublished;
