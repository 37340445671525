import React, { useState, useEffect, useRef, useCallback } from "react";
import { Prompt } from "react-router";
import { db, storage, firebase } from "../../firebase";
import ItemNav from "./ItemNav";
import Themes from "./Themes";
import Members from "./Members";
import Share from "./Share";
import { useMountEffect, AsyncState, capString, isBrendan } from "./Resources";
import { ContactForm } from "../core/Contact";
import { MembershipLimit } from "../users/MembershipData";
import { Modal, Confirm, Input, Popup } from "semantic-ui-react";
import { useIdleTimer } from "react-idle-timer";
import { useHotkeys } from "react-hotkeys-hook";
import ReactBody from "react-body";
import _cloneDeep from "lodash.clonedeep";
import { Helmet } from "react-helmet-async";
import * as routes from "constants/routes";

import Palette from "./Palette";
import PaletteList from "./PaletteList";
import Adjectives from "./Adjectives";
import Fonts from "./Fonts";
import FontList from "./FontList";
import Audience from "./Audience";
import Details from "./Details";
import Images from "./Images";
import Logos from "./Logos";
import Icons from "./Icons";
import Elements from "./Elements";
import Files from "./Files";
import Screens from "./Screens";
import PersonaAI from "./PersonaAI";
import TextContent from "./TextContent";
import { SignupForm } from "../users/Signup";

export const ItemEdit = (props) => {
  const { history, userMembership, type, label, authUser, itemID, demo } =
    props;

  useEffect(() => window.scrollTo(0, 0), []);

  const [item, setItem] = AsyncState({});
  const [itemTemplate, setItemTemplate] = useState([]);
  const itemInitial = useRef(null);
  const timeout = useRef(null);
  const timeline = useRef([]);
  const timelineAction = useRef("");
  const timelineCount = useRef(-1);
  const timelineMax = 15;

  const stepTotal =
    type === "projects"
      ? 8
      : type === "styleguides"
      ? 9
      : type === "showcases"
      ? 6
      : type === "personas"
      ? 6
      : 1;
  const [itemSaved, setItemSaved] = useState(false);
  const [itemClose, setItemClose] = useState(false);
  const [itemsLimit, setItemsLimit] = useState(false);
  const [publishedItem, setPublishedItem] = useState(false);
  const [itemDeleting, setItemDeleting] = useState(false);
  const [itemDuplicating, setItemDuplicating] = useState(false);
  const [doneMove, setDoneMove] = useState(false);
  const [itemEditingModal, setItemEditingModal] = useState(false);
  const [itemDeletedModal, setItemDeletedModal] = useState(false);
  const [itemDeniedModal, setItemDeniedModal] = useState(false);
  const [itemRemovedModal, setItemRemovedModal] = useState(false);
  const [itemArchivedModal, setItemArchivedModal] = useState(false);
  const [userSignupModal, setUserSignupModal] = useState(false);
  const [userSignupType, setUserSignupType] = useState(null);
  const [shareItemModal, setShareItemModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [manageUsersModal, setManageUsersModal] = useState(false);
  const [archiveItemConfirm, setArchiveItemConfirm] = useState(false);
  const [deleteItemConfirm, setDeleteItemConfirm] = useState(false);
  const [deleteItemConfirmAgain, setDeleteItemConfirmAgain] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);

  useEffect(() => {
    logItem(item);
  }, [item]);

  useMountEffect(() => {
    itemUserEdit();
    getTemplate();
    window.addEventListener("focus", itemUserEdit);

    // TODO: Consider using the `pagehide` or `visibilitychange` events instead
    window.addEventListener("beforeunload", itemUserEditCloseWindow);
    return () => {
      window.removeEventListener("focus", itemUserEdit);

      // TODO: Consider using the `pagehide` or `visibilitychange` events instead
      window.removeEventListener("beforeunload", itemUserEditCloseWindow);
      if (
        history.location.pathname !== `/${label}/${itemID}` &&
        timeline.current[timelineCount.current].theme
      ) {
        document.body.classList.remove(
          timeline.current[timelineCount.current].theme
        );
      }
      clearTimeout(timeout.current);
      itemUserEditClose();
      setItem({});
    };
  });

  const logItem = (update) => {
    if (
      JSON.stringify(update) !==
      JSON.stringify(timeline.current[timelineCount.current])
    ) {
      if (
        timelineAction.current === "undo" ||
        timelineAction.current === "redo"
      ) {
        timeline.current = timeline.current.slice(0, timelineCount.current + 1);
        timelineCount.current -=
          timeline.current.length - timelineCount.current - 1;
      } else if (timelineAction.current === "log") {
        if (timelineCount.current > timelineMax) {
          timeline.current.shift();
          timelineCount.current--;
        }
      }
      timeline.current.push(update);
      timelineAction.current = "log";
      timelineCount.current++;
    }
  };

  const undoItem = () => {
    if (timelineCount.current > 0) {
      timelineCount.current--;
      timelineAction.current = "undo";
      if (item.theme !== timeline.current[timelineCount.current].theme) {
        document.body.classList.remove(item.theme);
        document.body.classList.add(
          timeline.current[timelineCount.current].theme
        );
      }
      setItem(timeline.current[timelineCount.current]);
    }
  };

  const redoItem = () => {
    if (
      timelineCount.current < timeline.current.length - 1 &&
      timelineCount.current >= 0
    ) {
      timelineCount.current++;
      timelineAction.current = "redo";
      if (item.theme !== timeline.current[timelineCount.current].theme) {
        document.body.classList.remove(item.theme);
        document.body.classList.add(
          timeline.current[timelineCount.current].theme
        );
      }
      setItem(timeline.current[timelineCount.current]);
    }
  };

  const killSteps = (update) => {
    timeline.current = [];
    timeline.current.push(update);
    timelineAction.current = "";
    timelineCount.current = 0;
  };

  const findItemLimit = () => {
    db.getUserItems(type, authUser.uid).then((items) => {
      let itemCount = Object.keys(items).length;
      items.forEach((item) => {
        if (item.val().archived) {
          itemCount--;
        }
      });
      setItemsLimit(MembershipLimit(itemCount, userMembership));
    });
  };

  const initItem = (snapshot, template) => {
    if (!snapshot.version) {
      snapshot.version = template.version;
    }
    if (!snapshot.stepCount) {
      snapshot.stepCount = 1;
    }
    if (!snapshot.doneList) {
      snapshot.doneList = {};
    } else {
      snapshot.doneList = closeDoneCheck(snapshot);
    }
    if (!snapshot.name) {
      snapshot.name = "";
      setNameFocus(true);
    }
    if (!snapshot.theme) {
      snapshot.theme = template.themes ? template.themes[0] : "light";
    }
    if (!snapshot.settings) {
      snapshot.settings = template.settings;
    }

    // Item Specific

    if (type === "projects") {
      if (!snapshot.content) {
        snapshot.content = {};
      }
      if (!snapshot.adjectives) {
        snapshot.adjectives = [];
      }
      if (!snapshot.palette) {
        snapshot.palette = {
          colors: {},
        };
      }

      // Legacy catch for users without the name + family DB table
      if (snapshot.font && !snapshot.font.name) {
        const itemFamily = template.sections.fontList
          .map((e) => {
            return e.name;
          })
          .indexOf(snapshot.font);
        snapshot.font = {
          type: "basic",
          name: snapshot.font,
          family: template.sections.fonts[itemFamily].family,
        };
      }

      // Catch for no fonts or else using "Muli" since they changed the name
      if (!snapshot.font || (snapshot.font && snapshot.font.name === "muli")) {
        snapshot.font = {
          type: "basic",
          name: "mulish",
          family: "Mulish",
        };
      }
      if (!snapshot.fonts) {
        snapshot.fonts = [];
        snapshot.font.type = "basic";
      } else {
        snapshot.fonts = checkBadFileRef(snapshot.fonts);
      }
      if (!snapshot.images) {
        snapshot.images = [];
      } else {
        snapshot.images = checkBadFileRef(snapshot.images);
      }
      if (!snapshot.audience) {
        snapshot.audience = {};
      }
      if (!snapshot.audience.industries) {
        snapshot.audience.industries = [];
      }
      if (!snapshot.audience.industryOther) {
        snapshot.audience.industryOther = [];
      }
      if (!snapshot.details) {
        snapshot.details = {};
      }
      if (!snapshot.details.category) {
        snapshot.details.category = [];
      }
      if (!snapshot.files) {
        snapshot.files = [];
      } else {
        snapshot.files = checkBadFileRef(snapshot.files);
      }
    }

    if (type === "styleguides") {
      if (!snapshot.content) {
        snapshot.content = {};
      }
      if (!snapshot.logos) {
        snapshot.logos = [];
      } else {
        snapshot.logos = checkBadFileRef(snapshot.logos);
      }
      if (!snapshot.palette) {
        snapshot.palette = {};
      }
      if (!snapshot.palette.colors) {
        snapshot.palette.colors = {};
      }
      if (!snapshot.palette.altColors) {
        snapshot.palette.altColors = [];
      }
      if (!snapshot.fonts) {
        snapshot.fonts = [];
      } else {
        snapshot.fonts = checkBadFileRef(snapshot.fonts);
      }
      if (!snapshot.icons) {
        snapshot.icons = [];
      } else {
        snapshot.icons = checkBadFileRef(snapshot.icons);
      }
      if (!snapshot.images) {
        snapshot.images = [];
      } else {
        snapshot.images = checkBadFileRef(snapshot.images);
      }
      if (!snapshot.elements) {
        snapshot.elements = template.sections.elements;
      }
      if (!snapshot.files) {
        snapshot.files = [];
      } else {
        snapshot.files = checkBadFileRef(snapshot.files);
      }
    }

    if (type === "showcases") {
      if (!snapshot.content) {
        snapshot.content = {};
      }
      if (!snapshot.screens) {
        snapshot.screens = [];
      } else {
        snapshot.screens = checkBadFileRef(snapshot.screens);
      }
      if (!snapshot.palette) {
        snapshot.palette = {};
      }
      if (!snapshot.palette.colors) {
        snapshot.palette.colors = {};
      }
      if (!snapshot.palette.altColors) {
        snapshot.palette.altColors = [];
      }
      if (!snapshot.fonts) {
        snapshot.fonts = [];
      } else {
        snapshot.fonts = checkBadFileRef(snapshot.fonts);
      }
      if (!snapshot.files) {
        snapshot.files = [];
      } else {
        snapshot.files = checkBadFileRef(snapshot.files);
      }
    }

    if (type === "personas") {
      if (!snapshot.content) {
        snapshot.content = {};
      }
      if (!snapshot.personaai) {
        snapshot.personaai = {};
      }
      if (!snapshot.screens) {
        snapshot.screens = [];
      } else {
        snapshot.screens = checkBadFileRef(snapshot.screens);
      }
      if (!snapshot.palette) {
        snapshot.palette = {};
      }
      if (!snapshot.palette.colors) {
        snapshot.palette.colors = {};
      }
      if (!snapshot.palette.altColors) {
        snapshot.palette.altColors = [];
      }
      if (!snapshot.fonts) {
        snapshot.fonts = [];
      } else {
        snapshot.fonts = checkBadFileRef(snapshot.fonts);
      }
      if (!snapshot.files) {
        snapshot.files = [];
      } else {
        snapshot.files = checkBadFileRef(snapshot.files);
      }
    }

    document.body.classList.add(snapshot.theme);
    setItemTemplate(template);
    itemInitial.current = _cloneDeep(snapshot);
    killSteps(snapshot);
    setItem(snapshot);
    if (!demo) findItemLimit();
  };

  const getItem = (itemID, template) => {
    if (!demo) {
      db.getItem(type, itemID).then((snapshot) => {
        if (
          snapshot.val() !== null &&
          (Object.keys(snapshot.val().users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan())
        ) {
          if (
            snapshot.val().version &&
            template.version &&
            template.version !== snapshot.val().version
          ) {
            storage
              .getCurrentArchivedTemplate(label, snapshot.val().version)
              .then((response) => {
                const request = new XMLHttpRequest();
                request.open("GET", response, true);
                request.onreadystatechange = (request) => {
                  if (request.target.readyState === 4) {
                    const data = JSON.parse(request.target.response);
                    initItem(snapshot.val(), data);
                  }
                };
                request.send();
              });
          } else {
            initItem(snapshot.val(), template);
          }
        } else if (
          snapshot.val() !== null &&
          Object.keys(snapshot.val().users).indexOf(authUser.uid) === -1
        ) {
          setItemDeniedModal(true);
        } else {
          setItemDeletedModal(true);
        }
      });
    } else {
      initItem({}, template);
    }
  };

  const getTemplate = () => {
    // TODO: We should probably redo this so we get the Item FIRST and THEN lookup the correct template

    storage.getCurrentTemplate(label).then((response) => {
      const request = new XMLHttpRequest();
      request.open("GET", response, true);
      request.onreadystatechange = (request) => {
        if (request.target.readyState === 4) {
          const data = JSON.parse(request.target.response);
          getItem(itemID, data);
        }
      };
      request.send();
    });
  };

  const getActiveData = useCallback(() => {
    return {
      active: {
        editing: true,
        userID: authUser.uid,
        userName: authUser.displayName,
        userEmail: authUser.email,
        date: new Date().getTime(),
      },
    };
  }, [authUser]);

  const closeRedirect = useCallback(
    (redirect) => {
      let route = `/${type}`;
      if (redirect === label) {
        route = `/${label}/${itemID}`;
      }
      timeout.current = setTimeout(() => {
        history.push(route);
      }, 750);
    },
    [history, itemID, label, type]
  );

  const closeItem = (saveType) => {
    if (checkItemChange() && saveType !== "save") {
      history.push(`/${type}`);
    } else {
      if (saveType === "save") {
        setItem({ ...item, doneList: closeDoneCheck(item) }).then((update) =>
          saveItem("close", update, type)
        );
      } else {
        setItem({ ...item, doneList: closeDoneCheck(item) });
        setItemClose(true);
        closeRedirect(type);
      }
    }
  };

  const closeDoneCheck = (snapshot) => {
    if (snapshot.stepCount > 1) {
      snapshot.doneList.name = true;
    } else {
      snapshot.doneList.name = false;
    }

    // Item Specific

    if (type === "projects") {
      if (snapshot.stepCount > 2) {
        snapshot.doneList.adjectives = true;
      } else {
        snapshot.doneList.adjectives = false;
      }
      if (snapshot.stepCount > 3) {
        snapshot.doneList.palette = true;
      } else {
        snapshot.doneList.palette = false;
      }
      if (snapshot.stepCount > 4) {
        snapshot.doneList.font = true;
      } else {
        snapshot.doneList.font = false;
      }
      if (snapshot.stepCount > 5) {
        snapshot.doneList.images = true;
      } else {
        snapshot.doneList.images = false;
      }
      if (snapshot.stepCount > 6) {
        snapshot.doneList.audience = true;
      } else {
        snapshot.doneList.audience = false;
      }
      if (snapshot.stepCount > 7) {
        snapshot.doneList.details = true;
      } else {
        snapshot.doneList.details = false;
      }
    }

    if (type === "styleguides") {
      if (snapshot.stepCount > 2) {
        snapshot.doneList.logos = true;
      } else {
        snapshot.doneList.logos = false;
      }
      if (snapshot.stepCount > 3) {
        snapshot.doneList.palette = true;
      } else {
        snapshot.doneList.palette = false;
      }
      if (snapshot.stepCount > 4) {
        snapshot.doneList.fonts = true;
      } else {
        snapshot.doneList.fonts = false;
      }
      if (snapshot.stepCount > 5) {
        snapshot.doneList.images = true;
      } else {
        snapshot.doneList.images = false;
      }
      if (snapshot.stepCount > 6) {
        snapshot.doneList.icons = true;
      } else {
        snapshot.doneList.icons = false;
      }
      if (snapshot.stepCount > 7) {
        snapshot.doneList.elements = true;
      } else {
        snapshot.doneList.elements = false;
      }
      if (snapshot.stepCount > 8) {
        snapshot.doneList.files = true;
      } else {
        snapshot.doneList.files = false;
      }
    } else if (type === "showcases") {
      if (snapshot.stepCount > 2) {
        snapshot.doneList.screens = true;
      } else {
        snapshot.doneList.screens = false;
      }
      if (snapshot.stepCount > 3) {
        snapshot.doneList.palette = true;
      } else {
        snapshot.doneList.palette = false;
      }
      if (snapshot.stepCount > 4) {
        snapshot.doneList.fonts = true;
      } else {
        snapshot.doneList.fonts = false;
      }
      if (snapshot.stepCount > 5) {
        snapshot.doneList.files = true;
      } else {
        snapshot.doneList.files = false;
      }
    } else if (type === "personas") {
      if (snapshot.stepCount > 2) {
        snapshot.doneList.screens = true;
      } else {
        snapshot.doneList.screens = false;
      }
      if (snapshot.stepCount > 3) {
        snapshot.doneList.palette = true;
      } else {
        snapshot.doneList.palette = false;
      }
      if (snapshot.stepCount > 4) {
        snapshot.doneList.fonts = true;
      } else {
        snapshot.doneList.fonts = false;
      }
      if (snapshot.stepCount > 5) {
        snapshot.doneList.files = true;
      } else {
        snapshot.doneList.files = false;
      }
    }

    return snapshot.doneList;
  };

  const itemUserEditClose = () => {
    if (
      !demo &&
      timeline.current[timelineCount.current].active &&
      timeline.current[timelineCount.current].active.userID === authUser.uid
    ) {
      let itemData = {
        active: {
          editing: false,
          userID: "",
          userName: "",
          userEmail: "",
          date: "",
        },
        doneList: closeDoneCheck(timeline.current[timelineCount.current]),
        stepCount: timeline.current[timelineCount.current].stepCount,
      };

      db.getItem(type, itemID).then((snapshot) => {
        if (
          snapshot.val() !== null &&
          (Object.keys(snapshot.val().users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan())
        ) {
          db.saveItem(type, itemID, itemData);
        }
      });
    }
    clearTimeout(timeout.current);
  };

  const itemUserEditCloseWindow = (event) => {
    event.preventDefault();
    itemUserEditClose();
    if (checkItemChange()) {
      let confirmMessage = "Sure you want to leave? Changes will not be saved.";
      event.returnValue = confirmMessage;
      return confirmMessage;
    }
  };

  const itemUserEdit = () => {
    // This timeout is necessary when a user tries to close the tab / browser and is prompted that changes will not be saved, if they click "ok" then we don't want the "focus" event to kick in and save their data before close -- this is complex, I realize but it works
    timeout.current = setTimeout(() => {
      if (!demo) {
        let itemData = getActiveData();
        db.getItem(type, itemID).then((snapshot) => {
          if (snapshot.val() !== null) {
            db.getItemActive(type, itemID).then((responseActive) => {
              // This is designed for other idle cases (like on mobile) where the browser is closed but doesn't trigger the listeners
              let timeNow = new Date().getTime();
              let timeActive = responseActive.val()
                ? responseActive.val().date
                : new Date().getTime();
              if (
                responseActive.val() === null ||
                !responseActive.val().editing ||
                responseActive.val().userID === authUser.uid ||
                (responseActive.val().editing &&
                  timeNow - timeActive > 600000) ||
                timeActive === undefined
              ) {
                if (!itemEditingModal) {
                  db.saveItem(type, itemID, itemData).then(() => {
                    if (item.version) {
                      setItem({ ...item, active: itemData.active });
                    }
                    setItemEditingModal(false);
                  });
                } else {
                  db.saveItem(type, itemID, itemData).then(() => {
                    if (item.version) {
                      setItem({ ...item, active: itemData.active });
                    }

                    // TODO: Consider using the `pagehide` or `visibilitychange` events instead
                    window.addEventListener(
                      "beforeunload",
                      itemUserEditCloseWindow
                    );
                  });
                }
                db.setUpdateData(label, itemID, authUser.uid);
              } else {
                setItemEditingModal(true);

                // TODO: Consider using the `pagehide` or `visibilitychange` events instead
                window.removeEventListener(
                  "beforeunload",
                  itemUserEditCloseWindow
                );
              }
            });
          } else {
            setItemEditingModal(false);
            setItemDeletedModal(true);
          }
        });
      }
    });
  };

  const checkBadFileRef = (files) => {
    let badFiles = [];
    files.forEach((file) => {
      if (file && file.new) {
        badFiles.push(file);
      }
    });
    badFiles.forEach((file) => {
      let i = files.indexOf(file);
      files.splice(i, 1);
    });
    return files;
  };

  const checkItemChange = () => {
    if (
      demo ||
      itemEditingModal ||
      itemDeniedModal ||
      itemDeletedModal ||
      itemRemovedModal ||
      itemArchivedModal
    ) {
      return false;
    }

    // Item Specific

    if (type === "projects") {
      if (
        JSON.stringify(itemInitial.current.content) !==
        JSON.stringify(item.content)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.name) !== JSON.stringify(item.name)
      ) {
        return true;
      }
      if (
        JSON.stringify(
          itemInitial.current.adjectives,
          itemInitial.current.adjectives.sort()
        ) !== JSON.stringify(item.adjectives, item.adjectives.sort())
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.palette) !==
        JSON.stringify(item.palette)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.font) !== JSON.stringify(item.font)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.fonts) !== JSON.stringify(item.fonts)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.images) !==
        JSON.stringify(item.images)
      ) {
        return true;
      }
      if (
        JSON.stringify(
          itemInitial.current.audience,
          Object.keys(itemInitial.current.audience).sort()
        ) !== JSON.stringify(item.audience, Object.keys(item.audience).sort())
      ) {
        return true;
      }
      if (
        JSON.stringify(
          itemInitial.current.details,
          Object.keys(itemInitial.current.details).sort()
        ) !== JSON.stringify(item.details, Object.keys(item.details).sort())
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.files) !== JSON.stringify(item.files)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.theme) !== JSON.stringify(item.theme)
      ) {
        return true;
      }
    }

    if (type === "styleguides") {
      if (
        JSON.stringify(itemInitial.current.content) !==
        JSON.stringify(item.content)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.name) !== JSON.stringify(item.name)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.logos) !== JSON.stringify(item.logos)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.palette) !==
        JSON.stringify(item.palette)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.fonts) !== JSON.stringify(item.fonts)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.images) !==
        JSON.stringify(item.images)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.icons) !== JSON.stringify(item.icons)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.elements) !==
        JSON.stringify(item.elements)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.files) !== JSON.stringify(item.files)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.theme) !== JSON.stringify(item.theme)
      ) {
        return true;
      }
    }

    if (type === "showcases") {
      if (
        JSON.stringify(itemInitial.current.content) !==
        JSON.stringify(item.content)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.name) !== JSON.stringify(item.name)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.screens) !==
        JSON.stringify(item.screens)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.palette) !==
        JSON.stringify(item.palette)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.fonts) !== JSON.stringify(item.fonts)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.files) !== JSON.stringify(item.files)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.theme) !== JSON.stringify(item.theme)
      ) {
        return true;
      }
    }

    if (type === "personas") {
      if (
        JSON.stringify(itemInitial.current.content) !==
        JSON.stringify(item.content)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.name) !== JSON.stringify(item.name)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.screens) !==
        JSON.stringify(item.screens)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.palette) !==
        JSON.stringify(item.palette)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.fonts) !== JSON.stringify(item.fonts)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.files) !== JSON.stringify(item.files)
      ) {
        return true;
      }
      if (
        JSON.stringify(itemInitial.current.theme) !== JSON.stringify(item.theme)
      ) {
        return true;
      }
    }

    return false;
  };

  const editItemName = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      name: event.target.value,
      doneList: {
        ...item.doneList,
        name: event.target.value === itemInitial.current.name ? true : false,
      },
    });
  };

  const inputNameKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(event.target.id).blur();
      doneStep("name", 1);
    }
  };

  const checkColorPalette = (palette) => {
    if (type === "projects") {
      if (
        palette &&
        palette.name === "custom" &&
        (palette.colors.primary === "" ||
          palette.colors.secondary === "" ||
          palette.colors.accent === "")
      ) {
        palette = [];
      }
    }

    if (type === "styleguides" || type === "showcases" || type === "personas") {
      if (palette && palette.colors) {
        if (
          !palette.colors.primary ||
          palette.colors.primary === "" ||
          !palette.colors.secondary ||
          palette.colors.secondary === "" ||
          !palette.colors.accent ||
          palette.colors.accent === ""
        ) {
          palette.colors = [];
        }
      }
      if (palette && palette.altColors) {
        let altColorsCopy = [];
        palette.altColors.forEach((color) => {
          if (color.color) {
            altColorsCopy.push(color);
          }
        });
        palette.altColors = altColorsCopy.length > 0 ? altColorsCopy : [];
      }
    }

    return palette;
  };

  const saveItem = (saveType, saveItem, saveRedirect) => {
    if (
      !itemEditingModal &&
      !itemDeniedModal &&
      !itemDeletedModal &&
      !itemRemovedModal &&
      !itemArchivedModal &&
      !demo
    ) {
      db.getItem(type, itemID).then((snapshot) => {
        if (
          snapshot.val() !== null &&
          (Object.keys(snapshot.val().users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan()) &&
          !snapshot.val().archived
        ) {
          clearTimeout(timeout.current);
          let itemData = [];
          if (type === "projects") {
            itemData = {
              updated: new Date().getTime(),
              updatedBy: authUser.uid,
              stepCount: saveItem.stepCount ? saveItem.stepCount : 1,
              content: saveItem.content ? saveItem.content : [],
              name: saveItem.name ? saveItem.name : [],
              adjectives: saveItem.adjectives ? saveItem.adjectives : [],
              palette: saveItem.palette ? saveItem.palette : [],
              font: saveItem.font ? saveItem.font : [],
              fonts: saveItem.fonts
                ? checkBadFileRef(_cloneDeep(saveItem.fonts))
                : [],
              images: saveItem.images
                ? checkBadFileRef(_cloneDeep(saveItem.images))
                : [],
              audience: saveItem.audience ? saveItem.audience : [],
              details: saveItem.details ? saveItem.details : [],
              files: saveItem.files
                ? checkBadFileRef(_cloneDeep(saveItem.files))
                : [],
              theme: saveItem.theme ? saveItem.theme : [],
              doneList: saveItem.doneList ? saveItem.doneList : [],
            };
          }
          if (type === "styleguides") {
            itemData = {
              updated: new Date().getTime(),
              updatedBy: authUser.uid,
              stepCount: saveItem.stepCount ? saveItem.stepCount : 1,
              content: saveItem.content ? saveItem.content : [],
              name: saveItem.name ? saveItem.name : [],
              logos: saveItem.logos
                ? checkBadFileRef(_cloneDeep(saveItem.logos))
                : [],
              palette: saveItem.palette ? item.palette : [],
              fonts: saveItem.fonts
                ? checkBadFileRef(_cloneDeep(saveItem.fonts))
                : [],
              icons: saveItem.icons
                ? checkBadFileRef(_cloneDeep(saveItem.icons))
                : [],
              images: saveItem.images
                ? checkBadFileRef(_cloneDeep(saveItem.images))
                : [],
              elements: saveItem.elements ? saveItem.elements : [],
              files: saveItem.files
                ? checkBadFileRef(_cloneDeep(saveItem.files))
                : [],
              theme: saveItem.theme ? saveItem.theme : [],
              doneList: saveItem.doneList ? saveItem.doneList : [],
            };
          }
          if (type === "showcases") {
            itemData = {
              active: getActiveData().active,
              updated: new Date().getTime(),
              updatedBy: authUser.uid,
              stepCount: saveItem.stepCount ? saveItem.stepCount : 1,
              content: saveItem.content ? saveItem.content : [],
              name: saveItem.name ? saveItem.name : [],
              screens: saveItem.screens
                ? checkBadFileRef(_cloneDeep(saveItem.screens))
                : [],
              palette: saveItem.palette ? item.palette : [],
              fonts: saveItem.fonts
                ? checkBadFileRef(_cloneDeep(saveItem.fonts))
                : [],
              files: saveItem.files
                ? checkBadFileRef(_cloneDeep(saveItem.files))
                : [],
              theme: saveItem.theme ? saveItem.theme : [],
              doneList: saveItem.doneList ? saveItem.doneList : [],
            };
          }
          if (type === "persaons") {
            itemData = {
              active: getActiveData().active,
              updated: new Date().getTime(),
              updatedBy: authUser.uid,
              stepCount: saveItem.stepCount ? saveItem.stepCount : 1,
              content: saveItem.content ? saveItem.content : [],
              name: saveItem.name ? saveItem.name : [],
              screens: saveItem.screens
                ? checkBadFileRef(_cloneDeep(saveItem.screens))
                : [],
              palette: saveItem.palette ? item.palette : [],
              fonts: saveItem.fonts
                ? checkBadFileRef(_cloneDeep(saveItem.fonts))
                : [],
              files: saveItem.files
                ? checkBadFileRef(_cloneDeep(saveItem.files))
                : [],
              theme: saveItem.theme ? saveItem.theme : [],
              doneList: saveItem.doneList ? saveItem.doneList : [],
            };
          }
          itemInitial.current = _cloneDeep(saveItem);
          db.saveItem(type, itemID, itemData).then(() => {
            if (saveType === "save") {
              setItemSaved(true);
              timeout.current = setTimeout(() => {
                setItemSaved(false);
              }, 3000);
            } else {
              setItemClose(true);
              closeRedirect(saveRedirect);
            }
          });
        } else if (snapshot.val() !== null && snapshot.val().archived) {
          setItemArchivedModal(true);
          setItemClose(true);
        } else if (
          snapshot.val() !== null &&
          Object.keys(snapshot.val().users).indexOf(authUser.uid) === -1
        ) {
          setItemRemovedModal(true);
          setItemClose(true);
        } else {
          setItemDeletedModal(true);
          setItemClose(true);
        }
      });
    }
  };

  const archiveItem = () => {
    if (!demo) {
      db.archiveItem(type, itemID, authUser.uid).then(() => {
        setArchiveItemConfirm(false);
        history.push(`/${type}`);
      });
    }
  };

  const deleteItem = (event) => {
    event.preventDefault();
    if (!demo) {
      setItemDeleting(true);
      firebase
        .deleteItem({ id: itemID, type: type, uid: authUser.uid })
        .then(() => {
          setItemDeleting(false);
          setItemClose(true);
          history.push(`/${type}`);
        });
    }
  };

  const duplicateItem = () => {
    setItemDuplicating(true);
    db.duplicateItem(type, itemID, authUser.uid, (dupID) => {
      firebase
        .duplicateItemFiles({
          id: itemID,
          dupID: dupID,
          type: "items",
          uid: authUser.uid,
        })
        .then(() => {
          setItemDuplicating(false);
          history.push(`/${label}/edit/${itemID}`);
          getItem(itemID, itemTemplate);
        });
    });
  };

  const publishItem = (event) => {
    event.preventDefault();
    if (!demo) {
      const initPub = !item.doneList.publish ? true : false;

      let doneList = {};
      let palette = [];

      if (type === "projects") {
        doneList.name = true;
        doneList.adjectives = true;
        doneList.palette = true;
        doneList.font = true;
        doneList.images = true;
        doneList.audience = true;
        doneList.details = true;
        doneList.publish = true;
        palette = checkColorPalette(_cloneDeep(item.palette));
      }

      if (type === "styleguides") {
        doneList.name = true;
        doneList.logos = true;
        doneList.palette = true;
        doneList.fonts = true;
        doneList.icons = true;
        doneList.images = true;
        doneList.elements = true;
        doneList.files = true;
        doneList.publish = true;
        palette = checkColorPalette(_cloneDeep(item.palette));
      }

      if (type === "showcases") {
        doneList.name = true;
        doneList.screens = true;
        doneList.palette = true;
        doneList.fonts = true;
        doneList.files = true;
        doneList.publish = true;
        palette = checkColorPalette(_cloneDeep(item.palette));
      }

      if (type === "personas") {
        doneList.name = true;
        doneList.screens = true;
        doneList.palette = true;
        doneList.fonts = true;
        doneList.files = true;
        doneList.publish = true;
        palette = checkColorPalette(_cloneDeep(item.palette));
      }

      setPublishedItem(true);
      timeout.current = setTimeout(() => {
        setItemClose(true);
        setItem({ ...item, doneList: doneList, palette: palette }).then(
          (update) => {
            if (checkItemChange() || initPub) {
              saveItem("done", update, label);
            } else {
              closeRedirect(label);
            }
          }
        );
      }, 670);
    }
  };

  const doneStep = (doneType, step) => {
    setItem({
      ...item,
      stepCount: step >= item.stepCount ? item.stepCount + 1 : item.stepCount,
      doneList: {
        ...item.doneList,
        [doneType]: true,
      },
    }).then((update) => {
      if (checkItemChange()) {
        saveItem("save", update, "none");
      } else if (demo) {
        itemInitial.current = _cloneDeep(update);
      }
    });
  };

  useEffect(() => {
    if (item.stepCount < stepTotal) {
      setDoneMove(true);
      let stepView = document.getElementById(`step-${item.stepCount}`);
      if (stepView) stepView.scrollIntoView();
      timeout.current = setTimeout(() => setDoneMove(false));
    }
  }, [item.stepCount, stepTotal]);

  const createItemLayout = (section, index) => {
    index++;
    const defaultProps = {
      authUser: authUser,
      id: itemID,
      type: type,
      label: label,
      item: item,
      setItem: setItem,
      itemTemplate: itemTemplate,
      doneStep: doneStep,
      saveItem: saveItem,
      killSteps: killSteps,
      demo: demo,
    };

    switch (section) {
      case "adjectives":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-adjectives step"
          >
            {item.stepCount >= index ? (
              <Adjectives step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "palettes":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-palette step"
          >
            {item.stepCount >= index ? (
              <Palette step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "paletteList":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-palette step"
          >
            {item.stepCount >= index ? (
              <PaletteList step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "fonts":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-fonts step"
          >
            {item.stepCount >= index ? (
              <Fonts step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "fontList":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-fonts step"
          >
            {item.stepCount >= index ? (
              <FontList step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "images":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-images step"
          >
            {item.stepCount >= index ? (
              <Images step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "audience":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-audience step"
          >
            {item.stepCount >= index ? (
              <Audience step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "details":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-details step"
          >
            {item.stepCount >= index ? (
              <Details step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "logos":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-logos step"
          >
            {item.stepCount >= index ? (
              <Logos step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "icons":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-icons step"
          >
            {item.stepCount >= index ? (
              <Icons step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "elements":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-elements step"
          >
            {item.stepCount >= index ? (
              <Elements step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "files":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-files step"
          >
            {item.stepCount >= index ? (
              <Files step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "screens":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-screens step"
          >
            {item.stepCount >= index ? (
              <Screens step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      case "personaai":
        return (
          <section
            key={section}
            id={`step-${index + 1}`}
            className="item-section item-personaai step"
          >
            {item.stepCount >= index ? (
              <PersonaAI step={index + 1} {...defaultProps} />
            ) : null}
          </section>
        );
      default:
        return (
          <div className="content-loading no-header" key={`loading-${index}`} />
        );
    }
  };

  const textContentProps = {
    id: itemID,
    type: type,
    item: item,
    setItem: setItem,
    child: "root",
    saveItem: saveItem,
  };

  useHotkeys(
    "command+z, ctrl+z",
    (event) => {
      event.preventDefault();
      undoItem();
    },
    [item]
  );

  useHotkeys(
    "command+y, ctrl+y, shift+command+z, shift+ctrl+z",
    (event) => {
      event.preventDefault();
      redoItem();
    },
    [item]
  );

  useHotkeys(
    "command+s, ctrl+s",
    (event) => {
      event.preventDefault();
      saveItem("save", item, "none");
    },
    [item]
  );

  useIdleTimer({
    timeout: 300000,
    onIdle: itemUserEditClose,
    onActive: itemUserEdit,
    debounce: 500,
  });

  return (
    <section
      id={`${label}-edit`}
      className={`content-outer ${itemSaved ? "saved" : ""} ${
        itemClose ? "closed" : ""
      } ${publishedItem ? "published" : ""} ${itemDeleting ? "deleting" : ""} ${
        itemDuplicating ? "duplicating" : ""
      } ${item.stepCount === stepTotal ? "publish-bar" : ""} active-step-${
        item.stepCount
      } ${doneMove ? "done-step" : ""} theme-${item.theme}`}
    >
      <Helmet>
        <title>
          DesignGapp -{" "}
          {demo
            ? `${capString(label)} Demo`
            : `${item.name ? item.name : capString(label)}`}
        </title>
        <meta name="description" content={`Edit your DesignGapp ${label}`} />
      </Helmet>

      <Confirm
        className="confirm"
        open={deleteItemConfirm}
        header={`Do you really want to delete this ${label}?`}
        content="This is permanent and can't be undone."
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={() => setDeleteItemConfirm(false)}
        onConfirm={() => {
          setDeleteItemConfirm(false);
          setDeleteItemConfirmAgain(true);
        }}
      />

      <Confirm
        className={`confirm delete ${itemDeleting ? "loading" : ""}`}
        open={deleteItemConfirmAgain || itemDeleting}
        header={`Are you totally sure? All your ${label} content will be deleted!`}
        content="Just wanted to double-check! There's no way to recover deleted items."
        cancelButton="nevermind"
        confirmButton="yes, delete"
        onCancel={() => setDeleteItemConfirmAgain(false)}
        onConfirm={deleteItem}
      />

      <Confirm
        className="confirm progressive"
        open={archiveItemConfirm}
        header={`Are you sure you want to archive this ${label}?`}
        content={`Archived ${type} can be restored but only by you, the ${label} owner. This ${label} will be hidden to all other members.`}
        cancelButton="nevermind"
        confirmButton="yes, archive"
        onCancel={() => setArchiveItemConfirm(false)}
        onConfirm={archiveItem}
      />

      {itemDuplicating ? (
        <div className="item-duplicating item-duplicating">
          <img
            src={require("../../images/loading-md.svg")}
            alt={`duplicating ${label}`}
          />
          <h3>Duplicating {label}...</h3>
        </div>
      ) : null}

      {!demo ? (
        <Members
          type={type}
          closeManageUsersModal={() => setManageUsersModal(false)}
          manageUsersOpen={manageUsersModal}
          id={itemID}
          data={item}
          authUser={authUser}
          history={history}
        />
      ) : null}

      {!demo ? (
        <Share
          type={type}
          shareOpen={shareItemModal}
          closeShareModal={() => setShareItemModal(false)}
          id={itemID}
        />
      ) : null}

      <Modal className="item-deleted-modal" open={itemDeletedModal}>
        <Modal.Header>Sorry, this {label} doesn't exist</Modal.Header>
        <Modal.Content>
          <p>
            The url may be incorrect or it may have been deleted by the owner.
            You can return to the {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(`/${type}`)}
          >
            back to {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-removed-modal" open={itemRemovedModal}>
        <Modal.Header>Sorry, your {label} access was removed</Modal.Header>
        <Modal.Content>
          <p>
            Please contact the {label} owner for help. You can return to the{" "}
            {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(`/${type}`)}
          >
            back to {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-archived-modal" open={itemArchivedModal}>
        <Modal.Header>Sorry, the owner archived this {label}</Modal.Header>
        <Modal.Content>
          <p>
            Please contact the {label} owner for help. You can return to the{" "}
            {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(`/${type}`)}
          >
            back to {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-denied-modal" open={itemDeniedModal}>
        <Modal.Header>
          Sorry, you don't have access to this {label}
        </Modal.Header>
        <Modal.Content>
          <p>
            Please contact the {label} owner for help. You can return to the{" "}
            {type} page below.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(`/${type}`)}
          >
            back to {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal className="item-editing-modal" open={itemEditingModal}>
        <Modal.Header>
          Sorry, someone is already editing this {label}
        </Modal.Header>
        <Modal.Content>
          <p>
            It looks like{" "}
            {item.active && item.active.userName ? (
              <a
                href={`mailto:${item.active.userEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.active.userName}
              </a>
            ) : (
              "someone"
            )}{" "}
            is currently editing this {label}.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => history.push(`/${type}`)}
          >
            back to {type}
          </button>
        </Modal.Content>
      </Modal>

      <Modal
        className="help-modal"
        open={helpModal}
        onClose={() => setHelpModal(false)}
      >
        <div className="modal-close-wrap">
          <span className="modal-close" onClick={() => setHelpModal(false)}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        <Modal.Header>
          Tell us what's wrong and we'll get back to you as soon as possible.
        </Modal.Header>
        <Modal.Content>
          <ContactForm />
        </Modal.Content>
      </Modal>

      {demo ? (
        <Modal
          className="demo-modal"
          open={userSignupModal}
          onClose={() => setUserSignupModal(false)}
        >
          <div className="modal-close-wrap">
            <span
              className="modal-close"
              onClick={() => setUserSignupModal(false)}
            >
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Content>
            <span className="content-inline">
              <h2 className="centered">Sign up &ndash; Free forever</h2>
              <SignupForm
                item={item}
                itemTemplate={itemTemplate}
                type={type}
                label={label}
                history={history}
                userSignupType={userSignupType}
                setUserSignupModal={setUserSignupModal}
              />
            </span>
          </Modal.Content>
        </Modal>
      ) : null}

      {itemTemplate.version && itemTemplate.version === item.version ? (
        <span className="item-wrap">
          <div
            className="item-outer"
            onClickCapture={(event) => {
              if (
                itemEditingModal ||
                itemDeniedModal ||
                itemDeletedModal ||
                itemRemovedModal ||
                itemArchivedModal
              ) {
                event.stopPropagation();
              }
            }}
          >
            <Prompt
              message="Are you sure you want to leave? Changes have not been saved."
              when={!itemClose && checkItemChange() && !userSignupModal}
            />
            <form
              id="item-form"
              onSubmit={(event) => {
                event.preventDefault();
                saveItem("save", item, "none");
              }}
            >
              <section id="step-1" className="item-section item-name step">
                <div
                  className={`component-section-wrap ${
                    item.doneList && item.doneList.name ? "done" : ""
                  } ${!item.name ? "none-entered" : ""}`}
                >
                  <div className="component-section-inner">
                    <Input
                      id="item-name-input"
                      name="item-name"
                      value={item.name ? item.name : ""}
                      autoFocus={nameFocus}
                      onKeyUp={inputNameKeyPress}
                      placeholder={
                        window.innerWidth > 768
                          ? `Give your ${label} a name...`
                          : `${capString(label)} name...`
                      }
                      onChange={editItemName}
                    />
                  </div>
                  <div className="section-btn-done">
                    <div
                      className="btn btn-done"
                      onClick={() => doneStep("name", 1)}
                    >
                      <i className="icon icon-checkmark-circle" />
                    </div>
                  </div>
                </div>
              </section>

              {item.stepCount > 1 && item.content ? (
                <TextContent node={0} {...textContentProps} />
              ) : null}

              {itemTemplate.sections
                ? Object.keys(itemTemplate.sections).map(createItemLayout)
                : null}

              {item.stepCount > 1 && item.content ? (
                <TextContent node={1} {...textContentProps} />
              ) : null}

              {itemTemplate.themes ? (
                <Themes
                  step={Object.keys(itemTemplate.sections).length + 2}
                  type={type}
                  template={itemTemplate}
                  item={item}
                  setItem={setItem}
                />
              ) : null}
            </form>
            {demo ? (
              <a
                className="link-example btn btn-white"
                href={
                  type === "projects"
                    ? "https://www.designgapp.com/p/ph3ZuxXWO"
                    : type === "styleguides"
                    ? "https://www.designgapp.com/s/jOo2AZi1p"
                    : "https://www.designgapp.com/h/wy4k_3Sap"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                view a published {label} <i className="icon icon-arrow-left" />
              </a>
            ) : null}
          </div>

          {!demo ? (
            <ItemNav
              label={label}
              doneList={item.doneList}
              stepTotal={stepTotal}
              itemsLimit={itemsLimit}
              closeItem={closeItem}
              checkItemChange={checkItemChange}
              item={item}
              saveItem={saveItem}
              publishItem={publishItem}
              stepCount={item.stepCount}
              itemAdmin={item.admin}
              itemUser={authUser.uid}
              setItemUsersModal={setManageUsersModal}
              setShareItemModal={setShareItemModal}
              setHelpModal={setHelpModal}
              setArchiveItemConfirm={setArchiveItemConfirm}
              setDeleteItemConfirm={setDeleteItemConfirm}
              duplicateItem={duplicateItem}
            />
          ) : (
            <nav
              className={`item-edit-nav ${
                item.stepCount === stepTotal ? "publish" : ""
              }`}
            >
              <div className="item-nav-btns">
                <div className="item-publish">
                  {item.stepCount === stepTotal ? (
                    <span>
                      <button
                        className={`btn btn-success btn-publish ${
                          !item.doneList.publish ? "unpublished" : ""
                        }`}
                        onClick={() => {
                          setUserSignupModal(true);
                          setUserSignupType("publish");
                        }}
                      >
                        <span className="btn-publish-text">
                          <i className="icon icon-rocket" />
                          <span className="publish-text">
                            sign up to publish &mdash; it's free!
                          </span>
                        </span>
                      </button>
                      <Popup
                        hoverable
                        hideOnScroll
                        position="top right"
                        className="popup-help publish"
                        trigger={
                          <div className="publish-help">
                            why ask for a signup?
                          </div>
                        }
                        header={
                          <p>
                            We need to save your project in order to publish and
                            we'll need to associate it with an account so we
                            don't lose your work.
                            <br />
                            <br />
                            <strong>
                              You can easily delete your account at any time.
                            </strong>
                          </p>
                        }
                      />
                    </span>
                  ) : null}
                </div>
                <div className="item-close">
                  <div
                    className="item-nav-trigger"
                    onClick={() => history.push(routes.Demo)}
                  >
                    <i className="icon icon-arrow-left" />
                    <span className="item">exit demo</span>
                  </div>
                </div>
                {item.stepCount > 2 ? (
                  <button
                    className="btn btn-text btn-signup"
                    onClick={() => {
                      setUserSignupModal(true);
                      setUserSignupType("save");
                    }}
                  >
                    sign up to save this {label}
                  </button>
                ) : null}
              </div>
              <div className={`item-progress step-${item.stepCount}`}>
                <span
                  className={`progress-bar positive done-${Math.round(
                    (item.stepCount / stepTotal) * 100
                  )}`}
                >
                  <span className="progress-bar-fill" />
                </span>
              </div>
            </nav>
          )}
        </span>
      ) : (
        <div className="content-loading no-header" key="loading" />
      )}
      <ReactBody className={label} />
    </section>
  );
};

export default ItemEdit;
