import React from "react";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import ItemEdit from "../shared/ItemEdit";
import withAuthorization from "components/withAuthorization";

export const StyleguideEdit = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const userMembership = useSelector(
    (state) => state.sessionState.userMembership
  );

  return (
    <ItemEdit
      history={props.history}
      type={"styleguides"}
      label={"styleguide"}
      authUser={authUser}
      userMembership={userMembership}
      itemID={props.match.params.styleguideID}
    />
  );
};

const authCondition = (authUser) => !!authUser;

export default compose(withAuthorization(authCondition))(StyleguideEdit);
