import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";

const logger = (api) => (next) => {
  return (action) => {
    const result = next(action);
    return result;
  };
};

const createStoreWithMiddleware = applyMiddleware(logger)(createStore);
const store = createStoreWithMiddleware(rootReducer);

export default store;
