import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import {
  ItemColor,
  ItemBoxShadow,
  ItemFontSize,
  ItemPadding,
  ItemBorderStyle,
  ItemBorderWidth,
  ItemBorderRadius,
  ItemDecoration,
  ItemCSS,
} from "../Elements";

export const DropdownsElement = (props) => {
  const {
    sections,
    borderStyles,
    findFontVal,
    findColorVal,
    findStyleVal,
    updateColor,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, palette },
  } = props;

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontSizeProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  const borderProps = {
    elements: elements,
    updateStyle: updateStyle,
    borderStyles: borderStyles,
  };

  const boxShadowProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateStyle: updateStyle,
    updateColor: updateColor,
  };

  return (
    <div
      id="dropdown-element"
      className={`element element-dropdowns ${
        sections.dropdowns.active ? "active" : ""
      } ${sections.dropdowns.css ? "css" : ""} ${
        elements.dropdownMenu.disabled ? "disabled" : ""
      }`}
    >
      <div
        className="element-content"
        onClick={() => toggleSection("dropdowns", true)}
      >
        <div className="dropdown-group">
          <style>
            {`
              #elements #dropdown-element .element-content .dropdown .menu {
                background: ${findColorVal("dropdownMenu", "background")};
                border-radius: ${elements.dropdownMenu.borderRadius};
                box-shadow: ${elements.dropdownMenu.boxShadow} ${findColorVal(
              "dropdownMenu",
              "boxShadowColor"
            )};
              }
              #elements #dropdown-element .element-content .dropdown .menu:before {
                border-bottom-color: ${findColorVal(
                  "dropdownMenu",
                  "background"
                )};
              }
              #elements #dropdown-element .element-content .dropdown .trigger {
                background: ${findColorVal("dropdownButton", "background")};
                border-color: ${findColorVal("dropdownButton", "borderColor")};
                border-radius: ${elements.dropdownButton.borderRadius};
                border-style: ${elements.dropdownButton.borderStyle};
                border-width: ${elements.dropdownButton.borderWidth};
                box-shadow: ${elements.dropdownButton.boxShadow} ${findColorVal(
              "dropdownButton",
              "boxShadowColor"
            )};
                color: ${findColorVal("dropdownButton", "color")};
                padding: ${elements.dropdownButton.padding};
              }
              #elements #dropdown-element .element-content .dropdown .trigger:hover {
                box-shadow: ${elements.buttonsHover.boxShadow} ${findColorVal(
              "dropdownButtonHover",
              "boxShadowColor"
            )};
              }
              #elements #dropdown-element .element-content .dropdown .trigger:active, #elements #dropdown-element .element-content .dropdown .trigger.active {
                box-shadow: ${elements.buttonsActive.boxShadow} ${findColorVal(
              "dropdownButtonActive",
              "boxShadowColor"
            )};
              }
              #elements #dropdown-element .element-content .dropdown .item .link {
                background: ${findColorVal("dropdownItem", "background")};
                color: ${findColorVal("dropdownItem", "color")};
                font-family: ${findFontVal("buttons", "fontFamily")};
                font-size: ${elements.dropdownItem.fontSize};
                font-weight: ${elements.buttons.fontWeight};
                padding: ${elements.dropdownItem.padding};
                text-decoration: ${elements.dropdownItem.textDecoration};
                transition: background ease 0.33s, color ease 0.33s;
              }
              #elements #dropdown-element .element-content .dropdown .item:hover .link, #elements #dropdown-element .element-content .dropdown .item.hover .link {
                background: ${findColorVal("dropdownItemHover", "background")};
                color: ${findColorVal("dropdownItemHover", "color")};
                text-decoration: ${elements.dropdownItemHover.textDecoration};
              }
            `}
          </style>

          <Menu>
            <Dropdown
              item
              onClick={() => toggleSection("dropdowns", true)}
              trigger={
                <button
                  className="btn btn-white trigger"
                  onClick={(event) => event.preventDefault()}
                >
                  Dropdown <i className="icon icon-chevron-down" />
                </button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item One</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Two</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Three</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Four</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>

          <Menu>
            <Dropdown
              item
              disabled
              className="active"
              trigger={
                <button
                  className="btn btn-white trigger active"
                  onClick={(event) => event.preventDefault()}
                >
                  Active dropdown <i className="icon icon-chevron-down" />
                </button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item One</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Two</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Three</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Four</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>

          <Menu>
            <Dropdown
              item
              disabled
              className="active hover"
              trigger={
                <button
                  className="btn btn-white trigger active"
                  onClick={(event) => event.preventDefault()}
                >
                  Hovered dropdown <i className="icon icon-chevron-down" />
                </button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item One</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item hover">
                  <span className="link">Dropdown Item Two</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Three</span>
                </Dropdown.Item>
                <Dropdown.Item className="menu-item">
                  <span className="link">Dropdown Item Four</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>
        </div>
      </div>

      {sections.dropdowns.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Dropdown button styles</label>

            <div className="edit-item-sub">
              <ItemPadding item="dropdownButton" {...defaultProps} />

              <ItemColor
                type="color"
                item="dropdownButton"
                label="font color"
                {...colorProps}
              />

              <ItemColor
                type="background"
                item="dropdownButton"
                label="bg color"
                {...colorProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemBorderStyle item="dropdownButton" {...borderProps} />

              <ItemBorderWidth item="dropdownButton" {...defaultProps} />

              <ItemBorderRadius item="dropdownButton" {...defaultProps} />

              <ItemColor
                type="borderColor"
                item="dropdownButton"
                label="border color"
                {...colorProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemBoxShadow
                item={"dropdownButton"}
                label={"box shadow"}
                {...boxShadowProps}
              />

              <ItemBoxShadow
                item={"dropdownButtonHover"}
                label={"hover shadow"}
                {...boxShadowProps}
              />

              <ItemBoxShadow
                item={"dropdownButtonActive"}
                label={"active shadow"}
                {...boxShadowProps}
              />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Dropdown menu styles</label>

            <div className="edit-item-sub">
              <ItemColor
                type="background"
                item="dropdownMenu"
                label="bg color"
                {...colorProps}
              />

              <ItemBorderRadius item="dropdownMenu" {...defaultProps} />

              <ItemBoxShadow
                item={"dropdownMenu"}
                label={"box shadow"}
                {...boxShadowProps}
              />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Dropdown item styles</label>

            <div className="edit-item-sub">
              <ItemFontSize item={"dropdownItem"} {...fontSizeProps} />

              <ItemPadding item="dropdownItem" {...defaultProps} />

              <ItemColor
                type="color"
                item="dropdownItem"
                label="font color"
                {...colorProps}
              />

              <ItemColor
                type="background"
                item="dropdownItem"
                label="bg color"
                {...colorProps}
              />

              <ItemDecoration item="dropdownItem" {...defaultProps} />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Dropdown item hover styles</label>

            <div className="edit-item-sub">
              <ItemColor
                type="color"
                item="dropdownItemHover"
                label="hover font"
                {...colorProps}
              />

              <ItemColor
                type="background"
                item="dropdownItemHover"
                label="hover bg"
                {...colorProps}
              />

              <ItemDecoration item="dropdownItemHover" {...defaultProps} />
            </div>
          </div>
        </div>
      ) : null}
      {sections.dropdowns.active ? (
        <div
          className="edit-element-btn"
          onClick={() => toggleCSS("dropdowns")}
        >
          <span>{sections.dropdowns.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.dropdowns.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.dropdownButton}
              label={`.dropdown button`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.dropdownButtonHover}
              label={`.dropdown button:hover`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.dropdownButtonActive}
              label={`.dropdown button:active`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.dropdownMenu}
              label={`.dropdown .menu`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.dropdownItem}
              label={`.dropdown .item`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.dropdownItemHover}
              label={`.dropdown .item:hover`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("dropdowns")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
