import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";

const ItemNav = ({
  label,
  doneList,
  stepTotal,
  itemsLimit,
  closeItem,
  checkItemChange,
  item,
  saveItem,
  publishItem,
  setItemUsersModal,
  stepCount,
  itemAdmin,
  itemUser,
  setShareItemModal,
  setHelpModal,
  setArchiveItemConfirm,
  setDeleteItemConfirm,
  duplicateItem,
}) => (
  <nav className={`item-edit-nav ${stepCount === stepTotal ? "publish" : ""}`}>
    <div className="item-nav-btns">
      <div className="item-publish">
        {stepCount === stepTotal ? (
          <button
            className={`btn btn-success btn-publish ${
              !doneList.publish ? "unpublished" : ""
            }`}
            onClick={publishItem}
          >
            {!doneList.publish ? (
              <span className="btn-publish-text">
                <i className="icon icon-rocket" />
                <span className="publish-text">publish {label}</span>
              </span>
            ) : (
              <span className="btn-publish-text">
                <i className="icon icon-rocket" />
                <span className="publish-text">view {label}</span>
              </span>
            )}
          </button>
        ) : null}
      </div>
      <div className="item-close">
        {checkItemChange() ? (
          <div className="item-nav-trigger" onClick={() => closeItem("save")}>
            <i className="icon icon-arrow-left" />
            <span className="item">save &amp; back</span>
          </div>
        ) : (
          <div className="item-nav-trigger" onClick={() => closeItem("back")}>
            <i className="icon icon-arrow-left" />
            <span className="item">back</span>
          </div>
        )}
      </div>
      <div className="item-menu">
        <Menu>
          <Dropdown
            trigger={
              <div className="item-nav-trigger">
                <span className="item">menu</span>
                <i className="icon icon-ellipsis" />
              </div>
            }
          >
            <Dropdown.Menu>
              <span
                className="link item-menu-item menu-item"
                onClick={() => closeItem("back")}
              >
                <i className="icon icon-arrow-left" />
                {`${label}s`}
              </span>
              <span
                className="link item-menu-item menu-item"
                onClick={() => saveItem("save", item, "none")}
              >
                <i className="icon icon-checkmark-circle" />
                save
              </span>
              <span
                className="link item-menu-item menu-item"
                onClick={() => setItemUsersModal(true)}
              >
                <i className="icon icon-users" />
                members
              </span>
              {doneList.publish ? (
                <span
                  className="link item-menu-item menu-item item-share"
                  onClick={() => setShareItemModal(true)}
                >
                  <i className="icon icon-exit-up" />
                  <span className="menu-text">share</span>
                </span>
              ) : null}
              <span
                className="link item-menu-item menu-item"
                onClick={() => setHelpModal(true)}
              >
                <i className="icon icon-question-circle" />
                help
              </span>
              <div className="item-menu-admin">
                {!itemsLimit ? (
                  <span
                    className="link item-menu-item menu-item item-duplicate"
                    onClick={duplicateItem}
                  >
                    <i className="icon icon-copy" />
                    duplicate
                  </span>
                ) : null}
                {itemAdmin === itemUser ? (
                  <span>
                    <span
                      className="link item-menu-item menu-item item-archive"
                      onClick={() => setArchiveItemConfirm(true)}
                    >
                      <i className="icon icon-archive" />
                      archive
                    </span>
                    <span
                      className="link item-menu-item menu-item item-delete"
                      onClick={() => setDeleteItemConfirm(true)}
                    >
                      <i className="icon icon-trash" />
                      delete
                    </span>
                  </span>
                ) : null}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </div>
      <div className="item-toast">
        <div className="toast-saved">
          <p>
            <i className="icon icon-checkmark-circle" />
            {label} saved
          </p>
        </div>
      </div>
      <div className={`item-progress step-${stepCount}`}>
        <span
          className={`progress-bar positive done-${Math.round(
            (stepCount / stepTotal) * 100
          )}`}
        >
          <span className="progress-bar-fill" />
        </span>
      </div>
    </div>
  </nav>
);

export default ItemNav;
