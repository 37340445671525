import React from "react";
import FilesList from "./FilesList";
import TextContent from "./TextContent";
import { headerText, helpText } from "./Resources";
import { Popup } from "semantic-ui-react";

export const Files = (props) => {
  const {
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { files, doneList },
    setItem,
    saveItem,
  } = props;

  const editFiles = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        files: false,
      },
    });
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "files",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.files ? "done" : ""
      } ${files.length === 0 ? "none-selected" : ""}`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.files[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.files[label]}</p>}
          />
        </h2>
        <TextContent node={0} {...textContentProps} />
        <div className="item-files-list">
          <FilesList {...props} />
        </div>
        <p className="section-empty">No files added, click here to add them.</p>
        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={() => doneStep("files", step)}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editFiles}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default Files;
