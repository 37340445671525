const INITIAL_STATE = {
  authUser: null,
  userImg: "",
  userName: "",
  userMembership: null,
  googleFontsList: [],
};

const setAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
});

const setUserImg = (state, action) => ({
  ...state,
  userImg: action.img,
});

const setUserName = (state, action) => ({
  ...state,
  userName: action.name,
});

const setUserSignup = (state, action) => ({
  ...state,
  userSignup: action.userSignup,
});

const setUserMembership = (state, action) => ({
  ...state,
  userMembership: action.membership,
});

const setGoogleFontsList = (state, action) => ({
  ...state,
  googleFontsList: action.googleFontsList,
});

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "AUTH_USER": {
      return setAuthUser(state, action);
    }
    case "USER_IMG": {
      return setUserImg(state, action);
    }
    case "USER_NAME": {
      return setUserName(state, action);
    }
    case "USER_SIGNUP": {
      return setUserSignup(state, action);
    }
    case "USER_MEMBERSHIP": {
      return setUserMembership(state, action);
    }
    case "GOOGLE_FONTS_LIST": {
      return setGoogleFontsList(state, action);
    }
    default:
      return state;
  }
};

export default sessionReducer;
