import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth, db, firebase } from "../../firebase";
import * as routes from "constants/routes";
import { PasswordForgetLink } from "./PasswordForget";
import LoginGoogle from "./LoginGoogle";
import LogoutButton from "components/users/Logout";
import ReactBody from "react-body";
import { Input } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { inFrame } from "../shared/Resources";

export const Login = (props) => {
  const { match, saveEmail, deleteUserConfirm, changePassword, close } = props;

  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <section
      id="login"
      className="content-outer"
      onLoad={window.scrollTo(0, 0)}
    >
      <Helmet>
        <title>DesignGapp - Login</title>
        <meta name="description" content="Login to DesignGapp." />
      </Helmet>
      {!authUser ? (
        <div>
          <h1>Log in</h1>
          <LoginForm
            history={props.history}
            action={"login"}
            match={match}
            saveEmail={saveEmail}
            deleteUserConfirm={deleteUserConfirm}
            changePassword={changePassword}
            close={close}
          />
          <SignupLink />
        </div>
      ) : authUser ? (
        <LoggedInMsg />
      ) : (
        <div className="content-loading no-header" />
      )}
      <ReactBody className="login" />
    </section>
  );
};

const LoginForm = (props) => {
  const {
    history,
    action,
    match,
    saveEmail,
    deleteUserConfirm,
    changePassword,
    close,
  } = props;

  const authUser = useSelector((state) => state.sessionState.authUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [userLoginState, setUserLoginState] = useState(false);

  useEffect(() => {
    if (inFrame()) {
      history.push(routes.IframeError);
    }
  }, [history]);

  const loginRedirect = (email) => {
    if (history.location.search) {
      history.push(history.location.search.replace("?", ""));
    } else if (email) {
      db.lookupUserByEmail(email).then((user) => {
        // console.log('USER: ', user)

        const userID = Object.keys(user.val())[0];
        const userData = user.val()[userID];
        if (userData.verified === "new user") {
          history.push(routes.Account);
        } else if (userData.updateData) {
          // TODO: This should go to a welcome page? No... probably whatever was updated last?
          userData.updateData.type === "project" && userData.projects
            ? history.push(routes.Projects)
            : userData.updateData.type === "styleguide" && userData.styleguides
            ? history.push(routes.Styleguides)
            : history.push(routes.Showcases);
        } else {
          !userData.styleguides && userData.projects
            ? history.push(routes.Projects)
            : userData.styleguides && !userData.projects
            ? history.push(routes.Styleguides)
            : history.push(routes.Showcases);
        }
      });
    } else {
      history.push(routes.Projects);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setUserLoginState(true);
    if (
      (match.path === routes.Account &&
        email.toLowerCase() === authUser.email) ||
      match.path === routes.Login
    ) {
      if (match.path === routes.Account) {
        const user = firebase.auth.currentUser;
        const credential = EmailAuthProvider.credential(email, password);
        reauthenticateWithCredential(user, credential)
          .then(() => {
            setEmail("");
            setPassword("");
            setError(null);
            if (action === "email") {
              saveEmail();
            } else if (action === "account") {
              deleteUserConfirm();
            } else if (action === "password") {
              changePassword();
            }
            close();
          })
          .catch((error) => {
            setError(error);
            setUserLoginState(false);
          });
      } else {
        auth
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            setEmail("");
            setPassword("");
            setError(null);
            loginRedirect(email);
          })
          .catch((error) => {
            setError(error);
            setUserLoginState(false);
          });
      }
    } else {
      setError({
        code: "auth/wrong-email",
        message: "That email doesn't match your current login",
      });
      setUserLoginState(false);
    }
  };

  const isInvalid = password === "" || email === "";

  return (
    <form id="form-login" onSubmit={onSubmit}>
      <Input
        type="email"
        autoFocus={window.innerWidth > 1024 ? true : false}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Email"
      />
      <Input
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        placeholder="Password"
      />
      <button className="btn btn-primary" disabled={isInvalid} type="submit">
        {!userLoginState ? (
          <span>log in</span>
        ) : (
          <span className="btn-loading" />
        )}
      </button>
      <PasswordForgetLink />
      {error && <p className="error">{error.message}</p>}
      <LoginGoogle
        history={history}
        action={action}
        saveEmail={saveEmail}
        deleteUserConfirm={deleteUserConfirm}
        changePassword={changePassword}
        close={close}
        loginRedirect={loginRedirect}
      />
    </form>
  );
};

const SignupLink = () => (
  <Link className="auth-bottom-link" to="/signup">
    Don't have an account yet? Sign up now.
  </Link>
);

const LoggedInMsg = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <div className="msg-logged-in">
      {authUser ? (
        <span>
          <h1>
            You're already logged in, <br />
            please log out first.
          </h1>
          <div className="btn-row">
            <LogoutButton />
          </div>
        </span>
      ) : (
        <div className="content-loading no-header" />
      )}
    </div>
  );
};

export default compose(Login);

export { LoginForm };
