import React from "react";
import tinycolor from "tinycolor2";
import { Popup } from "semantic-ui-react";
import { findTextColor, colorPercents, rgb2cmyk } from "../shared/Resources";

export const PaletteDisplay = (props) => {
  const { name, color } = props;
  const colorRGB = tinycolor(color).toRgb();
  const colorCMYK = rgb2cmyk(colorRGB.r, colorRGB.g, colorRGB.b);
  const colorCMYKString = `cmyk(${colorCMYK.c}%, ${colorCMYK.m}%, ${colorCMYK.y}%, ${colorCMYK.k}%)`;

  return (
    <span
      className={`color ${findTextColor(color, "small")}`}
      style={{ background: color }}
      alt={name ? name : "Untitled"}
    >
      <label title={name ? name : "Untitled"}>{name ? name : "Untitled"}</label>
      <span className="color-hex">{color}</span>
      <span className="color-rgb">{tinycolor(color).toRgbString()}</span>
      <span className="color-hsl">{tinycolor(color).toHslString()}</span>
      <span className="color-cmyk">{colorCMYKString}</span>
      <div className="color-vars">
        {colorPercents.map((percent, index) => {
          const percentHexString = tinycolor(color)
            .lighten(100 - percent)
            .toHexString();
          const percentRGBString = tinycolor(color)
            .lighten(100 - percent)
            .toRgbString();
          const percentHSLString = tinycolor(color)
            .lighten(100 - percent)
            .toHslString();
          const percentRGB = tinycolor(color)
            .lighten(100 - percent)
            .toRgb();
          const percentCMYK = rgb2cmyk(
            percentRGB.r,
            percentRGB.g,
            percentRGB.b
          );
          const percentCMYKString = `cmyk(${percentCMYK.c}%, ${percentCMYK.m}%, ${percentCMYK.y}%, ${percentCMYK.k}%)`;
          if (
            percentHexString !== "#ffffff" &&
            percentHexString !== "#000000"
          ) {
            return (
              <Popup
                key={percent}
                flowing
                hoverable
                position={"top center"}
                className={`color-vars-details ${name ? name : "untitled"}`}
                trigger={
                  <span
                    className="color-var"
                    style={{
                      backgroundColor: tinycolor(color)
                        .lighten(100 - percent)
                        .toHexString(),
                    }}
                  />
                }
                header={
                  <h5>
                    {name ? name : "Untitled"} at {percent}%
                  </h5>
                }
                content={
                  <p>
                    <span className="color-var-detail">{percentHexString}</span>
                    <span className="color-var-detail">{percentRGBString}</span>
                    <span className="color-var-detail">{percentHSLString}</span>
                    <span className="color-var-detail">
                      {percentCMYKString}
                    </span>
                  </p>
                }
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </span>
  );
};
