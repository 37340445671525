import React from "react";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import Item from "../shared/Item";
import withAuthorization from "components/withAuthorization";

export const Persona = (props) => {
  const authUser = useSelector((state) => state.sessionState.authUser);

  return (
    <Item
      history={props.history}
      type={"personas"}
      label={"persona"}
      authUser={authUser}
      itemID={props.match.params.personaID}
    />
  );
};

const authCondition = (authUser) => !!authUser;

export default compose(withAuthorization(authCondition))(Persona);
