import React, { useState, useEffect } from "react";
import { Checkbox, Popup, Sidebar, Menu, Dropdown } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import TextareaAutosize from "react-textarea-autosize";
import Sticky from "react-sticky-el";

export const ItemSettings = (props) => {
  const {
    template,
    settings,
    theme,
    drawerActive,
    setDrawerActive,
    updateSettings,
    saveTheme,
  } = props;

  const [orderSorting, setOrderSorting] = useState(false);
  const [swipe, setSwipe] = useState({});
  const [saveStatus, setSaveStatus] = useState("");

  useEffect(() => {
    if (saveStatus === "saving") {
      setTimeout(() => {
        setSaveStatus("saved");
        setTimeout(() => {
          setSaveStatus("");
        }, 1000);
      }, 500);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (drawerActive) {
      document.body.classList.add("drawer-open");
    } else {
      document.body.classList.remove("drawer-open");
    }
  }, [drawerActive]);

  const onSortStart = () => {
    setOrderSorting(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    settings.order = arrayMove(settings.order, oldIndex, newIndex);
    updateSettings(settings);
    setOrderSorting(false);
  };

  const toggleTouchStart = (event) => {
    const touch = event.changedTouches[0];
    setSwipe({ x: touch.clientX });
  };

  const toggleTouchMove = (event) => {
    if (event.changedTouches && event.changedTouches.length) {
      setSwipe({ x: swipe.x, swiping: true });
    }
  };

  const toggleTouchEnd = (event, section) => {
    const touch = event.changedTouches[0];
    const absX = Math.abs(touch.clientX - swipe.x);
    if (absX > 25) {
      settingToggle(section);
    }
    setSwipe({});
  };

  const settingToggle = (section) => {
    const settingsCopy = { ...settings };
    settingsCopy[section] = !settings[section];
    updateSettings(settingsCopy);
  };

  const updateCSS = (code) => {
    const settingsCopy = { ...settings };
    settingsCopy.css = code;
    updateSettings(settingsCopy, true);
  };

  const saveCSS = () => {
    setSaveStatus("saving");
    updateSettings(settings);
  };

  const createSettingLayout = (section) => {
    switch (section) {
      case "order":
        return settings.orderDisabled.length !== settings.order.length ? (
          <div
            className={`item-setting setting-order ${
              orderSorting ? "sorting" : ""
            }`}
            key="order"
          >
            <label>
              Section Order
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>The order of the sections on the published page.</span>
                }
              />
            </label>
            <SortableList
              sections={settings.order}
              disabled={settings.orderDisabled}
              helperClass={"order-drag"}
              distance={5}
              axis={"y"}
              onSortStart={({ node, index, collection }, event) => {
                event.preventDefault();
                onSortStart();
              }}
              onSortEnd={onSortEnd}
            />
          </div>
        ) : null;
      case "accessibility":
        return (
          <div
            className="item-setting setting-accessibility"
            key="accessibility"
          >
            <label>
              Color accessibility
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>Show WCAG contrast checker with color alternates.</span>
                }
              />
            </label>
            <div className="settings-toggle">
              <Checkbox
                toggle
                checked={settings[section] === true}
                onChange={() => settingToggle(section)}
                onTouchStart={toggleTouchStart}
                onTouchMove={toggleTouchMove}
                onTouchEnd={(event) => toggleTouchEnd(event, section)}
              />
            </div>
          </div>
        );
      case "parallax":
        return (
          <div className="item-setting setting-parallax" key="parallax">
            <label>
              Parallax header
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>The three-dimension scroll effect on the header.</span>
                }
              />
            </label>
            <div className="settings-toggle">
              <Checkbox
                toggle
                checked={settings[section] === true}
                onChange={() => settingToggle(section)}
                onTouchStart={toggleTouchStart}
                onTouchMove={toggleTouchMove}
                onTouchEnd={(event) => toggleTouchEnd(event, section)}
              />
            </div>
          </div>
        );
      case "icons":
        return (
          <div className="item-setting setting-icons" key="icons">
            <label>
              Include Icons
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>The icon graphics in each section of the project.</span>
                }
              />
            </label>
            <div className="settings-toggle">
              <Checkbox
                toggle
                checked={settings[section] === true}
                onChange={() => settingToggle(section)}
                onTouchStart={toggleTouchStart}
                onTouchMove={toggleTouchMove}
                onTouchEnd={(event) => toggleTouchEnd(event, section)}
              />
            </div>
          </div>
        );
      case "paletteHeader":
        return (
          <div
            className="item-setting setting-paletteHeader"
            key="paletteHeader"
          >
            <label>
              Header Palette
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>
                    Toggle the styleguide color blocks at the top of the page.
                  </span>
                }
              />
            </label>
            <div className="settings-toggle">
              <Checkbox
                toggle
                checked={settings[section] === true}
                onChange={() => settingToggle(section)}
                onTouchStart={toggleTouchStart}
                onTouchMove={toggleTouchMove}
                onTouchEnd={(event) => toggleTouchEnd(event, section)}
              />
            </div>
          </div>
        );
      case "css":
        return (
          <div className="item-setting setting-css" key="css">
            <label>
              Custom CSS
              <Popup
                key={section}
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={
                  <span>Include you own css to customize the design.</span>
                }
              />
            </label>
            <TextareaAutosize
              value={settings.css}
              className="css-edit"
              placeholder="Enter your own CSS..."
              onKeyDown={(event) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  document.execCommand("insertText", false, "\t");
                }
              }}
              onChange={(event) => updateCSS(event.target.value)}
            />
            <Sticky
              className="sticky-holder"
              positionRecheckInterval={1}
              mode="bottom"
            >
              <button
                className={`btn btn-success ${saveStatus}`}
                onClick={saveCSS}
                disabled={saveStatus === "saving" || saveStatus === "saved"}
              >
                {saveStatus === "saving" ? (
                  <div className="inline-loading" />
                ) : saveStatus === "saved" ? (
                  "saved!"
                ) : (
                  "save css"
                )}
              </button>
            </Sticky>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div id="settings" className="item-settings-menu">
      <Sidebar
        as={Menu}
        onHide={() => (window.innerWidth < 768 ? setDrawerActive(false) : null)}
        // onHide={() => setDrawerActive(false)}
        vertical
        visible={drawerActive}
      >
        <div className="item-settings">
          <h5>
            <i className="icon icon-equalizer" />
            Publish Settings
          </h5>

          <div className="item-setting setting-theme" key="theme">
            <label>
              Theme
              <Popup
                key="theme"
                hoverable
                className="popup-settings"
                position="top center"
                trigger={<i className="icon icon-question-circle" />}
                content={<span>Choose the overall design theme.</span>}
              />
            </label>
            <Dropdown
              selection
              placeholder="Choose theme"
              value={theme}
              onChange={(event, data) => saveTheme(data.value)}
              selectOnBlur={false}
              options={[
                { key: "none", value: null, text: "Choose theme" },
                { key: "light", value: "light", text: "Light" },
                { key: "dark", value: "dark", text: "Dark" },
              ]}
            />
          </div>

          {settings
            ? Object.keys(settings)
                .sort(
                  (a, b) =>
                    Object.keys(template.settings).indexOf(a) -
                    Object.keys(template.settings).indexOf(b)
                )
                .map(createSettingLayout)
            : null}
        </div>
        <div className="item-nav-close" onClick={() => setDrawerActive(false)}>
          <i className="icon icon-cross" />
        </div>
      </Sidebar>
    </div>
  );
};

const SortableList = SortableContainer(({ sections, disabled }) => (
  <ul className="order-list sortable">
    {sections.map((section, index) =>
      disabled.indexOf(section) === -1 ? (
        <SortableItem
          key={section}
          index={index}
          section={section}
          fileContent={<div className="section-content" />}
        />
      ) : null
    )}
  </ul>
));

const SortableItem = SortableElement(({ section }) => (
  <li className="order-list-item">
    <span className="item-reorder" />
    {section}
  </li>
));
