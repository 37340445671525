import React from "react";
import {
  ItemColor,
  ItemFont,
  ItemFontSize,
  ItemFontStyle,
  ItemLineHeight,
  ItemCSS,
} from "../Elements";

export const ParagraphElement = (props) => {
  const {
    sections,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateFont,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, fonts, palette },
  } = props;

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  return (
    <div
      id="paragraph-element"
      className={`element element-paragraph ${
        sections.p.active ? "active" : ""
      } ${sections.p.css ? "css" : ""} ${
        elements.p.disabled ? "disabled" : ""
      }`}
    >
      <div className="element-content" onClick={() => toggleSection("p", true)}>
        <div className="text-group">
          <style>
            {`
              #elements p, .text-content p, .text-content li {
                color: ${findColorVal("p", "color")};
                font-family: ${findFontVal("p", "fontFamily")};
                font-size: ${elements.p.fontSize};
                font-style: ${elements.p.fontStyle};
                font-weight: ${elements.p.fontWeight};
                line-height: ${elements.p.lineHeight};
              }
            `}
          </style>
          <p>
            <strong>Paragraph: </strong>I wandered lonely as a cloud That floats
            on high o'er vales and hills, When all at once I saw a crowd, A
            host, of golden daffodils; Beside the lake, beneath the trees,
            Fluttering and dancing in the breeze. Continuous as the stars that
            shine And twinkle on the milky way, They stretched in never-ending
            line Along the margin of a bay: Ten thousand saw I at a glance,
            Tossing their heads in sprightly dance.
          </p>
        </div>
      </div>

      {sections.p.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Paragraph font and color</label>

            <div className="edit-item-sub">
              <ItemFont item={"p"} {...fontProps} />

              <ItemFontStyle item={"p"} {...fontStyleProps} />

              <ItemFontSize item={"p"} {...defaultProps} />

              <ItemLineHeight item={"p"} {...defaultProps} />

              <ItemColor
                type="color"
                item="p"
                label="font color"
                {...colorProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.p.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("p")}>
          <span>{sections.p.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.p.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.p}
              label={`p`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("p")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
