import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import * as routes from "constants/routes";

const ItemInviteCard = ({
  item,
  type,
  label,
  itemsLimit,
  itemInviteAction,
  itemInviteStatus,
  itemInviteKey,
}) => (
  <div className="item-card item-invite">
    {type === "styleguides" ? (
      <StyleguideInviteContent styleguide={item.val()} />
    ) : (
      <ProjectInviteContent project={item.val()} />
    )}
    <h3>{item.val().name}</h3>
    {!itemsLimit ? (
      <div className="item-card-btn" disabled={itemInviteStatus !== ""}>
        <button
          className="btn btn-success"
          onClick={() => itemInviteAction("confirm", item.key)}
        >
          {itemInviteStatus === "joining" && itemInviteKey === item.key ? (
            <span className="btn-loading" />
          ) : (
            <span>join {label}</span>
          )}
        </button>
      </div>
    ) : (
      <div className="item-card-btn">
        <Link className="btn btn-secondary" to={routes.AccountUpgrade}>
          upgrade to join
        </Link>
      </div>
    )}
    <div className="item-card-btn" disabled={itemInviteStatus !== ""}>
      <button
        className="btn btn-text dark"
        onClick={() => itemInviteAction("delete", item.key)}
      >
        {itemInviteStatus === "declining" && itemInviteKey === item.key ? (
          <span className="btn-loading" />
        ) : (
          <span>decline invitation</span>
        )}
      </button>
    </div>
  </div>
);

const StyleguideInviteContent = ({ styleguide }) => (
  <div className="item-content">
    <div className={`item-logos ${!styleguide.logos ? "no-logo" : ""}`}>
      {styleguide.logos ? (
        <ProgressiveImage
          placeholder={require("../../images/loading-sm.svg")}
          onError={(error) => console.log("ERROR - Load Image", error)}
          src={styleguide.logos[0].downloadURL}
          key={styleguide.logos[0].downloadURL}
        >
          {(src, loading) => (
            <div
              className={`item-logo progressive ${
                loading ? "loading" : "done"
              } ${styleguide.logos[0].color ? styleguide.logos[0].color : ""}`}
            >
              <img src={src} alt="Primary Logo" />
            </div>
          )}
        </ProgressiveImage>
      ) : (
        <span className="item-logo-empty">
          <i className="icon icon-picture" />
        </span>
      )}
    </div>
    {styleguide.fonts ? (
      <div className={`item-fonts`}>
        {styleguide.fonts[0] ? (
          <div
            className="item-font"
            style={{ fontFamily: `"${styleguide.fonts[0].family}"` }}
          >
            <p>Aa</p>
          </div>
        ) : null}
        {styleguide.fonts[1] ? (
          <div
            className="item-font"
            style={{ fontFamily: `"${styleguide.fonts[0].family}"` }}
          >
            <p>Aa</p>
          </div>
        ) : null}
      </div>
    ) : null}
    <div className="item-palette">
      {styleguide.palette && styleguide.palette.colors ? (
        <div className="palette">
          <div
            className="color color-primary"
            style={{ backgroundColor: styleguide.palette.colors.primary }}
          >
            <span className="hex">{styleguide.palette.colors.primary}</span>
          </div>
          <div
            className="color color-secondary"
            style={{ backgroundColor: styleguide.palette.colors.secondary }}
          >
            <span className="hex">{styleguide.palette.colors.secondary}</span>
          </div>
          <div
            className="color color-accent"
            style={{ backgroundColor: styleguide.palette.colors.accent }}
          >
            <span className="hex">{styleguide.palette.colors.accent}</span>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

const ProjectInviteContent = ({ project }) => (
  <div className="item-content">
    <div className={`item-images ${!project.palette ? "no-palette" : ""}`}>
      {project.images ? (
        <span
          className={`${project.images.length > 2 ? "has-three" : ""} ${
            project.images.length === 2 ? "has-two" : ""
          }`}
        >
          {project.images[0] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[0].downloadURL
                  ? project.images[0].downloadURL
                  : project.images[0].urls.small
              }
              key={
                project.images[0].downloadURL
                  ? project.images[0].downloadURL
                  : project.images[0].urls.small
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-1 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
          {project.images[1] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[1].downloadURL
                  ? project.images[1].downloadURL
                  : project.images[1].urls.small
              }
              key={
                project.images[1].downloadURL
                  ? project.images[1].downloadURL
                  : project.images[1].urls.small
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-2 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
          {project.images[2] ? (
            <ProgressiveImage
              placeholder={require("../../images/loading-sm.svg")}
              onError={(error) => console.log("ERROR - Load Image", error)}
              src={
                project.images[2].downloadURL
                  ? project.images[2].downloadURL
                  : project.images[2].urls.small
              }
              key={
                project.images[2].downloadURL
                  ? project.images[2].downloadURL
                  : project.images[2].urls.small
              }
            >
              {(src, loading) => (
                <div
                  className={`item-image item-image-3 progressive ${
                    loading ? "loading" : "done"
                  }`}
                  style={{ backgroundImage: `url("${src}")` }}
                />
              )}
            </ProgressiveImage>
          ) : null}
        </span>
      ) : (
        <span className="item-image-empty">
          <i className="icon icon-picture" />
        </span>
      )}
    </div>
    <div className="item-palette">
      {project.palette ? (
        <div className="palette">
          <div
            className="color color-primary"
            style={{ backgroundColor: project.palette.colors.primary }}
          >
            <span className="hex">{project.palette.colors.primary}</span>
          </div>
          <div
            className="color color-secondary"
            style={{ backgroundColor: project.palette.colors.secondary }}
          >
            <span className="hex">{project.palette.colors.secondary}</span>
          </div>
          <div
            className="color color-accent"
            style={{ backgroundColor: project.palette.colors.accent }}
          >
            <span className="hex">{project.palette.colors.accent}</span>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export default ItemInviteCard;
