export const IconAudience = ({ colors }) =>
  <svg version="1.1" id="icon-audience" x="0px" y="0px" viewBox="0 0 155 155" height="155" width="155">
    <g>
      <circle style={{ fill: colors.colorDisplay.primary }} cx="77.5" cy="77.5" r="77.5"/>
      <g>
        <g>
          <g>
            <g>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="8.5,128.9 4.9,128.9 4.9,125.2 6.7,125.2 6.7,127 8.5,127"/>
              <path style={{ fill: colors.colorDisplay.primaryDarker }} d="M6.7,119.6H4.9V114h1.8V119.6z M6.7,108.4H4.9v-5.6h1.8V108.4z M6.7,97.2H4.9v-5.6h1.8V97.2z M6.7,85.9
                H4.9v-5.6h1.8V85.9z M6.7,74.7H4.9v-5.6h1.8V74.7z M6.7,63.5H4.9v-5.6h1.8V63.5z M6.7,52.2H4.9v-5.6h1.8V52.2z M6.7,41H4.9
                v-5.6h1.8V41z"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="6.7,29.8 4.9,29.8 4.9,26.1 8.5,26.1 8.5,28 6.7,28"/>
              <path style={{ fill: colors.colorDisplay.primaryDarker }} d="M141,28h-5.5v-1.8h5.5V28z M129.9,28h-5.5v-1.8h5.5V28z M118.9,28h-5.5v-1.8h5.5V28z M107.9,28h-5.5v-1.8
                h5.5V28z M96.8,28h-5.5v-1.8h5.5V28z M85.8,28h-5.5v-1.8h5.5V28z M74.7,28h-5.5v-1.8h5.5V28z M63.7,28h-5.5v-1.8h5.5V28z
                 M52.7,28h-5.5v-1.8h5.5V28z M41.6,28h-5.5v-1.8h5.5V28z M30.6,28h-5.5v-1.8h5.5V28z M19.6,28H14v-1.8h5.5V28z"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="150.1,29.8 148.3,29.8 148.3,28 146.5,28 146.5,26.1 150.1,26.1"/>
              <path style={{ fill: colors.colorDisplay.primaryDarker }} d="M150.1,119.6h-1.8V114h1.8V119.6z M150.1,108.4h-1.8v-5.6h1.8V108.4z M150.1,97.2h-1.8v-5.6h1.8V97.2z
                 M150.1,85.9h-1.8v-5.6h1.8V85.9z M150.1,74.7h-1.8v-5.6h1.8V74.7z M150.1,63.5h-1.8v-5.6h1.8V63.5z M150.1,52.2h-1.8v-5.6h1.8
                V52.2z M150.1,41h-1.8v-5.6h1.8V41z"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="150.1,128.9 146.5,128.9 146.5,127 148.3,127 148.3,125.2 150.1,125.2"/>
              <path style={{ fill: colors.colorDisplay.primaryDarker }} d="M141,128.9h-5.5V127h5.5V128.9z M129.9,128.9h-5.5V127h5.5V128.9z M118.9,128.9h-5.5V127h5.5V128.9z
                 M107.9,128.9h-5.5V127h5.5V128.9z M96.8,128.9h-5.5V127h5.5V128.9z M85.8,128.9h-5.5V127h5.5V128.9z M74.7,128.9h-5.5V127h5.5
                V128.9z M63.7,128.9h-5.5V127h5.5V128.9z M52.7,128.9h-5.5V127h5.5V128.9z M41.6,128.9h-5.5V127h5.5V128.9z M30.6,128.9h-5.5
                V127h5.5V128.9z M19.6,128.9H14V127h5.5V128.9z"/>
            </g>
            <g>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="153.6,28 148.3,28 148.3,22.6 150.1,22.6 150.1,26.1 153.6,26.1"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="150.1,132.4 148.3,132.4 148.3,127 153.6,127 153.6,128.9 150.1,128.9"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="6.7,28 1.4,28 1.4,26.1 4.9,26.1 4.9,22.6 6.7,22.6"/>
              <polygon style={{ fill: colors.colorDisplay.primaryDarker }} points="6.7,132.4 4.9,132.4 4.9,128.9 1.4,128.9 1.4,127 6.7,127"/>
            </g>
          </g>
        </g>
        <g>
          <rect x="21" y="42.2" style={{ fill: '#FFFFFF' }} width="113" height="70.6"/>
        </g>
        <g>
          <g>
            <rect x="27.4" y="55.9" style={{ fill: '#dddddd' }} width="54.2" height="50.6"/>
            <g>
              <polygon style={{ fill: '#353F49' }} points="62.9,72.5 46,72.5 44.8,97.1 64.1,97.1"/>
              <path style={{ fill: '#FFFFFF' }} d="M73.1,100.9c-0.3-0.8-0.7-1.6-1.4-2.3c-0.6-0.7-1.4-1.2-2.2-1.5l-8.7-3.5c-0.4-0.2-0.8-0.5-1.1-0.9
                c-0.3-0.4-0.4-0.9-0.4-1.4V87h-9.6v4.3c0,0.5-0.1,0.9-0.4,1.4c-0.3,0.4-0.7,0.7-1.1,0.9l-8.7,3.5c-0.8,0.3-1.6,0.8-2.2,1.5
                c-0.6,0.7-1.1,1.5-1.4,2.3l-1.7,5.5h40.7L73.1,100.9z"/>
              <path style={{ fill: colors.colorVars.accent }} d="M42.6,100.3c0,2.2-0.3,4.4-0.9,6.1h25.5c-0.6-1.7-0.9-3.9-0.9-6.1c0-1.6,0.2-3,0.5-4.4L64.4,95l-1,4
                c-0.5,1.9-1.6,3.7-3.3,5.1c-1.7,1.3-3.6,1.9-5.7,1.9c-2.1,0-4-0.6-5.7-1.9c-1.7-1.3-2.8-3.1-3.3-5.1l-1-4L42.1,96
                C42.5,97.3,42.6,98.8,42.6,100.3z"/>
              <path style={{ fill: '#FFFFFF' }} d="M54.5,65.4c-5.2,0-9,3.8-9,11.9c0,6.8,4,12.6,9,12.6c4.9,0,9-5.8,9-12.6C63.4,69.2,59.7,65.4,54.5,65.4z"/>
              <path style={{ fill: '#353F49' }} d="M62.5,65.8h-8h-8c0,0-1.1,8,0.3,9.7c0.1-0.7,0.6-0.9,1.7-0.9c0,0,0.9-1,1.5-3.8c0.5,1.9,0.3,3.8,0.3,3.8
                c1.1,0.1,2.5,0.2,4.2,0.2c0.4,0,0.8,0,1.2,0c0,0,0.5-0.3,0.7-1c0.3,0.4,0.3,1,0.3,1c3.4-0.2,5.4-0.6,5.5,0.6
                C63.6,73.9,62.5,65.8,62.5,65.8z"/>
              <path style={{ fill: '#353F49' }} d="M48.1,93.6c0.4-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.9,0.4-1.4v-1.8H47c-0.3-2.5-0.5-5-0.5-7.4
                c0-7,0.5-9.6,2.4-12c2.5-3,5.6-3.7,5.6-3.7s3.1,0.6,5.6,3.7c1.9,2.4,2.4,5,2.4,12c0,2.4-0.2,4.9-0.5,7.4h-2.7v1.8
                c0,0.5,0.1,0.9,0.4,1.4c0.3,0.4,0.7,0.7,1.1,0.9l5,2c0.6-0.8,0.9-1.7,0.9-2.6v-3.5c0-5.8-0.2-13.4-1.4-18.9
                c-1.1-4.7-5.1-9.3-10.9-8.1c-5.7-1.2-9.8,3.3-10.9,8.1c-1.2,5.5-1.4,13.1-1.4,18.9V93c0,0.9,0.3,1.9,0.9,2.6L48.1,93.6z"/>
            </g>
            <g>
              <rect x="27.4" y="48.6" style={{ fill: colors.colorVars.secondary }} width="54.2" height="7.3"/>
            </g>
          </g>
          <g>
            <rect x="87.9" y="48.6" style={{ fill: colors.colorVars.accent }} width="39.7" height="14.5"/>
            <g>
              <rect x="87.9" y="65.4" style={{ fill: '#CCCBCA' }} width="39.7" height="2.3"/>
              <rect x="87.9" y="70" style={{ fill: '#CCCBCA' }} width="39.7" height="2.3"/>
              <rect x="87.9" y="74.6" style={{ fill: '#CCCBCA' }} width="19.9" height="2.3"/>
            </g>
          </g>
          <g>
            <path style={{ fill: '#D9D8D7' }} d="M111.4,94.3c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2C110,91.1,111.4,92.5,111.4,94.3z"/>
            <g>
              <g>
                <rect x="101.6" y="85.9" transform="matrix(0.7072 -0.707 0.707 0.7072 -32.4244 97.9645)" style={{ fill: '#CCCBCA' }} width="1" height="4.6"/>
                <polygon style={{ fill: '#CCCBCA' }} points="102.1,92.3 97.9,90.6 98.3,89.6 102.5,91.4            "/>
                <rect x="97.2" y="93.8" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="97.9" y="97.1" transform="matrix(0.9254 -0.379 0.379 0.9254 -29.5305 45.2468)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="99.8" y="100" transform="matrix(0.7076 -0.7066 0.7066 0.7076 -41.1494 101.4985)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="102.6" y="101.9" transform="matrix(0.3821 -0.9241 0.9241 0.3821 -29.7797 160.1888)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="107.7" y="100.8" style={{ fill: '#CCCBCA' }} width="1" height="4.6"/>
                <rect x="114.7" y="93.8" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="114" y="90.5" transform="matrix(0.9242 -0.382 0.382 0.9242 -25.9269 51.3268)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="112.1" y="87.6" transform="matrix(0.7062 -0.7081 0.7081 0.7062 -28.7822 106.905)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="109.3" y="85.7" transform="matrix(0.3828 -0.9238 0.9238 0.3828 -10.8044 156.3047)" style={{ fill: '#CCCBCA' }} width="4.6" height="1"/>
                <rect x="107.7" y="83.3" style={{ fill: '#CCCBCA' }} width="1" height="4.6"/>
                <rect x="104.4" y="84" transform="matrix(0.9238 -0.3829 0.3829 0.9238 -25.026 46.7359)" style={{ fill: '#CCCBCA' }} width="1" height="4.6"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

export default IconAudience;