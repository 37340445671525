import React from "react";
import {
  ItemColor,
  ItemFont,
  ItemFontStyle,
  ItemFontSize,
  ItemLineHeight,
  ItemPadding,
  ItemBorderStyle,
  ItemBorderWidth,
  ItemBorderRadius,
  ItemCSS,
} from "../Elements";

export const CodeElement = (props) => {
  const {
    sections,
    borderStyles,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateFont,
    updateWeight,
    updateStyle,
    toggleCSS,
    toggleSection,
    item: { elements, fonts, palette },
  } = props;

  const defaultProps = {
    elements: elements,
    updateStyle: updateStyle,
  };

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  const borderProps = {
    elements: elements,
    updateStyle: updateStyle,
    borderStyles: borderStyles,
  };

  return (
    <div
      id="code-element"
      className={`element element-code ${
        sections.code.active ? "active" : ""
      } ${sections.code.css ? "css" : ""} ${
        elements.code.disabled ? "disabled" : ""
      }`}
    >
      <div
        className="element-content"
        onClick={() => toggleSection("code", true)}
      >
        <div className="text-group">
          <code
            style={{
              background: findColorVal("code", "background"),
              borderColor: findColorVal("code", "borderColor"),
              borderRadius: elements.code.borderRadius,
              borderStyle: elements.code.borderStyle,
              borderWidth: elements.code.borderWidth,
              boxShadow: elements.code.boxShadow,
              color: findColorVal("code", "color"),
              fontFamily: findFontVal("code", "fontFamily"),
              fontSize: elements.code.fontSize,
              fontStyle: elements.code.fontStyle,
              fontWeight: elements.code.fontWeight,
              lineHeight: elements.code.lineHeight,
              padding: elements.code.padding,
            }}
          >
            This is how {`<code>`} looks...
            <br />
            {`function myFunction() {
              alert("Hello World!");
            }`}
          </code>
        </div>
      </div>
      {sections.code.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Code font, styles, and colors</label>

            <div className="edit-item-sub">
              <ItemFont item={"code"} {...fontProps} />

              <ItemFontStyle item={"code"} {...fontStyleProps} />

              <ItemFontSize item={"code"} {...defaultProps} />

              <ItemLineHeight item={"code"} {...defaultProps} />

              <ItemColor
                type="color"
                item="code"
                label="font color"
                {...colorProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemPadding item="code" {...defaultProps} />

              <ItemColor
                type="background"
                item="code"
                label="bg color"
                {...colorProps}
              />
            </div>

            <div className="edit-item-sub">
              <ItemBorderStyle item="code" {...borderProps} />

              <ItemBorderWidth item="code" {...defaultProps} />

              <ItemBorderRadius item="code" {...defaultProps} />

              <ItemColor
                type="borderColor"
                item="code"
                label="border color"
                {...colorProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.code.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("code")}>
          <span>{sections.code.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.code.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.code}
              label={`code`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("code")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
