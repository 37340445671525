import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import {
  textContentModules,
  textContentFormats,
  spaceToNone,
} from "./Resources";

const TextContent = (props) => {
  const {
    item,
    item: { content, fonts, doneList },
    setItem,
    saveItem,
    child,
    node,
  } = props;

  const quillRef = useRef();
  const quillLength = useRef();
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    if (doneList[child] && editState) {
      setEditState(false);
    }
  }, [doneList, child, editState]);

  useEffect(() => {
    if (editState) {
      quillRef.current.focus();
    }
  }, [editState]);

  const updateContent = (content, source) => {
    quillLength.current = quillRef.current
      ? quillRef.current.getEditor().getLength()
      : -1;
    if (source === "user") {
      setItem({
        ...item,
        content: {
          ...item.content,
          [child]: {
            ...item.content[child],
            [`node-${node}`]: quillLength.current <= 1 ? [] : content,
          },
        },
      });
    }
  };

  const setContent = () => {
    if (quillLength.current <= 1 || !item.content[child]) {
      setItem({
        ...item,
        content: {
          ...item.content,
          [child]: {
            ...item.content[child],
            [`node-${node}`]: [],
          },
        },
      }).then((update) => saveItem("save", update, "none"));
    } else {
      saveItem("save", item, "none");
    }
    setEditState(false);
  };

  const clearContent = () => {
    setItem({
      ...item,
      content: {
        ...item.content,
        [child]: {
          ...item.content[child],
          [`node-${node}`]: [],
        },
      },
    }).then((update) => saveItem("save", update, "none"));
    setEditState(false);
  };

  return (
    <div
      className={`text-content ${editState ? "editing" : ""} ${
        !content[child] ||
        (content[child] && !content[child][`node-${node}`]) ||
        (content[child] &&
          content[child][`node-${node}`] &&
          content[child][`node-${node}`].length === 0)
          ? "empty"
          : ""
      }`}
    >
      <div className="text-content-display" onClick={() => setEditState(true)}>
        {content[child] && content[child][`node-${node}`] ? (
          <div
            className="content-display"
            dangerouslySetInnerHTML={{ __html: content[child][`node-${node}`] }}
          />
        ) : null}
      </div>
      <div className="text-content-edit">
        <div className="text-edit-wrap">
          {fonts && fonts[0] ? (
            <style>
              {`
                .text-content .quill, .text-content .content-display {
                  font-family: ${
                    fonts[0].css_stack
                      ? fonts[0].css_stack
                      : fonts[0].family
                      ? `'${fonts[0].family}'`
                      : `'${fonts[0].name}'`
                  };
                }
              `}
            </style>
          ) : null}
          {fonts
            ? fonts.map((font, index) => (
                <style
                  key={`${font.family ? font.family : font.name}-${index}`}
                >
                  {`
                .ql-font-${spaceToNone(font.family ? font.family : font.name)} {
                  font-family: ${
                    font.css_stack
                      ? font.css_stack
                      : font.family
                      ? `'${font.family}'`
                      : `'${font.name}'`
                  } !important;
                }
                .quill .ql-snow .ql-picker-options .ql-picker-item[data-value='${spaceToNone(
                  font.family ? font.family : font.name
                )}']:before,
                .quill .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='${spaceToNone(
                  font.family ? font.family : font.name
                )}']:before {
                  content: '${
                    font.name
                      ? font.type === "upload"
                        ? font.name.replace(".ttf", "").replace(".otf", "")
                        : font.name
                      : font.family
                  }';
                  font-family: ${
                    font.css_stack
                      ? font.css_stack
                      : font.family
                      ? `'${font.family}'`
                      : `'${font.name}'`
                  } !important;
                }
              `}
                </style>
              ))
            : null}
          <ReactQuill
            ref={(el) => (quillRef.current = el)}
            theme="snow"
            className="content-edit"
            value={
              content[child] &&
              content[child][`node-${node}`] &&
              content[child][`node-${node}`].length > 1
                ? content[child][`node-${node}`]
                : ""
            }
            modules={textContentModules(item)}
            formats={textContentFormats}
            onChange={(content, delta, source, editor) =>
              updateContent(content, source)
            }
          />
          <div className="text-save-btn" onClick={setContent}>
            <i className="icon icon-checkmark-circle" />
          </div>
        </div>
        <div className="text-edit-btn" onClick={() => setEditState(true)}>
          <i className="icon icon-plus-circle" />
        </div>
        <div className="text-delete-btn">
          <div className="card-delete-wrap">
            <span className="card-delete" onClick={clearContent}>
              <i className="icon icon-cross" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const TextContentDisplay = ({ content, child, node }) => {
  return (
    <div className="text-content">
      {content && content[child] && content[child][`node-${node}`] ? (
        <div
          className="content-display"
          dangerouslySetInnerHTML={{ __html: content[child][`node-${node}`] }}
        />
      ) : null}
    </div>
  );
};

export { TextContentDisplay };

export default TextContent;
