import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  isTouch,
  useMountEffect,
  inlineCSS,
  inFrame,
  findFileType,
  findNormalFontWeight,
  findNormalFontStyle,
  findElementColorVal,
  findElementFontVal,
  findElementStyleVal,
  findTextColor,
  findHeaderName,
  convertFileSize,
  colorTypes,
  isBrendan,
} from "../shared/Resources";
import * as routes from "constants/routes";
import LoadingSpinner from "../shared/Icons/LoadingSpinner";
import TextAccessibility from "../shared/TextAccessibility";
import { FontsDisplay } from "../shared/Fonts";
import { PaletteDisplay } from "../shared/PaletteDisplay";
import { TextContentDisplay } from "../shared/TextContent";
import { FileDisplay } from "../shared/FilesList";
import { ItemSettings } from "../shared/ItemSettings";
import { ItemCSS } from "../shared/Elements";
import Parallax from "../shared/Parallax.js";
import { Dropdown, Menu, Popup, Checkbox } from "semantic-ui-react";
import ProgressiveImage from "react-progressive-image";
import Masonry from "react-masonry-component";
import ReactBody from "react-body";
import tinycolor from "tinycolor2";
import Sticky from "react-sticky-el";
import Scrollspy from "react-scrollspy";

export const StyleguidePublished = (props) => {
  const {
    history,
    authUser,
    item,
    itemID,
    itemTemplate,
    itemColors,
    itemIsReady,
    loadItem,
    hideSpinner,
    updateSettings,
    saveTheme,
    manageItemUsers,
    openShareItemModal,
  } = props;

  const headers = [
    { name: "h1" },
    { name: "h2" },
    { name: "h3" },
    { name: "h4" },
    { name: "h5" },
  ];

  const [sections, setSections] = useState({
    headers: false,
    p: false,
    blockquote: false,
    code: false,
    buttons: false,
    dropdowns: false,
    inputs: false,
    toggles: false,
  });

  const [drawerActive, setDrawerActive] = useState(false);
  const [itemLoadError, setItemLoadError] = useState(false);
  const [scrollItem, setScrollItem] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const prevScrollY = useRef(0);

  const onScroll = () => {
    setIsHidden((isHidden) => {
      const scrolledDown = window.scrollY > prevScrollY.current;
      if (scrolledDown && !isHidden) {
        return true;
      } else if (!scrolledDown && isHidden) {
        return false;
      } else {
        prevScrollY.current = window.scrollY;
        return isHidden;
      }
    });
  };

  useMountEffect(() => {
    if (isTouch()) {
      window.addEventListener("scroll", onScroll);
    }
    return () => {
      if (isTouch()) {
        window.removeEventListener("scroll", onScroll);
      }
    };
  });

  const scrollToContent = (content) => {
    let contentScroll = document.getElementById(content);
    if (contentScroll) contentScroll.scrollIntoView();
  };

  const findStyleVal = (element, type) => {
    return findElementStyleVal(
      item.elements,
      item.fonts,
      item.palette,
      element,
      type
    );
  };

  const toggleCSS = (type) => {
    setSections(
      Object.assign([], { ...sections }, { [type]: !sections[type] })
    );
  };

  return (
    <section
      id="styleguide"
      className={`content-outer ${itemIsReady ? "loaded" : ""} theme-${
        item.theme ? item.theme : "light"
      }`}
    >
      {item.doneList && item.doneList.publish ? (
        <div className="item-outer">
          <Helmet>
            <title>{item.name ? item.name : "DesignGapp"}</title>
            <meta
              name="description"
              content={`Styleguide: ${item.name ? item.name : "Untitled"}`}
            />
            <meta
              name="og:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="twitter:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="description"
              content={`Styleguide: ${item.name ? item.name : "Untitled"}`}
            />
          </Helmet>

          {item.settings && item.settings.css ? (
            <style>{inlineCSS(item.settings.css, "#styleguide")}</style>
          ) : null}

          {authUser &&
          (Object.keys(item.users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan()) &&
          !inFrame() ? (
            <nav className="item-publish-nav">
              <button
                className="btn btn-text item-back-btn"
                onClick={() => history.push(routes.Styleguides)}
              >
                <i className="icon icon-arrow-left" />
              </button>

              <Menu className="item-nav-menu">
                <Dropdown
                  trigger={
                    <div className="item-nav-trigger">
                      <i className="icon icon-ellipsis" />
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <span
                      className="link item-menu-item menu-item item-styleguides"
                      onClick={() => history.push(routes.Styleguides)}
                    >
                      <i className="icon icon-arrow-left" />
                      <span className="menu-text">styleguides</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() =>
                        history.push(routes.Styleguide + "/edit/" + itemID)
                      }
                    >
                      <i className="icon icon-edit" />
                      <span className="menu-text">edit</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() => setDrawerActive(true)}
                    >
                      <i className="icon icon-equalizer" />
                      <span className="menu-text">publish settings</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-members"
                      onClick={manageItemUsers}
                    >
                      <i className="icon icon-users" />
                      <span className="menu-text">members</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-share"
                      onClick={openShareItemModal}
                    >
                      <i className="icon icon-exit-up" />
                      <span className="menu-text">share</span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>

              <ItemSettings
                template={itemTemplate}
                settings={item.settings}
                theme={item.theme}
                drawerActive={drawerActive}
                setDrawerActive={setDrawerActive}
                updateSettings={updateSettings}
                saveTheme={saveTheme}
              />
            </nav>
          ) : // : inFrame() ? <div onLoad={() => window.postMessage(document.body.scrollHeight, '*')} />
          null}

          {item.palette ? (
            <style>{`
              ::selection {
                background: ${itemColors.colorDisplay.primary};
              }
              .item-publish .item-loading h1 {
                color: ${itemColors.colorDisplay.primary};
              }
              #styleguide h2 .header-text,
              .item-inspiration-font .item-publish-font h2,
              .item-inspiration-font .item-publish-font h3,
              .item-publish .item-publish-nav .item-back-btn .icon,
              .item-publish .item-publish-nav .item-nav-trigger .icon {
                color: ${itemColors.colorDisplay.primary};
              }
              .item-publish #styleguide .sticky-holder .sticky ul li.active {
                box-shadow: 0 -0.15em 0 ${
                  itemColors.colorDisplay.primary
                } inset;
              }
              #styleguide .sticky-holder ul {
                background: ${tinycolor(itemColors.colorVars.primary).setAlpha(
                  0.575
                )};
              }
              .section-sub p {
                color: ${itemColors.colorDisplay.secondary};
              }
              .item-publish .item-publish-nav .item-back-btn .icon,
              .item-publish .item-publish-nav .item-nav-trigger .icon {
                color: ${
                  item.theme === "dark"
                    ? itemColors.colorDisplay.primary
                    : itemColors.colorDisplay.primaryDark
                };
              }
              .item-publish .item-publish-nav .item-back-btn:hover .icon {
                color: ${itemColors.colorDisplay.accent};
              }
              @media (max-width: 768px) {
                .item-publish #styleguide .item-publish-nav .item-nav-trigger {
                  background: ${itemColors.colorDisplay.primary};
                }
                .item-publish #styleguide .item-publish-nav .item-nav-trigger:hover {
                  background: ${itemColors.colorDisplay.primaryDark};
                }
              } 
              .item-publish #styleguide .item-publish-nav .item-nav-trigger:hover {
                background: ${itemColors.colorDisplay.primary};
              }
              .font-paragraph {
                border-left-color: ${itemColors.colorDisplay.primary}
              }
            `}</style>
          ) : null}
          <div
            className={`item-content ${
              item.settings && !item.settings.paletteHeader
                ? "no-paletteHeader"
                : ""
            }`}
          >
            {item.fonts ? (
              <style>
                {`
                  .item-content, .item-content h2, .item-content h3, .item-content p, .item-content a, .item-content .btn, .item-publish .item-loading h1 {
                    font-family: ${
                      item.fonts[0].family
                        ? `"${item.fonts[0].family}"`
                        : item.fonts[0].css_stack
                    };
                    font-weight: ${findNormalFontWeight(item.fonts[0])};
                    font-style: ${findNormalFontStyle(item.fonts[0])};
                  }
                `}
              </style>
            ) : null}

            <div
              className={`item-header ${
                item.logos ? item.logos[0].color : ""
              } ${item.settings && !item.settings.parallax ? "not-fixed" : ""}`}
            >
              {item.logos && findFileType(item.logos[0]) === "image-embed" ? (
                <Parallax
                  speed="2"
                  direction="down"
                  className={`item-parallax-logo ${
                    item.logos[0].height >= item.logos[0].width
                      ? "tallest"
                      : item.logos[0].height * 1.5 >= item.logos[0].width
                      ? "taller"
                      : item.logos[0].height * 3 >= item.logos[0].width
                      ? "tall"
                      : ""
                  }`}
                  disabled={item.settings && !item.settings.parallax}
                >
                  <ProgressiveImage
                    placeholder={require("../../images/loading-md.svg")}
                    onError={(error) => {
                      console.log("ERROR - Load Image", error);
                      setItemLoadError(true);
                    }}
                    src={item.logos[0].downloadURL}
                  >
                    {(src, loading) => (
                      <img
                        className={`progressive ${
                          loading ? "loading" : "done"
                        }`}
                        src={item.logos[0].downloadURL}
                        alt={item.logos[0].name}
                        onLoad={!loading ? loadItem : null}
                      />
                    )}
                  </ProgressiveImage>
                </Parallax>
              ) : (
                <Parallax
                  speed="2"
                  direction="down"
                  className="item-parallax-name"
                  disabled={item.settings && !item.settings.parallax}
                >
                  <h1>{item.name}</h1>
                </Parallax>
              )}
            </div>

            <div
              className={`item-body ${
                !item.palette && !item.altColors ? "no-palette" : ""
              }`}
            >
              <Sticky
                className="sticky-holder item-nav-holder"
                positionRecheckInterval={1}
                disabled={isTouch()}
              >
                <div
                  className={`styleguide-nav ${
                    scrollItem === "palette-header" ? "palette-header" : ""
                  } ${!item.palette && !item.altColors ? "no-palette" : ""} ${
                    itemColors && itemColors.colorVars
                      ? findTextColor(itemColors.colorVars.primary, "small")
                      : ""
                  } ${isHidden ? "hidden" : ""}`}
                >
                  <Scrollspy
                    onUpdate={(update) =>
                      setScrollItem(update && update.id ? update.id : "")
                    }
                    items={
                      item.settings && item.settings.order
                        ? ["palette-header", ...item.settings.order]
                        : []
                    }
                    currentClassName="active"
                    offset={-50}
                  >
                    {item.settings && item.settings.paletteHeader ? (
                      <li style={{ display: "none" }}>Top</li>
                    ) : null}
                    {item.settings && item.settings.order
                      ? item.settings.order.map((section) =>
                          section === "logos" && item.logos ? (
                            <li
                              onClick={() => scrollToContent("logos")}
                              key="logos"
                            >
                              Logos
                            </li>
                          ) : section === "palette" && item.palette ? (
                            <li
                              onClick={() => scrollToContent("palette")}
                              key="palette"
                            >
                              Palette
                            </li>
                          ) : section === "fonts" && item.fonts ? (
                            <li
                              onClick={() => scrollToContent("fonts")}
                              key="fonts"
                            >
                              Fonts
                            </li>
                          ) : section === "images" && item.images ? (
                            <li
                              onClick={() => scrollToContent("images")}
                              key="images"
                            >
                              Images
                            </li>
                          ) : section === "icons" && item.icons ? (
                            <li
                              onClick={() => scrollToContent("icons")}
                              key="icons"
                            >
                              Icons
                            </li>
                          ) : section === "elements" &&
                            item.elements &&
                            (!item.elements.headers.disabled ||
                              !item.elements.p.disabled ||
                              !item.elements.a.disabled ||
                              !item.elements.blockquote.disabled ||
                              !item.elements.code.disabled ||
                              !item.elements.buttons.disabled ||
                              !item.elements.dropdownMenu.disabled ||
                              !item.elements.input.disabled ||
                              !item.elements.toggle.disabled) ? (
                            <li
                              onClick={() => scrollToContent("elements")}
                              key="elements"
                            >
                              Elements
                            </li>
                          ) : section === "files" && item.files ? (
                            <li
                              onClick={() => scrollToContent("files")}
                              key="files"
                            >
                              Files
                            </li>
                          ) : null
                        )
                      : null}
                  </Scrollspy>
                </div>
              </Sticky>

              {itemColors && itemColors.colorDisplay ? (
                <section id="palette-header" className="item-palette-header">
                  <div
                    className="item-header-color header-palette-primary"
                    style={{ background: itemColors.colorVars.primary }}
                  >
                    {item.images && item.images[0] ? (
                      <Parallax
                        speed="6"
                        direction="down"
                        className="item-parallax-img"
                        disabled={item.settings && !item.settings.parallax}
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-md.svg")}
                          onError={(error) => {
                            console.log("ERROR - Load Image", error);
                            setItemLoadError(true);
                          }}
                          src={
                            item.images[0].downloadURL
                              ? item.images[0].downloadURL
                              : item.images[0].urls.regular
                          }
                        >
                          {(src, loading) => (
                            <div
                              className="header-img progressive blur"
                              style={{
                                filter: loading
                                  ? "blur(6px)"
                                  : "grayscale(0.925) brightness(0.875)",
                                backgroundImage: `url(${src})`,
                              }}
                              ref={!loading ? loadItem : null}
                            />
                          )}
                        </ProgressiveImage>
                      </Parallax>
                    ) : null}
                  </div>
                  <div
                    className="item-header-color header-palette-secondary"
                    style={{ background: itemColors.colorVars.secondary }}
                  >
                    {item.images && item.images[1] ? (
                      <Parallax
                        speed="5"
                        direction="down"
                        className="item-parallax-img"
                        disabled={item.settings && !item.settings.parallax}
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-md.svg")}
                          onError={(error) => {
                            console.log("ERROR - Load Image", error);
                            setItemLoadError(true);
                          }}
                          src={
                            item.images[1].downloadURL
                              ? item.images[1].downloadURL
                              : item.images[1].urls.regular
                          }
                        >
                          {(src, loading) => (
                            <div
                              className="header-img progressive blur"
                              style={{
                                filter: loading
                                  ? "blur(6px)"
                                  : "grayscale(0.925) brightness(0.875)",
                                backgroundImage: `url(${src})`,
                              }}
                              ref={!loading ? loadItem : null}
                            />
                          )}
                        </ProgressiveImage>
                      </Parallax>
                    ) : null}
                  </div>
                  <div
                    className="item-header-color header-palette-accent"
                    style={{ background: itemColors.colorVars.accent }}
                  >
                    {item.images && item.images[2] ? (
                      <Parallax
                        speed="4"
                        direction="down"
                        className="item-parallax-img"
                        disabled={item.settings && !item.settings.parallax}
                      >
                        <ProgressiveImage
                          placeholder={require("../../images/loading-md.svg")}
                          onError={(error) => {
                            console.log("ERROR - Load Image", error);
                            setItemLoadError(true);
                          }}
                          src={
                            item.images[2].downloadURL
                              ? item.images[2].downloadURL
                              : item.images[2].urls.regular
                          }
                        >
                          {(src, loading) => (
                            <div
                              className="header-img progressive blur"
                              style={{
                                filter: loading
                                  ? "blur(6px)"
                                  : "grayscale(0.925) brightness(0.875)",
                                backgroundImage: `url(${src})`,
                              }}
                              ref={!loading ? loadItem : null}
                            />
                          )}
                        </ProgressiveImage>
                      </Parallax>
                    ) : null}
                  </div>
                </section>
              ) : null}

              {item.logos ||
              item.palette ||
              item.fonts ||
              item.images ||
              item.icons ||
              item.elements ||
              item.files ? (
                <section className="item-publish-inspiration publish-section">
                  <div className="item-inner">
                    <div className="item-inspiration-sub section-sub">
                      {headers
                        ? headers.map((header, index) => (
                            <style key={index}>
                              {`
                            #styleguide ${header.name} {
                              font-weight: ${
                                item.elements[header.name].fontWeight
                              };
                              font-style: ${
                                item.elements[header.name].fontStyle
                              };
                            }
                            #styleguide #elements .element-content ${
                              header.name
                            }, #styleguide .text-content .content-display ${
                                header.name
                              } {
                              color: ${findElementColorVal(
                                item.elements,
                                item.palette,
                                header.name,
                                "color"
                              )};
                              font-family: ${findElementFontVal(
                                item.elements,
                                item.fonts,
                                header.name,
                                "fontFamily"
                              )};
                              font-size: ${
                                item.elements[header.name] &&
                                item.elements[header.name].fontSize
                                  ? item.elements[header.name].fontSize
                                  : "inherit"
                              };
                              font-style: ${
                                item.elements[header.name] &&
                                item.elements[header.name].fontStyle
                                  ? item.elements[header.name].fontStyle
                                  : "inherit"
                              };
                              font-weight: ${
                                item.elements[header.name] &&
                                item.elements[header.name].fontWeight
                                  ? item.elements[header.name].fontWeight
                                  : "inherit"
                              };
                            }
                          `}
                            </style>
                          ))
                        : null}

                      <style>
                        {`
                          #styleguide p, #styleguide .styleguide-nav li, #styleguide .palette-report-wrap {
                            font-style: ${item.elements.p.fontStyle};
                            font-weight: ${item.elements.p.fontWeight};
                          }
                          #styleguide #elements p, #styleguide .text-content .content-display p, #styleguide .text-content .content-display li {
                            color: ${findElementColorVal(
                              item.elements,
                              item.palette,
                              "p",
                              "color"
                            )};
                            font-family: ${findElementFontVal(
                              item.elements,
                              item.fonts,
                              "p",
                              "fontFamily"
                            )};
                            font-size: ${item.elements.p.fontSize};
                            font-style: ${item.elements.p.fontStyle};
                            font-weight: ${item.elements.p.fontWeight};
                            line-height: ${item.elements.p.lineHeight};
                          }
                          #styleguide a, #styleguide .btn, #styleguide .styleguide-nav li {
                            font-style: ${item.elements.a.fontStyle};
                            font-weight: ${item.elements.a.fontWeight};
                          }
                          #styleguide #elements a, #styleguide .styleguide-nav a, #styleguide #elements .link, #styleguide .styleguide-nav .link, #styleguide .text-content .content-display a, #styleguide .text-content .content-display .link {
                            color: ${findElementColorVal(
                              item.elements,
                              item.palette,
                              "a",
                              "color"
                            )};
                            font-family: ${findElementFontVal(
                              item.elements,
                              item.fonts,
                              "p",
                              "fontFamily"
                            )};
                            font-style: ${
                              item.elements.a && item.elements.a.fontStyle
                                ? item.elements.a.fontStyle
                                : "inherit"
                            };
                            font-weight: ${
                              item.elements.a && item.elements.a.fontWeight
                                ? item.elements.a.fontWeight
                                : "inherit"
                            };
                            text-decoration: ${
                              item.elements.a && item.elements.a.textDecoration
                                ? item.elements.a.textDecoration
                                : "inherit"
                            };
                          }
                          #styleguide a:hover {
                            font-style: ${item.elements.aHover.fontStyle};
                            font-weight: ${item.elements.aHover.fontWeight};
                          }
                          #styleguide #elements a:hover, #styleguide #elements a.hover, #styleguide .styleguide-nav a:hover, #styleguide #elements .link:hover, #styleguide #elements .link.hover, #styleguide .styleguide-nav .link:hover, #styleguide .text-content .content-display a:hover, #styleguide .text-content .content-display .link:hover {
                            color: ${findElementColorVal(
                              item.elements,
                              item.palette,
                              "aHover",
                              "color"
                            )};
                            text-decoration: ${
                              item.elements.aHover &&
                              item.elements.aHover.textDecoration
                                ? item.elements.aHover.textDecoration
                                : "inherit"
                            };
                            font-style: ${
                              item.elements.aHover &&
                              item.elements.aHover.fontStyle
                                ? item.elements.aHover.fontStyle
                                : "inherit"
                            };
                            font-weight: ${
                              item.elements.aHover &&
                              item.elements.aHover.fontWeight
                                ? item.elements.aHover.fontWeight
                                : "inherit"
                            };
                          }
                          #styleguide a:active{
                            font-style: ${item.elements.aActive.fontStyle};
                            font-weight: ${item.elements.aActive.fontWeight};
                          }
                          #styleguide #elements a:active, #styleguide #elements a.active, #styleguide .styleguide-nav a:active, #styleguide #elements .link:active, #styleguide #elements .link.active, #styleguide .styleguide-nav .link:active, #styleguide .text-content .content-display a:active, #styleguide .text-content .content-display .link:active {
                            color: ${findElementColorVal(
                              item.elements,
                              item.palette,
                              "aActive",
                              "color"
                            )};
                            text-decoration: ${
                              item.elements.aActive &&
                              item.elements.aActive.textDecoration
                                ? item.elements.aActive.textDecoration
                                : "inherit"
                            };
                            font-style: ${
                              item.elements.aActive &&
                              item.elements.aActive.fontStyle
                                ? item.elements.aActive.fontStyle
                                : "inherit"
                            };
                            font-weight: ${
                              item.elements.aActive &&
                              item.elements.aActive.fontWeight
                                ? item.elements.aActive.fontWeight
                                : "inherit"
                            };
                          }
                        `}
                      </style>

                      <TextContentDisplay
                        content={item.content}
                        child={"root"}
                        node={0}
                      />

                      {item.settings && item.settings.order
                        ? item.settings.order.map((section) =>
                            section === "logos" && item.logos ? (
                              <StyleguideLogos
                                item={item}
                                loadItem={loadItem}
                                setItemLoadError={setItemLoadError}
                                key={"logos"}
                              />
                            ) : section === "palette" && item.palette ? (
                              <StyleguidePalette
                                item={item}
                                itemColors={itemColors}
                                key={"palette"}
                              />
                            ) : section === "fonts" && item.fonts ? (
                              <StyleguideFonts item={item} key={"fonts"} />
                            ) : section === "images" && item.images ? (
                              <StyleguideImages
                                item={item}
                                loadItem={loadItem}
                                setItemLoadError={setItemLoadError}
                                key={"images"}
                              />
                            ) : section === "icons" && item.icons ? (
                              <StyleguideIcons item={item} key={"icons"} />
                            ) : section === "elements" &&
                              item.elements &&
                              (!item.elements.headers.disabled ||
                                !item.elements.p.disabled ||
                                !item.elements.a.disabled ||
                                !item.elements.blockquote.disabled ||
                                !item.elements.code.disabled ||
                                !item.elements.buttons.disabled ||
                                !item.elements.dropdownMenu.disabled ||
                                !item.elements.input.disabled ||
                                !item.elements.toggle.disabled) ? (
                              <StyleguideElements
                                item={item}
                                findStyleVal={findStyleVal}
                                scrollItem={scrollItem}
                                headers={headers}
                                sections={sections}
                                toggleCSS={toggleCSS}
                                key={"elements"}
                              />
                            ) : section === "files" && item.files ? (
                              <StyleguideFiles
                                item={item}
                                loadItem={loadItem}
                                setItemLoadError={setItemLoadError}
                                key={"files"}
                              />
                            ) : null
                          )
                        : null}

                      <TextContentDisplay
                        content={item.content}
                        child={"root"}
                        node={1}
                      />
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </div>
          <div
            className={`item-loading content-loading no-spinner ${
              itemLoadError ? "error" : ""
            }`}
          >
            {!itemLoadError && !hideSpinner ? (
              <span>
                <h1>Building styleguide...</h1>
                <LoadingSpinner colors={itemColors} />
              </span>
            ) : itemLoadError ? (
              <span className="item-load-error">
                <i className="icon icon-cross-circle" />
                <h1>
                  Sorry, something went wrong.
                  <br />
                  Please try loading this again.
                </h1>
                <p>
                  <Link to={routes.Contact}>
                    Contact us if this problem persists.
                  </Link>
                </p>
              </span>
            ) : null}
          </div>
          <ReactBody className="item-publish" />
        </div>
      ) : item.doneList && !item.doneList.publish ? (
        <div className="item-outer item-unpublish-outer">
          <i className="icon icon-large icon-notification" />
          <h1>This styleguide hasn't been published.</h1>
        </div>
      ) : (
        <ReactBody className="item-publish" />
      )}
    </section>
  );
};

const StyleguideLogos = ({ item, loadItem, setItemLoadError }) => (
  <div id="logos" className="item-inspiration-logos sub-content">
    <h2>Logos</h2>
    <TextContentDisplay content={item.content} child={"logos"} node={0} />
    <div className="item-logos-wrap">
      {item.logos.map((logo, index) => (
        <div
          id={`item-logo-${index}`}
          className={`item-logo logo-content ${logo.color} ${
            logo.height >= logo.width
              ? "tallest"
              : logo.height * 1.5 >= logo.width
              ? "taller"
              : logo.height * 2 >= logo.width
              ? "tall"
              : ""
          } ${findFileType(logo) !== "image-embed" ? "logo-icon" : ""}`}
          key={logo.name}
        >
          <a href={logo.downloadURL} target="_blank" rel="noopener noreferrer">
            {findFileType(logo) === "image-embed" ? (
              <ProgressiveImage
                placeholder={require("../../images/loading-md.svg")}
                onError={(error) => {
                  console.log("ERROR - Load Image", error);
                  setItemLoadError(true);
                }}
                src={logo.downloadURL}
              >
                {(src, loading) => (
                  <img
                    className={`progressive ${loading ? "loading" : "done"}`}
                    src={src}
                    alt={logo.name}
                    onLoad={!loading ? loadItem : null}
                  />
                )}
              </ProgressiveImage>
            ) : (
              <span className={`file-icon ${findFileType(logo)}`}>
                <i className="icon icon-file-empty" />
                <span className="file-detail">
                  <p className="file-detail-content">
                    <span className="file-text">{logo.name}</span>
                    <span className="file-size">
                      {convertFileSize(logo.size)}
                    </span>
                  </p>
                </span>
              </span>
            )}
          </a>
          <a href={logo.downloadURL} target="_blank" rel="noopener noreferrer">
            <span className="card-download">
              <i className="icon icon-download" />
            </span>
          </a>
        </div>
      ))}
      <TextContentDisplay content={item.content} child={"logos"} node={1} />
    </div>
  </div>
);

const StyleguidePalette = ({ item, itemColors }) => (
  <div id="palette" className="item-inspiration-palette sub-content">
    <h2>Palette</h2>
    <TextContentDisplay content={item.content} child={"palette"} node={0} />
    {item.palette.colors ? (
      <div className="item-palette-wrap">
        {colorTypes.map((color) => (
          <div className={`item-palette-color ${color}`} key={color}>
            <PaletteDisplay name={color} color={itemColors.colorVars[color]} />
          </div>
        ))}
      </div>
    ) : null}
    {item.palette.altColors ? (
      <div className="item-palette-wrap palette-alt-wrap">
        {item.palette.altColors.map((altColor) => (
          <div
            className="item-palette-color palette-alt-color"
            key={altColor.id}
          >
            <PaletteDisplay name={altColor.name} color={altColor.color} />
          </div>
        ))}
      </div>
    ) : null}
    {item.settings &&
    item.settings.accessibility &&
    (item.palette.colors || item.palette.altColors) ? (
      <TextAccessibility item={item} itemColors={itemColors} />
    ) : null}
    <TextContentDisplay content={item.content} child={"palette"} node={1} />
  </div>
);

const StyleguideFonts = ({ item }) => (
  <div id="fonts" className="item-inspiration-font sub-content">
    <h2>Fonts</h2>
    <TextContentDisplay content={item.content} child={"fonts"} node={0} />
    <FontsDisplay type={"styleguide"} fonts={item.fonts} />
    <TextContentDisplay content={item.content} child={"fonts"} node={1} />
  </div>
);

const StyleguideImages = ({ item, loadItem, setItemLoadError }) => (
  <div id="images" className="item-inspiration-images sub-content">
    <h2>Images</h2>
    <TextContentDisplay content={item.content} child={"images"} node={0} />
    <div className="item-publish-images">
      <Masonry className={"publish-images"}>
        {item.images.map((image, index) => (
          <div className="item-image-wrap" key={index}>
            <div className="item-image">
              <a
                href={`${
                  image.downloadURL
                    ? image.downloadURL
                    : `${image.urls.full}&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ProgressiveImage
                  placeholder={require("../../images/loading-md.svg")}
                  onError={(error) => {
                    console.log("ERROR - Load Image", error);
                    setItemLoadError(true);
                  }}
                  src={`${
                    image.downloadURL
                      ? image.downloadURL
                      : `${image.urls.regular}&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`
                  }`}
                >
                  {(src, loading) => (
                    <img
                      className={`progressive ${loading ? "loading" : "done"}`}
                      src={src}
                      alt={image.description ? image.description : image.name}
                      onLoad={!loading ? loadItem : null}
                    />
                  )}
                </ProgressiveImage>
              </a>
              <a
                href={`${
                  image.downloadURL
                    ? image.downloadURL
                    : `${image.urls.full}&client_id=521f64d6fd4e7115d827c2d57affb3f54eb6031d4e0f132d649f2a82fa086c28`
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="card-download">
                  <i className="icon icon-download" />
                </span>
              </a>
            </div>
          </div>
        ))}
      </Masonry>
    </div>
    <TextContentDisplay content={item.content} child={"images"} node={1} />
  </div>
);

const StyleguideIcons = ({ item }) => (
  <div id="icons" className="item-inspiration-icons sub-content">
    <h2>Icons</h2>
    <TextContentDisplay content={item.content} child={"icons"} node={0} />
    <div className="item-icons-wrap">
      <div className="item-icons">
        {item.icons.map((icon, index) => (
          <div className="icons-grid" key={icon.name}>
            {icon.iconSet !== "upload" ? (
              <style>
                {`@font-face {
                    font-family: 'icons-${index}';
                    src: url(data:font/ttf;base64,${icon.encode});
                  }
                  .icons-${index} {
                    font-family: 'icons-${index}' !important
                  }
                `}
              </style>
            ) : (
              <style>
                {`#icons .icons-grid .icons-upload-nodata a {
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "a",
                        "color"
                      )};
                    }
                  `}
              </style>
            )}
            {icon.iconSet === "icomoon" ? (
              icon.data.icons.map((data, dataIndex) => (
                <div
                  className="icons-data"
                  key={`icomoon-${index}-${dataIndex}`}
                >
                  {
                    <style>
                      {`.icons-${index}.data-${dataIndex}.icon-${data.properties.name
                        .replace(/,/g, "")
                        .replace(/ /g, ".icon-")}:before {
                          content: '\\${data.properties.code.toString(16)}'
                        }
                      `}
                    </style>
                  }
                  <Popup
                    flowing
                    hoverable
                    position="top center"
                    className="popup-icon"
                    trigger={
                      <i
                        className={`icons-${index} data-${dataIndex} icon-${data.properties.name
                          .replace(/,/g, "")
                          .replace(/ /g, " icon-")}`}
                      />
                    }
                    header={
                      <h5>
                        icon-
                        {data.properties.name
                          .replace(/,/g, "")
                          .replace(/ /g, " icon-")}
                      </h5>
                    }
                    content={
                      <p>
                        character:{" "}
                        <span>\{data.properties.code.toString(16)}</span>
                      </p>
                    }
                  />
                </div>
              ))
            ) : icon.iconSet === "fontello" ? (
              icon.data.glyphs.map((data, dataIndex) => (
                <div
                  className="icons-data"
                  key={`fontello-${index}-${dataIndex}`}
                >
                  {
                    <style>
                      {`.icons-${index}.data-${dataIndex}.icon-${data.css
                        .replace(/,/g, "")
                        .replace(/ /g, ".")}:before {
                          content: '\\${data.code.toString(16)}'
                        }
                      `}
                    </style>
                  }
                  <Popup
                    hoverable
                    position="top center"
                    className="popup-icon"
                    trigger={
                      <i
                        className={`icons-${index} data-${dataIndex} icon-${data.css.replace(
                          /,/g,
                          ""
                        )}`}
                      />
                    }
                    header={<h5>icon-{data.css.replace(/,/g, "")}</h5>}
                    content={
                      <p>
                        character: <span>\{data.code.toString(16)}</span>
                      </p>
                    }
                  />
                </div>
              ))
            ) : icon.iconSet === "fontastic" ? (
              Object.keys(icon.data).map((data, dataIndex) => (
                <div
                  className="icons-data"
                  key={`fontastic-${index}-${dataIndex}`}
                >
                  {
                    <style>
                      {`.icons-${index}.data-${dataIndex}.icon-${data}:before {
                          content: '\\${icon.data[data].content}'
                        }
                      `}
                    </style>
                  }
                  <Popup
                    hoverable
                    position="top center"
                    className="popup-icon"
                    trigger={
                      <i
                        className={`icons-${index} data-${dataIndex} icon-${data}`}
                      />
                    }
                    header={<h5>icon-{data}</h5>}
                    content={
                      <p>
                        character: <span>\{icon.data[data].content}</span>
                      </p>
                    }
                  />
                </div>
              ))
            ) : icon.iconSet === "upload" && icon.data ? (
              Object.keys(icon.data).map((data, dataIndex) => (
                <div
                  className="icons-data"
                  key={`update-${index}-${dataIndex}`}
                  title={icon.data[data]}
                >
                  <Popup
                    hoverable
                    position="top center"
                    className="popup-icon"
                    trigger={
                      <img
                        src={`data:image/svg+xml;base64,${icon.encode[data]}`}
                        alt={icon.data[data]}
                      />
                    }
                    content={<p>{icon.data[data]}</p>}
                  />
                </div>
              ))
            ) : icon.iconSet === "upload" && !icon.data ? (
              <div className="icons-upload-nodata">
                <a
                  href={icon.downloadURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon icon-file-zip" /> {icon.name}
                </a>
              </div>
            ) : null}
            <a
              href={icon.downloadURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="card-download">
                <i className="icon icon-download" />
              </span>
            </a>
          </div>
        ))}
      </div>
    </div>
    <TextContentDisplay content={item.content} child={"icons"} node={1} />
  </div>
);

const StyleguideElements = ({
  item,
  findStyleVal,
  scrollItem,
  headers,
  sections,
  toggleCSS,
}) => {
  const [inputText, setInputText] = useState("");
  const [textareaText, setTextareaText] = useState("");
  const [checkBoxToggle, setCheckBoxToggle] = useState(true);
  const [checkBoxSlider, setCheckBoxSlider] = useState(true);
  const [checkBoxOne, setCheckBoxOne] = useState(true);
  const [checkBoxTwo, setCheckBoxTwo] = useState(false);
  const [radioBtn, setRadioBtn] = useState(true);

  return (
    <div id="elements" className="item-inspiration-elements sub-content">
      <h2>Elements</h2>
      <TextContentDisplay content={item.content} child={"elements"} node={0} />
      <div className="item-elements-wrap">
        <div className="item-elements">
          {!item.elements.headers.disabled ? (
            <div className="item-element element-headers">
              <div className="element-content">
                <div className="content-headers">
                  {headers.map((header, index) => (
                    <div
                      className={`header-wrap ${header.name}`}
                      key={header.name}
                    >
                      <header.name>
                        Header {findHeaderName(header.name)}
                      </header.name>
                    </div>
                  ))}
                </div>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.headers ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("headers")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.headers ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${
                      sections.headers ? "active" : ""
                    }`}
                  >
                    <ItemCSS
                      item={item.elements.headers}
                      label={`h1, h2, h3, h4, h5`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.h1}
                      label={`h1`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.h2}
                      label={`h2`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.h3}
                      label={`h3`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.h4}
                      label={`h4`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.h5}
                      label={`h5`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.p.disabled ? (
            <div className="item-element element-paragraph">
              <div className="element-content">
                <div className="content-paragraph">
                  <p>
                    <strong>Paragraph: </strong>I wandered lonely as a cloud
                    That floats on high o'er vales and hills, When all at once I
                    saw a crowd, A host, of golden daffodils; Beside the lake,
                    beneath the trees, Fluttering and dancing in the breeze.
                    Continuous as the stars that shine And twinkle on the milky
                    way, They stretched in never-ending line Along the margin of
                    a bay: Ten thousand saw I at a glance, Tossing their heads
                    in sprightly dance.
                  </p>
                  <p>
                    This is how a <span className="link">link will appear</span>{" "}
                    and this is a{" "}
                    <span className="link hover">hovered link</span> and an{" "}
                    <span className="link active">active link</span>.
                  </p>
                </div>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.p ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("p")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.p ? "hide" : "show"} css
                  </button>
                  <div className={`element-css ${sections.p ? "active" : ""}`}>
                    <ItemCSS
                      item={item.elements.p}
                      label={`p`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.a}
                      label={`a`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.aHover}
                      label={`a:hover`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.aActive}
                      label={`a:active`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.blockquote.disabled ? (
            <div className="item-element element-blockquote">
              <div className="element-content">
                <blockquote
                  style={{
                    background: findElementColorVal(
                      item.elements,
                      item.palette,
                      "blockquote",
                      "background"
                    ),
                    borderColor: findElementColorVal(
                      item.elements,
                      item.palette,
                      "blockquote",
                      "borderColor"
                    ),
                    borderRadius: item.elements.blockquote.borderRadius,
                    borderStyle: item.elements.blockquote.borderStyle,
                    borderWidth: item.elements.blockquote.borderWidth,
                    boxShadow: item.elements.blockquote.boxShadow,
                    color: findElementColorVal(
                      item.elements,
                      item.palette,
                      "blockquote",
                      "color"
                    ),
                    fontFamily: findElementFontVal(
                      item.elements,
                      item.fonts,
                      "blockquote",
                      "fontFamily"
                    ),
                    fontSize: item.elements.blockquote.fontSize,
                    fontStyle: item.elements.blockquote.fontStyle,
                    fontWeight: item.elements.blockquote.fontWeight,
                    lineHeight: item.elements.blockquote.lineHeight,
                    margin: item.elements.blockquote.margin,
                    padding: item.elements.blockquote.padding,
                  }}
                >
                  <strong>Blockquote: </strong>
                  If you can dream—and not make dreams your master; If you can
                  think—and not make thoughts your aim; If you can meet with
                  Triumph and Disaster And treat those two impostors just the
                  same;
                </blockquote>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.blockquote ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("blockquote")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.blockquote ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${
                      sections.blockquote ? "active" : ""
                    }`}
                  >
                    <ItemCSS
                      item={item.elements.blockquote}
                      label={`blockquote`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.code.disabled ? (
            <div className="item-element element-code">
              <div className="element-content">
                <code
                  style={{
                    background: findElementColorVal(
                      item.elements,
                      item.palette,
                      "code",
                      "background"
                    ),
                    borderColor: findElementColorVal(
                      item.elements,
                      item.palette,
                      "code",
                      "borderColor"
                    ),
                    borderRadius: item.elements.code.borderRadius,
                    borderStyle: item.elements.code.borderStyle,
                    borderWidth: item.elements.code.borderWidth,
                    boxShadow: item.elements.code.boxShadow,
                    color: findElementColorVal(
                      item.elements,
                      item.palette,
                      "code",
                      "color"
                    ),
                    fontFamily: findElementFontVal(
                      item.elements,
                      item.fonts,
                      "code",
                      "fontFamily"
                    ),
                    fontSize: item.elements.code.fontSize,
                    fontStyle: item.elements.code.fontStyle,
                    fontWeight: item.elements.code.fontWeight,
                    lineHeight: item.elements.code.lineHeight,
                    padding: item.elements.code.padding,
                  }}
                >
                  This is how {`<code>`} looks...
                  <br />
                  {`function myFunction() {
                    alert("Hello World!");
                  }`}
                </code>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.code ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("code")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.code ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${sections.code ? "active" : ""}`}
                  >
                    <ItemCSS
                      item={item.elements.code}
                      label={`code`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {item.elements.buttons &&
          item.elements.buttons.list &&
          !item.elements.buttons.disabled ? (
            <div className="item-element element-buttons">
              <div className="element-content">
                {item.elements.buttons.list.map((button, index) => (
                  <div className="element-button-content" key={button.id}>
                    <style>
                      {`
                        #styleguide #elements .btn {
                          border-radius: ${item.elements.buttons.borderRadius};
                          border-style: ${item.elements.buttons.borderStyle};
                          border-width: ${item.elements.buttons.borderWidth};
                          box-shadow: ${
                            item.elements.buttons.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "buttons",
                        "boxShadowColor"
                      )};
                          color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            "buttons",
                            "color"
                          )};
                          font-family: ${findElementFontVal(
                            item.elements,
                            item.fonts,
                            "buttons",
                            "fontFamily"
                          )};
                          font-size: ${item.elements.buttons.fontSize};
                          font-style: ${item.elements.buttons.fontStyle};
                          font-weight: ${item.elements.buttons.fontWeight};
                          padding: ${item.elements.buttons.padding};
                          transition: all ease 0.33s;
                        }
                        #styleguide #elements .btn:hover, #styleguide #elements .btn.hover {
                          box-shadow: ${
                            item.elements.buttonsHover.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "buttonsHover",
                        "boxShadowColor"
                      )};
                        }
                        #styleguide #elements .btn:active, #styleguide #elements .btn.active {
                          box-shadow: ${
                            item.elements.buttonsActive.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "buttonsActive",
                        "boxShadowColor"
                      )};
                        }
                        #styleguide #elements .btn.disabled {
                          box-shadow: none;
                          cursor: default;
                        }
                        #styleguide #elements .btn.btn-${button.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()} {
                          background: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}]`,
                            "background"
                          )};
                          border-color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}]`,
                            "borderColor"
                          )};
                          box-shadow: ${
                            item.elements.buttons.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        `buttons.list[${index}]`,
                        "boxShadowColor"
                      )};
                          color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}]`,
                            "color"
                          )};
                        }
                        #styleguide #elements .btn.btn-${button.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}:hover, #styleguide #elements .btn.btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()}.hover {
                          background: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.hover`,
                            "background"
                          )};
                          border-color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.hover`,
                            "borderColor"
                          )};
                          box-shadow: ${
                            item.elements.buttonsHover.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        `buttons.list[${index}].states.hover`,
                        "boxShadowColor"
                      )};
                          color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.hover`,
                            "color"
                          )};
                        }
                        #styleguide #elements .btn.btn-${button.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}:active, #styleguide #elements .btn.btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()}.active {
                          background: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.active`,
                            "background"
                          )};
                          border-color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.active`,
                            "borderColor"
                          )};
                          box-shadow: ${
                            item.elements.buttonsActive.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        `buttons.list[${index}].states.active`,
                        "boxShadowColor"
                      )};
                          color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.active`,
                            "color"
                          )};
                        }
                        #styleguide #elements .btn.btn-${button.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}.disabled {
                          background: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.disabled`,
                            "background"
                          )};
                          border-color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.disabled`,
                            "borderColor"
                          )};
                          box-shadow: ${
                            item.elements.buttonsDisabled.boxShadow
                          } ${findElementColorVal(
                        item.elements,
                        item.palette,
                        `buttons.list[${index}].states.disabled`,
                        "boxShadowColor"
                      )};
                          color: ${findElementColorVal(
                            item.elements,
                            item.palette,
                            `buttons.list[${index}].states.disabled`,
                            "color"
                          )};
                        }
                      `}
                    </style>
                    <button
                      className={`btn btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()} normal`}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {button.name}
                    </button>
                    <button
                      className={`btn btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()} hover`}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      Hover
                    </button>
                    <button
                      className={`btn btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()} active`}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      Active
                    </button>
                    <button
                      className={`btn btn-${button.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()} disabled`}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      Disabled
                    </button>
                  </div>
                ))}
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.buttons ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("buttons")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.buttons ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${
                      sections.buttons ? "active" : ""
                    }`}
                  >
                    <ItemCSS
                      item={item.elements.buttons}
                      label={`button`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.buttonsHover}
                      label={`button:hover`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.buttonsActive}
                      label={`button:active`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.buttonsDisabled}
                      label={`button[disabled]`}
                      findStyleVal={findStyleVal}
                    />
                    {item.elements.buttons && item.elements.buttons.list
                      ? item.elements.buttons.list.map((button, index) => (
                          <span key={index}>
                            <ItemCSS
                              item={button}
                              label={`button.${button.name.toLowerCase()}`}
                              shadow={item.elements.buttons}
                              findStyleVal={findStyleVal}
                            />
                            <ItemCSS
                              item={button.states.hover}
                              label={`button.${button.name.toLowerCase()}:hover`}
                              shadow={item.elements.buttonsHover}
                              findStyleVal={findStyleVal}
                            />
                            <ItemCSS
                              item={button.states.active}
                              label={`button.${button.name.toLowerCase()}:active`}
                              shadow={item.elements.buttonsActive}
                              findStyleVal={findStyleVal}
                            />
                            <ItemCSS
                              item={button.states.disabled}
                              label={`button.${button.name.toLowerCase()}[disabled]`}
                              shadow={item.elements.buttonsDisabled}
                              findStyleVal={findStyleVal}
                            />
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.dropdownMenu.disabled ? (
            <div className="item-element element-dropdowns">
              <div className="element-content">
                <style>
                  {`
                    #styleguide #elements .element-dropdowns .element-content .dropdown .menu {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownMenu",
                        "background"
                      )};
                      border-radius: ${item.elements.dropdownMenu.borderRadius};
                      box-shadow: ${
                        item.elements.dropdownMenu.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "dropdownMenu",
                    "boxShadowColor"
                  )};
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .menu:before {
                      border-bottom-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownMenu",
                        "background"
                      )};
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .trigger {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownButton",
                        "background"
                      )};
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownButton",
                        "borderColor"
                      )};
                      border-radius: ${
                        item.elements.dropdownButton.borderRadius
                      };
                      border-style: ${item.elements.dropdownButton.borderStyle};
                      border-width: ${item.elements.dropdownButton.borderWidth};
                      box-shadow: ${
                        item.elements.dropdownButton.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "dropdownButton",
                    "boxShadowColor"
                  )};
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownButton",
                        "color"
                      )};
                      padding: ${item.elements.dropdownButton.padding};
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .trigger:hover {
                      box-shadow: ${
                        item.elements.buttonsHover.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "dropdownButtonHover",
                    "boxShadowColor"
                  )};
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .trigger:active, #styleguide #elements .element-dropdowns .element-content .dropdown .trigger.active {
                      box-shadow: ${
                        item.elements.buttonsActive.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "dropdownButtonActive",
                    "boxShadowColor"
                  )};
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .item .link {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownItem",
                        "background"
                      )};
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownItem",
                        "color"
                      )};
                      font-family: ${findElementFontVal(
                        item.elements,
                        item.fonts,
                        "buttons",
                        "fontFamily"
                      )};
                      font-size: ${item.elements.dropdownItem.fontSize};
                      font-weight: ${item.elements.buttons.fontWeight};
                      padding: ${item.elements.dropdownItem.padding};
                      text-decoration: ${
                        item.elements.dropdownItem.textDecoration
                      };
                      transition: background ease 0.33s, color ease 0.33s;
                    }
                    #styleguide #elements .element-dropdowns .element-content .dropdown .item:hover .link, #styleguide #elements .element-dropdowns .element-content .dropdown .item.hover .link {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownItemHover",
                        "background"
                      )};
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "dropdownItemHover",
                        "color"
                      )};
                      text-decoration: ${
                        item.elements.dropdownItemHover.textDecoration
                      };
                    }
                  `}
                </style>
                <div className="element-dropdown-content">
                  <Menu>
                    <Dropdown
                      item
                      trigger={
                        <button
                          className="btn btn-white trigger"
                          onClick={(event) => event.preventDefault()}
                        >
                          Dropdown <i className="icon icon-chevron-down" />
                        </button>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item One</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Two</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Three</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Four</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                  <Menu>
                    <Dropdown
                      item
                      disabled
                      className="active"
                      trigger={
                        <button
                          className="btn btn-white trigger active"
                          onClick={(event) => event.preventDefault()}
                        >
                          Active dropdown{" "}
                          <i className="icon icon-chevron-down" />
                        </button>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item One</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Two</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Three</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Four</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                  <Menu>
                    <Dropdown
                      item
                      disabled
                      className="active hover"
                      trigger={
                        <button
                          className="btn btn-white trigger active"
                          onClick={(event) => event.preventDefault()}
                        >
                          Hovered dropdown{" "}
                          <i className="icon icon-chevron-down" />
                        </button>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item One</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item hover">
                          <span className="link">Dropdown Item Two</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Three</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="menu-item">
                          <span className="link">Dropdown Item Four</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                </div>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.dropdowns ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("dropdowns")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.dropdowns ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${
                      sections.dropdowns ? "active" : ""
                    }`}
                  >
                    <ItemCSS
                      item={item.elements.dropdownButton}
                      label={`.dropdown button`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.dropdownButtonHover}
                      label={`.dropdown button:hover`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.dropdownButtonActive}
                      label={`.dropdown button:active`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.dropdownMenu}
                      label={`.dropdown .menu`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.dropdownItem}
                      label={`.dropdown .item`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.dropdownItemHover}
                      label={`.dropdown .item:hover`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.input.disabled ? (
            <div className="item-element element-inputs">
              <div className="element-content">
                <style>
                  {`
                    #styleguide #elements .textarea-group textarea {
                      resize: none;
                    }
                    #styleguide #elements .input-group input, 
                    #styleguide #elements .textarea-group textarea {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "input",
                        "background"
                      )};
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "input",
                        "borderColor"
                      )};
                      border-radius: ${item.elements.input.borderRadius};
                      border-style: ${item.elements.input.borderStyle};
                      border-width: ${item.elements.input.borderWidth};
                      box-shadow: ${
                        item.elements.input.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "input",
                    "boxShadowColor"
                  )};
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "input",
                        "color"
                      )};
                      font-family: ${findElementFontVal(
                        item.elements,
                        item.fonts,
                        "input",
                        "fontFamily"
                      )};
                      font-size: ${item.elements.input.fontSize};
                      font-style: ${item.elements.input.fontStyle};
                      font-weight: ${item.elements.input.fontWeight};
                      line-height: ${item.elements.input.lineHeight};
                      padding: ${item.elements.input.padding};
                      transition: all ease 0.33s;
                    }
                    #styleguide #elements .input-group input:focus, #styleguide #elements .input-group input.focus,
                    #styleguide #elements .textarea-group textarea:focus, #styleguide #elements .textarea-group textarea.focus {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "inputFocus",
                        "background"
                      )};
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "inputFocus",
                        "borderColor"
                      )};
                      box-shadow: ${
                        item.elements.inputFocus.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "inputFocus",
                    "boxShadowColor"
                  )};
                    }
                    #styleguide #elements .input-group input.error,
                    #styleguide #elements .textarea-group textarea.error {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "inputError",
                        "background"
                      )};
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "inputError",
                        "borderColor"
                      )};
                      box-shadow: ${
                        item.elements.inputError.boxShadow
                      } ${findElementColorVal(
                    item.elements,
                    item.palette,
                    "inputError",
                    "boxShadowColor"
                  )};
                    }
                  `}
                </style>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={inputText}
                    placeholder="Text Input"
                    onChange={(event) => setInputText(event.target.value)}
                  />
                  <input
                    type="text"
                    className="input focus"
                    value={inputText}
                    placeholder="Text Input Focus"
                    onChange={(event) => setInputText(event.target.value)}
                  />
                  <input
                    type="text"
                    className="input error"
                    value={inputText}
                    placeholder="Text Input Error"
                    onChange={(event) => setInputText(event.target.value)}
                  />
                </div>
                <div className="textarea-group">
                  <textarea
                    rows="4"
                    className="textarea"
                    value={textareaText}
                    placeholder="Textarea"
                    onChange={(event) => setTextareaText(event.target.value)}
                  />
                  <textarea
                    rows="4"
                    className="textarea focus"
                    value={textareaText}
                    placeholder="Textarea"
                    onChange={(event) => setTextareaText(event.target.value)}
                  />
                  <textarea
                    rows="4"
                    className="textarea error"
                    value={textareaText}
                    placeholder="Textarea"
                    onChange={(event) => setTextareaText(event.target.value)}
                  />
                </div>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.inputs ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("inputs")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.inputs ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${sections.inputs ? "active" : ""}`}
                  >
                    <ItemCSS
                      item={item.elements.input}
                      label={`input, textarea`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.inputFocus}
                      label={`input:focus, textarea:focus`}
                      findStyleVal={findStyleVal}
                    />
                    <ItemCSS
                      item={item.elements.inputError}
                      label={`input.error, textarea.error`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!item.elements.toggle.disabled ? (
            <div className="item-element element-toggles">
              <div className="element-content">
                <style>
                  {`
                    #styleguide #elements .checkbox input ~ label, #styleguide #elements .checkbox input:checked ~ label {
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "color"
                      )};
                      font-family: ${findElementFontVal(
                        item.elements,
                        item.fonts,
                        "toggle",
                        "fontFamily"
                      )};
                      font-style: ${item.elements.toggle.fontStyle};
                      font-weight: ${item.elements.toggle.fontWeight};
                    }
                    #styleguide #elements .checkbox.disabled input ~ label, #styleguide #elements .checkbox.disabled input:checked ~ label {
                      color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggleDisabled",
                        "color"
                      )};
                    }
                    #styleguide #elements .checkbox input:checked ~ label:after {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "borderColor"
                      )};
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "borderColor"
                      )};
                    }
                    #styleguide #elements .checkbox.toggle.disabled input ~ label:before, #styleguide #elements .checkbox.slider.disabled input ~ label:before {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggleDisabled",
                        "background"
                      )};
                    }
                    #styleguide #elements .checkbox.toggle.disabled input ~ label:after, #styleguide #elements .checkbox.slider.disabled input ~ label:after {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggleDisabled",
                        "borderColor"
                      )};
                    }
                    #styleguide #elements .checkbox.toggle input:checked ~ label:before, #styleguide #elements .checkbox.slider input:checked ~ label:before {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "background"
                      )};
                    }
                    #styleguide #elements .checkbox.toggle input:checked ~ label:after, #styleguide #elements .checkbox.slider input:checked ~ label:after {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "borderColor"
                      )};
                    }
                    #styleguide #elements .checkbox.radio input:checked ~ label:before {
                      background: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "borderColor"
                      )};
                    }
                    #styleguide #elements .checkbox.radio input:checked ~ label:after {
                      border-color: ${findElementColorVal(
                        item.elements,
                        item.palette,
                        "toggle",
                        "borderColor"
                      )};
                    }
                  `}
                </style>
                <div className="toggle-group">
                  <Checkbox
                    toggle
                    label="Toggle"
                    className={!checkBoxToggle ? "disabled" : ""}
                    checked={checkBoxToggle}
                    onChange={() => setCheckBoxToggle(!checkBoxToggle)}
                  />
                  <Checkbox
                    toggle
                    label="Toggle"
                    className={checkBoxToggle ? "disabled" : ""}
                    checked={!checkBoxToggle}
                    onChange={() => setCheckBoxToggle(!checkBoxToggle)}
                  />
                </div>
                <div className="toggle-group">
                  <Checkbox
                    slider
                    label="Slider"
                    className={!checkBoxSlider ? "disabled" : ""}
                    checked={checkBoxSlider}
                    onChange={() => setCheckBoxSlider(!checkBoxSlider)}
                  />
                  <Checkbox
                    slider
                    label="Slider"
                    className={checkBoxSlider ? "disabled" : ""}
                    checked={!checkBoxSlider}
                    onChange={() => setCheckBoxSlider(!checkBoxSlider)}
                  />
                </div>
                <div className="toggle-group">
                  <Checkbox
                    label="Checkbox"
                    className={!checkBoxOne ? "disabled" : ""}
                    checked={checkBoxOne}
                    onChange={() => setCheckBoxOne(!checkBoxOne)}
                  />
                  <Checkbox
                    label="Checkbox"
                    className={!checkBoxTwo ? "disabled" : ""}
                    checked={checkBoxTwo}
                    onChange={() => setCheckBoxTwo(!checkBoxTwo)}
                  />
                </div>
                <div className="toggle-group">
                  <Checkbox
                    radio
                    label="Radio"
                    className={!radioBtn ? "disabled" : ""}
                    checked={radioBtn}
                    onChange={() => setRadioBtn(!radioBtn)}
                  />
                  <Checkbox
                    radio
                    label="Radio"
                    className={!radioBtn ? "disabled" : ""}
                    checked={!radioBtn}
                    onChange={() => setRadioBtn(!radioBtn)}
                  />
                </div>
                <div className="element-css-wrap">
                  <button
                    className={`btn btn-element-css ${
                      sections.toggles ? "active" : ""
                    }`}
                    onClick={() => toggleCSS("toggles")}
                  >
                    <i className="icon icon-code" />{" "}
                    {sections.toggles ? "hide" : "show"} css
                  </button>
                  <div
                    className={`element-css ${
                      sections.toggles ? "active" : ""
                    }`}
                  >
                    <ItemCSS
                      item={item.elements.toggle}
                      label={`.toggle`}
                      findStyleVal={findStyleVal}
                    />

                    <ItemCSS
                      item={item.elements.toggleDisabled}
                      label={`.toggle.disabled`}
                      findStyleVal={findStyleVal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <TextContentDisplay content={item.content} child={"elements"} node={1} />
    </div>
  );
};

const StyleguideFiles = ({ item, loadItem, setItemLoadError }) => (
  <div id="files" className="item-inspiration-files sub-content">
    <h2>Files</h2>
    <TextContentDisplay content={item.content} child={"files"} node={0} />
    <div className="styleguide-files item-files">
      <ul className="files-list cards-list">
        {item.files.map((file, index) => (
          <li key={index} title={file.name} className="card selected item-file">
            <FileDisplay
              file={file}
              loadItem={loadItem}
              setItemLoadError={setItemLoadError}
            />
          </li>
        ))}
      </ul>
    </div>
    <TextContentDisplay content={item.content} child={"files"} node={1} />
  </div>
);

export default StyleguidePublished;
