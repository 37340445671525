import React, { Component } from "react";
import { connect } from "react-redux";
import { auth } from "../../firebase";

class LogoutButton extends Component {
  logoutUser = () => {
    const { setUserMembership } = this.props;
    setUserMembership(null);
    auth.doSignOut();
  };
  render() {
    return (
      <span className="logout">
        <button
          className="btn btn-accent"
          type="button"
          onClick={this.logoutUser}
        >
          logout
        </button>
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserMembership: (membership) =>
    dispatch({ type: "USER_MEMBERSHIP", membership }),
});

export default connect(null, mapDispatchToProps)(LogoutButton);
