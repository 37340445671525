import React, { useState, useEffect, useRef, useCallback } from "react";
import TextContent from "./TextContent";
import { HeadersElement } from "./Elements/HeadersElement";
import { ParagraphElement } from "./Elements/ParagraphElement";
import { LinkElement } from "./Elements/LinkElement";
import { BlockquoteElement } from "./Elements/BlockquoteElement";
import { CodeElement } from "./Elements/CodeElement";
import { ButtonsElement } from "./Elements/ButtonsElement";
import { DropdownsElement } from "./Elements/DropdownsElement";
import { InputsElement } from "./Elements/InputsElement";
import { TogglesElement } from "./Elements/TogglesElement";
import { Popup, Menu, Dropdown, Checkbox } from "semantic-ui-react";
import {
  useMountEffect,
  capString,
  customPresetColors,
  headerText,
  helpText,
  camelToDash,
  colorTypes,
  borderStyles,
  findElementColorVal,
  findElementFontVal,
  findElementFontVariants,
  findElementStyleVal,
  findNormalFontWeight,
  findNormalFontStyle,
} from "./Resources";
import { SketchPicker } from "react-color";
import tinycolor from "tinycolor2";
import shortid from "shortid";
import _get from "lodash.get";
import _cloneDeep from "lodash.clonedeep";

export const Elements = (props) => {
  const {
    id,
    type,
    label,
    step,
    doneStep,
    item,
    item: { elements, fonts, palette, doneList },
    setItem,
    saveItem,
  } = props;

  const fontsInit = useRef(fonts);
  const elementsInit = useRef(elements);

  const [sections, setSections] = useState({
    headers: { active: false, css: false },
    p: { active: false, css: false },
    a: { active: false, css: false },
    blockquote: { active: false, css: false },
    code: { active: false, css: false },
    buttons: { active: false, css: false },
    dropdowns: { active: false, css: false },
    inputs: { active: false, css: false },
    toggles: { active: false, css: false },
  });

  const toggleList = [
    "headers",
    "p",
    "a",
    "blockquote",
    "code",
    "buttons",
    "dropdownMenu",
    "input",
    "toggle",
  ];

  const headerTypes = ["h1", "h2", "h3", "h4", "h5"];

  useMountEffect(() => {
    const elementsClone = _cloneDeep(elements);
    validateFontStyles(elementsClone);
    if (!elements.toggle.background && palette.colors && doneList.palette)
      elementsClone.toggle.background = getInitTrackColor();
    if (!elements.buttons.list && palette.colors && doneList.palette)
      elementsClone.buttons.list = getInitButtons();
    setItem({ ...item, elements: elementsClone });
  });

  const validateFontStyles = useCallback(
    (elements) => {
      Object.keys(elements).forEach((element) => {
        let elementFont =
          element === "a" || element === "aHover" || element === "aActive"
            ? elements["p"].font
            : elements[element].font;
        let elementFontWeight =
          element === "a" || element === "aHover" || element === "aActive"
            ? elements["p"].fontWeight
            : elements[element].fontWeight;
        let elementFontStyle =
          element === "a" || element === "aHover" || element === "aActive"
            ? elements["p"].fontStyle
            : elements[element].fontStyle;
        if (elementFont >= fonts.length) {
          elements[element].font = 0;
          elementFont = 0;
        }

        if (fonts[elementFont] && fonts[elementFont].variantData) {
          const findVarFont = fonts[elementFont].variantData.filter(
            (variant) =>
              variant.weight === elementFontWeight &&
              variant.style === elementFontStyle
          );
          if (findVarFont && findVarFont[0] && !findVarFont[0].active) {
            elements[element].fontWeight = findNormalFontWeight(
              fonts[elementFont]
            );
            elements[element].fontStyle = findNormalFontStyle(
              fonts[elementFont]
            );
          }
        }
      });
      return elements;
    },
    [fonts]
  );

  useEffect(() => {
    if (JSON.stringify(fontsInit.current) !== JSON.stringify(fonts)) {
      fontsInit.current = fonts;
      setItem({ ...item, elements: validateFontStyles(_cloneDeep(elements)) });
    }
  }, [item, setItem, elements, fonts, validateFontStyles]);

  const getInitTrackColor = useCallback(() => {
    if (
      ["primary", "secondary", "accent"].indexOf(
        elements.toggle.borderColor
      ) !== -1
    ) {
      return tinycolor(palette.colors[elements.toggle.borderColor])
        .setAlpha(0.25)
        .toRgbString();
    } else {
      return tinycolor(elements.toggle.borderColor)
        .setAlpha(0.25)
        .toRgbString();
    }
  }, [palette, elements]);

  useEffect(() => {
    if (
      !elements.toggle.background &&
      elements.toggle.borderColor !== elementsInit.current.toggle.borderColor
    ) {
      elementsInit.current.toggle = elements.toggle;
      setItem({
        ...item,
        elements: {
          ...elements,
          toggle: {
            ...elements.toggle,
            background: getInitTrackColor(),
          },
        },
      });
    }
  }, [item, setItem, elements, getInitTrackColor]);

  const getInitButtons = () => {
    let buttonsList = [];
    if (palette.colors && Object.keys(palette.colors).length === 3) {
      Object.keys(palette.colors)
        .sort((a, b) => colorTypes.indexOf(a) - colorTypes.indexOf(b))
        .map(
          (color, index) =>
            (buttonsList[index] = {
              id: shortid.generate(),
              name: `${capString(color)}`,
              background: `${color}`,
              borderColor: `${color}`,
              boxShadowColor: "rgba(0,0,0,0.15)",
              color: "#ffffff",
              states: {
                hover: {
                  background: tinycolor(palette.colors[color])
                    .darken(10)
                    .toHexString(),
                  borderColor: tinycolor(palette.colors[color])
                    .darken(10)
                    .toHexString(),
                  boxShadowColor: "rgba(0,0,0,0.25)",
                  color: "#ffffff",
                },
                active: {
                  background: tinycolor(palette.colors[color])
                    .lighten(10)
                    .toHexString(),
                  borderColor: tinycolor(palette.colors[color])
                    .lighten(10)
                    .toHexString(),
                  boxShadowColor: "rgba(0,0,0,0.15)",
                  color: "#ffffff",
                },
                disabled: {
                  background: tinycolor(palette.colors[color])
                    .setAlpha(0.25)
                    .toRgbString(),
                  borderColor: "rgba(0,0,0,0)",
                  boxShadowColor: "rgba(0,0,0,0.15)",
                  color: "#333333",
                },
              },
            })
        );
    }
    return buttonsList;
  };

  const editElements = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        elements: false,
      },
    });
  };

  const toggleSection = (section, bool) => {
    setSections({
      ...sections,
      [section]: {
        active: bool ? true : !sections[section].active,
        css: false,
      },
    });
  };

  const toggleCSS = (section) => {
    setSections({
      ...sections,
      [section]: {
        ...sections[section],
        css: !sections[section].css,
      },
    });
  };

  const updateFont = (element, update) => {
    const elementsClone = _cloneDeep(elements);
    if (elementsClone[element].font !== update) {
      elementsClone[element].font = update;
      elementsClone[element].fontStyle = findNormalFontStyle(fonts[update]);
      elementsClone[element].fontWeight = findNormalFontWeight(fonts[update]);
      if (element === "headers") {
        headerTypes.forEach((header) => {
          elementsClone[header].font = update;
          elementsClone[header].fontStyle = findNormalFontStyle(fonts[update]);
          elementsClone[header].fontWeight = findNormalFontWeight(
            fonts[update]
          );
        });
      }
    }
    setItem({ ...item, elements: elementsClone });
  };

  const updateWeight = (element, update) => {
    const elementsClone = _cloneDeep(elements);
    elementsClone[element].fontStyle = findStyleMatch(update);
    elementsClone[element].fontWeight = findWeightMatch(update);
    if (element === "headers") {
      headerTypes.forEach((header) => {
        elementsClone[header].fontStyle = findStyleMatch(update);
        elementsClone[header].fontWeight = findWeightMatch(update);
      });
    }
    setItem({ ...item, elements: elementsClone });
  };

  const findStyleMatch = (update) => {
    if (update.indexOf("italic") !== -1) {
      return "italic";
    } else {
      return "normal";
    }
  };

  const findWeightMatch = (update) => {
    if (update !== "italic") {
      return update.replace(" italic", "");
    } else {
      return "normal";
    }
  };

  const updateColor = (element, type, update) => {
    const elementsClone = _cloneDeep(elements);
    _get(elementsClone, element)[type] = findColorMatch(type, update);
    if (element === "headers") {
      elementsClone.headers[type] = findColorMatch(type, update);
      elementsClone.h1[type] = findColorMatch(type, update);
      elementsClone.h2[type] = findColorMatch(type, update);
      elementsClone.h3[type] = findColorMatch(type, update);
      elementsClone.h4[type] = findColorMatch(type, update);
      elementsClone.h5[type] = findColorMatch(type, update);
    } else if (
      element === "buttons" ||
      element === "buttonsHover" ||
      item === "buttonsActive" ||
      item === "buttonsDisabled"
    ) {
      elementsClone[item][type] = findColorMatch(type, update);
      Object.keys(elementsClone.buttons.list).forEach((button) => {
        if (element === "buttonsHover") {
          _get(elementsClone, `buttons.list[${button}].states.hover`)[type] =
            findColorMatch(type, update);
        } else if (item === "buttonsActive") {
          _get(elementsClone, `buttons.list[${button}].states.active`)[type] =
            findColorMatch(type, update);
        } else if (item === "buttonsDisabled") {
          _get(elementsClone, `buttons.list[${button}].states.disabled`)[type] =
            findColorMatch(type, update);
        } else {
          _get(elementsClone, `buttons.list[${button}]`)[type] = findColorMatch(
            type,
            update
          );
        }
      });
    }
    setItem({ ...item, elements: elementsClone });
  };

  const findColorMatch = (type, update) => {
    if (
      colorTypes
        .map((e) => {
          return e;
        })
        .indexOf(update) !== -1
    ) {
      return update;
    } else {
      if (type === "color") {
        if (update.hex) {
          return update.hex;
        } else {
          return update;
        }
      } else {
        var color = [];
        if (update.rgb) {
          color.r = update.rgb.r;
          color.g = update.rgb.g;
          color.b = update.rgb.b;
          color.a = update.rgb.a;
        } else {
          var colorConvert = tinycolor(update).toRgb();
          color.r = colorConvert.r;
          color.g = colorConvert.g;
          color.b = colorConvert.b;
          color.a = colorConvert.a;
        }
        return `rgba(${color.r},${color.g},${color.b},${color.a})`;
      }
    }
  };

  const updateStyle = (element, type, update) => {
    const elementsClone = _cloneDeep(elements);
    _get(elementsClone, element)[type] = update;
    setItem({ ...item, elements: elementsClone });
  };

  const findColorVal = (element, type) => {
    return findElementColorVal(item.elements, item.palette, element, type);
  };

  const findFontVal = (element, type) => {
    return findElementFontVal(item.elements, item.fonts, element, type);
  };

  const findFontVariants = (element) => {
    return findElementFontVariants(item.elements, item.fonts, element);
  };

  const findStyleVal = (element, type) => {
    return findElementStyleVal(
      item.elements,
      item.fonts,
      item.palette,
      element,
      type
    );
  };

  const toggleElement = (element, update) => {
    setItem({
      ...item,
      elements: {
        ...elements,
        [element]: {
          ...elements[element],
          disabled: update ? null : true,
        },
      },
    });
  };

  const toggleElements = (event, bool) => {
    const elementsClone = _cloneDeep(elements);
    toggleList.forEach((toggle) => {
      elementsClone[toggle].disabled = bool;
    });
    setItem({ ...item, elements: elementsClone });
  };

  const doneElements = () => {
    doneStep("elements", step);
  };

  const defaultProps = {
    item: item,
    setItem: setItem,
    elements: elements,
    sections: sections,
    borderStyles: borderStyles,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    findColorVal: findColorVal,
    findStyleVal: findStyleVal,
    updateColor: updateColor,
    updateFont: updateFont,
    updateStyle: updateStyle,
    updateWeight: updateWeight,
    toggleCSS: toggleCSS,
    toggleSection: toggleSection,
    toggleElement: toggleElement,
    getInitButtons: getInitButtons,
  };

  const textContentProps = {
    id: id,
    type: type,
    setItem: setItem,
    item: item,
    child: "elements",
    saveItem: saveItem,
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.elements ? "done" : ""
      } ${
        elements.headers.disabled &&
        elements.p.disabled &&
        elements.a.disabled &&
        elements.blockquote.disabled &&
        elements.code.disabled &&
        elements.buttons.disabled &&
        elements.dropdownMenu.disabled &&
        elements.input.disabled &&
        elements.toggle.disabled
          ? "none-selected"
          : ""
      }`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.elements[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={
              <p
                dangerouslySetInnerHTML={{ __html: helpText.elements[label] }}
              />
            }
          />
        </h2>

        <TextContent node={0} {...textContentProps} />

        {elements ? (
          <div id="elements" className="elements-wrap">
            <div className="elements-toggle">
              <h3>
                Elements to include:
                <div
                  className="btn btn-text btn-alert"
                  onClick={(event) => toggleElements(event, true)}
                >
                  disable all
                </div>
                <div
                  className="btn btn-text btn-success"
                  onClick={(event) => toggleElements(event, false)}
                >
                  enable all
                </div>
              </h3>
              <div className="elements-toggle-list">
                <Checkbox
                  toggle
                  label="Headers"
                  className={elements.headers.disabled ? "disabled" : ""}
                  checked={!elements.headers.disabled}
                  onChange={() =>
                    toggleElement("headers", elements.headers.disabled)
                  }
                />
                <Checkbox
                  toggle
                  label="Paragraph"
                  className={elements.p.disabled ? "disabled" : ""}
                  checked={!elements.p.disabled}
                  onChange={() => toggleElement("p", elements.p.disabled)}
                />
                <Checkbox
                  toggle
                  label="Links"
                  className={elements.a.disabled ? "disabled" : ""}
                  checked={!elements.a.disabled}
                  onChange={() => toggleElement("a", elements.a.disabled)}
                />
                <Checkbox
                  toggle
                  label="Blockquote"
                  className={elements.blockquote.disabled ? "disabled" : ""}
                  checked={!elements.blockquote.disabled}
                  onChange={() =>
                    toggleElement("blockquote", elements.blockquote.disabled)
                  }
                />
                <Checkbox
                  toggle
                  label="Code"
                  className={elements.code.disabled ? "disabled" : ""}
                  checked={!elements.code.disabled}
                  onChange={() => toggleElement("code", elements.code.disabled)}
                />
                <Checkbox
                  toggle
                  label="Buttons"
                  className={elements.buttons.disabled ? "disabled" : ""}
                  checked={!elements.buttons.disabled}
                  onChange={() =>
                    toggleElement("buttons", elements.buttons.disabled)
                  }
                />
                <Checkbox
                  toggle
                  label="Dropdowns"
                  className={elements.dropdownMenu.disabled ? "disabled" : ""}
                  checked={!elements.dropdownMenu.disabled}
                  onChange={() =>
                    toggleElement(
                      "dropdownMenu",
                      elements.dropdownMenu.disabled
                    )
                  }
                />
                <Checkbox
                  toggle
                  label="Inputs"
                  className={elements.input.disabled ? "disabled" : ""}
                  checked={!elements.input.disabled}
                  onChange={() =>
                    toggleElement("input", elements.input.disabled)
                  }
                />
                <Checkbox
                  toggle
                  label="Toggles"
                  className={elements.toggle.disabled ? "disabled" : ""}
                  checked={!elements.toggle.disabled}
                  onChange={() =>
                    toggleElement("toggle", elements.toggle.disabled)
                  }
                />
              </div>
            </div>
            <div className="elements-item elements-text">
              <div className="elements-content">
                {elements.headers ? <HeadersElement {...defaultProps} /> : null}
                {elements.p ? <ParagraphElement {...defaultProps} /> : null}
                {elements.a ? <LinkElement {...defaultProps} /> : null}
                {elements.blockquote ? (
                  <BlockquoteElement {...defaultProps} />
                ) : null}
                {elements.code ? <CodeElement {...defaultProps} /> : null}
              </div>
            </div>

            <div className="elements-item elements-form">
              <div className="elements-content">
                {elements.buttons ? <ButtonsElement {...defaultProps} /> : null}
                {elements.dropdownMenu ? (
                  <DropdownsElement {...defaultProps} />
                ) : null}
                {elements.input ? <InputsElement {...defaultProps} /> : null}
                {elements.toggle ? <TogglesElement {...defaultProps} /> : null}
              </div>
            </div>
          </div>
        ) : null}

        <p className="section-empty">
          No elements enabled, click here to enable them.
        </p>

        <TextContent node={1} {...textContentProps} />
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneElements}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editElements}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export const ItemColor = (props) => {
  const [colorActive, setColorActive] = useState(false);

  const { type, item, label, elements, palette, findColorVal, updateColor } =
    props;

  return (
    <div
      className={`edit-item-colors ${type} ${
        !palette ||
        !palette.colors ||
        (!palette.colors.primary &&
          !palette.colors.secondary &&
          !palette.colors.accent &&
          (!palette.altColors || palette.altColors.length === 0))
          ? "no-brand"
          : ""
      }`}
    >
      <div
        className={`item-palette-picker ${
          _get(elements, item)[label] === "rgba(0,0,0,0)" ? "transparent" : ""
        } ${colorActive ? "active" : ""}`}
        style={{ background: findColorVal(item, type) }}
      >
        <Menu className="item-color-wrap">
          <Dropdown
            item
            closeOnChange={false}
            onOpen={() => setColorActive(true)}
            onClose={() => setColorActive(false)}
            trigger={<span className="item-color-trigger" />}
            options={[
              {
                key: item,
                content: colorActive ? (
                  <div
                    className="color-picker"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <SketchPicker
                      color={findColorVal(item, type)}
                      presetColors={customPresetColors}
                      onChangeComplete={(color) =>
                        updateColor(item, type, color)
                      }
                      disableAlpha={type === "color" ? true : false}
                    />
                    {palette &&
                    ((palette.colors &&
                      (palette.colors.primary ||
                        palette.colors.secondary ||
                        palette.colors.accent)) ||
                      (palette.altColors && palette.altColors.length > 0)) ? (
                      <div className="color-picker-brand">
                        <label>
                          Brand
                          <br />
                          Colors
                        </label>
                        {palette && palette.colors && palette.colors.primary ? (
                          <div
                            className="color-brand"
                            style={{ background: palette.colors.primary }}
                            onClick={() => updateColor(item, type, "primary")}
                          />
                        ) : null}
                        {palette &&
                        palette.colors &&
                        palette.colors.secondary ? (
                          <div
                            className="color-brand"
                            style={{ background: palette.colors.secondary }}
                            onClick={() => updateColor(item, type, "secondary")}
                          />
                        ) : null}
                        {palette && palette.colors && palette.colors.accent ? (
                          <div
                            className="color-brand"
                            style={{ background: palette.colors.accent }}
                            onClick={() => updateColor(item, type, "accent")}
                          />
                        ) : null}
                        {palette.altColors
                          ? Object.keys(palette.altColors).map((key) => (
                              <div
                                key={key}
                                className="color-brand"
                                style={{
                                  background: palette.altColors[key].color,
                                }}
                                onClick={() =>
                                  updateColor(
                                    item,
                                    type,
                                    palette.altColors[key].color
                                  )
                                }
                              />
                            ))
                          : null}
                      </div>
                    ) : null}
                  </div>
                ) : null,
              },
            ]}
          />
        </Menu>
      </div>
      {label ? <label>{label}</label> : null}
    </div>
  );
};

export const ItemBoxShadow = (props) => {
  const {
    item,
    label,
    elements,
    palette,
    findColorVal,
    updateStyle,
    updateColor,
  } = props;

  return (
    <div className="edit-item-input edit-box-shadow">
      <input
        type="string"
        value={
          elements[item] && elements[item].boxShadow
            ? elements[item].boxShadow
            : ""
        }
        onChange={(event) => updateStyle(item, "boxShadow", event.target.value)}
      />
      <ItemColor
        type="boxShadowColor"
        item={item}
        elements={elements}
        palette={palette}
        findColorVal={findColorVal}
        updateColor={updateColor}
      />
      <label>{label}</label>
    </div>
  );
};

export const ItemFont = (props) => {
  const { item, fonts, elements, findFontVal, updateFont } = props;

  return fonts && fonts.length > 0 ? (
    <Menu className="item-font-name">
      <Dropdown
        item
        trigger={
          <button
            className="btn btn-white trigger"
            onClick={(event) => event.preventDefault()}
          >
            {findFontVal(item, "fontName")}{" "}
            <i className="icon icon-chevron-down" />
          </button>
        }
      >
        <Dropdown.Menu>
          {item === "code" ? (
            <Dropdown.Item
              className={`menu-item ${
                elements.code.font === "monospace" ? "active" : ""
              }`}
            >
              <span
                className="link"
                onClick={() => updateFont("code", "monospace")}
              >
                monospace
              </span>
            </Dropdown.Item>
          ) : null}
          {fonts
            ? fonts.map((font, index) => (
                <Dropdown.Item
                  key={font.family ? font.family : font.name}
                  className={`menu-item ${
                    elements[item].font === index ? "active" : ""
                  }`}
                >
                  <span
                    className="link"
                    onClick={() => updateFont(item, index)}
                  >
                    {font.family ? font.family : font.name}
                  </span>
                </Dropdown.Item>
              ))
            : null}
        </Dropdown.Menu>
      </Dropdown>
      <label>font family</label>
    </Menu>
  ) : null;
};

export const ItemFontStyle = (props) => {
  const { item, elements, findFontVal, findFontVariants, updateWeight } = props;
  return findFontVal(
    item === "a" || item === "aHover" || item === "aActive" ? "p" : item,
    "fontType"
  ) !== "upload" ? (
    <Menu className="item-font-weight">
      <Dropdown
        item
        trigger={
          <button
            className="btn btn-white trigger"
            onClick={(event) => event.preventDefault()}
          >
            {elements[item].fontWeight}
            {elements[item].fontStyle === "italic" ? " italic" : ""}{" "}
            <i className="icon icon-chevron-down" />
          </button>
        }
      >
        <Dropdown.Menu>
          {findFontVariants(
            item === "a"
              ? "p"
              : item === "aHover"
              ? "p"
              : item === "aActive"
              ? "p"
              : item
          ).map((weight) => (
            <Dropdown.Item
              key={weight}
              className={`menu-item ${
                `${elements[item].fontWeight}${
                  elements[item].fontStyle === "italic" ? " italic" : ""
                }` === weight
                  ? "active"
                  : ""
              }`}
            >
              <span className="link" onClick={() => updateWeight(item, weight)}>
                {weight}
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <label>font style</label>
    </Menu>
  ) : null;
};

export const ItemFontSize = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input small edit-font-size">
      <input
        type="number"
        step="1"
        value={
          elements[item] && elements[item].fontSize
            ? elements[item].fontSize.replace("px", "")
            : ""
        }
        onChange={(event) =>
          updateStyle(item, "fontSize", event.target.value + "px")
        }
      />
      <label>font size</label>
    </div>
  );
};

export const ItemLineHeight = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input edit-line-height">
      <input
        type="number"
        step="0.5"
        value={elements[item].lineHeight}
        onChange={(event) =>
          updateStyle(item, "lineHeight", event.target.value)
        }
      />
      <label>line height</label>
    </div>
  );
};

export const ItemMargin = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input edit-margin">
      <input
        type="string"
        value={elements[item].margin}
        onChange={(event) => updateStyle(item, "margin", event.target.value)}
      />
      <label>margin</label>
    </div>
  );
};

export const ItemPadding = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input edit-padding">
      <input
        type="string"
        value={elements[item].padding}
        onChange={(event) => updateStyle(item, "padding", event.target.value)}
      />
      <label>padding</label>
    </div>
  );
};

export const ItemBorderStyle = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <Menu className="item-font-name">
      <Dropdown
        item
        trigger={
          <button
            className="btn btn-white trigger"
            onClick={(event) => event.preventDefault()}
          >
            {elements[item].borderStyle}{" "}
            <i className="icon icon-chevron-down" />
          </button>
        }
      >
        <Dropdown.Menu>
          {borderStyles.map((style) => (
            <Dropdown.Item
              key={style.type}
              className={`menu-item ${
                elements[item].borderStyle === style.type ||
                elements[item].borderStyle === style.style
                  ? "active"
                  : ""
              }`}
            >
              <span
                className="link"
                onClick={() => updateStyle(item, "borderStyle", style.type)}
              >
                {style.type ? style.type : style.type}
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <label>border style</label>
    </Menu>
  );
};

export const ItemBorderWidth = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input edit-border-width">
      <input
        type="string"
        value={elements[item].borderWidth}
        onChange={(event) =>
          updateStyle(item, "borderWidth", event.target.value)
        }
      />
      <label>border width</label>
    </div>
  );
};

export const ItemBorderRadius = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-input edit-border-radius">
      <input
        type="string"
        value={elements[item].borderRadius}
        onChange={(event) =>
          updateStyle(item, "borderRadius", event.target.value)
        }
      />
      <label>border radius</label>
    </div>
  );
};

export const ItemDecoration = (props) => {
  const { item, elements, updateStyle } = props;

  return (
    <div className="edit-item-decoration">
      <Checkbox
        label="Underlined"
        checked={elements[item].textDecoration === "underline" ? true : false}
        onChange={() =>
          updateStyle(
            item,
            "textDecoration",
            elements[item].textDecoration === "underline" ? "none" : "underline"
          )
        }
      />
    </div>
  );
};

export const ItemCSS = (props) => {
  const { item, label, shadow, findStyleVal } = props;

  return (
    <code>
      <span className="css-name">{label}</span>
      {`{`}
      {Object.keys(item).map((key) => {
        if (
          key !== "id" &&
          key !== "list" &&
          key !== "states" &&
          key !== "name" &&
          key !== "boxShadow" &&
          key !== "boxShadowColor"
        ) {
          return (
            <div key={key}>
              <span className="css-key">{camelToDash(key)}</span>
              <span className="css-punct">:</span>{" "}
              {findStyleVal(item[key], key)}
              <span className="css-punct">;</span>
            </div>
          );
        } else if (key === "boxShadowColor") {
          return (
            <div key={key}>
              <span className="css-key">box-shadow</span>
              <span className="css-punct">:</span>{" "}
              {shadow
                ? findStyleVal(shadow.boxShadow, key)
                : findStyleVal(item.boxShadow, key)}{" "}
              {findStyleVal(item[key], key)}
              <span className="css-punct">;</span>
            </div>
          );
        } else {
          return null;
        }
      })}
      {`}`}
    </code>
  );
};

export default Elements;
