import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { ContactForm } from "./Contact";
import * as routes from "constants/routes";

function Footer(props) {
  const [helpModal, setHelpModal] = useState(false);
  const { authUser } = props;

  return (
    <footer id="footer" className="footer-wrap">
      <Modal
        className="help-modal"
        open={helpModal}
        onClose={() => setHelpModal(false)}
      >
        <div className="modal-close-wrap">
          <span className="modal-close" onClick={() => setHelpModal(false)}>
            <i className="icon icon-arrow-left" />
          </span>
        </div>
        <Modal.Header>
          Tell us what's wrong and we'll get back to you as soon as possible.
        </Modal.Header>
        <Modal.Content>
          <ContactForm />
        </Modal.Content>
      </Modal>
      <div className="social-wrap">
        <a
          href="https://www.twitter.com/DesignGapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon icon-twitter" />
        </a>
      </div>
      <nav className="nav-footer">
        {authUser ? (
          <FooterNavAuth openHelpModal={() => setHelpModal(true)} />
        ) : (
          <FooterNavNonAuth openHelpModal={() => setHelpModal(true)} />
        )}
        <p className="footer-copy">
          &copy; {new Date().getFullYear()} &nbsp;-{" "}
          <a
            href="http://www.ftpcreative.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            FTP Creative, LLC
          </a>
        </p>
      </nav>
    </footer>
  );
}

const FooterNavAuth = ({ openHelpModal }) => (
  <ul>
    <li>
      <Link to={routes.Projects}>projects</Link>
    </li>
    <li>
      <Link to={routes.Styleguides}>styleguides</Link>
    </li>
    <li>
      <Link to={routes.Showcases}>showcases</Link>
    </li>
    {/* <li><Link to={routes.Personas}>personas</Link></li> */}
    <li>
      <Link to={routes.Account}>account</Link>
    </li>
    <li>
      <Link to={routes.Contact}>contact</Link>
    </li>
    <ul>
      <li>
        <Link to={routes.Terms}>terms</Link>
      </li>
      <li>
        <Link to={routes.Privacy}>privacy</Link>
      </li>
      <li>
        <span className="link" onClick={openHelpModal}>
          help
        </span>
      </li>
    </ul>
  </ul>
);

const FooterNavNonAuth = ({ openHelpModal }) => (
  <ul>
    <li className="demo-link">
      <Link to={routes.Demo}>demo</Link>
    </li>
    <li>
      <Link to={routes.About}>how it works</Link>
    </li>
    <li>
      <Link to={routes.Signup}>sign up</Link>
    </li>
    <li>
      <Link to={routes.Login}>log in</Link>
    </li>
    <li>
      <Link to={routes.Contact}>contact</Link>
    </li>
    <ul>
      <li>
        <Link to={routes.Terms}>terms</Link>
      </li>
      <li>
        <Link to={routes.Privacy}>privacy</Link>
      </li>
      <li>
        <span className="link" onClick={openHelpModal}>
          help
        </span>
      </li>
    </ul>
  </ul>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Footer);
