import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";
import { compose } from "recompose";
import { db, auth, storage, firebase } from "../../firebase";
import { updateProfile, updateEmail } from "firebase/auth";
import { Modal, Confirm, Input, Popup } from "semantic-ui-react";
import { LoginForm } from "components/users/Login";
import { ContactForm } from "components/core/Contact";
import { findInitials, getStripeKey } from "../shared/Resources";
import { membershipData } from "./MembershipData";
import { PasswordChangeForm } from "./PasswordChange";
import withAuthorization from "components/withAuthorization";
import * as routes from "constants/routes";
import ReactBody from "react-body";
import ProgressiveImage from "react-progressive-image";
import AvatarEditor from "react-avatar-editor";
import Moment from "react-moment";
import _debounce from "lodash.debounce";
import validator from "email-validator";
import {
  StripeProvider,
  injectStripe,
  Elements,
  CardElement,
} from "react-stripe-elements";
import { Helmet } from "react-helmet-async";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.debounceUsers = _debounce(this.findUsers, 150);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initUserData();
  }

  setMembership = () => {
    const { authUser, userMembership, setUserMembership } = this.props;
    if (!userMembership) {
      return db.getUserMembership(authUser.uid).then((snapshot) => {
        setUserMembership(snapshot.val() ? snapshot.val() : "none");
        if (
          userMembership &&
          userMembership.subscription &&
          userMembership.subscription.plan.product ===
            membershipData.products.team.id
        ) {
          this.findTeamMembers();
        }
        if (userMembership && userMembership.status === "invitedTeam") {
          this.findTeamLeadName(userMembership.team_lead);
        }
      });
    } else return null;
  };

  initUserData = () => {
    const { authUser } = this.props;
    if (authUser.emailVerified) {
      return db.getUser(authUser.uid).then((result) => {
        this.setState(
          {
            userData: result.val(),
            profileImageLoad: false,
            updatedName: result.val().username,
            updatedEmail: result.val().email,
          },
          () => {
            db.getCustomerID(authUser.uid).then((member) => {
              this.setState({ memberID: member.val() }, () => {
                this.setMembership();
              });
            });
          }
        );
      });
    } else {
      return null;
    }
  };

  findTeamMembers = () => {
    const { authUser } = this.props;
    const { userData, teamCount } = this.state;
    let teamMembers = [];
    teamMembers.push({
      uid: authUser.uid,
      profileImage: userData.profileImage,
      username: userData.username,
      email: authUser.email,
      active: "lead",
    });
    return db.getTeamMembers(authUser.uid).then((snapshot) => {
      if (snapshot.val() !== null) {
        let teamList = Object.keys(snapshot.val()).map((member) => {
          if (member.indexOf("new_user") === -1) {
            return new Promise((resolve) => {
              db.getUser(member).then((result) => {
                if (result && result.val()) {
                  teamMembers.push({
                    uid: member,
                    profileImage: result.val().profileImage,
                    username: result.val().username,
                    email: result.val().email,
                    active: snapshot.val()[member],
                  });
                  resolve();
                } else {
                  resolve();
                }
              });
            });
          } else {
            return new Promise((resolve) => {
              db.getInvitedNewUser(member).then((result) => {
                if (result && result.val()) {
                  teamMembers.push({
                    email: result.val().email,
                    active: snapshot.val()[member],
                  });
                  resolve();
                } else {
                  resolve();
                }
              });
            });
          }
        });
        return Promise.all(teamList)
          .then(() => {
            const emptySpots = teamCount - teamList.length - 1;
            for (var i = 0; i < emptySpots; i++) {
              teamMembers.push("empty");
            }
            return this.setState({ teamMembersList: teamMembers });
          })
          .catch((error) => console.log(error));
      } else {
        for (var j = 0; j < teamCount - 1; j++) {
          teamMembers.push("empty");
        }
        return this.setState({ teamMembersList: teamMembers });
      }
    });
  };

  openUserLoginModal = () => {
    this.setState({
      userLoginOpen: true,
      userDeleteConfirmOpen: false,
    });
  };

  closeUserLoginModal = () => {
    this.setState({
      userLoginOpen: false,
    });
  };

  editName = (event) => {
    event.preventDefault();
    this.setState(
      {
        isEditName: true,
      },
      () => {
        document.getElementById("input-profile-username").focus();
      }
    );
  };

  editCancelName = (event) => {
    event.preventDefault();
    this.setState({
      isEditName: false,
    });
  };

  editEmail = (event) => {
    event.preventDefault();
    this.setState(
      {
        isEditEmail: true,
      },
      () => {
        document.getElementById("input-profile-email").focus();
      }
    );
  };

  editCancelEmail = (event) => {
    event.preventDefault();
    this.setState({
      isEditEmail: false,
    });
  };

  saveName = (event) => {
    event.preventDefault();
    const { authUser, setUserName } = this.props;
    const { updatedName } = this.state;
    if (authUser.displayName !== updatedName) {
      updateProfile(authUser, {
        displayName: updatedName,
      })
        .then(() => {
          db.saveName(authUser.uid, updatedName).then(() => {
            this.setState({
              isEditName: false,
              updatedName: authUser.displayName,
            });
            setUserName(authUser.displayName);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        isEditName: false,
      });
    }
  };

  saveEmailConfirm = (event) => {
    event.preventDefault();
    const { userData, updatedEmail } = this.state;
    if (userData.email !== updatedEmail) {
      this.setState(
        {
          accountAction: "email",
        },
        () => {
          this.openUserLoginModal();
        }
      );
    } else {
      this.setState({
        isEditEmail: false,
      });
    }
  };

  saveEmail = () => {
    const { authUser } = this.props;
    const { userData, updatedEmail } = this.state;
    if (userData.email !== updatedEmail) {
      this.setState(
        {
          isEditEmailLoad: true,
        },
        () => {
          updateEmail(authUser, updatedEmail)
            .then(() => {
              const emailVerified =
                userData.email.toLowerCase() !== updatedEmail.toLowerCase()
                  ? false
                  : true;
              firebase.updateStripeCustomerEmail({
                uid: authUser.uid,
                email: updatedEmail,
              });
              firebase
                .updateUserEmail({
                  uid: authUser.uid,
                  email: updatedEmail,
                  verified: emailVerified,
                })
                .then(() => {
                  if (!emailVerified) {
                    this.setState(
                      {
                        isEditEmail: false,
                      },
                      () => {
                        const { history } = this.props;
                        auth
                          .doSendEmailVerification()
                          .then(() => {
                            history.push(routes.Verify);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    );
                  } else {
                    const userDataCopy = { ...userData };
                    userDataCopy.email = updatedEmail;
                    this.setState({
                      isEditEmail: false,
                      isEditEmailLoad: false,
                      userData: userDataCopy,
                    });
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                error: error,
                isEditEmailLoad: false,
              });
            });
        }
      );
    } else {
      this.setState({
        isEditEmail: false,
      });
    }
  };

  uploadImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      const file = event.target.files[0];
      this.setState({
        profileImageFile: file,
        profileImageOpen: true,
      });
    }
  };

  saveProfileImage = (event) => {
    event.preventDefault();
    const { userData } = this.state;
    this.setState(
      {
        profileImageSaving: true,
      },
      () => {
        if (
          userData.profileImage &&
          userData.profileImage.indexOf("firebasestorage") !== -1
        ) {
          let profileImage = storage.getRef(userData.profileImage);
          storage
            .deleteFile(profileImage)
            .then(() => {
              this.saveNewProfileImage();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.saveNewProfileImage();
        }
      }
    );
  };

  saveNewProfileImage = () => {
    const { authUser } = this.props;
    const { profileImageFile } = this.state;
    const storageRef = storage.getRef(
      `users/${authUser.uid}/images/profile/${profileImageFile.name}`
    );
    const canvasScaled = this.editor.getImageScaledToCanvas();
    canvasScaled.toBlob(
      (blob) => {
        storage.uploadFile(storageRef, blob).then((snapshot) => {
          storage.getDownload(snapshot.ref).then((downloadURL) => {
            this.updateProfileImage(downloadURL);
          });
        });
      },
      profileImageFile.type,
      1
    );
  };

  setEditorRef = (editor) => (this.editor = editor);

  closeProfileImageModal = (event) => {
    event.preventDefault();
    this.setState({
      profileImageOpen: false,
      profileImageFile: "",
    });
  };

  updateProfileImage = (url) => {
    const { authUser, setUserImg } = this.props;
    const { userData } = this.state;
    const userDataCopy = { ...userData };
    updateProfile(authUser, {
      photoURL: url ? url : "",
    })
      .then(() => {
        db.saveProfileImage(authUser.uid, url).then(() => {
          userDataCopy.profileImage = url;
          this.setState({
            profileImageLoad: false,
            profileImageFile: "",
            profileImageFileUpload: "",
            profileImageOpen: false,
            profileImageSaving: false,
            profileImageScale: 1,
            userData: userDataCopy,
          });
          setUserImg(url);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteImage = (event) => {
    event.preventDefault();
    this.setState({
      profileImageConfirmOpen: true,
    });
  };

  cancelDeleteImage = (event) => {
    event.preventDefault();
    this.setState({
      profileImageConfirmOpen: false,
    });
  };

  deleteImageFile = () => {
    const { userData } = this.state;
    this.setState(
      {
        profileImageLoad: true,
        profileImageConfirmOpen: false,
      },
      () => {
        if (
          userData.profileImage &&
          userData.profileImage.indexOf("firebasestorage") !== -1
        ) {
          let profileImage = storage.getRef(userData.profileImage);
          storage
            .deleteFile(profileImage)
            .then(() => {
              this.updateProfileImage(null);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    );
  };

  deleteUser = (event) => {
    event.preventDefault();
    this.setState({
      userDeleteOpen: true,
    });
  };

  confirmDeleteUser = (event) => {
    event.preventDefault();
    this.setState({
      userDeleteOpen: false,
      userDeleteConfirmOpen: true,
    });
  };

  cancelDeleteUser = (event) => {
    event.preventDefault();
    this.setState({
      userDeleteOpen: false,
      userDeleteConfirmOpen: false,
    });
  };

  deleteUserLogin = () => {
    this.setState(
      {
        accountAction: "account",
      },
      () => {
        this.openUserLoginModal();
      }
    );
  };

  deleteUserConfirm = () => {
    const { authUser, history, setUserImg } = this.props;
    this.setState(
      {
        userDeleteState: true,
      },
      () => {
        firebase.deleteUser({ uid: authUser.uid }).then(() => {
          auth.doSignOut().then(() =>
            setTimeout(() => {
              setUserImg(null);
              history.push(routes.Deleted);
            }, 100)
          );
        });
      }
    );
  };

  openHelpModal = () => {
    this.setState({
      helpModalOpen: true,
    });
  };

  closeHelpModal = () => {
    this.setState({
      helpModalOpen: false,
    });
  };

  reactivatePlan = () => {
    const { authUser } = this.props;
    firebase
      .updateMemberStatus({ uid: authUser.uid, status: "reactivate" })
      .then(() => {
        this.setMembership().then(() => {
          this.setState({ planReactivated: true });
        });
      });
  };

  openCancelSubConfirm = () => {
    this.setState({
      cancelSubOpen: true,
    });
  };

  closeCancelSubConfirm = () => {
    this.setState({
      cancelSubOpen: false,
    });
  };

  cancelSubscription = () => {
    const { authUser, userMembership } = this.props;
    if (
      userMembership.status !== "cancelled" &&
      userMembership !== "willCancel"
    ) {
      if (
        userMembership.subscription.plan.id !== membershipData.plans.free.id
      ) {
        this.setState(
          {
            cancelSubOpen: false,
            cancelSubLoading: true,
          },
          () => {
            firebase
              .updateMemberStatus({ uid: authUser.uid, status: "cancel" })
              .then(() => {
                db.watchStripeStatusChange(
                  authUser.uid,
                  this.cancelSubscriptionConfirm
                );
              });
          }
        );
      } else {
        this.setState(
          {
            cancelSubOpen: false,
            cancelSubLoading: true,
          },
          () => {
            firebase
              .updateMemberStatus({ uid: authUser.uid, status: "cancelTeam" })
              .then(() => {
                db.watchStripeStatusChange(
                  authUser.uid,
                  this.cancelTeamSubscriptionConfirm
                );
              });
          }
        );
      }
    } else {
      this.setState({ cancelSubOpen: false });
    }
  };

  cancelSubscriptionConfirm = (status) => {
    const { cancelSubLoading } = this.state;
    if (status === "cancelled" && cancelSubLoading) {
      this.setMembership().then(() => {
        this.setState({
          cancelSubLoading: false,
        });
      });
    }
  };

  cancelTeamSubscriptionConfirm = (status) => {
    const { authUser, userMembership } = this.props;
    const { cancelSubLoading } = this.state;
    if (status === "cancelled" && cancelSubLoading) {
      this.setState(
        {
          cancelSubLoading: false,
          teamMemberCancel: true,
        },
        () => {
          firebase
            .removeMember({
              uid: authUser.uid,
              teamLead: userMembership.team_lead,
            })
            .then(() => {
              this.setMembership();
            });
        }
      );
    }
  };

  openChangeCardModal = () => {
    this.setState({
      formDisabled: false,
      changeCardOpen: true,
      changeSuccess: false,
      changeError: false,
      changeErrorMsg: "",
    });
  };

  closeChangeCardModal = () => {
    this.setState({
      changeCardOpen: false,
    });
  };

  disableForm = () => {
    this.setState({
      formDisabled: true,
    });
  };

  enableForm = () => {
    this.setState({
      formDisabled: false,
    });
  };

  cardChange = (status) => {
    const { authUser } = this.props;
    const { changeSuccess, changeComplete, changeError, changeErrorMsg } =
      this.state;
    if (status === "card updated" && !changeSuccess) {
      this.setState({
        changeSuccess: true,
        changeComplete: false,
      });
    } else if (status === "active" && !changeComplete) {
      this.setMembership().then(() => {
        this.setState({
          changeComplete: true,
        });
      });
    } else if (status === "error" && !changeError) {
      this.setState(
        {
          changeError: true,
        },
        () => {
          db.getStripeError(authUser.uid).then((snapshot) => {
            if (changeErrorMsg !== snapshot.val()) {
              this.setState({
                changeErrorMsg: snapshot.val(),
              });
            }
          });
        }
      );
    }
  };

  resetChangeCardForm = (event) => {
    this.setState({
      formDisabled: false,
      changeSuccess: false,
      changeError: false,
      changeErrorMsg: "",
    });
  };

  handleUserSearchChange = (value) => {
    const { addNewMember } = this.state;
    if (addNewMember) {
      this.setState({
        userSearchValue: value,
        addNewMember: false,
      });
    } else {
      this.setState({
        userSearchValue: value,
        userSearchResults: [],
      });
    }
    if (validator.validate(value)) {
      this.debounceUsers();
    }
  };

  findUsers = () => {
    const { userSearchValue, teamMembersList } = this.state;
    if (userSearchValue !== "") {
      this.setState({ findingMember: true }, () => {
        const searchValue = userSearchValue;
        db.lookupUserByEmail(searchValue.toString().toLowerCase()).then(
          (foundUser) => {
            let isAdded = false;
            let isInvited = false;
            if (foundUser.val()) {
              teamMembersList.forEach((user) => {
                if (
                  user.uid === Object.keys(foundUser.val())[0] &&
                  user.active === "lead"
                ) {
                  isAdded = true;
                } else if (
                  user.uid === Object.keys(foundUser.val())[0] &&
                  user.active === true
                ) {
                  isAdded = true;
                } else if (
                  user.uid === Object.keys(foundUser.val())[0] &&
                  user.active === false
                ) {
                  isInvited = true;
                }
              });
              const userSearchResults = foundUser.val()[
                Object.keys(foundUser.val())[0]
              ]
                ? foundUser.val()[Object.keys(foundUser.val())[0]]
                : {};
              userSearchResults.id = Object.keys(foundUser.val())[0];
              if (isAdded) {
                userSearchResults.code = "added";
              }
              if (isInvited) {
                userSearchResults.code = "invited";
              }
              if (!isAdded && !isInvited) {
                db.getUserMembership(Object.keys(foundUser.val())[0]).then(
                  (snapshot) => {
                    if (
                      snapshot.val() &&
                      snapshot.val().subscription &&
                      snapshot.val().status === "active" &&
                      snapshot.val().subscription.plan.product !==
                        membershipData.products.team.id
                    ) {
                      userSearchResults.plan = snapshot.val().subscription;
                    } else if (
                      snapshot.val() &&
                      snapshot.val().subscription &&
                      snapshot.val().status === "active" &&
                      snapshot.val().subscription.plan.product ===
                        membershipData.products.team.id
                    ) {
                      userSearchResults.code = "on a team";
                    } else if (
                      snapshot.val() &&
                      snapshot.val().status === "invitedTeam"
                    ) {
                      userSearchResults.code = "invited to a team";
                    }
                    userSearchResults.status =
                      snapshot.val() && snapshot.val().status
                        ? snapshot.val().status
                        : null;
                    this.setState({
                      userSearchResults: userSearchResults,
                      findingMember: false,
                    });
                  }
                );
              } else {
                this.setState({
                  userSearchResults: userSearchResults,
                  findingMember: false,
                });
              }
            } else {
              teamMembersList.forEach((user) => {
                if (
                  user.email &&
                  user.email.toLowerCase() ===
                    searchValue.toString().toLowerCase()
                ) {
                  isInvited = true;
                }
              });
              db.findInvitedNewUser(searchValue.toString().toLowerCase()).then(
                (res) => {
                  this.setState({
                    addNewMember: true,
                    findingMember: false,
                    userSearchResults: {
                      newUserEmail: searchValue.toString().toLowerCase(),
                      code: isInvited
                        ? "invited"
                        : res.val() && res.val().team_lead
                        ? "invited to a team"
                        : null,
                    },
                  });
                }
              );
            }
          }
        );
      });
    } else {
      this.setState({
        addNewMember: false,
        userSearchResults: [],
      });
    }
  };

  toggleAddMember = (key) => {
    this.setState(
      {
        userSearchValue: "",
        teamMemberAddActive: key,
        userSearchResults: [],
      },
      () => {
        document.getElementById("user-search-input-" + key).focus();
      }
    );
  };

  cancelAddMember = () => {
    this.setState({
      userSearchValue: "",
      teamMemberAddActive: "",
      userSearchResults: [],
    });
  };

  inviteUserToTeam = () => {
    const { authUser } = this.props;
    const { userSearchResults, addNewMember } = this.state;
    this.setState({ addingMember: true }, () => {
      if (userSearchResults.hasOwnProperty("email") && !addNewMember) {
        firebase
          .inviteMember({ uid: userSearchResults.id, teamLead: authUser.uid })
          .then(() => {
            this.addedUserCallback(userSearchResults.email);
          });
      } else if (
        userSearchResults.hasOwnProperty("newUserEmail") &&
        addNewMember
      ) {
        firebase
          .inviteNewMember({
            email: userSearchResults.newUserEmail,
            teamLead: authUser.uid,
          })
          .then(() => {
            this.addedUserCallback(userSearchResults.newUserEmail);
          });
      }
    });
  };

  addedUserCallback = (email) => {
    const { authUser } = this.props;
    firebase.sendJoinTeamInvitation({
      name: authUser.displayName,
      url: `https://www.designgapp.com/account`,
      email: email,
    });
    this.setState(
      {
        addNewMember: false,
        addingMember: false,
        userSearchResults: {},
        userSearchValue: "",
        teamMemberAddActive: "",
      },
      () => {
        this.findTeamMembers();
      }
    );
  };

  findTeamLeadName = (memberID) => {
    db.getUser(memberID).then((snapshot) => {
      this.setState({
        teamLeadName: snapshot.val() ? snapshot.val().username : "Someone",
      });
    });
  };

  joinTeam = () => {
    const { authUser, userMembership } = this.props;
    const { joinUserState } = this.state;
    if (joinUserState !== "loading") {
      this.setState(
        {
          joinUserState: "loading",
        },
        () => {
          firebase
            .joinTeam({
              uid: authUser.uid,
              teamLead: userMembership.team_lead,
              planID: membershipData.plans.free.id,
            })
            .then(() => {
              db.watchStripeStatusChange(authUser.uid, this.joinedTeamConfirm);
            });
        }
      );
    }
  };

  joinedTeamConfirm = (status) => {
    if (status === "active") {
      this.setMembership().then(() => {
        this.setState({
          joinUserState: "done",
        });
      });
    }
  };

  openDeclineConfirm = () => {
    this.setState({ declineConfirmOpen: true });
  };

  closeDeclineConfirm = () => {
    this.setState({ declineConfirmOpen: false });
  };

  declineJoinTeam = () => {
    const { authUser, userMembership } = this.props;
    this.setState(
      {
        declineConfirmOpen: false,
        joinUserState: "declining",
      },
      () => {
        firebase
          .declineJoinTeam({
            uid: authUser.uid,
            teamLead: userMembership.team_lead,
            status: userMembership.subscription ? "active" : "deleted",
          })
          .then(() => {
            this.setMembership().then(() => {
              this.setState({
                joinUserState: "",
              });
            });
          });
      }
    );
  };

  openRemoveMemberConfirm = (memberID, memberType) => {
    this.setState({
      removeMemberOpen: true,
      memberToRemove: memberID,
      memberToRemoveType: memberType,
    });
  };

  closeRemoveMemberConfirm = (memberID) => {
    this.setState({
      removeMemberOpen: false,
      memberToRemove: "",
      memberToRemoveType: "",
    });
  };

  removeTeamMember = () => {
    const { authUser } = this.props;
    const { memberToRemove } = this.state;
    db.getUserMembership(memberToRemove).then((snapshot) => {
      if (!snapshot || !snapshot.val()) {
        this.setState(
          {
            removeMemberOpen: false,
            removingMember: true,
          },
          () => {
            firebase
              .removeNewMember({
                email: memberToRemove.toLowerCase(),
                teamLead: authUser.uid,
              })
              .then(() => {
                this.findTeamMembers().then(() => {
                  this.setState({
                    removingMember: false,
                    memberToRemove: "",
                    memberToRemoveType: "",
                  });
                });
              });
          }
        );
      } else if (
        snapshot.val() &&
        snapshot.val().subscription &&
        snapshot.val().subscription.plan.product ===
          membershipData.products.team.id &&
        snapshot.val().status !== "invitedTeam"
      ) {
        if (
          snapshot.val().subscription.plan.id === membershipData.plans.free.id
        ) {
          this.setState(
            {
              removeMemberOpen: false,
              removingMember: true,
            },
            () => {
              firebase
                .removeMember({
                  uid: memberToRemove,
                  teamLead: authUser.uid,
                  status: "cancelTeam",
                })
                .then(() => {
                  this.findTeamMembers().then(() => {
                    this.setState({
                      removingMember: false,
                      memberToRemove: "",
                      memberToRemoveType: "",
                    });
                  });
                });
            }
          );
        }
      } else {
        this.setState(
          {
            removeMemberOpen: false,
            removingMember: true,
          },
          () => {
            firebase
              .removeMember({
                uid: memberToRemove.toLowerCase(),
                teamLead: authUser.uid,
                status: snapshot.val().subscription ? "active" : "deleted",
              })
              .then(() => {
                this.findTeamMembers().then(() => {
                  this.setState({
                    removingMember: false,
                    memberToRemove: "",
                    memberToRemoveType: "",
                  });
                });
              });
          }
        );
      }
    });
  };

  removeDeclinedTeamMember = (memberID) => {
    const { authUser } = this.props;
    this.setState(
      {
        removingMember: true,
        memberToRemove: memberID,
      },
      () => {
        firebase
          .removeMember({ uid: memberID, teamLead: authUser.uid })
          .then(() => {
            this.findTeamMembers().then(() => {
              this.setState({
                removingMember: false,
                memberToRemove: "",
              });
            });
          });
      }
    );
  };

  cancelTeamSubscription = () => {
    const { authUser } = this.props;
    this.setState(
      {
        cancelSubOpen: false,
        cancelSubLoading: true,
      },
      () => {
        firebase
          .updateMemberStatus({ uid: authUser.uid, status: "cancelTeam" })
          .then(() => {
            db.watchStripeStatusChange(
              authUser.uid,
              this.cancelTeamSubscriptionConfirm
            );
          });
      }
    );
  };

  changeTeamLead = (memberID) => {
    this.setState({
      teamLeadChangeOn: true,
      teamLeadChangeID: memberID,
    });
  };

  cancelChangeLead = () => {
    this.setState({
      teamLeadChangeOn: false,
      teamLeadChangeID: "",
      teamLeadNewID: "",
    });
  };

  openChangeLeadConfirm = () => {
    this.setState({ teamLeadChangeConfirmOpen: true });
  };

  cancelChangeLeadConfirm = () => {
    this.setState({ teamLeadChangeConfirmOpen: false });
  };

  changeTeamLeadConfirm = () => {
    const { authUser } = this.props;
    const { teamLeadChangeID, teamLeadNewID } = this.state;
    db.getUser(teamLeadNewID).then((res) => {
      this.setState(
        {
          teamLeadChangeConfirmOpen: false,
          teamLeadChangeSaving: true,
        },
        () => {
          firebase
            .changeTeamLead({
              teamLead: teamLeadChangeID,
              newTeamLead: teamLeadNewID,
            })
            .then(() => {
              this.setState(
                {
                  teamLeadChangeSaving: false,
                  teamLeadChangeSuccess: true,
                  teamLeadChangeOn: false,
                  teamLeadChangeID: "",
                  teamLeadNewID: "",
                },
                () => {
                  db.watchStripeStatusChange(
                    authUser.uid,
                    this.changeTeamLeadDone
                  );
                }
              );
            });
          firebase.sendTeamLeadChange({
            name: authUser.displayName,
            url: `https://www.designgapp.com/account`,
            email: res.val().email,
          });
        }
      );
    });
  };

  changeTeamLeadDone = (status) => {
    const { teamLeadChangeSuccess } = this.state;
    if (status === "active" && teamLeadChangeSuccess) {
      this.setMembership().then(() => {
        setTimeout(() => {
          this.setState({
            teamLeadChangeSuccess: false,
          });
        }, 3000);
      });
    }
  };

  toggleNewLeadSelect = (memberID) => {
    const { teamLeadNewID } = this.state;
    if (memberID !== teamLeadNewID) {
      this.setState({ teamLeadNewID: memberID });
    } else {
      this.setState({ teamLeadNewID: "" });
    }
  };

  openPasswordChangeModal = () => {
    this.setState({ passwordChangeOpen: true });
  };

  closePasswordChangeModal = () => {
    this.setState({
      passwordChangeOpen: false,
      passwordChangeSuccess: false,
    });
  };

  passwordChangeSuccess = () => {
    this.setState({ passwordChangeSuccess: true });
  };

  render() {
    const { authUser, userMembership, userImg, history, match } = this.props;

    const {
      userData,
      userSearchValue,
      userSearchResults,
      userLoginOpen,
      memberID,
      addNewMember,
      addingMember,
      findingMember,
      removingMember,
      memberToRemove,
      teamLeadName,
      teamMembersList,
      teamMemberAddActive,
      teamMemberCancel,
      teamLeadChangeOn,
      teamLeadNewID,
      teamLeadChangeSaving,
      teamLeadChangeSuccess,
      passwordChangeSuccess,
      planReactivated,
      updatedName,
      updatedEmail,
      isEditName,
      isEditEmail,
      isEditEmailLoad,
      profileImageLoad,
      profileImageFile,
      profileImageSaving,
      profileImageScale,
      accountAction,
      userDeleteState,
      cancelSubLoading,
      changeSuccess,
      changeComplete,
      changeError,
      changeErrorMsg,
      formDisabled,
      error,
      profileImageConfirmOpen,
      profileImageFileUpload,
      userDeleteOpen,
      userDeleteConfirmOpen,
      cancelSubOpen,
      removeMemberOpen,
      declineConfirmOpen,
      teamLeadChangeConfirmOpen,
      profileImageOpen,
      helpModalOpen,
      changeCardOpen,
      passwordChangeOpen,
      joinUserState,
    } = this.state;

    return (
      <section id="account" className="content-outer">
        <Helmet>
          <title>DesignGapp - Account</title>
          <meta name="description" content="Your account settings." />
        </Helmet>

        <Confirm
          className="confirm"
          open={profileImageConfirmOpen}
          header="Sure you want to delete this image?"
          content="The image will be permanently removed."
          cancelButton="nevermind"
          confirmButton="yes, delete"
          onCancel={this.cancelDeleteImage}
          onConfirm={this.deleteImageFile}
        />

        <Confirm
          className="confirm"
          open={userDeleteOpen}
          header="Do you really want to delete your account?"
          content="All your projects will be deleted. This is permanent and can't be undone."
          cancelButton="nevermind"
          confirmButton="yes, delete"
          onCancel={this.cancelDeleteUser}
          onConfirm={this.confirmDeleteUser}
        />

        <Confirm
          className="confirm delete"
          open={userDeleteConfirmOpen}
          header="Are you totally sure? All of your content and projects will be deleted"
          content="Just wanted to double-check! There's no way to recover deleted projects you own."
          cancelButton="nevermind"
          confirmButton="yes, delete"
          onCancel={this.cancelDeleteUser}
          onConfirm={this.deleteUserLogin}
        />

        <Confirm
          className="confirm"
          open={cancelSubOpen}
          header="This will cancel your membership"
          content={
            userMembership.subscription &&
            userMembership.subscription.plan.id === membershipData.plans.free.id
              ? "Your team plan will immediately be canceled and you will be downgraded to the free plan."
              : "Your plan will be removed at the end of the billing period. You will have access to your current plan until then."
          }
          cancelButton="nevermind"
          confirmButton="yes, cancel my plan"
          onCancel={this.closeCancelSubConfirm}
          onConfirm={this.cancelSubscription}
        />

        <Confirm
          className="confirm"
          open={removeMemberOpen}
          header="Remove team member"
          content="Sure you want to do this? They will no longer have access to unlimited projects."
          cancelButton="nevermind"
          confirmButton="yes, remove member"
          onCancel={this.closeRemoveMemberConfirm}
          onConfirm={this.removeTeamMember}
        />

        <Confirm
          className="confirm"
          open={declineConfirmOpen}
          header="Decline team plan invitation"
          content="Sure you want to do this? You will not be added to the team and will stay on your current plan."
          cancelButton="nevermind"
          confirmButton="yes, decline invitation"
          onCancel={this.closeDeclineConfirm}
          onConfirm={this.declineJoinTeam}
        />

        <Confirm
          className="confirm"
          open={teamLeadChangeConfirmOpen}
          header="Change Team Lead"
          content="Sure you want to do this? The new Team Lead will be responsible for the team account and members. They will need to put a card down for the next billing cycle."
          cancelButton="nevermind"
          confirmButton="yes, change lead"
          onCancel={this.cancelChangeLeadConfirm}
          onConfirm={this.changeTeamLeadConfirm}
        />

        <Modal
          open={profileImageOpen}
          onClose={this.closeProfileImageModal}
          className="modal-profile-image"
        >
          <div className="modal-close-wrap">
            <span className="modal-close" onClick={this.closeProfileImageModal}>
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Content>
            <div className="profile-image-editor">
              <AvatarEditor
                ref={this.setEditorRef}
                image={profileImageFile}
                width={250}
                height={250}
                border={0}
                borderRadius={250}
                color={[255, 255, 255, 0.5]}
                scale={profileImageScale}
                rotate={0}
              />
              <div className="image-editor-controls">
                <label>Zoom:</label>
                <input
                  type="range"
                  orient="vertical"
                  min="1"
                  max="2"
                  step="0.2"
                  className="profile-image-zoom"
                  value={profileImageScale}
                  onChange={(event) =>
                    this.setState({
                      profileImageScale: Number(event.target.value),
                    })
                  }
                />
              </div>
            </div>
            <button className="btn btn-success" onClick={this.saveProfileImage}>
              {!profileImageSaving ? (
                <span>save image</span>
              ) : (
                <span className="btn-loading" />
              )}
            </button>
          </Modal.Content>
        </Modal>

        <Modal
          className="login-modal"
          open={userLoginOpen}
          onClose={this.closeUserLoginModal}
        >
          <div className="modal-close-wrap">
            <span className="modal-close" onClick={this.closeUserLoginModal}>
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Content>
            <span className="content-inline">
              <h2 className="centered">Please confirm your login.</h2>
              <LoginForm
                history={history}
                match={match}
                action={accountAction}
                saveEmail={this.saveEmail}
                deleteUserConfirm={this.deleteUserConfirm}
                close={this.closeUserLoginModal}
              />
            </span>
          </Modal.Content>
        </Modal>

        <Modal
          className="help-modal"
          open={helpModalOpen}
          onClose={this.closeHelpModal}
        >
          <div className="modal-close-wrap">
            <span className="modal-close" onClick={this.closeHelpModal}>
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Header>
            Tell us what's wrong and we'll get back to you as soon as possible.
          </Modal.Header>
          <Modal.Content>
            <ContactForm />
          </Modal.Content>
        </Modal>

        <Modal
          className="change-card-modal"
          open={changeCardOpen}
          onClose={this.closeChangeCardModal}
        >
          <div className="modal-close-wrap">
            <span className="modal-close" onClick={this.closeChangeCardModal}>
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Header>Update your card</Modal.Header>
          <Modal.Content>
            <span className="content-inline">
              <StripeProvider apiKey={getStripeKey()}>
                <Elements
                  fonts={[
                    {
                      cssSrc:
                        "https://fonts.googleapis.com/css?family=Mulish:300",
                    },
                  ]}
                >
                  <CardForm
                    fontSize={"16px"}
                    authUser={authUser}
                    cardChange={this.cardChange}
                    formDisabled={formDisabled}
                    disableForm={this.disableForm}
                    enableForm={this.enableForm}
                    changeSuccess={changeSuccess}
                    changeError={changeError}
                    changeErrorMsg={changeErrorMsg}
                    resetChangeCardForm={this.resetChangeCardForm}
                    closeChangeCardModal={this.closeChangeCardModal}
                  />
                </Elements>
              </StripeProvider>
            </span>
          </Modal.Content>
        </Modal>

        <Modal
          className="change-password-modal"
          open={passwordChangeOpen}
          onClose={this.closePasswordChangeModal}
        >
          <div className="modal-close-wrap">
            <span
              className="modal-close"
              onClick={this.closePasswordChangeModal}
            >
              <i className="icon icon-arrow-left" />
            </span>
          </div>
          <Modal.Header>Change your password</Modal.Header>
          <Modal.Content>
            <span className="content-inline">
              <PasswordChangeForm
                history={history}
                authUser={authUser}
                passwordChangeSuccess={this.passwordChangeSuccess}
              />
              {passwordChangeSuccess ? (
                <button
                  className="btn btn-primary btn-done"
                  onClick={this.closePasswordChangeModal}
                >
                  close this window
                </button>
              ) : null}
            </span>
          </Modal.Content>
        </Modal>

        {!userDeleteState ? (
          <div className="content-wrap">
            <MembershipStatus />
            <h1>My Account</h1>
            <div className="content-inner">
              <div className="account-wrap">
                <div className="account-left">
                  <div
                    className={`account-profile-img ${
                      profileImageLoad ? "loading" : ""
                    } ${
                      !authUser.photoURL && !userData.profileImage
                        ? "no-photo"
                        : ""
                    }`}
                  >
                    <form id="form-profile-image">
                      <label>
                        <input
                          type="file"
                          value={profileImageFileUpload}
                          name="upload"
                          onChange={this.uploadImage}
                        />
                        <div className="profile-img-wrap">
                          <p className="profile-initials">
                            {findInitials(authUser.displayName)}
                          </p>
                          {userData.profileImage ? (
                            <span>
                              <ProgressiveImage
                                placeholder={require("../../images/loading-md.svg")}
                                src={
                                  userData.profileImage
                                    ? userData.profileImage
                                    : authUser.photoURL
                                }
                              >
                                {(src, loading) => (
                                  <div
                                    className={`profile-img progressive ${
                                      loading ? "loading" : "done"
                                    }`}
                                    style={{ backgroundImage: `url("${src}")` }}
                                  />
                                )}
                              </ProgressiveImage>
                              <img
                                className="profile-img-src"
                                src={
                                  userData.profileImage
                                    ? userData.profileImage
                                    : authUser.photoURL
                                }
                                alt="profile"
                                role="presentation"
                              />
                            </span>
                          ) : null}
                          <span className="profile-img-lbl">
                            {userData.profileImage ? "change" : "upload"}
                          </span>
                        </div>
                        <span className="btn btn-primary btn-small">
                          {userData.profileImage
                            ? "change image"
                            : "upload image"}
                        </span>
                      </label>
                      {userData.profileImage &&
                      userData.profileImage.indexOf("firebasestorage") !==
                        -1 ? (
                        <span
                          onClick={this.deleteImage}
                          className="link link-alert"
                        >
                          delete image
                        </span>
                      ) : null}
                    </form>
                  </div>
                </div>
                <div className="account-right">
                  <div className="account-input-row user-name">
                    <form
                      id="form-profile-name"
                      className={`${isEditName ? "editing" : ""}`}
                    >
                      <div className="form-text">
                        <label>Name</label>
                        <p className="text">{authUser.displayName}</p>
                        <input
                          type="text"
                          id="input-profile-username"
                          value={updatedName}
                          onChange={(event) =>
                            this.setState({ updatedName: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-btns">
                        <button
                          className="btn btn-primary btn-small"
                          onClick={this.editName}
                        >
                          edit
                        </button>
                        <button
                          className="btn btn-cancel btn-text btn-small regressive"
                          onClick={this.editCancelName}
                        >
                          cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-success btn-small"
                          onClick={this.saveName}
                        >
                          save
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="account-input-row user-email">
                    <form
                      id="form-profile-email"
                      className={`${isEditEmail ? "editing" : ""}`}
                    >
                      <div className="form-text">
                        <label>
                          {error ? (
                            <span className="error">
                              Error: {error.message}
                            </span>
                          ) : (
                            "Email"
                          )}
                        </label>
                        <p className="text">
                          {!isEditEmailLoad ? (
                            userData.email
                          ) : (
                            <span className="inline-loading" />
                          )}
                        </p>
                        <Input
                          type="email"
                          id="input-profile-email"
                          value={updatedEmail}
                          onChange={(event) =>
                            this.setState({ updatedEmail: event.target.value })
                          }
                        />
                      </div>
                      {authUser.providerData[0].providerId === "password" ? (
                        <div className="form-btns">
                          <button
                            className="btn btn-primary btn-small"
                            onClick={this.editEmail}
                          >
                            edit
                          </button>
                          <button
                            className="btn btn-cancel btn-text btn-small regressive"
                            onClick={this.editCancelEmail}
                          >
                            cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success btn-small"
                            onClick={this.saveEmailConfirm}
                          >
                            {!isEditEmailLoad ? (
                              <span>save</span>
                            ) : (
                              <span className="btn-loading" />
                            )}
                          </button>
                        </div>
                      ) : null}
                    </form>
                  </div>
                  {userMembership ? (
                    <div
                      id="membership-form"
                      className="account-input-row user-plan"
                    >
                      <div className="account-form">
                        <div className="form-text">
                          <label>Membership Plan</label>
                          {!teamLeadChangeSuccess ? (
                            <span className="account-plan-content">
                              <p className="account-plan">
                                {userMembership.subscription &&
                                userMembership.status !== "deleted" &&
                                !teamMemberCancel
                                  ? userMembership.subscription.plan.nickname.replace(
                                      " ",
                                      " - "
                                    )
                                  : "free"}
                                <span>
                                  {!userMembership ||
                                  !userMembership.subscription ||
                                  teamMemberCancel ||
                                  userMembership.status === "deleted"
                                    ? "(3: projects, styleguides, showcases)"
                                    : ""}
                                  {userMembership &&
                                  userMembership.subscription &&
                                  userMembership.subscription.plan.product ===
                                    membershipData.products.starter.id
                                    ? "(6: projects, styleguides, showcases)"
                                    : ""}
                                  {userMembership &&
                                  userMembership.subscription &&
                                  userMembership.subscription.plan.product ===
                                    membershipData.products.professional.id
                                    ? "(Unlimited: projects, styleguides, Showcases)"
                                    : ""}
                                  {userMembership &&
                                  userMembership.subscription &&
                                  userMembership.subscription.plan.product ===
                                    membershipData.products.team.id &&
                                  userMembership.status !== "deleted" &&
                                  !teamMemberCancel
                                    ? "(5 members + Unlimited: projects, styleguides, showcases)"
                                    : ""}
                                </span>
                              </p>
                              {userMembership &&
                              userMembership.status === "cancelled" &&
                              !teamMemberCancel ? (
                                <p className="membership-cancelled">
                                  <span className="label">
                                    Plan will cancel on{" "}
                                    <Moment unix format="M/D/YYYY">
                                      {
                                        userMembership.subscription
                                          .current_period_end
                                      }
                                    </Moment>
                                  </span>
                                </p>
                              ) : null}
                              {planReactivated &&
                              userMembership &&
                              userMembership.status !== "cancelled" &&
                              !cancelSubLoading ? (
                                <span className="membership-reactivated">
                                  Your plan was reactivated!
                                </span>
                              ) : null}
                            </span>
                          ) : (
                            <div className="inline-loading" />
                          )}
                        </div>
                        {userMembership && !teamLeadChangeSuccess ? (
                          <div className="form-btns">
                            <Link to={routes.AccountUpgrade}>
                              <button className="btn btn-primary btn-small">
                                change plan
                              </button>
                            </Link>
                            {userMembership.subscription &&
                            userMembership.status !== "cancelled" &&
                            userMembership.status !== "deleted" &&
                            !cancelSubLoading ? (
                              <span
                                className="link membership-cancel"
                                onClick={this.openCancelSubConfirm}
                              >
                                {userMembership.subscription &&
                                userMembership.subscription.plan.id ===
                                  membershipData.plans.free.id
                                  ? "leave team"
                                  : "cancel plan"}
                              </span>
                            ) : userMembership.status !== "cancelled" &&
                              cancelSubLoading ? (
                              <div className="inline-loading cancel-sub-loading" />
                            ) : userMembership.status === "cancelled" &&
                              !teamMemberCancel ? (
                              <span
                                className="link membership-reactivate"
                                onClick={this.reactivatePlan}
                              >
                                reactivate plan
                              </span>
                            ) : null}
                          </div>
                        ) : userMembership &&
                          memberID !== userMembership.subscription.customer ? (
                          <div className="form-btns">
                            <span
                              className="link membership-cancel"
                              onClick={this.openCancelSubConfirm}
                            >
                              leave the team
                            </span>
                          </div>
                        ) : (
                          <div className="form-btns">
                            <Link to={routes.AccountUpgrade}>
                              <button className="btn btn-primary btn-small">
                                change plan
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                      {userMembership &&
                      userMembership.status === "invitedTeam" ? (
                        <div
                          className="team-invite"
                          disabled={
                            joinUserState === "loading" ||
                            joinUserState === "declining"
                          }
                        >
                          <i className="icon icon-users-plus" />
                          <div className="team-invite-content">
                            <p>
                              {teamLeadName} invited you to join their team
                              plan.
                            </p>
                            <div className="btn-row">
                              <button
                                className="btn btn-small btn-text alert"
                                onClick={this.openDeclineConfirm}
                              >
                                {joinUserState !== "declining" ? (
                                  "decline"
                                ) : (
                                  <span className="btn-loading" />
                                )}
                              </button>
                              <button
                                className="btn btn-small btn-success"
                                onClick={this.joinTeam}
                              >
                                {joinUserState !== "loading" ? (
                                  "join"
                                ) : (
                                  <span className="btn-loading" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : userMembership &&
                        userMembership.status === "active" &&
                        joinUserState === "done" ? (
                        <div className="team-invite done">
                          <i className="icon icon-checkmark-circle" />
                          <div className="team-invite-content">
                            <p>You have successfully joined the team!</p>
                          </div>
                        </div>
                      ) : null}
                      {teamLeadChangeSuccess ? (
                        <div className="team-invite done">
                          <i className="icon icon-checkmark-circle" />
                          <div className="team-invite-content">
                            <p>You have successfully changed the Team Lead!</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {!teamLeadChangeSuccess &&
                  userMembership &&
                  userMembership.subscription &&
                  userMembership.subscription.plan.product ===
                    membershipData.products.team.id &&
                  userMembership.subscription.plan.id !==
                    membershipData.plans.free.id ? (
                    <div className="account-input-row user-team">
                      <div id="team-members" className="account-form">
                        <div className="form-text">
                          <label>Team Members</label>
                          <div
                            className={`team-members-list ${
                              teamLeadChangeOn ? "lead-change" : ""
                            }`}
                          >
                            {Object.keys(teamMembersList).map((key) => (
                              <div
                                className={`team-member-wrap ${
                                  teamMemberAddActive === key ? "active" : ""
                                }`}
                                key={key}
                              >
                                {teamMembersList[key] === "empty" ? (
                                  <TeamMemberAdd
                                    teamMemberID={key}
                                    teamMembersList={teamMembersList}
                                    teamMemberAddActive={teamMemberAddActive}
                                    toggleAddMember={this.toggleAddMember}
                                    cancelAddMember={this.cancelAddMember}
                                    userSearchValue={userSearchValue}
                                    userSearchResults={userSearchResults}
                                    handleUserSearchChange={
                                      this.handleUserSearchChange
                                    }
                                    findInitials={findInitials}
                                    addNewMember={addNewMember}
                                    inviteUserToTeam={this.inviteUserToTeam}
                                    findingMember={findingMember}
                                    addingMember={addingMember}
                                  />
                                ) : (
                                  <TeamMemberCard
                                    teamMemberID={teamMembersList[key].uid}
                                    teamMemberName={
                                      teamMembersList[key].username
                                    }
                                    teamMemberEmail={teamMembersList[key].email}
                                    teamMemberProfileImg={
                                      teamMembersList[key].uid === authUser.uid
                                        ? userImg
                                        : teamMembersList[key].profileImage
                                    }
                                    teamMemberActive={
                                      teamMembersList[key].active
                                    }
                                    teamMembersList={teamMembersList}
                                    findInitials={findInitials}
                                    openRemoveMemberConfirm={
                                      this.openRemoveMemberConfirm
                                    }
                                    removeDeclinedTeamMember={
                                      this.removeDeclinedTeamMember
                                    }
                                    changeTeamLead={this.changeTeamLead}
                                    teamLeadChangeOn={teamLeadChangeOn}
                                    cancelChangeLead={this.cancelChangeLead}
                                    openChangeLeadConfirm={
                                      this.openChangeLeadConfirm
                                    }
                                    teamLeadNewID={teamLeadNewID}
                                    toggleNewLeadSelect={
                                      this.toggleNewLeadSelect
                                    }
                                    addingMember={addingMember}
                                    removingMember={removingMember}
                                    memberToRemove={memberToRemove}
                                    teamLeadChangeSaving={teamLeadChangeSaving}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {userMembership &&
                  userMembership.subscription &&
                  userMembership.subscription.plan.id !==
                    membershipData.plans.free.id ? (
                    <div
                      id="payment-form"
                      className="account-input-row user-payment"
                    >
                      <div className="account-form">
                        <div className="form-text">
                          <label>Payment Method</label>
                          {changeComplete &&
                          userMembership.card &&
                          userMembership.card.status !== "pending" ? (
                            <p
                              className={`account-payment-method ${FindMemberStatus(
                                userMembership
                              )}`}
                            >
                              {FindMemberStatus(userMembership) ===
                              "pastDue" ? (
                                <span className="payment-pastDue">
                                  <i className="icon icon-notification" />
                                  <label>past due</label>
                                </span>
                              ) : null}
                              <span className="payment-name">
                                {userMembership.card
                                  ? userMembership.card.name
                                  : "NO CARD"}
                              </span>
                              <span className="payment-card card-num">
                                <span
                                  className={`card-type ${
                                    userMembership.card
                                      ? userMembership.card.brand.toLowerCase()
                                      : ""
                                  }`}
                                />
                                <span className="card-dots">
                                  **** **** ****
                                </span>
                                <span className="card-last4">
                                  {userMembership.card
                                    ? userMembership.card.last4
                                    : "NO CARD"}
                                </span>
                              </span>
                            </p>
                          ) : !userMembership.card ? (
                            <p className="no-payment-method">
                              <i className="icon icon-notification" />
                              You don't have a card on file
                            </p>
                          ) : (
                            <div className="inline-loading card-change-loading" />
                          )}
                        </div>
                        <div className="form-btns">
                          {changeComplete ? (
                            <button
                              className="btn btn-primary btn-small"
                              onClick={this.openChangeCardModal}
                            >
                              {!userMembership.card ? "add a" : "change"} card
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {userMembership && userMembership.invoices ? (
                    <div className="account-input-row user-invoices">
                      <div className="account-form">
                        <div className="form-text">
                          <label>Payment History</label>
                          <div className="account-invoice-history">
                            {userMembership.invoices ? (
                              <ul>
                                {Object.keys(userMembership.invoices)
                                  .sort((a, b) => {
                                    return (
                                      userMembership.invoices[b].date -
                                      userMembership.invoices[a].date
                                    );
                                  })
                                  .map((key) => (
                                    <li key={key}>
                                      <div className="invoice-row">
                                        <span className="invoice-date">
                                          <Moment unix format="MMM D, YYYY">
                                            {userMembership.invoices[key].date}
                                          </Moment>
                                        </span>
                                        <span className="invoice-amount">
                                          <span>$</span>
                                          {userMembership.invoices[key]
                                            .amount_paid / 100}
                                        </span>
                                        <span className="invoice-pdf">
                                          <a
                                            href={
                                              userMembership.invoices[key]
                                                .invoice_url
                                                ? userMembership.invoices[key]
                                                    .invoice_url
                                                : userMembership.invoices[key]
                                                    .invoice_pdf
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Invoice
                                          </a>
                                        </span>
                                        {userMembership.invoices[key]
                                          .receipt_url ? (
                                          <span className="invoice-receipt">
                                            <a
                                              href={
                                                userMembership.invoices[key]
                                                  .receipt_url
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Receipt
                                            </a>
                                          </span>
                                        ) : null}
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <div className="inline-loading invoice-loading" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="account-input-row user-date">
                    <div className="form-text">
                      <label>Member Since</label>
                      <p>
                        {String(authUser.metadata.creationTime).slice(8, 16)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-row">
              {authUser.providerData[0].providerId === "password" ? (
                <button
                  className="btn btn-primary"
                  onClick={this.openPasswordChangeModal}
                >
                  change your password
                </button>
              ) : (
                <div className="row-google">
                  <i className="icon icon-google" />
                  <span>
                    Your account is linked to your Gmail login.{" "}
                    <span className="link" onClick={this.openHelpModal}>
                      Need help?
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="btn-row row-delete">
              <span className="link link-alert" onClick={this.deleteUser}>
                delete your account permanently
              </span>
            </div>
          </div>
        ) : (
          <div
            className="content-wrap content-loading"
            onLoad={window.scrollTo(0, 0)}
          >
            <h1 className="error">Deleting your account...</h1>
          </div>
        )}
        <ReactBody className="account" />
      </section>
    );
  }
}

const TeamMemberAdd = ({
  teamMemberID,
  teamMemberAddActive,
  toggleAddMember,
  cancelAddMember,
  userSearchValue,
  userSearchResults,
  handleUserSearchChange,
  findInitials,
  addNewMember,
  inviteUserToTeam,
  findingMember,
  addingMember,
}) => (
  <div className="team-member empty">
    <div className="item-user-profile no-user">
      <div className="profile-img-wrap">
        <i className="icon icon-user" />
      </div>
    </div>
    <div className="item-user-info">
      {teamMemberAddActive !== teamMemberID ? (
        <p onClick={() => toggleAddMember(teamMemberID)}>+ Add team member</p>
      ) : (
        <div className="item-user-add" disabled={addingMember}>
          <div
            className={`item-user-search ${
              userSearchResults.hasOwnProperty("email") ? "has-account" : ""
            } ${userSearchResults.code ? "has-code" : ""}`}
          >
            <Input
              type="email"
              id={`user-search-input-${teamMemberID}`}
              className="user-search-input"
              name="user-search"
              value={userSearchValue}
              placeholder="Invite user by email..."
              onChange={(event, val) => handleUserSearchChange(val.value)}
            />
            {addNewMember && !userSearchResults.code && !addingMember ? (
              <i className="icon icon-plus-circle" onClick={inviteUserToTeam} />
            ) : addNewMember && userSearchResults.code && !findingMember ? (
              <span className="user-search-added">
                already {userSearchResults.code}
              </span>
            ) : (findingMember || addingMember) &&
              !userSearchResults.hasOwnProperty("email") ? (
              <div className="inline-loading" />
            ) : null}
            {userSearchResults.hasOwnProperty("email") ? (
              <div
                className={`user-search-results ${
                  userSearchResults.code ? "has-code" : ""
                }`}
              >
                <TeamMemberCard
                  teamMemberID={userSearchResults.id}
                  teamMemberName={userSearchResults.username}
                  teamMemberEmail={userSearchResults.email}
                  teamMemberProfileImg={userSearchResults.profileImage}
                  teamMemberPlan={userSearchResults.plan}
                  teamMemberStatus={userSearchResults.status}
                  findInitials={findInitials}
                />
                {!userSearchResults.code && !addingMember ? (
                  <i
                    className="icon icon-plus-circle"
                    onClick={inviteUserToTeam}
                  />
                ) : userSearchResults.code && !addingMember ? (
                  <span className="user-search-added">
                    already {userSearchResults.code}
                  </span>
                ) : addingMember ? (
                  <div className="inline-loading" />
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            className="user-search-cancel btn btn-text btn-small regressive"
            onClick={cancelAddMember}
          >
            cancel
          </div>
        </div>
      )}
    </div>
  </div>
);

const TeamMemberCard = ({
  teamMemberID,
  teamMemberName,
  teamMemberEmail,
  teamMembersList,
  teamMemberProfileImg,
  teamMemberPlan,
  teamMemberStatus,
  teamMemberActive,
  findInitials,
  openRemoveMemberConfirm,
  removeDeclinedTeamMember,
  changeTeamLead,
  teamLeadChangeOn,
  cancelChangeLead,
  openChangeLeadConfirm,
  teamLeadNewID,
  toggleNewLeadSelect,
  addingMember,
  removingMember,
  memberToRemove,
  teamLeadChangeSaving,
}) => (
  <div
    className={`team-member ${!teamMemberID ? "no-user" : ""} ${
      teamMemberActive === true
        ? "active"
        : teamMemberActive === false
        ? "invited"
        : "lead"
    }`}
    disabled={removingMember && memberToRemove === teamMemberID}
  >
    {teamMemberID ? (
      <div
        className={`item-user-profile ${
          teamMemberProfileImg ? "" : "no-photo"
        }`}
      >
        <div className="profile-img-wrap">
          <p className="profile-initials">{findInitials(teamMemberName)}</p>
          {teamMemberProfileImg ? (
            <span>
              <ProgressiveImage
                placeholder={require("../../images/loading-md.svg")}
                src={teamMemberProfileImg}
              >
                {(src, loading) => (
                  <div
                    className={`profile-img progressive ${
                      loading ? "loading" : "done"
                    }`}
                    style={{ backgroundImage: `url("${src}")` }}
                  />
                )}
              </ProgressiveImage>
              <img
                className="profile-img-src"
                src={teamMemberProfileImg}
                alt="profile"
                role="presentation"
              />
            </span>
          ) : null}
        </div>
      </div>
    ) : (
      <div className="item-user-profile no-user">
        <div className="profile-img-wrap">
          <i className="icon icon-user" />
        </div>
      </div>
    )}
    <div className="item-user-info">
      {teamMemberID ? (
        <p>
          {teamMemberName}
          {teamMemberActive === "lead" ? (
            <span className="item-user-lead">(Team Lead)</span>
          ) : null}
        </p>
      ) : null}
      <a
        href={`mailto:${teamMemberEmail}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {teamMemberEmail}
      </a>
      {teamMemberPlan && teamMemberStatus === "active" ? (
        <span className="item-user-plan" style={{ display: "none" }}>
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <span>(this user is on a paid plan &ndash; learn more)</span>
            }
            header={
              <p>
                By adding this user to your team, their existing plan balance
                will be automatically transferred as credit to the next team
                payment.
              </p>
            }
          />
        </span>
      ) : null}
    </div>
    {removingMember &&
    (memberToRemove === teamMemberID || memberToRemove === teamMemberEmail) ? (
      <div className="inline-loading" />
    ) : !teamLeadChangeOn ? (
      <div className="item-user-status">
        {teamMemberActive === "lead" &&
        teamMembersList.filter((member) => member.active).length > 1 ? (
          <button
            className="btn btn-small btn-primary btn-lead"
            onClick={() => changeTeamLead(teamMemberID)}
          >
            change lead
          </button>
        ) : teamMemberActive === true ? (
          <p className="user-status active">
            <span>joined</span>
            <button
              className="btn btn-text alert"
              onClick={() =>
                openRemoveMemberConfirm(
                  teamMemberID ? teamMemberID : teamMemberEmail,
                  teamMemberID ? "existingUser" : "newUser"
                )
              }
            >
              remove
            </button>
          </p>
        ) : teamMemberActive === false ? (
          <p className="user-status invited">
            <span>invited</span>
            <button
              className="btn btn-text alert"
              onClick={() =>
                openRemoveMemberConfirm(
                  teamMemberID ? teamMemberID : teamMemberEmail,
                  teamMemberID ? "existingUser" : "newUser"
                )
              }
            >
              remove
            </button>
          </p>
        ) : teamMemberActive === "declined" ? (
          <p className="user-status declined">
            <span>declined</span>
            <button
              className="btn btn-text alert"
              onClick={() => removeDeclinedTeamMember(teamMemberID)}
            >
              remove
            </button>
          </p>
        ) : null}
      </div>
    ) : teamLeadChangeOn ? (
      <div className="item-user-status lead-change">
        {teamMemberActive === "lead" ? (
          <div className="lead-change-btns" disabled={teamLeadChangeSaving}>
            <button
              className="btn btn-small btn-text regressive"
              onClick={cancelChangeLead}
            >
              cancel
            </button>
            <button
              className="btn btn-small btn-success"
              disabled={teamLeadNewID === ""}
              onClick={openChangeLeadConfirm}
            >
              {!teamLeadChangeSaving ? (
                "save"
              ) : (
                <span className="btn-loading" />
              )}
            </button>
          </div>
        ) : teamMemberActive === true ? (
          <div
            className={`lead-change-select ${
              teamLeadNewID === teamMemberID ? "active" : ""
            }`}
            onClick={() => toggleNewLeadSelect(teamMemberID)}
          >
            <i className="icon icon-checkmark-circle" />
          </div>
        ) : null}
      </div>
    ) : null}
  </div>
);

const createOptions = (fontSize: string, padding: ?string) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#2b2b2b",
        letterSpacing: "0.025em",
        fontFamily: `"Mulish", sans-serif`,
        "::placeholder": {
          color: "#888",
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class _CardForm extends React.Component<
  InjectedProps & { authUser: array } & { cardChange: fn } & {
    formDisabled: boolean,
  } & { disableForm: fn } & { enableForm: fn } & { changeSuccess: boolean } & {
    changeError: boolean,
  } & { changeErrorMsg: string } & { resetChangeCardForm: fn } & {
    closeChangeCardModal: fn,
  }
> {
  constructor(props) {
    super(props);
    this.state = { error: "" };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const {
      stripe,
      formDisabled,
      disableForm,
      authUser,
      cardChange,
      enableForm,
    } = this.props;
    if (stripe && !formDisabled) {
      disableForm();
      stripe
        .createToken({ type: "card", name: event.target.name.value })
        .then((payload) => {
          if (!payload.error) {
            firebase
              .submitNewCard({ uid: authUser.uid, token: payload.token })
              .then(() => {
                db.watchStripeStatusChange(authUser.uid, cardChange);
              });
          } else {
            enableForm();
            this.setState({
              error: payload.error.message,
            });
          }
        });
    } else {
      console.log("Stripe.js hasn't loaded yet -- or two clicks of submit.");
    }
  };

  render() {
    const { error } = this.state;

    const {
      changeSuccess,
      changeError,
      changeErrorMsg,
      formDisabled,
      fontSize,
      closeChangeCardModal,
      resetChangeCardForm,
    } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        id="form-change-card"
        className={formDisabled ? "disabled" : ""}
      >
        <div id="account-change-card-form">
          {!changeSuccess && !changeError ? (
            <div className="change-card-form">
              <div className="form-row billing-name">
                <label className="form-label">Name on card</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Jane Doe"
                  required
                />
              </div>
              <div className="form-row billing-card">
                <label className="form-label">Card details</label>
                <CardElement {...createOptions(fontSize)} />
              </div>
              {error ? (
                <div className="form-errors">
                  <p className="error">{error}</p>
                </div>
              ) : null}
              <div className="form-row billing-confirm">
                <button className="btn btn-success">
                  {!formDisabled ? (
                    <span>update card</span>
                  ) : (
                    <span className="btn-loading" />
                  )}
                </button>
              </div>
            </div>
          ) : changeSuccess ? (
            <div className="change-card-success">
              <i className="icon icon-checkmark-circle" />
              <h3>Card successfully updated!</h3>
              <button
                className="btn btn-primary"
                onClick={closeChangeCardModal}
              >
                close this window
              </button>
            </div>
          ) : changeError ? (
            <div className="change-card-error">
              <i className="icon icon-cross-circle" />
              <h3>Something went wrong...</h3>
              <p>{changeErrorMsg}</p>
              <button className="btn btn-primary" onClick={resetChangeCardForm}>
                update your card
              </button>
            </div>
          ) : null}
        </div>
      </form>
    );
  }
}

const CardForm = injectStripe(_CardForm);

const FindMemberStatus = (membership) => {
  let memberStatus = membership && membership.status ? membership.status : "";
  if (
    membership &&
    membership.subscription &&
    membership.subscription.plan.id !== membershipData.plans.free.id &&
    !membership.card &&
    memberStatus !== "create" &&
    membership.subscription.status !== "trialing"
  ) {
    memberStatus = "missingCard";
  }
  if (membership && membership.status && membership.status === "pastDue") {
    memberStatus = "pastDue";
  }
  return memberStatus;
};

class MembershipStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberStatus: FindMemberStatus(props.userMembership),
    };
  }
  render() {
    const { memberStatus } = this.state;
    return memberStatus === "pastDue" ? (
      <div className="membership-status pastDue">
        <i className="icon icon-notification" />
        <p>
          Your membership payment is <strong>past due</strong>. Please update
          your payment now to keep your plan.
        </p>
        <Link to={`${routes.Account}#payment-form`}>
          <button className="btn btn-small btn-primary">update</button>
        </Link>
      </div>
    ) : memberStatus === "invitedTeam" ? (
      <div className="membership-status teamInvite">
        <i className="icon icon-users-plus" />
        <p>You've been invited to join a team plan.</p>
        <Link to={`${routes.Account}#membership-form`}>
          <button className="btn btn-small btn-primary">view invite</button>
        </Link>
      </div>
    ) : memberStatus === "missingCard" ? (
      <div className="membership-status missingCard">
        <i className="icon icon-credit-card" />
        <p>Please add a card to your account for the next billing cycle.</p>
        <Link to={`${routes.Account}#payment-form`}>
          <button className="btn btn-small btn-primary">update</button>
        </Link>
      </div>
    ) : null;
  }
}

const INITIAL_STATE = {
  userData: [],
  userSearchValue: "",
  userSearchResults: [],
  stripeCustomer: [],
  teamCount: 5,
  teamLeadName: "",
  teamMembersList: [],
  addingMember: false,
  findingMember: false,
  removingMember: false,
  projectUsers: [],
  invitedUsers: [],
  teamMemberAddActive: "",
  teamMemberCancel: false,
  teamLeadChangeOn: false,
  teamLeadChangeID: "",
  teamLeadNewID: "",
  teamLeadChangeSaving: false,
  teamLeadChangeSuccess: false,
  teamLeadChangeConfirmOpen: false,
  profileImageLoad: true,
  profileImageFile: "",
  profileImageFileUpload: "",
  updatedName: "",
  updatedEmail: "",
  accountAction: "",
  joinUserState: "",
  planReactivated: false,
  cancelSubOpen: false,
  cancelSubLoading: false,
  declineConfirmOpen: false,
  isEditName: false,
  isEditEmail: false,
  isEditEmailLoad: false,
  profileImageOpen: false,
  profileImageConfirmOpen: false,
  profileImageScale: 1,
  profileImageSaving: false,
  userDeleteOpen: false,
  userDeleteConfirmOpen: false,
  userDeleteState: false,
  passwordChangeOpen: false,
  passwordChangeSuccess: false,
  error: null,
  helpModalOpen: false,
  changeComplete: true,
  changeSuccess: false,
  changeError: false,
  formDisabled: false,
  changeCardOpen: false,
  removeMemberOpen: false,
  memberToRemove: "",
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userImg: state.sessionState.userImg,
  userMembership: state.sessionState.userMembership,
});

const mapDispatchToProps = (dispatch) => ({
  setUserImg: (img) => dispatch({ type: "USER_IMG", img }),
  setUserName: (name) => dispatch({ type: "USER_NAME", name }),
  setUserMembership: (membership) =>
    dispatch({ type: "USER_MEMBERSHIP", membership }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Account);

export { MembershipStatus };
