import React, { Component } from "react";
import { firebase } from "../../firebase";
import { Input, Dropdown } from "semantic-ui-react";
import ReactBody from "react-body";
import { Helmet } from "react-helmet-async";
import TextareaAutosize from "react-textarea-autosize";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { name, email, message, category } = this.state;
    this.setState(
      {
        emailSending: true,
      },
      () => {
        firebase
          .sendContactEmail({
            name: name,
            email: email,
            category: category,
            message: message,
          })
          .then((response) => {
            if (response.data === true) {
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  category: "",
                  error: null,
                  emailSending: false,
                  emailSent: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      emailSent: false,
                    });
                  }, 4000);
                }
              );
            } else {
              this.setState({
                error: response.data,
                emailSending: false,
                emailSent: false,
              });
            }
          });
      }
    );
  };

  render() {
    const { name, email, message, category, error, emailSending, emailSent } =
      this.state;

    const isInvalid =
      name === "" || email === "" || message === "" || category === "";

    return (
      <form className="contact-form" onSubmit={this.onSubmit}>
        <Input
          autoFocus
          type="text"
          value={name}
          placeholder="Name"
          onChange={(event) => this.setState({ name: event.target.value })}
        />
        <Input
          type="email"
          value={email}
          placeholder="Email"
          onChange={(event) => this.setState({ email: event.target.value })}
        />
        <Dropdown
          selection
          placeholder="Choose contact category"
          value={category}
          onChange={(event, data) => this.setState({ category: data.value })}
          selectOnBlur={false}
          options={[
            { key: "none", value: "", text: "Choose contact category" },
            {
              key: "support",
              value: "General Support Question",
              text: "General Support Question",
            },
            {
              key: "tech",
              value: "Tech Support Issue",
              text: "Tech Support Issue",
            },
            {
              key: "feedback",
              value: "Product / Design Feedback",
              text: "Product / Design Feedback",
            },
          ]}
        />
        <TextareaAutosize
          value={message}
          placeholder="Message"
          minRows={4}
          maxRows={12}
          onChange={(event) => this.setState({ message: event.target.value })}
        />
        <button
          className={`btn btn-primary ${emailSent ? "btn-success" : ""}`}
          type="submit"
          disabled={isInvalid}
        >
          {!emailSending && !emailSent ? (
            <span>send message</span>
          ) : !emailSending && emailSent ? (
            <span>
              <i className="icon icon-checkmark-circle" /> message sent!
            </span>
          ) : (
            <span className="btn-loading" />
          )}
        </button>
        {error && <p className="error">{error.response}</p>}
      </form>
    );
  }
}

const INITIAL_STATE = {
  name: "",
  email: "",
  message: "",
  category: "",
  error: null,
  emailSending: false,
  emailSent: false,
};

const ContactPage = () => (
  <div id="contact" className="content-outer" onLoad={window.scrollTo(0, 0)}>
    <Helmet>
      <title>DesignGapp - Contact</title>
      <meta
        name="description"
        content="Simple, intuitive design documentation for projects and styleguides."
      />
    </Helmet>
    <div className="content-wrap">
      <h1>Contact Us</h1>
      <p className="contact-text">
        If you have any feedback, questions, or suggestions then please email us
        at:{" "}
        <a
          href="mailto:support@designgapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@designgapp.com
        </a>
        .
        <br />
        <br />
        You can also fill out the contact support form and we'll be in touch as
        soon as humanly possible.
      </p>
      <ContactForm />
    </div>
    <ReactBody className="contact" />
  </div>
);

export default ContactPage;

export { ContactForm };
