import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { findInitials } from "../shared/Resources";
import LogoutButton from "components/users/Logout";
import ProgressiveImage from "react-progressive-image";
import * as routes from "constants/routes";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {
    const { authUser, userImg, userName, userMembership, scrollListener } =
      this.props;

    return authUser ? (
      <NavigationAuth
        authUser={authUser}
        userImg={userImg}
        userName={userName}
        userMembership={userMembership}
      />
    ) : (
      <NavigationNonAuth scrollListener={scrollListener} />
    );
  }
}

const NavigationAuth = ({ authUser, userImg, userName, userMembership }) => (
  <nav className={`nav-main nav-auth ${!userImg ? "no-photo" : ""}`}>
    <Menu>
      <Menu.Item
        className="menu-item projects"
        name="projects"
        content="Projects"
        to={routes.Projects}
        as={Link}
      />
      <Menu.Item
        className="menu-item styleguides"
        name="styleguides"
        content="Styleguides"
        to={routes.Styleguides}
        as={Link}
      />
      <Menu.Item
        className="menu-item showcases"
        name="showcases"
        content="Showcases"
        to={routes.Showcases}
        as={Link}
      />
      {/* <Menu.Item
        className="menu-item new personas"
        name="personas"
        content="Personas"
        to={routes.Personas}
        as={Link}
      /> */}
      <Menu.Menu
        className={`menu-item profile-menu ${
          !userMembership ||
          !userMembership.subscription ||
          userMembership.status === "deleted"
            ? "upgrade"
            : ""
        }`}
      >
        <Dropdown
          item
          trigger={
            <div className="profile-img-wrap">
              <p className="profile-initials">{findInitials(userName)}</p>
              {userImg ? (
                <span>
                  <ProgressiveImage
                    placeholder={require("../../images/loading-md.svg")}
                    src={userImg}
                  >
                    {(src, loading) => (
                      <div
                        className={`profile-img progressive ${
                          loading ? "loading" : "done"
                        }`}
                        style={{ backgroundImage: `url("${src}")` }}
                      />
                    )}
                  </ProgressiveImage>
                  <img
                    className="profile-img-src"
                    src={userImg}
                    alt="profile"
                    role="presentation"
                  />
                </span>
              ) : null}
            </div>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item className="menu-item account">
              <Link className="account" to={routes.Account}>
                My Account
              </Link>
            </Dropdown.Item>
            {!userMembership ||
            !userMembership.subscription ||
            userMembership.status === "deleted" ? (
              <Dropdown.Item className="menu-item account-upgrade">
                <Link
                  className="upgrade btn btn-hollow btn-white"
                  to={routes.AccountUpgrade}
                >
                  Upgrade
                </Link>
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item className="menu-item logout">
              <LogoutButton />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  </nav>
);

const NavigationNonAuth = ({ scrollListener }) => (
  <nav className="nav-main">
    <Menu>
      <Menu.Item
        className="menu-item demo"
        name="demo"
        content="Demo"
        to={routes.Demo}
        as={Link}
      />
      <Menu.Item
        className="menu-item about"
        name="how it works"
        content="How it Works"
        to={routes.About}
        as={Link}
        onClick={scrollListener}
      />
      <Menu.Item
        className="menu-item signup"
        name="sign up"
        content="Sign Up"
        to={routes.Signup}
        as={Link}
      />
      <Menu.Item
        className="menu-item login btn btn-accent"
        name="log in"
        content="Log In"
        to={routes.Login}
        as={Link}
      />
    </Menu>
  </nav>
);

const INITIAL_STATE = {
  promoteUpgrade: false,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userImg: state.sessionState.userImg,
  userName: state.sessionState.userName,
  userMembership: state.sessionState.userMembership,
});

export default connect(mapStateToProps)(Navigation);
