import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as routes from "constants/routes";
import { db } from "../../firebase";
import ReactBody from "react-body";

class Index extends Component {
  componentDidUpdate() {
    const { history, authUser } = this.props;
    if (authUser) {
      db.getUser(authUser.uid).then((user) => {
        const userData = user.val();
        if (userData.updateData) {
          userData.updateData.type === "showcase" && userData.showcases
            ? history.push(routes.Showcases)
            : userData.updateData.type === "styleguide" && userData.styleguides
            ? history.push(routes.Styleguides)
            : history.push(routes.Projects);
        } else {
          !userData.showcases && !userData.styleguides && userData.projects
            ? history.push(routes.Projects)
            : userData.styleguides && !userData.projects && !userData.showcases
            ? history.push(routes.Styleguides)
            : userData.showcases && !userData.projects && !userData.styleguides
            ? history.push(routes.Showcases)
            : history.push(routes.Projects);
        }
      });
    } else {
      history.push(routes.Home);
    }
  }

  render() {
    return (
      <div id="index" className="content-outer">
        <div className="content-loading no-header" />
        <ReactBody className="index" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps))(Index);
