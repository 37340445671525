import React, { useState } from "react";
import FilesList from "./FilesList";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { headerText, helpText } from "./Resources";
import "react-datepicker/dist/react-datepicker.css";
import TextareaAutosize from "react-textarea-autosize";

export const Details = (props) => {
  const {
    label,
    step,
    doneStep,
    item,
    item: { details, files, doneList },
    setItem,
    itemTemplate,
  } = props;

  const [dueDate, setDueDate] = useState(
    details.dueDate ? moment(details.dueDate) : null
  );
  const [dueDateValid, setDueDateValid] = useState(true);

  const editDetails = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        details: false,
      },
    }).then(() => {
      if (!details.problem || details.problem === "") {
        document.getElementById("details-problem-textarea").focus();
      }
    });
  };

  const setDetailsProblem = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      details: {
        ...details,
        problem: event.target.value,
      },
    });
  };

  const toggleDetailsCategory = (category) => {
    setItem({
      ...item,
      details: {
        ...details,
        category: details.category === category ? [] : category,
      },
    });
  };

  const setDetailsOther = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      details: {
        ...details,
        other: event.target.value,
      },
    });
  };

  const setDueDatePicker = (date) => {
    if (date !== null) {
      setDueDate(date);
      setDueDateValid(true);
    } else {
      setDueDate(null);
      setDueDateValid(false);
    }
    setItem({
      ...item,
      details: {
        ...details,
        dueDate: date === null ? [] : date._d,
      },
    });
  };

  const setDueDateInput = (event) => {
    let dateOpt1 = moment(String(event), "MM/DD/YYYY", true);
    let dateOpt2 = moment(String(event), "LL", true);
    if (dateOpt1.isValid()) {
      setDueDatePicker(moment(dateOpt1, "LL"));
    } else if (dateOpt2.isValid()) {
      setDueDatePicker(moment(dateOpt2, "LL"));
    } else if (event === "") {
      setDueDateValid(true);
      clearDueDate();
    } else {
      setDueDateValid(false);
      clearDueDate();
    }
  };

  const clearDueDate = () => {
    setDueDate(null);
    setItem({
      ...item,
      details: {
        ...details,
        dueDate: [],
      },
    });
  };

  const doneDetails = () => {
    doneStep("details", step);
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.details ? "done" : ""
      } ${
        (!details.problem || details.problem === "") &&
        (!details.category || details.category.length === 0) &&
        (!details.other || details.other === "") &&
        !dueDate &&
        (!files || files.length === 0)
          ? "none-selected"
          : ""
      }`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.details[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.details[label]}</p>}
          />
        </h2>
        <div
          className={`details-section details-problem ${
            !details.problem || details.problem === "" ? "empty" : "selected"
          }`}
        >
          <label>What problem is this {label} solving?</label>
          <div className="details-problem-wrap">
            <TextareaAutosize
              id="details-problem-textarea"
              minRows={1}
              maxRows={12}
              type="text"
              name="details-intro"
              style={{ lineHeight: 1.67 }}
              value={details.problem}
              className="details-textarea"
              placeholder="Think about what your audience will accomplish..."
              onChange={setDetailsProblem}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  document.getElementById("details-problem-textarea").blur();
                }
              }}
            />
            <span className="card-selected-wrap">
              <span className="card-selected">
                <i className="icon icon-check" />
              </span>
            </span>
          </div>
        </div>
        <div
          className={`details-section details-category ${
            !details.category || details.category.length === 0 ? "empty" : ""
          }`}
        >
          <label>What type of {label} is this?</label>
          <ul className="details-list cards-list">
            {Object.keys(itemTemplate.sections.details.categories).map(
              (key) => (
                <li
                  key={key}
                  className={`card ${
                    itemTemplate.sections.details.categories[key]
                  } ${
                    details.category &&
                    details.category ===
                      itemTemplate.sections.details.categories[key]
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    toggleDetailsCategory(
                      itemTemplate.sections.details.categories[key]
                    )
                  }
                >
                  <p>{itemTemplate.sections.details.categories[key]}</p>
                </li>
              )
            )}
            <p className="details-category-text">or</p>
          </ul>
        </div>
        <div
          className={`details-section details-due ${
            !dueDate ? "empty" : "selected"
          }`}
        >
          <label>Does this {label} have a due date?</label>
          <div className="details-due-wrap">
            <DatePicker
              fixedHeight
              selected={dueDate}
              onChange={setDueDatePicker}
              onChangeRaw={(event) => setDueDateInput(event.target.value)}
              placeholderText="Pick a due date or enter MM/DD/YYYY"
              minDate={moment()}
              dateFormat="MM/DD/YYYY"
            />
            {!dueDateValid ? (
              <span className="due-date-invalid">
                <i className="icon icon-notification" /> Use MM/DD/YYYY
              </span>
            ) : null}
            <span className="card-selected-wrap">
              <span className="card-selected">
                <i className="icon icon-check" />
              </span>
            </span>
            <span className="card-delete-wrap">
              <span className="card-delete" onClick={clearDueDate}>
                <i className="icon icon-cross" />
              </span>
            </span>
          </div>
        </div>
        <div
          className={`details-section details-files ${
            !files || files.length === 0 ? "empty" : ""
          }`}
        >
          <FilesList
            header="Are there important files for the item?"
            {...props}
          />
        </div>
        <div
          className={`details-section details-other ${
            !details.other || details.other === "" ? "empty" : "selected"
          }`}
        >
          <label>What other details of the {label} are important?</label>
          <div className="details-other-wrap">
            <TextareaAutosize
              id="details-other-textarea"
              minRows={1}
              maxRows={12}
              type="text"
              name="details-intro"
              style={{ lineHeight: 1.67, minHeight: "7.25em" }}
              value={details.other || ""}
              className="details-textarea"
              placeholder="List out anything else of note..."
              onChange={setDetailsOther}
            />
            <span className="card-selected-wrap">
              <span className="card-selected">
                <i className="icon icon-check" />
              </span>
            </span>
          </div>
        </div>
        <p className="section-empty">
          No {label} details, click here to enter them.
        </p>
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneDetails}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editDetails}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default Details;
