import { useState, useEffect } from "react";
import WebFont from "webfontloader";
import _get from "lodash.get";
import tinycolor from "tinycolor2";
import ReactQuill from "react-quill";
import { animateScroll } from "react-scroll";

// eslint-disable-next-line
export const useMountEffect = (effectFn) => useEffect(effectFn, []);

export const AsyncState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
};

export const isBrendan = () =>
  process.env.REACT_APP_ENV === "production"
    ? "Q4UOpxMvJmP9XylAfLC2Y7Psdea2"
    : "h6UlJ1f225ejmeeZl0UL1cFyexl1";

export const isTouch = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const itemProject = {
  example: true,
  palette: {
    colors: {
      accent: "#E1367B",
      primary: "#1FBADB",
      secondary: "#511FDB",
    },
  },
  images: {
    0: {
      downloadURL: require("../../images/home-1.jpg"),
    },
    1: {
      downloadURL: require("../../images/home-8.jpg"),
    },
    2: {
      downloadURL: require("../../images/home-4.jpg"),
    },
    3: {
      downloadURL: require("../../images/home-5.jpg"),
    },
  },
};

export const itemStyleguide = {
  example: true,
  palette: {
    colors: {
      accent: "#E1367B",
      primary: "#1FBADB",
      secondary: "#511FDB",
    },
  },
  logos: {
    0: {
      downloadURL: require("../../images/designgapp-logo.png"),
    },
  },
  elements: {
    buttons: {
      list: {
        0: {
          background: "#1FBADB",
          name: "Primary",
        },
        1: {
          background: "#511FDB",
          name: "Secondary",
        },
        2: {
          background: "#E1367B",
          name: "Accent",
        },
      },
    },
  },
};

export const itemShowcase = {
  example: true,
  palette: {
    colors: {
      accent: "#1FBADB",
      primary: "#E18936",
      secondary: "#E1367B",
    },
  },
  screens: {
    0: {
      downloadURL: require("../../images/designgapp-showcase-2.jpg"),
      screenType: "desktop",
    },
  },
};

export const findFileType = (file) => {
  let type = "general";
  if (file.type.indexOf("image") !== -1) {
    type = "image-embed";
  }
  if (
    file.type.indexOf("json") !== -1 ||
    file.type.indexOf("javascript") !== -1 ||
    file.name.indexOf(".sass") !== -1 ||
    file.name.indexOf(".scss") !== -1 ||
    file.type.indexOf("css") !== -1
  ) {
    type = "brackets";
  } else if (
    file.type.indexOf("html") !== -1 ||
    file.type.indexOf("php") !== -1 ||
    file.name.indexOf(".perl") !== -1 ||
    file.name.indexOf(".asp") !== -1 ||
    file.name.indexOf(".cgi") !== -1 ||
    file.name.indexOf(".xml") !== -1
  ) {
    type = "code";
  } else if (
    file.name.indexOf(".pages") !== -1 ||
    file.type.indexOf("rtf") !== -1 ||
    file.name.indexOf(".txt") !== -1 ||
    file.name.indexOf(".sxw") !== -1
  ) {
    type = "text";
  } else if (
    file.name.indexOf(".docx") !== -1 ||
    file.name.indexOf(".doc") !== -1
  ) {
    type = "word";
  } else if (
    file.name.indexOf(".numbers") !== -1 ||
    file.name.indexOf(".sxc") !== -1
  ) {
    type = "spreadsheet";
  } else if (
    file.name.indexOf(".xlsx") !== -1 ||
    file.name.indexOf(".xls") !== -1
  ) {
    type = "excel";
  } else if (
    file.name.indexOf(".keynote") !== -1 ||
    file.name.indexOf(".sxi") !== -1
  ) {
    type = "presentation";
  } else if (
    file.name.indexOf(".pptx") !== -1 ||
    file.name.indexOf(".ppt") !== -1
  ) {
    type = "powerpoint";
  } else if (file.type.indexOf("pdf") !== -1) {
    type = "pdf";
  } else if (file.name.indexOf(".sketch") !== -1) {
    type = "sketch";
  } else if (file.name.indexOf(".fig") !== -1) {
    type = "figma";
  } else if (file.name.indexOf(".psd") !== -1) {
    type = "photoshop";
  } else if (
    file.name.indexOf(".ai") !== -1 ||
    file.name.indexOf(".eps") !== -1
  ) {
    type = "illustrator";
  } else if (file.name.indexOf(".xd") !== -1) {
    type = "xd";
  } else if (
    file.name.indexOf(".indd") !== -1 ||
    file.name.indexOf(".indl") !== -1
  ) {
    type = "indesign";
  } else if (
    file.name.indexOf(".mp3") !== -1 ||
    file.name.indexOf(".midi") !== -1 ||
    file.name.indexOf(".wav") !== -1 ||
    file.type.indexOf("audio") !== -1
  ) {
    type = "audio";
  } else if (
    file.name.indexOf(".mp4") !== -1 ||
    file.name.indexOf(".mov") !== -1 ||
    file.name.indexOf(".avi") !== -1 ||
    file.type.indexOf("video") !== -1
  ) {
    type = "video";
  } else if (file.type.indexOf("zip") !== -1) {
    type = "zip";
  } else if (
    file.name.indexOf("ttf") !== -1 ||
    file.name.indexOf("otf") !== -1 ||
    file.name.indexOf("ttc") !== -1
  ) {
    type = "font";
  }
  return type;
};

export const convertFileSize = (bytes) => {
  let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const findFileUsage = (files) => {
  let fileUsage = 0;
  files.forEach((file) => {
    if (!file.new && file.downloadURL) fileUsage += file.size;
  });
  return fileUsage;
};

export const findInitials = (userName) => {
  let initials = userName ? userName.match(/\b\w/g) : [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

export const replaceSpace = (string) => {
  return string ? String(string.toLowerCase()).replace(/\s/g, "") : "";
};

export const rgb2cmyk = function (r, g, b) {
  var computedC = 0;
  var computedM = 0;
  var computedY = 0;
  var computedK = 0;
  if (r === 0 && g === 0 && b === 0) {
    computedK = 1;
    return { c: 0, m: 0, y: 0, k: 1 };
  }
  computedC = 1 - r / 255;
  computedM = 1 - g / 255;
  computedY = 1 - b / 255;
  var minCMY = Math.min(computedC, Math.min(computedM, computedY));
  computedC = Math.round(((computedC - minCMY) / (1 - minCMY)) * 100);
  computedM = Math.round(((computedM - minCMY) / (1 - minCMY)) * 100);
  computedY = Math.round(((computedY - minCMY) / (1 - minCMY)) * 100);
  computedK = Math.round(minCMY * 100);
  return { c: computedC, m: computedM, y: computedY, k: computedK };
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  for (var i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const customPresetColors = [
  "rgba(0,0,0,0)",
  "#ffffff",
  "#000000",
  "#b71c1c",
  "#d50000",
  "#DF4B44",
  "#e14c7f",
  "#E98CAB",
  "#9c27b0",
  "#673ab7",
  "#3949ab",
  "#2952C3",
  "#1e88e5",
  "#5dbee9",
  "#32A5C3",
  "#00bcd4",
  "#1b5e20",
  "#39BD43",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#9DE747",
  "#cddc39",
  "#ffeb3b",
  "#E2CD17",
  "#ffc107",
  "#E39A02",
  "#FAAB51",
  "#ff5722",
  "#EC603D",
  "#ff7043",
  "#795548",
];

export const customTextColors = [
  "#ffffff",
  "#000000",
  "#b71c1c",
  "#d50000",
  "#DF4B44",
  "#e14c7f",
  "#E98CAB",
  "#9c27b0",
  "#673ab7",
  "#3949ab",
  "#2952C3",
  "#1e88e5",
  "#5dbee9",
  "#32A5C3",
  "#00bcd4",
  "#1b5e20",
  "#39BD43",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#9DE747",
  "#cddc39",
  "#ffeb3b",
  "#E2CD17",
  "#ffc107",
  "#E39A02",
  "#FAAB51",
  "#ff5722",
  "#EC603D",
  "#ff7043",
  "#795548",
];

export const websafeFonts = [
  {
    category: "sans-serif",
    name: "Arial",
    css_stack: "Arial, Helvetica, sans-serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Arial Black",
    css_stack: "Arial Black, Gadget, sans-serif",
    variants: ["400", "italic"],
  },
  {
    category: "sans-serif",
    name: "Comic Sans MS",
    css_stack: "Comic Sans MS, cursive, sans-serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "monospace",
    name: "Courier New",
    css_stack: "Courier New, Courier, monospace",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "monospace",
    name: "monospace",
    css_stack: "monospace",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "serif",
    name: "Georgia",
    css_stack: "Georgia, serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Helvetica",
    css_stack: "Helvetica, Arial, sans-serif",
    variants: ["300", "300italic", "400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Impact",
    css_stack: "Impact, Charcoal, sans-serif",
    variants: ["400", "italic"],
  },
  {
    category: "sans-serif",
    name: "Lucida Grande",
    css_stack: "Lucida Sans Unicode, Lucida Grande, sans-serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "monospace",
    name: "Lucida Console",
    css_stack: "Lucida Console, Monaco, monospace",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "serif",
    name: "Palatino Linotype",
    css_stack: "Palatino Linotype, Book Antiqua, Palatino, serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Tahoma",
    css_stack: "Tahoma, Geneva, sans-serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "serif",
    name: "Times New Roman",
    css_stack: "Times New Roman, Times, serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Trebuchet MS",
    css_stack: "Trebuchet MS, Helvetica, sans-serif",
    variants: ["400", "italic", "bold", "600italic"],
  },
  {
    category: "sans-serif",
    name: "Verdana",
    css_stack: "Verdana, Geneva, sans-serif",
    variants: ["400", "400italic", "italic", "bold", "600italic"],
  },
];

export const headerText = {
  adjectives: {
    project: "Which adjectives match the project aesthetic?",
  },
  audience: {
    project:
      "Everything you create should be done so through the perspective of your audience. Let's define who that is...",
  },
  details: {
    project:
      "Finally, let's capture the details of the project and what makes it important...",
  },
  font: {
    project: "Which font would be a good fit?",
  },
  fonts: {
    project: "Which font would be a good fit?",
    styleguide: "Find your branded font.",
    showcase: "Want to include any fonts?",
  },
  icons: {
    styleguide: "Add your icons.",
  },
  images: {
    project: {
      upload: "Upload project inspiration images.",
      search: "Select project inspiration images.",
    },
    styleguide: {
      upload: "Imagery that fits your visual language.",
      search: "Search Unsplash for imagery.",
    },
  },
  logos: {
    styleguide: "Upload your logo(s).",
  },
  palette: {
    project: "Which color palette would be a good fit?",
    styleguide: "Set your brand's color palette.",
    showcase: "Want to add a color palette?",
  },
  elements: {
    styleguide: "Design the UI elements.",
  },
  files: {
    project:
      "Want to attach any related files (screenshots, project docs, etc)?",
    styleguide: "Want to include any helpful files?",
    showcase: "Want to include any helpful files?",
  },
  screens: {
    showcase: "Drop your showcase images here",
  },
};

export const helpText = {
  adjectives: {
    project:
      "Establish the aesthetic tone of your project by choosing from this list. These choices will help define the overall design direction.",
  },
  audience: {
    project:
      "Designing for your audience is perhaps the single most important factor for a successful project. At all times, consider the person using your project.",
  },
  details: {
    project:
      "Shared understanding of the problem your project is solving and other important details and files will help guide the project through its lifetime.",
  },
  font: {
    project:
      "Choose a font that will match the overall design and theme of the project. How you display text can communicate a lot about the style of the project.",
  },
  fonts: {
    project:
      "Choose a font that will match the overall design and theme of the project. How you display text can communicate a lot about the style of the project.<span class='popup-tip'><strong>Pro tip:</strong> it's best practice not to use more than two fonts.</span>",
    styleguide:
      "Choose your font(s) that matches your brand. How you display text can communicate a lot about the style of the brand.<span class='popup-tip'><strong>Pro tip:</strong> it's best practice not to use more than two fonts.</span>",
    showcase:
      "Choose font(s) that were used in the design(s). Listing these will help with consistency.<span class='popup-tip'><strong>Pro tip:</strong> it's best practice not to use more than two fonts.</span>",
  },
  icons: {
    styleguide:
      "You'll want to have all approved versions of your icons ready so your design, development, and marketing teams have the correct versions to use in their deliverables.",
  },
  images: {
    project:
      "Select imagery that matches the overall aesthetic of the project. These images will serve as design inspiration.",
    styleguide:
      "Select imagery that matches the overall aesthetic of the brand. These images will serve to set the tone of the visual language and imagery used for brand communication.",
  },
  logos: {
    styleguide:
      "You'll want to have all approved versions of your logo ready so your design, development, and marketing teams have the correct versions to use in their deliverables.",
  },
  palette: {
    project:
      "Choose colors that match the theme of the project and will appeal to your users. These colors should compliment the project's aesthetic.",
    styleguide:
      "Choose colors that match the theme of the brand and will appeal to your users. These colors should compliment the brand's aesthetic.",
    showcase:
      "Add colors that are included in your design(s) to communicate the exact color values to use for the design or development process.",
  },
  elements: {
    styleguide:
      "These interface elements should reflect the brand and feature a consistent aesthetic to improve the design and UX. <span class='popup-tip'><strong>Pro tip:</strong> try to match colors and styling across elements to create familiar design patterns.</span>",
  },
  files: {
    styleguide:
      "Attach any files that will help explain your brand or any auxiliary documents that will help tell your story.",
    showcase:
      "Attach any files that were used in your design(s) to help other team members.",
  },
  screens: {
    showcase:
      "All of the images you want to showcase can be managed here. Drop all the important designs here so they can be viewed / downloaded.",
  },
};

export const camelToDash = (string) => {
  if (string === "font") {
    string = "font-family";
  }
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

export const capString = (string) => {
  if (typeof string !== "string") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const spaceToNone = (string) => {
  return String(string).replace(/\s+/g, "").replace(/-/g, "").toLowerCase();
};

export const spaceToEmpty = (string) => {
  return String(string).replace(/\s/g, "");
};

export const borderStyles = [
  { type: "dashed" },
  { type: "dotted" },
  { type: "double" },
  { type: "none" },
  { type: "solid" },
];

export const colorTypes = ["primary", "secondary", "accent"];
export const colorPercents = [125, 120, 115, 110, 105, 95, 90, 85, 80, 75];

export const findHeaderName = (name) => {
  let nameEdit = name.slice(1, 2);
  nameEdit =
    nameEdit === "1"
      ? "One"
      : nameEdit === "2"
      ? "Two"
      : nameEdit === "3"
      ? "Three"
      : nameEdit === "4"
      ? "Four"
      : nameEdit === "5"
      ? "Five"
      : nameEdit;
  return nameEdit;
};

export const defaultColorStyles = {
  headers: {
    color: "#000000",
  },
  h1: {
    color: "#000000",
  },
  h2: {
    color: "#000000",
  },
  h3: {
    color: "#000000",
  },
  h4: {
    color: "#000000",
  },
  h5: {
    color: "#000000",
  },
  a: {
    color: "blue",
  },
  aHover: {
    color: "blue",
  },
  aActive: {
    color: "#E14C7F",
  },
  blockquote: {
    borderColor: "#000000",
  },
  dropdownItem: {
    color: "#111111",
  },
  dropdownItemHover: {
    background: "#111111",
  },
  inputFocus: {
    borderColor: "#1FBADB",
  },
  toggle: {
    background: "rgba(94, 162, 48, 0.425)",
    borderColor: "#579629",
  },
};

export const findElementColorVal = (elements, palette, item, type) => {
  const colorLookup = _get(elements, item)[type];
  if (palette && palette.colors && Object.keys(palette.colors).length > 0) {
    return colorTypes
      .map((e) => {
        return e;
      })
      .indexOf(colorLookup) !== -1
      ? palette.colors[colorLookup]
      : colorLookup;
  } else {
    return colorTypes
      .map((e) => {
        return e;
      })
      .indexOf(colorLookup) !== -1 || colorLookup === ""
      ? defaultColorStyles[item] && defaultColorStyles[item][type]
        ? defaultColorStyles[item][type]
        : colorLookup
      : colorLookup;
  }
};

export const findElementFontVal = (elements, fonts, item, type) => {
  if (elements[item].font === "monospace") {
    return "monospace";
  } else {
    if (type === "fontFamily") {
      return fonts &&
        fonts[elements[item].font] &&
        fonts[elements[item].font].family
        ? `'${fonts[elements[item].font].family}'`
        : fonts &&
          fonts[elements[item].font] &&
          fonts[elements[item].font].css_stack
        ? fonts[elements[item].font].css_stack
        : "inherit";
    } else if (type === "fontName") {
      return fonts &&
        fonts[elements[item].font] &&
        fonts[elements[item].font].family
        ? fonts[elements[item].font].family
        : fonts && fonts[elements[item].font] && fonts[elements[item].font].name
        ? fonts[elements[item].font].name
        : "";
    } else if (type === "fontType") {
      return fonts &&
        fonts[elements[item].font] &&
        fonts[elements[item].font].type
        ? fonts[elements[item].font].type
        : "";
    } else {
      return null;
    }
  }
};

export const findElementFontVariants = (elements, fonts, item) => {
  const fontIndex = elements[item].font;
  if (fonts && fonts[fontIndex] && fontIndex >= 0) {
    let fontVariants = [];
    if (fonts[fontIndex].type === "typekit" && fonts[fontIndex].variations) {
      fonts[fontIndex].variations.forEach((variant, index) => {
        if (fonts[fontIndex].variantData[index].active) {
          fontVariants[index] = normalFontVariant(
            variant,
            fonts[fontIndex].type
          );
        }
      });
    } else if (fonts[fontIndex].variants) {
      fonts[fontIndex].variants.forEach((variant, index) => {
        if (
          fonts[fontIndex].variantData[index].active ||
          fonts[fontIndex].type === "websafe"
        ) {
          fontVariants[index] = normalFontVariant(
            variant,
            fonts[fontIndex].type
          );
        }
      });
    }
    return fontVariants;
  } else {
    return ["normal", "normal italic", "bold", "bold italic"];
  }
};

export const normalFontVariant = (variant, type) => {
  if (type === "typekit") {
    if (variant.indexOf("i") !== -1 && variant.length === 2) {
      variant = variant.replace("i", "");
      variant = String(Number(variant * 100) + " italic");
    }
    if (variant.indexOf("n") !== -1 && variant.length === 2) {
      variant = variant.replace("n", "");
      variant = String(Number(variant * 100));
    }
    if (variant === "400") {
      variant = "normal";
    }
    if (variant === "400 italic") {
      variant = "normal italic";
    }
  } else {
    variant.toLowerCase();
    if (variant.indexOf("italic") > 0 && variant.indexOf(" italic") === -1) {
      variant = variant.replace("italic", " italic");
    }
    if (variant.indexOf("400") !== -1) {
      variant = variant.replace("400", "normal");
    }
    if (variant.indexOf("regular") !== -1) {
      variant = variant.replace("regular", "normal");
    }
    if (variant === "italic") {
      variant = "normal italic";
    }
    if (type === "websafe" && variant.indexOf("600italic") !== -1) {
      variant = variant.replace("600italic", "bold italic");
    }
  }
  return variant;
};

export const findElementStyleVal = (elements, fonts, palette, item, type) => {
  if (type === "font") {
    if (fonts) {
      return fonts[item] && fonts[item].family
        ? fonts[item].type === "upload"
          ? `"${fonts[item].family}"`
          : `"${fonts[item].family}"`
        : fonts[item] && fonts[item].css_stack
        ? fonts[item].css_stack
        : null;
    } else {
      return null;
    }
  } else {
    if (
      colorTypes
        .map((e) => {
          return e;
        })
        .indexOf(item) !== -1
    ) {
      return palette && palette.colors && palette.colors[item]
        ? palette.colors[item]
        : "inherit";
    } else {
      return item;
    }
  }
};

export const findNormalFontWeight = (font) => {
  let findWeight = "normal";
  if (font && font.variantData) {
    let findNormal = font.variantData
      .map((e) => e.weight)
      .indexOf(font.type === "typekit" ? 400 : "normal");
    if (findNormal !== -1 && !font.variantData[findNormal].active) {
      let closestActive = font.variantData.map((e) => e.active).indexOf(true);
      findWeight =
        closestActive !== -1
          ? font.variantData[closestActive].weight
          : "normal";
    }
  }
  return findWeight;
};

export const findNormalFontStyle = (font) => {
  let findStyle = "normal";
  if (font && font.variantData) {
    let findNormal = font.variantData.map((e) => e.style).indexOf("normal");
    if (findNormal !== -1 && !font.variantData[findNormal].active) {
      let closestActive = font.variantData.map((e) => e.active).indexOf(true);
      findStyle =
        closestActive !== -1 ? font.variantData[closestActive].style : "normal";
    }
  }
  return findStyle;
};

export const loadFonts = (fonts, callback) => {
  let googleFontsList = [];
  let typekitFontsList = [];
  fonts.forEach((font) => {
    if (font.type === "google") {
      font.variantData.forEach((variant) => {
        googleFontsList.push(variant.url);
      });
    } else if (font.type === "typekit") {
      typekitFontsList.push(font.kit);
    }
  });
  if (googleFontsList.length > 0) {
    WebFont.load({
      google: {
        families: googleFontsList,
      },
      active: () => {
        if (callback) {
          callback();
        }
      },
    });
  } else if (callback) {
    callback();
  }
  if (typekitFontsList.length > 0) {
    typekitFontsList.forEach((kit) => {
      WebFont.load({
        typekit: {
          id: kit,
        },
        active: () => {
          if (callback) {
            callback();
          }
        },
      });
    });
  } else {
    if (callback) {
      callback();
    }
  }
};

export const getStripeKey = () => {
  return process.env.REACT_APP_ENV === "production"
    ? "pk_live_eeN3lY36l8kDlwOuvbQjpsLV"
    : "pk_test_akrnY7dj1JfynBxHakR3i1aV";
};

export const colorAAcheck = (theme, color, size) => {
  const bgColor = theme === "dark" ? "#000" : "#fff";
  return tinycolor.isReadable(bgColor, color, { level: "AA", size: size });
};

export const findAAcolor = (theme, color, size) => {
  let fixedColor = "";
  for (var i = 1; i < 100; i++) {
    let colorTemp =
      theme === "dark"
        ? tinycolor(color).lighten(i).toHexString()
        : tinycolor(color).darken(i).toHexString();
    if (colorAAcheck(theme, colorTemp, size)) {
      fixedColor = colorTemp;
      i = 100;
    }
  }
  return fixedColor;
};

export const findTextColor = (color, type) => {
  let colorClass = "light";
  if (type === "small") {
    if (tinycolor.readability("#fff", color) < 2) {
      colorClass = "dark";
    }
  } else if (type === "large") {
    if (tinycolor.readability("#fff", color) < 1.5) {
      colorClass = "dark";
    }
  }
  return colorClass;
};

export const setItemColors = (palette, theme) => {
  let colors = [];
  let colorPalette = [];

  if (palette) {
    if (palette.colors) {
      colorPalette = [
        {
          id: "primary",
          color: palette.colors.primary,
        },
        {
          id: "secondary",
          color: palette.colors.secondary,
        },
        {
          id: "accent",
          color: palette.colors.accent,
        },
      ];
    }

    // Fallback to use alt colors if no traditional palette was selected
    if (palette.altColors && !palette.colors) {
      if (palette.altColors[0]) {
        colorPalette[0] = {
          id: "primary",
          color: palette.altColors[0].color,
        };
      }
      if (palette.altColors[1]) {
        colorPalette[1] = {
          id: "secondary",
          color: palette.altColors[1].color,
        };
      }
      if (palette.altColors[2]) {
        colorPalette[2] = {
          id: "accent",
          color: palette.altColors[2].color,
        };
      }
    }

    if (palette.altColors) {
      colorPalette.push(...palette.altColors);
    }
  }
  colors = setColorArrays(colorPalette, theme);
  return colors;
};

export const setColorArrays = (colors, theme) => {
  let colorVars = [];
  let colorReport = [];
  let colorDisplay = [];

  colors.forEach((color) => {
    colorVars[color.id] = color.color;
    colorVars[`${color.id}Dark`] = tinycolor(color.color)
      .darken(10)
      .toHexString();
    colorVars[`${color.id}Darker`] = tinycolor(color.color)
      .darken(15)
      .toHexString();
    colorVars[`${color.id}Light`] = tinycolor(color.color)
      .lighten(10)
      .toHexString();
    colorVars[`${color.id}Lighter`] = tinycolor(color.color)
      .lighten(15)
      .toHexString();
    colorReport[`${color.id}AAsmall`] = colorAAcheck(
      theme,
      colorVars[color.id],
      "small"
    );
    colorReport[`${color.id}AAlarge`] = colorAAcheck(
      theme,
      colorVars[color.id],
      "large"
    );
    colorReport[`toggle${color.id}small`] = "";
    colorReport[`toggle${color.id}large`] = "";

    if (!colorReport[`${color.id}AAsmall`]) {
      colorReport[`${color.id}AAsmallAlt`] = findAAcolor(
        theme,
        colorVars[color.id],
        "small"
      );
    }
    if (!colorReport[`${color.id}AAlarge`]) {
      colorReport[`${color.id}AAlargeAlt`] = findAAcolor(
        theme,
        colorVars[color.id],
        "large"
      );
      colorDisplay[color.id] = colorReport[`${color.id}AAlargeAlt`];
    } else {
      colorDisplay[color.id] = colorVars[color.id];
    }
    colorDisplay[`${color.id}Light`] = tinycolor(colorDisplay[color.id])
      .lighten(10)
      .toHexString();
    colorDisplay[`${color.id}Dark`] = tinycolor(colorDisplay[color.id])
      .darken(10)
      .toHexString();
  });

  return {
    colorVars: colorVars,
    colorReport: colorReport,
    colorDisplay: colorDisplay,
  };
};

export const textContentModules = (item) => {
  const Quill = ReactQuill.Quill;
  let Font = Quill.import("formats/font");
  let itemFonts = ["sans-serif"];
  let itemColors = [];
  if (item && item.fonts && item.fonts.length > 0) {
    itemFonts = [];
    item.fonts.forEach((font) => {
      itemFonts.push(spaceToNone(font.family ? font.family : font.name));
    });
  }
  if (item && item.palette) {
    if (item.palette.colors) {
      Object.keys(item.palette.colors).forEach((color) => {
        itemColors.push(item.palette.colors[color]);
      });
    }
    if (item.palette.altColors) {
      item.palette.altColors.forEach((altColors) => {
        itemColors.push(altColors.color);
      });
    }
    itemColors = itemColors.concat(customTextColors);
  }
  Font.whitelist = itemFonts;
  Quill.register(Font, true);
  return {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      [{ font: Font.whitelist }],
      [{ align: [] }],
      ["bold", "italic", "underline"],
      [{ color: itemColors }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
      ["image"],
    ],
  };
};

export const textContentFormats = [
  "header",
  "font",
  "align",
  "bold",
  "italic",
  "underline",
  "color",
  "list",
  "bullet",
  "link",
  "clean",
  "image",
];

export const scrollClickFix = (duration) => {
  const initialHeight = document.documentElement.scrollHeight;
  const initialScrollPos = document.documentElement.scrollTop;
  setTimeout(() => {
    const finalHeight = document.documentElement.scrollHeight;
    const finalScroll =
      finalHeight - initialHeight + document.documentElement.scrollTop;
    if (initialScrollPos !== finalScroll) {
      animateScroll.scrollTo(finalScroll, {
        duration: duration ? duration : 100,
        isDynamic: true,
      });
    }
  });
};

export const inlineCSS = (css, id) => {
  return css.replace(
    /(^(?:\s|[^@{])*?|[},]\s*)(\/\/.*\s+|.*\/\*[^*]*\*\/\s*|@media.*{\s*|@font-face.*{\s*)*([.#]?-?[_a-zA-Z]+[_a-zA-Z0-9-]*)(?=[^}]*{)/g,
    `$1$2 #root ${id} $3`
  );
};

export const inFrame = () => {
  return window.location !== window.parent.location ? true : false;
};
