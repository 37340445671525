import React, { useState } from "react";
import { Checkbox } from "semantic-ui-react";
import { ItemColor, ItemFont, ItemFontStyle, ItemCSS } from "../Elements";

export const TogglesElement = (props) => {
  const {
    sections,
    findFontVal,
    findFontVariants,
    findColorVal,
    findStyleVal,
    updateColor,
    updateFont,
    updateWeight,
    toggleCSS,
    toggleSection,
    item: { elements, palette, fonts },
  } = props;

  const [checkBoxToggle, setCheckBoxToggle] = useState(true);
  const [checkBoxSlider, setCheckBoxSlider] = useState(true);
  const [checkBoxOne, setCheckBoxOne] = useState(true);
  const [checkBoxTwo, setCheckBoxTwo] = useState(false);
  const [radioBtn, setRadioBtn] = useState(true);

  const fontProps = {
    fonts: fonts,
    elements: elements,
    findFontVal: findFontVal,
    updateFont: updateFont,
  };

  const fontStyleProps = {
    elements: elements,
    findFontVal: findFontVal,
    findFontVariants: findFontVariants,
    updateWeight: updateWeight,
  };

  const colorProps = {
    elements: elements,
    palette: palette,
    findColorVal: findColorVal,
    updateColor: updateColor,
  };

  return (
    <div
      id="toggle-element"
      className={`element element-toggles ${
        sections.toggles.active ? "active" : ""
      } ${sections.toggles.css ? "css" : ""} ${
        elements.toggle.disabled ? "disabled" : ""
      }`}
    >
      <div
        className="element-content"
        onClick={() => toggleSection("toggles", true)}
      >
        <style>
          {`
            #elements .checkbox input ~ label, #elements .checkbox input:checked ~ label {
              color: ${findColorVal("toggle", "color")};
              font-family: ${findFontVal("toggle", "fontFamily")};
              font-style: ${elements.toggle.fontStyle};
              font-weight: ${elements.toggle.fontWeight};
            }
            #elements .checkbox.disabled input ~ label, #elements .checkbox.disabled input:checked ~ label {
              color: ${findColorVal("toggleDisabled", "color")};
            }
            #elements .checkbox input:checked ~ label:after {
              background: ${findColorVal("toggle", "borderColor")};
              border-color: ${findColorVal("toggle", "borderColor")};
            }
            #elements .checkbox.toggle.disabled input ~ label:before, #elements .checkbox.slider.disabled input ~ label:before {
              background: ${findColorVal("toggleDisabled", "background")};
            }
            #elements .checkbox.toggle.disabled input ~ label:after, #elements .checkbox.slider.disabled input ~ label:after {
              background: ${findColorVal("toggleDisabled", "borderColor")};
            }
            #elements .checkbox.toggle input:checked ~ label:before, #elements .checkbox.slider input:checked ~ label:before {
              background: ${findColorVal("toggle", "background")};
            }
            #elements .checkbox.toggle input:checked ~ label:after, #elements .checkbox.slider input:checked ~ label:after {
              background: ${findColorVal("toggle", "borderColor")};
            }
            #elements .checkbox.radio input:checked ~ label:before {
              background: ${findColorVal("toggle", "borderColor")};
            }
            #elements .checkbox.radio input:checked ~ label:after {
              border-color: ${findColorVal("toggle", "borderColor")};
            }
          `}
        </style>

        <div className="toggle-group">
          <Checkbox
            toggle
            label="Toggle"
            className={!checkBoxToggle ? "disabled" : ""}
            checked={checkBoxToggle}
            onChange={() => setCheckBoxToggle(!checkBoxToggle)}
          />
          <Checkbox
            toggle
            label="Toggle"
            className={checkBoxToggle ? "disabled" : ""}
            checked={!checkBoxToggle}
            onChange={() => setCheckBoxToggle(!checkBoxToggle)}
          />
        </div>

        <div className="toggle-group">
          <Checkbox
            slider
            label="Slider"
            className={!checkBoxSlider ? "disabled" : ""}
            checked={checkBoxSlider}
            onChange={() => setCheckBoxSlider(!checkBoxSlider)}
          />
          <Checkbox
            slider
            label="Slider"
            className={checkBoxSlider ? "disabled" : ""}
            checked={!checkBoxSlider}
            onChange={() => setCheckBoxSlider(!checkBoxSlider)}
          />
        </div>

        <div className="toggle-group">
          <Checkbox
            label="Checkbox"
            className={!checkBoxOne ? "disabled" : ""}
            checked={checkBoxOne}
            onChange={() => setCheckBoxOne(!checkBoxOne)}
          />
          <Checkbox
            label="Checkbox"
            className={!checkBoxTwo ? "disabled" : ""}
            checked={checkBoxTwo}
            onChange={() => setCheckBoxTwo(!checkBoxTwo)}
          />
        </div>

        <div className="toggle-group">
          <Checkbox
            radio
            label="Radio"
            className={!radioBtn ? "disabled" : ""}
            checked={radioBtn}
            onChange={() => setRadioBtn(!radioBtn)}
          />
          <Checkbox
            radio
            label="Radio"
            className={radioBtn ? "disabled" : ""}
            checked={!radioBtn}
            onChange={() => setRadioBtn(!radioBtn)}
          />
        </div>
      </div>

      {sections.toggles.active ? (
        <div className="edit-element">
          <div className="edit-item edit-parent">
            <label>Toggle styles</label>

            <div className="edit-item-sub">
              <ItemFont item={"toggle"} {...fontProps} />

              <ItemFontStyle item={"toggle"} {...fontStyleProps} />

              <ItemColor
                type="color"
                item="toggle"
                label="font color"
                {...colorProps}
              />

              <ItemColor
                type="borderColor"
                item="toggle"
                label="handle color"
                {...colorProps}
              />

              <ItemColor
                type="background"
                item="toggle"
                label="track color"
                {...colorProps}
              />
            </div>
          </div>

          <div className="edit-item edit-parent">
            <label>Disabled toggle styles</label>

            <div className="edit-item-sub">
              <ItemColor
                type="color"
                item="toggleDisabled"
                label="font color"
                {...colorProps}
              />

              <ItemColor
                type="borderColor"
                item="toggleDisabled"
                label="handle color"
                {...colorProps}
              />

              <ItemColor
                type="background"
                item="toggleDisabled"
                label="track color"
                {...colorProps}
              />
            </div>
          </div>
        </div>
      ) : null}
      {sections.toggles.active ? (
        <div className="edit-element-btn" onClick={() => toggleCSS("toggles")}>
          <span>{sections.toggles.css ? "hide" : "show"} css</span>
        </div>
      ) : null}
      {sections.toggles.css ? (
        <div className="edit-element-css">
          <div className="element-css">
            <ItemCSS
              item={elements.toggle}
              label={`.toggle`}
              findStyleVal={findStyleVal}
            />

            <ItemCSS
              item={elements.toggleDisabled}
              label={`.toggle.disabled`}
              findStyleVal={findStyleVal}
            />
          </div>
        </div>
      ) : null}

      <div className="card-edit-wrap">
        <span className="card-edit" onClick={() => toggleSection("toggles")}>
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};
