import React, { useEffect } from "react";
import { compose } from "recompose";
import { useSelector } from "react-redux";
import { useMountEffect } from "./Resources";
import { db, firebase } from "../../firebase";
import * as routes from "constants/routes";
import withAuthorization from "components/withAuthorization";

export const ConfirmUser = (props) => {
  const { history } = props;
  const userID = props.match.params.userID;
  const itemType = props.match.params.type;
  const itemID = props.match.params.id;
  const itemRoute =
    itemType === "showcases"
      ? routes.Showcase
      : itemType === "styleguides"
      ? routes.Styleguide
      : routes.Project;

  const authUser = useSelector((state) => state.sessionState.authUser);

  useEffect(() => window.scrollTo(0, 0), []);

  useMountEffect(() => {
    if (authUser.uid === userID) {
      db.getItem(itemType, itemID).then((snapshot) => {
        if (snapshot.val().hasOwnProperty("version")) {
          firebase
            .confirmJoinItem({ uid: userID, id: itemID, type: itemType })
            .then(() => {
              history.push(`${itemRoute}/edit/${itemID}`);
            });
        }
      });
    } else {
      history.push(itemRoute);
    }
  });

  return (
    <section id="confirm-added-user" className="content-outer">
      <div className="content-wrap">
        <div className="content-loading no-header" />
      </div>
    </section>
  );
};

const authCondition = (authUser) => !!authUser;

export default compose(withAuthorization(authCondition))(ConfirmUser);
