import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  inlineCSS,
  inFrame,
  findNormalFontWeight,
  findNormalFontStyle,
  colorTypes,
  isBrendan,
} from "../shared/Resources";
import * as routes from "constants/routes";
import LoadingSpinner from "../shared/Icons/LoadingSpinner";
import TextAccessibility from "../shared/TextAccessibility";
import { FontsDisplay } from "../shared/Fonts";
import { PaletteDisplay } from "../shared/PaletteDisplay";
import { TextContentDisplay } from "../shared/TextContent";
import { FileDisplay } from "../shared/FilesList";
import { ItemSettings } from "../shared/ItemSettings";
import { Dropdown, Menu } from "semantic-ui-react";
import ProgressiveImage from "react-progressive-image";
import ReactBody from "react-body";

export const ShowcasePublished = (props) => {
  const {
    history,
    authUser,
    item,
    itemID,
    itemTemplate,
    itemColors,
    itemIsReady,
    loadItem,
    hideSpinner,
    updateSettings,
    saveTheme,
    manageItemUsers,
    openShareItemModal,
  } = props;

  const [drawerActive, setDrawerActive] = useState(false);
  const [itemLoadError, setItemLoadError] = useState(false);

  return (
    <section
      id="showcase"
      className={`content-outer ${itemIsReady ? "loaded" : ""} theme-${
        item.theme ? item.theme : "light"
      }`}
    >
      {item.doneList && item.doneList.publish ? (
        <div className="item-outer">
          <Helmet>
            <title>{item.name ? item.name : "DesignGapp"}</title>
            <meta
              name="og:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="twitter:title"
              content={item.name ? item.name : "DesignGapp"}
            />
            <meta
              name="description"
              content={`DesignGapp Showcase: ${
                item.name ? item.name : "Untitled"
              }`}
            />
          </Helmet>

          {item.settings && item.settings.css ? (
            <style>{inlineCSS(item.settings.css, "#showcase")}</style>
          ) : null}

          {authUser &&
          (Object.keys(item.users).indexOf(authUser.uid) !== -1 ||
            authUser.uid === isBrendan()) &&
          !inFrame() ? (
            <nav className="item-publish-nav">
              <button
                className="btn btn-text item-back-btn"
                onClick={() => history.push(routes.Showcases)}
              >
                <i className="icon icon-arrow-left" />
              </button>

              <Menu className="item-nav-menu">
                <Dropdown
                  trigger={
                    <div className="item-nav-trigger">
                      <i className="icon icon-ellipsis" />
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <span
                      className="link item-menu-item menu-item item-showcases"
                      onClick={() => history.push(routes.Showcases)}
                    >
                      <i className="icon icon-arrow-left" />
                      <span className="menu-text">showcases</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() =>
                        history.push(routes.Showcase + "/edit/" + itemID)
                      }
                    >
                      <i className="icon icon-edit" />
                      <span className="menu-text">edit</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-edit"
                      onClick={() => setDrawerActive(true)}
                    >
                      <i className="icon icon-equalizer" />
                      <span className="menu-text">publish settings</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-members"
                      onClick={manageItemUsers}
                    >
                      <i className="icon icon-users" />
                      <span className="menu-text">members</span>
                    </span>
                    <span
                      className="link item-menu-item menu-item item-share"
                      onClick={openShareItemModal}
                    >
                      <i className="icon icon-exit-up" />
                      <span className="menu-text">share</span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>

              <ItemSettings
                template={itemTemplate}
                settings={item.settings}
                theme={item.theme}
                drawerActive={drawerActive}
                setDrawerActive={setDrawerActive}
                updateSettings={updateSettings}
                saveTheme={saveTheme}
              />
            </nav>
          ) : null}

          {item.palette ? (
            <style>{`
              ::selection {
                background: ${itemColors.colorDisplay.primary};
              }
              .item-publish .item-loading h1 {
                color: ${itemColors.colorDisplay.primary};
              }
              #showcase h2 .header-text,
              .item-inspiration-font .item-publish-font h2,
              .item-inspiration-font .item-publish-font h3,
              .item-publish .item-publish-nav .item-back-btn .icon,
              .item-publish .item-publish-nav .item-nav-trigger .icon {
                color: ${itemColors.colorDisplay.primary};
              }
              .item-publish .item-publish-nav .item-back-btn .icon,
              .item-publish .item-publish-nav .item-nav-trigger .icon {
                color: ${
                  item.theme === "dark"
                    ? itemColors.colorDisplay.primary
                    : itemColors.colorDisplay.primaryDark
                };
              }
              .item-publish #showcase .item-publish-nav .item-back-btn:hover .icon {
                color: ${itemColors.colorDisplay.accent};
              }
              @media (max-width: 768px) {
                .item-publish #showcase .item-publish-nav .item-nav-trigger {
                  background: ${itemColors.colorDisplay.primary};
                }
                .item-publish #showcase .item-publish-nav .item-nav-trigger:hover {
                  background: ${itemColors.colorDisplay.primaryDark};
                }
              } 
              .item-publish #showcase .item-publish-nav .item-nav-trigger:hover {
                background: ${itemColors.colorDisplay.primary};
              }
              .item-publish #showcase .item-publish-nav .item-nav-trigger:hover .icon {
                color: white;
              }
              .font-paragraph {
                border-left-color: ${itemColors.colorDisplay.primary}
              }
            `}</style>
          ) : null}
          <div className="item-content">
            {item.fonts ? (
              <style>
                {`
                  .item-content, .item-content h2, .item-content h3, .item-content p, .item-content a, .item-content .btn, .item-publish .item-loading h1 {
                    font-family: ${
                      item.fonts[0].family
                        ? `"${item.fonts[0].family}"`
                        : item.fonts[0].css_stack
                    };
                    font-weight: ${findNormalFontWeight(item.fonts[0])};
                    font-style: ${findNormalFontStyle(item.fonts[0])};
                  }
                `}
              </style>
            ) : null}

            <div className={`item-body ${item.content ? "has-content" : ""}`}>
              {item.screens || item.palette || item.fonts || item.files ? (
                <section className="item-publish-inspiration publish-section">
                  <div className="item-inner">
                    <div className="item-inspiration-sub section-sub">
                      <TextContentDisplay
                        content={item.content}
                        child={"root"}
                        node={0}
                      />

                      {item.settings && item.settings.order
                        ? item.settings.order.map((section, index) =>
                            section === "screens" && item.screens ? (
                              <ShowcaseScreens
                                item={item}
                                index={index}
                                loadItem={loadItem}
                                setItemLoadError={setItemLoadError}
                                key={"logos"}
                              />
                            ) : section === "palette" && item.palette ? (
                              <ShowcasePalette
                                item={item}
                                index={index}
                                itemColors={itemColors}
                                key={"palette"}
                              />
                            ) : section === "fonts" && item.fonts ? (
                              <ShowcaseFonts
                                item={item}
                                index={index}
                                key={"fonts"}
                              />
                            ) : section === "files" && item.files ? (
                              <ShowcaseFiles
                                item={item}
                                index={index}
                                loadItem={loadItem}
                                setItemLoadError={setItemLoadError}
                                key={"files"}
                              />
                            ) : null
                          )
                        : null}

                      <TextContentDisplay
                        content={item.content}
                        child={"root"}
                        node={1}
                      />
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </div>
          <div
            className={`item-loading content-loading no-spinner ${
              itemLoadError ? "error" : ""
            }`}
          >
            {!itemLoadError && !hideSpinner ? (
              <span>
                <h1>Building showcase...</h1>
                <LoadingSpinner colors={itemColors} />
              </span>
            ) : itemLoadError ? (
              <span className="item-load-error">
                <i className="icon icon-cross-circle" />
                <h1>
                  Sorry, something went wrong.
                  <br />
                  Please try loading this again.
                </h1>
                <p>
                  <Link to={routes.Contact}>
                    Contact us if this problem persists.
                  </Link>
                </p>
              </span>
            ) : null}
          </div>
          <ReactBody className="item-publish" />
        </div>
      ) : item.doneList && !item.doneList.publish ? (
        <div className="item-outer item-unpublish-outer">
          <i className="icon icon-large icon-notification" />
          <h1>This showcase hasn't been published.</h1>
        </div>
      ) : (
        <ReactBody className="item-publish" />
      )}
    </section>
  );
};

const ShowcaseScreens = ({ item, index, loadItem, setItemLoadError }) => (
  <div
    id="screens"
    className={`item-inspiration-screens sub-content index-${index}`}
  >
    <TextContentDisplay content={item.content} child={"screens"} node={0} />
    <div className="screens-list">
      {item.screens.map((screen) => (
        <div className={`screen-${screen.screenType}`} key={screen.name}>
          <div className="file-content screen-content">
            <div
              className={`file-img ${
                screen.screenScale ? `scale-${screen.screenScale}` : ""
              }`}
              style={{
                backgroundColor:
                  screen.screenType === "background" && screen.background
                    ? screen.background.hex
                    : (screen.screenType === "desktop" ||
                        screen.screenType === "mobile") &&
                      item.theme !== "dark"
                    ? "black"
                    : "white",
              }}
            >
              <ProgressiveImage
                placeholder={require("../../images/loading-sm.svg")}
                src={screen.downloadURL}
                key={screen.downloadURL}
              >
                {(src, loading) => (
                  <div
                    className={`file-img-wrap progressive ${
                      loading ? "loading" : "done"
                    }`}
                  >
                    <img
                      height={screen.height}
                      width={screen.width}
                      src={src}
                      alt={screen.name}
                    />
                  </div>
                )}
              </ProgressiveImage>
            </div>
          </div>
        </div>
      ))}
    </div>
    <TextContentDisplay content={item.content} child={"logos"} node={1} />
  </div>
);

const ShowcasePalette = ({ item, index, itemColors }) => (
  <div
    id="palette"
    className={`item-inspiration-palette sub-content index-${index}`}
  >
    <h2>Palette</h2>
    <TextContentDisplay content={item.content} child={"palette"} node={0} />
    {item.palette.colors ? (
      <div className="item-palette-wrap">
        {colorTypes.map((color) => (
          <div className={`item-palette-color ${color}`} key={color}>
            <PaletteDisplay name={color} color={itemColors.colorVars[color]} />
          </div>
        ))}
      </div>
    ) : null}
    {item.palette.altColors ? (
      <div className="item-palette-wrap palette-alt-wrap">
        {item.palette.altColors.map((altColor) => (
          <div
            className="item-palette-color palette-alt-color"
            key={altColor.id}
          >
            <PaletteDisplay name={altColor.name} color={altColor.color} />
          </div>
        ))}
      </div>
    ) : null}
    {item.settings &&
    item.settings.accessibility &&
    (item.palette.colors || item.palette.altColors) ? (
      <TextAccessibility item={item} itemColors={itemColors} />
    ) : null}
    <TextContentDisplay content={item.content} child={"palette"} node={1} />
  </div>
);

const ShowcaseFonts = ({ item, index }) => (
  <div
    id="fonts"
    className={`item-inspiration-font sub-content index-${index}`}
  >
    <h2>Fonts</h2>
    <TextContentDisplay content={item.content} child={"fonts"} node={0} />
    <FontsDisplay type={"showcase"} fonts={item.fonts} />
    <TextContentDisplay content={item.content} child={"fonts"} node={1} />
  </div>
);

const ShowcaseFiles = ({ item, index, loadItem, setItemLoadError }) => (
  <div
    id="files"
    className={`item-inspiration-files sub-content index-${index}`}
  >
    <h2>Files</h2>
    <TextContentDisplay content={item.content} child={"files"} node={0} />
    <div className="showcase-files item-files">
      <ul className="files-list cards-list">
        {item.files.map((file, index) => (
          <li key={index} title={file.name} className="card selected item-file">
            <FileDisplay
              file={file}
              loadItem={loadItem}
              setItemLoadError={setItemLoadError}
            />
          </li>
        ))}
      </ul>
    </div>
    <TextContentDisplay content={item.content} child={"files"} node={1} />
  </div>
);

export default ShowcasePublished;
