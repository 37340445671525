import { storage, storageDemos } from "./firebase";
import {
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export const getDownload = (path) => getDownloadURL(ref(storage, path));

export const getCurrentTemplate = (type) =>
  getDownload(`/templates/${type}Template.json`);

export const getCurrentArchivedTemplate = (type, id) =>
  getDownload(`/templates/archive/${type}-${id}.json`);

export const getRef = (path) => ref(storage, path);

export const getDemosRef = (path) => ref(storageDemos, path);

export const uploadFile = (storageRef, file) => uploadBytes(storageRef, file);

export const uploadFiles = (storageRef, file) =>
  uploadBytesResumable(storageRef, file);

export const deleteFile = (file) => deleteObject(file);
