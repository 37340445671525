import React from "react";
import { Popup } from "semantic-ui-react";
import { headerText, helpText } from "./Resources";

export const Adjectives = (props) => {
  const {
    label,
    step,
    doneStep,
    item,
    item: { adjectives, doneList },
    setItem,
    itemTemplate,
  } = props;

  const editAdjectives = (event) => {
    event.preventDefault();
    setItem({
      ...item,
      doneList: {
        ...doneList,
        adjectives: false,
      },
    });
  };

  const toggleAdjectives = (adjective) => {
    let adjectivesUpdate = [...adjectives];
    const i = adjectives.indexOf(adjective);
    if (i === -1) {
      adjectivesUpdate.push(adjective);
    } else {
      adjectivesUpdate.splice(i, 1);
    }
    setItem({ ...item, adjectives: adjectivesUpdate });
  };

  const doneAdjectives = () => {
    doneStep("adjectives", step);
  };

  return (
    <div
      className={`component-section-wrap ${
        doneList && doneList.adjectives ? "done" : ""
      } ${adjectives.length === 0 ? "none-selected" : ""}`}
    >
      <div className="component-section-inner">
        <h2 className="with-help">
          {headerText.adjectives[label]}
          <Popup
            hoverable
            hideOnScroll
            position="top right"
            className="popup-help"
            trigger={
              <div className="header-help">
                <i className="icon icon-question-circle" />
              </div>
            }
            header={<p>{helpText.adjectives[label]}</p>}
          />
        </h2>
        <ul className="adjectives-list cards-list">
          {Object.keys(itemTemplate.sections.adjectives).map((key) => (
            <li
              key={key}
              className={`card ${itemTemplate.sections.adjectives[key].value} ${
                adjectives.indexOf(
                  itemTemplate.sections.adjectives[key].value
                ) !== -1
                  ? "selected"
                  : ""
              }`}
              onClick={() =>
                toggleAdjectives(itemTemplate.sections.adjectives[key].value)
              }
            >
              <p>{itemTemplate.sections.adjectives[key].value}</p>
            </li>
          ))}
        </ul>
        <p className="section-empty">
          No adjectives selected, click here to choose one.
        </p>
      </div>
      <div className="section-btn-done">
        <div className="btn btn-done" onClick={doneAdjectives}>
          <i className="icon icon-checkmark-circle" />
        </div>
      </div>
      <div className="section-edit-wrap" onClick={editAdjectives}>
        <span className="section-edit-icon component-section-inner">
          <i className="icon icon-edit" />
        </span>
      </div>
    </div>
  );
};

export default Adjectives;
